import React, { useState, useEffect, useContext, useCallback } from "react"
import {Container} from "react-bootstrap"
import UserContext from "../../services/user-context"
import Fetch from "../helpers/fetch-data"
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataZoom";
import 'echarts/lib/component/legendScroll';
import { STYLES, createSeries } from '../plots/options'


const _haveValues = a => Array.isArray(a) && a.length>0

export default function BatteryOptimisation({battery: __battery=null, startDate, endDate}) {
    const [battery, setBattery] = useState(__battery)
    const [policy, setPolicy] = useState(null)
    const [soc, setSOC] = useState(null)
    const [socDataset, setSocDataset] = useState(null)
    const [option, setOption] = useState({})
    const [minMax, setMinMax] = useState({})
	const [chart, setChart] = useState(null)

    const { Time } = useContext(UserContext)

    const _timestamp = useCallback(ts => +Time.localTime(ts), Time)

    useEffect(() => {
        // if(!battery || !startDate || !endDate) return

        console.log(`Times : ${startDate} -> ${endDate}`)
        if(!battery || !startDate || !endDate) return

        const __minMax = {}
        if(startDate) {
            __minMax.min=+startDate
        }
        if(endDate) {
            __minMax.max=+endDate
        }
        setMinMax(__minMax)

        // console.log(`Times : ${Time.day(startDate).format()} -> ${Time.day(endDate).format()}`)
        Fetch(`/api/battery/${battery._id}/policy`, { 
                from: Time.day(startDate).format(),
                // Add day + 1 minute to include midnight at end
                to: Time.day(endDate).add(1, 'minute').format()
            }, setPolicy)

        Fetch(`/api/battery/${battery._id}/soc`, { 
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format()
        }, setSOC)

    }, [battery, startDate, endDate])

    useEffect(() => {
        if(!_haveValues(soc)) return
        console.log('[BatteryOptimisation] : SOC=', soc)
        // Prepare the dataset
        setSocDataset({
            dimensions: [ "timestamp", "soc"],
            source: soc.map(({timestamp, soc: __soc}) => ([
                +Time.localTime(timestamp),
                __soc
            ]))
        })
    }, [soc])

    useEffect(() => {
        if(!policy) return
        console.log('[BatteryOptimisation] : policy=', policy)
    }, [policy])

    useEffect(() => {
        if(!chart || !socDataset) return 

        setOption({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                }
            },
            legend: {
                orient: "horizontal",
                itemWidth: 10,
                itemHeight: 8,
                itemGap: 20,
                icon: "roundRect",
                textStyle: {
                    fontSize: 12
                }
            },
            // title: {
            //   left: 'center',
            //   text: 'Large Ara Chart'
            // },
            toolbox: {
                feature: {
                    dataZoom: {
                      title: {
                          zoom: 'Zoom',
                          back: 'Undo last zoom'
                      },
                      yAxisIndex: 'none'
                    },
                    restore: {
                        title: 'Reset zoom'
                    },
                    saveAsImage: {
                        title: 'Download as PNG'
                    }
                }
            },
            dataset: [
                socDataset
            ],
            xAxis: {
                type: 'time',
                boundaryGap: false,
                ...minMax
            },
            yAxis: {
                type: 'value',
                name: 'SoC (%)',
                min: 0, 
                max: 100,
                position: "right",
                boundaryGap: [0, '100%']
            },
            dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                },
                {
                  start: 0,
                  end: 100
                }
            ],
            series: [ 'soc' ].map(createSeries)
        })

        return () => {
            setOption(null)
        }
    }, [chart, socDataset, minMax])

    useEffect(() => {
        console.log('options = ', option)
    }, [option])



    if(!battery) return null

    if(!policy && !soc) {
        return (<div>Checking for optimisation</div>)
    }

    return <ReactEcharts
        ref={(e) => { setChart(e); }}
        notMerge={true}
        lazyUpdate={true}
        echarts={echarts}
        option={option}
        style={{ height: "350px", width: "100%" }}
    />
}