import React from "react";
//import moment from "moment";

export default function TariffRows({ tariff, editTariff, deleteTariff, valid_from, valid_to, tariff_id }) {
  // const date_to = moment(valid_to).format("DD-MM-YYYY")
  // const today = moment().startOf('day').toISOString();
  // const date_from = moment(valid_from).toISOString();
    //console.log("xxxx", date_from, today, date_from === today, date_from > today);
    // { moment(valid_from).format("DD-MM-YYYY")} -
    // { valid_to === null || valid_to === "" || valid_to === undefined
    //   ? ""
    //   : date_to}
  return (
    <tr>
      <td>
        {tariff.month_start} - {tariff.month_end}
      </td>
      <td>
        {tariff.weekday_start} - {tariff.weekday_end}
      </td>
      <td>
        {tariff.time_start} - {tariff.time_end}
      </td>
      <td>{tariff.price}</td>


    </tr>
  );
}
