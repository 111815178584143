import React, { useState, useEffect, useCallback } from 'react'
import Decimal from 'decimal.js-light'
import {Form, Col, InputGroup} from 'react-bootstrap'
import { noop } from '../../components/UIComponents'

const __default = {
    required: false, 
    minimum: Number.MIN_VALUE,
    maximum: Number.MAX_VALUE,
    default: 0,
    precision: 0
}

const VALID_INT=/^[+-]?[0-9][0-9]*$/,
    VALID_DECIMAL=/^([+-]?[0-9][0-9]*)|([+-]?[0-9]*\.[0-9][0-9]*)$/

export default function NumberControl({descriptor={}, value: __value=null, lg=3, onChange=noop, isValid=noop}) {
    const [props] = useState({
        ...__default,
        ...descriptor,
        placeholder: descriptor.label
    })
    const [value, setValue] = useState(0)
    const [error, setError] = useState(null)

    const errorCheck = useCallback((value="") => {
        const {required, label, pattern, precision, minimum, maximum } = props
        const __v = typeof(value)==='number'?value.toString():value
        if(required && __v.trim().length===0) {
            return `${label} required`
        }
        if(!pattern.test(__v)) {
            return precision>0?
                "Valid number required":
                "Valid whole number required"

        }
        const __val = Decimal(__v)
        if(__val.lt(minimum)) {
            return `Must be >= ${minimum}`
        }
        if(__val.gt(maximum)) {
            return `Must be <= ${maximum}`
        }
        return false
    }, [props])

    useEffect(() => {
        const { name, precision, default: __dflt=0 } = props

        const __v = __value===null?__dflt:__value
        props.pattern = precision>0?VALID_DECIMAL:VALID_INT
        // setPattern(pattern)

        // Do initial validity check
        isValid(name, errorCheck(__v.toString()))
        setValue(__v)
    }, [props])

    const __onChange = useCallback((e) => {
        const { value="" } = e.target
        setValue(value)
        setError(errorCheck(value))
    }, [props])

    useEffect(() => {
        if(typeof(value)==='string' && (value.trim()==="" || isNaN(value))) {
            onChange(undefined)
        }
        else {
            onChange(Number(value))
        }
    }, [value])

    useEffect(() => {
        if(error===null) return
        isValid(props.name, error)
    }, [error])

    return props && (<Form.Group as={Col} lg={lg} controlId={`input_${props.name}`}>
        <Form.Label>{`${props.required?'(*) ':''}${props.label}`}</Form.Label>
        <InputGroup>
            {props.pre && <InputGroup.Text>{props.pre.symbol}</InputGroup.Text>}
            <Form.Control
                type="text"
                placeholder={props.placeholder}
                onChange={__onChange}
                value={value} 
                isInvalid={!!error}/>
            {props.post && <InputGroup.Text>{props.post.symbol}</InputGroup.Text>}
        </InputGroup>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>)
}