import {createContext} from 'react'
import { noop } from '../../components/UIComponents'
import {Notify} from '../../components/AlertListener'
import {Request} from "../../../services/HttpService";


const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")
const enotify=Notify("add.battery.notifications")

export const diffObject = (a={}, b={}, path='') => {
    // console.log(`${path}A : `, a)
    // console.log(`${path}B : `, b)
    const keysA = Object.keys(a),
        keysB = Object.keys(b),
        deleted = [],
        __diff = {}

    for(const k of keysA) {
        const bNdx = keysB.indexOf(k)
        if(bNdx>=0) {
            // Key does exist on the other side
            const valA=a[k], typeA=typeof(valA),
                valB=b[k], typeB=typeof(valB)
            if(typeA!==typeB) {
                // Type has changed
                // console.log(`Added "${k}" = ${valB}`)
                __diff[k]=valB
            }
            else {
                switch(typeA) {
                    case 'string':
                    case 'number':
                    case 'boolean':
                        if(valA!==valB) {
                            // console.log(`Added "${k}" = ${valB}`)
                            __diff[k] = valB
                        }
                        break
                    case 'object':
                        if(Array.isArray(valA)) {
                            console.error('TBD : Arrays not handled')
                        }
                        else {
                            const __do = diffObject(valA, valB, `${path}${k}.`)
                            const { $$deleted: __del=[], ...obj} = __do
                            // Add any deleted parameters
                            if(__del.length>0) {
                                deleted.push(...__del)
                            }
                            if(Object.keys(obj).length>0) {
                                __diff[k] = obj
                            }
                        }
                        break
                    case 'undefined':
                        if(typeB!==undefined) {
                            __diff[k] = valB
                        }
                        break
                    default:
                        console.error(`Not supported type ${typeA} for attribute "${k}"`)
                        break 
                }
            }
            keysB.splice(bNdx,1)
        }
        else {
            deleted.push(`${path}${k}`)
        }
    }
    // Add any new keys from B
    // console.log('Remaining : ', keysB, __diff)
    for(const bk of keysB) {
        // console.log('bk : ', bk, b[bk])
        const valB = b[bk]
        if(typeof(valB) !== 'undefined' && valB!==null) {
            __diff[bk] = b[bk]
        }
    }
    if(deleted.length>0) {
        __diff.$$deleted = deleted
    }
    // console.log('diff : ', JSON.stringify(__diff))
    return __diff
}

const NO_CONFIG = {
    properties: [],
    config: {}
}

export function EditContext({battery, canSave=noop, preChange=noop, postChange=noop} = {}) {
    const {config={}}=battery,
        initial = {...battery, config: {...config}}
    
    const __errors = {}

    const errors = () => Object.fromEntries(Object.entries(__errors).filter(([k,v]) => v!==false))

    const hasErrors = () => Object.entries(__errors).filter(([k,v]) => v!==false).length>0 

    const __canSave = () => Object.keys(diffObject(initial, battery)).length>0 
        && Object.values(__errors).filter(v => v!==false).length===0

    const update = (attr, val) => {
        // console.log(`update(${attr}, ${val})`)
        preChange(attr, val, battery[attr])
        if(typeof(val)!=='undefined') {
            battery[attr] = val
        }
        else {
            delete(battery[attr])
        }
        // console.log('Cansave : ', Object.keys(diffObject(initial, battery)).length>0, Object.values(__errors).filter(v => v!==false).length===0)
        preChange(attr, val)
        canSave(__canSave())
    }

    const onError = (name, err) => {
        if(err===false) {
            delete __errors[name]
        }
        else {
            __errors[name] = err
        }
        canSave(__canSave())
    }
    
    return {
        initial, 
        battery,
        update,
        errors,
        onError,
        hasErrors
    }
}

// Load the property config


const BatteryContext = createContext({
    battery: null,
    initial: null,
    configs: new Map(),
    canSave: () => false,
    onError: noop,
    errors: () => {},
    hasErrors: () => false
})

export default BatteryContext