import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { ConfirmAlert, Overlay, noop } from '../../components/UIComponents'
import {Request} from "../../../services/HttpService";
import {AlertContainer, Notify} from '../../components/AlertListener'
import BatteryContext, {EditContext, diffObject} from './battery-context'
import Page1 from './AddPage1'
import Page2 from './AddPage2'
import Page3 from './AddPage3'
import { isEmpty } from 'lodash'

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")
const enotify=Notify("battery.notifications")


export default function EditBatteryForm({id=null}) {
    const [battery, setBattery] = useState(null) 
    const [confirmRequired, setConfirm] = useState(false)
    const [config, setConfig] = useState(null)
    const [canSave, enableSave] = useState(false)
    const [context, setContext] = useState(null)
    const cancelRef = useRef()

    const history = useHistory() 

    const keyUpHandler = useCallback(event => {
        if(event.key==='Escape') {
            // Use ESC to click cancel - button ref is cleanest way to ensure 
            // context is in scope
            cancelRef.current.click()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler)

        return () => {
            window.removeEventListener('keyup', keyUpHandler)
        }
    }, [])

    const done = () => {
        history.push('/dashboard/settings/battery')
    }

    useEffect(() => {
        if(!id) {
            return done()
        }
        const __loadBattery = async () => {
            try {
                const __battery = await request.get(`/api/battery/${id}`)
                setBattery(__battery)
            }
            catch(err) {
                notify.error(`Error loading battery - ${err.message}`)
                console.error(err)
                done()
            }
        }

        __loadBattery()

    }, [id])

    useEffect(() => {
        if(battery===null) return
        const __configs = new Map()

        setContext(EditContext({battery, canSave: enableSave, preChange: (name, value) => {
            if(name==='model' && battery.model !== value) {
                if(battery.model && battery.model.trim().length>0) {
                    // Archive old config
                    __configs.set(battery.model, battery.config)

                    // Restore/set new config
                    const __newConf = {...__configs.get(value) || {}}
                    battery.config = __newConf
                    setConfig(__newConf)
                }
            }
        }}))
        setConfig(battery.config || {})
    }, [battery])

    async function onSave() {
        // Save the battery
        const { _id, make, model, name, capacity, active, valid_from, valid_to, config } = battery,
            data = {  
                make, model, name, capacity, active, valid_from, valid_to, config
            }
        try {
            await request.call(`/api/battery/${_id}`, 'put', { data })
            notify.info(`Updated battery "${name}"`)
            done()
        }
        catch(err) {
            console.error('Error saving - ', err)
            enotify.error(`Error saving battery - ${err.message}`)
        }
    }

    function onCancel(e) {
        if(context===null || e===true || isEmpty(diffObject(context.initial, battery))) {
            done()
        }
        else {
            setConfirm(true)
        }
    }

    return context && (<Container fluid>
        <Row><Col><h4>Edit Battery</h4></Col></Row>
        <AlertContainer tag="edit.battery.notifications" page="battery"/>
        <Row><Col><em>(*) - required field</em></Col></Row>
        { context && (<BatteryContext.Provider value={context}>
            <Form noValidate onSubmit={onSave}>
            
                <h5>Details</h5>
                <Page1/>
                <Page2/>
                <h5>Connection Configuration</h5>
                <Page3 config={config}/>
            
            <Form.Row>
                <Col lg="6">
                    {confirmRequired?(<ConfirmAlert
                        heading="Details have changed"
                        text="Battery details have changed, really cancel?"
                        buttonsInline={false}
                        onNo={() => setConfirm(false)}
                        onYes={() => onCancel(true)}/>
                    ):
                    (<div className="btn-right">
                        <Button 
                            type="button" 
                            ref={cancelRef}
                            variant="outline-secondary" 
                            onClick={onCancel}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-times"/>Cancel
                        </Button>

                        <Button 
                            id="btnSave" 
                            disabled={!canSave} 
                            type="button" 
                            onClick={onSave} 
                            variant="re-primary">
                            <i className="fas fa-save"/>Save
                        </Button>
                    </div>)}
                </Col>
            </Form.Row>
        </Form></BatteryContext.Provider>)}
    </Container>)
}