import React from 'react';
import Dropdown from "./Dropdown2";

export default class TopDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: this.props.list
    };
  }

  render() {
    const {view, viewList, index_site, sites, members} = this.props;
    //console.log('props', viewList, view)

    return (
      <div >

      {view === "Site" ? (
        <div>
          <h4>View</h4>
          <select className="dropdown"  value={view} onChange={this.props.onViewSelected}>
            {viewList.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <h4>Site</h4>
          <Dropdown
            index={index_site}
            list={sites}
            onChange={this.props.onSiteSelected}
          />
        </div>
      ) : view === "Cluster" ? (
        <div>
          <h4>View</h4>

          <select className="dropdown" value={view} onChange={this.props.onViewSelected}>
            {viewList.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <h4>Organisation</h4>
          <select className="dropdown" onChange={this.props.onOrgSelected}>
            {members.map((option, index) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div>
          <h4>View</h4>
          <select className="dropdown" value={view} onChange={this.props.onViewSelected}>
            {viewList.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      </div>
    )
  }
}
