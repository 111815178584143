import React, {useState, useContext, useCallback, useEffect, Component, useRef} from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Request} from "../../services/HttpService"
import {Notify} from '../components/AlertListener'
import socketIOClient from "socket.io-client";
import ClusterGraph from './ClusterFlowGraph';
import io from "socket.io-client";

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")


export default function  ClusterFlow() {

  const [organisation, setOrganisation] = useState(null)
  const [flowData, setFlowData] = useState({nodes:[],links:[]})

  const [cluster, setCluster] = useState(null)
  const stDateRef = useRef(0)
  const uDataRef = useRef([])
  const infDataRef = useRef([])
  const infLevelRef = useRef(0)
  const miRef = useRef([])
  const origDataRef = useRef([])
  const initDataCluster = useRef(0)


  const { user } = useContext(UserContext)
  const [response, setResponse] = useState("");
  const [FData, setFData] = useState({})
  const fgRef = useRef();

  const initData = {nodes:[],links:[]}
  const wl = window.location   
  const surl = `${wl.protocol}//${wl.host}`


  useEffect(() => {
    if(user===null || !user.clusterId) return ;

    const { clusterId : id } = user

    async function getCluster() {
        try {
            const c = await request.get(`/api/cluster/${id}`)
            setCluster(c)
        }
        catch(e) {
            console.error(e)  
            notify.error(e.message)
        }
    }

    getCluster()

    return () => {
        setCluster(null)
    }
  }, [user])

  const clusterData = (dta) => {
    //console.log("dta: ", dta)
    setFlowData(dta)
  }


  async function processCMeters(mdata, cMeters, level, topLevel){
    if(mdata.hasOwnProperty('meters')){
      mdata.meters.forEach(async mt => {
        if(topLevel<level+1){
          topLevel = level+1
        }
        //console.log("topLevel: ", topLevel)
        let meter = {}
        meter.id = mt._id
        meter.meter_type = mt.meter_type
        meter.name = mt.name
        meter.description = mt.description
        meter.group = "meter"
        meter.additive = []
        if(mt.hasOwnProperty('config')){
          if(mt.config.hasOwnProperty('additive')){
            mt.config.additive.forEach(async cnf => {
              meter.additive.push(cnf._id)
            })         
          }
        }
        if(mt.reading.hasOwnProperty('reading')){
          meter.reading = Number(mt.reading.reading.toFixed(3))
          if(meter.reading === 0){
            meter.reading = 0.001
          }
        }else{
          meter.reading = 0.001
        }
        meter.level = topLevel
        //console.log(`Meter: ${JSON.stringify(meter)}`)
        cMeters.push(meter)
        if(mt.hasOwnProperty('meters')){
          //console.log("")
          processCMeters(mt, cMeters, level+1, topLevel)
        }
      })    
    }
    //console.log("cmeters: ", cMeters)
    return {topLevel:topLevel+1}
  }

  async function addClusterLabel(cdata, cMeters){
    let cLabel = {}
    cLabel.level = 1
    cLabel.id = cdata.id
    cLabel.name = cdata.name
    if(cdata.name.includes("-")){
      let cNameA = cdata.name.split(" - ")
      cLabel.description = `${cNameA[0]} Cluster`
    }
    cLabel.reading = 0
    cLabel.group = 'cluster'
    cLabel.additive = []
    cLabel.meter_type=['cluster_label']
    cMeters.forEach(async cm => {
      if(cm.level===2){
        cLabel.additive.push(cm.id)
      }
    })

    cMeters.push(cLabel)

    let mmeters = cMeters.slice()
    //console.log("mdata meters: ", mmeters)
    return {mmeters : mmeters}


  }
  

  async function checkMtrs(mtrs, nodes, links, topLevel, cData){
    //console.log(`mtrs: ${JSON.stringify(mtrs)}`)
      
    for(let i=1;i<=topLevel;i++){
      mtrs.forEach(async mtr => {
        if(mtr.level === i){

          mtr.icon_type = "imgMeter1"
          if(mtr.additive.length>0){
            //console.log(mtr.additive)
            //let val = 0
            mtr.additive.forEach(async mad => {
              let m = mtrs.find(mr => {
                return mr.id === mad
              })
              if(m !== undefined){
                if(m.hasOwnProperty('reading')){
                  //val += m.reading

                  if(m.reading>=0){
                    let lnk = {source:mtr.id,target:m.id,value:m.reading*2}                    
                    links.push(lnk)
                  }else{
                    let lnk = {source:m.id,target:mtr.id,value:m.reading*-2}                   
                    links.push(lnk)
                  }
                }        
              }  
            })
            
            //mtr.reading = Number(val.toFixed(3))       
          }
          if(mtr.meter_type.includes("virtual") && mtr.level!== 2){
            mtr.icon_type = "imgMeter2"
          }else if (mtr.level=== 2){
            //console.log(`mtr2: ${JSON.stringify(mtr)}`)

            mtr.icon_type = "imgCMeter"
          }else if (mtr.meter_type.includes("cluster_label")){
            mtr.icon_type = "imgClusterLabel"
          }else if (mtr.meter_type.includes("storage")){
            mtr.icon_type = "imgBattery"
          }else if (mtr.meter_type.includes("supply")){
            mtr.icon_type = "imgSolar"
          }else{
            mtr.icon_type = "imgMeter1"
          }

          if(mtr.hasOwnProperty('description')){

            if(mtr.description !== undefined && mtr.level!== 1){
              if (mtr.description.includes('\"')) { 
                let descA = await mtr.description.split('\"')
                let descS = descA[1].replace(/"/g, '')
                if(mtr.meter_type.includes("supply")){
                  mtr.label = `${descS} Supply`
                }else{
                  mtr.label = `${descS} Load`
                }
              }else if (mtr.description.includes('Virtual Meter for ')) { 
                let descS = mtr.description.replace(/Virtual Meter for /g, '')
                if(mtr.meter_type.includes("supply")){
                  mtr.label = `${descS} Supply`
                }else{
                  mtr.label = `${descS} Load`
                }
              }else{
                if(mtr.meter_type.includes("supply")){
                  mtr.label = `${mtr.description} Supply`
                }else{
                  mtr.label = `${mtr.description} Load`
                }
              }
            }else{
              if(mtr.meter_type.includes("supply")){
                mtr.label = `Cluster Supply`
              }else{
                mtr.label = `Cluster Load`
              }

            }
          }else{
            mtr.label = `${cData.name} ${mtr.name}`

          }


          if (mtr.label.includes('Údarás na Gaeltachta')) { 
            mtr.label = mtr.label.replace(/Údarás na Gaeltachta/g, 'Údarás')
          }
        
          nodes.push(mtr) 
        }     
      })
    }   
  }




  const __loadInitFlowData = useCallback(async (_id, initData) => {

    //console.log(`Sorting Init Data`)
    //setInfData(initData)
    //infDataRef.current = initData
    let nmid = {}
    let processedData = {}
    let nodes=[]
    let links=[]
    let sites = []
    let cMeters = []
    let meters = []
    let level = 1
    let topLevel = 1
    

    let processCmeters = await processCMeters(initData.cluster, cMeters, level, topLevel)

    //console.log(`cMeters: ${JSON.stringify(cMeters)}`)
    //console.log(`processCmeters.topLevel: ${JSON.stringify(processCmeters.topLevel)}`)

    let addClustLabel = await addClusterLabel(initData.cluster, cMeters)


    //console.log(`cMeters: ${JSON.stringify(cMeters)}`)




    // let processorgmtrs = await processOrgMtrs(initData.cluster, sites, meters, processCmeters.topLevel)
    origDataRef.current = addClustLabel.mmeters
    infLevelRef.current = processCmeters.topLevel
    initDataCluster.current = initData.cluster
    //console.log(`mmeters ${JSON.stringify(addClustLabel.mmeters)}`)
    let checkmtrs = await checkMtrs(cMeters, nodes, links, processCmeters.topLevel, initData.cluster)
    processedData.nodes = nodes
    processedData.links = links

    clusterData(processedData)

  });

  const processUpdates = useCallback(async () => {
      let uDataInfo = uDataRef.current
      //console.log(`uDataInfo ${JSON.stringify(uDataInfo)}`)
      let updData = origDataRef.current
      //console.log(`updData ${JSON.stringify(updData)}`)
      updData.forEach(async (ud, iv) => {

        uDataInfo.forEach(async (udi, ui) => {
          if(udi._id === ud.id){
            if(Number(udi.reading.toFixed(3)) === 0){
              updData[iv].reading = 0.001
            }else{
              updData[iv].reading = Number(udi.reading.toFixed(3)) 
            }           
          }
        })
      })
  
      let processedData = {}
      let resetData = {}
      let nodes=[]
      let links=[]
      let tLevel = infLevelRef.current
      let oDataC = initDataCluster.current
      //console.log("updData ", updData)
      let checkmtrs = await checkMtrs(updData, nodes, links, tLevel, oDataC)
      uDataRef.current = []
      processedData.nodes = nodes
      processedData.links = links
      //console.log("processed2", processedData)
      clusterData(processedData)  
    })
  
    const midData = (nodes) => {
      let nde = []
      nodes.map(async nd => {
        nde.push(nd.id)
      })
      miRef.current = [...miRef.current , ...nde]
    }
   
    useEffect(() => {
      midData(flowData.nodes)
    }, [flowData.nodes])

  const __updateFlowData = useCallback(async (_id, updateData) => {


    //console.log(`updateData: ${JSON.stringify(updateData)}`)

      let upData = uDataRef.current
      let stDate = stDateRef.current
      let tud = Date.now()

      if(stDate === 0){

        stDateRef.current = tud
        upData = updateData
        uDataRef.current = upData

      }else{

        if(upData.length < 1){

          upData = updateData
          uDataRef.current= upData
          //stDateRef.current = tud

        }else{

          // problem might be here

          let idx = upData.findIndex(el => {
            return updateData._id === el._id 
          })

          if(idx < 0){
            upData = updateData
            uDataRef.current = upData
            
          }else{
          
            if(upData[idx].timestamp < updateData[idx].timestamp){
                              
                upData = updateData
                uDataRef.current = upData

            } 
          }
        }
        //console.log(` diff ${tud - stDate}`)
        if(((tud-stDate)/1000)>60){

          let pud = await processUpdates()

          stDateRef.current = 0
          uDataRef.current = []

        }
      }

  })

  // const __loadInitialFlowData = useCallback(async (_id, initialData) => {

  //   clusterData(initialData)

  // });


  // const __someAPIwithData = useCallback( async () => {
  //   // TODO

  // }, [flowData])



  useEffect(() => {
    if(!cluster) return

    //console.log(`Cluster: ${cluster}`)
    
    console.log('Connecting to socket')
    //localStorage.debug = '*'
    const socket = socketIOClient(surl, {
      path: '/api/meter/ws'
    });
    socket.on ("connect", () => {
      console.log("CONNECTED to Socket")
      socket.emit("SubscribeCluster", cluster._id)
    })
    socket.on ("ClusterInitial", data => {
      //console.log(`ClusterInitial: ${JSON.stringify(data)}`)
      __loadInitFlowData(cluster._id, data)

    })
    socket.on("ClusterDataUpdate", data => {
      //console.log(`updated cluster data: ${JSON.stringify(data)}`)

      __updateFlowData(cluster._id, data.readings)

    })
    socket.on("CMHandler", data => {
      //console.log("updated data", data)
      socket.emit("ClusterMessageHandler", data)
    })
    socket.on("FromAPI", data => {
      setResponse(data);
    });

    const cookieVal = JSON.parse(localStorage.getItem('JSESSIONID')) ;
    //console.log(`**USER** ${JSON.stringify(user)}`)

    // Run when component dismounts to close the socket
    return () => {
      console.log("Closing socket....")
      socket.disconnect();  
      //setFlowData({"nodes":[],"links":[]})
    }
  }, [cluster]);

  return cluster && (
    <Container fluid>
      <h2>Flow - {cluster.name}</h2>
      <div>
            <ClusterGraph clusterData={flowData} />     
      </div>
     </Container>
  )

}
