import React, { Component } from "react";

class WholesaleDashboard extends Component {
	constructor(props) {
		super(props) ;
	}

	render() {
		return (
			<div>
				<h3>
					<span className="badge badge-secondary"><i className="fas fa-pencil-ruler"></i></span>&nbsp;
					Wholesale scenario dashboard under construction
				</h3>
				
			</div>
		)
	}
}

export default WholesaleDashboard ;