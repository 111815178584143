import axios from "axios";
import ErrorListener from "./ErrorListener"

const HTTP_STATUS_CODES = {
  // Retrieved from: https://www.restapitutorial.com/httpstatuscodes.html
  // STATUS_CODE: response_msg

  // Redundant, success responses;
  200: "",
  201: "",
  202: "",
  204: "",
  DEFAULT_SUCCESS: "Success",

  // Client errors;
  400: "Bad request",
  401: "Unauthorized access, invalid permissions",
  403: "Forbidden, invalid permissions",
  404: "Not found, invalid routing",
  408: "Request timeout",
  409: "Duplicate entry",
  DEFAULT_CLIENT_ERROR:
    "Client error, please try again with the correct credentials",

  // Server errors;
  500: "Internal server error",
  501: "Service not currently implemented",
  503: "Service currently unavailable, try again later",
  504: "Gateway timeout, try again later",
  DEFAULT_SERVER_ERROR: "Server error, please try again later. ",

  // Default message to display when given status code is not specified above;
  DEFAULT_ERROR: " Error occurred, please try again later"
};

// Utilities //

function getErrorResponse(statusCode = null, statusText = "") {
  /**
   * Retrieves the appropriate error response based on the status data given.
   *
   * @param {Number}	statusCode	The status code of the response.
   * @param {String}	statusText	Associated API error message with the status.
   *
   * @return {String}	Concatenated status' error message with description of status code.
   */

  if (statusCode === null || statusCode === undefined) {
    return HTTP_STATUS_CODES.DEFAULT_ERROR;
  }

  const statusResponse = HTTP_STATUS_CODES[statusCode],
    APIMessage = statusText;

  if (typeof statusResponse === "string") {
    return statusResponse + APIMessage;
  }

  if (statusCode < 500) {
    return HTTP_STATUS_CODES.DEFAULT_CLIENT_ERROR + APIMessage;
  } else if (statusCode < 600) {
    return HTTP_STATUS_CODES.DEFAULT_SERVER_ERROR + APIMessage;
  }

  return HTTP_STATUS_CODES.DEFAULT_ERROR + APIMessage;
}

// Method used to abstract axios call's error handling functionalities //

function handleAxiosCall(apiURL, _method = "get", toSend = {}) {
  /**
   * Makes an API call using axios.
   *
   * @param {String}	apiURL	URL of API to call.
   * @param {String}	_method	Type of HTTP request, i.e. 'get', 'put'
   * @param {Object}	toSend	Associated data to send with API call. Can contain 'headers', 'params' and 'data' objects.
   *
   * @return {Promise} A resolved or rejected promise of the API call, depending on the status of the request.
   */

  return new Promise((resolve, reject) => {
    let axiosBody = {
      method: _method.toLowerCase(),
      url: apiURL
    };

    const { headers, params, data } = toSend,
      requestConfig = { headers: headers, params: params, data: data };

    for (let requestType in requestConfig) {
      const request = requestConfig[requestType];

      if (typeof request !== "undefined" && request !== null) {
        axiosBody[requestType] = request;
      }
    }

    return axios(axiosBody)
      .then(rawResponse => {
        resolve(rawResponse);
      })
      .catch(error => {
        if (typeof error.response === 'undefined' && !error.response) {
          reject(error);
        }

        const errResponse = error.response,
          APIError = errResponse.data;

        let status = errResponse.status,
          statusText = errResponse.statusText;

        if(status === 401) {
            ErrorListener.raise(401, new Error('Not authorised'))
        }
        // Some APIs may return an APIError object upon an error.
        // See: https://gitlab-ee.tssg.org/smarter-aquaculture/sa-utils/blob/master/lib/error.js
        if (APIError && APIError.statusCode && APIError.message) {
          status = APIError.statusCode;
          statusText = APIError.message;
        }

        reject(getErrorResponse(status, statusText));
      });
  });
}

// API Calls //

const ClusterService = {
  serverURL: "/api",

  getAgregatedSupplyCluster(clsId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/aggregate/supply`,
      "get" , { params: { price: price,from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getAgregatedLoadCluster(clsId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/aggregate/load`,
      "get" , { params: { price: price,from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getPredictLoadCluster(clsId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/predict/load`,
      "get" , { params: { price,from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getPredictSupplyCluster(clsId, price = true, dateFrom_iso, dateTo_iso ) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/predict/supply`,
      "get" ,{ params: {price, from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getClusterList(_inactive = false) {
    /**
     * Gets a list of clusters.
     *
     * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(ClusterService.serverURL + "/cluster", "get", {
      params: { inactive: _inactive }
    });
  },

  getClusterId(clsId) {
    /**
     *
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}`,
      "get"
    );
  },

  getAllClusters(_inactive = true) {
    /**
     * Gets a list of clusters.
     *
     * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(ClusterService.serverURL + "/cluster", "get", {
      params: { inactive: _inactive }
    });
  },

  getClustersCadmin() {
    /**
     * Gets a list of clusters.
     *
     * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(
      ClusterService.serverURL + "/cluster/discover",
      "get"
    );
  },
  getClustersForCluswerOwner() {
    /**
     * Gets a list of clusters for cluster owner.
     *
     */
    return handleAxiosCall(ClusterService.serverURL + "/cluster", "get");
  },

  getRequests() {
    /**
     *
     */
    return handleAxiosCall(
      ClusterService.serverURL + "/cluster/request",
      "get"
    );
  },

  addCluster(cluster) {
    /**
     * Addsclusters.
     *
     * @param {Object} cluster		Cluster details to use for update.
     */
    return handleAxiosCall(ClusterService.serverURL + "/cluster", "put", {
      data: cluster
    });
  },

  setOwner(clsId, user) {
    /**
     *
     * @param {ObjectId} clsId
     * @param {Object} user
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/owner`,
      "put",
      { data: user }
    );
  },

  getRequestsOwner(clsId) {
    /**
     *
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/request`,
      "get"
    );
  },

  //api/cluster/{id}/request/{request_id}/accept

  acceptCluster(clsId, request_id, data) {
    /**
     *
     * @param {ObjectId} clsId
     * @param {ObjectId} request_id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/request/${request_id}/accept`,
      "put",
      { data: data }
    );
  },

  rejectCluster(clsId, request_id, data) {
    /**
     *
     * @param {ObjectId} clsId
     * @param {ObjectId} request_id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/request/${request_id}/reject`,
      "put",
      { data: data }
    );
  },

  getClusterMembers(clsId) {
    /**
     *
     * @param {ObjectId} clsId
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}/members`,
      "get"
    );
  },

  editCluster(clsId, cluster) {
    /**
     * Addsclusters.
     * @param {ObjectId} clsId
     * @param {Object} cluster		Cluster details to use for update.
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${clsId}`,
      "put",
      { data: cluster }
    );
  },

  sendJoinClusterRequest(id, data) {
    /**
     *
     *  @param {ObjectId} id
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/${id}/join`,
      "put",
      { data: data }
    );
  },

  deleteJoinClusterRequest(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${ClusterService.serverURL}/cluster/request/${id}`,
      "delete"
    );
  }
};

export default ClusterService;
