const Decimal = require('decimal.js');

const HUNDRED=Decimal(100)
const POINT_01=Decimal("0.0001")

const rndSort = () => (Math.floor(Math.random() * 3))-1

function getCandidates(eligible, v, exclude=[]) {
    return eligible.filter(({_id, locked, name, share}) => {
        if(locked || exclude.includes(_id)) return false
        if(v.isPositive()) {
            return share.gte(0)
        }
        else {
            return share.lte(100)
        }
    })
}



function Distribute(members=[], id, value=0) {
    // Value must be 0-100
    if(value<0) {
        value=0
    } else if(value>100) {
        value=100
    }
    value = Decimal(value)


    const __working = members.map(({_id, name, share, preciseValue=share, locked=false}) => 
        [ _id, {_id, name, share: Decimal(share), preciseValue: Decimal(preciseValue), locked}])

    const __memberMatch = __working.filter(([_id]) => id===_id),
        __member = __memberMatch.length>0?__memberMatch[0][1]:null ;
    if(__member===null || __member.locked) return members // Member ID not found, or member locked

    const __origValue = __member.preciseValue

    // Get amount to be distributed among other members
    let __toDistribute=__member.preciseValue.minus(value)
    if(__toDistribute.eq(0)) return members // Nothing to do

    __member.preciseValue = value

    const __others = __working.filter(([_id]) => _id!==id).map(([, data]) => data)
    // List of members to exclude later
    const __exclude = []

    let __candidates=getCandidates(__others, __toDistribute)
    if(__candidates.length===0) return members // Can do nothing

    let __passes=0,
        __distributing = !__toDistribute.abs().lt(0.01) && __candidates.length>0,
        // Track how much has been distributed
        __distributed = new Decimal(0)

    while(__distributing && __passes<10) {
        const __share = __toDistribute.div(__candidates.length)

        for(const __c of __candidates) {
            let __applicable=__share

            let __updatedShare = __c.preciseValue.plus(__applicable)
            if(__updatedShare.lt(0)) {
                __applicable=__c.preciseValue.neg()
            }

            __distributed = __distributed.plus(__applicable)
            __c.preciseValue = __c.preciseValue.plus(__applicable)
            if(__c.preciseValue.isZero() || __c.preciseValue.eq(HUNDRED)) {
                __exclude.push(__c._id)
            }
            __toDistribute = __toDistribute.minus(__applicable)
        }

        if(!__toDistribute.abs().lt(0.0001)) {
            // Haven't distributed everything
            __candidates=getCandidates(__candidates, __toDistribute, __exclude)
        }
        // Check whether to stop distributing
        __distributing = !__toDistribute.abs().lt(0.01) 
            && __candidates.length>0 
            && !__distributed.isZero()
        __passes+=1
    }

    // Round off the values
    // for(const __m of __others) {
    //     __m.share = __m.share.toDecimalPlaces(4)
    // }
    // Is there any residual?
    // Round to the nearest half
    const round = v => v.times(2).round().div(2)

    // Couldn't distribute everything - add it back to member
    __member.preciseValue = __member.preciseValue.add(__toDistribute)
    
    // Round them all off to the nearest .5
    __member.share = round(__member.preciseValue)
    // Figure out how much to distribute - residual in member plus total 
    // of residuals of any locked member
    __toDistribute = __member.preciseValue.minus(__member.share).plus(
        __others.reduce((t, m) => {
            if(!m.locked) return t
            return t.minus(m.preciseValue.minus(round(m.preciseValue)))
        }, Decimal(0))
    )

    for(const __m of __others) {
        if(!__m.locked) {
            const __adjusted = __m.preciseValue.minus(__toDistribute)
            __m.share = round(__adjusted)
            __toDistribute = __m.share.minus(__adjusted)
        }
        else {
            __m.share = round(__m.preciseValue)
        }
    }

    return __working.map(([_id, {share, ...rest}]) => ({_id, ...rest, share: share.toDecimalPlaces(1).toNumber()}))
}

export default Distribute