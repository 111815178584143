import React, { useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OrgTabView from '../OrgTabView'
import ClusterSolar from './ClusterSolar'
import SiteSolar from './SiteSolar'
import OrganisationSolar from './OrganisationSolar'

export default function Solar() {
    return (<Container fluid>
        <OrgTabView page="solar" 
            ClusterPage={ClusterSolar}
            OrganisationPage={OrganisationSolar}
            SitePage={SiteSolar}
            daterange/>
    </Container>)
}