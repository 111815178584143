import React, { Component } from "react";
import { notify } from "../../utility/ToastContainer";
import UserServices from "../../services/UserServices";
import { Alert } from "react-bootstrap";

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export default class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      fieldActive: false,
      oldpasswordActive: false,
      newpasswordActive: false,
      confirmPasswordActive: false,
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
      formValid: false,
      errorCount: 0,
      errors: {
        oldpassword: "",
        newpassword: "",
        confirmPassword: ""
      },
      variant: "",
      msg: "",
      show: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.innerRef.current.focus();
    }, 1);
  }

  activateField = e => {
    console.log();
    this.setState({
      [`${e.target.name}Active`]: true
    });
  };

  disableField = e => {
    this.setState({
      [`${e.target.name}Active`]: false
    });
  };

  onChange = e => {
    //console.log(event.target.value)
    const target = e.target;
    const value = target.value;
    const password = target.name;

    if (e.target.value === "") {
      this.disableField(e);
    } else {
      this.activateField(e);
    }

    // if (event.target.value !== '') {
    //   this.setState({fieldActive: true});
    // } else {
    //   this.setState({fieldActive: false});
    // }
    let errors = this.state.errors;
    switch (password) {
      case "oldpassword":
        errors.oldpassword =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      case "newpassword":
        errors.newpassword =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      case "confirmPassword":
        errors.confirmPassword =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [password]: value
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  changePassword = (event, errors, values) => {
    event.preventDefault();
    const { oldpassword, newpassword, confirmPassword } = this.state;
    const email = this.props.email;
  //  console.log("props email", email);
    // perform all neccassary validations
    if (newpassword !== confirmPassword) {
      //alert("Passwords don't match");
      this.showError("danger", "Passwords do not match");
    } else {
      let p = {
        password: oldpassword,
        newpassword: newpassword,
        email: email
      };
      //console.log('password', p)
     return UserServices.changePassword(p)
        .then(response => {
          //console.log("response", response);
          if (response.status === 204) {
            this.props.closeChangePassword(
              "success",
              "Password is updated successfully."
            );
            //this.closeForm();
          } else if (response.status === 401){
            // Conflict error found;
          //  console.log("response", response.statusText);
            this.props.closeChangePassword("danger", response.statusText);
          }
        })
        .catch(err => {
          console.log("err", err);
          this.props.closeChangePassword("danger", err);
        });
    }
  };

  // closeForm = event => {
  //   if (this.props.closeChangePassword) {
  //     this.props.closeChangePassword();
  //   }
  // };

  showError = (variant, msg) => {
    this.setState({ variant: variant, msg: msg });
    this.onShowAlert();
  };

  setShow = () => {
    this.setState({ show: false });
  };

  onShowAlert = () => {
    //console.log('onShowAlert')
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
    });
  };

  render() {
    const {
      errors,
      formValid,
      errorCount,
      confirmPasswordActive,
      newpasswordActive,
      oldpasswordActive,
      show,
      variant,
      msg
    } = this.state;

    return (
      <div>
        <div>
          {show === true ? (
            <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>
          ) : (
            <div></div>
          )}
          <form onSubmit={this.changePassword}>
            <div className="form-group">
              <div
                className="oldpassword"
                style={{ display: this.props.edit ? "block" : "none" }}
              >
                <div className="labelField">
                  <label className={oldpasswordActive ? "on" : ""}>
                    Current Password
                  </label>
                  <input
                    className="form-control"
                    type="password"
                    name="oldpassword"
                    id="oldpassword"
                    onChange={this.onChange}
                    placeholder="Current Password"
                    value={this.state.oldpassword}
                    ref={this.innerRef}
                  />
                  {errors.newpassword.length > 0 && (
                    <span className="error">{errors.oldpassword}</span>
                  )}
                </div>
              </div>
              <div className="labelField">
                <label className={newpasswordActive ? "on" : ""}>
                  New Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  required
                  name="newpassword"
                  id="newpassword"
                  onChange={this.onChange}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="New Password"
                  value={this.state.newpassword}
                />
                {errors.newpassword.length > 0 && (
                  <span className="error">{errors.newpassword}</span>
                )}
              </div>
              <div className="labelField">
                <label className={confirmPasswordActive ? "on" : ""}>
                  Confirm Password
                </label>
                <input
                  className="form-control"
                  type="password"
                  required
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={this.onChange}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Confirm Password"
                  value={this.state.confirmPassword}
                />
                {errors.confirmPassword.length > 0 && (
                  <span className="error">{errors.confirmPassword}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
