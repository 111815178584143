import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Container, Form } from 'react-bootstrap'
import { noop } from '../../components/UIComponents'
import BatteryContext from './battery-context';

import FormControl from './FormControl'

const NAME={
    type: "text",
    name: 'name', 
    label: 'Battery name',
    minLength: 2,
    required: true
}

const CAPACITY={
    type: "integer",
    name: 'capacity', 
    label: 'Capacity',
    post: { label: 'Watts', symbol: "W"}, 
    minimum: 1,
    default: 1,
    required: true
}

const ACTIVE={
    type: "boolean",
    name: "active",
    label: "Active",
    default: true,
    required: true
}

const VALID_RANGE={
    type: "date-range",
    name: ["valid_from", "valid_to"],
    label: ["Valid from", "Valid to"],
    endRequired: false,
    default: ["now"]
}


export default function AddPage2({canContinue=noop}) {

    const { battery, update, onError } = useContext(BatteryContext)

    const [validRange, setValidRange] = useState([battery.valid_from, battery.valid_to])
    const [errors, setErrors] = useState({})

    useEffect(() => {
        canContinue(false)
    }, [])

    useEffect(() => {
        canContinue(Object.values(errors).reduce((cc, e) => cc && !e, true))
    }, [errors])

    function setValid(name, error) {
        errors[name] = error
        setErrors({...errors})
        onError(name, error)
    }

    const __setDateRange=useCallback(([_start, _end]) => {
        setValidRange([_start, _end])
        update('valid_from', _start)
        update('valid_to', _end)
    })

    return (<Container fluid>
        <Form.Row>
            <FormControl descriptor={NAME} onChange={v => update('name', v)} isValid={setValid} value={battery.name}/>
        </Form.Row>
        <Form.Row className="align-bottom" >
            <FormControl descriptor={CAPACITY} onChange={v => update('capacity', v)} isValid={setValid} value={battery.capacity}/>
            <FormControl descriptor={ACTIVE} onChange={v => update('active', v)} isValid={setValid} value={battery.active}/>
        </Form.Row>
        <Form.Row>
            <FormControl descriptor={VALID_RANGE} as={String} onChange={__setDateRange} isValid={setValid} value={validRange}/>
        </Form.Row>
    </Container>)
}