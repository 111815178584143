import React, { useState, useEffect } from "react";
import { Button, Form, Card, InputGroup, Row, Col, Container } from 'react-bootstrap'
import {LockSwitch } from "../../../components/UIComponents"

import Distribute from '../../../../lib/distribute-value'

export default function SplitRenewables({settings={}}) {
    const [split, setSplit] = useState([])
    // const [ordering, setOrdering] = useState([])
    const [initial, setInitial] = useState({})
    const [canSave, enableSave] = useState(false)
    const [total, setTotal] = useState(100)

    useEffect(() => {
        if(settings===null) return 

        const __init = async () => {
            const __split = await settings.getEnergySplit()

            // setOrdering(Object.fromEntries(__split.map((s, i) => [s._id, i])))
            setInitial(JSON.stringify(__split))
            setSplit(__split)
        }

        __init()
    }, [settings])

    async function save() {
        try {
            await settings.setEnergySplit(split)
            setInitial(JSON.stringify(split))
            enableSave(false)
        }
        catch(error) {
            console.error(error)
        }
    }

    function reset() {
        setSplit(JSON.parse(initial))
        enableSave(false)
    }

    function updateShares(id, strValue) {
        let value = parseFloat(strValue)
        if(isNaN(value)) return 

        const __member = split.filter(({_id}) => _id===id)
        if(__member.length!==1) return 
        const { share } = __member[0]
        if(value<share) {
            if(value<1) {value=0}
        }
        else if(value>share) {
            if(value>99) value=100
        }
        const __updated = Distribute(split, id, value)
        setSplit(__updated)
        enableSave(initial !== JSON.stringify(__updated))
        setTotal(__updated.reduce((__tot, {_id, share}) => __tot+share, 0))
    }


    function toggleLocked(_id) {
        for(const s of split) {
            if(_id===s._id) {
                s.locked = !s.locked
                const __updated=[...split]
                enableSave(initial !== JSON.stringify(__updated))
                setSplit(__updated)
            }
        }
    }

    return (
        <Card>
        <Card.Header><h3>Renewables Share</h3></Card.Header>
        <Card.Body>
            <Container fluid><Row><Col md={6}>
            <Form noValidate>
                {split.map(({_id, name, locked, share}) => (
                <Form.Row key={`split_${_id}`} className="align-items-end">
                    {/* {console.log(`(${_id} : ${share})`)} */}
                    <Col md={1}>
                        <LockSwitch id={`locked_${_id}`}
                            locked={locked}
                            onChange={() => toggleLocked(_id)}/>
                        {/*
                        <Form.Check
                            id={`locked_${_id}`}
                            defaultChecked={locked}
                            onChange={() => toggleLocked(_id)}
                            type="switch"/>
                        */}
                    </Col>
                    <Col md={8}>
                        <Form.Label>{name}</Form.Label>
                        <Form.Control type="range" 
                                onChange={e => updateShares(_id, e.target.value)}
                                disabled={locked}
                                step={0.5}
                                value={share} custom/>
                    </Col>
                    <Col md={3}>
                        <InputGroup size="sm">
                            <Form.Control type="number" 
                                step={0.5}
                                min={0}
                                max={100}
                                disabled={locked}
                                onChange={e => updateShares(_id, e.target.value)}
                                value={share}/>
                            <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Row>) ) }
                <Row style={{marginTop: "0.5rem"}}>
                    <Col md={9} className="text-right">
                        <strong>Total : </strong>
                    </Col>
                    <Col md="auto">
                        {total.toFixed(2)}%
                    </Col>
                </Row>
                <div className="form-group save-cancel">
                  <Row>
                      <Col>
                      <Button 
                            type="button" 
                            onClick={save} 
                            variant="re-primary" 
                            disabled={!canSave}
                            style={{marginRight: "1rem"}}>
                                <i className="fas fa-save"/>Save
                        </Button>
                        <Button 
                            type="button" 
                            variant="outline-secondary" 
                            onClick={reset}>
                                <i className="fas fa-undo"/>Reset
                        </Button>
                      </Col>
                    </Row>
                </div>
            </Form>
            </Col></Row></Container>
        </Card.Body>
    </Card>)
}