import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {Alert, Button, Form, Container, Row, Col} from 'react-bootstrap'
import { EditButton, ConfirmAlert } from '../UIComponents.jsx'
import {Request} from "../../../services/HttpService";
import BootstrapTable from 'react-bootstrap-table-next';
import { Loading } from "../UIComponents.jsx"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import AuthService from "../../../services/auth";
import {friendlyTimeFormatter, Currencies, DEFAULT_CURRENCY} from "../../../utility";
import { Notify } from '../AlertListener.jsx'
import ElectricitySupplier from './electricity-supplier'
import moment from 'moment'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")

const ATTRS = { 
	"name": { minLength: 2, required: true },
	"plan_type": { required: true}, 
	"description" : {},
	"basis": { required: true},
	"currency": { required: true},
	"supplier_id": {}
}

const NEW_PLAN={
    name: "",
    plan_type: "",
    description: "",
    basis: "Tariff",
    currency: DEFAULT_CURRENCY,
    supplier_id: ""
}

const NO_TYPE=[["", '--select--' ]]
const TYPES = ['Core', 'Organisation', 'Cluster' ]

export default function EditBillingPlanForm({_id=null, root}) {
	const __type = new URLSearchParams(useLocation().search).get('type')			

	const [loading, setLoading] = useState(true)
	const [isEdit, setEdit] = useState(false)
	const [fields, setFields] = useState({...NEW_PLAN})
	const [initial, setInitial] = useState({})
	const [existingPeriods, setExistingPeriods] = useState([])
	const [validated, setValidated] = useState(false);
	const [dirty, setDirty] = useState(false)
	const [confirmNewPeriod, setConfirmNewPeriod] = useState(false)

	const [types, setTypes] = useState(NO_TYPE)

	const NO_SUPPLIER = [['', 'No supplier']]
	const [suppliers, setSuppliers]=useState(NO_SUPPLIER)

	const [redirect, setRedirect] = useState(null)
	const [confirmRequired, setConfirm] = useState(false)

	const __form = useRef()

	useEffect(() => {
		const __initTypes = async() => {
			try {
      			const __user = await AuthService.isAuthenticated();
      			const __valid = []
      			if(__user.isAdmin) __valid.push('Core')
  				if(__user.isOrgAdmin) __valid.push('Organisation')
  				if(__user.isClusterOwner) __valid.push('Cluster')

				if(__type!==null) {
					if(!TYPES.includes(__type)) {
						console.error('Invalid type ', __type)
						notify.error(`Invalid plan type '${__type}'`)
						setRedirect('')
					}
					else if(!__valid.includes(__type)) {
						notify.error(`Editing of plan of type '${__type}' not allowed`)
						setRedirect('')
					}
				}
				setTypes(NO_TYPE.concat(__valid.map(t => [t,t])))

    		} catch (err) {
    			console.error('Error ', err)
    			setTypes(NO_TYPE)
    		}
    	}


		const __fetchSuppliers = async() => {
			const __suppliers = await ElectricitySupplier()
			setSuppliers(__suppliers.getOptions())
		}

		__initTypes()
		__fetchSuppliers()
	}, [])

	useEffect(() => {
		async function __loadPlan() {
			try {
				const __plan = await request.call(`/api/billable/plan/${_id}?includePeriods`)
				// Copy the plan attributes
				const __initial = {
					name: __plan.name,
					plan_type: __plan.plan_type,
					description: __plan.description,
					basis: __plan.is_wholesale?'Wholesale':'Tariff',
					currency: __plan.currency,
					supplier_id: __plan.supplier_id
				}
				setInitial(__initial)
				setFields({...__initial})

				const {periods=[]} = __plan
				setEdit(true)
				setExistingPeriods(periods)
				setLoading(false)
			}
			catch(err) {
				console.error(err)
				notify.error(err.message)
				setRedirect('')
			}
		}

		if(_id===null) {
			const __initial = { ...NEW_PLAN, plan_type: __type===null?'':__type }
			setInitial({...__initial})
			setFields({...__initial})
			setEdit(false)
			setExistingPeriods([])
			setLoading(false)
		}
		else {
			__loadPlan()
		}

		return () => {
			setInitial({...NEW_PLAN})
			setFields({})
			setLoading(true)
			setEditRequest(null)
		}
	}, [_id])

	useEffect(() => {
		return () => {
			setRedirect(null)
		}
	}, [redirect])

	function hasChanged() {
		if(initial===null) return false
		return Object.keys(ATTRS).reduce((en, k) => en || initial[k]!==fields[k], false) 
	}

	function cancel(e) {
		if(e===true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			setRedirect('')
		}
		setConfirm(true)
	}

	const [errors, setErrors] = useState({})

	function isValid(name, value) {
		const {minLength=0, required=false} = ATTRS[name]
		let __err=false
		if(required && value.length===0) {
			__err = "Value required"
		}
		else if(minLength>0 && value.length<minLength) {
			__err = `Must be at least ${minLength} characters`
		}
		return  __err
	}

	function validate(__fields=fields) {
		const __validated = Object.entries(ATTRS).reduce((v, [name]) => {
			const __err = isValid(name, __fields[name])
			errors[name] = __err
			return v && __err===false
		}, true)
		setErrors({...errors})
		return __validated
	}

	async function save(event, onRedirect=() => setRedirect('')) {
		if(event) {
  			event.preventDefault();
  			event.stopPropagation();
  		}
  		const { id: __btn} = event.target ;

  		const __isValid = validate()
  		setValidated(__isValid)
  		if(__isValid) {
  			// Save the plan
  			const { basis, supplier_id, ...data} = fields
  			data.is_wholesale = basis==="Wholesale"
  			if(supplier_id!=="") data.supplier_id=supplier_id 
  			const __url=isEdit?
  				`/api/billable/plan/${_id}`:
  				`/api/billable/plan?type=${data.plan_type}`
  			try {
				const __new = await request.call(__url, 
					'put',
					{ 
						data
					})
				notify.info(`${isEdit?"Updated":"Created"} billing plan "${data.name}"`)
				if(__btn==='btnPlanNext') {
					setRedirect(`/${__new._id}/edit`)
				}
				else {
					onRedirect()
				}
				return true
  			 }
  			 catch(err) {
  			 	console.error(err)
  			 	notify.error(err.message)
  			 }
  		}
  		return false 
	}

	useEffect(() => {
		const __d = hasChanged() 
		setDirty(d => __d)
	}, [fields])

	function onChange(e) {
		const {currentTarget: __form} = e 
		const { value="", name } = e.target ;
		fields[name] = value
		setFields({...fields})
		errors[name]=isValid(name, value)
		setErrors({...errors})
		setValidated(Object.values(errors).reduce((v,e) => v && e===false, true))
	}

	const history=useHistory() 

	function ChangesNotSaved({errors={}}) {
		const [canSave, setCanSave]=useState(true)

		const __url = `${root}/${_id}/period/${editRequest===null?'new':`${editRequest}/edit`}`

		useEffect(() => {
			setCanSave(Object.values(errors).reduce((v,e) => v && e===false, true))

			return () => {
				setCanSave(true)
			}
		}, [errors])

		async function saveAndContinue(e) {
			const __saved=await save(e, () => history.push(__url))
		}

		return confirmNewPeriod?(
	        <Alert show variant="warning">
	            <Alert.Heading><i className="fas fa-exclamation-circle"/>Changes Not Saved</Alert.Heading>
	            {canSave?(<p>
	            	Changes to main plan not saved. Save changes before continuing?
	            </p>):(<React.Fragment>
	            	<p>Main plan has changed but has errors so cannot be saved</p>
	            	<p>Cancel to to fix, or discard and continue?</p>
	            </React.Fragment>)}
	            <hr/>
                <div className="d-flex justify-content-end">
                    <span style={{paddingRight: "1em"}}>
                        <Button type="button" onClick={e=> setConfirmNewPeriod(false) && setEditRequest(null)} variant="outline-secondary">
                        	<i className="fas fa-times"/>Cancel
                        </Button>
                    </span>
                    <span style={{paddingRight: "1em"}}>
                        <Button type="button" onClick={e=> history.push(__url)} variant="secondary">
                        	<i className="fas fa-trash"/>Discard
                        </Button>
                    </span>
                    {canSave?(<span>
                        <Button type="button" onClick={saveAndContinue} variant="re-primary">
                        	<i className="fas fa-save"/>Save and Continue
                        </Button>
                    </span>):null}
                </div>
	        </Alert>
		):null
	}

	const onNewPeriod = useCallback(() => {
		if(dirty) {
			setConfirmNewPeriod(true)
		}
		else {
			history.push(`${root}/${_id}/period/new`)
		}
	}, [history, dirty])

	// 
	// The following is a workaround to avoid stale state 
	// from the action buttons in the table formatter below
	//
	const [editRequest, setEditRequest]=useState(null)
	useEffect(() => {
		if(editRequest===null) return
		if(dirty) {
			setConfirmNewPeriod(true)
		}
		else {
			history.push(`${root}/${_id}/period/${editRequest}/edit`)
		}
	}, [editRequest, dirty, history])

	if(redirect!==null) {
		return (<Redirect to={`${root}${redirect}`}/>)
	}

	if(loading) {
		return (
			<Container fluid>
				<Row>
					<Col style={{height: "100px"}}>
						<Loading active={true}/>
					</Col>
				</Row>
			</Container>
		)
	}

	const formatter = friendlyTimeFormatter()
	function formatDay(ts) {
		return (<span>{ts?moment(ts).format('DD/MM/YYYY'):''}</span>)
	}

	const periodColumns = [{
	  dataField: '_id',
	  text: 'Period ID',
	  hidden: true
	}, {
	  dataField: 'valid_from',
	  text: 'From',
	  formatter: formatDay,
	  sort: true
	}, {
	  dataField: 'valid_to',
	  text: 'To',
	  formatter: formatDay,
	  sort: true
	}, {
	  dataField: 'updated_at',
	  text: 'Modified',
	  sort: true,
	  formatter: formatter.format
	},{
		dataField: 'buttons',
		isDummyField: true,
		text: '',
		formatter: (cellcontent, row, rowIndex, __deleteId) => {
			return (<React.Fragment>
      			<EditButton label={`Edit period`} onClick={() => setEditRequest(row._id)} size="sm"/>
	      	</React.Fragment>)
		}
	}];

	const __props = {
		disabled: confirmNewPeriod
	}
	
	return (
		<React.Fragment>
			<h3>{isEdit?"Edit":"New"} Billing Plan</h3>
			<Form noValidate onSubmit={save} ref={__form}>
				<Form.Row>
    				<Form.Group as={Col} lg="4" controlId="planName">
      					<Form.Label>Name</Form.Label>
  						<Form.Control required 
  							type="text" 
  							name="name"
  							{...__props}
  							minLength="2"
  							value={fields.name} 
  							onChange={onChange}
  							isInvalid={!!errors.name}
  							placeholder="Plan name"/>
  						<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
    				</Form.Group>
					<Form.Group as={Col} lg="2" controlId="planType">
						<Form.Label>Type</Form.Label>
						<Form.Control as='select' 
							name='plan_type' 
  							disabled={__props.disabled || __type!==null || _id!==null}
							value={fields.plan_type===null?'':fields.plan_type} 
							isInvalid={!!errors.plan_type}
							onChange={onChange}>
							{types.map(([v,t]) => (<option key={`__plantype_${v}`} value={v}>{t}</option>))}
						</Form.Control>
						<Form.Control.Feedback type="invalid">{errors.plan_type}</Form.Control.Feedback>
					</Form.Group>
  				</Form.Row>
  				<Form.Row>
    				<Form.Group as={Col} lg="6" controlId="planDescription">
      					<Form.Label>Description</Form.Label>
  						<Form.Control 
  							type="text" 
  							name="description"
  							{...__props}
  							value={fields.description} 
  							onChange={onChange} 
  							placeholder="Plan description"/>
    				</Form.Group>
  				</Form.Row>
  				<Form.Row>
					<Form.Group as={Col} lg="2" controlId="planBasis">
						<Form.Label>Basis</Form.Label>
						<Form.Control as='select' 
							name='basis' 
  							{...__props}
							value={fields.basis===null?'-1':fields.basis} 
							isInvalid={!!errors.basis}
							onChange={onChange}>
								<option value="">--select--</option>
								<option value="Tariff">Tariff</option>
								<option value="Wholesale">Wholesale</option>
						</Form.Control>
						<Form.Control.Feedback type="invalid">{errors.basis}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} lg="2" controlId="planCurrency">
						<Form.Label>Currency</Form.Label>
						<Form.Control as='select' 
							name="currency"
  							{...__props}
							value={fields.currency} 
							isInvalid={!!errors.currency}
							onChange={onChange}>
							<option key='currency_none' value=''>--select--</option>
							{Currencies.toOptions()}
						</Form.Control>
						<Form.Control.Feedback type="invalid">{errors.currency}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} lg="2" controlId="planSupplier">
						<Form.Label>Supplier</Form.Label>
						<Form.Control as='select' 
							name='supplier_id' 
  							{...__props}
							value={fields.supplier_id} 
							onChange={onChange}>
							{suppliers.map(([v,t]) => (<option key={`__supplier_${v}`} value={v}>{t}</option>))}
						</Form.Control>
					</Form.Group>
  				</Form.Row>
  				{isEdit?(<Row>
  					<Col lg="6">
						<div>
							<h4 style={{display: "inline-block", marginRight: "1rem"}}>Periods</h4>
			    			<Button size="sm" onClick={onNewPeriod} variant="re-primary"><i className="fas fa-plus"/>New Period</Button>
						</div>
						<ChangesNotSaved errors={errors}/>
				        <BootstrapTable
				         	bootstrap4 striped hover condensed 
				         	version="4"
							keyField='_id' 
							columns={periodColumns}
							data={existingPeriods}
				        	noDataIndication="No periods defined"
				        	defaultSorted={[{dataField: 'valid_from', order: 'asc'}]}
				        />
  					</Col>
  				</Row>):null}
  				<Form.Row>
  					<Col lg="6">
	            		{confirmRequired?(<ConfirmAlert
          					heading="Details have changed"
          					text="Plan details have changed, really cancel?"
          					buttonsInline={false}
          					onNo={() => setConfirm(false)}
          					onYes={() => cancel(true)}/>
		            	):
			            (<div className="btn-right">
			            	<Button 
		            			type="button" 
		            			variant="outline-secondary" 
		            			disabled={__props.disabled}
		            			onClick={cancel}
		            			style={{marginRight: "1rem"}}>
		            			<i className="fas fa-times"/>Cancel
		            		</Button>
		            		{!isEdit?(<Button id="btnPlanNext" type="button" 
		            				disabled={!hasChanged() && __props.disabled} 
		            				onClick={save} 
		            				variant="primary"
		            			style={{marginRight: "1rem"}}>
								<i className="fas fa-angle-double-right"/>Next
							</Button>):null}
		            		<Button id="btnPlanDone" disabled={!dirty || !validated || __props.disabled} type="button" onClick={save} variant="re-primary">
								<i className="fas fa-save"/>Save {isEdit?"":"and finish"}
							</Button>
						</div>)}
  					</Col>
  				</Form.Row>
			</Form>
		</React.Fragment>
	)
}
