import React, {useState, useEffect, useCallback} from 'react'
import { ButtonToolbar, ButtonGroup, Button, Row, Col } from 'react-bootstrap'
import moment, { monthsShort } from 'moment'
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/dataZoom";

import SolarWidget from './SolarWidget'
import RevenueWidget from './RevenueWidget'

import { JAN1, DAYS_IN_MONTH, scaleWattHours, OPTIONS as Options, calculateAxisParams } from './utils'

// Use 2019 as don't want a leap year
const noop=() => {}

const MONTHS = moment.months()
const MONTHS_SHORT = monthsShort()

const XAXIS = {
	type: 'category',
	name: 'Day',
	nameLocation: "middle",
	nameTextStyle: {
		fontSize: 16,
		padding: [10, 0,0,0]
	},
	data: []
}

export default function MonthPlot({data=null, month:__month=null, type='dc', comparing=[], setMonth: setMonthCB=noop, setDay=noop}) {
	const [options, setOptions] = useState({ ...Options, xAxis: [XAXIS]})
	const [month, setMonth] = useState(__month)
	const [readings, setReadings] = useState(null)
	const [scaler, setScaler] = useState(null)
    const [chart, setChart] = useState(null)
	const [monthNdx, setMonthNdx] = useState(-1)
	const [billedAmount, setBilledAmount] = useState(null)

    useEffect(() => {
		if(chart===null || month===null) return ;
        if(data===null) return ;
		if(month===null) {
			console.error("'month' required" )
			return 
		}
		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

		const [yAxis]  = __opt.yAxis
        yAxis.show=true
        yAxis.max=0

		const __m = MONTHS_SHORT.indexOf(month)
		if(__m===-1) {
			console.error(`Invalid month "${month}"`) 
			return 
		}
		setMonthNdx(__m)

		const __firstDay = DAYS_IN_MONTH.reduce((tot, ndays, i) => tot+(i<__m?ndays:0), 0), 
			__lastDay = __firstDay + DAYS_IN_MONTH[__m]

		// Figure out the units/precision in the data
		const __rawSeries = [data, ...comparing].map(([__scenario, __data=null]) => {
			if(__data===null) {
				console.error(`Unexpected null value for __data when mapping raw series for scenario ${__scenario.name}`)
				return [__scenario, [], 0]
			}

			const __hourly = __data.hourly[type].slice(__firstDay*24, __lastDay*24) 
			const __prices = __data.hourly.prices?__data.hourly.prices.slice(__firstDay*24, __lastDay*24):null
			const __daily = []
			let __totalPrice = 0
			for(let i=0;i<__hourly.length; i+=24) {
				__daily.push(__hourly.slice(i, i+24).reduce((tot, v) => tot+v, 0))
				if(__prices) {
					const __p = __prices.slice(i, i+24).reduce((tot, v) => tot+v, 0)
					__totalPrice += __p
				}
			}
			const __m=__daily.reduce((max, v) => v>max?v:max, 0)
			return [__scenario, __daily, __hourly, __m, __prices?__totalPrice:null]
		}), 
			__max = __rawSeries.reduce((max, [,,,v]) => v>max?v:max, 0),
			__scaler = scaleWattHours(__max), 
			__axisParams = calculateAxisParams(__scaler.transform(__max))

		const series = __rawSeries.map(([scenario, __data]) => ({
			name: scenario.name,
			data: __data.map(__scaler.transform),
			xAxisIndex: 0,
			yAxisIndex: 0,
			emphasis: {
				focus: 'series'
			},
			type: 'bar'
		}))

		setReadings(__rawSeries.map(s => s[2]))
		setScaler(__scaler)

		if(__rawSeries.length>0) {
			const [,,,,__totalPrice] = __rawSeries[0]
			if(__totalPrice!==null) {
				setBilledAmount(__totalPrice/100)
			}
		}
		
		const __axis = []
		const __curr=moment.utc(JAN1).add(__firstDay, 'days')
		for(let i=0; i<DAYS_IN_MONTH[__m]; ++i) {
			__axis.push(__curr.format('Do'))
			__curr.add(1, 'day')
		}

		const legend = {
			top: '90%', 
			left: 'center',
			formatter: name => {
				if(!window.chrome) return name
				return name.length>20?
						`${name}              `:
						`${name}     `
			},
			data: series.map(s => s.name)
		}

        const title = {
            left: 'center',
            text: `Solar Output ${MONTHS[__m]} (${type==='dc'?'DC':'AC'})`
        }

		setOptions(o => ({
            ...__opt,
            title,
			legend,
			series,
			xAxis: [{...XAXIS, data: __axis}],
			yAxis: [{...yAxis, name: `Output (${__scaler.units})`, ...__axisParams}]
		}))
    }, [chart, month, type, data, comparing])


	const onClick = useCallback((e) => {
		setDay(moment(JAN1).add(moment.duration({months: monthNdx, days: e.dataIndex})))
	}, [monthNdx, setDay])

    const chartEvents = {
		'click': onClick
	}

    return (
		<>
			{monthNdx>=0?(<Row className="justify-content-md-center">
				<Col md="auto">
				<ButtonToolbar>
					<ButtonGroup size="sm" className="mr-2" >
						<Button type="button" onClick={() => setMonth(MONTHS_SHORT[0])} disabled={monthNdx===0} variant="outline-secondary">
							<i className="fas fa-angle-double-left"/>Jan
						</Button>
						<Button type="button" onClick={() => setMonth(MONTHS_SHORT[monthNdx===0?11:monthNdx-1])} variant="outline-secondary">
							<i className="fas fa-angle-left"/>{MONTHS_SHORT[monthNdx===0?11:monthNdx-1]}
						</Button>
					</ButtonGroup>
					<ButtonGroup size="sm" className="mr-2" >
						<Button type="button" onClick={() => setMonthCB(null)} variant="outline-secondary">
							<i className="fas fa-angle-up"/>Annual
						</Button>
					</ButtonGroup>
					<ButtonGroup size="sm" className="mr-2" >
						<Button type="button" onClick={() => setMonth(MONTHS_SHORT[monthNdx===11?0:monthNdx+1])} variant="outline-secondary">
							<i className="fas fa-angle-right"/>{MONTHS_SHORT[monthNdx===11?0:monthNdx+1]}
						</Button>
						<Button type="button" onClick={() => setMonth(MONTHS_SHORT[11])} disabled={monthNdx===11} variant="outline-secondary">
							<i className="fas fa-angle-double-right"/>Dec
						</Button>
					</ButtonGroup>
				</ButtonToolbar>
				</Col>
			</Row>):null}
			<Row>
				<Col md={10}>
					<ReactEcharts
						ref={(e) => { setChart(e); }}
						echarts={echarts}
						option={options}
						notMerge={true}
						onEvents={chartEvents}
						lazyUpdate={true}
						style={{ height: "50vh"}}/>
				</Col>
				{Array.isArray(readings) && readings.length>0?
				(<Col md={2}>
					<div className="component-container">
						<SolarWidget readings={readings[0]} scaler={scaler}/>
						<RevenueWidget amount={billedAmount}/>
					</div>
				</Col>):null}
			</Row>
		</>
	)
}
