import React, { Component } from "react";
import Graph, { CURRENCY_MINOR, CURRENCY_MAJOR } from "../DashboardGraphs.jsx";

export default class MarketGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currency,
      yName,
      predictedCost,
      actualCost,
      tariffs,
      charges,
      __wholesale,
      timeline
    } = this.props;
    return (
      <div className="marginPage">
        <div className="row small-gutters ">
          <div className="col-lg-12">
            <Graph
              modules={true}
              timeline={timeline}
              yName={yName}
              currency={currency}
              axes={{ left: [CURRENCY_MINOR], right: [CURRENCY_MAJOR] }}
              series={[
                predictedCost,
                tariffs,
                charges,
                actualCost,
                __wholesale
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
