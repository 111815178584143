import React, { useState, useEffect, useCallback } from 'react'
import { Form, Col, ProgressBar } from 'react-bootstrap'
import { noop } from '../components/UIComponents'
import {Request} from "../../services/HttpService"

const request = new Request({ service: 'Battery service'})

export default function ChunkedUploader({
    data=[],
    uri=null,
    batchSize=1000,
    onDone=noop,
    onError=noop,
    waitOnDone=1000,
    maxRequests=5,
    lg=6
}) {
    const [progress, setProgress] = useState(0)
    const [active, setActive] = useState(false)
    const [slices, setSlices] = useState([])
    const [uploaded, setUploaded] = useState(0)
    const [error, setError] = useState(null)

    useEffect(() => {
        if(Array.isArray(data) && data.length>0) {
            const __slices = []
            for(let ndx=0; ndx<data.length; ndx+=batchSize) {
                __slices.push(data.slice(ndx, ndx+batchSize))
            }
            setSlices(__slices)
        }

        return () => {
            setSlices([])
            setActive(false)
        }
    }, [data])

    useEffect(() => {
        if(slices.length===0) return
        // console.log(`[ChunkecUploader] slices=${slices.length}`)

        runQueue()

        return () => {
            setProgress(0)
        }
    }, [slices])

    useEffect(() => {
        setProgress(Math.round((uploaded/data.length)*100))
    }, [uploaded, data])

    useEffect(() => {
        if(error===null) return
        console.error('[error] : ', error)
        onError(error)
    }, [error])

    const runQueue = useCallback(async () => {
        const __requests = slices.map((slice, ndx) => ({
            ndx,
            slice,
            status: 'pending',
            request: null
        })),
            __promises=[]
        
        const __max = __requests.length>maxRequests?maxRequests:__requests.length

        const __context = {
            error: false, 
            running: 0,
            ndx: 0,
            totalSent: 0
        }

        async function sendRequest() {
            if(__context.error || __context.ndx>=__requests.length || __context.running>=__max) return
            // console.log(`[ChunkecUploader::sendRequest] (max=${__requests.length}) context=${JSON.stringify(__context)}`)

            // Next request
            const __req=__requests[__context.ndx]
            __context.ndx+=1
            __req.status = 'running'
            __context.running+=1
            __req.request = request.call(uri,
                'put',
                {
                    data: __req.slice
                })
                .then(() => {
                    __req.status='done'
                    __context.totalSent+=__req.slice.length
                    setUploaded(__context.totalSent)
                    __context.running-=1
                    // send next one - if any
                    // console.log(`[ChunkecUploader::sendRequest] - Request ${__req.ndx} done. Queueing next`)
                    sendRequest()
                })
                .catch(err => {
                    __req.status = 'error'
                    __context.error=err
                    __context.running-=1
                    setError(err)
                })
            __promises.push(__req.request)
        }

        setActive(true)
        let i=0
        // Send initial batch
        while(!__context.error && i++<__max) sendRequest()

        // Wait either until an error happens, or all requests are complete
        while(!__context.error && __context.ndx<__requests.length) {
            await Promise.all(__promises)
        }
        // console.log(`Done. Promises=${__promises.length}, slices=${__requests.length}`)
        setTimeout(() => {
            setActive(false)
            onDone(__context.error)
        }, waitOnDone)
    }, [slices])


    return active && (
        <Form.Row>
            <Col lg={lg}>
                <div>Uploaded {uploaded}/{data.length}</div>
                <ProgressBar animated variant={error?"danger":""} now={progress}/>
            </Col>
        </Form.Row>
    )
}