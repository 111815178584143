import React, { useContext} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Switch, Route, Redirect } from "react-router-dom";
import {AlertContainer} from '../../components/AlertListener'
import UserContext from "../../../services/user-context"
import BatteryList from "./BatteryList"
import EditBatteryForm from './EditBatteryForm'

export default function BatterySettings() {
    
    const {user}  = useContext(UserContext)

    return user && (<>
        <AlertContainer tag="battery.notifications" page="battery"/>
        <Container fluid>
            <Row>
                <Col>
                    <h3>Battery Settings</h3>
                </Col>
            </Row>
            <Switch>
                <Route exact key="list" path="/dashboard/settings/battery"><BatteryList/></Route>
                <Route exact key="edit" path="/dashboard/settings/battery/:id([a-fA-F0-9]{24})/edit"
                    render={({match}) => (
                        <EditBatteryForm id={match.params.id}/>
                    )}
                />
                <Route render={() => <Redirect to="/dashboard/settings/battery"/>}/>
            </Switch>
        </Container>
    </>)
}