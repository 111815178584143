import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap'
import { ConfirmAlert, Overlay, noop } from '../../components/UIComponents'
import UserContext from "../../../services/user-context"
import {AlertContainer, Notify} from '../../components/AlertListener'
import BatteryContext, {EditContext} from './battery-context'
import {Request} from "../../../services/HttpService";
import AddPage1 from './AddPage1'
import AddPage2 from './AddPage2'
import AddPage3 from './AddPage3'
import {isEqual} from 'lodash'


const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")
const enotify=Notify("add.battery.notifications")

const PAGES = [
    { title: 'Make/Model', component: AddPage1},
    { title: 'Details', component: AddPage2},
    { title: 'Connection', component: AddPage3}
]

// const INITIAL = {
//     make: "sonnen",
//     model: "eco803",
//     active: true,
//     name: 'test',
//     capacity: 1,
//     config: {
//         battery_url: 'http://192.168.1.1/',
//         mqtt_proxy: '/foo/bar',
//         api_key: 'ASDF'
//     }
// }

const INITIAL = {
    make: "",
    model: "",
    active: true,
    name: '',
    capacity: 1,
    config: {
    }
}

export default function AddBatteryWizard({onDone=noop}) {
    const { Time } = useContext(UserContext)

    const [confirmRequired, setConfirm] = useState(false)
    const [page, setPage] = useState(0)
    const [canContinue, setCanContinue] = useState(false)
    const [canSave, enableSave] = useState(false)
    const [context, setContext] = useState(null)
    const [config, setConfig] = useState(null)
    const [battery, setBattery]=useState({...INITIAL, valid_from: Time.localToDate(), config: {}})

    const cancelRef = useRef()

    const keyUpHandler = useCallback(event => {
        if(event.key==='Escape') {
            // Use ESC to click cancel - button ref is cleanest way to ensure 
            // context is in scope
            cancelRef.current.click()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler)

        return () => {
            window.removeEventListener('keyup', keyUpHandler)
        }
    }, [])

    useEffect(() => {
        setContext(EditContext({battery, canSave: enableSave}))
        setConfig(battery.config || {})

    }, [battery])

    async function onCancel(e) {
        if(context===null || e===true || isEqual(context.initial, battery)) {
            onDone()
        }
        else {
            setConfirm(true)
        }
    }

    async function onSave() {
        console.log('Saving ', battery)
        try {
            const {make, model, name, capacity, active, valid_from, valid_to, config={}} = battery
            const data={
                make,
                model,
                name,
                capacity,
                active,
                valid_from,
                valid_to,
                config}
            await request.call('/api/battery', 'put', { data })
            notify.info(`Created battery "${name}"`)
            onDone()
        }
        catch(err) {
            enotify.error(`Error saving battery - ${err.message}`)
        }
    }

    const nextPage = useCallback(async () => {
        setPage(page+1)
        // console.log('[next] : battery=', battery)
        setBattery({...battery})
    }, [page])

    const props={disabled: confirmRequired}

    const Page = PAGES[page].component

    return (<Overlay><Card>
            <Card.Body>
                <Card.Title>New Battery</Card.Title>
                    <Row>
                <Col style={{color: "#aaa", fontSize: "1.5rem"}}>
                    {PAGES
                        .map((p, i) => (<span key={`add_page_${i}`} style={{color: page===i?'green':'inherit'}}>{p.title}</span>))
                        .reduce((prev, curr, i) => 
                                [prev, 
                                (<span key={`add_page_sep_${i}`} style={{paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>&mdash;</span>), 
                                curr]) }
                </Col>
            </Row>
            <AlertContainer tag="add.battery.notifications" page="battery"/>
            <Row><Col><em>(*) - required field</em></Col></Row>
            { context && (<BatteryContext.Provider value={context}><Row style={{padding: "1rem"}}>
                <Col>
                    <Page battery={battery} canContinue={setCanContinue} config={config}/>
                </Col>
            </Row></BatteryContext.Provider> )}
            <Form noValidate onSubmit={onSave}>
               <Form.Row>
  					<Col lg="6">
	            		{confirmRequired?(<ConfirmAlert
          					heading="Details have changed"
          					text="Battery details have changed, really cancel?"
          					buttonsInline={false}
          					onNo={() => setConfirm(false)}
          					onYes={() => onCancel(true)}/>
		            	):
			            (<div className="btn-right">
			            	<Button 
		            			type="button" 
                                ref={cancelRef}
		            			variant="outline-secondary" 
		            			disabled={props.disabled===true}
		            			onClick={onCancel}
		            			style={{marginRight: "1rem"}}>
		            			<i className="fas fa-times"/>Cancel
		            		</Button>

		            		{ page>0 && <Button 
                                id="btnPrev" 
                                // disabled={!dirty || !validated || props.disabled} 
                                type="button" 
                                onClick={() => setPage(page-1)} 
		            			style={{marginRight: "1rem"}}
                                variant="primary">
								<i className="fas fa-chevron-left"/>Back
							</Button>}

		            		{ page<PAGES.length-1?
                            <Button 
                                id="btnNext" 
                                disabled={!canContinue} 
                                type="button" 
                                onClick={nextPage} 
                                variant="primary">
                                <i className="fas fa-chevron-right"/>Next
                            </Button>:
                            <Button 
                                id="btnSave" 
                                disabled={!canContinue} 
                                type="button" 
                                onClick={onSave} 
                                variant="re-primary">
                                <i className="fas fa-save"/>Save
                            </Button>}
                        </div>)}
  					</Col>
  				</Form.Row>
            </Form>
        </Card.Body>
    </Card></Overlay>)
}