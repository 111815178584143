import React, { Component } from "react";
//import Graph, { LOAD } from "../../utility/Graph.jsx";

export default class BatteryGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div>Battery Graph #Page under construction</div>
      </div>
    );
  }
}
