import React, { Component } from "react";
import { notify } from "../../utility/ToastContainer";
import HttpServiceSettings from "../../services/HttpServiceSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
//const locale = 'en';
//moment.locale(locale);

const hours = [];
for (let hour = 0; hour < 24; hour++) {
  hours.push({
    value: moment({ hour, minute: 0 }).format("HH:mm"),
    name: moment({ hour, minute: 0 }).format("HH:mm")
  });
}
//console.log('hours',hours)

const months = [];
var m = moment();
for (var i = 0; i < 12; i++) {
  months.push({
    value: m
      .month(i)
      .format("MMMM")
      .toUpperCase(),
    name: m.month(i).format("MMMM")
  });
}
//console.log('month', months)

const weekdays = [];
for (var j = 0; j < 7; j++) {
  weekdays.push({
    value: moment.weekdays(j).toUpperCase(),
    name: moment.weekdays(j)
  });
}
//console.log('weekdays', weekdays)

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

var dateNow = new Date();


export default class AddTariffForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month_start: "",
      month_end: "",
      weekday_start: "",
      weekday_end: "",
      time_start: "",
      time_end: "",
      price: 0,
      tariffs: [
        {
          month_start: this.props.month_start || "JANUARY",
          month_end: this.props.month_end || "DECEMBER",
          weekday_start: this.props.weekday_start || "MONDAY",
          weekday_end:this.props.weekday_end || "SUNDAY",
          time_start: this.props.time_start || "00:00",
          time_end: this.props.time_end || "00:00",
          price: this.props.price || 0
        }
      ],
      startDate: dateNow,
      endDate: "",
      today:dateNow,
      billable_id: "",
      type: "",
      formValid: false,
      errorCount: 0,
      errors: {
        price: "",
        month_start: "",
        month_end: "",
        weekday_start: "",
        weekday_end: "",
        time_start: "",
        time_end: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const {
        billable_id,
        type,
        billables,
        valid_from,
        valid_to,
        startDate,
        month_start,
        month_end,
        weekday_start,
        weekday_end,
        time_start,
        time_end,
        price,
      } = this.props;
      this.setState({
        billable_id: billable_id,
        type: type,
        valid_from: valid_from,
        valid_to: valid_to,
        billables: billables,
        startDate: startDate,
        month_start:month_start,
        month_end:month_end,
        weekday_start:weekday_start,
        weekday_end:weekday_end,
        time_start:time_start,
        time_end:time_end,
        price:price
      });
    }, 1);
  }

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  // closeForm = event => {
  //   this.props.closeForm();
  // };

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleAddTariff = () => {
    this.setState({
      tariffs: this.state.tariffs.concat([
        {
          month_start: "JANUARY",
          month_end: "DECEMBER",
          weekday_start: "MONDAY",
          weekday_end: "SUNDAY",
          time_start: "00:00",
          time_end: "00:00",
          price: 0
        }
      ])
    });
  };

  onChangeSelect = idx => e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const errors = this.state.errors;
    //console.log('value, name', value, name)
    switch (name) {
      case "price":
        errors.price =
          /\+|-/.test(value)
            ? "Price is not valid"
            : "";
        break;
      default:
        break;
    }

    const newTariff = this.state.tariffs.map((tariff, sidx) => {
      if (idx !== sidx) return tariff;
      if (name === "price") return { ...tariff, [name]: Number(value) };
      else return { ...tariff, [name]: value };
    });
    this.setState({ errors, tariffs: newTariff });

    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleRemove = idx => () => {
    this.setState({
      tariffs: this.state.tariffs.filter((s, sidx) => idx !== sidx)
    });
  };

  addTariff = (event, errors, values) => {
    event.preventDefault();
    const tariffs = this.state.tariffs;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const dateFrom_iso = moment(startDate)
      .startOf('day')
      .toISOString();
    const dateTo_iso = moment().toISOString();

    console.log("startDate- endDate", startDate, endDate);
    var tariff;
    if (startDate === null || startDate === "") {
      alert("Please enter valid from date");
    } else if (endDate === null || endDate === "") {
      tariff = {
        valid_from: dateFrom_iso,
        tariffs: tariffs
      };
      this.submitTariff(tariff);
    } else {
      if (dateFrom_iso > dateTo_iso) {
        alert("Please check the sequence of dates selected");
      } else {
        tariff = {
          valid_from: dateFrom_iso,
          valid_to: endDate,
          tariffs: tariffs
        };
        this.submitTariff(tariff);
      }
    }
  };

  submitTariff = tariff => {
    const { type, billable_id, tariff_id } = this.props;
    console.log("added tariff", tariff);
    if (type === "edit") {
      return (
        HttpServiceSettings.editTariff(billable_id, tariff_id, tariff)
          .then(response => {
            if (response.data) {
              this.props.closeForm("success","Tariff is updated successfully.");
              //this.closeForm();
              this.setState({ price: "", selectedOption: null, type: "" });
            } else {
              this.props.closeForm("danger", "Tariff already exists");
            }
          })
          .catch((err, code, message, statusCode) => {
            this.props.closeForm("danger", err);
            console.log("error", err);
          })
      );
    } else if (type === "add") {
      return HttpServiceSettings.addTariff(tariff, billable_id)
        .then(response => {
          if (response.data) {
            //this.closeForm();
            this.props.closeForm("success","Tariff is created successfully.");
            this.setState({ tariffs: [], type: "" });
          } else {
            this.props.closeForm("danger", "Tariff already exists");
          }
        })
        .catch(err => {
          this.props.closeForm("danger", err);
          console.log("error", err);
        });
    } else {
      alert("error");
    }
  };

  render() {
    const {
      endDate,
      startDate,
      errors,
      formValid,
      errorCount,
      tariffs,
      today
    } = this.state;
    const { type } = this.props;
    //console.log('startDate',startDate)
    return (
      <div>
        <div>
          <form onSubmit={this.addTariff}>
            <div>
              <div className="form-inline">
                <div className="form-group col-xs-8">
                  <div className="labelDate2">
                    <label className="on">Valid from date</label>
                    <DatePicker
                      selected={startDate}
                      onChange={this.handleStartDateChange}
                      locale="en"
                      name="valid_from"
                      dateFormat="dd/MM/yyyy"
                      minDate={today}
                    />
                  </div>
                </div>
                <div className="form-group col-xs-8">
                  <div className="labelDate2">
                    <label className="on">Valid to date (optional)</label>
                    <DatePicker
                      selected={endDate}
                      onChange={this.handleEndDateChange}
                      locale="en"
                      name="valid_to"
                      dateFormat="dd/MM/yyyy"
                      minDate={today}
                    />
                  </div>
                </div>
              </div>

              {tariffs.map((tariff, idx) => (
                <div className="tariffsArray" key={idx}>
                  <h4 className="headerTable">
                    <button
                      type="button"
                      onClick={this.handleRemove(idx)}
                      className="clearItem"
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>{" "}
                  </h4>
                  <table className="table-market" id="tariff-add">
                    <thead>
                      <tr>
                        <th> Month from </th>
                        <th> Month to </th>
                        <th> Day from </th>
                        <th> Day to </th>
                        <th> Time from </th>
                        <th> Time to </th>
                        <th> Price </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            name="month_start"
                            value={tariff.value}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {months.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            name="month_end"
                            value={tariff.value}
                            defaultValue={"DECEMBER"}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {months.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            name="weekday_start"
                            value={tariff.value}
                            defaultValue={"MONDAY"}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {weekdays.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            name="weekday_end"
                            value={tariff.value}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {weekdays.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            name="time_start"
                            value={tariff.value}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {hours.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            name="time_end"
                            value={tariff.value}
                            onChange={this.onChangeSelect(idx)}
                            required
                          >
                            {hours.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            required
                            type="number"
                            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                            name="price"
                            id="price"
                            onChange={this.onChangeSelect(idx)}
                            placeholder="Enter price"
                            value={tariff.price || ""}
                          />
                          {errors.price.length > 0 && (
                            <span className="error">{errors.price}</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>

            <button
              type="button"
              onClick={this.handleAddTariff}
              className="addItem"
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </button>

            <div className="form-group ">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {type === "edit" ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
