import React from "react";
//import ComparisonRow from './ComparisonRow';
//import moment from "moment";

export default function ComparisonTable({ differences, sumPredictions,diffTotal,incr,sumActualReadings }) {
  const emptyMessage = <p>No actual reading data</p>;
  const formList = (
    <div>
      <table
        className="table-market table-hover table-responsive table-bordered"
        id="compare-table-load"
      >
        <thead>
          <tr>
            <th> Time </th>
            <th> Actual {sumActualReadings} (kw)</th>
            <th> Predicted {sumPredictions} (kw)</th>
            <th> Deviation {diffTotal} (kw) ({incr} (%))</th>
            {/*<th> Deviation  (%)</th>*/}
          </tr>
        </thead>
        <tbody>
          {differences.map((difference, index) => (
            <tr key={index}>
              <td>{difference.timestamp}</td>
              <td>{difference.reading}</td>
              <td>{difference.prediction}</td>
              <td>{ difference.difference} ({difference.percentage}) </td>
            {/*  {isNaN(difference.percentage) ? (
                <td>-</td>
              ) : (
                <td>{difference.percentage}</td>
              )}*/}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  return (
    <div>
      <div>{differences.length === 0 ? emptyMessage : formList}</div>
    </div>
  );
}
