import React, {useState, useEffect, useCallback} from 'react'
import { Container, Row, Col, SplitButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import {Redirect, useHistory, useLocation} from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import {Notify} from '../../components/AlertListener'
import {Request} from "../../../services/HttpService";
import { Overlay, noop } from '../../components/UIComponents'
import EditMeterForm from '../meters/EditMeterForm'
import MeterServices from '../../../services/MeterServices'


const request = new Request({ service: 'Organisation service'})
const notify=Notify("org.notifications")

const getUnits = (u) => {
    switch(u) {
        case 'voltage': 
            return 'V'
        case 'current':
            return 'A'
        case 'watts':
            return 'W'
        case 'kilowatts':
            return 'kW'
        case 'kilowatt-hour':
            return 'kWh'
        default:
            return 'kW'
    }
}
 

const PromptMeter = ({org, type, meters=[], 
        text=`No ${type} meter configured`, 
        onUpdate=noop, force=false, redirect, onCreate=noop}={}) => {
    const [visible, setVisible] = useState(true)
    const [redirectTo, setRedirectTo] = useState(null)

    // console.log(`PromptMeter :: type=${type}, visible=${visible}, redirect=${redirect}, to=${JSON.stringify(redirectTo)}`)
    if(visible) {
        const { prompt_meters={}} = org
        let __noPrompt = prompt_meters[type]===false
        
        if(!__noPrompt && !force) {
            __noPrompt=meters.filter(({meter_type=[]}) =>  meter_type.includes(type)).length>0
        }
        if(__noPrompt) return null
    }

    async function refusePrompt(e) {
        e.preventDefault()
        // Send the API request to suppress the prompt
        try {
            const data = {
                prompt_meters: {}
            }
            data.prompt_meters[type] = false

            await request.call(`/api/organisation/${org._id}/config`,
                'put', 
                {data } )
            setVisible(false)
        }
        catch(err) {
            console.error(err)
            notify.error(err.message)
        }    
        onUpdate()
    }

    async function createMeter(e) {
        e.preventDefault()
        if(redirect) {
            setRedirectTo({
                pathname: '/dashboard/settings/organisation',
                state: {
                    newMeter: {
                        type,
                        isVirtual: true
                    }
                }
            })
        }
        else {
            onCreate(type, true)
        }
    }

    if(!visible) return null

    if(redirectTo) {
        return (<Redirect to={redirectTo}/>)
    }

    return (<div>
        <span><em>{text}</em></span><a href="#" onClick={createMeter}> - Add {type} meter</a>
        <span style={{paddingLeft: "1.5rem"}}>(<a href="#" onClick={refusePrompt}>Don't ask again</a>)</span>
    </div>)
}

export default function OrganisationMeters({organisation=null, notable=false, noheader=false, redirect=false}) {
    const [meters, setMeters] = useState(null)
    const [config, setConfig] = useState(null)
    const [edit, setEdit] = useState(null)
    const [expanded, setExpanded] = useState([])
    const [redirectTo, setRedirectTo] = useState(null)

    const __loadConfig = useCallback(() => {
        const __load = async() => {
            try {
                const config = await request.get(`/api/organisation/${organisation._id}/config`)
                setConfig(config)
            }
            catch(err) {
                notify.error(err.message)
            }
        }
        __load()
    }, [organisation])

    const __loadMeters = useCallback(() => {
        const __load = async() => {
            try {
                const meters = await request.get(`/api/meter?level=organisation`)
                setMeters(meters)
            }
            catch(err) {
                notify.error(err.message)
            }
        }

        __load()
    }, [organisation])

    const __loadAggregable = useCallback(() => {
        const __load = async() => {
            try {
                const meters = await request.get(`/api/meter/aggregable?organisationId=${organisation._id}`)
                setMeters(meters)
            }
            catch(err) {
                notify.error(err.message)
            }
        }

        __load()
    }, [organisation])

    const __hasMeterType = useCallback((t) => {
        if(meters===null) return false

        for(const {meter_type=[]} of meters) {
            if(meter_type.includes(t)) return true
        }
        return false
    }, [meters])

    useEffect(() => {
        if(organisation===null) return

        __loadConfig()
        __loadMeters()
        
        return () => {
            setMeters(null)
            setConfig(null)
        }
    }, [organisation])

    const location = useLocation()

    useEffect(() => {
        const {state} = location
        if(state) {
            if(state.newMeter) {
                const { type, isVirtual=false} = state.newMeter
                createMeter(type, isVirtual)
            }
        }
    }, [location])

    const history = useHistory()

    async function onUpdate() {
        // Refresh the config and meter list
        __loadConfig()
        __loadMeters()
        history.replace(location.pathname, null)
    }

    function createMeter(type, virtual=false) {
        setEdit({ type, virtual} )
    }

    const columns=[{
        dataField: '_id',
        hidden: true,
    }, {
        dataField: 'name',
        text: 'Name',
    }, {
        dataField: 'description',
        text: 'Description',
    },
    {
        dataField: 'meter_type',
        text: 'Type',
        formatter: data => (<span>{Array.isArray(data)?data.join(', '):null}</span>)
    }, {
        dataField: 'meter_subtype',
        text: 'Units',
        formatter: getUnits
    }]

    const handleOnExpand = (meter, isExpand ) => {
        if(isExpand) {
            setExpanded([meter._id])
        }
    }

    const expandRow = {
        onlyOneExpanding: true,
        renderer: meter => (
            <Overlay>
                <EditMeterForm 
                    meter={meter}
                    organisation={organisation} 
                    existing={meters}
                    type={edit} 
                    onDone={() => { 
                        setEdit(null)
                        setExpanded([])
                        onUpdate()
                    }}/>
            </Overlay>        
        ),
        expanded, 
        onExpand: handleOnExpand
    };

    if(meters===null) return null

    if(redirectTo) {
        return (<Redirect to={redirectTo}/>)
    }

    return (
        <Container fluid>
            {!noheader?(<Row>
                <Col>
                    <div className="align-items-center">
                        <h4 style={{ display: 'inline-block'}}>Meters</h4>
                        <SplitButton
                            as={ButtonGroup}
                            style={{marginBottom: "0.5rem"}}
                            size="sm"
                            variant="outline-primary"
                            onClick={() => createMeter()}
                            onSelect={(eventKey) => createMeter(eventKey, true)}
                            title="Add Meter"
                        >
                            <Dropdown.Item eventKey="virtual">Virtual meter</Dropdown.Item>
                            {!__hasMeterType('load')?(<Dropdown.Item eventKey="load">Load meter</Dropdown.Item>):null}
                            <Dropdown.Item eventKey="supply">Supply meter</Dropdown.Item>
                            {!__hasMeterType('spill')?(<Dropdown.Item eventKey="spill">Spill meter</Dropdown.Item>):null}
                        </SplitButton>
                    </div>
                </Col>
            </Row>):null}
            {edit!==null?
                (<Row><Col><Overlay>
                    <EditMeterForm 
                        organisation={organisation} 
                        existing={meters}
                        type={edit.type} 
                        virtual={edit.virtual}
                        onDone={() => { 
                            setEdit(null)
                            onUpdate()
                        }}/>
                </Overlay></Col></Row>):null}
            {meters!==null?(<>
            <Row>
                <Col>
                {config!==null?['load', 'supply', 'spill'].map(t => (
                    <PromptMeter 
                        key={`prompt_${t}_${organisation._id}`} 
                        redirect={redirect}
                        onCreate={createMeter}
                        org={organisation} 
                        meters={meters} 
                        type={t}
                        onUpdate={onUpdate}/>
                )):null }
                </Col>
            </Row>
            </>):null}
            {meters.length>0 && !notable?(
            <BootstrapTable
                bootstrap4 striped hover condensed 
                version="4"
                keyField='_id' 
                data={meters}
                columns={columns}
                expandRow={expandRow}
                noDataIndication="No meters found"/>):null}
        </Container>
    )
}