import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import UserContext from "../../services/user-context"
import "react-datepicker/dist/react-datepicker.css";
import SessionStorage from "../../services/SessionStorage"
import moment from 'moment'

const noop=()=>{}

const START_DATE = 'dashboard.datepicker.start',
	END_DATE = 'dashboard.datepicker.end'

//
// 'maxRange' should be ISO8601 duration specification compatible with MomentJS. E.g. 'P1M' 
// would be 1 month
// Any valid duration can be used - but they should only be positive.
// See https://momentjs.com/docs/#/durations/as-iso-string/
//
export default function DateRangePicker({start, end, 
		minDate,
		maxDate=new moment().add(1, 'day'),
		onChange = noop,
		onEnter = noop,
		maxRange=null,
		nobutton=false
	}) {

	const { Time } = useContext(UserContext)
	const [startDate, setStartDate] = useState(Time.day(start?
		start:
		SessionStorage.get(START_DATE, Time.today().subtract(3, 'days'))
	))
	const [endDate, setEndDate] = useState(Time.day(end?
		end:
		SessionStorage.get(END_DATE, Time.tomorrow())
	))
	const [min, setMin] = useState()
	const [max, setMax] = useState(Time.day(maxDate?maxDate:Time.tomorrow()))
	const [range] = useState(maxRange!==null?moment.duration(maxRange):null)

	useEffect(() => {
		if(!minDate) return 

		setMin(Time.day(minDate).toDate())
	}, [minDate])

	useEffect(() => {
		let __end = endDate
		if(range) {
			let latest = Time.day(startDate).add(range)
			if(latest.isAfter(maxDate)) latest = maxDate
			if(endDate.isAfter(latest)) {
				__end=latest
			}
			else if(endDate.isBefore(startDate)) {
				__end=Time.day(startDate).add(1, 'day')
			}
			if(Time.compare(endDate, __end)!==0) {
				setEndDate(__end)
			}
		}
		SessionStorage.set(START_DATE, Time.localToDate(startDate))
		SessionStorage.set(END_DATE, Time.localToDate(endDate))
		onChange(startDate, __end)

	}, [startDate, endDate])

	return (
		<Row className="align-self-center">
			<Col xs="auto">
				<Form.Group as={Row} className="mb-3" controlId="frmDateFrom">
					<Form.Label column="lg" lg="auto">Date From</Form.Label>
					<DatePicker
						selected={startDate.toDate()}
						onChange={(d) => setStartDate(Time.day(d))}
						locale="en"
						minDate={min}
                        maxDate={moment(maxDate).subtract(1, 'day').toDate()}
						name="valid_from"
						dateFormat="dd/MM/yyyy"/>
				</Form.Group>
			</Col>
			<Col xs="auto">
				<Form.Group as={Row} className="mb-3" controlId="frmDateTo">
					<Form.Label column="lg" lg="auto">Date To</Form.Label>
					<DatePicker
						selected={endDate.toDate()}
						onChange={(d) => setEndDate(Time.day(d))}
						locale="en"
						minDate={moment(startDate).add(1, "day").toDate()}
                        maxDate={max.toDate()}
						name="valid_to"
						dateFormat="dd/MM/yyyy"/>
				</Form.Group>
			</Col>
			{!nobutton && <Col xs="auto" lg={2} >
				<Button variant="re-primary" style={{borderRadius: "0.5rem"}}
					onClick={() => onEnter(startDate, endDate)}>
					<i className="fas fa-search"/>Go
				</Button>
			</Col>}
		</Row>)

}