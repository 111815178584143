import React from 'react';

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: this.props.list
    };
  }

  render() {
    const {list, index} = this.props;
    //console.log('props', label)

    return (
              <select
                className="dropdown"
                onChange={this.props.onChange}
                value={index}>
                {list.map((option, index) => (
                  <option key={index} value={index}>
                    {option.name}
                  </option>
                ))}
              </select>
    )
  }
}
