import React, { Component } from "react";
import SiteRows from "./SiteRows";
import { Modal, Card, Alert } from "react-bootstrap";
import AddSiteForm from "./AddSiteForm";
//import ToastContainer from "../../utility/ToastContainer";
import HttpService from "../../services/HttpService";

export default class SiteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      orgId: "",
      name: "",
      site_id: "",
      activeSite: true,
      type: "",
      getInactive: "Include",
      show:false,
      variant:'', msg:'',
      noSites:false
    };
  }

  componentDidMount() {
    this.getSiteList()
  }

  getSiteList = () => {
    console.log('get sites')
    const login_user = sessionStorage.getItem("_user");
    const l_user = JSON.parse(login_user);
    const org_id = l_user.organisation.id;
    console.log('org_id', org_id)
    this.setState({ org_id: org_id });
    HttpService.getSiteList(org_id)
      .then(response => {
        let sites = response.data;
        var site_id;
        if (sites.length) {
          site_id = sites[0]._id;
        }
        //console.log("sites active", sites);
        this.setState({
          noSites:false,
          sites: sites,
          site_id: site_id,
          getInactive: "Include"
        });
        //this.child.current.getMeterList(site_id);
        //this.getMeterList(site_id)
      })
      .catch(err => {
        console.error(err);
        this.setState({ sites: [],noSites:true, });
      });
  };

  getInactiveSites = () => {
    console.log('get inactive')
    const getInactive = this.state.getInactive;
    if (getInactive === "Include") {
      const login_user = sessionStorage.getItem("_user");
      const l_user = JSON.parse(login_user);
      const org_id = l_user.organisation.id;
      //console.log('org_id', org_id)
      this.setState({ org_id: org_id });
      HttpService.getSitesAll(org_id)
        .then(response => {
          let sites = response.data;
          //console.log('sites all', sites)
          var site_id;
          if (sites.length) {
            site_id = sites[0]._id;
          }
          this.setState({
            sites: sites,
            site_id: site_id,
            getInactive: "Hide"
          });
          //this.child.current.getMeterList(site_id);
        })
        .catch(err => {
          console.error(err);
          this.setState({ clusters: [] });
        });
    } else {
      this.getSiteList();
    }
  };

  editSite = site => {
    //console.log("editsite", site);
    this.setState({
      showModalEditSite: true,
      site_id: site._id,
      name: site.name,
      activeSite: site.active,
      type: "edit"
    });
  };

  addSite = () => {
    //console.log("site add");
    this.setState({ showModaladdSite: true, type: "add" });
  };

  closeAddSite = (variant, msg) => {
    console.log('variant, msg', variant, msg)
    this.setState({ showModaladdSite: false, variant:variant, msg:msg });
    this.onShowAlert();
    this.getSiteList();
  };

  closeEditSite = (variant, msg) => {
    console.log('variant, msg', variant, msg)
    this.setState({ showModalEditSite: false, variant:variant, msg:msg });
    this.onShowAlert();
    this.getSiteList();
  };
  closeModal=()=>{
    this.setState({ showModalEditSite: false, showModaladdSite: false, });
  }

  setShow=()=>{
    this.setState({ show:false });
  }

  onShowAlert = ()=>{
    console.log('onShowAlert')
    this.setState({show:true},()=>{
      window.setTimeout(()=>{
        this.setState({show:false})
      },3000)
    });
  }

  render() {
    const { site_id, name, activeSite, type,sites, org_id, getInactive, show, variant, msg,noSites } = this.state;
    console.log('variant, msg2222', variant, msg)
    return (
      <div className="card">
        <div className="settings">
         { show===true
           ?(  <Alert variant={variant} onClose={() => this.setShow()} dismissible>
               <Alert.Heading>{msg}</Alert.Heading>
             </Alert>)
             :<div></div>
        }
          <div className="col-lg-12">
            <div className="headerTable">
              <h2>Sites</h2>
              <button className="inactive btn-round" onClick={this.getInactiveSites}>
                {getInactive} Inactive
              </button>

              <button className="add btn-round " onClick={this.addSite}>
                Add Site
              </button>
            </div>

            {noSites === false
            ?(<table className="table-market" id="cluster-join">
               <thead>
                 <tr>
                   <th> Name </th>
                   <th> Active </th>
                   <th> Edit </th>
                 </tr>
               </thead>
               <tbody>
                 {sites.map(site => (
                   <SiteRows
                     site={site}
                     key={site._id}
                     editSite={this.editSite}
                   />
                 ))}
               </tbody>
             </table>)
           :<div>  <Card
               style={{
                 width: "18rem"
               }}
             >
               <Card.Body>
                 <Card.Title>
                   <i
                     style={{
                       color: "orange"
                     }}
                     className="fas fa-exclamation-triangle"
                   ></i>
                   Warning
                 </Card.Title>
                 <Card.Text>No any sites yet.</Card.Text>
               </Card.Body>
             </Card>
           </div>}


          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModaladdSite}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="textCenter">Add Site</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddSiteForm
                    orgId={org_id}
                    closeAddSite={this.closeAddSite}
                    activeSite={true}
                    type={"add"}
                    onShowAlert={this.onShowAlert}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalEditSite}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="textCenter">Edit Site</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddSiteForm
                    orgId={org_id}
                    closeAddSite={this.closeEditSite}
                    site_id={site_id}
                    name={name}
                    activeSite={activeSite}
                    type={type}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
