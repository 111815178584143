import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Menu, Segment } from "semantic-ui-react";
import {
	// BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import Router from '../utility/DebugRouter'
import Loading  from "../LoadingPage";
import { LinkContainer } from 'react-router-bootstrap'
import AdminNav from "./AdminNav";
import SessionStorage from '../services/SessionStorage'
import AuthService from "../services/auth";
import ErrorListener from "../services/ErrorListener";

import Organisations from "../dashboard/settings/OrganisationSettingsLegacy";
import Clusters from "../dashboard/settings/ClusterTable";
import BillingDashboard from "../dashboard/components/billing/BillingDashboard"


const TAB_ACTIVE = 'admin.tab.active'

export default function AdminDashboard() {
	const [activeTab, setActiveTab] = useState(SessionStorage.get(TAB_ACTIVE, '/admin/organisation'))
	const [navbarActive, showNavbar] = useState(false)
	const [redirect, setRedirect] = useState(null)
	const [user, setUser] = useState(null)

	function on401Error(err) {
    	if (redirect!==null) return; // Already handled
    	console.error(`Dashboard error handler `, err);
    	AuthService.clearSession();
    	setRedirect("/login");
  	}

	useEffect(() => {
		async function checkLoggedIn() {
			try {
      			const __user = await AuthService.isAuthenticated();
      			if (!__user.roles.length) {
        			setRedirect("/login")
      			} else if (!__user.roles.includes("admin")) {
        			setRedirect("/dashboard")
      			} else {
        			setUser(__user)
				    ErrorListener.on(401, on401Error);
      			}
    		} catch (err) {
    			console.error('Error ', err)
    			setUser(null)
    		}
    	}
		checkLoggedIn()

		return () => {
		    ErrorListener.off(401, on401Error);
		    setRedirect(null)
		    setUser(null)
		}
	}, [])

	function handleTabChange(tab) {
		const __activeTab = `/admin/${tab}`
		SessionStorage.set(TAB_ACTIVE, __activeTab)
		setActiveTab(__activeTab)
	}

	if(redirect!==null) {
		return (<Redirect to={redirect}/>)
	}

	if(user===null) {
		return (<Loading/>)
	}

	return (
    	<div id="wrapper" className={navbarActive ? "active" : ""}>
        	<header className="topbar-nav">
          		<AdminNav
            		navbarActive={navbarActive}
            		user={user}
            		showNavbar={showNavbar}/>
        	</header>
        	<div className="clearfix"/>
        	<Container fluid>
		    	<Menu  pointing tabular attached="top">
					<LinkContainer to="/admin/organisation">
						<Menu.Item name="Organisation" onClick={() => handleTabChange('organisation')}>
							Organisation
						</Menu.Item>
					</LinkContainer>
					<LinkContainer to="/admin/billing">
						<Menu.Item name='Billing' onClick={() => handleTabChange('billing')}>
							Billing
						</Menu.Item>
					</LinkContainer>
					<LinkContainer to="/admin/clusters">
						<Menu.Item name='Clusters' onClick={() => handleTabChange('clusters')}>
							Clusters
						</Menu.Item>
					</LinkContainer>
	          	</Menu>
	          	<Segment attached="bottom">
	          	<Switch>
					<Route
						path={`/admin/organisation`}
						key={`organisation`}
						render={() => { 
							return (<Organisations/>)
						}}
					/>
					<Route
						path="/admin/billing"
						key='billing'
						render={() => (
							<BillingDashboard root="/admin/billing"/>
						)}
					/>
					<Route
						path="/admin/clusters"
						key='clusters'
						render={() => (
							<Clusters/>
						)}
					/>
					<Route
						render={() => (
							<Redirect to={activeTab} />
						)}
					/>
	          	</Switch>
        		</Segment>
        	</Container>
      	</div>
	)
}