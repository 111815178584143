import React, { Component } from "react";
import UserServices from "../../services/UserServices";
import UserRows from "./UserRows";
import { Modal,  Alert } from "react-bootstrap";
import AddUserForm from "./AddUserForm";
import ChangePasswordForm from "./ChangePassword";
//import ToastContainer from "../../utility/ToastContainer";

export default class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: "",
      users: [],
      firstname: "",
      lastname: "",
      email: "",
      roles: [],
      selectedRoles: null,
      active: true,
      type: "",
      user_id: "",
      getInactive: "Include",
      variant:'', msg:'',
      show:false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      const orgId = this.props.orgId;
      //console.log('orgId.......', orgId)
      this.setState({ orgId: orgId });
      this.getUserList();
    }, 1);
  }

  getUserList = id => {
    UserServices.getUserList()
      .then(response => {
        const login_user = sessionStorage.getItem("_user");
        const l_user = JSON.parse(login_user);
        const org_id = l_user.organisation.id;
        const o_id = this.props.orgId;
        var orgId;
        if (o_id) {
          orgId = o_id;
        } else {
          orgId = org_id;
        }
        let users = response.data;
        const found_users = users.filter(item => item.orgId === orgId);
        //console.log('USERS', users)
        this.setState({
          users: found_users,
          orgId: orgId,
          getInactive: "Include"
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ users: [] });
      });
  };

  getInactive = id => {
    const getInactive = this.state.getInactive;
    if (getInactive === "Include") {
      UserServices.getUsersAll()
        .then(response => {
          const login_user = sessionStorage.getItem("_user");
          const l_user = JSON.parse(login_user);
          const org_id = l_user.organisation.id;
          const o_id = this.props.orgId;
          var orgId;
          if (o_id) {
            orgId = o_id;
          } else {
            orgId = org_id;
          }
          let users = response.data;
          const found_users = users.filter(item => item.orgId === orgId);
          this.setState({
            users: found_users,
            orgId: orgId,
            getInactive: "Hide"
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({ users: [] });
        });
    } else {
      this.getUserList();
    }
  };

  changePassword = user => {
    //console.log('changePassword', user)
    this.setState({ showModalChangePassword: true, email: user.email });
  };

  closeChangePassword = (variant, msg) => {
    this.setState({ showModalChangePassword: false,variant:variant, msg:msg });
    this.onShowAlert();
  };

  editUser = user => {
    const roles = user.roles;
    const selectedRoles = roles.map(value => ({ value, label: value }));
    this.setState({
      showModalEditUser: true,
      user_id: user._id,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      roles: user.roles,
      active: user.active,
      type: "edit",
      selectedRoles: selectedRoles
    });
  };

  closeEditUser = (variant, msg) => {
    this.getUserList();
    this.setState({ showModalEditUser: false, variant:variant, msg:msg });
    this.onShowAlert();
  };

  addUser = () => {
    //console.log('User add')
    this.setState({ showModaladdUser: true, type: "new" });
  };

  closeModal=()=>{
    this.setState({ showModalEditUser: false, showModaladdUser: false, showModalChangePassword: false});
  }

  closeAddUser = (variant, msg) => {
    this.setState({ showModaladdUser: false,variant:variant, msg:msg  });
    this.onShowAlert();
    this.getUserList();
  };

  setShow=()=>{
    this.setState({ show:false });
  }


  onShowAlert = ()=>{
    //console.log('onShowAlert')
    this.setState({show:true},()=>{
      window.setTimeout(()=>{
      this.setState({show:false})
        },3000)
      });
    }

  render() {
    const { users, email, user_id, getInactive, active, show, variant,msg } = this.state;
    //console.log('active....', active)
    const orgId = this.state.orgId;
    const roles = this.state.roles;
    const selectedRoles = this.state.selectedRoles;

    const login_user = sessionStorage.getItem("_user");

    const l_user = JSON.parse(login_user);

    var options;
    if (l_user.roles.includes("admin")) {
      options = [
        { value: "admin", label: "admin" },
        { value: "cadmin", label: "cadmin" },
        { value: "user", label: "user" }

      ];
    } else {
      options = [
        { value: "cadmin", label: "cadmin" },
        { value: "user", label: "user" }
      ];
    }

    return (
      <div className="card">
        <div className="settings">
          <div>
          { show===true
            ?(  <Alert variant={variant} onClose={() => this.setShow()} dismissible>
                <Alert.Heading>{msg}</Alert.Heading>
              </Alert>)
              :<div></div>
         }
          </div>
          <div className="col-lg-12">
            <div className="headerTable">
              <h2>Users</h2>
              <button className="inactive btn-round" onClick={this.getInactive}>
                {getInactive} Inactive
              </button>
              <button className="add btn-round " onClick={this.addUser}>
                Add User
              </button>
            </div>

            <table className="table-market" id="user-table">
              <thead>
                <tr>
                  <th> Active </th>
                  <th> Name </th>
                  <th> Email </th>
                  <th> Roles </th>
                  <th> Edit </th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <UserRows
                    user={user}
                    key={user._id}
                    changePassword={this.changePassword}
                    editUser={this.editUser}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModaladdUser}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="textCenter">Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddUserForm
                    getUserList={this.getUserList}
                    userId={""}
                    orgId={orgId}
                    edit={false}
                    closeForm={this.closeAddUser}
                    selectedRoles={null}
                    active={active}
                    show={true}
                    type={"new"}
                    options={options}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalEditUser}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="textCenter">Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddUserForm
                    options={options}
                    getUserList={this.getUserList}
                    orgId={orgId}
                    userId={user_id}
                    firstname={this.state.firstname}
                    lastname={this.state.lastname}
                    email={this.state.email}
                    selectedRoles={selectedRoles}
                    roles={roles}
                    active={active}
                    type={"edit"}
                    edit={true}
                    show={false}
                    closeForm={this.closeEditUser}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalChangePassword}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton>
                <Modal.Title className="textCenter">
                  Change password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <ChangePasswordForm
                    getUserList={this.getUserList}
                    orgId={orgId}
                    email={email}
                    closeChangePassword={this.closeChangePassword}
                    type={"edit"}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
