import React, { useState, useEffect, useCallback } from 'react'
import {Form, Col} from 'react-bootstrap'
import { noop } from '../../components/UIComponents'

const __default = {
    required: false, 
    minLength: 0,
    maxLength: 0 // Don't limit
}

export default function TextControl({
            descriptor={}, 
            value: __value="", 
            lg=6, 
            onChange=noop, 
            validate=() => true,
            isValid=noop}) {
    const [props] = useState({...__default, ...descriptor, placeholder: descriptor.label})
    const [value, setValue] = useState(__value)
    const [error, setError] = useState(null)

    const errorCheck = useCallback((value="") => {
        const { label, required, minLength, maxLength} = props
        if((required || minLength>0) && value.trim().length===0) {
            return `${label} required`
        }
        if(value.length<minLength) {
            return `Must have at least ${minLength} characters`
        }
        if(maxLength>0 && value.length>maxLength) {
            return `Must have be less than ${maxLength} characters`
        }
        const __isValid = validate(value)
        if(__isValid!==true) {
            // Failed the validation
            console.log(`[TextControl::errorCheck] validate() = `, __isValid)
            return __isValid
        }
        return false
    }, [props])

    useEffect(() => {
        const { name } = props
        setError(errorCheck(__value))
        // isValid(name, errorCheck(__value))
    }, [])

    const __onChange = useCallback((e) => {
        const { value="" } = e.target
        setValue(value)
        setError(errorCheck(value))
    }, [props])

    useEffect(() => {
        onChange(value)
    }, [value])

    useEffect(() => {
        if(error===null) return
        isValid(props.name, error)
    }, [error])

    return (<Form.Group as={Col} lg={lg} controlId={`input_${props.name}`}>
        <Form.Label>{`${props.required?'(*) ':''}${props.label}`}</Form.Label>
        <Form.Control
            type="text"
            placeholder={props.placeholder}
            onChange={__onChange}
            value={value} 
            isInvalid={!!error}/>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>)
}