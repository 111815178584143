import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import { useHistory  } from "react-router-dom";
import {noop} from '../components/UIComponents'
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import SessionStorage from "../../services/SessionStorage"

const request = new Request({ service: 'Battery service'})
const notify=Notify("dash.notifications")

const SELECTED_BATTERY_KEY = 'site.dash.battery.selected',
    NO_BATTERIES = { value: "", label: "-- No batteries --"}

export default function BatterySelector({titlePre="Battery", selected=""}) {
    const [title, setTitle] = useState(null)
    const [batteries, setBatteries] = useState([NO_BATTERIES])
    const [selectedBattery, setSelectedBattery] = useState(null)
    const history = useHistory() 

    useEffect(() => {
        const __loadBatteries = async () => {
            try {
                let __batteries = await request.get(`/api/battery`)
                
                if(__batteries.length===0) {
                    setBatteries([NO_BATTERIES])
                    return ;
                }

                setBatteries([ 
                    {value: "", label: "-- Select Battery --"},
                    ...__batteries.map((b => ({ ...b, value: b._id, label: b.name })))
                ])
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }
        __loadBatteries()

    }, [selected])

    useEffect(() => {
        if(batteries===null) return 
        // let __selected = SessionStorage.get(SELECTED_BATTERY_KEY, "")
        const [__battery] = batteries.filter(({value}) => value===selected)
        if(__battery) {
            setSelectedBattery(__battery)
            const { name } = __battery
            if(name) {
                setTitle(`${titlePre} - ${__battery.name}`)
            }
        }
        else {
            setSelectedBattery(batteries[0])
        }
        return () => {
            setTitle(null)
        }
    }, [batteries])

    const onBatteryChange = useCallback(({_id}) => {
        SessionStorage.set(SELECTED_BATTERY_KEY, _id)
        history.replace(`/dashboard/battery${_id?`/${_id}`:''}`)
    }, [batteries])

    return (<Row>
        <Col>
            <Form className="dropdown">
                <Form.Row className="align-items-center">
                    <Col xs="auto"><h2>{title}</h2></Col>
                    <Col style={{textAlign: 'right'}}><h4>Battery</h4></Col>
                    <Col xs={3} style={{float: 'right'}}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={selectedBattery}
                            options={batteries}
                            onChange={onBatteryChange}
                            />

                    </Col>
                </Form.Row>
            </Form>
        </Col>
    </Row>
)
}