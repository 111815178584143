import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { PlayButton, EditButton, DeleteButton, ConfirmAlert, ConfirmDialog } from '../../components/UIComponents.jsx'
import { Loading } from "../../components/UIComponents.jsx"

import { Container, Row, Col, Dropdown, Button, ButtonGroup, ButtonToolbar } from "react-bootstrap"
import {Request} from "../../../services/HttpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Notify } from '../../components/AlertListener.jsx'
import './solar-scenario.css'

const notify=Notify("solar.notifications")

class SolarScenarioList extends Component {
	constructor(props) {
		super(props)
		
		this.state = {
			loading: true,
			scenarios: [], 
			deleteId: null,
			key: props.key,
			mode: 'list',
			selected: [],
			confirmDeleteMultiple: false
		}
		this.request = new Request({ service: 'Scenario service'})
	}

	async loadList() {
		this.setState({ loading: true, scenarios: []})
		// Call the API
		let scenarios = [] ;
		try {
			scenarios = await this.request.call('/api/scenario/solar')
			// Reconcile selected list with the loaded list
			const { selected } = this.state 
			this.setState({selected: scenarios.filter(({_id}) => selected.includes(_id)).map(({_id}) => _id) })
		}
		catch(err) {
		    notify.error(err.message) ;
		}
		this.setState({loading: false, scenarios})
	}

	async componentDidMount() {
		await this.loadList() ;
	}

	edit = (_id) => {
		this.setState({ deleteId: null, editParams: { mode: 'edit', _id, visible: true }})
	}

	delete = (_id) => {
		this.setState({deleteId: _id})
	}

	closeEdit = () => {
		this.setState(state => ({editParams: {visible: false}}))
		this.loadList() ;
	}

	addScenario = () => {
		this.setState({ editParams: { mode: 'add', visible: true }})
	}

	deleteScenario = async (id, name) => {
		const {deleteId} = this.state ;
		this.setState({deleteId: null})
		if(deleteId!==null && deleteId===id) {
			try {
				await this.request.call(`/api/scenario/solar/${deleteId}`, 'delete')
			    notify.info(`Deleted scenario "${name}"`) ;
			    this.loadList()
			}
			catch(err) {
			    notify.error(err.message)
			}
		}
	}

	handleKeyPress = e => {
		if(e.keyCode===27) {
			// Esc pressed
			const { deleteId=null } = this.state
			if(deleteId!==null) {
				this.setState({deleteId: null})
			}
		}
	}

	componentWillMount() {
		document.addEventListener("keydown", this.handleKeyPress)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyPress)
	}

	updateLocation = (lat, lon) => {
		// console.log(`Position ${lat}, ${lon}`)
	}

	deleteMultiple = async (e) => {
		const { selected } = this.state ;
		if(e===true) {
			// Confirmed delete
			const deleteRequests = selected.map(id => {
				return this.request.call(`/api/scenario/solar/${id}`, 'delete')
				.catch(err => {
					notify.error(e.message)
					return err
				})
			})
			await Promise.all(deleteRequests)
			this.setState({
				confirmDeleteMultiple: false,
				selected: []
			})
			this.loadList()
			return ;
		}
		if(selected.length>0) {
			this.setState({confirmDeleteMultiple: true})
		}
	}

	render() {
		const { loading, scenarios=[], disabled=false, selected, confirmDeleteMultiple } = this.state ;


		const columns = [{
				dataField: '_id',
				text: 'id',
				hidden: true
			}, {
				dataField: 'name',
				text: 'Name',
				sort: true
			}, {
				dataField: 'description',
				text: 'Description',
				sort: true
			}, {
				dataField: 'location',
				text: 'Location',
				formatter: ([lat,lon]) => (<span>{lat}, {lon}</span>)
			}, {
				dataField: 'buttons',
				isDummyField: true,
				text: '',
				formatter: (cellcontent, row, rowIndex, __deleteId) => {
					return __deleteId!==row._id?(<React.Fragment>
			      		<Link to={`/dashboard/scenario/solar/${row._id}/run`}>
			      			<PlayButton label={`Run scenario "${row.name}"`} size="sm"/>
			      		</Link>
			      		<Link to={`/dashboard/scenario/solar/${row._id}/edit`}>
			      			<EditButton label={`Edit scenario "${row.name}"`} size="sm"/>
			      		</Link>
			      		<DeleteButton label={`Delete scenario "${row.name}"`} size="sm" onClick={(e) => { e.stopPropagation(); this.delete(row._id)}}/>
			      	</React.Fragment>):
			      	(<ConfirmAlert text={`Delete "${row.name}"?`}
							onYes={() => { this.deleteScenario(row._id, row.name)}}
							onNo={() => { this.setState({deleteId: null})}}
							className="pull-right"/>

		      		)
				},
				formatExtraData: this.state.deleteId
			}
		]

		const defaultSorted = [{
	  		dataField: 'name',
	  		order: 'asc'
		}];

		const selectRow = {
	  		mode: 'checkbox',
	  		clickToSelect: true,
			selected,
	  		// hideSelectColumn: true,
	  		onSelect: (row, isSelect) => {
	  			const { _id } = row ;

	  			if(disabled) {
	  				return false
	  			}
	  			const { selected } = this.state ;
	  			if(isSelect) {
	  				if(!selected.includes(_id)) {
	  					selected.push(_id)
	  					this.setState({ selected: [...selected]}) ;
	  				}
	  				else {
	  					console.warn(`Row ${_id} already selected`)
	  				}
	  			}
	  			else {
	  				const ndx = selected.indexOf(_id)
	  				if(ndx>=0) {
		  				selected.splice(ndx, 1)
	  					this.setState({selected: [...selected]})
	  				}
	  				else {
	  					console.warn(`Row ${_id} not selected`)
	  				}
	  			}
	  			return true
			},
			onSelectAll: (isSelect, rows) => {
				if(isSelect) {
					this.setState({selected: rows.map(r => r._id)}) ;
				}
				else {
					this.setState({selected: []})
				}
			},
	  		bgColor: '#28ba9f'
		};

    	return (
    		<React.Fragment>
    		{confirmDeleteMultiple?(<ConfirmDialog 
    				yes="Delete" onYes={() => this.deleteMultiple(true)} 
    				no="Cancel" onNo={() => this.setState({confirmDeleteMultiple: false})} 
    				heading="Confirm Deletion" 
    				text={`Delete ${selected.length} scenario${scenarios.length>1?'s':''}?`}/>):null}
    			<Container fluid>
    				<Row>
    					<Col className="headerTable">
							<h2>Scenarios</h2>
    					</Col>
    				</Row>
    				<Row>
						<Col className="button-panel">
							<ButtonToolbar className="justify-content-between" aria-label="Toolbar with Button groups">
								<ButtonGroup className="mr-2">
									<Dropdown as={ButtonGroup} style={{margin: "0 0.5rem 0 0"}}>
										<Link to="/dashboard/scenario/solar/new">
											<Button type="button" variant="re-primary" className="btn-re btn-dropdown">
												<i className="fas fa-plus"/>
												<span>New</span>
											</Button>
										</Link>
			  							<Dropdown.Toggle variant="re-primary" split className="btn-re" id="dropdown-split-basic" />
										<Dropdown.Menu style={{margin: 0}}>
										    <Dropdown.Item as={Link} to="/dashboard/scenario/solar/new">Create new</Dropdown.Item>
										    <Dropdown.Item as={Link} to="/dashboard/scenario/solar/from-installation">Create from physical array</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>	
									<Button variant="outline-secondary" 
											type="button" 
											className="icon-only" 
											onClick={this.deleteMultiple}
											disabled={selected.length===0}>
										<i className="fas fa-trash"/>
									</Button>
								</ButtonGroup>
							</ButtonToolbar>
						</Col>
					</Row>
					{loading?(<Row><Col><Loading active={loading}/></Col></Row>):
					(<Row>
    					<Col>
							<BootstrapTable bootstrap4 striped hover condensed 
								keyField='_id' 
								className='selectable'
								data={ scenarios } 
								columns={ columns } 
								defaultSorted={defaultSorted}
								selectRow={selectRow}
								noDataIndication="No scenarios found"/>
    					</Col>
    				</Row>)}
				</Container>
    		</React.Fragment>
    	);

	}
}

export default SolarScenarioList