import React, { useState, useEffect } from 'react'
import {Alert, Container, Row, Col } from 'react-bootstrap'
import {SaveButton, CancelButton, ConfirmAlert } from '../../components/UIComponents.jsx'
import {Request} from "../../../services/HttpService";
import { Notify } from '../../components/AlertListener.jsx'

const notify=Notify("solar.notifications")

const PANEL_ATTRS=["name", "tilt", "capacity", "azimuth", "array_type|", "module_type", "losses"] ;

const MODULE_TYPES=[
	["MT_STANDARD", "Standard"], 
	["MT_PREMIUM", "Premium"], 
	["MT_THINFILM", "Thin Film"]],
	ARRAY_TYPES=[
		["AT_OPEN_RACK", "Fixed - Open Rack"],
		["AT_ROOF_MOUNTED", "Fixed - Roof Mounted"],
		["AT_1AXIS", "1-Axis"],
		["AT_1AXIS_BACKTRACK", "1-Axis Backtracking"],
		["AT_2AXIS", "2-Axis"]] ;

function validateNumber(n, v, min=NaN, max=NaN) {
	if(v.length===0) {
		throw new Error(`Value required for "${n}"`)
	}
	else {
		// Make sure value is nuneric
		const __v = parseFloat(v)
		if(isNaN(v) || isNaN(__v)) {
			throw new Error(`"${n}" Must be numeric value`)
		}
		if(!(isNaN(min) || isNaN(max))) {
			// Check range
			if(__v<min || __v>max) {
				throw new Error(`"${n}" must be in range ${min}/${max}`)
			}
		}
		else if(!isNaN(min)) {
			// Check min
			if(__v<min) {
				throw new Error(`"${n}" must be >= ${min}`)
			}
		}
		else if(!isNaN(max)) {
			// Check range
			if(__v>max) {
				throw new Error(`"${n}" must be <= ${max}`)
			}
		}
		return __v
	}
}

const noop=() => {}


const DEFAULT_PANEL = {name: "", tilt: 36, capacity: 10, azimuth: 0, array_type: "AT_ROOF_MOUNTED", module_type: "MT_PREMIUM", losses: 5, efficiency: 95}
const EMPTY = {name: "", tilt: "", capacity: "", azimuth: "", array_type: "", module_type: "", losses: "", efficiency: ""}

export default function EditSolarPanel({
		panel=null,
		onDone=()=>{},
		visible=false,
		scenario_id=null,
		onSave=noop,
		onCancel=noop
	}) {
	const request = new Request({ service: 'Scenario service'})

	const [show, setShow] = useState(visible)
	const [isEdit, setEdit] = useState(false)
	const [confirmRequired, setConfirm] = useState(false)
	const [initial, setInitial] = useState(panel)
	const [__panel, setPanel] = useState(panel)
	const [errors, setErrors] = useState(PANEL_ATTRS.reduce((a, c) => { a[c]=false; return a}, {}))
	
	useEffect(() => {
		console.log('EditSolarPanel :: mount - panel=', panel)
		if(panel===null) {
			// Add
			setPanel(p => ({...DEFAULT_PANEL}))
			setInitial(i => ({...DEFAULT_PANEL}))
			setEdit(e => false)
		}
		else {
			// Add
			setPanel(p => ({...panel}))
			setInitial(i => ({...panel}))
			setEdit(e => true)
		}
		setShow(s => visible)
		return () => {
			console.log('EditSolarPanel :: unmount')
			setPanel(p => ({...EMPTY}))
			setPanel(p => ({...EMPTY}))
		}
	}, [panel, visible])

	// useEffect(() => {
	// 	setShow(visible)
	// 	const p = typeof(panel)==='undefined'?{...DEFAULT_PANEL}:{...panel}
	// 	setInitial(p)
	// 	setPanel({...p})
	// 	setEdit(typeof(p._id)!=='undefined')
	// }, [visible])

	function hasChanged() {
		if(initial===null) return false
		const tmp =  PANEL_ATTRS.reduce((en, k) => en || initial[k]!==__panel[k], false) 
		return tmp
	}

	function isValid(name, value) {
		errors[name] = false ;
		let __v = value
		const checkOptions = (v, values) => {
			for(const [opt] of values) {
				if(v===opt) return true
			}
			return false ;
		}
		try {
			switch(name) {
				case 'name':
					if(value.length<2) {
						throw new Error('"Name" must be at least 2 characters')
					}
					break ;
				case "tilt":
					__v=validateNumber(name, value, 0,90) ;
					break ;
				case "capacity":
					__v=validateNumber(name, value, 0)
					break ;
				case "azimuth":
					__v=validateNumber(name, value, -180, 180)
					break ;
				case "efficiency":
				case "losses":
					__v=validateNumber(name, value, 0, 100)
					break ;
				case "array_type":
					if(!checkOptions(value, ARRAY_TYPES)) {
						throw new Error('Please select valid array type')
					}
					break ;
				case "module_type":
					if(!checkOptions(value, MODULE_TYPES)) {
						throw new Error('Please select valid module type')
					}
					break ;
				default:
					break
			}
		}
		catch(err) {
			errors[name]=err.message ;
		}
		__panel[name]=__v ;
		setPanel({...__panel})
		setErrors({...errors})
		return errors[name] === false ;
	}

	function onChange(e) {
		const { value="", name } = e.target ;
		isValid(name, value)
	}

	async function save() {
		if(PANEL_ATTRS.reduce((v, a) => v&=isValid(a, __panel[a]), true)) {
			try {
				let {_id=null, ...__p}=__panel ;
				if(scenario_id!==null) {
					__p = await request.call(
						`/api/scenario/solar/${scenario_id}/panels${_id!==null?`/${_id}`:''}`,
						'put',
						{
							data: __p
						})
				}
				setShow(false)
				setPanel(null)
				onSave(__p)
			}
			catch(err) {
				notify.error(err.message)
			}
		}
	}

	function cancel(e) {
		if(e===true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			setConfirm(false)
			setShow(false)
			setPanel(null)
			onCancel()
		}
		setConfirm(true)
	}

	function canSave() { return true }

	return __panel!==null?(
		<Alert show={show}>
			<Alert.Heading>{isEdit?'Edit':'New'} Panel</Alert.Heading>
			<Container className="form-group">
				<Row>
					<Col>
		                <label className="on">Name</label>
		                <input
		                  className="form-control"
		                  type="text"
		                  required
		                  name="name"
		                  id="pnl_name"
		                  onChange={onChange}
		                  placeholder="Panel name"
		                  value={__panel.name}
		                />
		                {errors.name && (
		                  <span className="error">{errors.name}</span>
		                )}

					</Col>
				</Row>
				<Row>
					<Col>
		                <label className="on">Capacity (kw)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="capacity"
		                  id="pnl_capacity"
		                  onChange={onChange}
		                  placeholder="Capacity"
		                  value={__panel.capacity}
		                />
		                {errors.capacity && (
		                  <span className="error">{errors.capacity}</span>
		                )}

					</Col>
					<Col>
		                <label className="on">Efficiency (%)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="efficiency"
		                  id="pnl_efficiency"
		                  onChange={onChange}
		                  placeholder="Efficiency"
		                  value={__panel.efficiency}
		                />
		                {errors.efficiency && (
		                  <span className="error">{errors.efficiency}</span>
		                )}

					</Col>
					<Col>
		                <label className="on">Losses (%)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="losses"
		                  id="pnl_losses"
		                  onChange={onChange}
		                  placeholder="Losses"
		                  value={__panel.losses}
		                />
		                {errors.losses && (
		                  <span className="error">{errors.losses}</span>
		                )}

					</Col>
				</Row>
				<Row>
					<Col>
		                <label className="on">Tilt (°)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="tilt"
		                  id="pnl_tilt"
		                  onChange={onChange}
		                  placeholder="Tilt"
		                  value={__panel.tilt}
		                />
		                {errors.tilt && (
		                  <span className="error">{errors.tilt}</span>
		                )}

					</Col>
					<Col>
		                <label className="on">Azimuth (°)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="azimuth"
		                  id="pnl_azimuth"
		                  onChange={onChange}
		                  placeholder="Azimuth"
		                  value={__panel.azimuth}
		                />
		                {errors.azimuth && (
		                  <span className="error">{errors.azimuth}</span>
		                )}

					</Col>
				</Row>
				<Row>
					<Col>
		                <label className="on">Array type</label>
		                <select name="array_type"
		                	id="pnl_array_type"
		                	className="form-control"
		                  	onChange={onChange}
		                	value={__panel.array_type}>
		                	<option key='at_none' value="none">--Select--</option>
		                	{ARRAY_TYPES.map(([k,t]) => (<option key={k} value={k}>{t}</option>))}
		                </select>
		                {errors.array_type && (
		                  <span className="error">{errors.array_type}</span>
		                )}

					</Col>
					<Col>
		                <label className="on">Module type</label>
		                <select  name="module_type"
		                	id="pnl_module_type"
		                  	onChange={onChange}
		                	className="form-control"
		                	value={__panel.module_type}>
		                	<option key='mt_none' value="-1">--Select--</option>
		                	{MODULE_TYPES.map(([k,t]) => (<option key={k} value={k}>{t}</option>))}
		                </select>
		                {errors.module_type && (
		                  <span className="error">{errors.module_type}</span>
		                )}
					</Col>
				</Row>
				<Row>
					<Col>
						{confirmRequired?(<ConfirmAlert
							rightJustify
							text="Panel details have changed, really cancel?"
							onYes={() => cancel(true)}
							onNo={() => setConfirm(false)}
						/>):
						(<div className="form-group btn-right save-cancel">
			            	<CancelButton label="Cancel edit" onClick={cancel}/>
			            	<SaveButton label={`${isEdit?'Update':'Create'} panel`} onClick={save} disabled={!canSave()}/>
			            </div>)}
			        </Col>
			    </Row>
			</Container>

		</Alert>
	):null
}

