import React, { useState, useEffect } from 'react'
import { Redirect } from "react-router-dom";
import { AddButton, EditButton, DeleteButton, ConfirmAlert, Toggle, LocationChooser } from '../../components/UIComponents.jsx'
import {Form, Card, Container,Row, Col, Button, Accordion, Dropdown, ButtonGroup } from 'react-bootstrap'
import EditSolarPanel from './EditSolarPanel.jsx'
import SaveAsDialog from './SaveAsDialog.jsx'
import {Request} from "../../../services/HttpService";
import { Notify } from '../../components/AlertListener.jsx'
import uuid from '../../../utility/uuid'

import { getText, ARRAY_TYPES, MODULE_TYPES} from './constants.js'

const request = new Request({ service: 'Scenario service'})

const ATTRS = [ "name", "description", "latitude", "longitude", "billing_plan_id", "cluster_sales_plan_id" ] ;

const notify=Notify("solar.notifications")

const DEFAULT = {name: "", description: "", billing_plan_id: "", cluster_sales_plan_id: "", latitude: 52.253547, longitude: -7.187753}

const IS_EMPTY = /^\s*$/

const FORM_WIDTH={lg: 6},
	noop=() => {}

function PanelRow({scenarioId, panel, onDelete=noop, edit=false, eventKey}) {
	const [isEdit, setEdit] = useState(edit) 
	const [__panel, setPanel] = useState({...panel})

	const [confirmRequired, confirmDelete] = useState(false)

	if(isEdit) {
		return (<EditSolarPanel visible={isEdit}
			panel={__panel}
			scenario_id={scenarioId}
			onSave={async (p) => {
				setEdit(false)
				setPanel({...p})
			}}
			onCancel={() => { setEdit(false)}}/>)
	}

	async function deletePanel(e) {
		if(e===true) {
			try {
				await request.call(`/api/scenario/solar/${scenarioId}/panels/${__panel._id}`, 'delete')
				return onDelete(__panel._id)
			}
			catch(err) {
				notify.error(err.message)
			}
			return confirmDelete(false)
		}
		confirmDelete(true)
	}

	return (
		<Card>
			<Card.Header>
				<Toggle eventKey={eventKey}/>
				<span>{__panel.name} - {__panel.capacity}kw</span>
				<div className="btn-right">
		      		<EditButton label={`Edit panel "${__panel.name}"`} size="sm" onClick={() => setEdit(true)}/>
		      		<DeleteButton label={`Delete panel "${__panel.name}"`} onClick={() => deletePanel()} size="sm"/>
		      	</div>
			</Card.Header>
			{confirmRequired?(
				<Card.Body>
					<ConfirmAlert
							rightJustify
							text={`Delete ${__panel.name}?`}
							onYes={() => deletePanel(true)}
							onNo={() => confirmDelete(false)}
						/>
				</Card.Body>
			):
    		(<Accordion.Collapse eventKey={eventKey}>
      			<Card.Body>
      				<p>Tilt={`${__panel.tilt}°`}, azimuth={`${__panel.azimuth}°`}</p>
      				<p>Efficiency={`${__panel.efficiency}%`}, losses={`${__panel.losses}%`}</p>
      				<p>Array type={`${getText(__panel.array_type, ARRAY_TYPES)}`}, 
      					module type={`${getText(__panel.module_type, MODULE_TYPES)}`}</p>
      			</Card.Body>
    		</Accordion.Collapse>)}
		</Card>
	)
}

function EditScenario({visible=false, mode='add', _id=null}) {

	const [fields, setFields] = useState(DEFAULT)
	const [initial, setInitial] = useState(DEFAULT)
	const [mapLatitude, setMapLatitude] = useState(DEFAULT.latitude)
	const [mapLongitude, setMapLongitude] = useState(DEFAULT.longitude)
	const [loading, setLoading] = useState(false)
	const [canSave, enableSave] = useState(false)
	const [saveAs, setSaveAs] = useState(null)
	const [redirect, setRedirect] = useState(null)
	const [confirmRequired, setConfirm] = useState(false)
	const [askAddPanels, addPanelsPrompt] = useState(false)
	const [id, setId] = useState(_id)
	const [isEdit, setEdit] = useState(false)
	const [panels, setPanels] = useState([])
	const [__addPanel, addPanel] = useState(false)
	const [billingPlans, setBillingPlans] = useState([])

	useEffect(() => {
		const loadScenario = async () => {
			addPanelsPrompt(false)
			setRedirect(null)
			if(mode==='add') {
				// Adding
				setEdit(false)
				setFields({...DEFAULT})
				setInitial({...DEFAULT})
				setMapLatitude(DEFAULT.latitude)
				setMapLongitude(DEFAULT.longitude)
			}
			else {
				// Editing
				// Need to load the record
				setEdit(true)
				try {
					const scenario = await request.call(`/api/scenario/solar/${_id}`)
					setId(_id)
					const { name, description="", location, billing_plan_id="", cluster_sales_plan_id="", panels=[] } = scenario,
						[latitude, longitude] = location ;
					const initial = { name, description, latitude, longitude, billing_plan_id, cluster_sales_plan_id}
					setInitial(initial)
					setFields({...initial})
					setPanels([...panels])
					setMapLatitude(latitude)
					setMapLongitude(longitude)
				}
				catch(err) {
					notify.error(err.message)
				}
			}
		}

		const loadBillingPlans = async() => {
			try {
				const __plans = await request.call(`/api/billable/plan?type=Cluster`)
				setBillingPlans(__plans)
			}
			catch(err) {
				notify.error(err.message)
			}
		}

		setLoading(true)
		Promise.allSettled([loadScenario(), loadBillingPlans()])
		.then(() => {
			setLoading(false)
		})

		return () => {
			setId(null)
			setLoading(false)
			setDisabled({disabled: false})
		}
	}, [mode, _id])

	const [__disabled, setDisabled] = useState({disabled: false})
	useEffect(() => {
		setDisabled({disabled: loading}) 

		return () => {
			setLoading(false)
			setDisabled({disabled: false})
		}
	}, [loading])

	useEffect(() => {
		return () => {
			setRedirect(null)
		}
	}, [redirect])

	const [errors, setErrors] = useState(ATTRS.reduce((a, c) => { a[c]=false; return a}, {}))
	const [mapVisible, showMap] = useState(false)

	async function loadPanels() {
		try {
			const __panels = await request.call(`/api/scenario/solar/${id}/panels`)

			setPanels(__panels)
			// setKeyBase(new Date().getTime())
		}
		catch(err) {
			notify.error(err.message)
		}
	}

	async function handleSaveAs() {
		const { name, longitude, latitude } = fields ;
		const __valid = isValid('name', name) && 
			isValid('latitude', latitude) && 
			isValid('longitude', longitude) ;
		if(__valid) {
			setSaveAs({...fields, name: `Copy of ${fields.name}`, panels: panels || []})
		}
	}

	async function save() {
		const { name, description, longitude, latitude, billing_plan_id="", cluster_sales_plan_id="" } = fields ;
		try {
			const __valid = isValid('name', name) && 
				isValid('latitude', latitude) && 
				isValid('longitude', longitude) ;
			if(__valid) {
				const data = { 
					name, 
					description, 
					location: [parseFloat(latitude), parseFloat(longitude)]
				}
				if(!IS_EMPTY.test(billing_plan_id)) {
					data.billing_plan_id=billing_plan_id
				}
				if(!IS_EMPTY.test(cluster_sales_plan_id)) {
					data.cluster_sales_plan_id=cluster_sales_plan_id
				}
				if(!isEdit) {
					const __new = await request.call(`/api/scenario/solar`, 
						'put',
						{ 
							data
						})
					setId(__new._id)
					addPanelsPrompt(true)
				}
				else {
					await request.call(`/api/scenario/solar${mode==='edit'?`/${_id}`:''}`, 
						'put',
						{ 
							data
						})
					setRedirect('/list')
				}
			    notify.info(`${isEdit?"Updated":"Created"} solar scenario "${name}"`) ;
			}
		}
		catch(err) {
		    notify.error(err.message) ;
		}
	}

	function hasChanged() {
		if(initial===null) return false
		const tmp =  ATTRS.reduce((en, k) => en || initial[k]!==fields[k], false) 
		return tmp
	}

	function saveButtonEnabled() {
		const __noErrors = ATTRS.reduce((en, k) => en && errors[k]===false, true)
		if(__noErrors) {
			return hasChanged()
		}
		return false ;
	}

	function isValid(name, value) {
		errors[name] = false ;

		switch(name) {
			case 'name':
				if(value.length<2) {
					errors.name = 'Name must be at least 2 characters'
				}
				break ;
			case 'latitude':
			case 'longitude':
				if(value.length===0) {
					errors[name] = `Value required for ${name}`
				}
				else {
					// Make sure value is nuneric
					const __v = parseFloat(value)
					if(isNaN(value) || isNaN(__v)) {
						errors[name] = `Must be numeric value`
					}
					else {
						// Check range
						const __range = name==='latitude'?[-90, 90]:[-180, 180]
						if(__v<__range[0] || __v>__range[1]) {
							errors[name] = `${name} must be in range ${__range[0]} - ${__range[1]}`
						}
					}
				}
				break ;
			default:
				break
		}
		fields[name] = value ;
		setFields({...fields})
		setErrors({...errors})
		enableSave(saveButtonEnabled()) ;
		return errors[name] === false ;
	}

	function onChange(e) {
		const { value="", name } = e.target ;
		isValid(name, value)
	}


	function cancel(e) {
		if(e===true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			setRedirect('/list')
		}
		setConfirm(true)
	}

	function updateMapCoords() {
		const { latitude: errLat, longitude: errLon} = errors
		if(!errLat && !errLon) {
			// Set the coordinates for the map
			const { latitude, longitude} =fields
			setMapLatitude(latitude)
			setMapLongitude(longitude)
		}
	}

	const ButtonSave = () => (<Button type="button" onClick={save} variant="re-primary" disabled={!canSave}>
		<i className="fas fa-save"/>Save
	</Button>)

	return redirect!==null?
	(<Redirect to={`/dashboard/scenario/solar${redirect}`}/>):
	(
		<Card className="settings"><Container className="small-spacing" style={{marginLeft: "0px", marginRight: "0px"}}>
			<Row>
				<Col className="headerTable">
					<h2>{`${isEdit?"Edit":"Add"} Scenario`}</h2>
				</Col>
			</Row>
			{saveAs!==null?(<SaveAsDialog 
				data={saveAs} 
				onCancel={() => setSaveAs(null)}
				onSave={(o) => {
					setSaveAs(null)
					notify.info(`Saved copy of "${fields.name}"`)
					setRedirect(`/${o._id}/edit`)
				}}/>
				):null}
			{ askAddPanels?
        		(<Row><Col lg="4"><ConfirmAlert 
        			heading="Add panels to scenario?"
        			variant="light"
        			onNo={() => setRedirect('/list')}
        			onYes={() => setRedirect(`/${id}/edit`)}
        			/></Col></Row>):
	        	(<form onSubmit={save} noValidate>
		      		<div className="form-group">
	              		<Form.Row>
	              			<Col lg="3">
				                <label className="on">Name</label>
				                <input
				                  className="form-control"
				                  type="text"
				                  required
				                  {...__disabled}
				                  name="name"
				                  id="name"
				                  onChange={onChange}
				                  placeholder="Enter scenario name"
				                  value={fields.name}
				                />
				                {errors.name && (
				                  <span className="error">{errors.name}</span>
				                )}
	              			</Col>
	              		</Form.Row>
	              		<Form.Row>
	              			<Col lg="3">
								<Form.Group>
					                <Form.Label>Description</Form.Label>
				                <Form.Control
				                  type="text"
				                  {...__disabled}
				                  name="description"
				                  id="description"
				                  onChange={onChange}
				                  placeholder="Enter description"
				                  value={fields.description}
				                />
				                {errors.description && (
				                  <span className="error">{errors.description}</span>
				                )}
								</Form.Group>
	              			</Col>
	              		</Form.Row>
	              		<Form.Row>
	              			<Col md="2">
								<Form.Group>
									<label className="on">Lat</label>
									<input
										className="form-control"
										type="text"
										{...__disabled}
										name="latitude"
										id="latitude"
										onChange={onChange}
										onKeyUp={(e) => e.key==='Enter' && updateMapCoords()}
										onBlur={() => updateMapCoords()}
										placeholder="Latitude"
										value={fields.latitude}
										/>
									{errors.latitude && (
										<span className="error">{errors.latitude}</span>
									)}
								</Form.Group>
	              			</Col>
	              			<Col md="2">
								<Form.Group>
									<Form.Label>Longitude</Form.Label>
									<Form.Control
										type="text"
										{...__disabled}
										name="longitude"
										id="longitude"
										onChange={onChange}
										onKeyUp={(e) => e.key==='Enter' && updateMapCoords()}
										onBlur={() => updateMapCoords()}
										placeholder="Longitude"
										value={fields.longitude}>

									</Form.Control>
									{errors.longitude && (
					                  <span className="error">{errors.longitude}</span>
				                )}
								</Form.Group>
	              			</Col>
	              			<Col className="d-flex align-items-center" style={{paddingLeft: "0px"}}>
	              				<Button variant="link"><i className="fas fa-2x fa-map-marker-alt" onClick={() => showMap(!mapVisible)}></i></Button>
	              			</Col>
	              		</Form.Row>
						<Form.Row>
						<Col md={3}>
							<Form.Group>
								<Form.Label>Billing plan (spill)</Form.Label>
								<Form.Control as="select" name="billing_plan_id" value={fields.billing_plan_id} onChange={onChange} custom>
									<option key="billing_plan_none" value="">None</option>
									{billingPlans.map(({_id, name}) => (
										<option key={`billing_plan_${_id}`} value={_id}>{name}</option>)
									)}
								</Form.Control>
								<Form.Text>Select an optional billing plan to calculate sales from energy spillage to the grid</Form.Text>
							</Form.Group>
						</Col>
						<Col md={3}>
							<Form.Group>
								<Form.Label>Billing plan (cluster sales)</Form.Label>
								<Form.Control as="select" name="cluster_sales_plan_id" value={fields.cluster_sales_plan_id} onChange={onChange} custom>
									<option key="cluster_sales_plan_none" value="">None</option>
									{billingPlans.map(({_id, name}) => (
										<option key={`cluster_sales_plan_${_id}`} value={_id}>{name}</option>)
									)}
								</Form.Control>
								<Form.Text>Select an optional billing plan to calculate sales from energy sales to the cluster</Form.Text>
							</Form.Group>
							</Col>
						</Form.Row>
	              		{mapVisible?
	              			(<Row>
	          					<Col>
	  								<LocationChooser 
	  									visible={mapVisible}
	  									onDismiss={() => showMap(false)}
	  									closable
										height="480px"
										latitude={mapLatitude} longitude={mapLongitude} 
										onPositionChange={(lat, lon) => {
											fields.latitude = lat; fields.longitude=lon
											setFields({...fields})
											enableSave(saveButtonEnabled()) ;
									}}/>
	          					</Col>
	          				</Row>
						):null}
		      		</div>
		      		{isEdit?(
					<Row>
						<Col {...FORM_WIDTH}>
							<div className="headerTable">
				  				<h5>Solar Panels</h5>
								<div className="btn-right">
									<AddButton label="Add panel" onClick={() => addPanel(true)} size="1x"/>
								</div>
							{__addPanel?(
								<EditSolarPanel visible={__addPanel}
									scenario_id={id}
									onSave={async (p) => {
										addPanel(false)
										loadPanels(id)
									}}
									onCancel={() => { addPanel(false)}}
								/>
							):null}
							</div>
							<hr/>

							{panels.length>0?
							(<Accordion>
								{panels.map((p,i) => (<PanelRow 
									scenarioId={id} 
									key={`pnlr_${uuid()}`} 
									eventKey={p._id} 
									panel={p}
									onDelete={async() => {
										await loadPanels() 
									}}/>))}
							</Accordion>
							):<div>No panels defined</div>}
						</Col>
					</Row>
		      		):null}
		      		<div className="form-group save-cancel">
	              		<Row>
	              			<Col {...FORM_WIDTH}>
	              				{confirmRequired?(<ConfirmAlert
	              					heading="Details have changed"
	              					text="Scenario details have been changed, really cancel?"
	              					buttonsInline={false}
	              					onNo={() => setConfirm(false)}
	              					onYes={() => cancel(true)}/>
				            	):
				            	(<div className="form-group btn-right">
				            		<Button 
				            			type="button" 
				            			variant="outline-secondary" 
				            			onClick={cancel}
				            			style={{marginRight: "1rem"}}>
				            			<i className="fas fa-times"/>Cancel
				            		</Button>
				            		{isEdit?(<Dropdown as={ButtonGroup} style={{margin: 0}}>
					            		<ButtonSave onClick={save}/>
					            		<Dropdown.Toggle split variant="re-primary"/>
					            		<Dropdown.Menu>
					            			<Dropdown.Item onSelect={save} disabled={!canSave}>Save</Dropdown.Item>
					            			<Dropdown.Item onSelect={handleSaveAs}>Save as</Dropdown.Item>
					            		</Dropdown.Menu>
				            		</Dropdown>):(<ButtonSave onClick={save}/>)}
					            </div>)}
	              			</Col>
	              		</Row>
	              	</div>
				</form>)}
		</Container></Card>
	)
}


export default EditScenario