import {Request} from "../../../../services/HttpService";
import { Notify } from '../../../components/AlertListener.jsx'
import SessionStorage from '../../../../services/SessionStorage'

const notify=Notify("solar.notifications")
const request = new Request({ service: 'Scenario service'})

const SCENARIO_ENERGY_SPLIT = 'scenario.settings.energysplit'

export default class Settings {
    constructor({clusterId=null}) {
        if(clusterId===null) {
            throw new Error("No cluster ID provided")
        }
        this.clusterId = clusterId
        this.__cluster = null
        this.__split = null
    }

    async getCluster() {
        if(!Array.isArray(this.__cluster)) {
            try {
                const __members = await request.get(`/api/cluster/${this.clusterId}/members`)
                this.__cluster = __members
            }
            catch(err) {
                notify.error(err.message)
                this.__cluster = []
            }
        }
        return this.__cluster
    }

    async getEnergySplit() {
        try {
            const __settings = await request.get(`/api/scenario/settings`)
            return __settings.energy_split || []
        }
        catch(err) {
            notify.error(err.message)
            return []
        }
    }

    async setEnergySplit(energy_split) {
        try {
            await request.call(`/api/scenario/settings`,
                'put',
                {
                    data: {
                        energy_split
                    }
                })
        }
        catch(err) {
            notify.error(err.message)
            return []
        }
    }
}