//import { today_iso, tomorrow_iso, yesterday_iso,day2tomorrow } from "./Times"
import moment from "moment";
import SpearmanRHO from "spearman-rho";
//import SolarCalulator from "./calculator.js";

export const dateNow = new Date();

export const tomorrowStart = new Date(dateNow);
tomorrowStart.setDate(tomorrowStart.getDate() + 1);

export const yesterdayStart = new Date(dateNow);
yesterdayStart.setDate(yesterdayStart.getDate() - 1);

export const lastDate = new Date(dateNow);
lastDate.setDate(lastDate.getDate() + 3);

export const currentday = moment();
export const today_format = currentday.format("YYYY-MM-DD");
export const today_iso = moment(today_format).toISOString();
export const tomorrow_date = moment(currentday)
  .add(1, "days")
  .format("YYYY-MM-DD");
export const tomorrow_iso = moment(tomorrow_date).toISOString();

export const yesterday = moment()
  .add(-1, "days")
  .format("YYYY-MM-DD");
export const yesterday_iso = moment(yesterday).toISOString();

export const dayaftertomorrow = moment(currentday)
  .add(2, "days")
  .format("YYYY-MM-DD");
export const day2tomorrow = moment(dayaftertomorrow).toISOString();

export const timeLine = getDates(yesterday_iso, day2tomorrow);


var hoursPerDay = 24;
var timeToday = [];
var timetomorrow = [];
var timeyesterday = [];
var i;

export function __filterDay(start, items, fillitems) {
  const __end = new moment(start).utc().add(1, "day"),
    __filtered = items.filter(i => {
      const { timestamp } = i;
      return new moment(timestamp).utc().isBetween(start, __end, "hour", "[]");
    });
  if (__filtered.length === 0 || !fillitems) return __filtered;
  const __curr = new moment(start);
  let __dataNdx = 0;
  const __padded = [];
  while (__curr.isSameOrBefore(__end) && __dataNdx < __filtered.length) {
    const __dataPt = __filtered[__dataNdx];
    if (!__dataPt || __curr.isBefore(__dataPt.timestamp)) {
      __padded.push({ timestamp: __curr });
    } else {
      __padded.push(__dataPt);
      __dataNdx += 1;
    }
    __curr.add(1, "hour");
  }
  return __padded;
}

export function __filterAll(start, timeend, items, fillitems) {
  const __end = new moment(timeend).utc(),
    __filtered = items.filter(i => {
      const { timestamp } = i;
      return new moment(timestamp).utc().isBetween(start, __end, "hour", "[]");
    });
  if (__filtered.length === 0 || !fillitems) return __filtered;
  const __curr = new moment(start);
  let __dataNdx = 0;
  const __padded = [];
  while (__curr.isSameOrBefore(__end) && __dataNdx < __filtered.length) {
    const __dataPt = __filtered[__dataNdx];
    if (!__dataPt || __curr.isBefore(__dataPt.timestamp)) {
      __padded.push({ timestamp: __curr });
    } else {
      __padded.push(__dataPt);
      __dataNdx += 1;
    }
    __curr.add(1, "hour");
  }
  return __padded;
}

export function filterYesterday(items, fillitems = true) {
  return __filterDay(new moment(yesterday_iso).utc(), items, fillitems);
}

export function filterToday(items, fillitems = true) {
  return __filterDay(new moment(today_iso).utc(), items, fillitems);
}

export function filterTomorrow(items, fillitems = true) {
  return __filterDay(new moment(tomorrow_iso).utc(), items, fillitems);
}

export function filterReport(timestart, timeend, items, fillitems = true) {
  return __filterAll(new moment(timestart).utc(), timeend, items, fillitems);
}

let numOr0 = n => (isNaN(n) ? 0 : n);
export function total_today_load(array) {
  if (array.length) {
    return array.reduce((a, b) => numOr0(a) + numOr0(b));
  }
}

export function total(array) {
  if (array.length) {
    return array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  }
}

export function timeOneDay() {
  var formattedTime;
  for (i = 0; i < hoursPerDay + 1; i++) {
    formattedTime = moment(tomorrow_iso)
      .subtract(i, "hours")
      .toISOString();
    timeToday.unshift(formattedTime);
  }
}
timeOneDay();

export function timesTomorrow() {
  var formattedTime;
  for (i = 0; i < hoursPerDay + 1; i++) {
    formattedTime = moment(day2tomorrow)
      .subtract(i, "hours")
      .toISOString();
    timetomorrow.unshift(formattedTime);
  }
}
timesTomorrow();

export function timeYesterday() {
  var formattedTime;
  for (i = 0; i < hoursPerDay + 1; i++) {
    formattedTime = moment(today_iso)
      .subtract(i, "hours")
      .toISOString();
    timeyesterday.unshift(formattedTime);
  }
}
timeYesterday();

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = moment(startDate);
  var endDate = moment(stopDate);
  while (currentDate <= endDate) {
    dateArray.push(moment(currentDate).format("DD/MMM HH:00"));
    currentDate = moment(currentDate).add(1, "hour");
  }
  return dateArray;
}

export function getArray(data) {
  data.map(a => a.reading.toFixed(3));
}



export function getComarisonValues(arrayRead, arrayPredict) {

  var mean_sq_error = null;
  var maseF = null;
  //var reading_array = [];
  var reading_length = arrayRead.length;
  //var prediction_array = [];

  if (arrayRead.length && arrayPredict.length) {
    const diff_all = arrayRead.map(function(num, idx) {
      return Math.pow(parseFloat(arrayPredict[idx] - parseFloat(num)), 2);
    });

    const sum_diff_all = total_today_load(diff_all).toFixed(2);
    mean_sq_error = (sum_diff_all / reading_length).toFixed(3);
    //  console.log('mean_sq_error',mean_sq_error)

    //maseF
    let n = reading_length;
    let diffs = 0;
    let d = 0;
    for (let i = 0; i < n - 1; i++) {
      let a = arrayRead[i];
      let b = arrayRead[i + 1];

      diffs += Math.abs(b - a);
    }
    d = diffs / (n - 1);
    let errors = 0;
    for (let j = 0; j < n; j++) {
      let a = arrayRead[j];
      let b = arrayPredict[j];

      errors += Math.abs(b - a);
    }
    let err_mean = errors / n;
    let mase = err_mean / d;
    maseF = mase.toFixed(3);

    //console.log('maseF',maseF)
  }

  return {
    mean_sq_error,
    maseF
  };
}








//SpearmanRH



export function getDataDifferences(readings=[], predicted=[], timeline) {
  var array = [];
  var total = [];
  //const r = loadReadingArray.map(a => a.reading.toFixed(3));
  const reading_array = readings[0];
  const predicted_array = predicted[0];

  var differences = [];
  array = [];
  total = predicted_array.map(function(prediction, idx) {
    array.push({
      _id: Math.random(),
      timestamp: timeline[idx] ? timeline[idx] : "-",
      prediction: prediction >= 0 ? prediction : "-",
      predictionTotal: prediction && reading_array[idx] ? prediction : "-",
      reading: reading_array[idx] >= 0 ? reading_array[idx] : "-",
      readingTotal: prediction && reading_array[idx] ? reading_array[idx] : "-",
      difference:
        prediction && reading_array[idx]
          ? (parseFloat(reading_array[idx]) - parseFloat(prediction)).toFixed(3)
          : "-",
      percentage:
        prediction && reading_array[idx]
          ? (
              ((parseFloat(reading_array[idx]) - parseFloat(prediction)) /
                parseFloat(reading_array[idx])) *
              100
            ).toFixed(3)
          : "-"
    });
    return array;
  });
  differences = total[0];
  return differences;
}

 export function getspearmanRHO(arrayRead, arrayPredict){
  var spearmanRHO
  const x = arrayPredict;
  const y = arrayRead;
  spearmanRHO = new SpearmanRHO(x, y);
  spearmanRHO.calc()
   .then(value => {
     getValue(value)
   })
   .catch(err => console.error(err));

}

export function getValue (value){
//console.log('value', value)
const r2 = (value*100).toFixed(3)
return r2
//this.setState({r2:r2})
//console.log('r2', r2)
}
