import React, { useState, useEffect, useCallback } from 'react'
import Decimal from 'decimal.js-light'
import {Form, Col} from 'react-bootstrap'
import { noop } from '../../components/UIComponents'

const __default = {
    required: true, 
    default: false
}

const VALID_INT=/^[+-]?[0-9][0-9]*$/,
    VALID_DECIMAL=/^([+-]?[0-9][0-9]*)|([+-]?[0-9]*\.[0-9][0-9]*)$/

export default function CheckboxControl({descriptor={}, value: __value, lg=3, onChange=noop}) {
    const [props, setProps] = useState(null)
    const [value, setValue] = useState(__value)
    
    useEffect(() => {
        const { name, label, required, default: __dflt } = { ...__default, ...descriptor }

        if(typeof(__value)!=="boolean") {
            setValue(__dflt)
        }

        setProps({
            name,
            label: required?`(*) ${label}`:label,
            required,
            placeholder: label
        })
    }, [])

    function toggle() {
        setValue(!value)
    }

    useEffect(() => {
        // Update the parent
        onChange(value)
    }, [value])

    return props && (
    <Form.Group as={Col} lg={lg} controlId={`input_${props.name}`}>
        <Form.Check
            style={{ position: "absolute", bottom: "0"}}
            custom
            type="checkbox"
            {...props}
            checked={value}
            onChange={toggle}/>
    </Form.Group>)
}