import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import UserContext from "../../services/user-context"
import {noop} from '../components/UIComponents'
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import SessionStorage from "../../services/SessionStorage"

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

const SELECTED_SITE_KEY = 'site.dash.site.selected',
    NO_SITES = { value: "", label: "-- No sites --"}

export default function SiteSelector({onChange=noop, titlePre="Site"}) {
    const [title, setTitle] = useState(null)
    const [sites, setSites] = useState([NO_SITES])
    const [selectedSite, setSelectedSite] = useState(null)

    const { user } = useContext(UserContext)

    useEffect(() => {
        if(user===null) return ;

        const { organisationId } = user

        const __loadSites = async () => {
            try {
                let __sites = await request.get(`/api/organisation/${organisationId}/site`, {
                    tenancyView: true,
                    full: true
                })
                
                if(__sites.length===0) {
                    setSites([NO_SITES])
                    return ;
                }

                const __tree=[]
                
                // Filter out all sites whose parent ID is not in set
                const __ids = __sites.map(({_id}) => _id)
                const __filtered = __sites.map(({parent_id, ...rest}) => {
                    if(!parent_id || parent_id==='' || !__ids.includes(parent_id)) return rest
                    return {
                        ...rest,
                        parent_id
                    }
                })

                function addNode({_id, name}, depth=0) {
                    __tree.push({ _id, value: _id, label: `${depth>0?'- ':''}${name}`, name, depth})
                    // Need to add child nodes?
                    const children = __filtered.filter(({parent_id}) => _id===parent_id)
                    for(const c of children) {
                        addNode(c, depth+1)
                    }
                }
                const __top=__filtered.filter(({parent_id}) => !parent_id)
                for(const n of __top) {
                    addNode(n)
                }
                // console.log('(tree) setSites() ', __tree)
                setSites(__tree)
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }
        __loadSites()

    }, [user])

    useEffect(() => {
        if(sites===null) return 
        let __selected = SessionStorage.get(SELECTED_SITE_KEY, "")
        // console.log(`Site filter "${__selected}" - sites=`, sites)
        const [__site] = sites.filter(({value}) => value===__selected)
        if(__site) {
            setSelectedSite(__site)
        }
        else {
            // console.log(`setSelectedSite(${sites[0]}`)
            setSelectedSite(sites[0])
        }
    }, [sites])

    const onSiteChange = useCallback((site) => {
        setSelectedSite(site)
        SessionStorage.set(SELECTED_SITE_KEY, site.value)
    }, [sites])

    useEffect(() => {
        // console.log(`[selectedSite] : `, selectedSite)
        if(selectedSite) {
            const { _id, name } = selectedSite
            if(_id) {
                setTitle(`${titlePre} - ${name}`)
            }
        }
        onChange(selectedSite)
        return () => {
            setTitle(null)
        }
    }, [selectedSite])

    return (<Row>
        <Col>
            <Form className="dropdown">
                <Form.Row className="align-items-center">
                    <Col xs="auto"><h2>{title}</h2></Col>
                    <Col style={{textAlign: 'right'}}><h4>Site</h4></Col>
                    <Col xs={3} style={{float: 'right'}}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={selectedSite}
                            options={sites}
                            onChange={onSiteChange}
                            styles={{
                                option: (styles, {data={}}) => {
                                    const {depth=0} = data
                                    return {
                                        ...styles,
                                        paddingLeft: `${depth*2}rem`
                                    }
                                }
                            }}
                            />

                    </Col>
                </Form.Row>
            </Form>
        </Col>
    </Row>
)
}