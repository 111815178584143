import React, { Component } from "react";
import {Button} from 'react-bootstrap'
import {
	Switch,
	Route,
	Link,
	Redirect
} from "react-router-dom";
import ScenarioList from './SolarScenarioList.jsx'
import ScenarioSettings from './settings/SolarScenarioSettings.jsx'
import EditScenario from './EditSolarScenario2.jsx'
import RunScenario from './RunScenario.jsx'
import SelectPVInstallation from './SelectPVInstallation.jsx'
import {AlertContainer} from '../../components/AlertListener.jsx'
import Auth from "../../../services/auth";
import Settings from './settings'

import { Notify } from '../../components/AlertListener.jsx'
const notify=Notify("solar.notifications")


const SETTINGS_PATH="/dashboard/scenario/solar/settings"

export default class SolarDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            settings: null
        }
    }

    async componentDidMount() {
        try {
            const __user = await Auth.isAuthenticated()
            const settings = new Settings({clusterId: __user.clusterId})
            this.setState({settings})
        }
        catch(err) {
            notify.error(err.message)
        }
    }

	render() {
        const { settings } = this.state
        const { location } = this.props
		return (
			<React.Fragment>
				<AlertContainer tag="solar.notifications" page="solar"/>
				{location.pathname!==SETTINGS_PATH?(<Link to={{
                    pathname: SETTINGS_PATH,
                    state: {
                        from: location.pathname
                    }}}
									style={{
										position: "absolute",
										right: "1rem",
										marginTop: "0.5rem",
										marginRight: "0.5rem",
										zIndex: 10
									}}>
				<Button as="span" variant="light"
						style={{
							fontSize: "1.5rem", 
							color:"#888",
							padding: "0.2rem",
						}}>
					<i className="fas fa-cog"/>
				</Button></Link>):null}
				{/*<Button as="span" variant="light" style={{fontSize: "2rem", color:"#555"}}><i className="fas fa-ellipsis-h"/></Button>*/}
                <Switch>
					<Route path="/dashboard/scenario/solar/list" exact>
							<React.Fragment>
							<ScenarioList/>
							</React.Fragment>
						</Route>
                    <Route path="/dashboard/scenario/solar/settings" exact>
                        <React.Fragment>
                        <ScenarioSettings settings={settings}/>
                        </React.Fragment>
                    </Route>
                    <Route path="/dashboard/scenario/solar/new" exact>
                        <React.Fragment>
                        <EditScenario/>
                        </React.Fragment>
                    </Route>
                    <Route path="/dashboard/scenario/solar/from-installation" exact>
                        <SelectPVInstallation/>
                    </Route>
                    <Route 
                        path="/dashboard/scenario/solar/:id([a-fA-F0-9]{24})/edit"
                        exact
                        render={({match, props, location}) => (
                            <EditScenario mode="edit" _id={match.params.id}/>
                        )}
                    />
                    <Route 
                        path="/dashboard/scenario/solar/:id([a-fA-F0-9]{24})/run"
                        exact
                        render={({match, props, location}) => (
                            <RunScenario _id={match.params.id}/>
                        )}
                    />
                    <Route 
                        path="/dashboard/scenario/solar/:id([a-fA-F0-9]{24})/run/:month(0[1-9]|1[0-2])"
                        exact
                        render={({match, props, location}) => (
                            <RunScenario _id={match.params.id} month={match.params.month}/>
                        )}
                    />
                    <Route 
                        path="/dashboard/scenario/solar/:id([a-fA-F0-9]{24})/run/:month(0[1-9]|1[0-2])/:day(0[1-9]|[12][0-9]|3[01])"
                        exact
                        render={({match, props, location}) => (
                            <RunScenario _id={match.params.id} month={match.params.month} day={match.params.day}/>
                        )}
                    />
                    <Route>
                        <Redirect to="/dashboard/scenario/solar/list" />
                    </Route>
                </Switch>
			</React.Fragment>
		)
	}
}

