import React, {useState, useEffect, useContext, useCallback} from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import ClusterMeters from "../settings/cluster/ClusterMeters"
import ThreeDayPlot from "../plots/ThreeDayPlot"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import SessionStorage from "../../services/SessionStorage"
import Decimal from 'decimal.js-light'
import WidgetPanel from "../widgets/WidgetPanel";

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

const SELECTED_ORG_KEY = 'cluster.dash.org.selected'

const ALL_ORGS = [{ value: "all", label: "All Organisations"}]

export default function ClusterDashboard() {
    const [title, setTitle] = useState(null)
    const [cluster, setCluster] = useState(null)
    const [loadData, setLoadData] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedLoadData, setPredictedLoadData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)
    const [wholesaleData, setWholesaleData] = useState(null)
    const [tariffData, setTariffData] = useState(null)
    const [clusterMembers, setClusterMembers] = useState(null)
    const [selectedOrg, setSelectedOrg] = useState(null)

    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(user===null || !user.clusterId) return ;

        const { clusterId : id } = user

        async function getCluster() {
            try {
                const c = await request.get(`/api/cluster/${id}`)
                setCluster(c)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        getCluster()

        const __loadClusterMembers = async () => {
            try {
                const __members = await request.get(`/api/cluster/${id}/members`)
                setClusterMembers([
                    ...ALL_ORGS, 
                    ...__members.map(({_id, name}) => ({value: _id, label: name}))
                ])
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }
        __loadClusterMembers()
        Fetch(`/api/billable/semo/price`, {
            from: Time.yesterday().format(),
            // Add day + 1 minute to include midnight at end
            to: Time.tomorrow().add({day: 1, minute: 1}).format(),
        }, (semo) => {
            setWholesaleData(semo.map(({timestamp, price}) => ({
                timestamp, 
                // Convert from €/mwh to c/kwh
                price: Decimal(price).mul(100).div(1000).toDecimalPlaces(1).toNumber()
            })))
        })

    }, [user])

    useEffect(() => {
        if(clusterMembers===null) return 
        let __selected = SessionStorage.get(SELECTED_ORG_KEY, "all")
        const [__org] = clusterMembers.filter(({value}) => value===__selected)
        if(__org) {
            setSelectedOrg(__org)
        }
        else {
            setSelectedOrg(clusterMembers[0])
        }

    }, [clusterMembers])

    useEffect(() => {
        if(selectedOrg===null || clusterMembers===null) return

        const __query= {
            from: Time.yesterday().format(),
            // Add day + 1 minute to include midnight at end
            to: Time.tomorrow().add({day: 1, minute: 1}).format(),
            price: 1
        }
        const { clusterId, clusterName } = user
        if(selectedOrg.value==="all") {
            // Fetch cluster data
            setTitle(`Cluster - ${clusterName}`)

            // Actual load
            Fetch(`/api/meter/cluster/${clusterId}/readings`, __query, setLoadData, ["timestamp", "reading", "price", "charges"])
            // Predicted load
            Fetch(`/api/cluster/${clusterId}/predict/load`, __query, setPredictedLoadData)
            // // Actual supply
            Fetch(`/api/meter/cluster/${clusterId}/readings`, 
                    { ...__query, meter_type: 'supply'}, 
                    setSupplyData,
                    ["timestamp", "reading"],
                    excludeNegativeFilter())
            // __fetch(`/api/cluster/${clusterId}/aggregate/supply`, setSupplyData)
            // Predicted supply
            Fetch(`/api/cluster/${clusterId}/predict/supply`, __query, setPredictedSupplyData)
        }
        else {
            // Actual load
            const {value: _id, label: name} = selectedOrg
            setTitle(`Member - ${name}`)

            Fetch(`/api/meter/organisation/${_id}/readings`, __query, setLoadData, ["timestamp", "reading", "price", "charges"])
            // Predicted load
            Fetch(`/api/cluster/${clusterId}/organisation/${_id}/predict/load`, __query, setPredictedLoadData)
            // Actual supply
            Fetch(`/api/meter/organisation/${_id}/readings`, 
                    { ...__query, meter_type: 'supply'}, 
                    setSupplyData,
                    ["timestamp", "reading"])
            // Predicted supply
            Fetch(`/api/cluster/${clusterId}/organisation/${_id}/predict/supply`, __query, setPredictedSupplyData)
            // Get the wholesale rates
            Fetch(`/api/cluster/${clusterId}/organisation/${_id}/billing/rates`, __query, setTariffData)
            // Fetch(`/api/billable/organisation/${_id}/rates`, __query, setTariffData)
        }

        return () => {
            setLoadData([])
            setPredictedLoadData([])
            setSupplyData([])
            setPredictedSupplyData([])
        }
    }, [selectedOrg])

    const onOrgChange = useCallback((org) => {
        setSelectedOrg(org)
        SessionStorage.set(SELECTED_ORG_KEY, org.value)
    }, [clusterMembers])

    if(user===null) return null

    return (
        <Container fluid>
            <Row>
            <Col>
                    <Form className="dropdown">
                        <Form.Row className="align-items-center">
                            <Col xs="auto"><h2>{title}</h2></Col>
                            <Col style={{textAlign: 'right'}}><h4>Organisation</h4></Col>
                            <Col xs={3} style={{float: 'right'}}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={selectedOrg}
                                    options={clusterMembers}
                                    onChange={onOrgChange}
                                    />

                            </Col>
                        </Form.Row>
                    </Form>
                </Col>

            </Row>
            <Row>
                <Col lg={10}>
                    <ClusterMeters cluster={cluster} redirect notable noheader/>
                    <ThreeDayPlot 
                        load={loadData} 
                        predictedLoad={predictedLoadData} 
                        supply={supplyData}
                        predictedSupply={predictedSupplyData} 
                        tariffs={tariffData}
                        wholesale={wholesaleData}
                    />
                </Col>
                <Col lg={2}>
                    <WidgetPanel load={loadData} solar={supplyData}/>
                </Col>
            </Row>
        </Container>
    )
}
