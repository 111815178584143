import React, { useState, useEffect, useRef, useCallback } from "react";
import imgSun from "../../../../assets/svg/sun.svg";

import {DEFAULT_WATT_HOUR_SCALER as defaultScaler, textWidth } from './utils'

export default function SolarWidget({readings=null, scaler=defaultScaler}) {
    const [totalSolar, setTotalSolar] = useState('--')
    const [units, setUnits] = useState('Wh')
    const [style, setStyle] = useState({})
    const [node, setNode] = useState(null)

    function useRefCB() {
        const ref = useRef(null)
        const setRef = useCallback(node => {
          if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
          }
          
          if (node) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
          }
          
          // Save a reference to the node
          ref.current = node
          setNode(ref.current || null)
        }, [])
        
        return [setRef]
    }
    const [__contentRef] = useRefCB(null)

    useEffect(() => {
        if(Array.isArray(readings) && node!==null) {
            const __tot=scaler.transform(readings.reduce((tot, r) => tot+r, 0))
            setTotalSolar(t => __tot)
            setUnits(scaler.units)
            setStyle({fontSize: textWidth(node, __tot, scaler.units)})

            return ;
        }
    }, [readings, node, scaler])

    return (
        <div className="component-card mini-card load">
            <img alt={"solar"} src={imgSun} />
            <h5>Solar Output</h5>
            <div ref={__contentRef} className="component-data">
                <p className="main-data" style={style}>
                    <span className="__value" style={{fontSize: "inherit", paddingRight: "0.4rem"}}>{totalSolar}</span>
                    <span id="__units" className="__units">{units}</span>
                </p>
            </div>
        </div>
    )
}