import React, { Component } from "react";
//import MeterServices from "../../services/MeterServices";
import AuthService from '../../services/auth'
import UserServices from "../../services/UserServices";
import SolarServices from "../../services/SolarServices";
import Dropdown from "../../utility/Dropdown2";
import { Modal, Card, Alert } from "react-bootstrap";
import AddInstallationForm from "./AddInstallation";
import PanelTable from "./PanelTable";
import AddPanelForm from "./AddPanel";
import Dialog from "react-bootstrap-dialog";

export default class SolarSettings extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      index_inst: 0,
      index_site: 0,
      noSites: false,
      noInst: false,
      orgId: null,
      sites: [],
      site_id: "",
      meters: [],
      installations: [],
      show: false,
      variant: "",
      msg: "",
      name: "",
      latitude: "",
      longitude: "",
      timezone: "",
      installation_id: "",
      active: true
    };
  }

  componentDidMount = async () => {
    
    try {
      const __user = await AuthService.isAuthenticated()
      console.log('Soler user = ', __user)
      this.setState({orgId: (__user.organisation && __user.organisation.id) || null})
      this.getSiteList(__user.organisationId);
    } catch (err) {
      console.error('Error ', err)
    }

    // setTimeout(() => {
    //   const { org_id } = this.props;
    //   this.setState({ org_id: org_id });
    //   this.getSiteList();
    // }, 1);
  };

  getSiteList = (orgId=this.state.orgId) => {
    console.log("org_id solar", orgId);

    UserServices.getSiteList(orgId)
      .then(response => {
        let sites = response.data;
        var site_id;
        if (sites.length) {
          site_id = sites[0]._id;
          this.setState({
            sites: sites,
            site_id: site_id,
            noSites: false,
            noInst: false,
            index_site: 0
          });
          //this.getMeterList(site_id);
          this.getInstalltionsList(site_id);
        } else {
          this.setState({ sites: [], noSites: true });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ clusters: [], noSites: true });
      });
  };

  onSiteSelected = e => {
    e.preventDefault();
    if (e.target.value !== "") {
      const index_site = e.target.value;
      const site = this.state.sites[e.target.value];
      const id = site._id;
      //this.getMeterList(id);
      this.getInstalltionsList(id);
      this.setState({ site_id: id, index_site: index_site, index_inst: 0 });
    }
  };

  getInstalltionsList = site_id => {
    SolarServices.getInstallationList()
      .then(response => {
        let i = response.data;
        const installations = i.filter(item => item.site_id === site_id);
        console.log("installations...", installations);

        if (installations.length) {
          const id = installations[0]._id;
          const name = installations[0].name;
          const longitude = installations[0].location.coordinates[0];
          const latitude = installations[0].location.coordinates[1];
          const timezone = installations[0].timezone;
          const active = installations[0].active;
          this.setState({
            noInst: false,
            installations: installations,
            installation_id: id,
            name: name,
            latitude: latitude,
            longitude: longitude,
            timezone: timezone,
            active: active
          });
          //console.log('id', id)
          this.child.current.getPanelList(id);
        } else {
          this.setState({
            installations: [],
            noInst: true
          });
          this.child.current.getPanelList();
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ installations: [], noInst: true });
      });
  };

  onInstSelected = e => {
    e.preventDefault();
    //console.log('solar ',e.target.value)
    if (e.target.value !== "") {
      const index_inst = e.target.value;
      const inst = this.state.installations[e.target.value];
      const id = inst._id;
      console.log("inst ", inst.location.coordinates[0]);
      const name = inst.name;
      const timezone = inst.timezone;
      const active = inst.active;
      const longitude = inst.location.coordinates[0];
      const latitude = inst.location.coordinates[1];
      this.setState({
        installation_id: id,
        name: name,
        latitude: latitude,
        longitude: longitude,
        index_inst: index_inst,
        timezone: timezone,
        active: active
      });
      this.child.current.getPanelList(id);
    }
  };

  addInstallation = () => {
    //console.log("site add");
    this.setState({
      installation_id: "",
      name: "",
      latitude: "",
      longitude: "",
      timezone: "",
      active: true,
      addInstallationModal: true,
      type: "Add"
    });
  };
  closeModal = () => {
    this.setState({ addInstallationModal: false, addPanelModal: false });
  };

  closeAdd = (variant, msg) => {
    //console.log("site add");
    const site_id = this.state.site_id;
    this.setState({ addInstallationModal: false, variant: variant, msg: msg });
    this.onShowAlert();
    this.getInstalltionsList(site_id);
    //window.location.reload();
  };

  addPanel = type => {
    //console.log("site add", type);
    this.setState({ addPanelModal: true, type: type });
  };

  editInst = () => {
    //console.log("site add");
    this.setState({ addInstallationModal: true, type: "Edit" });
  };

  closeAddPanel = (variant, msg) => {
    //console.log("site add");
    const id = this.state.installation_id;
    this.setState({ addPanelModal: false, variant: variant, msg: msg });
    this.onShowAlert();
    this.child.current.getPanelList(id);
    //window.location.reload();
  };

  setShow = () => {
    this.setState({ show: false });
  };

  onShowAlert = () => {
    //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
    });
  };

  deleteInst = () => {
    const name = this.state.name;
    this.dialog.show({
      body: "Would you like to delete'" + name + "'. Proceed ?",
      actions: [
        Dialog.CancelAction(),
        Dialog.Action("Proceed", () => this.deleteInstOK(), "btn-warning")
      ],
      bsSize: "medium"
    });
  };

  deleteInstOK = () => {
    const inst_id = this.state.installation_id;
    //console.log('installation_id', inst_id)
    SolarServices.deleteInstallation(inst_id)
      .then(response => {
        this.closeAdd("success", "Installation is deleted successfully.");
      })
      .catch(err => {
        console.error(err);
        this.closeAdd("danger", err);
      });
  };

  render() {
    const {
      sites,
      noSites,
      index_site,
      index_inst,
      installations,
      orgId,
      site_id,
      show,
      msg,
      variant,
      name,
      latitude,
      longitude,
      noInst,
      timezone,
      installation_id,
      type,
      active
    } = this.state;
    console.log("longitude", longitude);
    return (
      <div className="card">
        <div className="settings">
          <Dialog
            ref={el => {
              this.dialog = el;
            }}
          />
          {show === true ? (
            <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>
          ) : (
            <div></div>
          )}

          {noSites === false ? (
            <div className="col-sm-12">
              <div className="headerTable">
                <h2>Site</h2>
                <Dropdown
                  index={index_site}
                  list={sites}
                  onChange={this.onSiteSelected}
                />
                {/* <h2>Meter</h2>
              <select
                className="dropdown"
                onChange={this.onMeterSelected}
                value={index_meter}>
                {meters.map((option, index) => (
                  <option key={index} value={index}>
                    {option.description}
                  </option>
                ))}closeAddPanel
              </select>*/}

                <h2>Installation</h2>
                <Dropdown
                  index={index_inst}
                  list={installations}
                  onChange={this.onInstSelected}
                />

                <button
                  className="add btn-round "
                  onClick={this.addInstallation}
                >
                  Add Installation
                </button>
              </div>

              {noInst === false ? (
                <div>
                  <table className="table-market" id="solar-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Longitude</th>
                        <th>Latitude</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{name}</td>
                        <td>{longitude}</td>
                        <td>{latitude}</td>

                        <td>
                          <button
                            className="editDelete"
                            onClick={() => this.addPanel("Add")}
                          >
                            Add Panel
                          </button>
                        </td>
                        <td>
                          <button
                            className="editDelete"
                            onClick={() => this.editInst()}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            className="editDelete"
                            onClick={() => this.deleteInst()}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <Card
                    style={{
                      width: "18rem"
                    }}
                  >
                    <Card.Body>
                      <Card.Title>
                        <i
                          style={{
                            color: "orange"
                          }}
                          className="fas fa-exclamation-triangle"
                        ></i>
                        Warning
                      </Card.Title>
                      <Card.Text>No solar installations defined</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          ) : noSites === true ? (
            <div>
              <Card
                style={{
                  width: "18rem"
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <i
                      style={{
                        color: "orange"
                      }}
                      className="fas fa-exclamation-triangle"
                    ></i>
                    Warning
                  </Card.Title>
                  <Card.Text>
                    Add sites to be able to add solar installation.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <div></div>
          )}
          <PanelTable
            inst_id={installation_id}
            noInst={noInst}
            ref={this.child}
          />
        </div>
        <div className="clearfix" />

        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.addInstallationModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">
                {type} Installation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddInstallationForm
                  closeAdd={this.closeAdd}
                  type={type}
                  inst_id={installation_id}
                  site_id={site_id}
                  onShowAlert={this.onShowAlert}
                  org_id={orgId}
                  name={name}
                  latitude={latitude}
                  longitude={longitude}
                  active={active}
                  timezone={timezone}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            dialogClassName="custom-dialog"
            backdrop="static"
            keyboard={false}
            show={this.state.addPanelModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Add Panel Group</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddPanelForm
                  type={"Add"}
                  inst_id={installation_id}
                  closeForm={this.closeAddPanel}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
