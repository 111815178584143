import React, { useState, useEffect, useRef } from 'react'
import {Currencies} from "../../../utility";
import { Button, Card, Row, Col, Form } from 'react-bootstrap'
import { ConfirmAlert } from '../UIComponents.jsx'
import {Request} from "../../../services/HttpService";
import { Notify } from '../AlertListener.jsx'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")

const DEFAULT_CHARGE={
    name: '',
    charge_type: 'DUoS',
    basis: 'KWH',
    is_dlaf: false,
    is_credit: false,
    value: { __type:'number', __val: 0},
}

const noop=() => {}

export default function CompactChargeForm({template=null, planId=null, periodId=null, charge:__charge=null, currency, onDone=noop}) {
	const [charge, setCharge] = useState(null)
	const [initial, setInitial] = useState([])
	const [values, setValues] = useState([])
	const [schema, setSchema] = useState(null)
	const [units, setUnits] = useState(null)
	const [confirmRequired, setConfirm] = useState(false)
	const [dirty, setDirty] = useState(false)
	const [errors, setErrors]=useState({})
	const [validated, setValidated]=useState(false)
	const __form = useRef()

	useEffect(() => {
		if(template===null) return ;

		const { name, 
			charge_type,
			basis,
			is_major=false,
			is_dlaf,
			is_credit, 
			__schema={ 
				required: ['value'],
				properties: {
					value: {description: 'Charge value', minimum: 0}
				}
			}} = template

		setSchema(__schema)
		const __values = Object.entries(__schema.properties).map(([name, {description=name}]) => [name, description, 0])
		setValues(__values)
		setInitial(__values.map(v => v[2]))

		setCharge({name, charge_type, basis, is_major, is_dlaf, is_credit})
		// Work out the units
		const __currency = Currencies.get(currency)
		let __unitPeriod
		switch(basis) {
			case 'UNIT_PRICE':
			case 'KWH':
				__unitPeriod='kwh'
				break ;
			case 'ANNUAL':
				__unitPeriod='Annum'
				break ;
			case 'DAY':
				__unitPeriod='Day'
				break;
			case 'MONTH':
				__unitPeriod='Month'
				break ;
			default:
				__unitPeriod='???'
				break ;
		}
		setUnits(`${is_major?__currency.symbol:__currency.minorSymbol}/${__unitPeriod}`)

	}, [template])

	useEffect(() => {
		if(__charge===null) return 

		const { name, 
			charge_type,
			basis,
			is_major=false,
			is_dlaf,
			is_credit,
			value 
		} = __charge

		const __schema=value.__type==='number'?{ 
				required: ['value'],
				properties: {
					value: {description: 'Charge value', minimum: 0}
				}
			}:value.__schema

		setSchema(__schema)
		const __values=value.__type==='number'?
			[['value', 'Charge value', value.__val]]:
			Object.entries(__schema.properties).map(([name, {description=name}]) => [name, description, value.__val[name]])
		setValues(__values)
		setInitial(__values.map(v => v[2]))

		setCharge({name, charge_type, basis, is_major, is_dlaf, is_credit})
		// Work out the units
		const __currency = Currencies.get(currency)
		let __unitPeriod
		switch(basis) {
			case 'UNIT_PRICE':
			case 'KWH':
				__unitPeriod='kwh'
				break ;
			case 'ANNUAL':
				__unitPeriod='Annum'
				break ;
			case 'DAY':
				__unitPeriod='Day'
				break;
			case 'MONTH':
				__unitPeriod='Month'
				break ;
			default:
				__unitPeriod='???'
				break ;
		}
		setUnits(`${is_major?__currency.symbol:__currency.minorSymbol}/${__unitPeriod}`)
	}, [__charge])

	function hasChanged() {
		for(const [i,v] of values.entries()) {
			if(v[2]!==initial[i]) return true
		}
		return false ;
	}

	useEffect(() => {
		setDirty(d => hasChanged())
	}, [values])

	function isNumeric(str) {
		if(typeof(str)==="number") return true
		if (typeof str != "string") return false // we only process strings!  
	  		return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
	        	 !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
	}

	function isValid(name, value) {
		const { required=[]} = schema
		const {minimum=0, maximum=null} = schema.properties[name] ;
		let __err=false
		if(required.includes(name) && value.length===0) {
			__err="Value required"
		}
		else if(!isNumeric(value)) {
			__err="Must be valid number"
		}
		else {
			const __v=parseFloat(value)
			if(maximum!==null) {
				if(__v<=minimum || __v>=maximum) {
					__err = `Must be > ${minimum} and < ${maximum}`
				}
			}
			else {
				if(__v<=minimum) {
					__err = `Must be > ${minimum}`
				}
			}
		}
		return __err ;
	}

	function validate() {
		const __validated = values.reduce((__valid, v) => {
			const __err = isValid(v[0], v[2])
			errors[v[0]] = __err
			return __valid && __err===false
		}, true)
		setErrors({...errors})
		setValidated(__validated)
		return __validated
	}

	async function save() {
		if(validate()) {
			const data = {...charge}
			if(__charge===null) {
				if(template.__schema) {
					data.value = {
						__type: 'object',
						__schema: {...template.__schema},
						__val: values.reduce((__v, c) => { __v[c[0]]=parseFloat(c[2]); return __v}, {})
					}
				}
				else {
					data.value={
						__type: 'number',
						__val: parseFloat(values[0][2])
					}
				}
			}
			else {
				if(__charge.value.__type==='number') {
					data.value={
						__type: 'number',
						__val: parseFloat(values[0][2])
					}
				}
				else {
					data.value = {
						__type: 'object',
						__schema: {...__charge.__schema},
						__val: values.reduce((__v, c) => { __v[c[0]]=parseFloat(c[2]); return __v}, {})
					}
				}
			}
			try {
				await request.put(`/api/billable/plan/${planId}/period/${periodId}/charge${__charge===null?'':`/${__charge._id}`}`, data)
				onDone(true)
			}
			catch(err) {
				console.error('Error saving : ', err)
				notify.error(err.message)
			}
		}
	}

	function cancel(e) {
		if(e===true || !hasChanged()) {
			onDone()
			return
		}
		setConfirm(true)
	}

	function onChange(e) {
		const {currentTarget: __form} = e 
		const { value="", name } = e.target ;
		for(const v of values) {
			if(name===v[0]) v[2]=value ;
		}
		setValues([...values])
		errors[name]=isValid(name, value)
		setErrors({...errors})
		setValidated(Object.values(errors).reduce((v,e) => v && e===false, true))
	}

	const __props = {
		disabled: confirmRequired
	}

	return charge!==null?(
		<Card style={{padding: "0.5rem"}}>
			<Row><Col><h5>{charge.name} ({units})</h5></Col></Row>
			<Form noValidate onSubmit={save} ref={__form}>
				<Form.Row>
					{values.map(([name, description, val]) => (<Col key={`chrg_val_${name}`}>
						<Form.Group controlId={`chrg_val_${name}`}>
							<Form.Label>{description}</Form.Label>
							<Form.Control name={name}
								type="number" 
								value={val}
  								{...__props}
  								onClick={(e) => e.target.select()}
	  							onChange={onChange}
	  							isInvalid={!!errors[name]}
								placeholder={description}/>
	  						<Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>
						</Form.Group>
					</Col>))}
				</Form.Row>
			</Form>
			<Row>
				<Col>
				<hr/>
        		{confirmRequired?(<ConfirmAlert
  					heading="Details have changed"
  					text="Charge details have changed, really cancel?"
  					buttonsInline={false}
  					onNo={() => setConfirm(false)}
  					onYes={() => cancel(true)}/>
            	):
	            (<div className="btn-right">
	            	<Button 
            			type="button" 
            			variant="outline-secondary" 
            			onClick={cancel}
            			style={{marginRight: "1rem"}}>
            			<i className="fas fa-times"/>Cancel
            		</Button>
            		<Button id="btnPeriodDone" disabled={!dirty || !validated || __props.disabled===true} type="button" onClick={save} variant="re-primary">
						<i className="fas fa-save"/>Save
					</Button>
				</div>)}
				</Col>
			</Row>
		</Card>
	):null
}