import React, { useState, useEffect } from 'react'
import { Redirect } from "react-router-dom";
import {Button, ButtonToolbar, Card, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Loading } from "../../components/UIComponents.jsx"
import {Request} from "../../../services/HttpService";
import { Notify } from '../../components/AlertListener.jsx'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {ARRAY_TYPES, MODULE_TYPES} from './constants'

const notify=Notify("solar.notifications")
const requestSolar = new Request({ service: 'Solar service'})
const requestUser = new Request({ service: 'User service'})
const request = new Request({ service: 'Scenario service'})

export default function SelectPVInstallation() {
	const [pvs, setPVs] = useState([])
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [selected, setSelected] = useState(null)
	const [redirect, setRedirect] = useState(null)

	useEffect(disabled => {
		async function loadData() {
			setLoading(l => true)
			setSelected(v => null)
			const __requests = []

			function loadGroups(p) {
				return requestSolar.call(`/api/solar/data/${p._id}/group?includeInactive`)
				.then(groups => {
					p.groups = groups ;
				})				
			}

			function getSite(p) {
				return requestUser.call(`/api/organisation/${p.organisation_id}/site/${p.site_id}?includeInactive`)
				.then(async (site) => {
					const name = [site.name]
					while(site.parent_id) {
						site = await requestUser.call(`/api/organisation/${p.organisation_id}/site/${site.parent_id}`)
						name.unshift(site.name)
					}
					const __name = name.join(' / ')
					p.site = __name
				})
			}

			try {
				// Load the panels
				const __pvs=await requestSolar.call('/api/solar/data?includeInactive')
				for(const p of __pvs) {
					// Load the panel groups
					__requests.push(loadGroups(p)) ;
					// Load the site
					__requests.push(getSite(p)) ;
				}
				await Promise.all(__requests)
				const __data = __pvs.map(pv => {
					const { _id, groups: __groups, site, name, active } = pv ;
					const totalKW = __groups.reduce((t,c) => t+c.capacity, 0)
					const groups = __groups.reduce((desc,g) => {
							return `${desc.length>0?`${desc},`:''}${g.capacity}kw (az.=${g.azimuth}, tilt=${g.tilt})`
						}, '')
					return { _id, site, name, active, totalKW, groups, pv }
					})

				// setPVs(v => [])
				setPVs(v => __data)
				setDisabled(d => __data.length===0)
			}
			catch(err) {
				console.error(err)
				notify.error(err.message)
			}
			setLoading(l => false)
		}

		loadData()

		return () => {
			setPVs([])
		}
	}, [])

	useEffect(() => {
		return () => {
			setRedirect(null)
		}
	}, [redirect])

	async function handleCreate() {
		const {site, pv} = selected ;
		if(!pv) {
			console.error('No PV installation selected')
			return 
		}
		const warnings=[] ;
		const __scenario={
			name: `${site} : ${pv.name}`,
			description: pv.description===null?'':pv.description,
			location: [pv.location.coordinates[1], pv.location.coordinates[0]],
			panels: pv.groups.map(({name, capacity, losses, tilt, azimuth, efficiency, array_type, module_type}) => {
				if(array_type>ARRAY_TYPES.length) {
					array_type=1 ;
					warnings.push(`Panels "${name}" array type not regognised. Using default "${ARRAY_TYPES[array_type][1]}"`)
				}
				if(module_type>MODULE_TYPES.length) {
					module_type=1 ;
					warnings.push(`Panels "${name}" module type not regognised. Using default "${MODULE_TYPES[module_type][1]}"`)
				}
				return {
					name, 
					capacity, 
					losses, 
					tilt, 
					azimuth, 
					efficiency, 
					array_type: ARRAY_TYPES[array_type][0], 
					module_type: MODULE_TYPES[module_type][0]
				}
			})
		}

		try {
			const __new = await request.call(`/api/scenario/solar?unique`, 'put', { data: __scenario })
			setRedirect(`/${__new._id}/edit`)
			notify.info(`Created scenario "${__new.name}"`)
		}
		catch(err) {
			const { detail={errors:[]} } = err ;

			console.error('>>> ', JSON.stringify(err, null, 3))
			if(err.status===400) {
				notify.error((<React.Fragment>
					<div>Validation errors:</div>
					<ul>
						{detail.errors.map((e, i) => (<li key={`err_${i}`}>{e.message}</li>))}
					</ul>
				</React.Fragment>))
			}
			else {
				notify.error(err.message)
			}
		}
	}

	const columns = [{
			dataField: '_id',
			text: 'id',
			hidden: true
		}, {
			dataField: 'site',
			text: 'Site',
			sort: true
		}, {
			dataField: 'name',
			text: 'PV Array',
			sort: true
		}, {
			dataField: 'active',
			text: 'Active',
			formatter: (active) => (<span><input type="checkbox" disabled checked={active}/></span>)
		}, {
			dataField: 'totalKW',
			text: 'Output (kw)'
		}, {
			dataField: 'groups',
			text: 'Panels'
		}
	]

	const defaultSorted = [{
  		dataField: 'site',
  		order: 'asc'
	}];

	const selectRow = {
  		mode: 'radio',
  		clickToSelect: true,
  		// hideSelectColumn: true,
  		onSelect: (row, isSelect) => {
  			if(disabled) {
  				return false
  			}
  			setSelected(row)
  			return true
		},
  		bgColor: '#28ba9f'
	};

	if(redirect!==null) {
		return (<Redirect to={`/dashboard/scenario/solar${redirect}`}/>)
	}

    const renderTooltip = (props={}) => (
		<Tooltip id="button-tooltip" {...props}>
			{selected===null?"Select installation from table":"Create scenario based on installation"}
		</Tooltip>
    );

	return (
		<Card>
			<Container className="left-justify">
				<Row>
					<Col className="headerTable">
						<h2>Create Scenario From Existing PV Installation</h2>
					</Col>
				</Row>
				{loading?(
					<Row><Col><Loading active={loading}/></Col></Row>
				):
				(
				<Row>
					<Col className='selectable'>
						<ButtonToolbar>
					        <OverlayTrigger
					            placement="top"
					            delay={{ show: 250, hide: 150 }}
					            overlay={renderTooltip}>
								<Button type="button" 
									className="btn-re"
									variant="re-primary"
									onClick={handleCreate}
									disabled={disabled || selected===null}>
									<i className="fas fa-copy"/>
									Create Scenario
								</Button>
							</OverlayTrigger>
							<Button type="button"
								style={{marginLeft: "0.5rem"}}
								variant="outline-secondary"
								onClick={() => setRedirect('/list')}>
								<i className="fas fa-times"/>Cancel
							</Button>
						</ButtonToolbar>
						<BootstrapTable bootstrap4 striped hover condensed 
							keyField='_id' 
							className='selectable'
							data={ pvs } 
							columns={ columns } 
							defaultSorted={defaultSorted}
							selectRow={selectRow}
							noDataIndication="No PV installations found"/>
					</Col>
				</Row>
				)}
			</Container>
		</Card>
	)
}
