import React, {useState, useContext, useCallback, useEffect, Component, useRef} from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Request} from "../../services/HttpService"
import {Notify} from '../components/AlertListener'
import socketIOClient from "socket.io-client";
import OrgGraph from './OrgFlowGraph';
//import SocketContext from "../../services/socket-context";
import io from "socket.io-client";

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")


export default function  OrganisationFlow() {

  const [organisation, setOrganisation] = useState(null)
  const [flowData, setFlowData] = useState({nodes:[],links:[]})


  const stDateRef = useRef(0)
  const uDataRef = useRef([])
  const infDataRef = useRef([])
  const infLevelRef = useRef(0)
  const miRef = useRef([])
  const origDataRef = useRef([])


  const { user } = useContext(UserContext)
  const [response, setResponse] = useState("");
  const [FData, setFData] = useState({})
  const fgRef = useRef();

  const initData = {nodes:[],links:[]}
  const wl = window.location   
  const surl = `${wl.protocol}//${wl.host}`


  useEffect(() => {
    if(!user) return

    async function __getOrg() {
        try {
            const o = await request.get(`/api/organisation/${user.organisationId}`)
            setOrganisation(o)
        }
        catch(e) {
            console.error(e)  
            notify.error(e.message)
        }
    }

    __getOrg()

    return() => {
        setOrganisation(null)
    }
  }, [user])

  const orgData = (dta) => {
    //console.log("dta: ", dta)
    setFlowData(dta)
  }


  async function processSites(mdata, sites, level, topLevel){
    if(mdata.hasOwnProperty('sites')){
      mdata.sites.forEach(async st => {
        if(topLevel<level+1){
          topLevel = level+1
        }
        //console.log("topLevel: ", topLevel)
        let site = {}
        site.id = st._id
        site.name = st.name
        //site.level = level+1
        site.level = topLevel
        //console.log("site: ", site)
        sites.push(site)
        if(st.hasOwnProperty('sites')){
          //console.log("")
          processSites(st, sites, level+1, topLevel)
        }
      })    
    }
    //console.log("stes: ", sites)
    return {topLevel:topLevel+1}
  }
  
  // async function reverseSiteLevel(sites, lvl){
  //   console.log("sites2: ", sites)
  //   console.log("lvl: ", lvl)
  //   sites.forEach(async ste => {
  //     ste.level = lvl - ste.level
  //   })
  // }
  
  async function processOrgMtrs(mdata, sites, meters, topLevel){
    //console.log("mdata: ", mdata)
    //let level = 1
    mdata.meters.forEach(async mt => {
      //if(mt.meter_type.includes('virtual'))
      let meter = {}
      meter.id = mt._id
      meter.meter_type = mt.meter_type
      meter.name = mt.name
      meter.group = "meter"
      meter.additive = []
      if(mt.hasOwnProperty('config')){
        if(mt.config.hasOwnProperty('additive')){
          mt.config.additive.forEach(async cnf => {
            meter.additive.push(cnf._id)
          })         
        }
      }
      if(mt.reading.hasOwnProperty('reading')){
        meter.reading = Number(mt.reading.reading.toFixed(3))
      }
      if(!meter.hasOwnProperty('level')){
        meter.level = 1
      }
      
      meters.push(meter)
    })
    
    if(mdata.hasOwnProperty('sites')){
      await sMeters(mdata, sites, meters, topLevel-1)
    }
    let mmeters = meters.slice()
    //console.log("mdata meters: ", mmeters)
    return {mmeters : mmeters}
  }
  
  async function sMeters(smeters, sites, meters){
    if(smeters.hasOwnProperty('sites')){
      smeters.sites.forEach(async st => {
        if(st.hasOwnProperty('meters')){
          st.meters.forEach(async sm => {
            
            let meter = {}
            meter.id = sm._id
            meter.meter_type = sm.meter_type
            meter.name = sm.name
            meter.group = "meter"
            meter.site_id = sm.site_id
            meter.additive = []
            if(sm.config.hasOwnProperty('additive')){
              //console.log(sm.config)
              sm.config.additive.forEach(async cnf => {
                //console.log(cnf)
                meter.additive.push(cnf._id)
              })
              
            }
            let mfound = sites.find(s => {
              if(sm.site_id === s.id){
                return s.level
              } 
            })
            if(mfound !== undefined){
              meter.level = mfound.level
            }else{
              meter.level = 1
            }
            if(sm.reading.hasOwnProperty('reading')){
              meter.reading = Number(sm.reading.reading.toFixed(3))
              if(meter.reading === 0){
                meter.reading = 0.001
              }
            }else{
              meter.reading = 0.001
            }
            meters.push(meter)
            
          })
          if(st.hasOwnProperty('sites')){
            sMeters(st, sites, meters)
          }
        }
      })    
    } 
  }
  
  async function checkMtrs(mtrs, nodes, links, sites, topLevel){
    //console.log("mtrs: ", mtrs)
      
    for(let i=topLevel;i>=1;i--){
      mtrs.forEach(async mtr => {
        if(mtr.level === i){

          mtr.icon_type = "imgMeter1"
          if(mtr.additive.length>0){
            //console.log(mtr.additive)
            let val = 0
            mtr.additive.forEach(async mad => {
              let m = mtrs.find(mr => {
                return mr.id === mad
              })
              if(m !== undefined){
                if(m.hasOwnProperty('reading')){
                  val += m.reading

                  if(m.reading>=0){
                    let lnk = {source:m.id,target:mtr.id,value:m.reading*3}
                    links.push(lnk)
                  }else{
                    let lnk = {source:mtr.id,target:m.id,value:m.reading*-3}
                    links.push(lnk)
                  }
                }        
              }else{
                val = val+0.001
              }    
            })
            
            mtr.reading = Number(val.toFixed(3))       
          }
          if(mtr.meter_type.includes("virtual")){
            mtr.icon_type = "imgMeter2"
          }else if (mtr.meter_type.includes("storage")){
            mtr.icon_type = "imgBattery"
          }else if (mtr.meter_type.includes("supply")){
            mtr.icon_type = "imgSolar"
          }else{
            mtr.icon_type = "imgMeter1"
          }

          if(!mtr.hasOwnProperty('site_id')){
            if(organisation.name === "Údarás na Gaeltachta" &&  (!mtr.name.includes("Údarás"))){
              mtr.name = `Údarás ${mtr.name}`
            }else{
              if((!mtr.name.includes(organisation.name)) &&  (!mtr.name.includes("Údarás"))){
                mtr.name = `${organisation.name} ${mtr.name}`
              }
            }
            
          }else{
            let sfound = sites.find(s => {
              return s.id === mtr.site_id
            })
            if(sfound !== undefined ){
              if(mtr.name.toLowerCase() === "meter"){
                if(mtr.meter_type.includes("incomer")){
                  mtr.name = `${sfound.name} Incomer`
                }else{
                  mtr.name = `${sfound.name} ${mtr.meter_type[0]}`
                }

              }else{
                if(!mtr.name.includes(sfound.name)){
                  mtr.name = `${sfound.name} ${mtr.name}`
                }

                if(mtr.name.includes("Dingle")){
                  mtr.name = mtr.name.replace(/Dingle/g, '');
                }

                if(mtr.name.includes("  ")){
                  mtr.name = mtr.name.replace(/  /g, ' ');
                }
              }
            }
          }         
          nodes.push(mtr) 
        }     
      })
    }   
  }




  const __loadInitFlowData = useCallback(async (_id, initData) => {

    //console.log(`Sorting Init Data`)
    //setInfData(initData)
    //infDataRef.current = initData
    let nmid = {}
    let processedData = {}
    let nodes=[]
    let links=[]
    let sites = []
    let meters = []
    let level = 1
    let topLevel = 1

    let processsites = await processSites(initData.organisation, sites, level, topLevel)

    let processorgmtrs = await processOrgMtrs(initData.organisation, sites, meters, processsites.topLevel)
    origDataRef.current = processorgmtrs.mmeters
    infLevelRef.current = processsites.topLevel
    //console.log("mmeters ", processorgmtrs.mmeters)
    let checkmtrs = await checkMtrs(meters, nodes, links, sites, processsites.topLevel)
    processedData.nodes = nodes
    processedData.links = links

    orgData(processedData)

  });

  const processUpdates = useCallback(async () => {
      let uDataInfo = uDataRef.current
      //console.log("uDataInfo ", uDataInfo)
      let updData = origDataRef.current
      updData.forEach(async (ud, iv) => {

        uDataInfo.forEach(async (udi, ui) => {
          if(udi.meter_id === ud.id){
            if(Number(udi.reading.toFixed(3)) === 0){
              updData[iv].reading = 0.001
            }else{
              updData[iv].reading = Number(udi.reading.toFixed(3)) 
            }           
          }
        })
      })
  
      let processedData = {}
      let resetData = {}
      let nodes=[]
      let links=[]
      let sites = []
      let tLevel = infLevelRef.current
      //console.log("updData ", updData)
      let checkmtrs = await checkMtrs(updData, nodes, links, sites, tLevel)
      uDataRef.current = []
      processedData.nodes = nodes
      processedData.links = links
      //console.log("processed2", processedData)
      orgData(processedData)  
    })
  
    const midData = (nodes) => {
      let nde = []
      nodes.map(async nd => {
        nde.push(nd.id)
      })
      miRef.current = [...miRef.current , ...nde]
    }
   
    useEffect(() => {
      midData(flowData.nodes)
    }, [flowData.nodes])

  const __updateFlowData = useCallback(async (_id, updateData) => {

      let upData = uDataRef.current
      let stDate = stDateRef.current
      let tud = Date.now()

      if(stDate === 0){

        stDateRef.current = tud
        upData.push(updateData)
        uDataRef.current = upData

      }else{

        if(upData.length < 1){

          upData.push(updateData)
          uDataRef.current= upData
          //stDateRef.current = tud

        }else{

          let idx = upData.findIndex(el => {
            return updateData.meter_id === el.meter_id 
          })

          if(idx < 0){
            upData.push(updateData)
            uDataRef.current = upData
            
          }else{
          
            if(upData[idx].timestamp < updateData.timestamp){
                              
                upData[idx] = updateData
                uDataRef.current = upData

            } 
          }
        }
        //console.log(` diff ${tud - stDate}`)
        if(((tud-stDate)/1000)>60){

          let pud = await processUpdates()

          stDateRef.current = 0
          uDataRef.current = []

        }
      }

  })

  const __loadInitialFlowData = useCallback(async (_id, initialData) => {

    orgData(initialData)

  });


  const __someUIwithData = useCallback( async () => {
    // TODO

  }, [flowData])



  useEffect(() => {
    if(!organisation) return
    
    console.log('Connecting to socket')
    localStorage.debug = '*'
    const socket = socketIOClient(surl, {
      path: '/api/meter/ws'
    });
    socket.on ("connect", () => {
      console.log("CONNECTED to Socket")
      socket.emit("SubscribeOrg", organisation._id)
    })
    socket.on ("OrgInitial", data => {
      __loadInitFlowData(organisation._id, data)

    })
    socket.on("OrgDataUpdate", data => {


      __updateFlowData(organisation._id, data)

    })
    socket.on("OrgMessageHandler", data => {

      __updateFlowData(organisation._id, data)

    })
    
    socket.on("FromAPI", data => {
      setResponse(data);
    });

    const cookieVal = JSON.parse(localStorage.getItem('JSESSIONID')) ;
    //console.log(`**USER** ${JSON.stringify(user)}`)

    // Run when component dismounts to close the socket
    return () => {
      console.log("Closing socket....")
      socket.disconnect();  
      //setFlowData({"nodes":[],"links":[]})
    }
  }, [organisation]);

  return organisation && (
    <Container fluid>
      <h2>Flow - {organisation.name}</h2>
      <div>
            <OrgGraph orgData={flowData} />     
      </div>
     </Container>
  )

}
