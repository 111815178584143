import React, {useState, useEffect} from 'react'
import { Alert, Button, Container, Row, Col } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Notify } from '../../components/AlertListener.jsx'
import {Request} from "../../../services/HttpService";

const notify=Notify("solar.notifications"),
	request = new Request({ service: 'Scenario service'})

const noop = () => {}

export default function SelectLoadCompare({scenario=null, comparing=[], max, onSelect=noop, onCancel=noop}) {
	const [scenarios, setScenarios] = useState([])
	const [table, setTable] = useState(null)
	const SELECT_CONFIG={
  		mode: 'checkbox',
  		clickToSelect: true,
  		onSelect: handleSelect,
  		onSelectAll: handleSelectAll,
		// hideSelectColumn: true,
  		bgColor: '#28ba9f'
	}
	const [selected, setSelected] = useState([])
	useEffect(() => {
		const __load = async () => {
			// Call the API
			try {
				const scenarios = await request.call('/api/scenario/load?includePanels')
				// Exclude the current scenario from the list and any with no base load profiles associated
				setScenarios(scenarios.filter(s => s._id!==scenario._id  && Array.isArray(s.baseLoadProfiles) && s.baseLoadProfiles.length>0))

				setSelected(comparing.map(([s]) => s._id))

			}
			catch(err) {
			    notify.error(err.message) ;
			}
		}
		if(scenario===null) {
			setScenarios([])
		}
		else {
			__load()
		}

		return () => {
			setScenarios([])
		}

	}, [scenario, comparing])

	function handleSelect(row, isSelect) {
		const __current = table.selectionContext.selected
		if(isSelect) {
			return(__current.length<max)
		}
		return true ;
	}

	function handleSelectAll(isSelect, rows) {
		return []
	}

	const columns = [{
			dataField: '_id',
			text: 'id',
			hidden: true
		}, {
			dataField: 'name',
			text: 'Scenario'
		}]

	function select() {
		const __selected = table.selectionContext.selected ;
		onSelect(scenarios.filter(s => __selected.includes(s._id)))
	}

	return (
		<Container>
			<Row>
				<Col lg="6">
					<Alert show={true} dismissible variant="light" onClose={onCancel}>
						<Alert.Heading>Select up to {max} scenarios to compare</Alert.Heading>
						<div>
							<BootstrapTable bootstrap4 striped hover condensed 
								ref={t => setTable(t)}
								keyField='_id' 
								className='selectable'
								data={ scenarios } 
								columns={ columns } 
								selectRow={{...SELECT_CONFIG, selected }}
								noDataIndication="No scenarios found"/>
        					<div className="d-flex justify-content-end">
			            		<Button 
			            			type="button" 
			            			variant="outline-secondary" 
			            			onClick={onCancel}
			            			style={{marginRight: "1rem"}}>
			            			<i className="fas fa-times"/>Cancel
			            		</Button>
			            		<Button 
			            			type="button" 
			            			variant="re-primary" 
			            			onClick={select}>
			            			<i className="fas fa-check"/>Select
			            		</Button>
        					</div>
						</div>
		            </Alert>
				</Col>
			</Row>
		</Container>
	)
}