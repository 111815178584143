import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import SiteSelector from "../site/SiteSelector"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'
import AccuracyTable from './../load/AccuracyTable'

export default function SiteLoad({startDate, endDate}) {
    const [site, setSite] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)

    const { user, Time } = useContext(UserContext)

    const __load = useCallback(async (_id) => {
        // console.log(`[SiteLoad] Fetch load data for ${site.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        Fetch(`/api/meter/site/${_id}/readings`, { 
            ...__query,
            meter_type: 'supply', 
            summary: true}, setSupplyData, 
            ["timestamp", "reading"],
            excludeNegativeFilter())
        // Predicted supply
        Fetch(`/api/organisation/${user.organisationId}/site/${_id}/predict/supply`, 
                __query, setPredictedSupplyData)

    }, [startDate, endDate, site, user, Time])

    useEffect(() => {
        if(!(site && site._id)) return 
        __load(site._id)

        return () => {
            setSupplyData(null)
            setPredictedSupplyData(null)
        }
    }, [startDate, endDate, site])

    return (<Container fluid>
        <SiteSelector onChange={setSite} titlePre="Solar"/>
        <div>
            {(supplyData || predictedSupplyData) && 
                <EnergyPlot 
                    supply={supplyData} 
                    predictedSupply={predictedSupplyData} 
                    startTime={startDate}
                    endTime={endDate}/>}
            <AccuracyTable observed={supplyData} predicted={predictedSupplyData}/>
        </div>
    </Container>)
}