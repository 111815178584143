import React, { useState, useEffect, useContext, useCallback} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import ClusterSelector from "../cluster/ClusterSelector"
import Fetch from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'
import AccuracyTable from './AccuracyTable'

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

export default function ClusterLoad({startDate, endDate}) {
    const [cluster, setCluster] = useState(null)
    const [organisation, setOrganisation] = useState(null)
    const [loadData, setLoadData] = useState(null)
    const [predictedLoadData, setPredictedLoadData] = useState(null)
    
    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(user===null || !user.clusterId) return ;

        const { clusterId : id } = user

        async function getCluster() {
            try {
                const c = await request.get(`/api/cluster/${id}`)
                setCluster(c)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        getCluster()

        return () => {
            setCluster(null)
        }
    }, [user])

    const __loadMemberData = useCallback(async () => {
        // console.log(`[ClusterLoad]] Fetch load data for org ${organisation.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        const { _id } = organisation
        Fetch(`/api/meter/organisation/${_id}/readings`, __query, setLoadData, ["timestamp", "reading"])
        // Predicted load
        Fetch(`/api/cluster/${cluster._id}/organisation/${_id}/predict/load`, __query, setPredictedLoadData)
    }, [startDate, endDate, cluster, organisation])

    const __loadClusterData = useCallback(async () => {
        // console.log(`[ClusterLoad]] Fetch load data for cluster ${cluster.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        // Actual load
        Fetch(`/api/meter/cluster/${cluster._id}/readings`, __query, setLoadData, ["timestamp", "reading", "price", "charges"])
        // Predicted load
        Fetch(`/api/cluster/${cluster._id}/predict/load`, __query, setPredictedLoadData)
    }, [startDate, endDate, cluster])

    useEffect(() => {
        if(!organisation) return
        if(!organisation._id) {
            __loadClusterData()
        }
        else {
            __loadMemberData()
        }
        return () => {
            setLoadData(null)
            setPredictedLoadData(null)
        }
    }, [startDate, endDate, cluster, organisation])

    useEffect(() => {
        return () => {
            setLoadData(null)
            setPredictedLoadData(null)
        }
    }, [cluster])

    return cluster && (<Container fluid>
        <ClusterSelector cluster={cluster} onChange={setOrganisation} titlePre="Load"/>
        <div>
            {(loadData || predictedLoadData) && <>
                <EnergyPlot 
                    load={loadData} 
                    predictedLoad={predictedLoadData} 
                    startTime={startDate}
                    endTime={endDate}/>
            <AccuracyTable observed={loadData} predicted={predictedLoadData}/></>}
        </div>
    </Container>)
}