import {Notify} from '../dashboard/components/AlertListener'
import {Request} from "./HttpService"

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")

export async function getBattery(id) {
    try {
        return await request.get(`/api/battery/${id}`)
    }
    catch(err) {
        console.error(err)
        notify.error(err.message)
    }
}

export async function getBatteryConfig(battery) {
    if(!battery) return null

    //
    // This is hardcoded as we've only got a single battery type.
    // By rights, a call should be made to get the configuration for a given 
    // battery type
    //
    if(!battery.config) {
        battery.config = {}
    }
    
    battery.config.upload = {
        uri: `/api/battery/instance/sonneneco803/${battery._id}/upload`,
        fingerprint: "### UNIX-Timestamp, Date/Time, Discharge(W), Charge(W), Production(W), Consumption(W), State of Charge(%)",
        // Should get timezone from the site
        timezone: 'Europe/Dublin',
        parse: function(content, __notify){
            if(!__notify) __notify=notify
            // console.log('Fingerprint : ', this.fingerprint, notify)
            const re = /^(.*)$/mg
            const lineRE = /^([0-9]+)\s*,\s*[0-9]{2}.[0-9]{2}.[0-9]{4}\s+[0-9]{2}:[0-9]{2}:[0-9]{2}\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*$/
            
            const matches = content.matchAll(re)
            let __l=0
            let __isSonnenFile=false
            const __records=[]
            for(const m of matches) {
                // console.log(`Match[${i}] : `, m)
                __l+=1
                if(__isSonnenFile) {
                    const [,timestamp,discharge,charge,production,consumption,soc] = lineRE.exec(m[0])
                    // console.log(`[${__l}] : `, timestamp,discharge,charge,production,consumption,soc)
                    __records.push({
                        __l,
                        timestamp: parseInt(timestamp),
                        discharge: parseInt(discharge),
                        charge: parseInt(charge),
                        production: parseInt(production),
                        consumption: parseInt(consumption),
                        soc: parseInt(soc)
                    })
                }
                else {
                    if(__l>10) {
                        // No fingerprint found
                        throw new Error('Does not appear to be a valid Sonnen data file')
                    }
                    __isSonnenFile = m[0]===this.fingerprint
                    if(__isSonnenFile) __l=0 // Reset the line count
                }
            }
            // console.log("Records : ", __records.slice(0,10))
            return __records
        }
    }

    // console.log('[getBatteryConfig] : ', battery.config)
    return battery.config
}