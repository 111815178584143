import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import UserContext from "../../services/user-context"
import {noop} from '../components/UIComponents'
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import SessionStorage from "../../services/SessionStorage"

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

const SELECTED_ORG_KEY = 'cluster.dash.org.selected'

const ALL_ORGS = [{ _id: null, name: "All Organisations", value: "all", label: "All Organisations"}]

export default function ClusterSelector({cluster, onChange=noop, titlePre}) {
    const [title, setTitle] = useState(null)
    const [clusterMembers, setClusterMembers] = useState(null)
    const [selectedOrg, setSelectedOrg] = useState(null)

    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(!cluster) return 
        const __loadClusterMembers = async () => {
            try {
                const __members = await request.get(`/api/cluster/${cluster._id}/members`)
                setClusterMembers([
                    ...ALL_ORGS, 
                    ...__members.map(({_id, name}) => ({_id, name, value: _id, label: name}))
                ])
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }
        __loadClusterMembers()
    }, [cluster])

    useEffect(() => {
        if(clusterMembers===null) return 
        let __selected = SessionStorage.get(SELECTED_ORG_KEY, "all")
        const [__org] = clusterMembers.filter(({value}) => value===__selected)
        if(__org) {
            setSelectedOrg(__org)
        }
        else {
            setSelectedOrg(clusterMembers[0])
        }

    }, [clusterMembers])

    const onOrgChange = useCallback((org) => {
        setSelectedOrg(org)
        SessionStorage.set(SELECTED_ORG_KEY, org.value)
    }, [clusterMembers])

    useEffect(() => {
        if(selectedOrg===null) return
        const { _id, name } = selectedOrg
        onChange(selectedOrg)
        setTitle(`${titlePre?`${titlePre} - `:''}${_id?`${cluster.name} (Member)`:`${name} (Cluster)`}`)

        return () => {
            setTitle("")
        }
    }, [selectedOrg])

    return cluster && (<Row>
        <Col>
            <Form className="dropdown">
                <Form.Row className="align-items-center">
                    <Col xs="auto"><h2>{title}</h2></Col>
                    <Col style={{textAlign: 'right'}}><h4>Organisation</h4></Col>
                    <Col xs={3} style={{float: 'right'}}>
                    { clusterMembers && <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={selectedOrg}
                            options={clusterMembers}
                            onChange={onOrgChange}
                            />}

                    </Col>
                </Form.Row>
            </Form>
        </Col>
    </Row>) || null
}