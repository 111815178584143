import React, {useState, useEffect, useCallback } from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import { Accordion, Button as IButton, Icon } from "semantic-ui-react"
import { AddButton, LeafletMap, Toggle} from "../../components/UIComponents"
import SessionStorage from "../../../services/SessionStorage";
import SiteView from './SiteView'
import SiteMeters from './SiteMeters'
import SiteRenewables from './SiteRenewables'

const EXPANDED = "org.admin.site.active"

export default function SiteList({sites=[], level=0, __key="site_top"}) {
    const [key] = useState(`${EXPANDED}.${__key}`)
    const [expanded, setExpanded] = useState([])
    const [mapVisible, showMap] = useState(null)


    useEffect(() => {
        setExpanded(SessionStorage.get(key, []))
    }, [key])

    function toggleSite(e, props) {
        const {_id=""} = props
        const ndx = expanded.indexOf(_id)
        let toAdd=[]
        if(ndx>=0) {
            expanded.splice(ndx, 1) // Collapse
        }
        else {
            toAdd.push(_id) // Expand
        }

        const __expanded = [...expanded, ...toAdd]
        setExpanded(__expanded)
        SessionStorage.set(key, __expanded)
    }

    return (
        <Accordion styled fluid exclusive={false}>
            {sites.map(s => (
                <React.Fragment key={`site_${s._id}`}>
                    <Accordion.Title 
                        _id={s._id}
                        onClick={toggleSite}
                        active={expanded.includes(s._id)}>
                        <h4><Icon name="dropdown"/>
                        {s.name}</h4>
                    </Accordion.Title>
                    <Accordion.Content active={expanded.includes(s._id)}>
                    {expanded.includes(s._id)?(<Container fluid>
                            <SiteView site={s}/>
                            {Array.isArray(s.sites) && s.sites.length>0?(
                                <><h4>{s.name} Sites</h4>
                                <SiteList sites={s.sites} level={level+1} __key={`site_${s._id}`}/></>
                            ):null}
                            
                        </Container>):null}
                    </Accordion.Content>
                </React.Fragment>
            ))}
        </Accordion>
    )
}