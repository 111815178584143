import React, {useState, useEffect} from 'react'
import {Alert } from 'react-bootstrap'

function genKey() {
	const HEX="0123456789abcdef", __r=[]

	for(let i=0;i<24;++i) {
		__r.push(HEX.charAt(Math.floor(Math.random() * 16)))
	}
	return(__r.join(''))
}

class AlertListener {
	constructor() {
		this.categories = new Map()
		this.alerts = new Map() ;
		this.listeners = new Map() ;
	}

	getCategory(tag, create=true) {
		if(this.categories.has(tag)) {
			return this.categories.get(tag) ;
		}
		if(create) {
			const category = {
				alerts: new Map(),
				listeners: new Map()
			}
			this.categories.set(tag, category) ;
			return category ;
		}
		return null ;
	}

	clearAlert(key) {
		if(this.alerts.has(key)) {
			const a=this.alerts.get(key) ;
			if(a.ttlHandle!==null) {
				clearTimeout(a.ttlHandle)
			}
			this.alerts.delete(key)

			const category = this.getCategory(a.tag, false)
			if(category!==null) {
				category.alerts.delete(key)
				this.notify(category)
			}
			else {
				console.error(`[${key}] - Category "${a.tag}" not found for alert "${a.message}"`)
			}
		}
		else {
			console.warn(`[${key}] - Alert not found`)
		}
	}

	notify(category) {
		if(category.listener) {

			category.listener([...category.alerts.values()])
		}
	}

	raise(variant, tag, message, ttl=10) {
		const category = this.getCategory(tag)
		const { alerts } = category

		const key=genKey()
		const ttlHandle = ttl>0?
			setTimeout(()=>{
				this.clearAlert(key)
			}, ttl*1000):null
		const __a = {
			key,
			variant,
			tag,
			message,
			ttlHandle
		}
		alerts.set(key, __a)
		this.alerts.set(key, __a)
		this.notify(category)
	}

	subscribe(tag, handler) {
		const category = this.getCategory(tag) ;
		category.listener=handler ;
		// console.log(`[${tag}] - Subscribed - `, handler)
	}

	unsubscribe(tag) {
		const category = this.getCategory(tag, false) ;
		if(category!==null) {
			for(const a of [...category.alerts.values()]) {
				this.clearAlert(a.key) ;
			}
			category.listener = null ;
			this.categories.delete(tag)
			// console.log(`[${tag}] - Unsubscribed`)
		}
	}

}

const listener = new AlertListener()

function AlertContainer({tag, page=""}) {
	const [alerts, setAlerts] = useState([])

	function removeAlert(key) {
		listener.clearAlert(key)
	}

	useEffect(() => {
		listener.subscribe(tag, (__alerts) => { 
			// console.log(`[${tag}] - Setting alerts : `, __alerts)
			setAlerts(__alerts)
		})
		return () => {
			listener.unsubscribe(tag)
		}
	}, [tag])

	const alertList = alerts.map(a => (
			<Alert 
				key={a.key}
				variant={a.variant}
				onClose={() => removeAlert(a.key)}
				dismissible="true">
				<p>{a.message}</p>
			</Alert>
		))

	return alerts.length>0?(
		<React.Fragment>
			{alertList}
		</React.Fragment>
	):null
}

function Notify(tag) {
	return {
		info: (message, ttl) => {
			// console.log(`info - ${message}`)
			listener.raise('success', tag, message, ttl)
		},

		warn: (message, ttl) => {
			listener.raise('warning', tag, message, ttl)
		},

		error: (message, ttl) => {
			listener.raise('danger', tag, message, ttl)
		},
	}
}

export { AlertContainer, Notify }

export default listener ;