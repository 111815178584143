import React from "react";
import TariffsRow from "./TariffsRow";
import moment from "moment";

export default function TariffBillableRow({
  billable,
  editTariff,
  valid_to,
  valid_from,
  deleteTariff
}) {
  const today = moment().startOf("day").toISOString();
  const date_to = moment(valid_to).format("DD-MM-YYYY");
  const valid_start = moment(valid_from).format("DD-MM-YYYY");
  const valid_finish =
    valid_to === null || valid_to === "" || valid_to === undefined
      ? ""
      : date_to;
      //console.log('valid_start',valid_from)
      //console.log('today',today)
  return (
    <table className="table-market" id="tariffs_table">
      <thead>
        <tr>
          <th>Valid period</th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {valid_start} - {valid_finish}
          </td>
          <td></td>
          <td></td>

          {valid_from === today || valid_from > today ? (
            <td>
              <button
                className="delete-tariff"
                onClick={() => deleteTariff(billable._id)}
              >
                Delete
              </button>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      </tbody>
      <thead>
        <tr>
          <th> Month </th>
          <th> Day </th>
          <th> Time </th>
          <th> Price </th>
        </tr>
      </thead>
      <tbody>
        {billable.tariffs.map((t, ndx) => (
          <TariffsRow
            tariff={t}
            key={`${t._id}_${ndx}`}
            editTariff={editTariff}
            deleteTariff={deleteTariff}
            valid_from={valid_from}
            valid_to={valid_to}
            tariff_id={billable._id}
          />
        ))}
      </tbody>
    </table>
  );
}
