import React, { Component } from "react";
import UserServices from "../../services/UserServices";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (count = count+1)
  );
  return count;
}

export default class AddSiteForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      nameActive:false,
      name: '',
      activeSite:true,
      type:'add',
      site_id:'',
      formValid: false,
      errorCount: 0,
      errors: {
        name: '',
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
     const { name, type, site_id, activeSite } = this.props;
     this.setState({ name:name, type:type, site_id:site_id, activeSite:activeSite });
       this.innerRef.current.focus();
    }, 1);
  }

  activateField=(e)=> {
    console.log();
    this.setState({
     [`${e.target.name}Active`]: true
    })
  }

  disableField=(e)=> {
    this.setState({
      [`${e.target.name}Active`]: false
   })
 }

  onChangeActive = () => {
    this.setState({
      activeSite: !this.state.activeSite,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  }

  changeHandler = e => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;

    if (e.target.value === "") {
      this.disableField(e);
   } else {
      this.activateField(e);
   }
    //console.log('name', name)
    switch (name) {
      case 'name':
        errors.name =
          value.length < 3
            ? 'Name must be 3 characters long!'
            : '';
        break;
      default:
      break;
    }
    this.setState({
      errors,
      [name]: value,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  };


  addSite = (event, errors, values) => {
        event.preventDefault();
        const orgId = this.props.orgId;
        const siteId = this.state.site_id;
        const type = this.state.type;
        const active = this.state.activeSite;
        //console.log('add site activeSite', active)
        let site = {
             name: this.state.name,
             active: JSON.parse(active)
          };
          console.log('site', site)
        if (type === 'edit'){

          return UserServices.editSite(orgId, siteId, site)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                console.log('edit')
                this.setState({ name:'', type:'add', site_id:'', activeSite:true });
                this.props.closeAddSite("success", "Site is updated successfully.");
              } else {
                 this.props.closeAddSite("danger", "Site name already exists");
              };
            })
            .catch(err => {
              this.props.closeAddSite("danger", err);
            });
        }else if (type==='add'){
          return UserServices.addSite(orgId, site)
            .then(response => {
              if (response.data) {
                 this.setState({ name:'', type:'add', site_id:'', activeSite:true });
                this.props.closeAddSite("success", "Site is created successfully.");
              } else {
                 this.props.closeAddSite("danger", "Site name already exists");
              };
            })
            .catch(err => {
              this.props.closeAddSite("danger", err);
            });

        }

    }

  closeForm = event => {
    if (this.props.closeAddSite) {
        this.props.closeAddSite();
      }
    };

  render() {
    const {errors, formValid, errorCount, name} = this.state;
    const type = this.props.type;
    //console.log('type', type)

    var nameActive
    if (type === 'edit'){
      nameActive = true;
    } else {
      nameActive = this.state.nameActive;
    }
    //console.log('this.props.activeSite', this.props.activeSite)
    //console.log('this.state.activeSite', this.state.activeSite)

    if (type === 'edit'){
      var activeSite = this.props.activeSite;
    } else {
          activeSite = this.state.activeSite;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addSite}>
            <div className="form-group">
            <div className="labelField">
              <label  className={nameActive ? "on" : ""}>
              Name
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="name"
                id="name"
                onChange={this.changeHandler}
                placeholder="Enter Site Name"
                value={name || ""}
                ref={this.innerRef}
              />
              {errors.name.length > 0 &&
                <span className='error'>{errors.name}</span>}
              </div>
              <br/>
              <div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="activeSite"
                id="activeSite"
                onChange={this.onChangeActive}
                placeholder="Enter Dataset Name"
                value={activeSite}
                defaultChecked={activeSite}
              /></label>
              </div>
            </div>
            <div className="form-group">
              <button
                disabled = {formValid===false || errorCount !== 0 }
                type="submit"
                className="submit btn-round"
              >
                {type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0
              ? <p className="form-status">Form is {formValid===false || errorCount !== 0 ? 'invalid ❌' :'valid ✅' }</p>
              : 'Form not submitted'}
          </form>
        </div>
      </div>

    );
  }
}
