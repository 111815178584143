import React, {useState, useEffect, useContext } from 'react'
import { SplitButton, Dropdown, Button, ButtonGroup } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useLocation } from 'react-router-dom'
import {Request} from "../../../services/HttpService";
import {Notify} from '../../components/AlertListener'
import UserContext from '../../../services/user-context'

import { Overlay, noop } from '../../components/UIComponents'
import CacheResources from './cache-resources'
import MeterServices from '../../../services/MeterServices'
import EditMeterForm from '../meters/EditMeterForm'

const request = new Request({ service: 'Site service'})
const notify=Notify("site.notifications")
// const Cache = new CacheResources()

const getUnits = (u) => {
    switch(u) {
        case 'voltage': 
            return 'V'
        case 'current':
            return 'A'
        case 'watts':
            return 'W'
        case 'kilowatts':
            return 'kW'
        case 'kilowatt-hour':
            return 'kWh'
        default:
            return 'kW'
    }
}
 
const PromptMeter = ({site, type, meters=[], text=`No ${type} meter configured`, onUpdate=noop, force=false}={}) => {
    const [visible, setVisible] = useState(true)

    if(visible) {
        const { prompt_meters={}} = site
        let __noPrompt = prompt_meters[type]===false
        
        if(!__noPrompt && !force) {
            __noPrompt=meters.filter(({meter_type=[]}) =>  meter_type.includes(type)).length>0
        }
        if(__noPrompt) return null
    }

    async function refusePrompt(e) {
        e.preventDefault()
        // Send the API request to suppress the prompt
        try {
            const data = {
                prompt_meters: {}
            }
            data.prompt_meters[type] = false

            const __sites = await request.call(`/api/organisation/${site.organisation_id}/site/${site._id}/configure`,
                'put', 
                {data } )
            setVisible(false)
        }
        catch(err) {
            console.error(err)
            notify.error(err.message)
        }    
        onUpdate()
    }

    async function createMeter(e) {
        e.preventDefault()
        try {
            await MeterServices.createMeter({type, site, existing: meters})
        }
        catch(err) {
            console.error(err)
            notify.error(err.message)
        }
        onUpdate()
    }

    if(!visible) return null

    return (<div>
        <span><em>{text}</em></span><a href="#" onClick={createMeter}> - Add {type} meter</a>
        <span style={{paddingLeft: "1.5rem"}}>(<a href="#" onClick={refusePrompt}>Don't ask again</a>)</span>
    </div>)
}

export default function SiteMeters({site=null, meters=null, renewables=null, onUpdate=noop}) {
    const [renewablesPrompt, setRenewablesPrompt] = useState([])
    const [edit, setEdit] = useState(null)
    const [expanded, setExpanded] = useState([])
    const [isSiteOwner, setSiteOwner] = useState(false)

    const location=useLocation()
    const { user } = useContext(UserContext)

    useEffect(() => {
        setSiteOwner(site.organisation_id === user.organisationId)
    }, [user, site])

    useEffect(() => {
        const __prompts = []
        if(Array.isArray(meters) && Array.isArray(renewables) && isSiteOwner) {
            if(renewables.length>0) {
                // Check if there's a spill meter
                if(isSiteOwner && meters.filter(m => m.meter_type.includes('spill')).length===0) {
                    __prompts.push(['spill', 'Renewables configured, but no spill meter'])
                }
                // Check if there are enough supply meters
                const __supplyMeters = meters.filter(m => m.meter_type.includes('supply'))
                if(__supplyMeters.length<renewables.length) {
                    __prompts.push(['supply', `More renewables configured (${renewables.length}) than supply meters (${__supplyMeters.length})`, true])
                }
            }
        }
        setRenewablesPrompt(__prompts)
    }, [renewables, meters, isSiteOwner])

    function createMeter(type) {
        setEdit(type)
    }

    const columns=[{
        dataField: '_id',
        hidden: true,
    }, {
        dataField: 'name',
        text: 'Name',
    }, {
        dataField: 'description',
        text: 'Description',
    },
    {
        dataField: 'meter_type',
        text: 'Type',
        formatter: data => (<span>{Array.isArray(data)?data.join(', '):null}</span>)
    }, {
        dataField: 'meter_subtype',
        text: 'Units',
        formatter: getUnits
    }]

    const handleOnExpand = (meter, isExpand ) => {
        if(isExpand) {
            setExpanded([meter._id])
        }
    }

    const expandRow = {
        onlyOneExpanding: true,
        renderer: meter => (
            <Overlay>
                <EditMeterForm 
                    meter={meter}
                    site={site} 
                    existing={meters}
                    type={edit} 
                    onDone={() => { 
                        setEdit(null)
                        setExpanded([])
                        onUpdate()
                    }}/>
            </Overlay>        
        ),
        expanded, 
        onExpand: handleOnExpand
    };

    if(meters===null) return null

    return (
        <>
            <div className="align-items-center">
                <h5 style={{ display: 'inline-block'}}>Meters</h5>
                {isSiteOwner && 
                <SplitButton
                    as={ButtonGroup}
                    style={{marginBottom: "0.5rem"}}
                    size="sm"
                    variant="outline-primary"
                    onClick={() => createMeter()}
                    onSelect={(eventKey) => createMeter(eventKey)}
                    title="Add Meter"
                >
                    <Dropdown.Item eventKey="virtual">Virtual meter</Dropdown.Item>
                    <Dropdown.Item eventKey="incomer">Main incomer</Dropdown.Item>
                    <Dropdown.Item eventKey="load">Load meter</Dropdown.Item>
                    <Dropdown.Item eventKey="supply">Supply meter</Dropdown.Item>
                    <Dropdown.Item eventKey="spill">Spill meter</Dropdown.Item>
                </SplitButton>}
            </div>
            {edit!==null?
                (<Overlay>
                    <EditMeterForm 
                        site={site} 
                        existing={meters}
                        type={edit} 
                        onDone={() => { 
                            setEdit(null)
                            onUpdate()
                        }}/>
                </Overlay>):null}
            {isSiteOwner && <>
            <PromptMeter key={`prompt_incomer_${site._id}`} site={site} meters={meters} type="incomer" onUpdate={onUpdate}/>
            <PromptMeter key={`prompt_load_${site._id}`} site={site} meters={meters} type="load" onUpdate={onUpdate}/>
            {renewablesPrompt.map(([__type, __prompt, force=false]) => (
                <PromptMeter key={`prompt_${__type}_${site._id}`} site={site} meters={meters} type={__type} text={__prompt} onUpdate={onUpdate} force={force}/>
            ))}
            </>}
            {meters.length>0?(
            <BootstrapTable
                bootstrap4 striped hover condensed 
                version="4"
                keyField='_id' 
                data={meters}
                columns={columns}
                expandRow={expandRow}
                noDataIndication="No meters found"/>):null}
        </>
    )
}