import React, {useEffect, useState } from 'react'
import {Dropdown, Button} from 'react-bootstrap'
import {Request} from "../../../services/HttpService";
import { Notify } from '../AlertListener.jsx'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")


const noop=() => {}

export default function ChargeTemplateCombo({disabled=false, onSelect=noop, planType=null}) {
	const [ duos, setDuos ]= useState([])
	const [ tuos, setTuos ]= useState([])
	const [ market, setMarket ]= useState([])
	const [ other, setOther ]= useState([])

	useEffect(() => {

		async function __loadMetadata() {
			try {
				const {chargeTemplates} = await request.call(`/api/billable/plan/metadata`)
				const __filter=(c, type) => {
					return c.charge_type===type && (
						planType===null ||
						c.template_type==='Any' ||
						c.template_type===planType
					)
				}
				setDuos(chargeTemplates.filter(c => __filter(c, 'DUoS')))
				setTuos(chargeTemplates.filter(c => __filter(c, 'TUoS')))
				setMarket(chargeTemplates.filter(c => __filter(c, 'Market')))
				setOther(chargeTemplates.filter(c => __filter(c, 'Other')))
			}
			catch(err) {
				console.error(err)
				notify.error(err.message)
			}

		}

		__loadMetadata()

	}, [])

	return (
		<div style={{display: "inline-block"}}><Dropdown drop="right" id="charge-template-button">
			<Dropdown.Toggle as={Button} disabled={disabled} size="sm" variant="re-primary">
				<i className="fas fa-plus"/>Add Charge
			</Dropdown.Toggle>
			<Dropdown.Menu>
			{duos.length>0?(<React.Fragment>
				<Dropdown.ItemText><strong>DUoS Charges</strong></Dropdown.ItemText>
				{duos.map(c => (<Dropdown.Item key={`chrgtmp_${c._id}`} onSelect={() => onSelect(c)} as="button">--{c.name}</Dropdown.Item>))}
			</React.Fragment>):null}
			{tuos.length>0?(<React.Fragment>
				<Dropdown.ItemText><strong>TUoS Charges</strong></Dropdown.ItemText>
				{tuos.map(c => (<Dropdown.Item key={`chrgtmp_${c._id}`} onSelect={() => onSelect(c)} as="button">--{c.name}</Dropdown.Item>))}
			</React.Fragment>):null}
			{market.length>0?(<React.Fragment>
				<Dropdown.ItemText><strong>Market Charges</strong></Dropdown.ItemText>
				{market.map(c => (<Dropdown.Item key={`chrgtmp_${c._id}`} onSelect={() => onSelect(c)} as="button">--{c.name}</Dropdown.Item>))}
			</React.Fragment>):null}
			{other.length>0?(<React.Fragment>
				<Dropdown.ItemText><strong>Other Charges</strong></Dropdown.ItemText>
				{other.map(c => (<Dropdown.Item key={`chrgtmp_${c._id}`} onSelect={() => onSelect(c)} as="button">--{c.name}</Dropdown.Item>))}
			</React.Fragment>):null}
			</Dropdown.Menu>
		</Dropdown></div>
	)
}