import React, { Component } from "react";
import UserServices from "../../services/UserServices";
import { Modal, Alert } from "react-bootstrap";
import ProfileForm from "./ProfileForm";
import ChangePasswordForm from "./ChangePassword";
//import ToastContainer from "../../utility/ToastContainer";

export default class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      roles: [],
      selectedRoles: [],
      active: true,
      type: "",
      user_id: "",
      show: false,
      variant: "",
      msg: ""
    };
  }
  componentDidMount() {
    this.getUserList();
  }

  getUserList = () => {
    UserServices.getUserMe()
      .then(response => {
        const user = response.data;
        //  console.log('getuserme', user)
        const user_id = user.id;
        this.setState({
          user_id: user_id
        });
        this.getUserId(user_id);
      })
      .catch(err => {
        console.error(err);
        this.setState({ users: [] });
      });
  };

  getUserId = user_id => {
    const userId = user_id;
    UserServices.getUserId(userId)
      .then(response => {
        const user = response.data;
        const firstname = user.firstname;
        const lastname = user.lastname;
        const email = user.email;
        const roles = user.roles;
        this.setState({
          user_id: user_id,
          firstname: firstname,
          lastname: lastname,
          email: email,
          roles: roles
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ users: [] });
      });
  };

  changePassword = () => {
    //console.log('changePassword', user)
    this.setState({ showModalChangePassword: true });
  };

  closeChangePassword = (variant, msg) => {
    this.setState({
      showModalChangePassword: false,
      variant: variant,
      msg: msg
    });
    this.onShowAlert();
  };

  editUser = () => {
    const roles = this.state.roles;
    const selectedRoles = roles.map(value => ({ value, label: value }));
    this.setState({
      showModalEditUser: true,
      type: "edit",
      selectedRoles: selectedRoles
    });
  };

  closeEditUser = (variant, msg) => {
    this.setState({ showModalEditUser: false, variant: variant, msg: msg });
    this.onShowAlert();
    this.getUserList();
  };

  addUser = (variant, msg) => {
    //console.log('User add')
    this.setState({
      showModaladdUser: true,
      type: "new",
      variant: variant,
      msg: msg
    });
    this.onShowAlert();
  };

  closeAddUser = () => {
    this.setState({ showModaladdUser: false });
  };

  closeModal = () => {
    this.setState({ showModalEditUser: false });
  };

  setShow = () => {
    this.setState({ show: false });
  };

  onShowAlert = () => {
    //  console.log('onShowAlert')
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
    });
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      roles,
      user_id,
      selectedRoles,
      show,
      variant,
      msg
    } = this.state;
    const orgId = this.props.orgId;

    return (
      <div className="row card-row">
        <div className="col-lg-12">
          {show === true ? (
            <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>
          ) : (
            <div></div>
          )}
          <div className="headerTable">
            <h3>My details</h3>
          </div>

          <table className="table-market" id="org-table">
            <thead>
              <tr>
                <th> Name </th>
                <th> Email </th>
                <th> Role </th>
                <th> Edit </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {firstname} {lastname}
                </td>
                <td>{email}</td>
                {!roles ? <td> </td> : <td>{roles.join(", ")}</td>}
                <td>
                  <button className="editDelete" onClick={this.editUser}>
                    Details
                  </button>
                  <button className="editDelete" onClick={this.changePassword}>
                    Password
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showModalEditUser}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <ProfileForm
                  getUserList={this.getUserList}
                  orgId={orgId}
                  userId={user_id}
                  firstname={this.state.firstname}
                  lastname={this.state.lastname}
                  email={this.state.email}
                  roles={roles}
                  selectedRoles={selectedRoles}
                  checked={this.state.active}
                  type={"edit"}
                  edit={true}
                  profile={true}
                  closeForm={this.closeEditUser}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showModalChangePassword}
            onHide={this.closeChangePassword}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Change password</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <ChangePasswordForm
                  getUserList={this.getUserList}
                  orgId={orgId}
                  email={email}
                  closeChangePassword={this.closeChangePassword}
                  edit={true}
                  type={"edit"}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
