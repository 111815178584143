/** Deprecated */
import React, { Component } from "react";
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataZoom";
import 'echarts/lib/component/legendScroll';
import { Tab } from "semantic-ui-react";

import moment from "moment";

const TIMELINE = [], HOURS=[] ;
for (let hour = 0, time=moment(0, 0).utc(); hour <= 24; hour++, time.add(1,'hour')) {
  TIMELINE.push(time.format("HH:mm"));
  HOURS.push(time.hours())
}

const todayDate = moment().format("YYYY-MM-DD");
const tomorrowDate = moment()
  .add(1, "days")
  .format("YYYY-MM-DD");
const yesterdayDate = moment()
  .add(-1, "days")
  .format("YYYY-MM-DD");


//
// Export some constants to specify the axes
///
export const CURRENCY_MINOR="CURRENCY_MINOR",
  CURRENCY_MAJOR="CURRENCY_MAJOR",
  LOAD="LOAD",
  LOAD_PREDICTED="LOAD_PREDICTED"

//
// Preconfigured styles for the series
// The key should match 'item' in the Results() object passed in
// 'yAxisIndex' should be one of the contants above and that axis
// must be enabled somewhere on the graph
//

const STYLES = new Map([
  [ 'actual_cost', { itemStyle: { color: "#1ecbe1" }, step: "end", yAxisIndex: CURRENCY_MAJOR}],
  [ 'predicted_cost', { itemStyle: { color: "#E74C3C" }, step: "end", yAxisIndex: CURRENCY_MAJOR}],
  [ 'wholesale', { itemStyle: { color: "#2C3E50 " }, step: "end", yAxisIndex: CURRENCY_MINOR }],
  [ 'charges', {itemStyle: { color: "#4CAF50 " },step: "end", yAxisIndex: CURRENCY_MINOR}],
  [ 'tariffs', {itemStyle: { color: "#3f51b5" },step: "end", yAxisIndex: CURRENCY_MINOR}],

  [ 'actual_load', { itemStyle: { color: "#8476ff" }, smooth: true, yAxisIndex: LOAD}],
  [ 'actual_supply', { itemStyle: { color: "#ffea00" }, smooth: true, yAxisIndex: LOAD}],
  [ 'predicted_supply', { itemStyle: { color: "#ff8433" }, smooth: true, yAxisIndex: LOAD }],
  [ 'predicted_load', { itemStyle: { color: "#d32cbf" }, smooth: true, yAxisIndex: LOAD,}]

])


export default class DashboardGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yName: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const {
        yName,
      } = this.props;
      this.setState({
        yName: yName,
      });
    }, 1);
  };

  graph = (tab,yName) => {
    const { series=[], currency, axes={} } = this.props ;
    //console.log('1111series', series)

    const __axes = [] ;

    function buildAxis(axis, position, offset) {
      let __o ;
      switch(axis) {
        case CURRENCY_MAJOR:
          __o = {
            axisLabel: {
              formatter: `{value}`,
              inside:true,
              margin:-40
            },
            axisLine: {
              lineStyle: {
                color: "blue",
                  nside:true
              }
            },
            name: `Cost(${currency.major})`,
          }
          break ;
        case CURRENCY_MINOR:
          __o = {
            axisLabel: {
              formatter: `{value}`,
              inside:true,
              margin:-40
            },
            axisLine: {
              lineStyle: {
                color: "black"
              }
            },
            name: `Price(${currency.minor})`,
          }
          break ;
        default:
          break ;
      }
      return {
          __axis: axis,
          type: "value",
          scale: true,
          axisTick: {
            alignWithLabel: true
          },
          position,
          offset,
          nameTextStyle: {
            fontSize: 16,
            padding: 8
          },
          ...__o
        }
    }

    // Build up the axes - first left
    if(Array.isArray(axes.left)) {
      let offset = 0 ;
      for(const a of axes.left) {
        __axes.push(buildAxis(a, "left", offset))
        offset+=40
      }
    }
    // ...then right
    if(Array.isArray(axes.right)) {
      let offset = 0 ;
      for(const a of axes.right) {
        __axes.push(buildAxis(a, "right", offset))
        offset+=40
      }
    }

    // Render a plot element based on series data
    function __plot(series, opts={}) {
      if(series===null) {
        return {
          name: '',
          type: 'line',
          data: []
        }
      }
      // Get the data series for yesterday/today/tomorrow as appropriate
      const __item = series[tab] ;
      const {  ...__opts } = opts ;
      // Start with a default style
      const __style = { type: 'line', yAxisIndex: LOAD,
        // Add/override preconfigured styles
        ...STYLES.get(series.item) || {},
        // Add/override passed options
        ...__opts}

      // Find the axis
      let yAxisIndex = -1 ;
      // console.log('Foo : ', [...__axes.entries())
      for(let i=0; i< __axes.length && yAxisIndex<0; i+=1) {
        if(__axes[i].__axis===__style.yAxisIndex) {
          yAxisIndex=i ;
        }
      }
      if(yAxisIndex<0) {
        console.error('Undefined yAxisIndex - series = ', series)
      }
      return {
        ...__item,
        ...__style,
        yAxisIndex
      }
    }

    // Create the plot elements
    const __plots = series.map(s => {
      return __plot(s)
    })


    return {
      title: {
        text: "",
        x: "center"
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        //type: 'scroll',
        orient: 'horizontal',
        itemWidth:10,
        itemHeight:8,
        itemGap: 20,
        icon:'roundRect',
        textStyle:{
             fontSize:12
          },
        //data: [  ...__plots.map(p => p.name) ],
  },
      toolbox: {
        feature: {
          dataZoom: {
            title: "zoom",
            show: true
          },
          saveAsImage: { title: "download as png" }
        }
      },
      grid: {
        top:"20%",
        left: "8%",
        right: "10%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: [
        {
          interval:1,
          name: "",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 20,
            padding: 20
          },
          type: "category",
          data: TIMELINE,
          axisTick: {
            alignWithLabel: true
          },
          boundaryGap:false,
          splitLine:{
            show:true
          },
          offset:10
        }
      ],
      yAxis: __axes,
      series: [
        ...__plots
      ]
    };
  };

  graph2 = (tab,yName) => {
    const  series = this.props.series2 || [];
    const  axes = this.props.axes2 || {};
    //console.log('2222series', series)
    const __axes = [] ;

    function buildAxis(axis, position, offset) {
      let __o ;
      switch(axis) {
        case LOAD:
          __o = {
            axisLabel: {
              formatter: "{value}",
              inside:true,
              margin:-40,
            //  padding: [0, 0, 0, 5]
            },
            axisLine: {
              lineStyle: {
                color: "black"
              }
            },
            name: "Energy(kWh)",
            nameLocation :'end'
          }
        break ;
        default:
          break ;
      }
      return {
          __axis: axis,
          type: "value",
          position,
          offset,
          nameTextStyle: {
            fontSize: 16,
            padding: 8
          },
          ...__o
        }
    }
    if(Array.isArray(axes.left)) {
      let offset = 0 ;
      for(const a of axes.left) {
        __axes.push(buildAxis(a, "left", offset))
        offset+=40
      }
    }

    if(Array.isArray(axes.right)) {
      let offset = 0 ;
      for(const a of axes.right) {
        __axes.push(buildAxis(a, "right", offset))
        offset+=40
      }
    }



    // Render a plot element based on series data
    function __plot(series, opts={}) {
      if(series===null) {
        return {
          name: '',
          type: 'line',
          data: []
        }
      }
      // Get the data series for yesterday/today/tomorrow as appropriate
      const __item = series[tab] ;
      const {  ...__opts } = opts ;
      // Start with a default style
      const __style = { type: 'line', itemStyle: {color: 'black'}, yAxisIndex: LOAD,
        // Add/override preconfigured styles
        ...STYLES.get(series.item) || {},
        // Add/override passed options
        ...__opts}
      // Find the axis
      let yAxisIndex = -1 ;
      for(let i=0; i< __axes.length && yAxisIndex<0; i+=1) {
        if(__axes[i].__axis===__style.yAxisIndex) {
          yAxisIndex=i ;
        }
      }
      if(yAxisIndex<0) {
        console.error('Undefined yAxisIndex - series = ', series)
      }
      return {
        ...__item,
        ...__style,
        yAxisIndex
      }
    }

    // Create the plot elements

    const __plots = series.map(s => {
      return __plot(s)
    })

    return {
      title: {
        text: "",
        x: "center"
      },
      tooltip: {
        trigger: "axis"
      },


      legend: {
      //  type: 'scroll',
        orient: 'horizontal',
        itemWidth:10,
        itemHeight:8,
        itemGap: 20,
        icon:'roundRect',
        textStyle:{
             fontSize:12
          },
        //data: [  ...__plots.map(p => p.name) ],
  },
      toolbox: {
        feature: {
          dataZoom: {
            title: "zoom",
            show: true
          },
          saveAsImage: { title: "download as png" }
        }
      },
      grid: {
        top:"20%",
        left: "8%",
        right: "10%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: [
        {
        //  interval:1,
          name: "",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 20,
            padding: 20
          },
          type: "category",
          data: TIMELINE,
          axisTick: {
            alignWithLabel: true
          },
          boundaryGap: false,
          splitLine:{
            show:true
          },
          offset:10,

        }
      ],
      yAxis: __axes,
      series: [
        ...__plots
      ]
    };
  };

  graph3 = (tab, yName) => {
    const { series = [], currency, axes = {}, timeline } = this.props;
    //console.log('series ', series)

    const __axes = [];

    function buildAxis(axis, position, offset) {
      let __o;
      switch (axis) {
        case CURRENCY_MAJOR:
          __o = {
            axisLabel: {
              formatter: `{value}`,
              inside: true,
              margin: -40
            },
            axisLine: {
              lineStyle: {
                color: "blue",
                nside: true
              }
            },
            name: `Cost(${currency.major})`
          };
          break;
        case CURRENCY_MINOR:
          __o = {
            axisLabel: {
              formatter: `{value}`,
              inside: true,
              margin: -40
            },
            axisLine: {
              lineStyle: {
                color: "black"
              }
            },
            name: `Price(${currency.minor})`
          };
          break;
        case LOAD:
          __o = {
            axisLabel: {
              formatter: "{value}",
              inside: true,
              margin: -40
              //  padding: [0, 0, 0, 5]
            },
            axisLine: {
              lineStyle: {
                color: "black"
              }
            },
            name: "Energy(kWh)",
            nameLocation: "end"
          };
          break;
        default:
          break;
      }
      return {
        __axis: axis,
        type: "value",
        scale: true,
        axisTick: {
          alignWithLabel: true
        },
        position,
        offset,
        nameTextStyle: {
          fontSize: 16,
          padding: 8
        },
        ...__o
      };
    }

    // Build up the axes - first left
    if (Array.isArray(axes.left)) {
      let offset = 0;
      for (const a of axes.left) {
        __axes.push(buildAxis(a, "left", offset));
        offset += 40;
      }
    }
    // ...then right
    if (Array.isArray(axes.right)) {
      let offset = 0;
      for (const a of axes.right) {
        __axes.push(buildAxis(a, "right", offset));
        offset += 40;
      }
    }

    // Render a plot element based on series data
    function __plot(series, opts = {}) {
      if (series === null) {
        return {
          name: "",
          type: "line",
          data: []
        };
      }
      // Get the data series for yesterday/today/tomorrow as appropriate
      const __item = series[tab];
      const { ...__opts } = opts;
      // Start with a default style
      const __style = {
        type: "line",
        yAxisIndex: LOAD,
        // Add/override preconfigured styles
        ...(STYLES.get(series.item) || {}),
        // Add/override passed options
        ...__opts
      };

      // Find the axis
      let yAxisIndex = -1;
      // console.log('Foo : ', [...__axes.entries())
      for (let i = 0; i < __axes.length && yAxisIndex < 0; i += 1) {
        if (__axes[i].__axis === __style.yAxisIndex) {
          yAxisIndex = i;
        }
      }
      if (yAxisIndex < 0) {
        console.error("Undefined yAxisIndex - series = ", series);
      }
      return {
        ...__item,
        ...__style,
        yAxisIndex
      };
    }

    // Create the plot elements
    const __plots = series.map(s => {
      return __plot(s);
    });
    //  console.log('__plots',__plots)

    return {
      title: {
        text: "",
        x: "center"
      },
      tooltip: {
        trigger: "axis"
      },
      legend: {
        orient: 'horizontal',
        itemWidth: 10,
        itemHeight: 8,
        itemGap: 20,
        icon: "roundRect",
        textStyle: {
          fontSize: 12
        },
        //data: [...__plots.map(p => p.name)]
      },
      toolbox: {
        feature: {
          dataZoom: {
            title: "zoom",
            show: true
          },
          saveAsImage: { title: "download as png" }
        }
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "10%",
        bottom: "10%",
        containLabel: true
      },
      xAxis: [
        {
          interval: 1,
          name: "",
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 20,
            padding: 20
          },
          type: "category",
          data: timeline,
          axisTick: {
            alignWithLabel: true,
            interval: 0
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          offset: 10
        }
      ],
      yAxis: __axes,
      series: [...__plots]
    };
  };

  render() {
    const {
      yName, modules
    } = this.props;

    console.log('Energy : ', this.graph2(
      'yesterday',
      yName
    ))

    console.log('Cost : ', this.graph(
      'yesterday',
      yName
    ))

    const panes = [
      {
        menuItem: "Yesterday",
        render: () => (
          <Tab.Pane>
          <ReactEcharts
            echarts={echarts}
            option={this.graph2(
              'yesterday',
              yName
            )}
            style={{ height: "350px", width: "100%" }}
          />
            <ReactEcharts
              echarts={echarts}
              option={this.graph(
                'yesterday',
                yName
              )}
              style={{ height: "350px", width: "100%" }}
            />
            {yesterdayDate}
          </Tab.Pane>
        )
      },
      {
        menuItem: "Today",
        render: () => (
          <Tab.Pane>
          <ReactEcharts
            notMerge={true}
            lazyUpdate={true}
            echarts={echarts}
            option={this.graph2(
              "today",
              yName,
            )}
            style={{ height: "350px", width: "100%" }}
          />
            <ReactEcharts
              notMerge={true}
              lazyUpdate={true}
              echarts={echarts}
              option={this.graph(
                "today",
                yName,
              )}
              style={{ height: "350px", width: "100%" }}
            />

            {todayDate}
          </Tab.Pane>
        )
      },
      {
        menuItem: "Tomorrow",
        render: () => (
          <Tab.Pane>
          <ReactEcharts
            echarts={echarts}
            option={this.graph2(
              "tomorrow",
              yName,
            )}
            style={{ height: "350px", width: "100%" }}
          />
            <ReactEcharts
              echarts={echarts}
              option={this.graph(
                "tomorrow",
                yName,
              )}
              style={{ height: "350px", width: "100%" }}
            />
            {tomorrowDate}
          </Tab.Pane>
        )
      }
    ];

    const panesReport = [
      {
        menuItem: "Report",
        render: () => (
          <Tab.Pane>
            <ReactEcharts
              notMerge={true}
              lazyUpdate={true}
              echarts={echarts}
              option={this.graph3("alldata", yName)}
              style={{ height: "350px", width: "100%" }}
            />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div >
        {
          !modules?
          (
            <Tab panes={panes} defaultActiveIndex={1} />
          )
          : <Tab panes={panesReport} />
        }

      </div>
    );
  }
}
