import React, { Component } from "react";
import OrganisationTable from "./OrganisationTable";

export default class OrganisationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      authenticated: false
    };
  }
  showNavbar = state => {
    this.setState({ active: state });
  };

  render() {
    return (
      <div>
        <div>
          <OrganisationTable />
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}
