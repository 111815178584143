import React, {useState, useEffect} from 'react'
import { Form, ButtonToolbar, ButtonGroup, Button, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/dataZoom";
import SessionStorage from '../../../../services/SessionStorage'

import { JAN1, scaleWattHours, OPTIONS as Options, calculateAxisParams } from './utils'

import SolarWidget from './SolarWidget'
import RevenueWidget from './RevenueWidget'

const OPT_SHOW_DAYS="senario.solar.day.plotCount"

// Use 2019 as don't want a leap year
const DEC31=moment('2019-12-31')
const noop=() => {}

const XAXIS = {
	type: 'category',
	name: 'Time',
	nameLocation: "middle",
	nameTextStyle: {
		fontSize: 16,
		padding: [10, 0,0,0]
	},
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00',
        '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
        '20:00', '21:00', '22:00', '23:00']
}
const GRID = { ...Options.grid, 
    bottom: '25%'
}

function Plot({day, type, data, comparing}) {
	const [options, setOptions] = useState({ ...Options, xAxis: [XAXIS]})
	const [chart, setChart] = useState(null)
	const [readings, setReadings] = useState(null)
	const [scaler, setScaler] = useState(null)
	const [billedAmount, setBilledAmount] = useState(null)

	useEffect(() => {
		if(chart===null || day===null) return ;
        if(data===null) return ;
		if(day===null) {
			console.error("'day' required" )
			return 
		}

		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

        const [yAxis]  = __opt.yAxis
        yAxis.show=true
        yAxis.max=0

        const __start= (day.dayOfYear()-1)*24, 
			__end = __start+24
		// Figure out the units/precision in the data
		const __rawSeries = [data, ...comparing].map(([__scenario, __data=null]) => {
			if(__data===null) {
				console.error(`Unexpected null value for __data when mapping raw series for scenario ${__scenario.name}`)
				return [__scenario, [], 0]
			}
			const __series = __data.hourly[type].slice(__start, __end),
				max=__series.reduce((max, v) => v>max?v:max, 0)
			const __totalPrice = __data.hourly.prices
				?__data.hourly.prices.slice(__start, __end).reduce((tot, v) => tot+v, 0)
				:null

			return [__scenario, __series, max, __totalPrice]
		}), 
			__max = __rawSeries.reduce((max, [,,v]) => v>max?v:max, 0),
			__scaler = scaleWattHours(__max), 
			__axisParams = calculateAxisParams(__scaler.transform(__max))

		if(__rawSeries.length>0) {
			const [,,,__totalPrice] = __rawSeries[0]
			if(__totalPrice!==null) {
				setBilledAmount(__totalPrice/100)
			}
		}
	
		const series = __rawSeries.map(([scenario, __data]) => ({
				name: scenario.name,
				data: __data.map(__scaler.transform),
				xAxisIndex: 0,
				yAxisIndex: 0,
				smooth: true,
				emphasis: {
					focus: 'series'
				},
				type: 'line',
				areaStyle: {}
		}))
				
		setReadings(__rawSeries.map(s => s[1]))
		setScaler(__scaler)


		const legend = {
			top: '90%', 
			left: 'center',
			formatter: name => {
				if(!window.chrome) return name
				return name.length>20?
						`${name}              `:
						`${name}     `
			},
			data: series.map(s => s.name)
		}

        const title = {
            left: 'center',
            text: `Solar Output ${day.format('MMMM Do')} (${type==='dc'?'DC':'AC'})`
        }

		setOptions(o => ({
            ...__opt,
            title,
			legend,
            series,
            grid: GRID,
			xAxis: [{...XAXIS }],
			yAxis: [{...yAxis, name: `Output (${__scaler.units})`, ...__axisParams}]
		}))

		return () => {
			setReadings(r => null)
		}
    }, [chart, day, type, data, comparing])

	return (
		<Row>
			<Col md={10}>
				<ReactEcharts
					ref={(e) => { setChart(e); }}
					echarts={echarts}
					option={options}
					notMerge={true}
					lazyUpdate={true}
					style={{ height: "33vh"}}/>
			</Col>
			{Array.isArray(readings) && readings.length>0?
			(<Col md={2}>
				<div className="component-container">
					<SolarWidget readings={readings[0]} scaler={scaler}/>
					<RevenueWidget amount={billedAmount}/>
				</div>
			</Col>):null}
		</Row>
	)
}

function PlotSplit({day, type, split}) {
	const [options, setOptions] = useState({ ...Options, xAxis: [XAXIS]})
	const [chart, setChart] = useState(null)

	useEffect(() => {
		if(chart===null || day===null || split===null) return ;

		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

        const [yAxis]  = __opt.yAxis
        yAxis.show=true
        yAxis.max=0

        const __start= (day.dayOfYear()-1)*24, 
			__end = __start+24

		const __raw = split.map(m => ([m.name, m.energy.slice(__start, __end)]))

		// Aggregate the series to get the totals
		const __agg = []
		for(let i=0;i<24;++i) {
			__agg[i] = __raw.reduce((t, v) => t+v[1][i], 0)
		}
		// Get the max for the scale
		const __max = __agg.reduce((m, v) => v>m?v:m, 0),
			__scaler = scaleWattHours(__max, 3), 
			__axisParams = calculateAxisParams(__scaler.transform(__max))


		const series = __raw.filter(([,__data]) => __data.reduce((t, v) => t+v, 0)>0)
			.map(([label, __data]) => ({
				name: label,
				data: __data.map(__scaler.transform),
				xAxisIndex: 0,
				yAxisIndex: 0,
				emphasis: {
					focus: 'series'
				},
				type: 'bar',
				stack: 'total',
				areaStyle: {}
			}))
				
		const legend = {
			top: '90%', 
			left: 'center',
			formatter: name => {
				if(!window.chrome) return name
				return name.length>20?
						`${name}              `:
						`${name}     `
			},
			data: series.map(s => s.name)
		}

        const title = {
            left: 'center',
            text: `Member split`
        }

		setOptions(o => ({
            ...__opt,
            title,
			legend,
            series,
            grid: GRID,
			xAxis: [{...XAXIS }],
			yAxis: [{...yAxis, name: `Share (${__scaler.units})`, ...__axisParams}]
		}))
    }, [chart, day, type, split])

	return (
		<Row>
			<Col md={10}>
				<ReactEcharts
					ref={(e) => { setChart(e); }}
					echarts={echarts}
					notMerge={true}
					option={options}
					lazyUpdate={true}
					style={{ height: "33vh"}}/>
			</Col>
		</Row>
	)
}


export default function DayPlot({data=null, split=null, day: __day=null, type='dc', comparing=[], setDay:setDayCB=noop}) {
	const [day, setDay] = useState(__day)
	// const [spill, setSpill] = useState(null)
	// const [byMember, setByMember] = useState(null)
	const [days, setDays] = useState(null)
    const [prev, setPrev] = useState(null)
    const [startOfMonth, setStartOfMonth] = useState(null)
    const [next, setNext] = useState(null)
	const [endOfMonth, setEndOfMonth] = useState(null)
	const [showDays, setShowDays] = useState(SessionStorage.get(OPT_SHOW_DAYS, 1))

	useEffect(() => {
		SessionStorage.set(OPT_SHOW_DAYS, showDays)
	}, [showDays])

    useEffect(() => {
		if(day===null) return 
        const __prev=moment(day).subtract(1, 'day')
        if(__prev.isBefore(JAN1)) __prev.add(1, 'year')
        setPrev(__prev)
        setStartOfMonth(moment(day).startOf('month'))
        const __next=moment(day).add(1, 'day')
        if(__next.isAfter(DEC31)) __next.subtract(1, 'year')
        setNext(__next)
        setEndOfMonth(moment(day).startOf('month').add(1, 'month').subtract(1, 'day'))

		const __days=[]
		for(let __d=0;__d<showDays;++__d) {
			const __day = moment(day).add(__d, 'days')
			if(__day.isAfter(DEC31)) __day.subtract(1, 'year')
			__days.push(__day)
		}
		setDays(__days)

	}, [day, showDays])

    const DayNavButton = ({day, icon}) => day!==null?(
        <Button type="button" onClick={() => setDay(day)} variant="outline-secondary">
            <i className={`fas ${icon}`}/>{day.format('MMM Do')}
        </Button>):null


    return (
		<>
			{day!==null?(<>
			<Row key="dayplot1" className="justify-content-md-center" style={{marginBottom: "0.5rem"}}>
				<Col md="auto">
					<Form inline>
					<Form.Group>
						<Form.Label column>Show Days : </Form.Label>
						<Form.Control 
							as="select"
							value={showDays}
							onChange={e => setShowDays(e.target.value)}>
								<option value="1">1 day</option>
								<option value="2">2 days</option>
								<option value="3">3 days</option>
								<option value="4">4 days</option>
								<option value="5">5 days</option>
								<option value="6">6 days</option>
								<option value="7">7 days</option>
						</Form.Control>
					</Form.Group>
				</Form>			
				</Col>
			</Row>
			<Row key="dayplot2" className="justify-content-md-center">
				<Col md="auto">
				<ButtonToolbar>
					<ButtonGroup size="sm" className="mr-2" >
                        <DayNavButton day={startOfMonth} icon="fa-angle-double-left"/>
                        <DayNavButton day={prev} icon="fa-angle-left"/>
					</ButtonGroup>
					<ButtonGroup size="sm" className="mr-2" >
                    <Button type="button" onClick={() => setDayCB(null, day.format('MMM'))} variant="outline-secondary">
							<i className="fas fa-angle-up"/>{day.format('MMMM')}
						</Button>
					</ButtonGroup>
					<ButtonGroup size="sm" className="mr-2" >
                        <DayNavButton day={next} icon="fa-angle-right"/>
                        <DayNavButton day={endOfMonth} icon="fa-angle-double-right"/>
					</ButtonGroup>
				</ButtonToolbar>
				</Col>
			</Row></>):null}
			{days!==null?days.map(__d => (<div key={`plot___${__d.dayOfYear()}`}>
				<Plot  day={__d} type={type} data={data} comparing={comparing}/>
				{split!==null?
					(<PlotSplit key={`plotSplit___${__d.dayOfYear()}`} day={__d} type={type} split={split}/>):null}
			</div>)):null}
		</>
	)
}
