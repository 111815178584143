import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Button, Row, Col, Tooltip } from 'react-bootstrap'
import { useParams, Link, useLocation  } from "react-router-dom";
import { Overlay, FloatingButton } from '../components/UIComponents'
import {AlertContainer, Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import {getBattery, getBatteryConfig} from "../../services/battery-service"
import BatterySelector from './BatterySelector'
import BatteryMeterDetails from "./BatteryMeterDetails";
import BatteryDataUpload from "./BatteryDataUpload";
import DateRangePicker from '../components/DateRangePicker.jsx'
import UserContext from "../../services/user-context"
import Fetch from "../helpers/fetch-data"
import BatteryPlot from "./BatteryPlot";
import BatteryOptimisation from "./BatteryOptimisation";

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")

export default function BatteryDetails() {
    const [battery, setBattery] = useState(null)
    const [add, setAdd] = useState(false)
    const [config, setConfig] = useState(null)
    const [upload, setUpload] = useState(false)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
    const [readings, setReadings] = useState(null)
    const [soc, setSOC] = useState(null)


    const { Time } = useContext(UserContext)
    const [maxDate] = useState(Time.localToDate(Time.tomorrow()))

    const { id } = useParams()

    const location = useLocation()

    useEffect(() => {
        if(!battery || !battery.meter_id || !startDate || !endDate) return

        const __loadData = async (_id) => {
            // console.log(`[SiteLoad] Fetch load data for ${site.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
            Fetch(`/api/battery/${_id}/soc`, 
                { 
                    from: Time.day(startDate).format(),
                    // Add day + 1 minute to include midnight at end
                    to: Time.day(endDate).add(1, 'minute').format()
                }, 
                (results) => {
                    setReadings(results.map(
                        ({timestamp, charge, discharge, soc}) => 
                            ({
                                timestamp, 
                                reading: (charge>0?charge*-1:discharge)/1000,
                                soc
                            })))
                    setSOC(results.map(({timestamp, soc}) => ({timestamp, soc})))
                })
            // Fetch(`/api/meter/${battery.meter_id}/readings`, { 
            //         from: Time.day(startDate).format(),
            //         // Add day + 1 minute to include midnight at end
            //         to: Time.day(endDate).add(1, 'minute').format(),
            //         aggregation: 'raw',
            //         meter_type: 'storage'
            //     }, setReadings)
        }
    
        __loadData(battery._id)

    }, [startDate, endDate, Time, battery])

    useEffect(() => {
        if(!id) return 

        getBattery(id)
        .then(setBattery)

    }, [id])

    // useEffect(() => {
    //     console.log('Readings : ', readings)
    // }, [readings])

    // useEffect(() => {
    //     console.log('soc : ', soc)
    // }, [soc])

    useEffect(() => {
        if(battery===null) return

        getBatteryConfig(battery)
        .then(setConfig)

        return () => {
            setConfig(null)
        }
    }, [battery])

    const handleUpdateDateRange = useCallback((__start, __end) => {
        // console.log(`[handleUpdateDateRange] - start = ${__start}, end=${__end})`)
        setStartDate(__start)
        setEndDate(__end)
    }, [])


    return (
        <Container fluid>
            <BatterySelector selected={id}/>
            <AlertContainer tag="battery.notifications" page="battery"/>
            { battery && <>
            {!battery.meter_id?<>
            <Row>
                <Col>
                    <span><em>No meter associated with battery {battery.name}</em></span>
                    <a href="#" onClick={e => {
                        e.preventDefault()
                        setAdd(true)
                    }}> - Add meter</a>
                </Col>
            </Row>
            { add && <Row>
                <Col>
                    <Overlay>
                        <BatteryMeterDetails 
                            battery={battery}
                            onDone={async (msg) => {
                                setAdd(false)
                                if(msg) {
                                    notify.info(msg)
                                }
                                setBattery(await getBattery(id))
                            }}/>
                    </Overlay>
                </Col>
            </Row>}
            </>:<div>
            { config && config.upload && <FloatingButton icon="fa-upload" label="Upload CSV data" link='#' onClick={e => {
                e.preventDefault()
                setUpload(true)
            }}/>}
            </div> /** !battery.meter_id*/}
            { upload && <BatteryDataUpload battery={battery} onDone={() => setUpload(false)}/>}
            { battery.meter_id && 
            /* Plot the battery */
            <Row>
                <Col>
                    <DateRangePicker
                        minDate={battery.valid_from} 
                        maxDate={maxDate} maxRange="P1M"
                        onChange={handleUpdateDateRange}
                        nobutton/>
                    <BatteryPlot data={readings} soc={soc} startDate={startDate} endDate={endDate}/>
                </Col>
            </Row> /** battery.meter_id */ }
            {/* <Row>
                <Col>
                    <BatteryOptimisation battery={battery} startDate={startDate} endDate={endDate}/>
                </Col>
            </Row> */}
            </> /** battery*/}
        </Container>
    )
}
