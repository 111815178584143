import React from 'react';
import RateChargesRow from './RateChargesRow'

export default function RateChargeTable({ rate_charges}) {
    const emptyMessage = (
      <div >
      </div>
    );
    const formList = (
      <div >
          <div >
            <h4>Rate Charges </h4>
          </div>
          <table className="table-market">
            <thead>
              <tr>
                <th scope="col"> Name </th>
                <th scope="col"> Valid from </th>
                <th scope="col"> Valid to </th>
                <th scope="col"> Start </th>
                <th scope="col"> End </th>
                <th scope="col"> Band </th>
                <th scope="col"> Charge Type </th>
                <th scope="col"> Value Type </th>
                <th scope="col"> Currency </th>
                <th scope="col"> Value </th>
              </tr>
            </thead>
              <tbody>
                {rate_charges.map(rate_charge=> <RateChargesRow
                  rate_charges={rate_charges} rate_charge={rate_charge} key={rate_charge._id}  />)}
              </tbody>
          </table>
      </div>
    )
    return (
        <div>
            <div>
                {rate_charges.length === 0
                    ? emptyMessage
                    : formList}
            </div>
        </div>
    );
}
