import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { Menu, Segment } from "semantic-ui-react";
import {  LinkContainer } from 'react-router-bootstrap'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import UserContext from "../services/user-context"
import SessionStorage from "../services/SessionStorage"
import {AlertContainer} from './components/AlertListener'
import OrganisationDashboard from './organisation/OrganisationDashboard'
import ClusterDashboard from './cluster/ClusterDashboard'
import SiteDashboard from './site/SiteDashboard'

const DASH_SELECTED_TAB = 'main.dash.tab.active',
    DEFAULT_TAB = '/dashboard/home/organisation'

export default function MainDashboard() {
	const [activeTab, setActiveTab] = useState(SessionStorage.get(DASH_SELECTED_TAB, DEFAULT_TAB))

    const { user } = useContext(UserContext)

    const location = useLocation()
    const history  = useHistory()

    useEffect(() => {
        if(!location || ! history) return

    })

    function handleTabChange(tab) {
        const __activeTab = `/dashboard/home/${tab}`
		SessionStorage.set(DASH_SELECTED_TAB, __activeTab)
		setActiveTab(__activeTab)
    }

    if(!user) return null ;

    return (<Container fluid>
        <Menu  pointing tabular attached="top">
            {user.isClusterOwner && <LinkContainer to="/dashboard/home/cluster">
                <Menu.Item name='Cluster' onClick={() => handleTabChange('cluster')}>
                    Cluster
                </Menu.Item>
            </LinkContainer>}
            <LinkContainer to="/dashboard/home/organisation">
                <Menu.Item name='Organisation' onClick={() => handleTabChange('organisation')}>
                    Organisation
                </Menu.Item>
            </LinkContainer>
            {user.isOrgAdmin && <LinkContainer to="/dashboard/home/site">
                <Menu.Item name='Site' onClick={() => handleTabChange('site')}>
                    Site
                </Menu.Item>
            </LinkContainer>}

        </Menu>
        <Segment attached="bottom">
            <AlertContainer tag="dash.notifications" page="dashboard"/>
            <Switch>
                {user.isClusterOwner && <Route key="cluster" path="/dashboard/home/cluster" component={ClusterDashboard}/>}
                <Route key="organisation" path="/dashboard/home/organisation" component={OrganisationDashboard}/>
                {user.isOrgAdmin && <Route key="site" path="/dashboard/home/site" component={SiteDashboard}/>}
                <Route
                    render={() => <Redirect to={location.pathname.startsWith(activeTab)?DEFAULT_TAB:activeTab}/>}
                />
            </Switch>
        </Segment>    
    </Container>)
}