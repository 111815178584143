import {Request} from "../../../services/HttpService";

const request = new Request({ service: 'Site service'})


class CacheResources {
    constructor() {
        this.meters = new Map()
        this.renewables = new Map()
    }

    clear() {
        this.meters.clear()
        this.renewables.clear()
    }

    invalidate(siteId, __meters=true, __renewables=true) {
        if(__meters) this.meters.delete(siteId)
        if(__renewables) this.renewables.delete(siteId)
    }

    async getMetersForSite(siteId) {
        if(this.meters.has(siteId)) {
            return this.meters.get(siteId)
        }
        const __results = await request.call(`/api/meter?site_id=${siteId}`)
        this.meters.set(siteId, __results)
        return __results
    }

    async getRenewablesForSite(siteId) {
        if(this.renewables.has(siteId)) {
            return this.renewables.get(siteId)
        }
        const __results = await request.call(`/api/solar/data?site_id=${siteId}&panels`)
        this.renewables.set(siteId, __results)
        return __results
    }
}

export default CacheResources