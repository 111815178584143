import React, { Component } from "react";
import ProfileRow from "./ProfileRow";
//import ToastContainer from "../../utility/ToastContainer";
import MeterServices from "../../services/MeterServices";
import { Modal, Alert } from "react-bootstrap";
import AddProfileForm from "./AddProfile";
import { notify } from "../../utility/ToastContainer";
import RateChargesTable from "./RateChargesTable";
import Dialog from "react-bootstrap-dialog";
//import moment from "moment";

export default class chargeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      billable_id: this.props.billable_id || "",
      getInactive: "Show",
      profile_id: "",
      duos: "",
      tuos: "",
      valid_from: "",
      rate_charges: [],
      show: false,
      variant: "",
      msg: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const { billable_id, timezone, currency } = this.props;
      this.setState({
        billable_id: billable_id,
        timezone: timezone,
        currency: currency
      });
    }, 1);
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  getProfileHistory = () => {
    //  console.log('1')
    const billable_id = this.props.billable_id;
    const activeState = this.state.getInactive;
  //  console.log('activeState',billable_id, activeState)
    if (billable_id && activeState === "Show") {
      console.log('2', billable_id)
      MeterServices.getProfileHistory(billable_id)
        .then(response => {

          const profiles = response.data;
          console.log('response', profiles)
          if (profiles.length) {
            const billable_id = profiles[0].billable_id;
            const timezone = profiles[0].timezone;
            const profile_id = profiles[0]._id;
            const currency = profiles[0].currency;
            this.setState({
              getInactive: "Hide",
              profiles: profiles,
              billable_id: billable_id,
              timezone: timezone,
              currency: currency,
              profile_id: profile_id
            });
            this.props.sendData(profiles);
            this.getRatesList(billable_id, profile_id);
          } else {
            this.setState({
              profiles: [],
              rate_charges: [],
              getInactive: "Hide",
              billable_id: "",
              timezone: "",
              currency: "",
              profile_id: ""
            });
            this.props.sendData(profiles);
            this.getRatesList();
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            profiles: [],
            getInactive: "Hide",
            billable_id: "",
            timezone: "",
            currency: "",
            profile_id: "",
            rate_charges: []
          });
        });
    } else if (billable_id && this.state.getInactive === "Hide") {
      const id = this.state.billable_id
      this.getProfileList(id)
    } else {
      this.setState({
        profiles: [],
        getInactive: "Hide",
        billable_id: "",
        timezone: "",
        currency: "",
        profile_id: "",
        rate_charges: []
      });
    }
  };

  getProfileList = (billable_id) => {
    if (billable_id) {
      MeterServices.getProfileList(billable_id)
        .then(response => {
          const profiles = response.data;
          if (profiles.length) {
            const billable_id = profiles[0].billable_id;
            const timezone = profiles[0].timezone;
            const profile_id = profiles[0]._id;
            const currency = profiles[0].currency;
            this.setState({
              getInactive: "Show",
              profiles: profiles,
              billable_id: billable_id,
              timezone: timezone,
              currency: currency,
              profile_id: profile_id
            });
            this.props.sendData(profiles);
            this.getRatesList(billable_id, profile_id);
          } else {
            this.setState({
              profiles: [],
              rate_charges: [],
              getInactive: "Show",
              billable_id: "",
              timezone: "",
              currency: "",
              profile_id: ""
            });
            this.props.sendData(profiles);
            this.getRatesList();
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            profiles: [],
            getInactive: "Show",
            billable_id: "",
            timezone: "",
            currency: "",
            profile_id: "",
            rate_charges: []
          });
        });
    } else {
      this.setState({
        profiles: [],
        getInactive: "Show",
        billable_id: "",
        timezone: "",
        currency: "",
        profile_id: "",
        rate_charges: []
      });
    }
  };

  getRatesList = (billable_id, profile_id) => {
    if (billable_id && profile_id) {
      MeterServices.getRateChargesList(billable_id, profile_id)
        .then(response => {
          const profile = response.data;
          const rates = profile.rate_charges;
          if (rates.length) {
            this.setState({
              rate_charges: rates
            });
          } else {
            this.setState({
              rate_charges: []
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            rate_charges: []
          });
        });
    } else {
      this.setState({
        rate_charges: []
      });
    }
  };

  deleteProfile = profile => {
    const profile_id = profile._id
    const duos = profile.duos;
    const tuos  = profile.tuos;
    //const space = &nbsp;
    console.log("profile", profile);
    this.dialog.show({
      body: "Would you like to delete " + duos + " " + tuos + " profile. Proceed ?",
      actions: [
        Dialog.CancelAction(),
        Dialog.Action(
          "Proceed",
          () => this.deleteProfileOK(profile_id),
          "btn-warning"
        )
      ],
      bsSize: "medium"
    });
  };

  deleteProfileOK = profile_id => {
    const billable_id = this.props.billable_id;

    MeterServices.deleteProfile(billable_id, profile_id)
      .then(response => {
        this.getRatesList();
        this.getProfileList(billable_id);
        this.setState({variant:"success", msg:"Profile is deleted successfully."});
        this.onShowAlert();
      })
      .catch(err => {
        console.error(err);
        this.setState({variant:"danger", msg:err});
        this.onShowAlert();
      });
  };

  editProfile = p => {
    const profile_id = p._id;
    const duos = p.duos;
    const tuos = p.tuos;
    const valid_from = p.valid_from;
    const billable_id = p.billable_id;
    this.setState({
      profile_id: profile_id,
      duos: { value: duos, label: duos },
      tuos: { value: tuos, label: tuos },
      valid_from: valid_from,
      billable_id: billable_id
    });
    this.setState({ showModalAddProfile: true });
  };

  closeAddProfile = (variant, msg) => {
    const billable_id = this.state.billable_id;
    //const billable_id = this.state.billable_id;
    this.setState({ showModalAddProfile: false,variant: variant, msg: msg });
    this.getProfileList(billable_id);
    this.onShowAlert();
  };

  setShow=()=>{
    this.setState({ show:false });
  }

  onShowAlert = () => {
  //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
    });
  };

  render() {
    const {
      profiles,
      profile_id,
      getInactive,
      valid_from,
      currency,
      tuos,
      duos,
      billable_id,
      rate_charges,
      variant, msg, show
    } = this.state;

    return (
      <div className="table-padding">
      {show === true ? (
        <Alert variant={variant} onClose={() => this.setShow()} dismissible>
          <Alert.Heading>{msg}</Alert.Heading>
        </Alert>
      ) : (
        <div></div>
      )}
        <Dialog
          ref={el => {
            this.dialog = el;
          }}
        />

        <div className=" headerTable">
          <h4>Profiles</h4>
          <button
            className="inactive-tariffs btn-round"
            onClick={this.getProfileHistory}
          >
            {getInactive} Inactive
          </button>

          { profiles.length ?
            (<div>
            <table className="table-market" id="tariffs_table">
              <thead>
                <tr>
                  <th> Valid from</th>
                  <th> DUoS </th>
                  <th> TUoS</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {profiles.map(p => (
                  <ProfileRow
                    profile={p}
                    key={p._id}
                    deleteProfile={this.deleteProfile}
                    editProfile={this.editProfile}
                  />
                ))}
              </tbody>
            </table>

            <RateChargesTable rate_charges={rate_charges} />
          </div>)
        :<div></div>}

        </div>
        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showModalAddProfile}
            onHide={this.closeAddProfile}
          >
            <Modal.Header closeButton="closeButton">
              <Modal.Title className="textCenter">Edit Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <AddProfileForm
                profile_id={profile_id}
                billable_id={billable_id}
                type={"edit"}
                closeForm={this.closeAddProfile}
                currency={currency}
                tuos={tuos}
                duos={duos}
                valid_from={valid_from}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
