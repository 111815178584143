import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {Notify} from '../../components/AlertListener'
import BootstrapTable from 'react-bootstrap-table-next';
import {Request} from "../../../services/HttpService";

const request = new Request({ service: 'Cluster service'})
const notify=Notify("cluster.notifications")

export default function ClusterMemberList({cluster=null}) {
    const [members, setMembers] = useState([])
    useEffect(() => {
        if(cluster===null) return 

        async function __loadMembers() {
            try {
                setMembers(await request.get(`/api/cluster/${cluster._id}/members`))
            }
            catch(err) {
                notify.error(err.message)
            }
        }

        __loadMembers()
        return () => {
            setMembers([])
        }
    }, [cluster])

    if(cluster===null) return null

    const columns = [{
		dataField: '_id',
		text: 'Member ID',
		hidden: true
	}, {
		dataField: 'name',
		text: 'Organisation',
		sort: true
	}]

    return (
        <Container style={{paddingTop : "1rem"}} fluid>
            <Row style={{paddingBottom: "1rem"}}><Col><h4>Member Organisations</h4></Col></Row>
            <Row>
                <Col lg="6">
                    <BootstrapTable
                        bootstrap4 striped hover condensed 
                        version="4"
                        keyField='_id' 
                        noDataIndication="No members found"
                        defaultSorted={[{dataField: 'name', order: 'asc'}]}
                        columns={columns}
                        data={members}
                    />
                </Col>
            </Row>
        </Container>
    )
}