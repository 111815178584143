/** Deprecated */
import React, { Component } from "react";
import MeterServices from "./../services/MeterServices";
import UserServices from "./../services/UserServices";
import ClusterServices from "./../services/ClusterServices";
import SpearmanRHO from "spearman-rho";

//import TopDropdown from "../../utility/TopDropdown";
import moment from "moment";

import ModulesGraph from "./modulesGraphing/MenuModulesGraphing.jsx";
import DashboardContent from "./DashboardContent";

import { Switch, Route } from "react-router-dom";

import Results from "./../utility/Result";

import {
  filterToday,
  total_today_load,
  yesterdayStart,
  tomorrowStart,
  yesterday_iso,
  day2tomorrow,
  timeLine,
  getDates,
  getDataDifferences,
  total,
  getComarisonValues,
  lastDate
} from "./../utility/HelperFunctions";

import ReportResult from "./../utility/ReportResult";

const currencyMap = new Map([
    ["EUR", { major: "€", minor: "c" }],
    ["GBP", { major: "£", minor: "p" }],
    ["USD", { major: "$", minor: "¢" }]
  ]),
  currency = currencyMap.get("EUR");

export default class Menu extends Component {
  constructor(props) {
    super(props);

    const { user } = props;

    const viewList = ["Organisation", "Site"];
    if (user.roles.includes("cluster_owner")) {
      viewList.push("Cluster");
    }
    //console.log('timeLine',timeLine)
    this.child = React.createRef();
    this.state = {
      //sites
      view: "Organisation",
      viewList,
      sites: [],
      meters: [],
      index_site: 0,
      index_meter: "",
      sitesupply: [],
      siteload: [],
      predict_supply: [],
      ///organisation
      org_load: [],
      org_supply: [],
      org_predict_supply: [],
      ///grahphg
      yName: "",
      currency,
      money: "c",
      tariffs: null,
      charges: null,
      actualLoad: null,
      actualCost: null,
      actualSupply: null,
      predictedSupply: null,
      predictedLoad: null,
      wholesale_price: null,
      price_kwhHome: null,
      extra_chargesHome: null,
      //homepage
      predictedCostHome: null,
      tariffsHome: null,
      chargesHome: null,
      wholesale_priceHome: null,
      actualCostHome: null,
      predictedSupplyHome: null,
      predictedLoadHome: null,
      actualLoadHome: null,
      actualSupplyHome: null,
      //clusters
      load_cluster: [],
      supply_cluster: [],
      predictedCost: null,
      cluster_predict_supply: [],
      org_predict_load: [],
      members: [],

      org_id: "",
      cluster_id: "",
      //widgets
      widgetLoad: null,
      totalLoad: null,
      widgetSolar: null,
      totalSolar: null,
      ///calendar times
      startDate: yesterdayStart,
      endDate: tomorrowStart,
      timeline: timeLine,
      dateFrom_iso: yesterday_iso,
      dateTo_iso: day2tomorrow,
      dateToMax:lastDate,
      //comparison
      sumMeterReadings: null,
      sumPredictions: null,
      diffTotal: null,
      incr: null,
      mean_sq_error: null,
      maseF: null,
      spearmanRHO: null,
      loadReadingArray: [],
      solarReadingArray: [],
      solarPredictionArray: [],
      loadPredictionArray: [],

      loadParsed: [],
      predictedloadParsed: [],
      actualSupplyParsed: [],
      predictedSupplyParsed: [],
      r2Load: null,
      r2Solar: null,
      differencesLoad: [],
      differencesSolar: [],
      totalDifLoad: null,
      totalReadingLoad: null,
      totalPredictionLoad: null,
      totalPercLoad: null,
      totalDifSolar: null,
      totalReadingSolar: null,
      totalPredictionSolar: null,
      totalPercSolar: null,
      mean_sq_errorLoad: null,
      maseFLoad: null,
      mean_sq_errorSolar: null,
      maseFSolar: null,

      variant: "",
      msg: "",
      show: false,
      prLoadError:null,
      prSolarError:null,
      loadError:null,
      solarError:null,
      marketError:null
    };
  }

  componentDidMount = (view = "Organisation") => {
    setTimeout(() => {
      const { user } = this.props;
      this.setState({ user: user });
    }, 1);
    this.getSemoPrice();
    // console.log("1View", view);
    switch (view) {
      case "Site":
        this.getSiteList();
        break;
      case "Organisation":
        this.getOrganisationData();
        break;
      case "Cluster":
        this.getClusterData();
        break;
      default:
        console.error(`**** Error view: ${view}`);
    }
  };

  componentWillUnmount = () => {
    //clearInterval(this.loadInterval);
  };

  getCurrentPriceCharges = (tariffs = this.state.tariffs,charges = this.state.charges,tariffsHome = this.state.tariffsHome,chargesHome = this.state.chargesHome) => {
    //console.log('this.state.tariffs',tariffs)
    let price_kwh = null,
      extra_charges = null,
      price_kwhHome = null,
      extra_chargesHome = null;
    const __hour = new moment().local().hour();
    //  console.log('Hour : ', __hour, tariffs)
    if (tariffs !== null) {
      const __tariffs = tariffs.data;
      if (typeof __tariffs[__hour] !== "undefined") {
        price_kwh = Math.round(__tariffs[__hour]);
      }
    }
    //  console.log('charges', charges)
    if (charges !== null) {
      const __charges = charges.data;
      if (typeof __charges[__hour] !== "undefined") {
        extra_charges = Math.round(__charges[__hour]);
      }
    }

    if (tariffsHome !== null) {
      const __tariffsHome = tariffsHome.data;
      if (typeof __tariffsHome[__hour] !== "undefined") {
        price_kwhHome = Math.round(__tariffsHome[__hour]);
      }
    }
    //  console.log('charges', charges)
    if (chargesHome !== null) {
      const __chargesHome = chargesHome.data;
      if (typeof __chargesHome[__hour] !== "undefined") {
        extra_chargesHome = Math.round(__chargesHome[__hour]);
      }
    }
    return { price_kwh, extra_charges, price_kwhHome, extra_chargesHome };
  };

  onViewSelected = e => {
    e.preventDefault();
    let { value: view } = e.target;
    const cTariffsAndCharges = this.getCurrentPriceCharges();

    // console.log(`Select view: ${view} : `);

    if (view === "") {
      // console.warn('Setting view to default "Organisation"');
      view = "Organisation";
    }
    this.setState({
      view,
      index_site: 0,
      tariffs: null,
      charges: null,
      tariffsHome: null,
      chargesHome: null,
      wholesale_price: null,
      wholesale_priceHome: null,
      actualLoad: null,
      actualLoadHome: null,
      actualCost: null,
      actualCostHome: null,
      actualSupply: null,
      actualSupplyHome: null,
      widgetLoad: null,
      totalLoad: null,
      widgetSolar: null,
      totalSolar: null,
      loadReadingArray: [],
      solarReadingArray: [],
      solarPredictionArray: [],
      loadPredictionArray: [],
      diffTotalLoad: null,
      diffTotalSolar: null,
      incr: null,
      differencesLoad: null,
      differencesSolar: null,
      r2Solar: null,
      r2Load: null,
      mean_sq_errorSolar: null,
      maseFSolar: null,
      mean_sq_errorLoad: null,
      maseFLoad: null,
      ...cTariffsAndCharges
    });
    this.componentDidMount(view);
  };

  ////semo prices

  getSemoPrice = async () => {
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    this.setState({ wholesale_price: null, wholesale_priceHome: null });

    try {
      const response = await MeterServices.getSemoPrice(timestart, timeend);
      const __prices = response.data;
      const __currency = currencyMap.get(
        __prices.length > 0 ? __prices[0].currency : "EUR"
      );
      const wholesale_price = new ReportResult(
        "wholesale",
        `Wholesale (${__currency.minor})`,
        "price",
        __prices.map(({ timestamp, price }) => {
          // Could just divide by 10, but this is clearer
          return { timestamp, price: (price * 100) / 1000 };
        }),
        timestart,
        timeend
      );

      const wholesale_priceHome = new Results(
        "wholesale",
        `Wholesale (${__currency.minor})`,
        "price",
        __prices.map(({ timestamp, price }) => {
          // Could just divide by 10, but this is clearer
          return { timestamp, price: (price * 100) / 1000 };
        })
      );
      // console.log("Wholesale price : ", wholesale_priceHome);
      this.setState({ wholesale_price, wholesale_priceHome,marketError:null });
    } catch (err) {
      console.error('ERROR semo price...: ', err);
      if (err === "Server error, please try again later Error : Bad Gateway"){
        // console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          marketError: " Billable Service, "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({ wholesale_price: null, wholesale_priceHome: null });

    }
  };

  // get Cluster data

  getClusterData = () => {
    // console.log('selected cluster view:')
    const user = this.props.user;
    const org_id = user.organisation.id;
    const cluster_id = user.organisation.clusterId;
    //  console.log("user", cluster_id,user,org_id);
    this.getAgregatedLoadCluster(cluster_id);
    this.getAgregatedSupplyCluster(cluster_id);
    this.getPredictLoadCluster(cluster_id);
    this.getPredictSupplyCluster(cluster_id);
    this.getTariffsAndChargesforCluserAdmin(cluster_id, org_id);
    this.getClusterMembers(cluster_id);
  };

  getAgregatedLoadCluster = async cluster_id => {
    this.setState({
      actualLoad: null,
      actualCost: null,
      actualLoadHome: null,
      actualCostHome: null
    });
    try {
      const response = await ClusterServices.getAgreg0atedLoadCluster(
        cluster_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const clusterLoad = response.data;
      //console.log("1.agregated_clusterLoad", clusterLoad);
      this.parseLoad(clusterLoad);
      this.parseLoadHome(clusterLoad);
    } catch (err) {
      console.error('ERROR getAgregatedLoadCluster: ', err);
      this.setState({
        actualLoad: null,
        actualCost: null,
        actualLoadHome: null,
        actualCostHome: null
      });
    }
  };

  getAgregatedSupplyCluster = async cluster_id => {
    this.setState({ actualSupply: null, actualSupplyHome: null });
    try {
      const response = await ClusterServices.getAgregatedSupplyCluster(
        cluster_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const clusterSupply = response.data;
      //console.log("WWWWWWWWWWWWWWWWWWWWWagregated_supply_cluster", clusterSupply);
      this.parseSupply(clusterSupply);
      this.parseSupplyHome(clusterSupply);
    } catch (err) {
      console.error('getAgregatedSupplyCluster', err);
      this.setState({ actualSupply: null, actualSupplyHome: null });
    }
  };

  getPredictSupplyCluster = async cluster_id => {
    //  console.log("XXXXX");
    this.setState({ predictedSupply: null, predictedSupplyHome: null });
    try {
      const response = await ClusterServices.getPredictSupplyCluster(
        cluster_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const clusterPredictedSupply = response.data;
      //console.log("cluster_predict_supply", clusterPredictedSupply);
      this.parsePredictedSupply(clusterPredictedSupply);
      this.parsePredictedSupplyHome(clusterPredictedSupply);
      this.getSolarDiff();
      this.setState({prSolarError: null});
    } catch (err) {
      console.error('ERROR getPredictSupplyCluster: ', err);
      if (err === "Server error, please try again later. Service not available"){
        //console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({prSolarError: " Solar Prediction Service cluster"});
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({ predictedSupply: null, predictedSupplyHome: null });
    }
  };

  getPredictLoadCluster = async cluster_id => {
    this.setState({
      predictedLoad: null,
      predictedCost: null,
      predictedLoadHome: null,
      predictedCostHome: null
    });
    try {
      const response = await ClusterServices.getPredictLoadCluster(
        cluster_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const predictions = response.data;
      //console.log("cluster_predict_load", predictions);
      this.parsePredictedLoad(predictions);
      this.parsePredictedLoadHome(predictions);
      this.getLoadDiff();
      this.setState({prLoadError:null});
    } catch (err) {
      console.error('ERROR getPredictLoadCluster: ', err);
      if (err === "Server error, please try again later. Service not available"){
        // console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          prLoadError: " Load Prediction Service "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({
        predictedLoad: null,
        predictedCost: null,
        predictedLoadHome: null,
        predictedCostHome: null
      });
    }
  };

  getClusterMembers = cluster_id => {
    ClusterServices.getClusterMembers(cluster_id)
      .then(response => {
        let members = response.data;
        members.push({
          active: true,
          altKey: "",
          cluster_id: "",
          name: "All Aggregated",
          _id: "999"
        });
        const allMembers = members.reverse();
        //console.log("members", allMembers);
        this.setState({
          members: allMembers
        });
      })
      .catch(err => {
        console.error('ERROR getClusterMembers: ', err);
        this.setState({ members: [] });
      });
  };

  onOrgSelected = e => {
    //const org_id = this.state.org_id;
    //console.log('orgId', org_id)
    this.setState({
      r2Solar: null,
      r2Load: null,
      differencesLoad: null,
      differencesSolar: null,
      mean_sq_errorSolar: null,
      maseFSolar: null,
      mean_sq_errorLoad: null,
      maseFLoad: null
    });

    e.preventDefault();
    const value = e.target.value;
    //console.log("value", value);
    if (value !== "" && value !== "999") {
      const org_id = e.target.value;
      //  console.log("org_id", org_id);
      this.getOrganisationDataforCluserAdmin(org_id);
      this.setState({ org_id: org_id });
    } else if (value !== "" && value === "999") {
      this.getClusterData();
    } else {
      this.componentDidMount();
    }
  };

  ///////////////////////////////////

  //get organisations data for cluster cadmin

  getOrganisationDataforCluserAdmin = organisation_id => {
    const user = this.props.user;
    const cluster_id = user.organisation.clusterId;
    var org_id = "";
    if (organisation_id) {
      org_id = organisation_id;
    } else {
      org_id = user.organisation.id;
    }

    //console.log("org_id", org_id);
    this.getOrgAgregateLoadforCluserAdmin(cluster_id, org_id);
    this.getOrgAggregateSupplyforCluserAdmin(cluster_id, org_id);
    this.getOrganisationPredictLoadforCluserAdmin(cluster_id, org_id);
    this.getOrganisationPredictSupplyforCluserAdmin(cluster_id, org_id);
    this.getTariffsAndChargesforCluserAdmin(cluster_id, org_id);
  };

  getTariffsAndChargesforCluserAdmin = async (cluster_id, org_id) => {
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    this.setState({
      tariffs: null,
      charges: null,
      tariffsHome: null,
      chargesHome: null
    });
    try {
      const { data } = await UserServices.getTariffsAndChargesforCluserAdmin(
        cluster_id,
        org_id,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      //  console.log("TARIFF data", data);
      const { minor: unit } = this.state.currency;
      const tariffs = new ReportResult(
        "tariffs",
        `Elec. price (${unit}) `,
        "tariff",
        data.map(i => {
          return { timestamp: i.timestamp, tariff: (i.rate * 100).toFixed(3) };
        }),
        timestart,
        timeend
      );

      const charges = new ReportResult(
        "charges",
        `Elec. charges (${unit})`,
        "charges",
        data.map(i => {
          return {
            timestamp: i.timestamp,
            charges: (i.charges * 100).toFixed(3)
          };
        }),
        timestart,
        timeend
      );

      const tariffsHome = new Results(
        "tariffs",
        `Elec. price (${unit}) `,
        "tariff",
        data.map(i => {
          return { timestamp: i.timestamp, tariff: (i.rate * 100).toFixed(3) };
        })
      );

      const chargesHome = new Results(
        "charges",
        `Elec. charges (${unit})`,
        "charges",
        data.map(i => {
          return {
            timestamp: i.timestamp,
            charges: (i.charges * 100).toFixed(3)
          };
        })
      );
      //console.log('111charges data', charges, tariffs)

      const __tandc = this.getCurrentPriceCharges(
        tariffs,
        charges,
        tariffsHome,
        chargesHome
      );
      this.setState({
        tariffs,
        charges,
        tariffsHome,
        chargesHome,
        ...__tandc
      });
      this.setState({marketError: null});
    } catch (err) {
      console.error("error getTariffsAndChargesforCluserAdmin:", err);
      if (err === "502"){
        console.log("getTariffsAndChargesforCluserAdmin", err);
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({marketError: " Billable Service, "});
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({
        tariffs: null,
        charges: null,
        tariffsHome: null,
        chargesHome: null
      });
    }
  };

  getOrgAgregateLoadforCluserAdmin = async (cluster_id, org_id) => {
    //  console.log("xxx");
    this.setState({
      actualLoad: null,
      actualCost: null,
      actualLoadHome: null,
      actualCostHome: null
    });
    try {
      const response = await UserServices.getAgregatedLoadOrgforCluserAdmin(
        cluster_id,
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const org_load = response.data;
      //  console.log("1.agregated_org_load", org_load);
      this.parseLoad(org_load);
      this.parseLoadHome(org_load);
    } catch (err) {
      console.error("error getOrgAgregateLoadforCluserAdmin:", err);
      this.setState({
        actualLoad: null,
        actualCost: null,
        actualLoadHome: null,
        actualCostHome: null
      });
    }
  };

  getOrgAggregateSupplyforCluserAdmin = async (cluster_id, org_id) => {
    this.setState({ actualSupply: null, actualSupplyHome: null });
    try {
      const response = await UserServices.getAgregatedSupplyOrgforCluserAdmin(
        cluster_id,
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const orgSupply = response.data;

      //console.log("2.agregated_supply_organisation", orgSupply);
      this.parseSupply(orgSupply);
      this.parseSupplyHome(orgSupply);
      this.getSolarDiff();
    } catch (err) {
      console.error("error getOrgAggregateSupplyforCluserAdmin:", err);
      this.setState({ actualSupply: null, actualSupplyHome: null });
    }
  };

  getOrganisationPredictLoadforCluserAdmin = async (cluster_id, org_id) => {
  //  console.log("3.org_load_predict");
    this.setState({
      predictedLoad: null,
      predictedCost: null,
      predictedLoadHome: null,
      predictedCostHome: null
    });
    try {
      const response = await UserServices.getPredictLoadOrgforCluserAdmin(
        cluster_id,
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const predictions = response.data;
      //console.log("3.org_load_predict", predictions);
      this.parsePredictedLoad(predictions);
      this.parsePredictedLoadHome(predictions);
      this.getLoadDiff();
    } catch (err) {
      console.error("ERROR getOrganisationPredictLoadforCluserAdmin:", err);
      this.setState({
        predictedLoad: null,
        predictedCost: null,
        predictedLoadHome: null,
        predictedCostHome: null
      });
    }
  };

  getOrganisationPredictSupplyforCluserAdmin = async (cluster_id, org_id) => {
    //  console.log('hi')
    this.setState({ predictedSupply: null, predictedSupplyHome: null });
    try {
      const response = await UserServices.getPredictSupplyOrgforCluserAdmin(
        cluster_id,
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const orgPredictedSupply = response.data;

      //console.log("4..org_supply_predict", orgPredictedSupply);
      this.parsePredictedSupply(orgPredictedSupply);
      this.parsePredictedSupplyHome(orgPredictedSupply);
      this.getSolarDiff();
    } catch (err) {
      console.error("ERRROR getOrganisationPredictSupplyforCluserAdmin:", err);
      this.setState({ predictedSupply: null, predictedSupplyHome: null });
    }
  };

  /////////////////////////////////////////////////////////////

  // get organisation data

  getOrganisationData = organisation_id => {
    //  console.log('qqqqqq')
    const user = this.props.user;
    const cluster_id = user.organisation.clusterId;
    //console.log("getOrganisationData");
    //const cluster_id = user.organisation.clusterId;
    var org_id = "";
    if (organisation_id) {
      org_id = organisation_id;
    } else {
      org_id = user.organisation.id;
    }

    //console.log("org_id", org_id);
    this.getOrgAgregateLoad(org_id);
    this.getOrgAggregateSupply(org_id);
    this.getOrganisationPredictLoad(org_id);
    this.getOrganisationPredictSupply(org_id);
    if (user.roles.includes("cluster_owner")) {
      this.getTariffsAndChargesforCluserAdmin(cluster_id, org_id);
    } else {
      this.getTariffsAndCharges(org_id);
    }
  };

  getTariffsAndCharges = async org_id => {
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    this.setState({
      tariffs: null,
      charges: null,
      tariffsHome: null,
      chargesHome: null
    });
    try {
      const { data } = await UserServices.getTariffsAndCharges(
        org_id,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      //  console.log("0.tariffs_charges", data);

      const { minor: unit } = this.state.currency;
      const tariffs = new ReportResult(
        "tariffs",
        `Elec. price (${unit}) `,
        "tariff",
        data.map(i => {
          return { timestamp: i.timestamp, tariff: (i.rate * 100).toFixed(3) };
        }),
        timestart,
        timeend
      );

      const charges = new ReportResult(
        "charges",
        `Elec. charges (${unit})`,
        "charges",
        data.map(i => {
          return {
            timestamp: i.timestamp,
            charges: (i.charges * 100).toFixed(3)
          };
        }),
        timestart,
        timeend
      );

      const tariffsHome = new Results(
        "tariffs",
        `Elec. price (${unit}) `,
        "tariff",
        data.map(i => {
          return { timestamp: i.timestamp, tariff: (i.rate * 100).toFixed(3) };
        })
      );

      const chargesHome = new Results(
        "charges",
        `Elec. charges (${unit})`,
        "charges",
        data.map(i => {
          return {
            timestamp: i.timestamp,
            charges: (i.charges * 100).toFixed(3)
          };
        })
      );

      const __tandc = this.getCurrentPriceCharges(
        tariffs,
        charges,
        tariffsHome,
        chargesHome
      );
      this.setState({
        tariffs,
        charges,
        tariffsHome,
        chargesHome,
        ...__tandc
      });
      this.setState({marketError: null});
    } catch (err) {
      console.error('getTariffsAndCharges' ,err);
      if (err === "502"){
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          marketError: " Billable Service, "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({
        tariffs: null,
        charges: null,
        tariffsHome: null,
        chargesHome: null
      });
    }
  };

  getOrgAgregateLoad = async org_id => {
    //  console.log('xxx',  this.state.dateFrom_iso, this.state.dateTo_iso)
    this.setState({
      actualLoad: null,
      actualCost: null,
      actualLoadHome: null,
      actualCostHome: null
    });
    try {
      const response = await UserServices.getAgregatedLoadOrg(
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const org_load = response.data;
      //console.log("xxxx.agregated_org_load", org_load);
      this.parseLoad(org_load);
      this.parseLoadHome(org_load);
    } catch (err) {
      console.error( 'ERROR getOrgAgregateLoad', err);
      if (err === "502"){
        // console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          marketError: " Billable Service, "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({
        actualLoad: null,
        actualCost: null,
        actualLoadHome: null,
        actualCostHome: null
      });
    }
  };

  getOrgAggregateSupply = async org_id => {
    this.setState({ actualSupply: null, actualSupplyHome: null });
    try {
      const response = await UserServices.getAgregatedSupplyOrg(
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const orgSupply = response.data;
      this.parseSupply(orgSupply);
      this.parseSupplyHome(orgSupply);
      this.setState({marketError: null});
    } catch (err) {
      console.error( 'ERROR getOrgAggregateSupply',err);
      if (err === "502"){
        // console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          marketError: " Billable Service, "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({ actualSupply: null, actualSupplyHome: null });
    }
  };

  getOrganisationPredictLoad = async org_id => {
  //console.log("org_load_predict", this.state.view);
    this.setState({
      predictedLoad: null,
      predictedCost: null,
      predictedLoadHome: null,
      predictedCostHome: null
    });
    try {
      const response = await UserServices.getPredictLoadOrg(
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const predictions = response.data;
      //this.setState({ show: false });
      this.parsePredictedLoad(predictions);
      this.parsePredictedLoadHome(predictions);
      this.getLoadDiff();
      this.setState({prLoadError:null});
    } catch (err) {
      //console.error('ERROR ');
      console.error('ERROR getOrganisationPredictLoad', err);
      if (err === "502"){
        // console.log("response", );
        //this.showError("danger", "Load Prediction Service currently unavailable, try again later");
        this.setState({
          prLoadError: " Load Prediction Service, "
        });
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({
        predictedLoad: null,
        predictedCost: null,
        predictedLoadHome: null,
        predictedCostHome: null,
      });
    }
  };

  getOrganisationPredictSupply = async org_id => {
    this.setState({ predictedSupply: null, predictedSupplyHome: null });
    try {
      const response = await UserServices.getPredictSupplyOrg(
        org_id,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const orgPredictedSupply = response.data;
      //console.log("4..org_supply_predict", orgPredictedSupply);
      this.parsePredictedSupply(orgPredictedSupply);
      this.parsePredictedSupplyHome(orgPredictedSupply);
      this.getSolarDiff();
      this.setState({
        prSolarError: null,
      });
    } catch (err) {
      console.error('ERROR getOrganisationPredictSupply', err);
      if (err === "502"){
        this.setState({
          prSolarError: " Solar Prediction Service, ",
        });
        //this.showError("danger", "Solar Prediction Service currently unavailable, try again later");
        setTimeout(() => this.componentDidMount(this.state.view), 300000);
      }
      this.setState({ predictedSupply: null, predictedSupplyHome: null });
    }
  };

  //////////////////////////////

  //site
  getSiteList = () => {
    const user = this.props.user;
    const org_id = user.organisation.id;
    const cluster_id = user.organisation.clusterId;
    //  console.log("3getsitelist");
    this.setState({ org_id: org_id });
    UserServices.getSiteList(org_id)
      .then(response => {
        let sites = response.data.reverse();
        var site_id;
        if (sites.length) {
          site_id = sites[0]._id;
          this.setState({
            sites: sites,
            site_id: site_id,
            noSites: false,
            view: "Site",
            cluster_id: cluster_id
          });
          this.getSiteData(site_id, org_id, cluster_id, user);
          // if (this.child.current){
          //     this.child.current.getInstalltionsList(site_id);
          // }
        } else {
          this.setState({ sites: [], noSites: true });
        }
      })
      .catch(err => {
        console.error('ERROR getSiteList', err);
        this.setState({ clusters: [] });
      });
  };

  getSiteData = (site_id, org_id, cluster_id) => {
    const user = this.props.user;
    this.getAgregatedSupplySite(org_id, site_id);
    this.getAgregatedLoadSite(org_id, site_id);
    this.getPredictSupplySite(org_id, site_id);
    this.setState({
      predictedLoad: null,
      predictedCost: null,
      predictedLoadHome: null,
      predictedCostHome: null,
      r2Load: null
    });
    if (user.roles.includes("cluster_owner")) {
      this.getTariffsAndChargesforCluserAdmin(cluster_id, org_id);
    } else {
      this.getTariffsAndCharges(org_id);
    }
  };

  onSiteSelected = e => {
    this.setState({
      r2Solar: null,
      r2Load: null,
      differencesLoad: null,
      differencesSolar: null,
      mean_sq_errorSolar: null,
      maseFSolar: null,
      mean_sq_errorLoad: null,
      maseFLoad: null
    });
    const org_id = this.state.org_id;
    const user = this.props.user;
    const cluster_id = user.organisation.clusterId;
    e.preventDefault();
    if (e.target.value !== "") {
      const index_site = e.target.value;
      const site = this.state.sites[e.target.value];
      const site_id = site._id;
      this.getSiteData(site_id, org_id, cluster_id, user);
      this.setState({ site_id: site_id, index_site: index_site });
    }
  };

  //////////get site data

  getPredictSupplySite = async (orgId, siteId) => {
    this.setState({ predictedSupply: null, predictedSupplyHome: null });
    try {
      const response = await UserServices.getPredictSupplySite(
        orgId,
        siteId,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const sitePredictedSupply = response.data;

      this.parsePredictedSupply(sitePredictedSupply);
      this.parsePredictedSupplyHome(sitePredictedSupply);
      this.getSolarDiff();
    } catch (err) {
      console.error(' ERROR getPredictSupplySite', err);
      this.setState({ predictedSupply: null, predictedSupplyHome: null });
    }
  };

  getAgregatedSupplySite = async (orgId, siteId) => {
    this.setState({ actualSupply: null, actualSupplyHome: null });
    try {
      const response = await UserServices.getAgregatedSupplySite(
        orgId,
        siteId,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const siteSupply = response.data;
      this.parseSupply(siteSupply);
      this.parseSupplyHome(siteSupply);
      this.setState({ site_id: siteId });
    } catch (err) {
      console.error(' ERROR getAgregatedSupplySite', err);
      this.setState({ actualSupply: null, actualSupplyHome: null });
    }
  };

  getAgregatedLoadSite = async (orgId, siteId) => {
    this.setState({
      actualLoad: null,
      actualCost: null,
      actualLoadHome: null,
      actualCostHome: null
    });
    try {
      const response = await UserServices.getAgregatedLoadSite(
        orgId,
        siteId,
        true,
        this.state.dateFrom_iso,
        this.state.dateTo_iso
      );
      const siteLoad = response.data;
      //console.log("7.Agregated Load Site", siteLoad);
      this.parseLoad(siteLoad);
      this.parseLoadHome(siteLoad);
    } catch (err) {
      console.error(' ERROR getAgregatedLoadSite', err);
      this.setState({
        actualLoad: null,
        actualCost: null,
        actualLoadHome: null,
        actualCostHome: null
      });
    }
  };

  // Parse the load into load and price values
  parseLoad = __load => {
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    //  console.log("__load", __load);
    const { currency } = this.state;
    if (__load.length) {
      const actualLoad = new ReportResult(
        "actual_load",
        "Actual load (kWh)",
        "reading",
        __load,
        timestart,
        timeend
      );
      const actualCost = new ReportResult(
        "actual_cost",
        `Elec. cost (${currency.major}) `,
        "price",
        __load,
        timestart,
        timeend
      );
      //console.log('actualLoad',actualLoad.data
      this.setState({
        actualLoad,
        actualCost,
        loadReadingArray: __load,
        loadParsed: actualLoad.data
      });
    }
  };

  parseSupply = __supply => {
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    var widgetSolar, totalSolar;
    if (__supply.length) {
      const actualSupply = new ReportResult(
        "actual_supply",
        "Solar (kWh)",
        "reading",
        __supply,
        timestart,
        timeend
      );
      this.setState({
        actualSupply,
        widgetSolar,
        totalSolar,
        solarReadingArray: __supply,
        actualSupplyParsed: actualSupply.data
      });
    }
  };

  parsePredictedSupply = __predictions => {
    //  console.log("__predictions Suply", __predictions);
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    const predictedSupply = new ReportResult(
      "predicted_supply",
      " Pred. solar (kWh)   ",
      "prediction",
      __predictions,
      timestart,
      timeend
    );
    this.setState({
      predictedSupply,
      solarPredictionArray: __predictions,
      predictedSupplyParsed: predictedSupply.data
    });
  };

  // Parse the load into load and price values
  parsePredictedLoad = __load => {
    //  console.log("_prediction _load", __load);
    const timestart = this.state.dateFrom_iso;
    const timeend = this.state.dateTo_iso;
    //console.log('__predicted load', __load)
    const { currency } = this.state;

    const predictedLoad = new ReportResult(
      "predicted_load",
      " Pred. load (kWh)   ",
      "prediction",
      __load,
      timestart,
      timeend
    );
    const predictedCost = new ReportResult(
      "predicted_cost",
      `Pred. Elec. cost (${currency.major}) `,
      "price",
      __load,
      timestart,
      timeend
    );

    this.setState({
      predictedLoad,
      predictedCost,
      loadPredictionArray: __load,
      predictedloadParsed: predictedLoad.data
    });
  };

  getLoadDiff = () => {
    this.setState({ differencesLoad: [] });

    const loadParsed = this.state.loadParsed;
    const predictedloadParsed = this.state.predictedloadParsed;
    const timeline = this.state.timeline;
    var differences = null;
    var totalDif = null;
    var totalReading = null;
    var totalPrediction = null;
    var totalSelectReading = null;
    var totalSelectPrediction = null;
    var totalPerc = null;

    if (loadParsed.length && predictedloadParsed.length) {
      differences = getDataDifferences(
        loadParsed,
        predictedloadParsed,
        timeline
      );

      const diff = differences.map(a => a.difference);
      const arrayDiff = diff.filter(a => !isNaN(a));
      const reading = differences.map(a => a.readingTotal);
      var arrayRead = reading.filter(a => !isNaN(a));
      const prediction = differences.map(a => a.predictionTotal);
      var arrayPredict = prediction.filter(a => !isNaN(a));

      const readingAll = differences.map(a => a.reading);
      var arrayReadAll = readingAll.filter(a => !isNaN(a));
      const predictionAll = differences.map(a => a.prediction);
      var arrayPredictAll = predictionAll.filter(a => !isNaN(a));

      totalReading = total(arrayReadAll).toFixed(2);
      totalPrediction = total(arrayPredictAll).toFixed(2);

      totalSelectReading = total(arrayRead).toFixed(2);
      totalSelectPrediction = total(arrayPredict).toFixed(2);


      totalDif = total(arrayDiff).toFixed(2);
      totalPerc = (((totalSelectReading - totalSelectPrediction) / totalSelectReading)*100).toFixed(2);

      var { mean_sq_error, maseF } = getComarisonValues(
        arrayRead,
        arrayPredict
      );

      this.setState({
        differencesLoad: differences,
        totalDifLoad: totalDif,
        totalReadingLoad: totalReading,
        totalPredictionLoad: totalPrediction,
        totalPercLoad: totalPerc,
        mean_sq_errorLoad: mean_sq_error,
        maseFLoad: maseF
      });
      if (arrayRead.length === arrayPredict.length) {
        this.getspearmanRHOLoad(arrayRead, arrayPredict);
      }
    }
  };

getSolarDiff = () => {
  const loadParsed = this.state.actualSupplyParsed;
  const predictedloadParsed = this.state.predictedSupplyParsed;
  const timeline = this.state.timeline;
  var differences = null;
  var totalDif = null;
  var totalReading = null;
  var totalPrediction = null;
  var totalPerc = null;
  var totalSelectReading = null;
  var totalSelectPrediction = null;

  if (loadParsed.length && predictedloadParsed.length) {
    differences = getDataDifferences(loadParsed, predictedloadParsed, timeline);
    if (differences && differences.length) {
      const diff = differences.map(a => a.difference);
      const arrayDiff = diff.filter(a => !isNaN(a));

      const reading = differences.map(a => a.readingTotal);
      var arrayRead = reading.filter(a => !isNaN(a));
      const prediction = differences.map(a => a.predictionTotal);
      var arrayPredict = prediction.filter(a => !isNaN(a));

      const readingAll = differences.map(a => a.reading);
      var arrayReadAll = readingAll.filter(a => !isNaN(a));

      const predictionAll = differences.map(a => a.prediction);
      var arrayPredictAll = predictionAll.filter(a => !isNaN(a));

      if (arrayRead.length) {
        totalSelectReading = total(arrayRead).toFixed(2);
      }
      if (arrayPredict.length) {
        totalSelectPrediction = total(arrayPredict).toFixed(2);
      }

      if (totalSelectPrediction && totalSelectReading) {
        totalPerc = (
          ((totalSelectReading - totalSelectPrediction) / totalSelectReading) *
          100
        ).toFixed(2);
      }

      if (arrayDiff.length) {
        totalDif = total(arrayDiff).toFixed(2);
      }
      if (arrayReadAll.length) {
        totalReading = total(arrayReadAll).toFixed(2);
      }
      if (arrayPredictAll) {
        totalPrediction = total(arrayPredictAll).toFixed(2);
      }

      var { mean_sq_error, maseF } = getComarisonValues(
        arrayRead,
        arrayPredict
      );

      this.setState({
        differencesSolar: differences,
        totalDifSolar: totalDif,
        totalReadingSolar: totalReading,
        totalPredictionSolar: totalPrediction,
        totalPercSolar: totalPerc,
        mean_sq_errorSolar: mean_sq_error,
        maseFSolar: maseF
      });
      if (arrayRead.length === arrayPredict.length) {
        this.getspearmanRHOSolar(arrayRead, arrayPredict);
      }
    }
  } else {
    this.setState({ r2Solar: null });
  }
};

getspearmanRHOLoad = (arrayRead, arrayPredict) => {
  var spearmanRHO;
  console.log('R2 : ', arrayRead, arrayPredict)
  const x = arrayPredict;
  const y = arrayRead;
  console.log('RHO : ', x, y)
  spearmanRHO = new SpearmanRHO(x, y);
  spearmanRHO
    .calc()
    .then(value => {
      this.getValueLoad(value);
    })
    .catch(err => console.error(err));
};

getValueLoad = value => {
  const r2 = (value * 100).toFixed(3);
  this.setState({ r2Load: r2 });
};

getspearmanRHOSolar = (arrayRead, arrayPredict) => {
  var spearmanRHO;
  const x = arrayPredict;
  const y = arrayRead;
  console.log('RHO : ', x, y)
  spearmanRHO = new SpearmanRHO(x, y);
  spearmanRHO
    .calc()
    .then(value => {
      this.getValueSolar(value);
    })
    .catch(err => console.error(err));
};

getValueSolar = value => {
  const r2 = (value * 100).toFixed(3);
  this.setState({ r2Solar: r2 });
};

//home page data

  parseLoadHome = __load => {
    const { currency } = this.state;
    var widgetLoad, totalLoad;
    if (__load.length) {
      //get current load
      const current_load = __load[__load.length - 1];
      widgetLoad = current_load.reading;
      //get total for today load
      const today_load = filterToday(__load);
      const today_l = today_load.map(a => a.reading);
      totalLoad = total_today_load(today_l);
    }
    const actualLoadHome = new Results(
      "actual_load",
      "Actual load (kWh)",
      "reading",
      __load
    );
    const actualCostHome = new Results(
      "actual_cost",
      `Elec. cost (${currency.major}) `,
      "price",
      __load
    );
    this.setState({ actualLoadHome, actualCostHome, widgetLoad, totalLoad });
  };

  parseSupplyHome = __supply => {
    //get current solar reading
    var widgetSolar, totalSolar;
    if (__supply.length) {
      const current_supply = __supply[__supply.length - 1];
      //get total for today solar
      widgetSolar = current_supply.reading;
      const today_solar = filterToday(__supply);
      const today_s = today_solar.map(a => a.reading);
      totalSolar = total_today_load(today_s);
    }
    const actualSupplyHome = new Results(
      "actual_supply",
      "Solar (kWh)",
      "reading",
      __supply
    );
    this.setState({ actualSupplyHome, widgetSolar, totalSolar });
  };

  parsePredictedSupplyHome = __predictions => {
    const predictedSupplyHome = new Results(
      "predicted_supply",
      " Pred. solar (kWh)   ",
      "prediction",
      __predictions
    );
    this.setState({ predictedSupplyHome });
  };

  // Parse the load into load and price values
  parsePredictedLoadHome = __load => {
    const { currency } = this.state;

    const predictedLoadHome = new Results(
      "predicted_load",
      " Pred. load (kWh)   ",
      "prediction",
      __load
    );
    const predictedCostHome = new Results(
      "predicted_cost",
      `Pred. Elec. cost (${currency.major}) `,
      "price",
      __load
    );
    this.setState({ predictedLoadHome, predictedCostHome });
  };

  handleStartDateChange = date => {
    const dateFrom_iso = moment(date).startOf("day").toISOString();
    const maxDate = new Date(date);
    maxDate.setDate(maxDate.getDate() + 31);

    var dateToMax;
    if (moment(maxDate).startOf("day").toISOString() > moment(lastDate).startOf("day").toISOString()){
      dateToMax = lastDate;
    } else {
      dateToMax = maxDate;
    }

  const dateTo_iso = moment(dateToMax).startOf("day").toISOString();
    this.setState({
      startDate: date,
      dateFrom_iso: dateFrom_iso,
      // dateToMax:dateToMax,
      endDate:dateToMax,
      dateTo_iso:dateTo_iso,

    });
  };

  handleEndDateChange = date => {
    const dateTo_iso = moment(date)
      .startOf("day")
      .toISOString();
    this.setState({
      endDate: date,
      dateTo_iso: dateTo_iso
    });
  };

  enterDates = () => {
    this.setState({
      r2Solar: null,
      r2Load: null,
      differencesLoad: null,
      differencesSolar: null,
      mean_sq_errorSolar: null,
      maseFSolar: null,
      mean_sq_errorLoad: null,
      maseFLoad: null
    });
    const org_id = this.state.org_id;
    const site_id = this.state.site_id;
    const cluster_id = this.state.cluster_id;
    const startDate = this.state.dateFrom_iso;
    const endDate = this.state.dateTo_iso;
    const view = this.state.view;
    if (startDate > endDate) {
      alert("Please check the sequence of dates selected");
    } else if (startDate===endDate) {
      alert("Please select Date to at least 1 day after Date from");
    } else {
      const timeline = getDates(startDate, endDate);
      this.setState({
        timeline: timeline
      });
      //this.componentDidMount(view);
      this.getSemoPrice();
      switch (view) {
        case "Site":
          this.getSiteData(site_id, org_id, cluster_id);
          break;
        case "Organisation":
          this.getOrganisationData();
          break;
        case "Cluster":
          this.getClusterData();
          break;
        default:
          console.error(`**** Error view: ${view}`);
      }
    }
  };

  render() {
    const {
      view,
      //  user,
      viewList,
      currency,
      sites,
      actualLoad,
      actualCost,
      predictedLoad,
      predictedCost,
      actualSupply,
      predictedSupply,
      tariffs,
      charges,
      startDate,
      endDate,
      timeline,
      /////////
      predictedCostHome,
      tariffsHome,
      chargesHome,
      actualCostHome,
      predictedSupplyHome,
      predictedLoadHome,
      actualLoadHome,
      actualSupplyHome,
      index_site,
      yName,
      wholesale_price,
      wholesale_priceHome,
      price_kwhHome = null,
      extra_chargesHome = 5,
      members,
      totalLoad,
      widgetLoad,
      totalSolar,
      widgetSolar,
      //startDate,
      //endDate,
      loadReadingArray,
      loadPredictionArray,
      solarReadingArray,
      solarPredictionArray,

      site_id,
      loadParsed,
      predictedloadParsed,
      actualSupplyParsed,
      predictedSupplyParsed,
      differencesLoad,
      differencesSolar,
      r2Load,
      r2Solar,
      totalDifLoad,
      totalReadingLoad,
      totalPredictionLoad,
      totalPercLoad,
      totalDifSolar,
      totalReadingSolar,
      totalPredictionSolar,
      totalPercSolar,

      mean_sq_errorSolar,
      maseFSolar,
      mean_sq_errorLoad,
      maseFLoad,
      prLoadError, prSolarError, marketError,
      dateToMax
    } = this.state;

    const user = this.props;

    const __wholesale = view === "Cluster" ? wholesale_price : null;
    const __wholesaleHome = view === "Cluster" ? wholesale_priceHome : null;

    return (
      <div>
        <div className="headerHome">
      { (!prLoadError && !prSolarError && !marketError)?
         <div> </div>
        : <div className='service-error'>
          <i style={{color: "#db2269"}} className="fas fa-exclamation-triangle"></i>
            Currently unavailable:
            {prLoadError} {prSolarError} {marketError} please try again later. </div>
      }

          <div>
            <div className="row small-gutters large-bottom-margin">
              <div className="col-lg-12">
                <div>
                  <Switch>
                    <Route
                      exact
                      path="/dashboard"
                      render={props => (
                        <DashboardContent
                          {...props}
                          user={user}
                          yName={yName}
                          currency={currency}
                          predictedCost={predictedCostHome}
                          tariffs={tariffsHome}
                          charges={chargesHome}
                          actualCost={actualCostHome}
                          __wholesale={__wholesaleHome}
                          predictedLoad={predictedLoadHome}
                          actualLoad={actualLoadHome}
                          predictedSupply={predictedSupplyHome}
                          actualSupply={actualSupplyHome}
                          price_kwh={price_kwhHome}
                          extra_charges={extra_chargesHome}
                          widgetLoad={widgetLoad}
                          totalLoad={totalLoad}
                          widgetSolar={widgetSolar}
                          totalSolar={totalSolar}
                          view={view}
                          viewList={viewList}
                          index_site={index_site}
                          sites={sites}
                          members={members}
                          onViewSelected={this.onViewSelected}
                          onOrgSelected={this.onOrgSelected}
                          onSiteSelected={this.onSiteSelected}
                          getSemoPrice={this.getSemoPrice}
                          getSiteList={this.getSiteList}
                          getOrganisationData={this.getOrganisationData}
                          getClusterData={this.getClusterData}
                          getOrganisationDataforCluserAdmin={
                            this.getOrganisationDataforCluserAdmin
                          }

                        />
                      )}
                    />
                    <Route
                      path="/dashboard/modulesgraph"
                      render={props => (
                        <ModulesGraph
                          {...props}
                          ref={this.child}
                          user={user}
                          currency={currency}
                          yName={yName}
                          predictedCost={predictedCost}
                          actualCost={actualCost}
                          tariffs={tariffs}
                          charges={charges}
                          __wholesale={__wholesale}
                          predictedLoad={predictedLoad}
                          actualLoad={actualLoad}
                          loadPredictionArray={loadPredictionArray}
                          loadReadingArray={loadReadingArray}
                          predictedSupply={predictedSupply}
                          actualSupply={actualSupply}
                          solarReadingArray={solarReadingArray}
                          solarPredictionArray={solarPredictionArray}
                          view={view}
                          viewList={viewList}
                          index_site={index_site}
                          sites={sites}
                          site_id={site_id}
                          members={members}
                          onViewSelected={this.onViewSelected}
                          onOrgSelected={this.onOrgSelected}
                          onSiteSelected={this.onSiteSelected}
                          getSemoPrice={this.getSemoPrice}
                          getSiteList={this.getSiteList}
                          getOrganisationData={this.getOrganisationData}
                          getClusterData={this.getClusterData}
                          handleStartDateChange={this.handleStartDateChange}
                          handleEndDateChange={this.handleEndDateChange}
                          enterDates={this.enterDates}
                          startDate={startDate}
                          endDate={endDate}
                          timeline={timeline}
                          loadParsed={loadParsed}
                          predictedloadParsed={predictedloadParsed}
                          actualSupplyParsed={actualSupplyParsed}
                          predictedSupplyParsed={predictedSupplyParsed}
                          differencesLoad={differencesLoad}
                          differencesSolar={differencesSolar}
                          r2Solar={r2Solar}
                          r2Load={r2Load}
                          totalDifLoad={totalDifLoad}
                          totalReadingLoad={totalReadingLoad}
                          totalPredictionLoad={totalPredictionLoad}
                          totalPercLoad={totalPercLoad}
                          totalDifSolar={totalDifSolar}
                          totalReadingSolar={totalReadingSolar}
                          totalPredictionSolar={totalPredictionSolar}
                          totalPercSolar={totalPercSolar}
                          mean_sq_errorSolar={mean_sq_errorSolar}
                          maseFSolar={maseFSolar}
                          mean_sq_errorLoad={mean_sq_errorLoad}
                          maseFLoad={maseFLoad}
                          maxDate={dateToMax}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
