import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Container, Form, Col, Button } from 'react-bootstrap'
import { ConfirmAlert } from '../components/UIComponents'
import { useHistory } from "react-router"
import UserContext from "../../services/user-context"
import { TimeUtils } from 'time-utils'

import Select from "react-select";
import {loadSitesForOrganisation} from "../../services/SiteService";
import {Request} from "../../services/HttpService";
import {noop} from '../components/UIComponents'
import {AlertContainer, Notify} from '../components/AlertListener'
import { isEqual } from 'lodash'
import FormControl from '../settings/battery/FormControl'

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.meter.notifications")

const NO_SITES = { value: "", label: "-- No sites --"},
    NO_METERS = { value: "", label: "-- No meters --"}

const FRM_NAME={
    type: "text",
    name: 'name', 
    label: 'Meter name',
    minLength: 2,
    required: true
}, FRM_VALID_RANGE={
    type: "date-range",
    name: ["valid_from", "valid_to"],
    label: ["Valid from", "Valid to"],
    endRequired: false,
    default: ["now"]
}

const DFLT_METER_NAME = 'Storage'

/*
    Choose a meter to associate with a battery
*/
export default function BatteryMeterDetails({ battery, onDone=noop}) {
    const [confirmRequired, setConfirm] = useState(false)
    const [canSave, enableSave] = useState(false)
    const [meterId, setMeterId] = useState("")
    const [allMeters, setAllMeters] = useState(null)
    const [meters, setMeters] = useState(null)
    const [meter, setMeter] = useState(null)
    const [siteId, setSiteId] = useState("")
    const [tz, setTZ] = useState(null)
    const [sites, setSites] = useState(null)
    const [site, setSite] = useState(null)
    const [createNew, setCreateNew] = useState(false)
    const [meterName, setMeterName] = useState(DFLT_METER_NAME)
    const [validRange, setValidRange] = useState([battery.valid_from, battery.valid_to])
    const [errMeterName, setErrMeterName] = useState(false)
    const [errValidRange, setErrValidRange] = useState(false)
    const cancelRef = useRef()

    const history = useHistory()
    const { user } = useContext(UserContext)

    const keyUpHandler = useCallback(event => {
        if(event.key==='Escape') {
            // Use ESC to click cancel - button ref is cleanest way to ensure 
            // context is in scope
            cancelRef.current.click()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler)

        return () => {
            window.removeEventListener('keyup', keyUpHandler)
        }
    }, [])

    // Load the battery
    useEffect(() => {
        setSiteId(battery.site_id || "")
        setMeterId(battery.meter_id || "")

        loadSitesForOrganisation(user.organisationId, sites => setSites(
            sites.length>0?
            [
                {value: "", label: '-- Select Site --'},
                ...sites
            ]:
            NO_SITES
        ))
        .catch(err => {
            console.error(err)
            notify.error(err.message)
        })
    }, [battery])

    useEffect(() => {
        if(!Array.isArray(sites) || sites.length<1) return
        const [__selected] = sites.filter(({value}) => siteId===value)
        setSite(__selected)

    }, [sites, siteId])

    useEffect(() => {
        if(!site || site.value==="") {
            setMeters([NO_METERS])
        }
        else {
            // Load the meters for a site
            async function __loadMeters() {
                try {
                    const __allMeters = await request.get(`/api/meter?site_id=${site._id}`)

                    setAllMeters(__allMeters)

                    let __meters= __allMeters.filter(({meter_type}) => meter_type.includes('storage'))
                    
                    setMeters(__meters.length>0?
                        [
                            { value: "", label: '-- Select meter --'},
                            ...__meters.map(m => ({...m, value: m._id, label: m.name}))
                        ]:
                        [ NO_METERS ])

                }
                catch(err) {
                    notify.error(`Error loading meters- ${err.message}`)
                    console.error(err)
                }
            }
    
            __loadMeters()
            setTZ(new TimeUtils(site.timezone))
        }

        return () => {
            setMeters([])
            setMeter(null)
            setTZ(null)
        }
    }, [site])

    useEffect(() => {
        if(!Array.isArray(meters) || meters.length<1) return
        const [__selected] = meters.filter(({value}) => meterId===value)
        setMeter(__selected)

        return () => {
            setMeter(NO_METERS)
        }
    }, [meters, meterId])

    const toggleCreateNew = useCallback(() => {
        setCreateNew(!createNew)
    }, [createNew])

    const checkMeterName = useCallback((__name) => {
        if(site!==null && allMeters!==null) {
            for(const {name} of allMeters) {
                if(name===__name) {
                    return `A meter with name "${__name}" already exists at ${site.name}`
                }
            }
        }

        return true
    }, [allMeters, site])

    useEffect(() => {
        let __canSave = false
        if(createNew) {
            // Create new has higher priority
            __canSave = errMeterName===false && errValidRange===false
        }
        else {
            // Is there a meter selected?
            if(meter) {
                __canSave = !!meter._id
            }
        }
        // console.log(`[canSave] createNew=${createNew}, errMeterName=${errMeterName}, errValidRange=${errValidRange} : ${canSave}`)
        enableSave(__canSave)
    }, [createNew, errMeterName, errValidRange, meter])

    async function onSave(event) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        let __meterId = meter && meter._id
        if(createNew) {
            // Create the meter first
            const [valid_from, valid_to] = validRange
            const data = {
                name: meterName, 
                description: `Storage meter for ${site.name}`,
                active: true, 
                organisation: site.organisation_id,
                site_id: site._id,
                meter_type: ['storage'],
                meter_subtype: "kilowatt-hour",
                valid_from: tz.dateToUTC(valid_from, 'UTC').toISOString(),
                valid_to: valid_to?tz.dateToUTC(valid_to, 'UTC').toISOString():undefined,
                config: {}
            }

            try {
                const __new = await request.call('/api/meter', 'put', { data })
                __meterId = __new._id
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
                return false
            }
        }
        if(!__meterId) {
            notify.error('Select or create a storage meter')
            return false
        }
        // Update the meter with the meter ID
        try {
            await request.call(`/api/battery/${battery._id}`,
                'put',
                {
                    data: {
                        ...battery,
                        meter_id: __meterId
                    }
                })
            onDone(`${createNew?'Created and assigned':'Assigned'} meter to battery`)
        }
        catch(err) {
            console.error(err) 
            notify.error(err.message)
        }
    }

    function onCancel(e) {
        let __confirm=false
        if(e!==true) {
            if(!createNew) {
                if(meterId==="") {
                    __confirm = meter && meter._id
                }
                else {
                    __confirm = !meter || meter._id!==meterId
                }
            }
            else {
                __confirm = !isEqual(meterName, DFLT_METER_NAME) ||
                    !isEqual([battery.valid_from, battery.valid_to], validRange)
            }
        }
        setConfirm(__confirm)
        if(!__confirm) {
            onDone()
        }
    }


    return battery && (
        <Container fluid>
            <AlertContainer tag="battery.meter.notifications" page="battery"/>
            <Form noValidate onSubmit={onSave}>
            <Form.Row>
            <Form.Group as={Col} lg={3} controlId="batterySite">
                    <Form.Label>Site</Form.Label>
                    <Select
                        className="basic-single"
                        aria-label="site"
                        classNamePrefix="select"
                        value={site}
                        options={sites}
                        onChange={setSite}/>
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="batteryMeter">
                    <Form.Label>Meter</Form.Label>
                    <Select
                        className="basic-single"
                        aria-label="meter"
                        classNamePrefix="select"
                        value={meter}
                        options={meters}
                        isDisabled={createNew}
                        onChange={setMeter}/>
                </Form.Group>
            </Form.Row>
            {site && site.value!=="" && <><Form.Row>
                <Form.Check inline
                    label={`Create new storage meter in ${site.name}`}
                    name="chkCreateNew"
                    type="checkbox"
                    checked={createNew}
                    onChange={toggleCreateNew}/>
            </Form.Row>

            {createNew && <>
            <Form.Row>
                <Col><em>(*) - required field</em></Col>
            </Form.Row>
            <Form.Row>
                <FormControl 
                    descriptor={FRM_NAME} 
                    onChange={setMeterName} 
                    isValid={(name, err) => setErrMeterName(err)}
                    validate={checkMeterName} 
                    value={meterName}/>
            </Form.Row>
            <Form.Row>
                <FormControl 
                    descriptor={FRM_VALID_RANGE} 
                    as={String}
                    onChange={setValidRange} 
                    isValid={(name, err) => setErrValidRange(err) }
                    value={validRange}/>
            </Form.Row></>}
            </>}
            <Form.Row>
                <Col lg="6">
                    {confirmRequired?(<ConfirmAlert
                        heading="Details have changed"
                        text="Battery meter details have changed, really cancel?"
                        buttonsInline={false}
                        onNo={() => setConfirm(false)}
                        onYes={() => onCancel(true)}/>
                    ):
                    (<div className="btn-right">
                        <Button 
                            type="button" 
                            ref={cancelRef}
                            variant="outline-secondary" 
                            onClick={onCancel}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-times"/>Cancel
                        </Button>

                        <Button 
                            id="btnSave" 
                            disabled={!canSave} 
                            type="button" 
                            onClick={onSave} 
                            variant="re-primary">
                            <i className="fas fa-save"/>Save
                        </Button>
                    </div>)}
                </Col>
            </Form.Row>
            </Form>
        </Container>
    )
}
