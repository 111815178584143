//import React, { Component } from "react";

import { filterYesterday, filterToday, filterTomorrow  } from './HelperFunctions'

export default class Results {
  constructor(item, label, attr, values, precision = 3) {
    this.item = item;
    this.label = label;
    const __precision = n => {
      if (precision === null || typeof n !== "number") return n;
      return n.toFixed(3);
    };
    this.data = [
      filterYesterday(values).map(v => __precision(v[attr])),
      filterToday(values).map(v => __precision(v[attr])),
      filterTomorrow(values).map(v => __precision(v[attr]))
    ];
  }

  get yesterday() {
    const { label } = this,
      data = this.data[0];
    return {
      name: data.length > 0 ? label : "",
      data
    };
  }

  get today() {
    const { label } = this,
      data = this.data[1];
    return {
      name: data.length > 0 ? label : "",
      data
    };
  }

  get tomorrow() {
    const { label } = this,
      data = this.data[2];
    return {
      name: data.length > 0 ? label : "",
      data
    };
  }
}
