import React, {useState, useEffect} from 'react'
import { Row, Col } from 'react-bootstrap'

import { monthsShort } from 'moment'
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/dataZoom";

import LoadWidget from './LoadWidget'
import RevenueWidget from './RevenueWidget'

import { OPTIONS as Options, calculateAxisParams, DAYS_IN_MONTH, scaleWattHours } from './utils'

const noop=() => {}

const XAXIS = {
	type: 'category',
	name: 'Month',
	nameLocation: "middle",
	nameTextStyle: {
		fontSize: 16,
		padding: [10, 0,0,0]
	},
	data: monthsShort()
}

export default function YearPlot({data=null, type='dc', comparing=[], setMonth=noop}) {
	const [options, setOptions] = useState(Options)
    const [chart, setChart] = useState(null)
	const [readings, setReadings] = useState(null)
	const [scaler, setScaler] = useState(null)
	const [billedAmount, setBilledAmount] = useState(null)

    useEffect(() => {
		if(chart===null) return ;
        if(data===null) return ;
		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

        const [yAxis]  = __opt.yAxis
        yAxis.show=true
		yAxis.max=0
		
		const __rawSeries = [data, ...comparing].map(([__scenario, __data=null]) => {
			if(__data===null) {
				console.error(`Unexpected null value for __data when mapping raw series for scenario ${__scenario.name}`)
				return [__scenario, [], 0]
			}

			const __hourly = __data.hourly[type]
			const __monthly = __data.monthly[type]
			const __m=__monthly.reduce((max, v) => v>max?v:max, 0)
			const __totalPrice = __data.hourly.prices
				?__data.hourly.prices.reduce((tot, v) => tot+v, 0)
				:null
			return [__scenario, __monthly, __hourly, __m, __totalPrice]
		}), 
			__max = __rawSeries.reduce((max, [,,,v]) => v>max?v:max, 0),
			__scaler = scaleWattHours(__max),
			__axisParams = calculateAxisParams(__scaler.transform(__max))

		if(__rawSeries.length>0) {
			const [,,,,__totalPrice] = __rawSeries[0]
			if(__totalPrice!==null) {
				setBilledAmount(__totalPrice/100)
			}
		}

		const series = __rawSeries.map(([scenario, __data]) => ({
			name: scenario.name,
			data: __data.map(__scaler.transform),
			xAxisIndex: 0,
			yAxisIndex: 0,
			emphasis: {
				focus: 'series'
			},
			type: 'bar'
		}))

		setReadings(__rawSeries.map(s => s[2]))
		setScaler(__scaler)
		
		const legend = {
			top: '90%', 
			left: 'center',
			formatter: name => {
				if(!window.chrome) return name
				return name.length>20?
						`${name}              `:
						`${name}     `
			},
			data: series.map(s => s.name)
		}
        const title = {
            left: 'center',
            text: `Annual Load Output (${type==='dc'?'DC':'AC'})`
        }

		setOptions(o => ({
            ...__opt,
            title,
			legend,
			series,
			xAxis: [XAXIS],
			yAxis: [{...yAxis, name: `Output (${__scaler.units})`, ...__axisParams}]
		}))

    }, [chart, type, data, comparing])

	const chartEvents = {
		'click': e => setMonth(e.name)
	}

    return (
		<div>
			<Row>
				<Col md={10}>
					<ReactEcharts
						ref={(e) => { setChart(e); }}
						echarts={echarts}
						notMerge={true}
						option={options}
						onEvents={chartEvents}
						lazyUpdate={true}
						style={{ height: "50vh"}}/>
				</Col>
				{Array.isArray(readings) && readings.length>0?
				(<Col md={2}>
					<div className="component-container">
						<LoadWidget readings={readings[0]} scaler={scaler}/>
						<RevenueWidget amount={billedAmount}/>
					</div>
				</Col>):null}
			</Row>
		</div>
	)
}
