import React, { Component, useState } from "react";

import { useHistory } from "react-router-dom"
import { Alert } from 'react-bootstrap'
import AuthService from "../services/auth";

import logo from "../dashboard/sidebar/interreg-logo.svg";
import regEnergylogo from "../dashboard/sidebar/Icon_Green@3x.png";
import "../sass/style.scss";
import "../sass/components/_login.scss";
//import CookieConsent from "react-cookie-consent";

const noop = () => {}

function LoginForm({user={username: "", password: ""}, onLogin=noop, ...props}) {
  const [username, setUsername] = useState(user.username)
  const [password, setPassword] = useState(user.password)
  const [error, setError] = useState(null)
  const history = useHistory()

  async function login(e) {
    e.preventDefault();
    try {
      const __user = await AuthService.login(username, password);
      onLogin(__user)
    } catch (err) {
      console.error("Login error : ", err);
    }
  }

  return (
    <form onSubmit={login}>
      <div className="form-group">
        <div className="position-relative has-icon-left">
          <label htmlFor="exampleInputUsername" className="sr-only">
            Username
          </label>
          <input
            type="email"
            id="username"
            className="form-control form-control-rounded"
            value={username}
            onChange={e => setUsername(e.target.value)}
            placeholder="user@example.com"
          />
          <div className="form-control-position">
            <i className="fa fa-user" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="position-relative has-icon-left">
          <label htmlFor="exampleInputPassword" className="sr-only">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control form-control-rounded"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className="form-control-position">
            <i className="fa fa-lock" />
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-login btn-round btn-block">
        Login
      </button>
    </form>
  )

}

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      active: false,
      username: (user && user.email) || ""
    };
  }

  render() {
    return (
      <div>
        <div className="card-authentication2 mx-auto my-5">
          <div className="card-group">
            <div className="card mb-0">
              <div className="bg-signin2" />

              <div className="card-img-overlay rounded-left my-5">
                <h1 className="text-white">
                  REG<b>ENERGY</b>
                </h1>
                <h4 className="text-white">Renewable Energy Regions </h4>

                <p className="card-text text-white pt-3">
                  Boost Renewable Energies!
                </p>
                <div className="loginLogo">
                  <img src={logo} alt="RegEnergy logo" />
                </div>
              </div>
            </div>

            <div className="card mb-0 ">
              <div className="card-body">
                <div className="card-content p-3">
                  <div className="text-center pt-3">
                    <img src={regEnergylogo} alt="RegEnergy logo" />
                  </div>
                  <div className="card-title text-uppercase text-center py-3">
                    Sign In
                  </div>
                  <LoginForm {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<CookieConsent
          location="bottom"
          buttonText="I Accept"
          cookieName="RegEnergyCookiePolicy"
          style={{
            background: "#2B373B",
            textAlign: "center",
            Transition: ".2s"
          }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          acceptOnScroll={false}
          //onAccept={() => {alert("consent given")}}
          debug={false}
        >
          By using this website, you consent to the use of cookies in accordance
          with the REGENERGY
          <a
            className="ascp-more-info-link"
            tabIndex="0"
            target="_blank"
            rel="noopener noreferrer"
            href="https://tssg.org/cookie-policy/"
          >
            Cookie Policy.
          </a>
          {}
        </CookieConsent>*/}
      </div>
    );
  }
}

export default LoginPage;
