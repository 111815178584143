import React, { useState, useEffect, useCallback } from 'react'
import { Redirect, useHistory } from "react-router-dom";
import moment from 'moment'
import { Alert, Container, Card, Form, Row, Col, Button } from 'react-bootstrap'
import {Request} from "../../../services/HttpService";
import { Notify } from '../AlertListener.jsx'
import DatePicker from "react-datepicker";
import BootstrapTable from 'react-bootstrap-table-next';
import { ConfirmAlert, Overlay, EditButton, DeleteButton, Loading } from '../UIComponents.jsx'
import "react-datepicker/dist/react-datepicker.css";
import ChargeTemplates from './ChargeTemplateCombo'
import ChargeForm from './CompactChargeForm'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")

const __NOW=moment(),
	date=__NOW.date(),
	month = __NOW.month(),
	year = __NOW.year(),
	TODAY=moment.utc({year, month, date}),
	NEW_PERIOD = {
		validFrom: moment(TODAY),
		validTo: moment(TODAY).add(1, 'month')
	}

async function loadCharges(plan_id, period_id) {
	try {
		return await request.get(`/api/billable/plan/${plan_id}/period/${period_id}/charge`)
	}
	catch(err) {
		console.error(err)
		notify.error(err.message)
	}
	return []
}

export default function BillingPlanPeriodForm({plan_id=null, _id=null, root}) {
	const [loading, setLoading] = useState(true)
	const [plan, setPlan] = useState(null)
	const [redirect, setRedirect] = useState(null)
	const [confirmRequired, setConfirm] = useState(false)
	const [error, setError] = useState(null)
	const [initial, setInitial] = useState(null)
	const [validFrom, setValidFrom] = useState(null)
	const [validTo, setValidTo] = useState(null)
	const [openEnded, setOpenEnded] = useState(false)
	const [fromTemplate, setFromTemplate] = useState(null)
	const [charges, setCharges] = useState([])
	const [expanded, setExpanded] = useState([])
	const [notExpanded, setNotExpanded] = useState([])
	const [editRequest, setEditRequest]=useState(null)
	const [toDelete, setToDelete]=useState(null)

	useEffect(() => {
		async function __loadPlan() {
			try {
				const __plan = await request.call(`/api/billable/plan/${plan_id}?includePeriods`)
				setPlan(__plan)
			}
			catch(err) {
				console.error(err)
				notify.error(err.message)
				setRedirect('')
			}

		}

		if(plan_id===null) {
			setPlan(null)
		}
		else {
			__loadPlan() ;
		}

		return () => {
			setPlan(null)
		}
	}, [plan_id])

	const history = useHistory()

	useEffect(() => {
		let __initial 
		if(plan===null) {
			setInitial(null)
			return 
		} 
		const existingPeriods = plan.periods
		if(_id===null) {
			// New period
			if(existingPeriods.length>0) {
				let {valid_from, valid_to=null} = existingPeriods[existingPeriods.length-1]
				let __from 
				if(valid_to!==null) {
					__from = moment(valid_to).add(1, 'day') ;
				}
				else if(TODAY.isAfter(valid_from)) {
					__from=moment(TODAY)
				}
				else {
					__from=moment(valid_from).add(1, 'month')
				}
				__initial = {
					validFrom: __from, 
					validTo: moment(__from).add(1, 'month').subtract(1, 'day')
				}
			}
			else {
				__initial = NEW_PERIOD 
			}
		}
		else {
			const __period=existingPeriods.filter(p => p._id===_id)
			if(__period.length===0) {
				notify.error(`Period ID=${_id} not found`)
				setRedirect(`/${plan_id}/edit`)
				return ;
			}
			__initial = {
				validFrom: moment(__period[0].valid_from),
				validTo: __period[0].valid_to?moment(__period[0].valid_to):null
			}
		}
		setInitial(i => ({...__initial}))
		setLoading(false)
		
		return () => {
			setInitial(null)
		}
	}, [plan])

	useEffect(() => {
		return () => {
			setRedirect(null)
		}
	}, [redirect])

	useEffect(() => {
		setExpanded(charges.map(c => c._id).filter(_id => _id===editRequest))
		const __notExp = charges.map(c => c._id).filter(_id => _id!==editRequest)
		setNotExpanded(__notExp)
	}, [editRequest, setExpanded, setNotExpanded])

	useEffect(() => {
		async function __getCharges() {
			const __charges = await loadCharges(plan_id, _id)
			setCharges(__charges.map(c => ({...c, __expanded: false})))
		}

		if(initial!==null) {
			const { validFrom, validTo=null} = initial
			setValidFrom(validFrom.toDate())
			setValidTo(validTo!==null?validTo.toDate():null)
			if(validTo===null) {
				setOpenEnded(true)
			}
			if(_id!==null) {
				// Editing a period
				__getCharges()
			}
			else setCharges(c => [])
		}

		return () => {
			setValidTo(null) 
			setValidFrom(null)
			setCharges([])
		}
	}, [initial])

	const __delete = useCallback(async (charge) => {
		try {
			await request.call(`/api/billable/plan/${plan_id}/period/${_id}/charge/${charge._id}`, 'delete')
			setCharges(await loadCharges(plan_id, _id))
		}
		catch(err) {
			notify.error('Error deleting charge - ', err.message)
		}
		setToDelete(null)
	}, [charges, setToDelete, setCharges])

	useEffect(() => {
		if(!toDelete) return
		setExpanded([toDelete._id])
		setNotExpanded(charges.map(c => c._id).filter(_id => _id!==editRequest))
		return () => {
			setExpanded([])
			setNotExpanded([])
		}
	}, [toDelete, setExpanded, setNotExpanded])

	if(redirect!==null) {
		return (<Redirect to={`${root}${redirect}`}/>)
	}

	function isValid(from, to) {
		const __from = moment(from)
		if(__from.isSameOrAfter(to)) {
			setError('Valid from must be earlier than valid to')
			return false 
		}
		else {
			setError(null)
			return true
		}
	}

	function update(whichControl, d) {
		if(whichControl==="from") {
			setValidFrom(d)
			isValid(d, validTo)
		}
		else {
			setValidTo(d)
			isValid(validFrom, d)
		}
	}

	async function save(e) {
		const { id: __btn } = e.target ;
		if(isValid(validFrom, validTo)) {
  			try {
				const __from = moment(validFrom), 
					__to = !openEnded?moment(validTo):null
  				const data={
  					valid_from: __from.format('YYYY-MM-DD')
  				}
  				if(__to!==null) {
  					data.valid_to=__to.format('YYYY-MM-DD') 
  				}
				const __new = await request.call(`/api/billable/plan/${plan._id}/period${_id!==null?`/${_id}`:''}`, 
					'put',
					{ 
						data
					})
				notify.info(`${_id===null?'Created':'Updated'} period from ${__from.format("DD/MM/YYYY")} ${__to!==null?`to ${__to.format("DD/MM/YYYY")}`:''} on plan "${plan.name}"`)
				switch(__btn) {
					case 'btnPeriodNext':
						// setRedirect(`/${plan_id}/period/${__new._id}/edit`)
						history.replace(`${root}/${plan_id}/period/${__new._id}/edit`)
						break
					case 'btnPeriodDone':
						history.replace(`${root}/${plan_id}/edit`)
						break ;
					default:
						break
				}
  			}
  			catch(err) {
  			 	console.error(err)
  			 	notify.error(err.message)
  			}
		}
	}

	function cancel(e) {
		if(e===true || 
			(initial.validFrom.isSame(validFrom) && 
			( (openEnded && initial.validTo===null) ||
			  (!openEnded && validTo!==null && initial.validTo!==null && initial.validTo.isSame(validTo, 'day'))
				 ) )) {
			// Result of clicking confirm yes or no change
			setRedirect(`/${plan_id}/edit`)
		}
		setConfirm(true)
	}

	if(redirect!==null) {
		return (<Redirect to={`${root}${redirect}`}/>)
	}

	if(loading) {
		return (
			<Container fluid>
				<Row>
					<Col style={{height: "50vh"}}>
						<Loading text="Loading plan and period" active={true}/>
					</Col>
				</Row>
			</Container>
		)
	}

	const __props = {
		disabled: confirmRequired || fromTemplate!==null || editRequest!==null
	}

	const chargeColumns = [{
		dataField: '_id',
		text: 'Period ID',
		hidden: true
	}, {
		dataField: '__expanded',
		text:'',
		hidden: true
	}, {
		dataField: 'name',
		text: 'Name',
		sort: true
	}, {
		dataField: 'charge_type',
		text: 'Type',
		sort: true
	}, {
		dataField: 'basis',
		text: 'Basis',
		sort: true
	}, {
		dataField: 'value',
		text: 'Value',
		formatter: (data, row) => (<div>
			<div style={{display: "inline-block"}}>
				{ data.__type==='number'?
					data.__val:
					Object.entries(data.__val).map(([f,v]) => `${f}=${v}`).join(', ')}
			</div>
			<div style={{display: "inline-block", float: 'right'}}>
				<EditButton outline label={`Edit charge`} onClick={() => setEditRequest(er => er!==null?er:row._id)} size="sm"/>
				<DeleteButton outline style={{marginLeft: "0.5rem"}} label={`Delete charge`} onClick={() => setToDelete(row)} size="sm"/>
			</div>
		</div>)
	}];

	function toggleOpenEnded() {
		const __openEnded = !openEnded
		// Make sure there are no later period
		const __validFrom = moment(validFrom) 
		for(const p of plan.periods) {
			if(__validFrom.isBefore(p.valid_from)) {
				notify.error('Cannot make open-ended; later period exists')
				return ;
			}
		}
		setOpenEnded(__openEnded)
		// Set validTo appropriately
		setValidTo(!__openEnded
			?initial.validTo!==null?moment(initial.validTo).toDate():moment(validFrom).add(1, 'day').toDate() 
			:null)
			
	}
	
	const expandRow={
		renderer: row => {
			return toDelete?
			(<div style={{float: "right"}}>
            <ConfirmAlert text={`Delete charge "${row.name}"?`}
							onYes={() => { __delete(row) }}
							onNo={() => { 
								setToDelete(null)
								setExpanded([])
							}}
							className="pull-right"/>
        	</div>)
			:(<Overlay>
				<ChargeForm 
					planId={plan_id}
					periodId={_id}
					charge={row}
					currency={plan.currency}
					onDone={async (refresh) => {
						if(refresh===true) {
							setCharges(await loadCharges(plan_id, _id))
						}
						setEditRequest(null)
					}}/>
				</Overlay>
		)},
		expanded: expanded,
		notExpandable: notExpanded,
		expandByColumnOnly: true,
		onExpand: () => {}
	}

	return initial!==null?(
		<Container fluid>
			<Row>
				<Col lg="6">
					<h3>{_id===null?'New':'Edit'} Billing Period</h3>
				</Col>
			</Row>
			<Row>
				<Col lg="1"><strong>Plan</strong></Col><Col>{plan.name}</Col>
			</Row>
			<Row>
				<Col lg="1"><strong>Basis</strong></Col><Col>{plan.is_wholesale?'Wholesale':'Tariff'}</Col>
			</Row>
			<Row>
				<Col lg="1"><strong>Currency</strong></Col><Col>{plan.currency}</Col>
			</Row>

			<Row>
				<Col lg="6"><h4>Existing Periods</h4></Col>
			</Row>
			<Row style={{paddingLeft: "1rem"}}>
				<Col lg="2"><strong>From</strong></Col>
				<Col lg="2"><strong>To</strong></Col>
			</Row>
			{plan.periods.length>0?
			plan.periods.map(p => (<Row key={`period_${p._id}`} style={{paddingLeft: "1rem"}}>
				<Col lg="2" style={{backgroundColor: _id===p._id?'lightblue':'inherit'}}>
					{moment(p.valid_from).format('DD/MM/YYYY')}
				</Col>
				<Col lg="2" style={{backgroundColor: _id===p._id?'lightblue':'inherit'}}>
					{p.valid_to?moment(p.valid_to).format('DD/MM/YYYY'):''}
				</Col>
			</Row>)):
			(<Row  style={{paddingLeft: "1rem"}}>
				<Col lg="6">
					None found
				</Col>
			</Row>)}
			<Row><Col lg="6"><hr/></Col></Row>
			{error?(<Row><Col lg="6"><Alert variant="danger">{error}</Alert></Col></Row>):null}
			<Row>
				<Col lg="3">
					<div>Valid From</div>
					<DatePicker
						style={{width: "100%"}}
						selected={validFrom}
						{...__props}
						onChange={d => update('from', d)}
						locale="en"
						name="valid_from"
						dateFormat="dd/MM/yyyy"/>
				</Col>
				<Col lg="3">
					<div>Valid To</div>
					<DatePicker
						selected={validTo}
						disabled={__props.disabled || openEnded}
						onChange={d => update('to', d)}
						locale="en"
						name="valid_to"
						dateFormat="dd/MM/yyyy"/>
					<div>
						<Form.Check
							type="checkbox"
							label="Open ended period"
							{...__props}
							onChange={e => toggleOpenEnded()}
							checked={openEnded}/>
					</div>
				</Col>
			</Row>
			{_id!==null?(<Row>
				<Col lg="8">
					<div>
						<h4 style={{display: "inline-block", marginRight: "1rem"}}>Charges</h4>
		    			<ChargeTemplates disabled={fromTemplate!==null} 
		    				planType={plan!==null?(plan.is_wholesale?'Wholesale':'Tariff'):null} 
		    				onSelect={c => setFromTemplate(c)}/>
		    			{fromTemplate!==null?(
		    				<ChargeForm template={fromTemplate} 
		    					planId={plan_id}
		    					periodId={_id}
		    					currency={plan.currency}
		    					onDone={async (refresh) => {
		    						if(refresh===true) {
		    							setCharges(await loadCharges(plan_id, _id))
		    						}
		    						setFromTemplate(null)
		    					}}/>):null
		    			}
					</div>
					<div>
				        <BootstrapTable
				         	bootstrap4 striped hover condensed 
				         	version="4"
							keyField='_id' 
							columns={chargeColumns}
							data={charges}
				        	noDataIndication="No charges defined"
				        	expandRow={expandRow}
				        	defaultSorted={[{dataField: 'name', order: 'asc'}]}
				        />
					</div>
				</Col>
			</Row>):null}
			<Row>
				<Col lg="6">
				<hr/>
        		{confirmRequired?(<ConfirmAlert
  					heading="Details have changed"
  					text="Plan details have changed, really cancel?"
  					buttonsInline={false}
  					onNo={() => setConfirm(false)}
  					onYes={() => cancel(true)}/>
            	):
	            (<div className="btn-right">
	            	<Button 
            			type="button" 
            			variant="outline-secondary" 
						{...__props}
            			onClick={cancel}
            			style={{marginRight: "1rem"}}>
            			<i className="fas fa-times"/>Cancel
            		</Button>
            		{_id===null?(<Button id="btnPeriodNext" disabled={error!==null || __props.disabled} type="button" onClick={save} variant="primary"
            			style={{marginRight: "1rem"}}>
						<i className="fas fa-angle-double-right"/>Next
					</Button>):null}
            		<Button id="btnPeriodDone" disabled={error!==null || __props.disabled} type="button" onClick={save} variant="re-primary">
						<i className="fas fa-save"/>Save{_id!==null?"":" and finish"}
					</Button>
				</div>)}
				</Col>
			</Row>
		</Container>
	):null
}
