class ErrorListener {

	constructor() {
		this.listeners=new Map() ;
	}

	on(error, handler) {
		let handlers = this.listeners.get(error) ;
		if(typeof(handlers)==='undefined') {
			handlers=[]
			this.listeners.set(error, handlers)
		}
		if(handlers.includes(handler)) {
			// console.log(`(${error}) Already registered, `, handler)
		}
		else {
			handlers.push(handler)
			// console.log(`(${error}) Registered `, handler) ;
		}
	}

	off(error, handler) {
		let handlers = this.listeners.get(error) ;
		if(!Array.isArray(handlers)) return ;
		const ndx = handlers.indexOf(handler) ;
		if(ndx<0) {
			// console.log(`(${error}) Not registered, `, handler)
		}
		else {
			this.listeners.set(error, handlers.splice(ndx, 1))
			// console.log(`(${error}) Removed from position ${ndx}, `, handler)
		}
	}

	raise(status, error) {
		const handlers = this.listeners.get(status) ;
		if(Array.isArray(handlers)) {
			for(const h of handlers) {
				h(error) ;
			}
		}
	}
}

export default new ErrorListener()