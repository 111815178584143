import React, {useState, useEffect, useContext, useCallback} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import OrganisationMeters from "../settings/organisation/OrganisationMeters"
import ThreeDayPlot from "../plots/ThreeDayPlot"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import WidgetPanel from "../widgets/WidgetPanel";
const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")


export default function OrganisationDashboard() {
    const [organisation, setOrganisation] = useState(null)
    const [loadData, setLoadData] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedLoadData, setPredictedLoadData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)
    const [tariffData, setTariffData] = useState(null)

    const { user, Time } = useContext(UserContext)

    const __fetch = useCallback(async (uri, setter, query={}) => {
        try {
            const __from = Time.yesterday(),
                __to = Time.tomorrow().add(1, 'day') 
            let __data = await request.get(
                uri,
                {
                    ...query,
                    price: true, 
                    from: __from.toISOString(),
                    to: __to.toISOString()
                }
            )
            // Map the data if necessary
            if(__data && 
                Array.isArray(__data) && __data.length>0) {
                if(!Array.isArray(__data[0])) {
                    __data=__data.map(({ timestamp, reading, prediction, price, rate, charges }) => 
                        ([timestamp, reading || prediction, price || rate, charges]))
                }
            }
            setter(__data)
        }
        catch(err) {
            console.error(err)
            notify.error(err.message)
        }
    }, [user, Time])

    useEffect(() => {
        if(user===null) return ;

        async function __getOrg() {
            try {
                const o = await request.get(`/api/organisation/${user.organisationId}`)
                setOrganisation(o)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        __getOrg()
        const __query= {
            from: Time.yesterday().format(),
            // Add day + 1 minute to include midnight at end
            to: Time.tomorrow().add({day: 1, minute: 1}).format(),
            price: 1
        }
        // Actual load
        Fetch(`/api/meter/organisation/${user.organisationId}/readings`, 
                __query, 
                setLoadData, 
                ["timestamp", "reading", "price", "charges"])
        // Predicted load
        Fetch(`/api/organisation/${user.organisationId}/predict/load`, __query, setPredictedLoadData)
        // Actual supply
        Fetch(`/api/meter/organisation/${user.organisationId}/readings`, 
                { ...__query, meter_type: 'supply'}, 
                setSupplyData,
                ["timestamp", "reading"],
                excludeNegativeFilter())
        // Predicted supply
        Fetch(`/api/organisation/${user.organisationId}/predict/supply`, __query, setPredictedSupplyData)
        // Get the wholesale rates
        Fetch(`/api/billable/organisation/${user.organisationId}/rates`, __query, setTariffData)

        return () => {
            setOrganisation(null)
        }
    }, [user])

    if(organisation===null) return null

    return (
        <Container fluid>
            <Row>
                <Col><h2>{organisation.name}</h2></Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <OrganisationMeters organisation={organisation} redirect notable noheader/>
                    <ThreeDayPlot 
                        load={loadData} 
                        predictedLoad={predictedLoadData} 
                        supply={supplyData}
                        predictedSupply={predictedSupplyData} 
                        tariffs={tariffData}
                    />
                </Col>
                <Col lg={2}>
                    <WidgetPanel load={loadData} solar={supplyData}/>
                </Col>
            </Row>
        </Container>
    )
}
