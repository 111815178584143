import React, { useState, useEffect, useCallback, useContext } from 'react'
import DatePicker from "react-datepicker";
import UserContext from "../../../services/user-context"
import {Button, Form, Col} from 'react-bootstrap'
import { IconButtonLight, noop } from '../../components/UIComponents'

const __default = {
    endRequired: false,
    name: ['start', 'end'],
    label: ['Start', 'End'],
    default: ['today']
}

const MONTH_LEN = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
const DATE_RE=/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(20[0-9]{2})$/

export default function DateRangeControl({
        descriptor={}, 
        as=String, 
        value=[new Date().toISOString()], 
        lg=3, 
        onChange=noop, 
        endRequired=false,
        absoluteRange=[],
        allowReset=false,
        minValue=null,
        maxValue=null,
        time=false,
        isValid=noop}) {

    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [openEnded, setOpenEnded] = useState(true)
    const [canReset, enableReset] = useState(false)
    const [name, setName] = useState(null)
    const [label, setLabel] = useState(null)
    const [props, setProps] = useState({})
    const [absDateRange, setAbsDateRange] = useState(null)
    const [error, setError] = useState(false)

    const { Time } = useContext(UserContext)


    useEffect(() => {
        const {name, label,  endRequired, 
            allowReset=false
        } = {...__default, ...descriptor}

        setName(name)
        setLabel(label)

        const [start, end=null] = value
        const __start=!time?Time.day(start).toDate():Time.localTime(start).toDate(),
            __end=!time?(end?Time.day(end).toDate():null):(end?Time.localTime(end).toDate():null)
        setStart(__start)
        setEnd(__end)
        setOpenEnded(!endRequired && __end===null)

        if(allowReset) {
            if(minValue===null) {
                console.error('Reset enabled on DateControl, but no minimum value provided. Ignoring')
            }
            else if(endRequired && maxValue===null) {
                console.error('Reset enabled on constrained DateControl, but no maximum value provided. Ignoring')
            }
            else {
                setAbsDateRange([minValue, maxValue])
                enableReset(true)
            }
        }

        setProps({
            dateFormat: `dd/MM/yyyy${time?", h:mmaa":""}`,
            showTimeInput: time,
            timeFormat: "HH:mm"
        })
    }, [])


    const checkError = useCallback((start, end, openEnded) => {
        const { label } = descriptor
        if(label===null) return
        if(!start) {
            return `"${label[0]}" required`
        }
        if(!openEnded) {
            if(!end) {
                return `"${label[1]}" required`
            }
            // Make sure start is before end
            if(Time.compare(start, end)>=0) {
                return `"${label[0]}" must be earlier than "${label[1]}"`
            }
        }
        return false
    }, [])

    const handleChange = useCallback((start, end, openEnded) => {
        const __err = checkError(start, end, openEnded)
        setError(__err)
        // console.log(`[handleChange](${start}, ${end}, ${openEnded})`)
        onChange([
            start.toISOString(),
            !openEnded && !!end?end.toISOString():undefined
        ])
    }, [])

    const updateStart = useCallback((start) => {
        setStart(start)
        handleChange(start, end, openEnded)
    }, [end, openEnded])

    const updateEnd = useCallback((end) => {
        setEnd(end)
        handleChange(start, end, openEnded)
    }, [start, openEnded])

    const toggleOpenEnded=useCallback(() => {
        const __openEnded = !openEnded
        setOpenEnded(__openEnded)
        handleChange(start, end, __openEnded)
    }, [start, end, openEnded])


    useEffect(() => {
        if(!name) return
        // if(error) console.error('[DateRangeControl[ Error = ', error)
        isValid(name[0], error)
    }, [name, error])

    const handleReset = useCallback(() => {
        if(!canReset) return

        const [__rngStart, __rngEnd=null] = absDateRange

        const __start = !time?Time.day(__rngStart).toDate():Time.localTime(__rngStart).toDate()
        setStart(start)
        let __end=end, openEnded=!!end
        if(__rngEnd) {
            __end=!time?Time.day(__rngEnd).toDate():Time.localTime(__rngEnd).toDate()
        }
        else {
            if(endRequired) {
                console.error("[DateRangeControl] Can't reset end date/time. No max value")
            }
            else {
                __end=null
                openEnded = true
            }
        }
        handleChange(__start, __end, openEnded)
    }, [canReset, end, absDateRange])

    return name && label && (<>
        <Col lg={lg}>
            <div>(*) {label[0]}</div>
            <DatePicker
                style={{width: "100%"}}
                selected={start}
                onChange={updateStart}
                // onChange={d => update('from', d)}
                locale="en"
                name="start"
                {...props}/>
            <div 
                style={{display: !!error?'block':'none'}}
                className="invalid-feedback">
                {error}
            </div>
        </Col>
        <Col lg={lg}>
            <div>{endRequired?'(*) ':''}{label[1]}</div>
            <DatePicker
                style={{width: "100%"}}
                selected={end}
                onChange={updateEnd}
                // onChange={d => update('from', d)}
                disabled={openEnded}
                locale="en"
                name="end"
                {...props}/>
            { canReset && <span style={{marginLeft: "0.5rem"}}>
                <IconButtonLight icon="fa-undo" 
                    label="Reset"
                    onClick={handleReset}/>
            </span>}
            <div>
                { !endRequired && <Form.Check
                    type="checkbox"
                    name="openEnded"
                    label="Open ended period"
                    onChange={toggleOpenEnded}
                    checked={openEnded}/>}
            </div>
        </Col>
    </>)
}