import React, {useState, useEffect} from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import SiteMeters from './SiteMeters'
import SiteRenewables from './SiteRenewables'
import { AddButton, LeafletMap, Toggle} from "../../components/UIComponents"
import {Notify} from '../../components/AlertListener'

import CacheResources from './cache-resources'

const notify=Notify("site.notifications")
const Cache = new CacheResources()

export default function SiteView({site}) {
    const [meters, setMeters]=useState(null)
    // use the following to trigger a reload
    const [reload, setReload] = useState(0)
    const [renewables, setRenewables]=useState(null)
    const [mapVisible, showMap] = useState(null)

    useEffect(() => {
        return () => {
            Cache.clear()
        }
    }, [])
    
    useEffect(() => {
        if(site===null) return 
        const __load = async () => {
            try {
                const [__meters, __renewables] = await Promise.all([
                    Cache.getMetersForSite(site._id),
                    Cache.getRenewablesForSite(site._id)
                ])
                setMeters([...__meters])
                setRenewables([...__renewables])
            }
            catch(err) {
                console.error(err)
                notify.error(err)
            }
        }
        __load()
    }, [site, reload])

    return (<>
        <Row style={{lineHeight: "2.5rem"}}>
            <Col md={2}><span><strong>Description : </strong></span></Col>
            <Col md={4}>{site.description}</Col>
        </Row>
        <Row style={{lineHeight: "2.5rem"}}>
            <Col md={2}><span><strong>Location : </strong></span></Col>
            <Col md={4}>
                {site.location!==null?(
                    <>
                        <span>{site.location[0]}, {site.location[1]}</span>
                        <Button 
                            onClick={() => showMap(mapVisible!==site._id?site._id:null)}
                            variant="link" 
                            size="sm"><i style={{fontSize: "1.2rem", padding: "0.2rem"}} className="fas fa-map-marker-alt"/></Button>
                        {mapVisible===site._id?(
                            <LeafletMap 
                                visible
                                latitude={site.location[0]}
                                longitude={site.location[1]}
                                height="200px"/>
                        ):null}
                    </>
                ):null}
            

            </Col>
        </Row>
        <Row><Col><SiteMeters site={site} meters={meters} renewables={renewables} onUpdate={() => {
            Cache.invalidate(site._id, true, false)
            setReload(reload+1)
        } }/></Col></Row>
        <Row><Col><SiteRenewables site={site} renewables={renewables}/></Col></Row>
    </>)
}