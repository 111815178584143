
const MODULE_TYPES=[
    ["MT_STANDARD", "Standard"], 
    ["MT_PREMIUM", "Premium"], 
    ["MT_THINFILM", "Thin Film"]],
    ARRAY_TYPES=[
        ["AT_OPEN_RACK", "Fixed - Open Rack"],
        ["AT_ROOF_MOUNTED", "Fixed - Roof Mounted"],
        ["AT_1AXIS", "1-Axis"],
        ["AT_1AXIS_BACKTRACK", "1-Axis Backtracking"],
        ["AT_2AXIS", "2-Axis"]] ;

function getText(key, list) {
    for(const [k, t] of list) {
        if(k===key) return t
    }
    return null ;
}

export {
    MODULE_TYPES,
    ARRAY_TYPES,
    getText
}
