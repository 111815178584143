import React, { useState, useEffect, useContext, useRef } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Select from "react-select";
import { noop } from '../../components/UIComponents'
import {Notify} from '../../components/AlertListener'
import {Request} from "../../../services/HttpService";
import BatteryContext from './battery-context';

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")

export default function AddPage1({canContinue=noop}) {
    const [makes, setMakes] = useState(null)
    const [make, setMake] = useState(null)
    const [models, setModels] = useState(null)
    const [model, setModel] = useState(null)

    const { battery, update } = useContext(BatteryContext)

    useEffect(() => {
        async function __loadSupported() {
            try {
                const __supported = await request.get('/api/battery/supported')
                setMakes(Object.entries(__supported)
                    .map(([make, descriptor]) => {
                        const { name, ...models} = descriptor
                        return {
                            value: make, 
                            label: name,
                            make,
                            name,
                            models: Object.entries(models)
                                .map(([model, {name}]) => ({ model, name}))
                        }
                    }))
            }
            catch(err) {
                notify.error('Error loading supported models')
            }
        }
        __loadSupported()
    }, [])

    useEffect(() => {
        if(!battery || !Array.isArray(makes)) return 
        
        const { make } = battery
        if(!make) return 
        
        const [__selected] = makes.filter(({value}) => value===make)
        if(!__selected) return 
        setMake(__selected)

    }, [battery, makes])

    useEffect(() => {
        if(!battery) return
        if(make!==null) {
            // onChange('make', make.value)
            setModels(make.models.map(m => ({...m, value: m.model, label: m.name })))
            update('make', make.value)
        }
        else {
            setModels(null)
            setModel(null)
        }
    }, [battery, make])

    useEffect(() => {
        if(!battery || !Array.isArray(models)) return 
        
        const { model } = battery
        if(!model) return 
        
        const [__selected] = models.filter(({value}) => value===model)
        if(!__selected) return 
        setModel(__selected)

        return () => {
            setModel(null)
        }
    }, [battery, models])

    useEffect(() => {
        if(model) {
            canContinue(true)
            update('model', model.value)
            // onChange('model', model.value)
        }
        else {
            canContinue(false)
            // onChange('model', null)
        }
    }, [model])

    return makes!==null && (<Container fluid><Form.Row>
        <Form.Group as={Col} lg={3} controlId="batteryMake">
            <Form.Label>(*) Make</Form.Label>
            <Select
                className="basic-single"
                aria-label="foobar"
                classNamePrefix="select"
                value={make}
                options={makes}
                onChange={setMake}/>
        </Form.Group>
        <Form.Group as={Col} lg={3} controlId="batteryModel">
            <Form.Label>(*) Model</Form.Label>
            <Select
                className="basic-single"
                classNamePrefix="select"
                value={model}
                options={models}
                isDisabled={!models}
                placeholder={models?"Select...":"Select a make"}
                onChange={setModel}/>
        </Form.Group>
    </Form.Row></Container>)
}