import React, { Component } from "react";
import { notify } from "../../utility/ToastContainer";
import ToastContainer from "../../utility/ToastContainer";
import { Link } from "react-router-dom";

import AuthService from "../../services/auth";

import avatar from "./user_green.png";

class Titlebar extends Component {
  constructor(props) {
    super(props);
  }

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  logout = async e => {
    e.preventDefault();
    try {
      await AuthService.logout();
      // this.props.history.replace("/login?status=Logged.Out");
      //this.sendStatusNotification("SUCCESS");
      //console.log('sessionStorage', sessionStorage)
    } catch (err) {
      console.error("logout() : ", err);
      this.sendStatusNotification("ERROR", err);
      //alert('Error : '+ err)
      //this.props.history.push(`/login?status=Logged.Out&err=${err.message}`);
    }
  };

  render() {
    const { user } = this.props;
    //console.log('user title bar', user)

    return (
      <div>
        <div>
          <ToastContainer />
        </div>
        <div>
          <nav className="navbar navbar-expand fixed-top gradient-scooter">
            <ul className="navbar-nav mr-auto align-items-center">
            </ul>

            <ul className="navbar-nav align-items-center right-nav-link">
              <li className="nav-item">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  data-toggle="dropdown"
                  href="/#"
                  aria-expanded="false"
                >
                  <span className="user-profile">
                    <img src={avatar} alt="user icon" className="user-icon" />
                  </span>
                  <span style={{ color: "#212529", marginLeft: "10px" }}>
                    Welcome {user.firstname} ({user.organisation.name})
                    <i className="fas fa-caret-down" />
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li className="dropdown-item user-details">
                    <div className="media">
                      <div className="avatar">
                        <img
                          src={avatar}
                          alt="user icon"
                          className="user-icon"
                        />
                      </div>
                      <div className="media-body">
                        <h6 className="mt-2 user-title">
                          {user.organisation.name}
                        </h6>
                        <p className="user-subtitle">{user.email}</p>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item">
                    <a href="/dashboard">
                      {" "}
                      <i className="fa fa-home" /> Home
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <a href="/dashboard/profile">
                      {" "}
                      <i className="fa fa-cog" /> Profile
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <Link to='/logout'>
                      <i className="fas fa-power-off" /> Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Titlebar;
