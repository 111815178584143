import axios from "axios";
import ErrorListener from "./ErrorListener";
import {Request} from "./HttpService";

const request = new Request({ service: 'Site service'})

const HTTP_STATUS_CODES = {
  // Retrieved from: https://www.restapitutorial.com/httpstatuscodes.html
  // STATUS_CODE: response_msg

  // Redundant, success responses;
  200: "",
  201: "",
  202: "",
  DEFAULT_SUCCESS: "Success",

  // Client errors;
  400: "Bad request",
  401: "Unauthorized access, invalid permissions",
  403: "Forbidden, invalid permissions",
  404: "Not found, invalid routing",
  408: "Request timeout",
  409: "Duplicate entry",
  DEFAULT_CLIENT_ERROR:
    "Client error, please try again with the correct credentials",

  // Server errors;
  500: "Internal server error",
  501: "Service not currently implemented",
  503: "Service currently unavailable, try again later",
  504: "Gateway timeout, try again later",
  DEFAULT_SERVER_ERROR: "Server error, please try again later",

  // Default message to display when given status code is not specified above;
  DEFAULT_ERROR: "Error occurred, please try again later"
};

// Utilities //

function getErrorResponse(statusCode = null, statusText = "") {
  console.log('statusCode',statusCode)
  /**
   * Retrieves the appropriate error response based on the status data given.
   *
   * @param {Number}	statusCode	The status code of the response.
   * @param {String}	statusText	Associated API error message with the status.
   *
   * @return {String}	Concatenated status' error message with description of status code.
   */

  if (statusCode === null || statusCode === undefined) {
    return HTTP_STATUS_CODES.DEFAULT_ERROR;
  }

  const statusResponse = HTTP_STATUS_CODES[statusCode],
    APIMessage = statusText !== "" ? " Error : " + statusText : "";

  if (typeof statusResponse === "string" && statusCode !== 409) {
    return statusResponse + APIMessage;
  }
  if (statusCode === 409 ) {
    return "Duplicate entry";
  } else if (statusCode < 500 && statusCode !== 409  ) {
    return HTTP_STATUS_CODES.DEFAULT_CLIENT_ERROR + APIMessage;
  } else if (statusCode < 600) {
    return HTTP_STATUS_CODES.DEFAULT_SERVER_ERROR + APIMessage;
  }

  return HTTP_STATUS_CODES.DEFAULT_ERROR + APIMessage;
}

// Method used to abstract axios call's error handling functionalities //

function handleAxiosCall(apiURL, _method = "get", toSend = {}) {
  /**
   * Makes an API call using axios.
   *
   * @param {String}	apiURL	URL of API to call.
   * @param {String}	_method	Type of HTTP request, i.e. 'get', 'put'
   * @param {Object}	toSend	Associated data to send with API call. Can contain 'headers', 'params' and 'data' objects.
   *
   * @return {Promise} A resolved or rejected promise of the API call, depending on the status of the request.
   */

  return new Promise((resolve, reject) => {
    let axiosBody = {
      method: _method.toLowerCase(),
      url: apiURL
    };

    const { headers, params, data } = toSend,
      requestConfig = { headers: headers, params: params, data: data };

    for (let requestType in requestConfig) {
      const request = requestConfig[requestType];

      if (typeof request !== "undefined" && request !== null) {
        axiosBody[requestType] = request;
      }
    }

    return axios(axiosBody)
      .then(rawResponse => {
        resolve(rawResponse);
      })
      .catch(error => {
        if (error.response === undefined || error.response === null) {
          reject(error);
        }

        const errResponse = error.response,
          APIError = errResponse.data;

        let status = errResponse.status,
          statusText = errResponse.statusText;

        if (status === 401) {
          ErrorListener.raise(401, new Error("Not authorised"));
        }
        // Some APIs may return an APIError object upon an error.
        // See: https://gitlab-ee.tssg.org/smarter-aquaculture/sa-utils/blob/master/lib/error.js
        if (APIError && APIError.statusCode && APIError.message) {
          status = APIError.statusCode;
          statusText = APIError.message;
          console.log("statusText", statusText);
        }

        reject(getErrorResponse(status, statusText));
      });
  });
}

// API Calls //

const MeterService = {
  serverURL: "/api",
  //  &from=" + dateFrom_iso + "&to=" + dateTo_iso
  getSemoPrice(dateFrom_iso, dateTo_iso) {
    /**
     * Gets a list of billables.
     */
    return handleAxiosCall(
      MeterService.serverURL + "/billable/semo/price",
      "get",
      {
        params: { from: dateFrom_iso, to: dateTo_iso }
      }
    );
  },

  getMeterList() {
    /**
     * Gets a list of meters.
     */
    return handleAxiosCall(MeterService.serverURL + "/meter", "get");
  },

  getAllMeters(_includeInactive = true) {
    /**
     * Gets a list of meters.
     *
     * @param {Boolean} _includeInactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(MeterService.serverURL + "/meter", "get", {
      params: { includeInactive: _includeInactive }
    });
  },

  addMeter(data) {
    /**
     * Add meters.
     *
     * @param {Object} data		Cluster details to use for update.
     */
    return handleAxiosCall(MeterService.serverURL + "/meter", "put", {
      data: data
    });
  },

  editMeter(id, data) {
    /**
     * Edit meters.
     * @param {ObjectId} id
     * @param {Object} data		Cluster details to use for update.
     */
    return handleAxiosCall(`${MeterService.serverURL}/meter/${id}`, "put", {
      data: data
    });
  },

  getBillableList() {
    /**
     * Gets a list of billables.
     */
    return handleAxiosCall(MeterService.serverURL + "/billable", "get");
  },

  addBillable(data) {
    /**
     * Add billable.
     *
     * @param {Object} data		Cluster details to use for update.
     */
    return handleAxiosCall(MeterService.serverURL + "/billable", "put", {
      data: data
    });
  },

  editBillable(id, data) {
    /**
     * Edit meters.
     * @param {ObjectId} id
     * @param {Object} data
     */
    return handleAxiosCall(`${MeterService.serverURL}/billable/${id}`, "put", {
      data: data
    });
  },

  addCharge(data, id) {
    /**
     * @param {ObjectId} id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff/charge`,
      "put",
      { data: data }
    );
  },

  getChargeList(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff/charge`,
      "get"
    );
  },

  editCharge(billable_id, charge_id, charge) {
    /**
     * @param {ObjectId} billable_id
     * @param {ObjectId} charge_id
     * @param {Object} charge
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/tariff/charge/${charge_id}`,
      "put",
      {
        data: charge
      }
    );
  },

  deleteCharge(billable_id, charge_id) {
    /**
     * @param {ObjectId} billable_id
     * @param {ObjectId} charge_id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/tariff/charge/${charge_id}`,
      "delete"
    );
  },

  getAllCharges(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff/charge?history`,
      "get"
    );
  },

  addTariff(data, id) {
    /**
     * @param {ObjectId} id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff`,
      "put",
      { data: data }
    );
  },

  deleteTariff(billable_id, tariff_id) {
    /**
     * @param {ObjectId} billable_id
     * @param {ObjectId} tariff_id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/tariff/${tariff_id}`,
      "delete"
    );
  },
  getTariffList(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff`,
      "get"
    );
  },

  getAllTariffs(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/tariff?history`,
      "get"
    );
  },

  //WHOLESALE
  addProfile(data, id) {
    /**
     * @param {ObjectId} id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/profile`,
      "put",
      { data: data }
    );
  },

  getProfileList(id) {
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/profile`,
      "get"
    );
  },

  getProfileHistory(id) {
    console.log("id", id);
    /**
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${id}/profile?history`,
      "get"
    );
  },

  // DELETE /api/billable/{id}/profile/{profile_id}

  deleteProfile(billable_id, profile_id) {
    /**
     * @param {ObjectId} billable_id
     * @param {ObjectId} profile_id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/profile/${profile_id}`,
      "delete"
    );
  },

  editProfile(data, profile_id, billable_id) {
    /**
     * @param {ObjectId} billable_id
     * @param {ObjectId} profile_id
     * @param {Object} data
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/profile/${profile_id}`,
      "put",
      {
        data: data
      }
    );
  },

  getRateChargesList(billable_id, profile_id) {
    /**
     * @param {ObjectId} profile_id
     * @param {ObjectId} billable_id
     */
    return handleAxiosCall(
      `${MeterService.serverURL}/billable/${billable_id}/profile/${profile_id}`,
      "get"
    );
  },

  findUniqueName(existing, __name, ndx=0) {
    const __nm=ndx>0?`${__name} (${ndx})`:__name
    const __matches = existing.filter(m => m.active && m.name===__nm)
    if(__matches.length>0) {
      return MeterService.findUniqueName(existing, __name, ndx+1)
    }
    return __nm
  },

  createMeter: async ({type=null, site=null, organisation=null, name, isVirtual=false, existing=[]}={}) => {
    // if(site===null) {
    //   throw new Error('Site required')
    // }

    let organisation_id, site_id
    if(site) {
      organisation_id = site.organisation_id
      site_id = site._id
    }
    else if(organisation) {
      organisation_id = organisation._id
    }
    else {
      throw new Error('Site required')
    }

    let meter_subtype="kilowatt-hour"
    switch(type) {
      case 'load':
        name=name || "Load meter"
        break ;
      case 'incomer':
        name=name || "Main incomer"
        break ;
      case 'supply':
        name=name || "Supply meter"
        break ;
      case 'spill':
        name=name || "Spill meter"
        break ;
      default:
        throw new Error(`Invalid type "${type}"`)
    }

    const meter_type = [type]
    if(isVirtual && type!=='virtual') {
      meter_type.push('virtual')
    }

    const meter={
      name: MeterService.findUniqueName(existing, name),
      active: true,
      organisation_id,
      site_id,
      meter_type,
      meter_subtype,
      valid_from: new Date().toISOString()
    }
    const data = meter

    const __meter = await request.call(`/api/meter`, 'put',  {data } )
  }
};

export default MeterService;
