import React, { Component } from "react";

import TopDropdown from "../../utility/TopDropdown";

import { Switch, Route } from "react-router-dom";
import Load from "./LoadGraph.jsx";
import Market from "./MarketGraph.jsx";
import Solar from "./SolarGraph.jsx";
import Battery from "./BatteryGraph.jsx";
import Wind from "./WindGraph.jsx";

import DateRangePicker from '../components/DateRangePicker.jsx'

export default class MenuModulesGraphing extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {
    this.props.getSemoPrice();


  };



  render() {
    const {
      view,
      //  user,
      viewList,
      currency,
      sites,
      actualLoad,
      actualCost,
      predictedLoad,
      predictedCost,
      actualSupply,
      predictedSupply,
      tariffs,
      charges,
      index_site,
      site_id,
      yName,
      members,
      __wholesale,
      loadReadingArray,
      loadPredictionArray,
      solarReadingArray,
      solarPredictionArray,
      startDate,
      endDate,
      timeline,
      loadParsed,
      predictedloadParsed,
      actualSupplyParsed,
      predictedSupplyParsed,
      differencesLoad,
      differencesSolar,
      r2Load, r2Solar,
      totalDifLoad,
      totalReadingLoad,
      totalPredictionLoad,
      totalPercLoad,
      totalDifSolar,
      totalReadingSolar,
      totalPredictionSolar,
      totalPercSolar,
      mean_sq_errorSolar,
      maseFSolar,
      mean_sq_errorLoad,
      maseFLoad,
      maxDate

    } = this.props;
    const panels = this.state.panels;
    //console.log('maxDate',maxDate)

    return (
      <div>
        <div className="headerHome">
          <div className="clearfix" />

          <div className="dropdown">
            <div className="row small-gutters large-bottom-margin">
              <div className="col-lg-12">
                <div className="form-inline">
                  <div className="form-group">
                    <TopDropdown
                      view={view}
                      viewList={viewList}
                      index_site={index_site}
                      sites={sites}
                      members={members}
                      onViewSelected={this.props.onViewSelected}
                      onOrgSelected={this.props.onOrgSelected}
                      onSiteSelected={this.props.onSiteSelected}
                    />
                    {
                      //
                      // 'P1M' is an ISO8601 duration specification (1 month) compatible with MomentJS
                      // Any valid duration can be used, see https://momentjs.com/docs/#/durations/as-iso-string/
                      //
                    }
                    <DateRangePicker start={startDate} end={endDate} maxDate={maxDate} maxRange="P1M"
                      onChange={(({startDate=null, endDate=null}) => {
                        if(startDate!==null) {
                          this.props.handleStartDateChange && this.props.handleStartDateChange(startDate)
                        }
                        if(endDate!==null) {
                          this.props.handleEndDateChange && this.props.handleEndDateChange(endDate)
                        }
                      })}
                      onEnter={(startDate, endDate) => {
                        this.setState({startDate, endDate})
                        this.props.enterDates && this.props.enterDates(startDate, endDate)
                      }}/>
                  </div>
                </div>
                <div>
                  <Switch>
                    <Route
                      path="/dashboard/modulesgraph/market"
                      render={props => (
                        <Market
                          {...props}
                          timeline={timeline}
                          currency={currency}
                          yName={yName}
                          predictedCost={predictedCost}
                          actualCost={actualCost}
                          tariffs={tariffs}
                          charges={charges}
                          __wholesale={__wholesale}
                        />
                      )}
                    />

                    <Route
                      path="/dashboard/modulesgraph/load"
                      render={props => (
                        <Load
                          {...props}
                          timeline={timeline}
                          predictedLoad={predictedLoad}
                          actualLoad={actualLoad}
                          yName={yName}
                          loadPredictionArray={loadPredictionArray}
                          loadReadingArray={loadReadingArray}
                          loadParsed={loadParsed}
                          predictedloadParsed={predictedloadParsed}
                          r2Load={r2Load}
                          differencesLoad={differencesLoad}
                          totalDifLoad={totalDifLoad}
                          totalReadingLoad={totalReadingLoad}
                          totalPredictionLoad={totalPredictionLoad}
                          totalPercLoad={totalPercLoad}
                          mean_sq_errorLoad={mean_sq_errorLoad}
                          maseFLoad={maseFLoad}

                        />
                      )}
                    />

                    <Route
                      path="/dashboard/modulesgraph/solar"
                      render={props => (
                        <Solar
                          {...props}
                          timeline={timeline}
                          predictedSupply={predictedSupply}
                          actualSupply={actualSupply}
                          yName={yName}
                          solarReadingArray={solarReadingArray}
                          solarPredictionArray={solarPredictionArray}
                          site_id={site_id}
                          panels={panels}
                          actualSupplyParsed={actualSupplyParsed}
                          predictedSupplyParsed={predictedSupplyParsed}
                          differencesSolar={differencesSolar}
                          r2Solar={r2Solar}
                          totalDifSolar={totalDifSolar}
                          totalReadingSolar={totalReadingSolar}
                          totalPredictionSolar={totalPredictionSolar}
                          totalPercSolar={totalPercSolar}
                          mean_sq_errorSolar={mean_sq_errorSolar}
                          maseFSolar={maseFSolar}

                        />
                      )}
                    />

                    <Route
                      path="/dashboard/modulesgraph/battery"
                      render={props => <Battery {...props} />}
                    />
                    <Route
                      path="/dashboard/modulesgraph/wind"
                      render={props => <Wind {...props} />}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
