import React, { useState, useEffect } from 'react'
import { Alert, Button, Modal, Container, Row, Col } from 'react-bootstrap'
import {Request} from "../../../services/HttpService";

const request = new Request({ service: 'Scenario service'})
const IS_EMPTY = /^\s*$/

function SaveAsDialog({data, onCancel, onSave, }) {
	const [error, setError] = useState(null)
	const [disabled, setDisabled] = useState(true)
	const [name, setName] = useState("")

	useEffect(() => {
		async function getUniqueName() {
			try {
				const nm=await request.call(`/api/scenario/solar/uniquename?name=${data.name}`)
				setName(n => nm.name)
				setDisabled(d => false)
			}
			catch(err) {
				setError(e=>err.message)
			}
		}

		getUniqueName() ;
		return () => {
			setError(e => null)
		}
	}, [data])

	function onChange(e) {
		const { value="" } = e.target ;
		if(value.length<2) {
			setError('Name must be at least 2 characters')
		}
		else {
			setError(null)
		}
		setName(value)
	}

	function handleCancel() {
		onCancel && onCancel() ;
	}

	async function handleSave() {
		const { description="", latitude, longitude, panels=[], billing_plan_id="", cluster_sales_plan_id="" } = data

		try {
			const __new = await request.call(`/api/scenario/solar`, 
				'put',
				{ 
					data : {
						name,
						description,
						location: [parseFloat(latitude), parseFloat(longitude)],
						billing_plan_id: IS_EMPTY.test(billing_plan_id)?undefined:billing_plan_id,
						cluster_sales_plan_id: IS_EMPTY.test(cluster_sales_plan_id)?undefined:cluster_sales_plan_id,
						panels: panels.map(({_id, ...rest}) => rest)
					}
				})
			onSave && onSave(__new) ;
		}
		catch(err) {
			setError(err.message)
		}
	}

	return (
		<Modal show={true}
      		aria-labelledby="contained-modal-title-vcenter"
      		centered>
			<Modal.Header closeButton>
				<Modal.Title>Save As</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{error!==null?(<Alert variant="danger">{error}</Alert>):null}
				<Container className="small-spacing" style={{marginLeft: "0px", marginRight: "0px"}}>
              		<Row>
              			<Col>
			                <label className="on">New Name</label>
			                <input
			                  className="form-control"
			                  type="text"
			                  autoFocus
			                  required
			                  disabled={disabled}
			                  name="name"
			                  id="name"
			                  onChange={onChange}
			                  placeholder="New name"
			                  value={name}
			                />
              			</Col>
              		</Row>
				</Container>
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={handleCancel} variant="outline-secondary">Cancel</Button>
				<Button onClick={handleSave} disabled={disabled && error===null} variant="re-primary">Save</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default SaveAsDialog
