import React, { Component } from "react";
import AuthService from "../../services/auth";
import ErrorListener from "../../services/ErrorListener";
import Loading from "../../LoadingPage";
import TopAdminNav from "../../admin/AdminNav";
//import TopNav from "../titlebar/Titlebar";
//import Sidebar from "../sidebar/Sidebar.jsx";
import Clusters from "./ClusterTable";
import Profile from "./Profile";
import Organisations from "./OrganisationSettingsLegacy";
import { Switch, Route } from "react-router-dom";

export default class TopAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      authenticated: false,
      redirect: null
    };
  }
  showNavbar = state => {
    this.setState({ active: state });
  };

  on401Error = err => {
    if (this.redirecting) return; // Already handled
    console.error(`(${this.redirecting}) Dashboard error handler `, err);
    AuthService.clearSession();
    this.redirecting = true;
    this.setState({ redirect: "/login" });
  };

  async componentDidMount() {
    let { authenticated, loading, redirect } = this.state;
    let user = null;
    console.log("TopAdmin::componentDidMount()");
    try {
      user = await AuthService.isAuthenticated();
      if (!user.roles.length) {
        redirect = "/login";
      } else if (!user.roles.includes("admin")) {
        redirect = "/dashboard";
      } else {
        authenticated = true;
        loading = false;
      }
    } catch (err) {
      authenticated = false;
      user = null;
      redirect = "/login";
    }
    this.setState({ loading, user, authenticated, redirect });
    ErrorListener.on(401, this.on401Error);
  }

  componentWillUnmount() {
    console.log("TopAdmin::componentWillUnmount()");
    ErrorListener.off(401, this.on401Error);
  }

  render() {
    const { authenticated, user, active } = this.state;
    if (!authenticated) return <Loading />;
    return (
      <div id="wrapper" className={active ? "active" : ""}>
        <header className="topbar-nav">
          <TopAdminNav
            navbarActive={active}
            user={user}
            showNavbar={this.showNavbar}
            {...this.props}
          />
        </header>

        <div class="top-admin">
          <Switch>
            <Route path="/topadmin/profile" component={Profile} />
            <Route path="/topadmin/clusters" component={Clusters} />
            <Route path="/topadmin/organisations" component={Organisations} />
            <Route component={Organisations} />
          </Switch>
        </div>
      </div>
    );
  }
}
