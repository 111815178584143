import React, { Component } from "react";
import  { notify } from "../../utility/ToastContainer";
import ClusterServices from "../../services/ClusterServices";
import Select from 'react-select';

const options = [
  { value: 'open', label: 'open' },
  { value: 'closed', label: 'closed' },
];

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (count = count+1)
  );
  return count;
}

export default class AddClusterForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      nameActive:false,
      name: '',cluster_id:'',type:'', active:true,
      selectedOption: this.props.status || 'open',
      formValid: false,
      errorCount: 0,
      errors: {
        name: '',
      },
    };
  }

  componentDidMount() {
    const { name, type, cluster_id, active, selectedStatus } = this.props;
    this.setState({ name:name, type:type, cluster_id:cluster_id, active:active, selectedOption:selectedStatus });
    setTimeout(() => {
      this.innerRef.current.focus();
    }, 1);
  }

  activateField=(e)=> {
    console.log();
    this.setState({
     [`${e.target.name}Active`]: true
    })
  }

  disableField=(e)=> {
    this.setState({
      [`${e.target.name}Active`]: false
   })
 }

 onChangeActive = () => {
    this.setState({
      active: !this.state.active,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  }

  handleChange = (selectedOption) => {
    let errors = this.state.errors;
    switch (selectedOption) {
      case 'select':
        errors.select =
          !selectedOption
            ? 'Select status!'
            : ''
        break;
        default:
          break;
        }
     this.setState({errors, selectedOption });
     this.setState({formValid: validateForm(this.state.errors)});
     this.setState({errorCount: countErrors(this.state.errors)});

  };

  onChangeName = e => {
    //console.log(event.target.value)
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;

    if (e.target.value === "") {
      this.disableField(e);
   } else {
      this.activateField(e);
   }
    //console.log('name', name)
    switch (name) {
      case 'name':
        errors.name =
          value.length < 3
            ? 'Name must be 3 characters long!'
            : '';
        break;
      default:
      break;
    }
    this.setState({
      errors,
      [name]: value,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
    this.props.getClusterList();
  };

  addCluster = (event, errors, values) => {
    event.preventDefault();
    const cluster_id = this.state.cluster_id;
    const type = this.state.type;
    const selectedOption = this.state.selectedOption;
    const status = selectedOption.value;
    //console.log('status', status)
    let cls = {
       name: this.state.name,
       active:JSON.parse(this.state.active),
       status:status
    };
  //  console.log('cls', cls)
    if (type === "edit") {
      return ClusterServices.editCluster(cluster_id, cls)
        .then(response => {
          this.props.closeAddCluster(
            "success",
            "Cluster is updated successfully."
          );
          //this.closeForm();
        })
        .catch(err => {
          this.props.closeAddCluster("danger", err);
        });
    } else if(type === "add") {
      return ClusterServices.addCluster(cls)
        .then(response => {
          if (response.data) {
              this.props.closeAddCluster(
              "success",
              "Cluster is created successfully."
            );
            //this.closeForm();
          } else {
          this.props.closeAddCluster("danger", "Cluster name already exists");
          };
        })
        .catch(err => {
          this.props.closeAddCluster("danger", "Cluster not created");
        });
    } else {
      console.log('error')
    }

  };

  closeForm = event => {
    if (this.props.closeAddCluster) {
      this.props.closeAddCluster();
    }
  };


  render() {
    const {errors, formValid, errorCount, name, selectedOption } = this.state;
    const type = this.props.type;
    //console.log(type)
    var active
    if (type === 'edit'){
      active = this.props.active;
    } else {
      active = this.state.active;
    }

    var nameActive
    if (type === 'edit'){
      nameActive = true;
    } else {
      nameActive = this.state.nameActive;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addCluster}>
            <div className="form-group">
            <div>
              <div className="labelField">
                <label  className={nameActive ? "on" : ""}>
                Name
                </label>
              <input
                className="form-control"
                type="text"
                required
                name="name"
                id="name"
                onChange={this.onChangeName}
                placeholder="Enter Cluster Name"
                value={name || ""}
                ref={this.innerRef}
              />
              {errors.name.length > 0 &&
                <span className='error'>{errors.name}</span>}
              </div>
            </div>
              <div>
              <div className="labelField">
              <label  className="on">
              Status
              </label>
              <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  placeholder={'Select Status'}
                  name="select"
                  inputId="select"
                />
              </div>
              </div>
              <br/>
              <div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="active"
                id="active"
                onChange={this.onChangeActive}
                placeholder="Enter Dataset Name"
                value={active}
                defaultChecked={active}
              /></label>
              </div>
              <div className="invalid-feedback">Please provide a valid name.</div>
            </div>
            <div className="form-group">
              <button
                disabled = {formValid===false || errorCount !== 0 }
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0
              ? <p className="form-status">Form is {formValid===false || errorCount !== 0 ? 'invalid ❌' :'valid ✅' }</p>
              : 'Form not submitted'}
          </form>
        </div>
      </div>

    );
  }
}
