import React, { Component } from "react";
//import { Button } from "react-bootstrap";
import  { notify } from "../../utility/ToastContainer";
import UserServices from "../../services/UserServices";
import Select from 'react-select';


const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (count = count+1)
  );
  return count;
}

export default class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      fieldActive:false,
      nameActive: false, lastnameActive:false, emailActive:false, passwordActive:false, selectActive:false,
      name: '', lastname:'', email: '', password:'',
      active:true,
      type:'add',
      show:this.props.show ,
      selectedOption: this.props.selectedRoles || null,
      formValid: false,
      errorCount: 0,
      errors: {
        name: '',
        lastname:'',
        email: '',
        password: '',
        select:''
      },
    };
  }

  componentDidMount() {

    setTimeout(() => {
      const { firstname, lastname,  email, selectedRoles, type, active, options } = this.props;
      //console.log('firstname, lastname,  email, active', firstname, lastname,  email, active, selectedRoles, type)
     this.setState({ name:firstname, email:email, lastname:lastname, selectedOption:selectedRoles, type:type, active:active, options:options });
       this.innerRef.current.focus();
    }, 1);

  }

  activateField=(e)=> {
    console.log();
    this.setState({
     [`${e.target.name}Active`]: true
    })
  }

  disableField=(e)=> {
    this.setState({
      [`${e.target.name}Active`]: false
   })
 }

  onChangeActive = () => {

    this.setState({
      active: !this.state.active,
    });
    //console.log('active', this.state.active)
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  }

  handleChange = ( selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    var errors = this.state.errors;
    if (selectedOption){
      var arrayRoles =  selectedOption.map(a => a.value)
      var adminRole =arrayRoles.includes('admin')
      console.log(`length`, arrayRoles.length);
      if (adminRole=== true && arrayRoles.length>1){
        errors.select = 'Admin can not have other roles!'
      } else {
          errors.select = ''
      }
    } else {
        errors.select = ''
    }


    if (selectedOption) {
      this.setState({selectActive: true});
    } else {
      this.setState({selectActive: false});
    }

     this.setState({  errors, selectedOption });
     this.setState({formValid: validateForm(this.state.errors)});
     this.setState({errorCount: countErrors(this.state.errors)});

  };

  closeForm = event => {
    const orgId = this.props.orgId;
    //console.log('add user', orgId)
    this.props.closeForm(orgId);
    this.props.getUserList();
  };

  changeHandler = e => {
    if (e.target.value === "") {
      this.disableField(e);
   } else {
      this.activateField(e);
   }
    if (e.target.value !== '') {
      this.setState({fieldActive: true});
    } else {
      this.setState({fieldActive: false});
    }
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name =
          value.length < 2
            ? 'Name must be 2 characters long!'
            : '';
        break;
      case 'lastname':
          errors.lastname =
            value.length < 2
              ? 'Surname must be 2 characters long!'
              : '';
          break;
      case 'email':
        errors.email =
          (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
            ? 'Email is not valid!'
            : '';
        break;
      case 'password':
        errors.password =
          value.length < 8
            ? 'Password must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({
      errors,
      [name]: value,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);

  };

  addUser = (event, errors, values) => {
    event.preventDefault();
    const type = this.props.type;
    //console.log('type', type)
    const orgId = this.props.orgId;
    const selectedOption = this.state.selectedOption;
    const roles = selectedOption.map(a => a.value)
    const userId = this.props.userId;
    const active = this.state.active;
    //console.log('userId', userId)
    //console.log('addd user active', active)
    const user = {
       firstname: this.state.name,
       lastname: this.state.lastname,
       email: this.state.email,
       password: this.state.password,
       roles:roles,
       active:active,
       orgId:orgId
    };
    //console.log('addd user ', user)

    if (selectedOption === null){
      alert(' Enter role(s)')
    } else if (type === 'edit'){
      //console.log('edit', user)
      return UserServices.editUser(userId, user)
        .then(response => {
          if (response.data) {
            this.props.closeForm("success", "User is updated successfully.");
            //this.closeForm();
            this.setState({ name:'', email:'', lastname:'', selectedOption:null, type:'' });
          } else {
            // Conflict error found;
            this.props.closeForm("danger", "User email already exists");
          };
        })
        //.then(this.props.getClusterList())
        .catch((err, code, message, statusCode) => {
          this.props.closeForm("danger", err);
          console.log('error', err)
        });
    } else if (type==='new'){
      //console.log('add user', user)
        return UserServices.addUser(user)
          .then(response => {
            if (response.data) {
                this.props.closeForm("success","User is created successfully."
              );
              this.setState({ name:'', email:'', lastname:'', selectedOption:null, type:'', password:'' });
            } else {
              // Conflict error found;
              this.props.closeForm("danger","User email already exists");
            };
          })
          //.then(this.props.getClusterList())
          .catch((err) => {
            this.props.closeForm("danger", err);
            console.log('error', err)
          });

    } else {
      alert('error')
    }
  };


  render() {
    const {selectedOption, errors, formValid, errorCount, name, lastname, email, password} = this.state;
    const options = this.props.options;
    const type = this.props.type;
    var active
    if (type === 'edit'){
      active = this.props.active;
    } else {
      active = this.state.active;
    }

    var nameActive, lastnameActive, emailActive, passwordActive, selectActive
    if (type === 'edit'){
      nameActive = lastnameActive= emailActive= passwordActive= selectActive = true;
    } else {
      nameActive = this.state.nameActive;
      lastnameActive= this.state.lastnameActive;
      emailActive= this.state.emailActive;
      passwordActive = this.state.passwordActive;
      selectActive  = this.state.selectActive;

    }
    return (
      <div>
        <div>
          <form onSubmit={this.addUser}>
            <div className="form-group">
            <div className="labelField">
            <label  className={nameActive ? "on" : ""}>
             First Name
            </label>
              <input
                className="form-control"
                type="text"
                required
                name="name"
                id="firstname"
                onChange={this.changeHandler}
                placeholder="Enter User First Name"
                value={name || ""}
                ref={this.innerRef}
              />
              {errors.name.length > 0 &&
                <span className='error'>{errors.name}</span>}
              </div>
              <div  className="labelField">
              <label  className={lastnameActive ? "on" : ""}>
               Last Name
              </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="lastname"
                  id="lastname"
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Enter User Last Name"
                  value={lastname|| ""}
                />
                {errors.lastname.length > 0 &&
                  <span className='error'>{errors.lastname}</span>}
                </div>
            <div  className="labelField">
              <label  className={selectActive ? "on" : ""}>
                Select Role(s)
              </label>
              <Select
                  className="basic-single"
                  classNamePrefix="select"
                  name="select"
                  id="select"
                  options={options}
                  value={selectedOption}
                  isClearable
                  onChange={this.handleChange}
                  placeholder='Select Role(s)'
                  inputId="select"
                  isMulti
                />
                {errors.select.length > 0 &&
                <span className='error'>{errors.select}</span>}
              </div>
                {this.state.show && <div>
                  <div className="labelField">
                    <label className={emailActive ? "on" : ""}>
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      required
                      name="email"
                      id="email"
                      onChange={this.changeHandler}
                      onFocus={this.activateField}
                      onBlur={this.disableFocus}
                      placeholder="Enter User Email"
                      value={email|| ""}
                      noValidate
                    />
                    {errors.email.length > 0 &&
                    <span className='error'>{errors.email}</span>}
                    </div>
                  <div className="labelField">
                    <label  className={passwordActive ? "on" : ""}>
                      Password
                    </label>
                    <input
                      className="form-control"
                      required
                      type="password"
                      name="password"
                      id="password"
                      onChange={this.changeHandler}
                      onFocus={this.activateField}
                      onBlur={this.disableFocus}
                      placeholder="Enter User Password"
                      value={password|| ""}
                    />
                    {errors.password.length > 0 &&
                    <span className='error'>{errors.password}</span>}
                    </div>
                 </div>}
                 <br/>
              <div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="active"
                id="active"
                onChange={this.onChangeActive}
                value={active}
                defaultChecked={active}
              /></label>
              </div>
            </div>
            <div className="form-group ">
              <button
                disabled = {formValid===false || errorCount !== 0 || !selectedOption}
                type="submit"
                className="submit btn-round">
                {type === "edit" ? "Update" : "Create"}
              </button>

            </div>
            {errorCount !== 0
              ? <p className="form-status">Form is {formValid===false || errorCount !== 0 || !selectedOption? 'invalid ❌' :'valid ✅' }</p>
              : 'Form not submitted'}

          </form>
        </div>
      </div>

    );
  }
}
