import React from 'react'
import moment from 'moment'

class Currency {
    constructor({key, name, symbol, minorSymbol}) {
        this.key=key
        this.name=name
        this.symbol=symbol
        this.minorSymbol=minorSymbol
    }

    toOption() {
        return (<option key={this.key} value={this.key}>{this.name} ({this.symbol})</option>)
    }

    toString() {
    	return `${this.name} (${this.symbol})`
    }

}

export const DEFAULT_CURRENCY='EUR'

const CURRENCIES = new Map([
    { key: 'EUR', name: 'Euro', symbol: '€', minorSymbol: 'c'},
    { key: 'GBP', name: 'British Pound', symbol: '£', minorSymbol: 'p'},
    { key: 'USD', name: 'U.S. Dollar',  symbol: '$', minorSymbol: '¢'}
].map(c => ([c.key, new Currency(c)])))

const NO_CURRENCY = new Currency({key: '<none>', name: '<unknown>', symbol: '?', minorSymbol: '?'})

export const Currencies = {
    toArray: () => [...CURRENCIES.entries()],
    toOptions: () => [...CURRENCIES.values()].map(c => c.toOption()),
    get: key => {
        const c=CURRENCIES.get(key)
        if(c) return c
        console.error(`No such currency "${key}"`)
        return NO_CURRENCY
    }
}

export function friendlyTimeFormatter() {
    const __today= moment().startOf('day'),
        __yesterday = moment(__today).subtract(1, 'day'),
        __lastWeek = moment(__today).subtract(7, 'days'),
        __thisYear = moment().startOf('year')

    return {
        format : ts => {
            const __ts = moment(ts)
            let __modified
            if(__ts.isSameOrAfter(__today)) {
                __modified=__ts.format('hh:mma')
            }
            else if(__ts.isSameOrAfter(__yesterday)) {
                __modified='Yesterday'
            }
            else if(__ts.isSameOrAfter(__lastWeek)) {
                __modified=__ts.format('dddd')
            }
            else if(__ts.isSameOrAfter(__thisYear)) {
                __modified=__ts.format('MMM D')
            }
            else {
                __modified=__ts.format('DD/MM/YYYY')
            }
            
            return (<span>{__modified}</span>)
        }
    }
}

export const isID = id => /^[a-fA-F0-9]{24}$/.test(id)
