import React, {useState, useEffect, useContext } from 'react'
import { Link, useLocation } from "react-router-dom"
import BootstrapTable from 'react-bootstrap-table-next';
import {Notify} from '../../components/AlertListener'
import UserContext from '../../../services/user-context'

import CacheResources from './cache-resources'

const notify=Notify("site.notifications")
const Cache = new CacheResources()

export default function SiteRenewables({site=null, renewables=null}) {
    const [isSiteOwner, setSiteOwner] = useState(false)

    const location=useLocation()
    const { user } = useContext(UserContext)

    function formatName(name, {panels=null}) {
        return (
            <>
                <span>{name}</span>
                {
                    panels!==null?
                        (<span> - {panels.reduce((t,{capacity}) => t+capacity, 0)}kW</span>):
                        null
                }
            </>
        )
    }

    const columns=[{
        dataField: '_id',
        hidden: true,
    }, {
        dataField: 'name',
        text: 'Name',
        formatter: formatName
    }]

    const expandRow = {
        renderer: row => (
            <div style={{paddingLeft: "2rem"}}>
                {Array.isArray(row.panels) && row.panels.length>0?(
                    <>
                        <h5>Panels</h5>
                        <ul>
                            {row.panels.map(p => (
                                <li key={`pnl_${p._id}`}>{p.name} - Capacity={p.capacity}kW, azimuth={p.azimuth}°, tilt={p.tilt}°</li>
                            ))}
                        </ul>
                    </>
                ):(<div>No panels defined</div>)}
            </div>
        ),
        showExpandColumn: true
    }

    useEffect(() => {
        setSiteOwner(site.organisation_id === user.organisationId)
    }, [user, site])

    if(renewables===null || (!isSiteOwner && renewables.length===0)) return null

    return (
        <>
            <h5>Renewables</h5>
            {renewables.length>0?(
                <BootstrapTable
                    bootstrap4 striped hover condensed 
                    version="4"
                    keyField='_id' 
                    data={renewables}
                    columns={columns}
                    expandRow={expandRow}
                    noDataIndication="No renewables found"/>
            ):isSiteOwner && (
            <div><span><em>No renewables set up </em> </span><Link to={
                {
                    pathname: "/dashboard/settings/renewables/new",
                    state: {
                        from: location.pathname
                }}}>Add renewable</Link> </div>
            )}
        </>
    )

}