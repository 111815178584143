import React, { useState, useEffect, useRef, useCallback } from "react";
import imgCoins from "../../../../assets/svg/coins.svg"

import { textWidth } from './utils'

export default function RevenueWidget({amount: __amount=null, units='€'}) {
    const [amount, setAmount] = useState('--')
    const [style, setStyle] = useState({})
    const [node, setNode] = useState(null)

    useEffect(() => {
        if(__amount!==null && node!==null) {
            let amount = parseFloat(__amount) 
            if(!isNaN(amount)) {
                // 999.5 as when rounded, this will be 1000 - so no minor denomination
                if(amount>=999.5) {
                    // Precision to nearest €/£/... when over 1000
                    amount=Math.round(amount)
                }
                else {
                    amount=amount.toFixed(2)
                }
                setAmount(amount)
                setStyle({fontSize: textWidth(node, amount, units)})
            }
        }
    }, [__amount, units, node])

    function useRefCB() {
        const ref = useRef(null)
        const setRef = useCallback(node => {
          if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
          }
          
          if (node) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
          }
          
          // Save a reference to the node
          ref.current = node
          setNode(ref.current || null)
        }, [])
        
        return [setRef]
    }
    const [__contentRef] = useRefCB(null)

    return (
        <div className="component-card mini-card load">
            <img alt={"coins"} src={imgCoins} />
            <h5>Electricity Cost</h5>
            <div ref={__contentRef} className="component-data">
                <p className="main-data" style={style}>
                    <span id="__units" style={{fontSize: "0.5em"}} className="__units">{units}</span>
                    <span className="__value" style={{fontSize: "inherit", paddingRight: "0.4rem"}}>{amount}</span>
                </p>
            </div>
        </div>
    )
}