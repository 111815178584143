/** Deprecated */
import React, { Component } from "react";
import LoadWidget from "./widgets/load.jsx";
//import TotalLoadWidget from "./widgets/totalLoad.jsx";
import MarketWidget from "./widgets/market.jsx";
import SavingsWidget from "./widgets/savings.jsx";
import SolarWidget from "./widgets/solar.jsx";
import AuthService from "../services/auth"
import OrganisationMeters from "./settings/organisation/OrganisationMeters"
import {Request} from "../services/HttpService";
//import BatteryWidget from "./widgets/battery.jsx";
//import WindWidget from "./widgets/wind.jsx";
import DashboardGraphs, {
  CURRENCY_MAJOR,
  CURRENCY_MINOR,
  LOAD
} from "./DashboardGraphs.jsx";

//import Dropdown from "./../utility/Dropdown2";
import TopDropdown from "./../utility/TopDropdown";

const request = new Request({ service: 'Dashboard service'})

export default class DashboardContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //sites
      view: this.props.view,
      organisation: null
    };
  }

  componentDidMount = async (view = "Organisation") => {
    //const { viee } = this.state;
    this.props.getSemoPrice();
    //console.log("View", view);
    // Load the user to get the organisation
    try {
      const __user = await AuthService.isAuthenticated()
      try {
        const organisation = await request.get(`/api/organisation/${__user.organisationId}`)
        this.setState({organisation})
      }
      catch(err) {
          console.error(err)
          this.setState({organisation: null})
      }

    }
    catch(err) {
      console.error(err)
      this.setState({organisation: null})
    }
  };

  /////////////////
  render() {
    const {
      view,
      viewList,
      index_site,
      sites,
      members,
      actualLoad,
      actualCost,
      predictedLoad,
      predictedCost,
      actualSupply,
      predictedSupply,
      tariffs,
      charges,
      currency,
      yName,
      __wholesale,
      price_kwh = null,
      extra_charges = 5,
      //widgets values
      totalLoad,
      widgetLoad,
      widgetSolar,
      totalSolar,
      user
    } = this.props;

    const { organisation } = this.state

    // const __wholesale = view === "Cluster" ? wholesale_price : null;

    return (
      <div>
        <div>
          <div className="dropdown">
            <div className="row small-gutters large-bottom-margin">
              <div className="col-lg-10">
                <TopDropdown
                  view={view}
                  viewList={viewList}
                  index_site={index_site}
                  sites={sites}
                  members={members}
                  onViewSelected={this.props.onViewSelected}
                  onOrgSelected={this.props.onOrgSelected}
                  onSiteSelected={this.props.onSiteSelected}
                />

                {view==='Organisation' && organisation!==null?
                (<OrganisationMeters organisation={organisation} redirect notable/>)
                :null}

                <div>
                  <DashboardGraphs
                    // price={priceLabel}
                    yName={yName}
                    currency={currency}
                    axes={{ left: [CURRENCY_MINOR], right: [CURRENCY_MAJOR] }}
                    axes2={{ left: [LOAD] }}
                    series={[
                      predictedCost,
                      tariffs,
                      charges,
                      actualCost,
                      __wholesale
                    ]}
                    series2={[
                      predictedSupply,
                      predictedLoad,
                      actualLoad,
                      actualSupply
                    ]}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="component-container">
                  <SavingsWidget />
                  <MarketWidget
                    price_kwh={price_kwh}
                    extra_charges={extra_charges}
                  />
                  <LoadWidget widgetLoad={widgetLoad} totalLoad={totalLoad} />
                  <SolarWidget
                    widgetSolar={widgetSolar}
                    totalSolar={totalSolar}
                  />
                  {/*  <WindWidget />
                  <BatteryWidget />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
