import React, { Component } from "react";
import HttpServiceSettings from "../../services/HttpServiceSettings";
import MeterServices from "../../services/MeterServices";
import Select from "react-select";
import {  Alert } from "react-bootstrap";

const options = [
  { value: "incomer", label: "incomer" },
  { value: "load", label: "load" },
  { value: "supply", label: "supply" },
  { value: "spill", label: "spill" },
  { value: "virtual", label: "virtual" },
  { value: "organisation", label: "organisation" },
  { value: "cluster", label: "cluster" },
];

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export default class AddMeterForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      nameActive: false,
      descriptionActive: false,
      typesActive: false,
      orgId: "",
      name: "",
      site_id: "",
      type: "",
      description: "",
      parent_id: "",
      types: [],
      active: false,
      show: this.props.show,
      selectedOption: this.props.selectedTypes || null,
      activeMeter: true,
      formValid: false,
      errorCount: 0,
      errors: {
        name: "",
        description: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const {
        orgId,
        name,
        site_id,
        type,
        description,
        parent_id,
        types,
        activeMeter,
        show,
        selectedTypes
      } = this.props;
      //console.log('site_id componentsdid mount', site_id)
      this.setState({
        orgId: orgId,
        site_id: site_id,
        parent_id: parent_id,
        types: types,
        activeMeter: activeMeter,
        show: show,
        name: name,
        description: description,
        selectedOption: selectedTypes,
        type: type
      });
      this.innerRef.current.focus();
    }, 1);
  }

  activateField = e => {
    //console.log();
    this.setState({
      [`${e.target.name}Active`]: true
    });
  };

  disableField = e => {
    this.setState({
      [`${e.target.name}Active`]: false
    });
  };

  onChangeActive = () => {
    this.setState({
      activeMeter: !this.state.activeMeter
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleChange = selectedOption => {
    console.log('selectedOption : ', selectedOption)
    if (selectedOption && selectedOption.length) {
      this.setState({ typesActive: true });
    } else {
      this.setState({ typesActive: false });
    }
    let errors = this.state.errors;
    switch (selectedOption) {
      case "select":
        errors.select = !selectedOption ? "Select type!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, selectedOption });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  closeForm = event => {
    this.props.closeForm();
  };

  changeHandler = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    if (e.target.value === "") {
      this.disableField(e);
    } else {
      this.activateField(e);
    }
    switch (name) {
      case "name":
        errors.name =
          value.length > 20 || value.length < 3
            ? "Name must be between 3-20 characters long!"
            : "";
        break;
      case "description":
        errors.description =
          value.length > 20 || value.length < 3
            ? "Description must be between 3-20 characters long!"
            : "";
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [name]: value
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  // sendStatusNotification = (type, msg) => {
  //   let className =
  //     type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
  //   let toastMsg = {
  //     msg: msg,
  //     className: className
  //   };
  //   notify(toastMsg);
  // };

  addMeter = (event, errors, values) => {
    event.preventDefault();
    const type = this.props.type;
    const orgId = this.props.orgId;
    //console.log('orgId', orgId)
    const selectedOption = this.state.selectedOption;
    const types = selectedOption.map(a => a.value);
    const meter_id = this.props.meter_id;
    const site_id = this.props.site_id;
    //console.log('site_id add meter', site_id)
    //const parent_id = this.state.parent_id;
    const name = this.state.name;
    const description = this.state.description;
    const meter = {
      name: name,
      description: description,
      meter_type: types,
      active: JSON.parse(this.state.activeMeter),
      organisation_id: orgId,
      site_id: site_id
    };
    console.log("meter", meter);

    if (selectedOption === null) {
      alert(" Enter role(s)");
    } else if (type === "edit") {
      //console.log('edit', meter)
      return (
        HttpServiceSettings.editMeter(meter_id, meter)
          .then(response => {
            if (response.data) {
              this.props.closeForm("success", "Meter is updated successfully.");
              //this.closeForm();
              this.setState({
                name: "",
                description: "",
                selectedOption: null,
                type: ""
              });
            } else {
              // Conflict error found;
              this.props.closeForm("danger", "Meter already exists");
            }
          })
          //.then(this.props.getClusterList())
          .catch((err, code, message, statusCode) => {
            this.showError("danger", err);
            //this.props.closeForm("danger", err);
            console.log("error", err);
          })
      );
    } else if (type === "add") {
      //console.log('add user', meter)
      return MeterServices.addMeter(meter)
        .then(response => {
          console.log("response", response.data);
          if (response.data) {
            this.props.closeForm("success", "Meter is created successfully.");
            //this.closeForm();
            //window.location.reload();
            this.setState({
              name: "",
              description: "",
              selectedOption: null,
              type: ""
            });
          } else {
            console.log("error1");
            // Conflict error found;
            this.showError("danger", "Meter already exists");
            //this.props.closeForm("danger", "Meter already exists");
          }
        })
        .catch(err => {
          console.log("error2");
          //this.props.closeForm("danger", err);
          this.showError("danger", err);
          console.log("error", err);
        });
    } else {
      alert("error");
    }
  };

  showError = (variant, msg) => {
    this.setState({ variant:variant, msg:msg  });
    this.onShowAlert();
  };

  setShow=()=>{
    this.setState({ show:false });
  }


  onShowAlert = ()=>{
    //console.log('onShowAlert')
    this.setState({show:true},()=>{
      window.setTimeout(()=>{
      this.setState({show:false})
        },3000)
      });
    }

  render() {
    const {
      selectedOption,
      formValid,
      errorCount,
      name,
      errors,
      description,
      show, variant, msg
    } = this.state;
    const type = this.props.type;
    var nameActive, descriptionActive, typesActive, activeMeter;
    if (type === "edit") {
      nameActive = descriptionActive = typesActive = true;
      activeMeter = this.props.activeMeter;
    } else {
      nameActive = this.state.nameActive;
      descriptionActive = this.state.descriptionActive;
      typesActive = this.state.typesActive;
      activeMeter = this.state.activeMeter;
    }

    return (
      <div>
      { show===true
        ?(  <Alert variant={variant} onClose={() => this.setShow()} dismissible>
            <Alert.Heading>{msg}</Alert.Heading>
          </Alert>)
          :<div></div>
     }
        <div>
          <form onSubmit={this.addMeter}>
            <div className="form-group">
              <div className="labelField">
                <label className={nameActive ? "on" : ""}>Name</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="name"
                  id="name"
                  onChange={this.changeHandler}
                  placeholder="Enter Meter Name"
                  value={name || ""}
                  ref={this.innerRef}
                />
                {errors.name.length > 0 && (
                  <span className="error">{errors.name}</span>
                )}
              </div>
              <div className="labelField">
                <label className={descriptionActive ? "on" : ""}>
                  Description
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="description"
                  id="description"
                  onChange={this.changeHandler}
                  placeholder="Enter Description"
                  value={description || ""}
                />
                {errors.description.length > 0 && (
                  <span className="error">{errors.description}</span>
                )}
              </div>
              <div className="labelField">
                <label className={typesActive ? "on" : ""}>
                  Select Type(s)
                </label>
                <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  placeholder={"Select Type(s)"}
                  name="select"
                  inputId="select"
                  isMulti
                />
              </div>
              <br />
              <div>
                <label htmlFor="active">
                  Active &nbsp;
                  <input
                    className="form-group text-right"
                    type="checkbox"
                    name="active"
                    id="active"
                    onChange={this.onChangeActive}
                    value={activeMeter}
                    defaultChecked={activeMeter}
                  />
                </label>
              </div>
            </div>
            <div className="form-group ">
              <button
                disabled={
                  formValid === false || errorCount !== 0 || !selectedOption
                }
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false || errorCount !== 0 || !selectedOption
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
