
import axios from "axios";
import ErrorListener from "./ErrorListener"

const HTTP_STATUS_CODES = {
	// Retrieved from: https://www.restapitutorial.com/httpstatuscodes.html
	// STATUS_CODE: response_msg

	// Redundant, success responses;
	200: '',
	201: '',
	202: '',
	204: '',
	DEFAULT_SUCCESS: 'Success',

	// Client errors;
	400: 'Bad request',
	401: 'Unauthorized access, invalid permissions',
	403: 'Forbidden, invalid permissions',
	404: 'Not found, invalid routing',
	408: 'Request timeout',
	409: 'Duplicate entry',
	DEFAULT_CLIENT_ERROR: 'Client error, please try again with the correct credentials',

	// Server errors;
	500: 'Internal server error',
	501: 'Service not currently implemented',
    502: 'Service currently unavailable, try again later',
	503: 'Service currently unavailable, try again later',
	504: 'Gateway timeout, try again later',
	DEFAULT_SERVER_ERROR: 'Server error, please try again later. ',

	// Default message to display when given status code is not specified above;
	DEFAULT_ERROR: ' Error occurred, please try again later'
};

class RESTError extends Error {
    constructor(status, message, detail) {
        super(message)
        this.status = status
        this.detail = detail
    }

    get errorClass() {
        return `__err${this.status}`
    }
}
// Utilities //

function getErrorResponse(statusCode = null, statusText = '') {
	/**
	 * Retrieves the appropriate error response based on the status data given.
	 *
	 * @param {Number}	statusCode	The status code of the response.
	 * @param {String}	statusText	Associated API error message with the status.
	 *
	 * @return {String}	Concatenated status' error message with description of status code.
	 */

	if (statusCode === null || statusCode === undefined) {
		return HTTP_STATUS_CODES.DEFAULT_ERROR;
	};

	const statusResponse = HTTP_STATUS_CODES[statusCode],
		  APIMessage = statusText;

	if (typeof statusResponse === 'string') {
		return statusResponse + APIMessage;
	};

	if (statusCode < 500) {
		return HTTP_STATUS_CODES.DEFAULT_CLIENT_ERROR + APIMessage;
	} else if (statusCode < 600) {
		return HTTP_STATUS_CODES.DEFAULT_SERVER_ERROR + APIMessage;
	};

	return HTTP_STATUS_CODES.DEFAULT_ERROR + APIMessage;
};

// Method used to abstract axios call's error handling functionalities //

function handleAxiosCall(apiURL, _method = 'get', toSend = {}) {
	/**
	 * Makes an API call using axios.
	 *
	 * @param {String}	apiURL	URL of API to call.
	 * @param {String}	_method	Type of HTTP request, i.e. 'get', 'put'
	 * @param {Object}	toSend	Associated data to send with API call. Can contain 'headers', 'params' and 'data' objects.
	 *
	 * @return {Promise} A resolved or rejected promise of the API call, depending on the status of the request.
	 */

	return new Promise((resolve, reject) => {

		let axiosBody = {
			method: _method.toLowerCase(),
			url: apiURL
		};

		const { headers, params, data } = toSend,
				  requestConfig = { headers: headers, params: params, data: data };

		for (let requestType in requestConfig) {
			const request = requestConfig[requestType];

			if (typeof(request) !== 'undefined' && request !== null) {
				axiosBody[requestType] = request;
			};
		};

		return axios(axiosBody)
		.then(rawResponse => {

			resolve(rawResponse);
		})
		.catch(error => {
            console.log('error : ', error)
			if (error.response === undefined || error.response === null) {
				reject(error);
			};

			const errResponse = error.response,
				  APIError = errResponse.data;

			let status = errResponse.status,
				statusText = errResponse.statusText;

            if(status === 401) {
                ErrorListener.raise(401, new Error('Not authorised'))
            }
			// Some APIs may return an APIError object upon an error.
			// See: https://gitlab-ee.tssg.org/smarter-aquaculture/sa-utils/blob/master/lib/error.js
			if (APIError && APIError.statusCode && APIError.message) {
				status = APIError.statusCode;
				statusText = APIError.message.message;
			};

            console.log(`Rejecting ${status}, text=${statusText}`)
            reject(getErrorResponse(status, statusText));
		});
	});
};

// API Calls //

class Request {
    constructor(props) {
        const { service="service" }=props ;
        this.service = service ;
    }

    async call(apiURL, _method = 'get', toSend = {}) {
        const { headers, params, data } = toSend,
            axiosBody = {
                method: _method.toLowerCase(),
                url: apiURL,
                headers,
                params,
                data
            };

        try {
            const response = await axios(axiosBody)
            return response.data

        }
        catch(err) {
            const {response}=err ;
            if (response === undefined || response === null) {
                throw(err);
            };

            let {status, statusText, data: __error={}} = response ;

            switch(status) {
                case 401:
                    ErrorListener.raise(401, new Error('Not authorised'))
                    return ;
                case 502:
                    throw new RESTError(502, `"${this.service}" not available. Please try again later`)
                default:
                    // Is it an APIError?
                    let {statusCode, code, message=null, detail=null} = __error
                    if (statusCode && code && (message!==null || detail!==null)) {
                        if(code==='Validation.Error') {
                            if(detail && Array.isArray(detail.errors)) {
                                message = detail.errors.map(({code: __code, message, ...__e}) => {
                                    let __msg
                                    switch(__code) {
                                        case "Param.Required":
                                            __msg = `Parameter "${__e.param}" required`
                                            break
                                        default:
                                            console.error(`Unhandled validation error : code=${__code}, message=${message} (e = ${JSON.stringify(__e)})`)
                                            __msg = message
                                            break ;
                                    }
                                    return __msg
                                })
                            }
                        }
                        console.log(`RESTError :: code=${code}`)
                        throw new RESTError(statusCode, 
                            message!==null?message:"Error", 
                            detail!==null?detail:{})
                    };

                    console.log(`Rejecting ${status}, text=${statusText}`)
                    throw new Error(getErrorResponse(status, statusText));
            }
        }
    }

    async get(url, query) {
        return await this.call(url, 'get', { params: query })
    }

    async put(url, data) {
        return await this.call(url, 'put', { data })
    }
}

const HttpService = {
	serverURL: "/api",

	getClusterList(_inactive = false) {
		/**
		 * Gets a list of clusters.
		 *
		 * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
		 */
		return handleAxiosCall(HttpService.serverURL + "/cluster", 'get',{ params: { inactive: _inactive } });
	},

	getAllClusters(_inactive = true) {
		/**
		 * Gets a list of clusters.
		 *
		 * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
		 */
		return handleAxiosCall(HttpService.serverURL + "/cluster", 'get',{ params: { inactive: _inactive } });
	},

	getClustersCadmin() {
		/**
		 * Gets a list of clusters.
		 *
		 * @param {Boolean} _inactive	If enabled, inactive farms will be returned alongside active ones.
		 */
		return handleAxiosCall(HttpService.serverURL + "/cluster/discover", 'get');
	},

	addCluster( cluster) {
		/**
		 * Addsclusters.
		 *
		 * @param {Object} cluster		Cluster details to use for update.
		 */
		return handleAxiosCall(HttpService.serverURL + "/cluster", 'put', { data: cluster });
	},

	editCluster(clsId, cluster) {
		/**
		 * Addsclusters.
		 * @param {ObjectId} clsId
		 * @param {Object} cluster		Cluster details to use for update.
		 */
		return handleAxiosCall(`${HttpService.serverURL}/cluster/${clsId}`, 'put', { data: cluster });
	},

	getOrganisationList(_includeInactive = false) {
		/**
		 * Gets a list of organisations.
		 *
		 * @param {Boolean} _includeInactive	If enabled, inactive farms will be returned alongside active ones.
		 */
		return handleAxiosCall(HttpService.serverURL + "/organisation", 'get', { params: { includeInactive: _includeInactive } });
	},

	getOrganisationsAll(_includeInactive = true) {
		/**
		 * Gets a list of organisations.
		 *
		 * @param {Boolean} _includeInactive	If enabled, inactive farms will be returned alongside active ones.
		 */
		return handleAxiosCall(HttpService.serverURL + "/organisation", 'get', { params: { includeInactive: _includeInactive } });
	},

	getOrganisationByKey( id) {
		/**
		 *
		 * @param {ObjectId} id
		 */
		return handleAxiosCall( `${HttpService.serverURL}/organisation/bykey/${id}`,  'get');
	},

	addOrganisation( organisation) {
		/**
		 * Addsclusters.
		 *
		 * @param {Object} organisation		Cluster details to use for update.
		 */
		return handleAxiosCall(HttpService.serverURL + "/organisation", 'put', { data: organisation });
	},

	editOrganisation( orgId, organisation) {
		//console.log('organisation.....',orgId, organisation.name, organisation.active, organisation.address)
		/**
		 *
		 * @param {ObjectId} orgId
		 * @param {Object} organisation
		 * @param  _delete
		 */
		 const name = organisation.name;
		 const active = organisation.active;
		 const address = organisation.address;

		 var payload = {}
		 if (address === null ) {
			 payload = {name: name, active: active, __delete: ['address']}
		 } else {
			 	payload = {name: name, active: active, address:address}
		 }

		return handleAxiosCall( `${HttpService.serverURL}/organisation/${orgId}`,  'put',{data:payload});
	},

	getSiteList(orgId) {
		/**
		 * Gets a list of sites.
		 *@param {ObjectId} orgId
		 */
		return handleAxiosCall( `${HttpService.serverURL}/organisation/${orgId}/site`, 'get');
	},

	getSitesAll(orgId, _inactive = true) {
		/**
		 *  Gets a list of sites including inactive.
		 * @param {ObjectId} orgId
		 * @param {Boolean} _inactive
		 */
		return handleAxiosCall( `${HttpService.serverURL}/organisation/${orgId}/site`, 'get', { params: { includeInactive: _inactive } });
	},

	addSite(orgId, site) {
		/**
		 * Addsclusters.
		 * @param {ObjectId} orgId
		 * @param {Object} site		Cluster details to use for update.
		 */
		return handleAxiosCall(`${HttpService.serverURL}/organisation/${orgId}/site`, 'put', { data: site });
	},

	editSite(orgId, siteId, site) {
		/**
		 * Addsclusters.
		 * @param {ObjectId} orgId
		 * @param {ObjectId} siteId
		 * @param {Object} site		Cluster details to use for update.
		 */
		return handleAxiosCall(`${HttpService.serverURL}/organisation/${orgId}/site/${siteId}`, 'put', { data: site });
	},

	getUserMe() {
		/**
		 * Gets a current user.
		 *
		 */
		return handleAxiosCall(HttpService.serverURL + "/user/me", 'get');
	},

	checkSession() {
		/**
		 * Checks if an existing user session already exists.
		 */
		return handleAxiosCall(`${HttpService.serverURL}/user/me`, 'get');
	},

	getUserId(userId) {
		/**
		 * Gets a current user.
		 * @param {ObjectId} userId
		 */
		return handleAxiosCall(`${HttpService.serverURL}/user/${userId}`,  'get',);
	},

	getUserList() {
		/**
		 * Gets a list of users.
		 *
		 */
		return handleAxiosCall(HttpService.serverURL + "/user", 'get');
	},

	getUsersAll(_inactive = true) {
		/**
		 * Gets a list of users.
		 *
		 * @param {Boolean} _inactive
		 */
		return handleAxiosCall(HttpService.serverURL + "/user", 'get',{ params: { includeInactive: _inactive } });
	},

	addUser( user) {
		/**
		 *
		 *
		 * @param {Object} user
		 */
		return handleAxiosCall(HttpService.serverURL + "/user", 'put', { data:user });
	},

	editUser( userId, user) {
		/**
		 *
		 * @param {ObjectId} userId
		 * @param {Object} user
		 */
		return handleAxiosCall( `${HttpService.serverURL}/user/${userId}`,  'put', { data:user });
	},
	changePassword( user) {
		/**
		 *
		 *
		 * @param {Object} user	Cluster details to use for update.
		 */
		return handleAxiosCall( HttpService.serverURL+"/user/password",  'put', { data:user });
	},

	// user-service Calls
	login(_email, _password) {
		/**
		 * Authenticates the given user credentials.
		 *
		 * @param {String} 	_email		Email of user credentials to authenticate with.
		 * @param {String}	_password	Password of user credentials to authenticate with.
		 */

		return handleAxiosCall(
			`${HttpService.serverURL}/user/authenticate`,
			'put',

			{
				data: {
					email: _email,
					password: _password
				}
			}
		)
	},

	logout() {
		/**
		 * Closes the user's login session.
		 */
		return handleAxiosCall(`${HttpService.serverURL}/user/logout`, 'put');
	},

	// weather-service Calls
	getWeatherStations(_lon = 1, _lat = 1, _limit = -1) {
		/**
		 * Gets a list of weather stations based on a coordinate location.
		 *
		 * @param {Number}	_lon	Longitude of coordinate location to retrieve from.
		 * @param {Number}	_lat	Latitude of coordinate location to retrieve from.
		 * @param {Number}	_limit	Maximum amount of weather stations to display (sorted by closest range). If -1, returns all stations.
		 */

		return handleAxiosCall(
			`${HttpService.serverURL}/weather/station`,
			'get',

			{
				params: {
					lon: _lon,
					lat: _lat,
					limit: _limit
				}
			}
		);
	},

	getWeatherRecords(_station, _from, _to) {
		/**
		 * Gets a list of weather records from a certain range of time based on a coordinate location.
		 *
		 * @param {ObjectId}	_station	Weather station of weather records to retrieve from.
		 * @param {String}		_from		Start time of weather records to retrieve from. Should be in an ISO 8601 format.
		 * @param {String}		_to			End time of weather records to retrieve from. Should be in an ISO 8601 format.
		 */

		return handleAxiosCall(
			`${HttpService.serverURL}/weather`,
			'get',

			{
				params: {
					station: _station,
					from: _from,
					to: _to
				}
			}
		);
	}
};

export { Request }

export default HttpService;
