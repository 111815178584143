import React, { Component } from "react";
import Graph, { LOAD } from "../DashboardGraphs.jsx";
import TotalComparisonTable from "../accuracy/TotalComparisonTable.jsx";
import ComparisonTable from "../accuracy/ComparisonTable";

// import {
//   getComarisonValues,
//   getDataDifferences,
//   total
// } from "../../utility/HelperFunctions";

export default class SolarGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      predictedSupply,
      actualSupply,
      yName,
      timeline,
      differencesSolar,
      r2Solar,
      mean_sq_errorSolar,
      maseFSolar,
      totalDifSolar,
      totalPredictionSolar,
      totalReadingSolar,
      totalPercSolar
    } = this.props;

    //console.log('solarReadingArray',actualSupplyParsed)
    //console.log('solarPredictionArray',predictedSupplyParsed)

    // var differences =[];
    // var totalDif = null;
    // var totalReading = null;
    // var totalPrediction = null;
    // var totalPerc = null;
    // if (actualSupplyParsed.length && predictedSupplyParsed.length) {
    //  differences = getDataDifferences(actualSupplyParsed, predictedSupplyParsed, timeline);
    //  if (differences && differences.length) {
    //    const diff = differences.map(a => a.difference)
    //    const arrayDiff = diff.filter(a=> !isNaN(a));
    //    const reading = differences.map(a => a.readingTotal);
    //    const arrayRead = reading.filter(a=> !isNaN(a));
    //    const prediction = differences.map(a => a.predictionTotal);
    //    const arrayPredict = prediction.filter(a=> !isNaN(a));
    //
    //     totalDif = total(arrayDiff).toFixed(2);
    //     totalReading = total(arrayRead).toFixed(2);
    //     totalPrediction = total(arrayPredict).toFixed(2);
    //     totalPerc = ((totalReading - totalPrediction)/totalReading *100).toFixed(3)
    //
    //     var {
    //       mean_sq_error,
    //       maseF,    } = getComarisonValues(arrayRead, arrayPredict)
    //   }
    //  }

    return (
      <div className="marginPage">
        <div className="row small-gutters ">
          <div className="col-lg-12">
            <Graph
              // price={priceLabel}
              modules={true}
              timeline={timeline}
              yName={yName}
              axes={{ left: [LOAD] }}
              series={[predictedSupply, actualSupply]}
            />
          </div>

          <div>
            <TotalComparisonTable
              mean_sq_error={mean_sq_errorSolar}
              maseF={maseFSolar}
              spearmanRHO={r2Solar}
            />
          </div>
        </div>

        {differencesSolar && differencesSolar.length ? (
          <div className="row small-gutters ">
            <div className="col-lg-12">
              <ComparisonTable
                differences={differencesSolar}
                sumActualReadings={totalReadingSolar}
                sumPredictions={totalPredictionSolar}
                diffTotal={totalDifSolar}
                incr={totalPercSolar}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
