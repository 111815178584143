import React, {useState, useRef, useEffect, useCallback} from 'react'
import { Container, Button, Col, Form } from 'react-bootstrap'
import { Overlay, noop } from '../components/UIComponents'
import {AlertContainer, Notify} from '../components/AlertListener'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import { TimeUtils } from 'time-utils'
import moment from 'moment'
import FormControl from '../settings/battery/FormControl'
import {Request} from "../../services/HttpService"
import {useDropzone} from 'react-dropzone';
import ChunkedUploader from './ChunkedUploader'

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.error.notifications")
const mainNotify=Notify("battery.notifications")

const DATA_RANGE={
    type: "date-range",
    name: ["valid_from", "valid_to"],
    label: ["Start time", "End time"],
    endRequired: true,
    allowReset: true,
    time: true
}

const DATE_FMT='Do MMM YY, HH:mm'

export default function BatteryDataUpload({battery, onDone=noop, timezone}) {
    const [canSave, enableSave] = useState(false)
    const [columns, setColumns] = useState([])
    const [file, setFile] = useState(null)
    const [validRange, setValidRange] = useState(null)
    const [dataMinTime, setDataMinTime] = useState(null)
    const [dataMaxTime, setDataMaxTime] = useState(null)
    const [records, setRecords] = useState(null)
    const [data, setData] =useState(null)
    const [tutils, setTutils] = useState(null)
    const [hasError, setError] = useState(false)
    const [sending, setSending] = useState(null)
    const cancelRef = useRef()

    const onDrop = useCallback(([file]) => {
        // Read the file
        const reader = new FileReader()

        reader.onabort = () => notify.error(`Aborted reading "${file.name}"`)
        reader.onerror = () => notify.error(`Error reading "${file.name}"`)
        reader.onload = () => {
            const content = reader.result
            try {
                setRecords(battery.config.upload.parse(content)) 
                setFile(file)
            }
            catch(err) {
                notify.error(`"${file.name}" - ${err.message}`)
                return
            }
    }
        reader.readAsText(file)
    }, [])

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        // onDrop,
        accept: "text/csv",
        multiple: false
    });

    useEffect(() => {
        if(acceptedFiles.length>0) {
            const [file] = acceptedFiles
            // Read the file
            const reader = new FileReader()

            reader.onabort = () => notify.error(`Aborted reading "${file.name}"`)
            reader.onerror = () => notify.error(`Error reading "${file.name}"`)
            reader.onload = () => {
                const content = reader.result
                try {
                    setRecords(battery.config.upload.parse(content)) 
                    setFile(file)
                }
                catch(err) {
                    notify.error(`"${file.name}" - ${err.message}`)
                    return
                }
            }
            reader.readAsText(file)
        }
        else if(fileRejections.length>0) {
            for(const {file, errors} of fileRejections) {
                for(const {message} of errors) {
                    notify.error(`File ${file.name} - ${message}`)
                }
            }
        }

        return () => {
            setFile(null)
            setData(null)
            setRecords(null)
        }
    }, [acceptedFiles, fileRejections])

    const keyUpHandler = useCallback(event => {
        if(event.key==='Escape') {
            // Use ESC to click cancel - button ref is cleanest way to ensure 
            // context is in scope
            cancelRef.current.click()
        }
    }, [])

    useEffect(() => {
        setTutils(new TimeUtils(battery.config.timezone))
    }, [battery])

    useEffect(() => {
        document.addEventListener('keyup', keyUpHandler)

        return () => {
            document.removeEventListener('keyup', keyUpHandler)
        }
    }, [])


    useEffect(() => {
        if(!tutils || !battery) return

        setColumns([ {
            dataField: 'timestamp',
            text: 'Time',
            formatter: ts => {
                return `${tutils.localTime(moment.unix(ts).utc()).format('DD/MM/YYYY HH:mm')}`
            }
        }, {
            dataField: 'discharge',
            text: 'Discharge (W)'
        }, {
            dataField: 'charge',
            text: 'Charge (W)'
        }, {
            dataField: 'production',
            text: 'Production (W)'
        }, {
            dataField: 'consumption',
            text: 'Consumption (W)'
        }, {
            dataField: 'soc',
            text: 'State of Charge (%)'
        }])
    }, [tutils, battery])

    useEffect(() => {
        if(!tutils || !Array.isArray(records) || records.length===0) return
        // Get the date range
        
        const { timestamp: __start} = records[0],
            { timestamp: __end} = records[records.length-1]

        const __s = tutils.localTime(moment.unix(__start).utc()).toISOString(),
            __e=tutils.localTime(moment.unix(__end).utc()).toISOString()

        setValidRange([ __s, __e ])
        setDataMinTime(__s)
        setDataMaxTime(__e)

        return () => {
            setValidRange(null)
        }
    }, [tutils, records])

    useEffect(() => {
        if(!tutils || !validRange || !Array.isArray(records)) return
        const __start = tutils.localTime(validRange[0]).unix(),
             __end = tutils.localTime(validRange[1]).unix()

        // Only in range records
        const __data = records.filter(({timestamp}) => timestamp>=__start && timestamp<=__end)

        setData(__data)
        // console.log(`[validate] start=${__start}, end=${__end}, data=${__data.length}, error=${hasError}`)

        enableSave(!hasError && __data.length>0)

        return () => {
            setData(null)
            enableSave(false)
        }
    }, [tutils, validRange, records, hasError])

    useEffect(() => {
        if(sending===false) {
            const [start, end]=validRange

            mainNotify.info(`Uploaded "${file.name}" - ${tutils.localTime(start).format(DATE_FMT)} -> ${tutils.localTime(end).format(DATE_FMT)}`)
            onDone()
        }
    }, [sending, file, tutils, validRange])

    async function onCancel() {
        onDone()
    }

    const onSave = useCallback(async () => {
        if(!Array.isArray(data)) return 
        const { uri } = battery.config.upload
        const [start, end]=validRange
        if(!uri) {
            notify.error('No upload URI set')
            return ;
        }
        setSending(true)

    }, [battery, data, file, validRange, tutils])

    return (<Overlay>
                        
        <AlertContainer tag="battery.error.notifications" page="battery"/>
        <Container fluid>
            <Form noValidate onSubmit={onSave}>
                <Form.Row>
                    <Form.Group as={Col} lg={6} controlId="dataFile">
                        <Form.Label><h4>Select CSV File</h4></Form.Label>
                        <div {...getRootProps({className: `dropzone ${isDragActive?'dragging':''}`, style: { minHeight: "10vh"}}) }>
                            <input {...getInputProps({})} />
                            <p>Click to select, or drag and drop a CSV file</p>
                        </div>
                    </Form.Group>
                </Form.Row>
                {data && <>
                {validRange && <Form.Row>
                    <FormControl 
                        descriptor={DATA_RANGE} 
                        as={String} 
                        lg={3}
                        onChange={setValidRange} 
                        isValid={(name, err) => setError(err)}
                        time
                        endRequired
                        allowReset 
                        minValue={dataMinTime}
                        maxValue={dataMaxTime}
                        value={validRange}/>
                </Form.Row> /* validRange */}
                {sending && <ChunkedUploader 
                    data={data.map(
                        ({timestamp, discharge, charge, production, consumption, soc}) =>  
                        ([timestamp, discharge, charge, production, consumption, soc]))}
                    uri={battery.config.upload.uri}
                    onDone={(err) => setSending(err?null:false)}
                    onError={err => notify.error(`Error uploading ${file.name} - ${err.message}`)}/>}
                <Form.Row>
                    <Col>
                    <h5>Preview - "{file.name}"</h5>
                    <BootstrapTable
                        bootstrap4 striped hover condensed 
                        version="4"
                        keyField='__l' 
                        data={data}
                        columns={columns}
                        pagination={paginationFactory()}
                        noDataIndication="No records"/>
                    </Col>
                </Form.Row>
                </> /* data */ }
                <Form.Row>
                    <Col lg="6">
                        <div className="btn-right">
                            <Button 
                                type="button" 
                                ref={cancelRef}
                                disabled={sending!==null} 
                                variant="outline-secondary" 
                                onClick={onCancel}
                                style={{marginRight: "1rem"}}>
                                <i className="fas fa-times"/>Cancel
                            </Button>

                            <Button 
                                id="btnSave" 
                                disabled={!canSave} 
                                type="button" 
                                onClick={onSave} 
                                variant="re-primary">
                                <i className="fas fa-upload"/>Upload
                            </Button>
                        </div>
                    </Col>
                </Form.Row>
            </Form>
        </Container>
    </Overlay>)
}