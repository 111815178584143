import React, {useContext} from 'react'
import {Route, Switch} from 'react-router-dom'
import UserContext from "../../../services/user-context"
import EditRenewable from './EditRenewables'
import SolarSettings from "../../solar/SolarSettings.jsx";

export default function MeterSettingDashboard() {
    const { user } = useContext(UserContext)

    return (
        <Switch>
            <Route exact path="/dashboard/settings/renewables" render={(props) =>
                            <SolarSettings {...props} user={user}/>} />
            <Route exact path="/dashboard/settings/renewables/new" render={props => <EditRenewable/>}/>

        </Switch>
    )
}