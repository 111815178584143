import React, {useState, useEffect} from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'

export default function EditMeter({_id=null}) {
    const [prev, setPrev]=useState(null)

    const location=useLocation() 

    useEffect(() => {
        console.log('location = ', location)
        if(location.state && location.state.from) {
            setPrev(location.state.from)
        }
    }, [location])

    const history = useHistory()

    function goBack() {
        if(prev!==null) {
            history.goBack()
        }
        else {
            history.replace('/dashboard/home0')
        }

    }

    return (
        <Container fluid>
            <Row><Col><h3>Add Meter</h3></Col></Row>
            <Row>
                <Col md={6}>
                    <Card>
                    <Card.Title>
                        <i
                          style={{
                            color: "orange"
                          }}
                          className="fas fa-exclamation-triangle"
                        ></i> Not yet implemented
                    </Card.Title>
                    <Card.Body>
                    <p>
                        New add meter functionality not yet implemented.
                    </p>
                    <p> 
                        For now, please add meter on legacy <Link to="/dashboard/settings/meters">meter management</Link> page
                    </p>
                    </Card.Body>
                  </Card>

                </Col>
            </Row>
            <Row>
                <Col><Button variant="outline-secondary" onClick={goBack}>Back</Button></Col>
            </Row>
        </Container>
    )
}