import React, { useState, useEffect, useContext } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { Icon, Popup } from "semantic-ui-react";
import UserContext from "../../services/user-context"
import SpearmanRHO from "spearman-rho";
import Decimal from 'decimal.js-light';

const ALIGN_RIGHT = () => 'right',
    ALIGN_CENTER = () => 'center'

const formatDecimal = val => !isNaN(val)?val.toFixed(3):'--'

export default function AccuracyTable({observed, predicted}) {
    const [data, setData]=useState(null)
    const [mse, setMSE] = useState(null)
    const [mase, setMASE] = useState(null)
    const [r2, setR2] = useState(null)
    const [totalObserved, setTotalObserved] = useState()
    const [totalPredicted, setTotalPredicted] = useState()
    const [totalDeviation, setTotalDeviation] = useState()

    const { Time } = useContext(UserContext)

    const caclulateStatistics = async (observations, predictions) => {
        const len = observations.length
        if(len===0) return 

        // Caculate Mean Squared Error
        const diffAll = observations.map((v, i) => new Decimal(predictions[i]-v).pow(2))
        const totDiff = diffAll.reduce((t, v) => t.add(v), new Decimal(0))

        setMSE(totDiff.div(len).toDecimalPlaces(2).toNumber())

        // Calculate MASE
        if(len<2) return // Need at least 2 samples

        let __diffs = 0
        for(let i=0; i<len-1; ++i) {
            __diffs += Math.abs(observations[i+1]-observations[i])
        }
        const __d = new Decimal(__diffs).div(len-1)

        if(__d.isZero()) return // Can't calulate MASE - would get div by zero

        const __errors = observations.reduce((t, v, i) => t+Math.abs(predictions[i]-v), 0)
        setMASE(new Decimal(__errors).div(len).div(__d).toDecimalPlaces(2).toNumber())
    }

    const calculateR2 = async (observations, predictions) => {
        const __spearmanRHO = new SpearmanRHO(observations, predictions)
        const r2 = await __spearmanRHO.calc()
        setR2(r2*100)
    }

    useEffect(() => {
        if(!Array.isArray(observed) || observed.length===0 ||
            !Array.isArray(predicted) || predicted.length===0) {
                return
            }
        // Get the earliest of the sequences
        let __startTime = Time.localTime(observed[0].timestamp)
        if(__startTime.isAfter(predicted[0].timestamp)) {
            __startTime = Time.localTime(predicted[0].timestamp)
        }
        const __data = []
        // Add the observed values
        for(const {timestamp: __ts, reading: observation} of observed) {
            const timestamp = Time.localTime(__ts),
                // Get the difference from the starttime
                ndx = timestamp.diff(__startTime, 'hours')
            __data[ndx] = { ndx, timestamp, observation: observation}
        }
        // Add the predictions
        for(const {timestamp: __ts, prediction} of predicted) {
            const timestamp = Time.localTime(__ts),
                // Get the difference from the starttime
                ndx = timestamp.diff(__startTime, 'hours')
            // Get the existing data record, if it exists
            const __rec=__data[ndx]
            if(__rec) {
                // Add the prediction
                __rec.prediction = prediction
            }
            else {
                // No reading - add it
                __data[ndx] = { ndx, timestamp, prediction}
            }
        }
        // Fill in any gaps
        for(const [ndx, rec] of __data.entries()) {
            if(!rec) {
                // Add a blank record
                __data[ndx] = { ndx, timestamp: Time.localTime(__startTime).add(ndx, 'hours')}
            }
        }
        // Calculate deviation
        // Get all entries where there are both observation and prediction
        const candidates = __data.filter(({ndx, observation, prediction}) => 
                !isNaN(observation) && observation!==0 && !isNaN(prediction))
        for(const {ndx, observation, prediction} of candidates) {
            const value = observation-prediction
            // Add it back to main record
            __data[ndx].deviation = {
                value,
                percent: (value/observation)*100
            }
        }
        setTotalObserved(__data.reduce((t, {observation=0}) => t+observation, 0))
        setTotalPredicted(__data.reduce((t, {prediction=0}) => t+prediction, 0))

        // Calculate the deviation on the candidates
        const [__to, __tp] = candidates.reduce(([tO, tP], {observation, prediction}) =>
                ([tO+observation, tP+prediction]), [0,0])
        if(__to!==0) {
            const __totalDeviation=__to-__tp
            setTotalDeviation({
                value: __totalDeviation,
                percent: (__totalDeviation/__to)*100
            })
        }
        setData(__data)

        // Extract the candidate values for statistical analysis
        const __observations = candidates.map(({observation}) => observation)
        const __predictions = candidates.map(({prediction}) => prediction)

        // Calculate MSE
        // Calculate MASE
        caclulateStatistics(__observations, __predictions)
        // Calculate R2
        calculateR2(__observations, __predictions)
    }, [observed, predicted])

    if(data===null) return null

    const columns = [
        {
            dataField: "ndx",
            text: '',
            hidden: true
        },
        {
            dataField: 'timestamp',
            text: 'Time',
            sort: true,
            align: ALIGN_CENTER,
            formatter: (data) => data.format('DD/MMM HH:mm')
        },
        {
            dataField: "observation",
            text: `Actual (${isNaN(totalObserved)?'--':totalObserved.toFixed(0)}kW)`,
            align: ALIGN_RIGHT,
            formatter: formatDecimal
        },
        {
            dataField: "prediction",
            text: `Prediction (${isNaN(totalPredicted)?'--':totalPredicted.toFixed(0)}kW)`,
            align: ALIGN_RIGHT,
            formatter: formatDecimal
        },
        {
            dataField: "deviation",
            text: `Deviation (${totalDeviation?`${totalDeviation.percent.toFixed(1)}% / ${totalDeviation.value.toFixed(3)}kW`:`--kW/--%`})`,
            align: ALIGN_RIGHT,
            formatter: (dev) => dev?`(${dev.percent.toFixed(1)}%)  ${dev.value.toFixed(3)}`:'--'
        }
    ]

    return (<div>
        <Row className="lead" style={{fontSize: "1.4rem"}}>
            <Col lg="auto"><b>Accuracy :</b> </Col> 
            <Col lg="auto">
                <Popup
                    trigger={<Icon circular className="info" size="small" />}
                    content="Mean squared error (MSE) or mean squared deviation (MSD) of an estimator (of a procedure for estimating an unobserved quantity) measures the average of the squares of the errors—that is,the average squared difference between the estimated values and the actual value.  Lower better, best <1."
                />
                Error score (MSE) : {mse!==null?mse:'--'} ; 
            </Col> 
            <Col lg="auto"><Popup
                trigger={<Icon circular className="info" size="small" />}
                content="The mean absolute scaled error (MASE) calculates the mean absolute scaled error (MASE) between the forecast and the eventual outcomes. It is the mean absolute error of the forecast values, divided by the mean absolute error of the in-sample one-step naive forecast. Lower better, best <1."
              />
              MASE of {mase!==null?mase:'--'} ;
            </Col> 
            <Col lg="auto"><Popup
                trigger={<Icon circular className="info" size="small" />}
                content="R-squared (R2) is a statistical measure that represents the proportion of the variance for a dependent variable that is explained by an independent variable or variables in a regression model. Best closer to 100% ."
              />
              R2 of {r2!==null?`${r2.toFixed(1)}%`:'--'}</Col> 
        </Row>
        { data && <Row>
            <Col>
                <BootstrapTable
                    bootstrap4 striped hover condensed 
                    version="4"
                    keyField='ndx' 
                    columns={columns}
                    data={data}
                    noDataIndication="No readings"
                    defaultSorted={[{dataField: 'timestamp', order: 'asc'}]}
                />
            </Col>
        </Row>}
    </div>)
}