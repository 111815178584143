import axios from "axios";
//import ErrorListener from "./ErrorListener"

const HTTP_STATUS_CODES = {
  // Retrieved from: https://www.restapitutorial.com/httpstatuscodes.html
  // STATUS_CODE: response_msg

  // Redundant, success responses;
  200: "",
  201: "",
  202: "",
  204: "",
  DEFAULT_SUCCESS: "Success",

  // Client errors;
  400: "Bad request",
  401: "Unauthorized access, invalid permissions",
  403: "Forbidden, invalid permissions",
  404: "Not found, invalid routing",
  408: "Request timeout",
  409: "Duplicate entry",
  DEFAULT_CLIENT_ERROR:
    "Client error, please try again with the correct credentials",

  // Server errors;
  500: "Internal server error",
  501: "Service not currently implemented",
  502: "502",
  503: "Service currently unavailable, try again later",
  504: "Gateway timeout, try again later",
  DEFAULT_SERVER_ERROR: "Server error, please try again later. ",

  // Default message to display when given status code is not specified above;
  DEFAULT_ERROR: " Error occurred, please try again later"
};

// Utilities //

function getErrorResponse(statusCode = null, statusText = "") {
  /**
   * Retrieves the appropriate error response based on the status data given.
   *
   * @param {Number}	statusCode	The status code of the response.
   * @param {String}	statusText	Associated API error message with the status.
   *
   * @return {String}	Concatenated status' error message with description of status code.
   */

  if (!statusCode) {
    return HTTP_STATUS_CODES.DEFAULT_ERROR;
  }

  const statusResponse = HTTP_STATUS_CODES[statusCode],
    APIMessage = statusText;

  if (typeof statusResponse === "string") {
    return statusResponse + APIMessage ;
  }

  if (statusCode < 500) {
    return HTTP_STATUS_CODES.DEFAULT_CLIENT_ERROR + APIMessage + statusCode;
  } else if (statusCode < 600) {
    return HTTP_STATUS_CODES.DEFAULT_SERVER_ERROR + APIMessage + statusCode;
  }

  return HTTP_STATUS_CODES.DEFAULT_ERROR + APIMessage;
}

// Method used to abstract axios call's error handling functionalities //

function handleAxiosCall(apiURL, _method = "get", toSend = {}) {
  /**
   * Makes an API call using axios.
   *
   * @param {String}	apiURL	URL of API to call.
   * @param {String}	_method	Type of HTTP request, i.e. 'get', 'put'
   * @param {Object}	toSend	Associated data to send with API call. Can contain 'headers', 'params' and 'data' objects.
   *
   * @return {Promise} A resolved or rejected promise of the API call, depending on the status of the request.
   */

  return new Promise((resolve, reject) => {
    let axiosBody = {
      method: _method.toLowerCase(),
      url: apiURL
    };

    const { headers, params, data } = toSend,
      requestConfig = { headers: headers, params: params, data: data };

    for (let requestType in requestConfig) {
      const request = requestConfig[requestType];

      if (typeof request !== "undefined" && request !== null) {
        axiosBody[requestType] = request;
      }
    }

    return axios(axiosBody)
      .then(rawResponse => {
        resolve(rawResponse);
      })
      .catch(error => {
        if (typeof error.response === 'undefined' && !error.response) {
          return reject(error);
        }

        const errResponse = error.response,
          APIError = errResponse.data;

        let status = errResponse.status,
          statusText = errResponse.statusText;

        if(status === 401) {
            //ErrorListener.raise(401, new Error('Not authorised'))
              // Conflict error found;
              console.log("response", 'Not authorised');
        }
        // Some APIs may return an APIError object upon an error.
        // See: https://gitlab-ee.tssg.org/smarter-aquaculture/sa-utils/blob/master/lib/error.js
        if (APIError && APIError.statusCode && APIError.message) {
          status = APIError.statusCode;
          statusText = APIError.message.message;
        }

        reject(getErrorResponse(status, statusText));
      });
  });
}

// API Calls //

const UserService = {
  serverURL: "/api",

  getOrganisationList(_includeInactive = false) {
    /**
     * Gets a list of organisations.
     *
     * @param {Boolean} _includeInactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(UserService.serverURL + "/organisation", "get", {
      params: { includeInactive: _includeInactive }
    });
  },

  getOrganisationsAll(_includeInactive = true) {
    /**
     * Gets a list of organisations.
     *
     * @param {Boolean} _includeInactive	If enabled, inactive farms will be returned alongside active ones.
     */
    return handleAxiosCall(UserService.serverURL + "/organisation", "get", {
      params: { includeInactive: _includeInactive }
    });
  },

  getOrganisationByKey(id) {
    /**
     *
     * @param {ObjectId} id
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/bykey/${id}`,
      "get"
    );
  },

  addOrganisation(organisation) {
    /**
     * AddsOrgs.
     *
     * @param {Object} organisation
     */
    return handleAxiosCall(UserService.serverURL + "/organisation", "put", {
      data: organisation
    });
  },

  editOrganisation(orgId, organisation) {
    //console.log('organisation.....',orgId, organisation.name, organisation.active, organisation.address)
    /**
     *
     * @param {ObjectId} orgId
     * @param {Object} organisation
     * @param  _delete
     */
    const name = organisation.name;
    const active = organisation.active;
    const address = organisation.address;

    var payload = {};
    if (address === null) {
      payload = { name: name, active: active, __delete: ["address"] };
    } else {
      payload = { name: name, active: active, address: address };
    }

    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}`,
      "put",
      { data: payload }
    );
  },

  ////////////////////cluser owner get data for individual organisations

  getAgregatedSupplyOrgforCluserAdmin(clId, orgId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/cluster/${clId}/organisation/${orgId}/aggregate/supply`,
      "get" , { params: { price: price, from: dateFrom_iso, to: dateTo_iso }}
    );
  },

  getTariffsAndChargesforCluserAdmin(clId, orgId,dateFrom_iso, dateTo_iso) {
    return handleAxiosCall(
      `${UserService.serverURL}/cluster/${clId}/organisation/${orgId}/billing/rates`,
      "get" , { params: { from: dateFrom_iso, to: dateTo_iso }}
    );
  },

  getAgregatedLoadOrgforCluserAdmin(clId, orgId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/cluster/${clId}/organisation/${orgId}/aggregate/load`,
      "get", { params: { price: price, from: dateFrom_iso, to: dateTo_iso } }
    );
  },

  getPredictLoadOrgforCluserAdmin(clId, orgId , price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/cluster/${clId}/organisation/${orgId}/predict/load`,
      "get" , { params: { price: price,from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getPredictSupplyOrgforCluserAdmin(clId, orgId, price = true, dateFrom_iso, dateTo_iso ) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/cluster/${clId}/organisation/${orgId}/predict/supply`,
      "get" ,{ params: {price, from:dateFrom_iso ,to:dateTo_iso }}
    );
  },
//////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//Get data for cadmin

getAgregatedSupplyOrg(orgId, price = true, dateFrom_iso, dateTo_iso) {
  /**
   *  Gets a list of sites including inactive.
   * @param {ObjectId} orgId
   */
  return handleAxiosCall(
    `${UserService.serverURL}/organisation/${orgId}/aggregate/supply`,
    "get" , { params: { price: price, from: dateFrom_iso, to: dateTo_iso }}
  );
},


getTariffsAndCharges( orgId, dateFrom_iso, dateTo_iso) {
  return handleAxiosCall(
    `${UserService.serverURL}/billable/organisation/${orgId}/rates`,
    "get" , { params: { from: dateFrom_iso, to: dateTo_iso }}
  );
},

getAgregatedLoadOrg( orgId, price = true, dateFrom_iso, dateTo_iso) {
  /**
   *  Gets a list of sites including inactive.
   * @param {ObjectId} orgId
   */
  return handleAxiosCall(
    `${UserService.serverURL}/organisation/${orgId}/aggregate/load`,
    "get", { params: { price: price, from: dateFrom_iso, to: dateTo_iso } }
  );
},

getPredictLoadOrg( orgId , price = true, dateFrom_iso, dateTo_iso) {
  /**
   *  Gets a list of sites including inactive.
   * @param {ObjectId} orgId
   */
  return handleAxiosCall(
    `${UserService.serverURL}/organisation/${orgId}/predict/load`,
    "get" , { params: { price: price,from:dateFrom_iso ,to:dateTo_iso }}
  );
},

getPredictSupplyOrg( orgId, price = true, dateFrom_iso, dateTo_iso ) {
  /**
   *  Gets a list of sites including inactive.
   * @param {ObjectId} orgId
   */
  return handleAxiosCall(
    `${UserService.serverURL}/organisation/${orgId}/predict/supply`,
    "get" ,{ params: {price, from:dateFrom_iso ,to:dateTo_iso }}
  );
},




///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////




  getAgregatedSupplySite( orgId, siteId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     * @param {ObjectId} siteId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site/${siteId}/aggregate/supply`,
      "get" , { params: { price, from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  // /api/organisation/{id}/site/{siteId}/aggregate/supply

  getAgregatedLoadSite(orgId, siteId, price = true, dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     * @param {ObjectId} siteId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site/${siteId}/aggregate/load`,
      "get" , { params: { price, from:dateFrom_iso ,to:dateTo_iso }}
    );
  },

  getPredictSupplySite(orgId, siteId, price = true,dateFrom_iso, dateTo_iso) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     * @param {ObjectId} siteId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site/${siteId}/predict/supply`,
      "get" , { params: { price, from:dateFrom_iso ,to:dateTo_iso  }}
    );
  },

  getSiteList(orgId) {
    /**
     * Gets a list of sites.
     *@param {ObjectId} orgId
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site`,
      "get"
    );
  },

  getSitesAll(orgId, _inactive = true) {
    /**
     *  Gets a list of sites including inactive.
     * @param {ObjectId} orgId
     * @param {Boolean} _inactive
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site`,
      "get",
      { params: { includeInactive: _inactive } }
    );
  },

  addSite(orgId, site) {
    /**
     * Addsites.
     * @param {ObjectId} orgId
     * @param {Object} site
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site`,
      "put",
      { data: site }
    );
  },

  editSite(orgId, siteId, site) {
    /**
     *
     * @param {ObjectId} orgId
     * @param {ObjectId} siteId
     * @param {Object} site		.
     */
    return handleAxiosCall(
      `${UserService.serverURL}/organisation/${orgId}/site/${siteId}`,
      "put",
      { data: site }
    );
  },

  getUserMe() {
    /**
     * Gets a current user.
     *
     */
    return handleAxiosCall(UserService.serverURL + "/user/me", "get");
  },

  getUserId(userId) {
    /**
     * Gets a current user.
     * @param {ObjectId} userId
     */
    return handleAxiosCall(`${UserService.serverURL}/user/${userId}`, "get");
  },

  getUserList() {
    /**
     * Gets a list of users.
     *
     */
    return handleAxiosCall(UserService.serverURL + "/user", "get");
  },

  getUsersAll(_inactive = true) {
    /**
     * Gets a list of users.
     *
     * @param {Boolean} _inactive
     */
    return handleAxiosCall(UserService.serverURL + "/user", "get", {
      params: { includeInactive: _inactive }
    });
  },

  addUser(user) {
    /**
     *
     *
     * @param {Object} user
     */
    return handleAxiosCall(UserService.serverURL + "/user", "put", {
      data: user
    });
  },

  editUser(userId, user) {
    /**
     *
     * @param {ObjectId} userId
     * @param {Object} user
     */
    return handleAxiosCall(`${UserService.serverURL}/user/${userId}`, "put", {
      data: user
    });
  },

  changePassword(user) {
    /**
     *
     *
     * @param {Object} user
     */
    return handleAxiosCall(UserService.serverURL + "/user/password", "put", {
      data: user
    });
  },

  // user-service Calls
  login(_email, _password) {
    /**
     * Authenticates the given user credentials.
     *
     * @param {String} 	_email		Email of user credentials to authenticate with.
     * @param {String}	_password	Password of user credentials to authenticate with.
     */

    return handleAxiosCall(
      `${UserService.serverURL}/user/authenticate`,
      "put",

      {
        data: {
          email: _email,
          password: _password
        }
      }
    );
  },

  checkSession() {
    /**
     * Checks if an existing user session already exists.
     */
    return handleAxiosCall(`${UserService.serverURL}/user/me`, "get");
  },

  logout() {
    /**
     * Closes the user's login session.
     */
    return handleAxiosCall(`${UserService.serverURL}/user/logout`, "put");
  }
};

export default UserService;
