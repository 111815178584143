import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"

const notify=Notify("dash.notifications")
const request = new Request({ service: 'Dashboard service'})

export function excludeNegativeFilter(attr='reading') {
    return (readings=[]) => 
        readings.map(r => {
            const keys = Object.keys(r)
            if(keys.includes(attr)) {
                const v = r[attr]
                if(v<0) {
                    // Negative - filter it
                    const newR = {...r}
                    newR[attr]=0
                    return newR
                }
            }
            return r
        })
}

export default async function Fetch(uri, query={}, setter, schema=null, filter=(readings)=>readings) {
    try {
        let __data = await request.get(
            uri,
            query
        )
        // console.log(`Fetch : uri=${uri}, query=${JSON.stringify(query)}, schema=${schema}, data = `, __data)
        // Map the data if necessary
        if(schema!==null) {
            __data = __data.map(v => {
                const __rec = {}
                for(const [i, attr] of schema.entries()) {
                    __rec[attr] = v[i]
                }
                return __rec
            })
            // console.log('__fetch(mapped) : ', __data)
        }
        // console.log('Setting : ' ,__data)
        setter(filter(__data))
    }
    catch(err) {
        console.error(err)
        notify.error(err.message)
    }
}