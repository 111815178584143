import React, { Component } from "react";

export default class WindGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
        <div >
        Wind Graph

        #Page under construction
        </div>
    );
  }
}
