import React, { Component } from "react";

export default class WindSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="card">
        <div className="settings">Wind Turbine Settings</div>
        <div className="clearfix" />
      </div>
    );
  }
}
