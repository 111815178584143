import React, {useState, useEffect, useCallback, useContext } from 'react'
import ReactEcharts from "echarts-for-react/lib/core";
import UserContext from "../../services/user-context"
import echarts from "echarts/lib/echarts";
import Decimal from 'decimal.js-light'
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataZoom";
import 'echarts/lib/component/legendScroll';
import { STYLES, BASE_OPTIONS, COST_AXES } from './options'

const NAME_RE=/^(.*)(\{currency\.(major|minor)\})(.*)$/

const toMinor = amt => isNaN(amt)?amt:Decimal(amt).mul(100).toDecimalPlaces(1).toNumber(),
    toMajor = amt => isNaN(amt)?amt:Decimal(amt).div(100).toDecimalPlaces(2).toNumber(),    
    noop = amt => amt

const calculateCost = ({ price, charges }) => {
    const __havePrice = typeof(price)!=='undefined' && price!==null,
        __haveCharges = typeof(charges)!=='undefined' && charges!==null
    if(__havePrice || __haveCharges) {
        const __p = __havePrice?price:0,
            __c = __haveCharges?charges:0 
        console.log(`Price : ${__p} (${__havePrice}, ${price}), charges=${__c} (${__haveCharges}, ${charges})`)
        return Decimal(__p+__c)
            .div(100)
            .toDecimalPlaces(2)
            .toNumber()
    }
    return undefined
}

export default function CostPlot({load=null, predictedLoad=null,
    tariffs=null, wholesale=null
}) {
	const [chart, setChart] = useState(null)

    const {Currency } = useContext(UserContext)
    const [option, setOption] = useState({
        ...BASE_OPTIONS, 
        yAxis: [
            {...COST_AXES[0], name: `Price (${Currency.minorSymbol})`},
            {...COST_AXES[1], name: `Cost (${Currency.symbol})`}
        ]})

    const [series, setSeries ] = useState([])


    const updateSeries=useCallback((seriesName, data, attr='price', scale=noop) => {
        const __style = STYLES.get(seriesName)
        if(typeof(attr) !== 'function') {
            const __a = attr
            attr = (v) => scale(v[__a])
        }
        if(!__style) {
            console.error(`[updateSeries] : No style for ${seriesName}`)
            return 
        }

        if(!Array.isArray(data) || data.length===null) {
            setSeries(s => {
                const __updated = {...s}
                delete __updated[seriesName]
                return __updated
            })
            return 
        }

        setSeries(s => {
            const __updated = {...s}
            const __values = data.map(attr)
            // Make sure there are some
            const __vCnt = __values.reduce((hasValues, cv) =>
                hasValues || (typeof(cv)!=='undefined' && cv!==null),
                false)
            // Return empty array if no undefined values present
            // __updated[seriesName] = __vCnt>0?__values:[]
            __updated[seriesName] = __vCnt>0?__values.map(v => v?Decimal(v) .toDecimalPlaces(2).toNumber():v):[]
            return __updated
        })
    }, [setSeries]) 

    useEffect(() => {
        updateSeries('actual_cost', load, calculateCost)
    }, [load])

    useEffect(() => {
        updateSeries('predicted_cost', predictedLoad, calculateCost)
    }, [predictedLoad])

    useEffect(() => {
        updateSeries('tariffs', tariffs, 'rate', toMinor)
        updateSeries('charges', tariffs, 'charges', toMinor)
    }, [tariffs])

    useEffect(() => {
        updateSeries('wholesale', wholesale)
    }, [wholesale])

    useEffect(() => { 
        if(!chart) return 

		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

        setOption(o => ({
            ...__opt, 
            series: Object.entries(series)
                .map(([k, v]) => {
                    // Get the style
                    if(v.length===0) return null
                    const __style = STYLES.get(k) 
                    if(!__style) return null
                    
                    let { name } = __style 
                    const __parts = NAME_RE.exec(name)
                    if(__parts!==null) {
                        // Replace the currency symbol
                        let __sym
                        switch(__parts[3]) {
                            case 'minor':
                                __sym=Currency.minorSymbol
                                break
                            case 'major':
                                __sym=Currency.symbol
                                break
                            default: 
                                __sym='?'
                                break
                        }
                        name = `${__parts[1]}${__sym}${__parts[4]}`
                    }
                    return { ...__style, name, type: 'line', xAxisIndex: 0, data: v}
                })
                .filter(s => s!==null)
        }))

    }, [Currency, chart, series, setOption])

    return (
        <ReactEcharts
            ref={(e) => { setChart(e); }}
            notMerge={true}
            lazyUpdate={true}
            echarts={echarts}
            option={option}
            style={{ height: "350px", width: "100%" }}
        />

    )
}