import React, { Component } from "react";
//import { notify } from "../../utility/ToastContainer";
import MeterServices from "../../services/MeterServices";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const tuos_options = [
  { value: "DTS-D1 non-LEU", label: "DTS-D1 non-LEU" },
  { value: "DTS-D2 non-LEU", label: "DTS-D2 non-LEU" },
  { value: "DTS-D2 LEU", label: "DTS-D2 LEU" },
  { value: "DTS-T non-LEU", label: "DTS-T non-LEU" },
  { value: "DTS-T LEU", label: "DTS-T LEU" }
];

const duos_options = [
  { value: "DG6", label: "DG6" },
  { value: "DG6a", label: "DG6a" },
  { value: "DG6b", label: "DG6b" },
  { value: "DG7", label: "DG7" },
  { value: "DG7a", label: "DG7a" },
  { value: "DG7b", label: "DG7b" },
  { value: "DG8", label: "DG8" },
  { value: "DG8a", label: "DG8a" },
  { value: "DG8b", label: "DG8b" }
];

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

const dateNow = new Date();

export default class AddProfileForm extends Component {
  constructor(props) {
    super(props);
    //this.innerRef = React.createRef();
    this.state = {
      type: this.props.type || "",
      startDate: new Date(this.props.valid_from) || dateNow,
      today: dateNow,
      currency: this.props.currency || "",
      duosActive: false,
      tuosActive: false,
      billable_id: "",
      active: true,
      tuosSelected: this.props.tuos || null,
      duosSelected: this.props.duos || null,
      errorCount: 0,
      errors: {
        tuos: "",
        duos: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { currency, billable_id, type, valid_from } = this.props;
      this.setState({
        billable_id: billable_id,
        currency: currency,
        type: type,
        valid_from: valid_from
      });
      //this.innerRef.current.focus();
    }, 1);
  }

  activateField = e => {
    this.setState({
      [`${e.target.name}Active`]: true
    });
  };

  disableField = e => {
    this.setState({
      [`${e.target.name}Active`]: false
    });
  };

  handleStartDateChange = date => {
    //console.log("date", date);
    this.setState({
      startDate: date
    });
  };

  duosChange = duosSelected => {
    if (duosSelected) {
      this.setState({ duosActive: true });
    } else {
      this.setState({ duosActive: false });
    }

    let errors = this.state.errors;
    switch (duosSelected) {
      case "select":
        errors.select = !duosSelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, duosSelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  tuosChange = tuosSelected => {
    if (tuosSelected) {
      this.setState({ tuosActive: true });
    } else {
      this.setState({ tuosActive: false });
    }

    let errors = this.state.errors;
    switch (tuosSelected) {
      case "select":
        errors.select = !tuosSelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, tuosSelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  // sendStatusNotification = (type, msg) => {
  //   let className =
  //     type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
  //   let toastMsg = {
  //     msg: msg,
  //     className: className
  //   };
  //   notify(toastMsg);
  // };

  addProfile = (event, errors, values) => {
    event.preventDefault();
    const currency = this.props.currency;
    const billable_id = this.props.billable_id;
    const profile_id = this.props.profile_id;
    const type = this.props.type;
    const tuos_selected = this.state.tuosSelected;
    const duos_selected = this.state.duosSelected;
    const tuos = tuos_selected.value;
    const duos = duos_selected.value;

    const startDate = this.state.startDate;
    //console.log("startDate, ", startDate);
    const valid_from = moment(startDate)
      .startOf("day")
      .toISOString();
    //const valid_from = '2020-05-19T15:46:15.925Z'
    //console.log("valid_from, ", valid_from);
    let profile = {
      currency: currency,
      tuos: tuos,
      duos: duos,
      valid_from: valid_from
    };
    //console.log("profile add", profile);
    if (type === "edit") {
      return MeterServices.editProfile(profile, profile_id, billable_id)
        .then(response => {
          this.props.closeForm("success", "Profile is updated successfully.");
          //this.closeForm();
        })
        .catch(err => {
          this.props.closeForm("danger", err);
        });
    } else if (type === "add") {
      return MeterServices.addProfile(profile, billable_id)
        .then(response => {
          if (response.data) {
            this.props.closeForm("success", "Profile is created successfully.");
            this.setState({
              tuos: "",
              duos: "",
              valid_from: dateNow,
              tuosSelected: null,
              duosSelected: null
            });
            //this.closeForm();
          } else {
            this.props.closeForm("danger", "Profile already exists");
          }
        })
        .catch(err => {
          this.props.closeForm("danger", err);
          console.log("err", err);
        });
    } else {
      console.log(" error: no type");
    }
  };

  // closeForm = event => {
  //   if (this.props.closeForm) {
  //     this.props.closeForm();
  //   }
  // };

  render() {
    const {
      startDate,
      formValid,
      errorCount,
      duosSelected,
      tuosSelected,
      today
    } = this.state;
    //const meter_id = this.props.meter_id;
    //console.log('meter_id', meter_id)
    const { type } = this.props;
    //const currencySelected = marketTypeOpt[0]

    var duosActive, tuosActive;

    if (type === "edit") {
      duosActive = tuosActive = true;
    } else {
      duosActive = this.state.duosActive;
      tuosActive = this.state.tuosActive;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addProfile}>
            <div className="form-group">
              {type === "edit" ? (
                <div></div>
              ) : (
                <div>
                  <div className="form-group col-xs-8">
                    <div className="labelDate2">
                      <label className="on">Valid from date</label>
                      <DatePicker
                        selected={startDate}
                        onChange={this.handleStartDateChange}
                        locale="en"
                        name="valid_from"
                        dateFormat="dd/MM/yyyy"
                        minDate={today}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="labelField">
              <label className={duosActive ? "on" : ""}>DUoS</label>
              <Select
                ref={this.innerRef}
                value={duosSelected}
                onChange={this.duosChange}
                options={duos_options}
                placeholder={"Select DUoS"}
                name="duos"
                inputId="duos"
              />
            </div>
            <div className="labelField">
              <label className={tuosActive ? "on" : ""}>TUoS</label>
              <Select
                value={tuosSelected}
                onChange={this.tuosChange}
                options={tuos_options}
                placeholder={"Select TUoS"}
                name="tuos"
                inputId="tuos"
              />
            </div>

            <div className="form-group">
              <button
                disabled={
                  formValid === false ||
                  errorCount !== 0 ||
                  !duosSelected ||
                  !tuosSelected
                }
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false ||
                errorCount !== 0 ||
                !duosSelected ||
                !tuosSelected
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
