import React, { Component } from "react";
import UserServices from "../../services/UserServices";
//import OrganisationRows from "./OrganisationRows";
import { Modal, Alert } from "react-bootstrap";
import AddOrganisationForm from "./AddOrganisationForm";
//import ToastContainer from "../../utility/ToastContainer";
import UserTable from "./UserTable";
//import Notifications from "../../utility/Notifications.jsx";

export default class OrganisationTable extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      getInactive: "Include",
      organisations: [],
      organisation: {},
      org_id: "",
      name: "",
      line1: "",
      line2: "",
      city: "",
      county: "",
      country: "",
      postcode: "",
      active: true,
      show: false,
      alerts: null,
      errors: null,
      showAlert:false,
      variant:'', msg:'',
    };
  }
  componentDidMount() {
    this.getOrganisationList();
  }

  getOrganisationList = altKey => {
    //console.log("getorganisations");
    UserServices.getOrganisationList()
      .then(response => {
        let organisations = response.data;
        //  console.log("altKey", altKey);
        console.log("organisations", organisations);
        var org;
        if (altKey) {
          //  console.log("altKey111", altKey);
          const foundOrg = organisations.filter(item => item.altKey === altKey);
          org = foundOrg[0];
        } else {
          //  console.log("altKey222", altKey);
          org = organisations[0];
        }
        //console.log('org1', org)
        //console.log('org.name2', org.name)
        const id = org._id;
        this.setState({
          organisations: organisations,
          name: org.name,
          org_id: org._id,
          getInactive: "Include",
          active: org.active
        });
        this.child.current.getUserList(id);
        if (
          typeof org.address === "object" &&
          org.address !== null &&
          Object.keys(org.address).length !== 0
        ) {
          this.setState({
            organisation: {
              ...this.state.organisation,
              name: org.name,
              active: org.active,
              altKey: org.altKey,
              address: {
                line1: org.address.line1,
                line2: org.address.line2,
                city: org.address.city,
                county: org.address.county,
                country: org.address.country,
                postcode: org.address.postcode
              }
            }
          });
        } else {
          this.setState({
            organisation: {
              ...this.state.organisation,
              name: org.name,
              altKey: org.altKey,
              active: org.active,
              address: {}
            }
          });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ clusters: [] });
      });
  };

  getInactive = () => {
    const getInactive = this.state.getInactive;
    if (getInactive === "Include") {
      UserServices.getOrganisationsAll()
        .then(response => {
          let organisations = response.data;
          var org = organisations[0];
          const id = org._id;
          this.setState({
            organisations: organisations,
            name: org.name,
            org_id: org._id,
            active: org.active,
            getInactive: "Hide"
          });
          this.child.current.getUserList(id);
          if (
            typeof org.address === "object" &&
            org.address !== null &&
            Object.keys(org.address).length !== 0
          ) {
            this.setState({
              organisation: {
                ...this.state.organisation,
                name: org.name,
                active: org.active,
                altKey: org.altKey,
                address: {
                  line1: org.address.line1,
                  line2: org.address.line2,
                  city: org.address.city,
                  county: org.address.county,
                  country: org.address.country,
                  postcode: org.address.postcode
                }
              }
            });
          } else {
            this.setState({
              organisation: {
                ...this.state.organisation,
                name: org.name,
                altKey: org.altKey,
                active: org.active,
                address: {}
              }
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ clusters: [] });
        });
    } else {
      this.getOrganisationList();
    }
  };

  getOrganisationByKey = altKey => {
    //console.log("getorganisations", altKey);
    UserServices.getOrganisationByKey(altKey)
      .then(response => {
        let organisation = response.data;
        // console.log("organisation by key", organisation);
        const id = organisation._id;
        this.setState({ name: organisation.name, org_id: id });
        this.child.current.getUserList(id);
        if (
          typeof organisation.address === "object" &&
          organisation.address !== null
        ) {
          this.setState({
            organisation: {
              ...this.state.organisation,
              name: organisation.name,
              active: organisation.active,
              address: {
                line1: organisation.address.line1,
                line2: organisation.address.line2,
                city: organisation.address.city,
                county: organisation.address.county,
                country: organisation.address.country,
                postcode: organisation.address.postcode
              }
            }
          });
        } else {
          this.setState({
            organisation: {
              ...this.state.organisation,
              name: organisation.name,
              active: organisation.active,
              address: {}
            }
          });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ clusters: [] });
      });
  };

  onOrganisationSelected = e => {
    if (e.target.value !== "") {
      //console.log('e.target.value', e.target.value)
      const org = this.state.organisations[e.target.value];
      this.setState({
        selected: org,
        name: org.name,
        org_id: org._id,
        active: org.active
      });
      const id = org._id;
      this.child.current.getUserList(id);
      if (
        typeof org.address === "object" &&
        org.address !== null &&
        Object.keys(org.address).length !== 0
      ) {
        this.setState({
          organisation: {
            ...this.state.organisation,
            name: org.name,
            active: org.active,
            altKey: org.altKey,
            address: {
              line1: org.address.line1,
              line2: org.address.line2,
              city: org.address.city,
              county: org.address.county,
              country: org.address.country,
              postcode: org.address.postcode
            }
          }
        });
      } else {
        this.setState({
          organisation: {
            ...this.state.organisation,
            name: org.name,
            active: org.active,
            altKey: org.altKey,
            address: null
          }
        });
      }
    }
  };

  editOrganisation = e => {
    //console.log('e.target.value ',e.target.value )
    //console.log('organisation', organisation);
    const organisation = this.state.organisation;
    const address = organisation.address;
    //console.log('organisation edit', organisation);
    this.setState({ showModalEdit: true });
    if (
      typeof address === "object" &&
      address !== null &&
      Object.keys(address).length !== 0
    ) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  };

  closeModal=()=>{
    this.setState({ showModalEdit: false, showModalAddOrganisation: false, });
  }

  closeEdit = (variant, msg) => {
    this.setState({ showModalEdit: false, variant:variant, msg:msg });
    this.onShowAlert();
  };

  // notify = (val, msg) => {
  //   console.log("type", val, msg);
  //   //let $this = this;
  //   this.setState({
  //     alerts: {
  //       type: "val",
  //       message: "msg"
  //     }
  //   });
  //   this.setState({ showModalEdit: false,  });
  // };

  handler = val => {
    console.log(val);
    this.setState({
      someVar: val
    });
  };

  addOrganisation = () => {
    //console.log('Organisation add')
    this.setState({ showModalAddOrganisation: true });
  };

  closeAddOrganisation = (variant, msg) => {
    this.setState({ showModalAddOrganisation: false, variant:variant, msg:msg });
    this.onShowAlert();
    this.getOrganisationList();
  };

  setShow=()=>{
    this.setState({ showAlert:false });
  }

  onShowAlert = ()=>{
    console.log('onShowAlert')
    this.setState({showAlert:true},()=>{
      window.setTimeout(()=>{
        this.setState({showAlert:false})
      },3000)
    });
  }

  closeModal = () => {
      this.setState({  showModalAddOrganisation: false, showModalEdit: false,  });
  }


  render() {
    const {
      organisations,
      show,
      organisation,
      org_id,
      getInactive,
      active, showAlert, variant, msg,
    } = this.state;
    //console.log('org_id org table', org_id)
    //const orgId = this.props.orgId; className="top-admin"
    //console.log('organisations render', organisations)
    const altKey = organisation.altKey;
    //console.log('organisation render ', organisation)
    //console.log('show', this.state.show)
    var line1, line2, city, county, country, postcode;
    if (
      typeof organisation.address === "object" &&
      organisation.address !== null
    ) {
      line1 = organisation.address.line1;
      line2 = organisation.address.line2;
      city = organisation.address.city;
      county = organisation.address.county;
      country = organisation.address.country;
      postcode = organisation.address.postcode;
    }

    return (
      <div>
        <div className="card">
          <div className="settings">
          { showAlert===true
            ?(  <Alert variant={variant} onClose={() => this.setShow()} dismissible>
                <Alert.Heading>{msg}</Alert.Heading>
              </Alert>)
              :<div></div>
         }
            <div className="col-lg-12">
              <div className="headerTable">
                <h2>Organisation</h2>
                <select
                  className="dropdown"
                  onChange={this.onOrganisationSelected}
                >
                  {organisations.map((option, index) => (
                    <option key={index} value={index}>
                      {option.name}
                    </option>
                  ))}
                </select>

                <button
                  className="inactive btn-round"
                  onClick={this.getInactive}
                >
                  {getInactive} Inactive
                </button>
                <button
                  className="add btn-round "
                  onClick={this.addOrganisation}
                >
                  Add Organisation
                </button>
              </div>

              <table className="table-market" id="org-table">
                <thead>
                  <tr>
                    <th>Active</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {organisation.active ? <td>Yes</td> : <td>No</td>}
                    <td>{organisation.name}</td>
                    <td>
                      {line1}&emsp;{line2}&emsp;{city}&emsp;{county}&emsp;
                      {country}
                      &emsp;{postcode}
                    </td>

                    <td>
                      <button
                        onClick={() => this.editOrganisation()}
                        className="editDelete"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <UserTable orgId={org_id} ref={this.child} />
            <div>
              <Modal
                backdrop="static"
                keyboard={false}
                show={this.state.showModalAddOrganisation}
                onHide={this.closeModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="textCenter">
                    Add Organisation
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "relative"
                  }}
                >
                  <div>
                    <AddOrganisationForm
                      getOrganisationList={this.getOrganisationList}
                      type={"add"}
                      edit={false}
                      show={false}
                      active={true}
                      closeForm={this.closeAddOrganisation}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div>
              <Modal
                backdrop="static"
                keyboard={false}
                show={this.state.showModalEdit}
                onHide={this.closeModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="textCenter">
                    Edit Organisation
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: "relative"
                  }}
                >
                  <div>
                    <AddOrganisationForm
                      altKey={altKey}
                      getOrganisationList={this.getOrganisationList}
                      getInactive={this.getInactive}
                      getOrganisationByKey={this.getOrganisationByKey}
                      orgId={this.state.org_id}
                      name={this.state.name}
                      line1={line1}
                      line2={line2}
                      city={city}
                      county={county}
                      country={country}
                      postcode={postcode}
                      active={active}
                      type={"edit"}
                      show={show}
                      edit={true}
                      closeForm={this.closeEdit}
                      notify={this.notify}
                      alerts={null}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
