import React, { Component } from "react";
import imgCoins from "../../assets/svg/coins.svg";

export default class SavingsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const percentage = 22;
    const savings = 100;
    const money = "€";

    return (
      <div>
        <div className="component-card mini-card load">
          <img alt={"coins"} src={imgCoins} />
          <h5>Savings</h5>
          <div className="component-data">
            <p className="main-data">
            <span>{money}&nbsp;</span>{savings}
            </p>
            <h6 className="units"> Percentage {percentage} %</h6>
          </div>
        </div>
      </div>
    );
  }
}
