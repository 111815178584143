import React, {useState} from 'react'
import LoadWidget from "./load.jsx";
import MarketWidget from "./market.jsx";
import SavingsWidget from "./savings.jsx";
import SolarWidget from "./solar.jsx";

export default function WidgetPanel({load=null, solar=null}) {
    const [priceKwh, setPriceKwh] = useState(null)
    const [extraCharges, setExtraCharges] = useState(null)

    return (
        <div className="component-container">
            <SavingsWidget />
            <MarketWidget
                price_kwh={priceKwh}
                extra_charges={extraCharges}
            />
            <LoadWidget load={load} />
            <SolarWidget solar={solar}/>
        </div>

    )
}