import React, { Component } from "react";
import imgMarket from "../../assets/svg/chart_1.svg";

export default class MarketWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { extra_charges = null, price_kwh = null} = this.props ;
    // console.log(`widgets::market - price=${price_kwh}, charges=${extra_charges}`)
    const moneyWidget = "c";

    return (
      <div>
        <div className="component-card mini-card load">
            <img alt={"market"} src={imgMarket} />
          <h5>Electricity Prices</h5>
          <div className="component-data">
            <p className="main-data">{price_kwh!==null?price_kwh:'--'}&nbsp;<span>{moneyWidget}/kWh</span></p>
            <h6>Extra Charges  {extra_charges!==null?extra_charges:'--'}{moneyWidget}/hour </h6>
          </div>
        </div>
      </div>
    );
  }
}
