import React, { useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OrgTabView from '../OrgTabView'
import ClusterLoad from './ClusterLoad'
import SiteLoad from './SiteLoad'
import OrganisationLoad from './OrganisationLoad'

export default function Load() {
    return (<Container fluid>
        <OrgTabView page="load" 
            ClusterPage={ClusterLoad}
            OrganisationPage={OrganisationLoad}
            SitePage={SiteLoad}
            daterange/>
    </Container>)
}