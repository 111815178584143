import React, { Component, useCallback, useEffect, useContext } from "react";
import LocationChooser from './LocationChooser.jsx'
import LeafletMap from './LeafletMap.jsx'
import { Alert, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { Dimmer, Loader } from "semantic-ui-react"
import ConfirmDialog from "./ConfirmDialog.jsx"
import './ui-components.css'

export function noop() { }

export function IconButtonLight({ icon, label=null, link=null, onClick=noop, size="lg"}) {
    const location = useLocation() 

    let __button = <Button as="span" variant="light"
            onClick={onClick}
            style={{
                fontSize: "1.5rem", 
                color:"#888",
                padding: "0.2rem",
            }}>
        <i className={`fas ${icon}`}/>
        </Button>

    if(link) {
        __button = <Link to={{
            pathname: "link",
            state: {
                from: location.pathname
            }}}>{__button}</Link>
    }

    if(label) {
        // Render an overlay for the tooltip
        return (<OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 150 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    { label }
                </Tooltip>
            )}>
                {__button}
        </OverlayTrigger>)
    }
    return __button
}

export function FloatingButton({ icon, label=null, link=null, onClick=noop, size="lg"}) {
    const location = useLocation() 

    let __button = <Button as="span" variant="light"
            onClick={onClick}
            style={{
                fontSize: "1.5rem", 
                color:"#888",
                padding: "0.2rem",
            }}>
        <i className={`fas ${icon}`}/>
        </Button>

    if(link) {
        __button = <Link to={{
            pathname: "link",
            state: {
                from: location.pathname
            }}}>{__button}</Link>
    }

    let __content = (<div style={{
        position: "absolute",
        right: "1rem",
        marginTop: "0.5rem",
        marginRight: "0.5rem",
        zIndex: 10
    }}>{__button}</div>)

    if(label) {
        // Render an overlay for the tooltip
        return (<OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 150 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    { label }
                </Tooltip>
            )}>
                {__content}
        </OverlayTrigger>)
    }
    return __content
}

function IconButton({icon, label, onClick=noop, className="", size='lg', 
    nostack=false, ...otherProps}) {

    const __btnProps = {
        type: "button",
        onClick,
        className: `icon-btn ${className}`,
        ...otherProps
    }

    const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{ label }
		</Tooltip>
    );

	return nostack?
    (
        <button {...__btnProps}>
            <span className={`fa-stack fa-${size}`}>
                <i className={`fas ${icon} fa-stack-1x`}></i>
            </span>
        </button>
    ):
    (
        <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 150 }}
            overlay={renderTooltip}>
            <button {...__btnProps}>
                <span className={`fa-stack fa-${size}`}>
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className={`fa ${icon} fa-stack-1x btn-white`}></i>
                </span>
            </button>
        </OverlayTrigger>
	)
}

function AddButton({...props}) {
    const __props = { label: 'Add', ...props}
    
    return (
        <IconButton className="icon-btn-add" {...__props} icon="fa-plus"/>
    )
}

class SaveButton extends Component {
    render() {
        const __props = { className: "icon-btn-save", label: 'Save', ...this.props}
        return (
            <IconButton {...__props} icon="fa-save"/>
        )
    }
}

class NextButton extends Component {
    render() {
        const __props = { className: "icon-btn-save", label: 'Next', ...this.props}
        return (
            <IconButton {...__props} icon="fa-angle-double-right"/>
        )
    }
}

class CancelButton extends Component {
    render() {
        const __props = { className: "icon-btn-cancel", label: 'Cancel', ...this.props}
        return (
            <IconButton {...__props} icon="fa-times"/>
        )
    }
}

const OutlineIconButton = ({variant='primary', icon, size='sm', className="", ...props}) => {
    const __props = {
        variant: `outline-${variant}`, 
        size: "sm", 
        className: `${className} icon-only`,
        ...props} 
    return (<Button {...__props}><i className={`fas fa-${icon}`}/></Button>)
}

export function EditButton({outline=false,className="", variant="primary", ...props}) {
    if(!outline) {
        const __props = { className: `${className} icon-btn-edit`, label: 'Edit', ...props}
        return (
            <IconButton {...__props} icon="fa-pencil-alt"/>
        )
    }
    else {
        return <OutlineIconButton {...props} variant={variant} label="Edit" icon="pencil-alt"/>
    }
}


export function DeleteButton({outline=false, variant="danger", className="", ...props}) {
    if(!outline) {
        const __props = { className: `${className} icon-btn-delete`, label: 'Delete', ...props}
        return (
            <IconButton {...__props} icon="fa-trash-alt"/>
        )
    }
    else {
        return <OutlineIconButton {...props} variant={variant} label="Delete" icon="trash-alt"/>
    }
}

class CloseButton extends Component {
    render() {
        const __props = { className: "icon-btn-close", label: 'Close', ...this.props}
        return (
            <IconButton {...__props} icon="fa-times-alt" nostack/>
        )
    }
}

function PlayButton({...props}) {
    const __props = { label: 'Add', ...props}
    
    return (
        <IconButton className="icon-btn-add" {...__props} icon="fa-play"/>
    )
}

function Toggle({ children, eventKey, onToggle }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => onToggle && onToggle(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" 
            className="btn btn-link"
            onClick={decoratedOnClick}>
            <span>
                <i className={`fas fa-chevron-${isCurrentEventKey?"down":"right"}`}></i>
            </span>
        </button>
    )
}

function ConfirmAlert({className="", heading=null, text=null, yes="Yes", onYes=noop, no="No", onNo=noop, 
        buttonsInline=true, rightJustify=false, variant="success"}) {

    const onKeyDown=useCallback(e => {
        if(e.key==='Escape') {
            e.stopPropagation()
            onNo()
        }
    }, [])

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => document.removeEventListener("keydown", onKeyDown)
    }, [])

    const clazz = rightJustify?"justify-content-end d-flex":""
    return (
        <Alert show variant={variant} className="pull-right">
            {heading!==null?(<Alert.Heading>{heading}</Alert.Heading>):null}
            {buttonsInline?
            (<div className={`${clazz} align-middle`}>
                {text!==null?(<span style={{paddingRight: "1em"}}>{text}</span>):null}
                <span style={{paddingRight: "1em"}}>
                    <Button size="sm" onClick={e=> { e.stopPropagation(); onNo(e)}} variant="secondary">{no}</Button>
                </span>
                <span>
                    <Button size="sm" onClick={e=> { e.stopPropagation(); onYes(e)}} variant="primary">{yes}</Button>
                </span>
            </div>):
            (
            <div>
                {text!==null?(<React.Fragment><p className={`align-middle ${clazz}`}>{text}</p><hr/></React.Fragment>):null}
                <div className="d-flex justify-content-end">
                    <span style={{paddingRight: "1em"}}>
                        <Button onClick={e=> { e.stopPropagation(); onNo(e)}} variant="secondary">{no}</Button>
                    </span>
                    <span>
                        <Button onClick={e=> { e.stopPropagation(); onYes(e)}} variant="primary">{yes}</Button>
                    </span>
                </div>
            </div>
            )}
        </Alert>
    )
}

function Loading({active=false, text="Loading"}) {
    return (
        <Dimmer active={active} inverted>
            <Loader active={active}>{text}</Loader>
        </Dimmer>
    )
}

function Warning({children, title="Warning", style}) {
    const props = { style }
    return (
        <Card {...props}>
            <Card.Body>
                <Card.Title>
                    <i style={{ color: "orange", paddingRight: "0.5em"  }} className="fas fa-exclamation-triangle"/>
                    {title}
                </Card.Title>
                <Card.Text>{children}</Card.Text>
            </Card.Body>
        </Card>
    )
}

function Info({children, title="Info", style}) {
    const props = { style }
    return (
        <Card {...props}>
            <Card.Body>
                <Card.Title>
                    <i style={{ color: "green", paddingRight: "0.5em" }} className="fas fa-info-circle"/>
                    {title}
                </Card.Title>
                <Card.Text>{children}</Card.Text>
            </Card.Body>
        </Card>
    )
}

export function LockSwitch({id="", locked=false, onChange=()=>{}}) {
    return (
        <label id={id} className="lock-switch">
            <input type="checkbox" checked={locked} onChange={onChange} />
            <span className="switch "><i className={`fas ${locked?'fa-lock':'fa-unlock'}`}/></span>
        </label>
    );
}


export { IconButton, AddButton, SaveButton,
    NextButton, CancelButton, CloseButton, PlayButton,
    Toggle, ConfirmAlert, ConfirmDialog, LocationChooser, LeafletMap, Loading, Warning, Info,
}

export function Overlay({children=null}) {
    return(<div>
        <div className="re-overlay-content">{children}</div>
        <div className="re-overlay"></div>
    </div>)
}


export function DeleteButtonOutline({classname="", ...props}) {
    const __props = {
        variant: "outline-secondary", 
        size: "sm", 
        className: `${classname} icon-only`,
        ...props} 
    return (<Button {...__props}><i className="fas fa-trash-alt"/></Button>)
}