import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import Fetch from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'
import AccuracyTable from './AccuracyTable'
const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

export default function OrganisationLoad({startDate, endDate}) {
    const [organisation, setOrganisation] = useState(null)
    const [loadData, setLoadData] = useState(null)
    const [predictedLoadData, setPredictedLoadData] = useState(null)
    
    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(!user) return

        async function __getOrg() {
            try {
                const o = await request.get(`/api/organisation/${user.organisationId}`)
                setOrganisation(o)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        __getOrg()

        return() => {
            setOrganisation(null)
        }
    }, [user])

    const __loadData = useCallback(async (_id) => {
        // console.log(`[OrgLoad] Fetch load data for ${organisation.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        // Actual load
        Fetch(`/api/meter/organisation/${user.organisationId}/readings`, 
                __query, 
                setLoadData, 
                ["timestamp", "reading"])
        // Predicted load
        Fetch(`/api/organisation/${user.organisationId}/predict/load`, __query, setPredictedLoadData)
    }, [startDate, endDate, organisation])

    useEffect(() => {
        if(!organisation) return
        __loadData(organisation._id)

        return () => {
            setLoadData(null)
            setPredictedLoadData(null)
        }
    }, [startDate, endDate, organisation])

    return organisation && (<Container fluid>
        <h2>Load - {organisation.name}</h2>
        <div>
            {(loadData || predictedLoadData) && 
                <EnergyPlot 
                    load={loadData} 
                    predictedLoad={predictedLoadData} 
                    startTime={startDate}
                    endTime={endDate}/>}
            <AccuracyTable observed={loadData} predicted={predictedLoadData}/>
        </div>
    </Container>)
}