import React from "react";
import moment from "moment";

export default function ChargesRows({
  charge,
  charge_id,
  deleteCharge,
  editCharge,
}) {
  const valid_to = charge.valid_to;
  const valid_from = charge.valid_from;
  const today = moment().startOf("day").toISOString();
  const date_to = moment(valid_to).format("DD-MM-YYYY");
  const valid_start = moment(valid_from).format("DD-MM-YYYY");
  const valid_finish =
    valid_to === null || valid_to === "" || valid_to === undefined
      ? ""
      : date_to;

  return (
    <tr>
      <td>{valid_start}- {valid_finish}</td>
      <td>{charge.name}</td>
      <td>{charge.charge_type}</td>
      <td>{charge.price}</td>
      { valid_from >= today ? (
        <td>
          <button
            className="editDelete"
            onClick={() => deleteCharge(charge._id)}
          >
            Delete
          </button>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
}
