import React from "react";
import moment from "moment";

export default function ProfileRow({ profile, editProfile, deleteProfile }) {
  const valid_from = profile.valid_from;
  const today = moment().startOf('day').toISOString();
  const date_from = moment(valid_from).toISOString();
  return (
    <tr>
      <td>{moment(valid_from).format("DD-MM-YYYY")}</td>
      <td>{profile.duos}</td>
      <td>{profile.tuos}</td>

      {date_from === today || date_from > today ? (
        <td>
          <button className="editDelete" onClick={() => editProfile(profile)}>
            Edit
          </button>
          <button className="editDelete" onClick={() => deleteProfile(profile)}>
            Delete
          </button>
        </td>
      ) : (
        <td></td>
      )}


    </tr>
  );
}
