import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'

function LeafletMap({
	latitude=null, longitude=null, 
	height="50vh", width="100%",
	visible=false,
	closable=false,
	text=null,
	onDismiss=()=>{}
}) {
	if(latitude===null || longitude===null) {
		throw new Error('LeafletMap() : latitude and longitude required')
	}
	const [ position, setPosition ] = useState({lat: latitude, lon: longitude})

	useEffect(() => {
		setPosition({lat: latitude, lon: longitude})
	}, [latitude, longitude])

	return visible?(
		<MapContainer style={{ height: height, width: width}} center={position} zoom={13} scrollWheelZoom={true}>
			<TileLayer
			    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
			    <Marker
			      position={position}>
			      {text!==null?
			      	(<Tooltip minWidth={90}>
				        <span>{text}</span>
				      </Tooltip>):null}
			    </Marker>
		</MapContainer>
	):null
}

export default LeafletMap ;