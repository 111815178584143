import {Request} from "../../../services/HttpService";
import { Notify } from '../AlertListener.jsx'

const request = new Request({ service: 'Billable service'}),
    notify=Notify("billing.notifications")


export default async function() {

    const NO_SUPPLIER = [['', 'No supplier']]


    let suppliers = null ;
    async function fetchSuppliers() {
        if(suppliers===null) {
            // Fetch the suppliers
            try {
                const __suppliers = await request.call(`/api/billable/plan/suppliers`)
                suppliers=__suppliers.map(s => [s._id, s.name])
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
                suppliers=[]
            }
        }
        return suppliers
    }

    await fetchSuppliers()

    function lookup(id) {
        if(id===null || typeof(id)==='undefined') return ''
        if(suppliers===null) return '<unknown>'
        for(const [__id, __name] of suppliers) {
            if(__id===id) return __name
        }
        return '<unknown>'
    }

    function getOptions() {
        if(suppliers===null) return NO_SUPPLIER
        return NO_SUPPLIER.concat(suppliers)
    }

    return { lookup, getOptions}
}
