import React, {useState, useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export default function NavigationItem({children=null, key, exact=false, to, ...props}) {
    const [active, setActive] = useState(false)
  
    const location = useLocation() ;
  
    useEffect(() => {
      // console.log(`useEffect() exact=${exact}, pathname=${location.pathname}, to=${to}`)
      setActive((exact && location.pathname === to) ||
        (!exact && location.pathname.startsWith(to))
      )
    }, [exact, location, to, setActive])
  
    return (
        <li className={active?'active':''}>
            <NavLink to={to} {...props}>
                {children}
            </NavLink>
        </li>)
  }
