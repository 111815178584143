import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {
	// BrowserRouter as Router,
	Switch,
	Route,
	Redirect, 
	useLocation
} from "react-router-dom";
import SessionStorage from '../../../services/SessionStorage'
import AuthService from '../../../services/auth'
import {AlertContainer} from '../AlertListener.jsx'
import BillingPlanList from "./BillingPlanList"
import EditBillingPlanForm from "./EditBillingPlanForm"
import BillingPlanPeriodForm from "./BillingPlanPeriodForm"
import CreatePlanFromExisting from "./CreatePlanFromExisting"

const PlanCategory = ({type, visible=true, toggle}) => {
	const __props = visible?
		{
			backgroundColor: 'DarkCyan',
			color: '#eee'
		}:
		{
			backgroundColor: 'DarkGray',
			color: '#222'
		}

	return (<div style={{marginRight: "1rem", color: "white", display: "inline-block", border: "1px solid", padding: "0.25rem", ...__props }}>
		<span style={{margin: "0.25rem"}}>{type}</span>
		<button 
			type="button" 
			className="close"
			onClick={() => toggle(type)}
			style={{borderLeft: "1px solid", paddingLeft: "0.25rem", marginLeft: "0.25rem", ...__props}}>
				<span aria-hidden="true"><i className={`fa fa-eye${visible?'':'-slash'}`}/></span><span className="sr-only">{visible?'Hide':'Show'} {type}</span>
		</button>
	</div>)
}

const OPT_CATEGORY_VISIBLE="billing.plan.list.category.visible"

export default function BillingDashboard({root='/admin'}) {
	const [user, setUser] = useState(null)

	useEffect(() => {
		async function checkLoggedIn() {
			try {
      			setUser(await AuthService.isAuthenticated())
    		} catch (err) {
    			console.error('Error ', err)
    			setUser(null)
    		}
    	}
		checkLoggedIn()

		return () => {
		    setUser(null)
		}
	}, [])

	function ListPlans(props) {
		const [visible, setVisible] = useState([])

		function toggle(t) {
			console.log(`toggle(${t})`)
			for(const pt of visible) {
				if(pt[0]===t) {
					const __v = !pt[1] 
					SessionStorage.set(`${OPT_CATEGORY_VISIBLE}.${t}`, __v)
					pt[1] = __v
					break ;
				}
			}
			setVisible([...visible])
		}

		useEffect(() => {
			const __visible = [["Core", SessionStorage.get(`${OPT_CATEGORY_VISIBLE}.Core`, true)]]
			if(user.isOrgAdmin) __visible.push(['Organisation', SessionStorage.get(`${OPT_CATEGORY_VISIBLE}.Organisation`, true)])
			if(user.isClusterOwner) __visible.push(['Cluster', SessionStorage.get(`${OPT_CATEGORY_VISIBLE}.Cluster`, true)])
			setVisible(__visible)

			return () => setVisible([])
		}, [])

		return (
			<Container fluid>
				<Row>
					<Col lg="12">
						<h3>Billing Plans</h3>
					</Col>
				</Row>
				<Row>
					<Col>
						{visible.map(([t, visible]) => (<PlanCategory key={`btn_${t}`} type={t} visible={visible} toggle={toggle}/>))}
					</Col>
				</Row>
				{visible.map(([type, __visible]) => __visible?(
					<Row key={type}>
						<Col>
							<BillingPlanList type={type} {...props}/>
						</Col>
					</Row>					
				):null)}
			</Container>
		)		
	}

	console.log(`Rendering BillingDashboard(root=${root}) : `, useLocation())

	const __props = { user, root }

	return user!==null?(
		<React.Fragment>
			<AlertContainer tag="billing.notifications" page="billing"/>
          	<Switch>
			    <Route exact path={`${root}`} render={() => <ListPlans {...__props} />}/>
				<Route exact path={`${root}/new`} render={() => <EditBillingPlanForm {...__props}/>}/>
				<Route exact path={`${root}/copy/as/:type(Core|Organisation|Cluster)`} render={({match, props, location}) => 
					(<CreatePlanFromExisting type={match.params.type} {...__props} />)}/>
				<Route exact path={`${root}/:id([a-fA-F0-9]{24})/edit`}
					render={({match, props, location}) => 
						(<EditBillingPlanForm _id={match.params.id} {...__props}/>)}/>
				<Route exact path={`${root}/:planId([a-fA-F0-9]{24})/period/new`}
					render={({match, props, location}) => 
						(<BillingPlanPeriodForm plan_id={match.params.planId} {...__props}/>)}/>
				<Route exact path={`${root}/:planId([a-fA-F0-9]{24})/period/:periodId([a-fA-F0-9]{24})/edit`}
					render={({match, props, location}) => 
						(<BillingPlanPeriodForm plan_id={match.params.planId} _id={match.params.periodId} {...__props}/>)}/>
				<Route>{console.log('dash default')}<Redirect to={`${root}`}/></Route>
          	</Switch>
		</React.Fragment>
	):null
}

