import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { noop } from '../../components/UIComponents'
import {Notify} from '../../components/AlertListener'
import {Request} from "../../../services/HttpService";

import FormControl from './FormControl'
import BatteryContext from './battery-context';

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")
const enotify=Notify("add.battery.notifications")


const BATTERY_URL = {
    type: 'url',
    name: 'battery_url',
    label: 'Battery URL',
    required: true
}

const MQTT_PROXY = {
    type: 'text',
    name: 'mqtt_proxy',
    label: "MQTT Proxy Endpoint",
    required: true
}

const API_KEY = {
    type: 'text',
    name: 'api_key',
    label: 'API Key',
    required: true
}

export default function AddPage3({canContinue=noop, config}) {
    const [properties, setProperties] = useState([])
    const [errors, setErrors] = useState({})
    const { battery, update, onError } = useContext(BatteryContext)

    
    function setValid(name, error) {
        errors[name] = error
        onError(`config.${name}`, error)
        setErrors({...errors})
    }

    async function __loadProperties() {
        const {make, model} = battery
        try {
            const __props = await request.get(`/api/battery/properties/${make}/${model}`)
            // console.log(`[context] Loaded props for model ${model} : `, __props)
            setProperties(__props)
        }
        catch(err) {
            console.error(err)
            enotify.error(`Properties - ${err.message}`)
        }
        return []
    }

    useEffect(() => {
        // setConfig(battery.config)
        if(!battery.model) return
        __loadProperties(battery.make, battery.model)
    }, [config])

    useEffect(() => {
        canContinue(Object.values(errors).reduce((cc, e) => cc && !e, true))
    }, [errors])

    const __onChange = (name, v) => {
        config[name]=v
        update('config', config)
    }

    return (<Container fluid>
        {
            config && properties.length>0?properties.map(p => (
                <Form.Row key={`config_prop_${p.name}`}>
                    <FormControl 
                        descriptor={p} 
                        value={config[p.name]}
                        onChange={v => __onChange(p.name, v) }
                        isValid={setValid}/>
                </Form.Row>
            )):(<em>No properties defined</em>)
        }
    </Container>)
}