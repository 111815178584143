import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap'
import { Switch, Route, useHistory, useLocation  } from "react-router-dom";
import BatteryDetails from './BatteryDetails'
import SessionStorage from "../../services/SessionStorage"

const SELECTED_BATTERY_KEY = 'site.dash.battery.selected' 

const RE=/\/dashboard\/battery(\/[0-9a-fA-F]{24})?/

export default function Battery() {
    const [battery, setBattery] = useState(null)
    
    const history = useHistory()
    const location = useLocation() 

    useEffect(() => {
        // Check if there's an ID already in the url
        const __match = RE.exec(location.pathname)
        if(!__match || !__match[1]) {
            let __selected = SessionStorage.get(SELECTED_BATTERY_KEY, null)
            if(__selected) {
                // Redirect to the last used battery
                history.replace(`/dashboard/battery/${__selected}`)
            }
        }
    }, [location])

    return (
        <Container fluid>
            <Switch>
                <Route exact key="battery_root" path="/dashboard/battery" component={BatteryDetails}/>
                <Route exact key="battery_details" path="/dashboard/battery/:id([a-fA-F0-9]{24})">
                    <BatteryDetails/>
                </Route>
            </Switch>
        </Container>
    )
}
