import React, { Component } from "react";
//import MeterServices from "../../services/MeterServices";
//import UserServices from "../../services/UserServices";
import SolarServices from "../../services/SolarServices";
//import Dropdown from "../../utility/Dropdown2";
import { Modal, Card, Alert } from "react-bootstrap";
import AddPanelForm from "./AddPanel";
import PanelRow from "./PanelRow";
import Dialog from "react-bootstrap-dialog";

export default class PanelsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noPanels: false,
      orgId: "",
      panels: [],
      show: false,
      variant: "",
      msg: "",
      inst_id: "",
      panel_id: "",
      name: "",
      active: true,
      capacity: 0,
      losses: 0,
      tilt: 0,
      azimuth: 0,
      efficiency: 0,
      array_type: 0,
      module_type: 0,
      noInst: false
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const { inst_id, noInst } = this.props;
      this.setState({ inst_id: inst_id, noInst: noInst });
      //this.innerRef.current.focus();
    }, 1);
  };

  getPanelList = inst_id => {
    //const inst_id = this.props.inst_id;
    console.log("1111inst_id", inst_id);
    if (inst_id) {
      SolarServices.getPanelGroup(inst_id)
        .then(response => {
          let panels = response.data;
          console.log("panels", panels);
          if (panels.length) {
            this.setState({
              panels: panels,
              noPanels: false
            });
          } else {
            this.setState({
              panels: [],
              noPanels: true
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ panels: [], noPanels: true });
        });
    } else {
      this.setState({ panels: [], noPanels: true });
    }
  };

  deletePanel = panel => {
    const name = panel.name;
    this.dialog.show({
      body: "Would you like to delete '" + name + "' panel. Proceed ?",
      actions: [
        Dialog.CancelAction(),
        Dialog.Action("Proceed", () => this.deletePanelOK(panel), "btn-warning")
      ],
      bsSize: "medium"
    });
  };

  deletePanelOK = panel => {
    //console.log('panel', panel)
    const panel_id = panel._id;
    const inst_id = panel.pv_installation_id;
    //console.log('inst_id delete', panel_id, inst_id)
    SolarServices.deletePanelGroup(inst_id, panel_id)
      .then(response => {
        this.closeEdit("success", "Panel is deleted successfully.");
        //console.log('deleted')
        this.setState({
          inst_id: inst_id
        });
      })
      .catch(err => {
        this.closeEdit("danger", err);
        console.error(err);
      });
  };

  addPanel = () => {
    //console.log("site add");
    this.setState({ addPanelModal: true, type: "add" });
  };

  editPanel = panel => {
    console.log("panel edit", panel);
    const name = panel.name;
    const active = panel.active;
    const capacity = panel.capacity;
    const losses = panel.losses;
    const tilt = panel.tilt;
    const azimuth = panel.azimuth;
    const efficiency = panel.efficiency;
    const array_type = panel.array_type;
    const module_type = panel.module_type;
    const id = panel.pv_installation_id;
    const panel_id = panel._id;
    this.setState({
      editPanelModal: true,
      type: "Edit",
      name: name,
      active: active,
      capacity: capacity,
      losses: losses,
      tilt: tilt,
      azimuth: azimuth,
      efficiency: efficiency,
      array_type: array_type,
      module_type: module_type,
      inst_id: id,
      panel_id: panel_id
    });
  };

  closeModal = () => {
    this.setState({ editPanelModal: false });
  };

  closeEdit = (variant, msg) => {
    //  console.log("closeEdit");

    const inst_id = this.props.inst_id;
    //console.log('inst_id delete', inst_id)
    this.setState({ editPanelModal: false, variant: variant, msg: msg });
    this.onShowAlert();
    this.getPanelList(inst_id);
    //window.location.reload();
  };
  setShow = () => {
    this.setState({ show: false });
  };

  onShowAlert = () => {
    //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
    });
  };

  render() {
    const {
      show,
      variant,
      msg,
      panels,
      noPanels,
      name,
      active,
      capacity,
      losses,
      tilt,
      azimuth,
      efficiency,
      array_type,
      module_type,
      panel_id
    } = this.state;
    //const org_id = this.props.org_id;
    const { inst_id, noInst } = this.props;
    //console.log('props inst_id', inst_id)
    return (
      <div>
        <div className="settings">
          <Dialog
            ref={el => {
              this.dialog = el;
            }}
          />
          {show === true ? (
            <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>
          ) : (
            <div></div>
          )}

          {noPanels === false ? (
            <div>
              <div className=" headerTable">
                <h4>Panel Group </h4>
              </div>
              <table className="table-market">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Capacity</th>
                    <th>Azimuth</th>
                    <th>Tilt</th>
                    <th>Losses</th>
                    <th>Efficiency</th>
                    <th>Module type</th>
                    <th>Array Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {panels.map(p => (
                    <PanelRow
                      panel={p}
                      key={p._id}
                      editPanel={this.editPanel}
                      deletePanel={this.deletePanel}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : noPanels === true && noInst === false ? (
            <div>
              <Card
                style={{
                  width: "18rem"
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <i
                      style={{
                        color: "orange"
                      }}
                      className="fas fa-exclamation-triangle"
                    ></i>
                    Warning
                  </Card.Title>
                  <Card.Text>No solar panels defined</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="clearfix" />

        <div>
          <Modal
            dialogClassName="custom-dialog"
            backdrop="static"
            keyboard={false}
            show={this.state.editPanelModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Edit Panel Group</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddPanelForm
                  type={"Edit"}
                  inst_id={inst_id}
                  closeForm={this.closeEdit}
                  name={name}
                  active={active}
                  capacity={capacity}
                  losses={losses}
                  tilt={tilt}
                  azimuth={azimuth}
                  efficiency={efficiency}
                  array_type={array_type}
                  module_type={module_type}
                  panel_id={panel_id}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
