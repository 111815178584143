import React, { useState, useEffect, useCallback } from 'react'
import {Form, Col} from 'react-bootstrap'
import { noop } from '../../components/UIComponents'

const SCHEMES = [
    { scheme: 'http:', port: 80},
    { scheme: 'https:', port: 443}
]

const PORT_RE=/^[1-9]?[0-9]{0,4}$/

export default function URLControl({descriptor={}, value="", lg=6,
        onChange=noop, isValid=noop, nopath=false}) 
{
    const [scheme, setScheme] = useState('https:')
    const [host, setHost] = useState('')
    const [port, setPort] = useState("")
    const [path, setPath] = useState('')
    const [portPlaceholder, setPortPlaceholder] = useState("443")
    const [errorFlag, setErrorFlag] = useState({})
    const [error, setError] = useState(false)
    // Don't validate on initialisation
    const [initDone, setInitDone]=useState(false)

    const checkURL=useCallback((__u) => {
        if(!__u || __u.trim().length===0) return 
        try {
            const __url = new URL(__u)
            return [false, __url]
        }
        catch(err) {
            return [ "Malformed URL",
                {
                    protocol: 'https:',
                    hostname: '',
                    pathname: '',
                    port: ''
                }]
        }
    }, [])

    useEffect(() => {
        if(value!=="") {
            // Set initial values
            const [__err, __url] = checkURL(value)
            // Get the scheme
            const [__scheme] = SCHEMES.filter(({scheme}) => scheme===__url.protocol)

            if(__scheme) {
                setScheme(__scheme.scheme)
                setHost(__url.hostname)
                setPath(__url.pathname)
                setPort(__url.port)
            }
            else {
                __err=`Invalid scheme ${__url.protocol}`
                console.error(__err)
                setErrorFlag({...errorFlag, scheme: true})
            }
            setError(__err)
        }
        setInitDone(true)
    }, [])

    useEffect(() => {
        const [__scheme] = SCHEMES.filter((s) => scheme===s.scheme)
        if(__scheme) {
            setPortPlaceholder(__scheme.port)
        }
    }, [scheme])

    useEffect(() => {
        if(!initDone) return
        const { required=false } = descriptor
        // Make sure port is numeric and in range
        const __errs = {}
        let __err=false
        let __port=0
        if(!PORT_RE.test(port)) {
            __errs.port=true
            __err = 'Port must be in range 1-65535'
        }
        else if(port.trim()!=='') {
            __port = Number(port)
            if(port<1 || port>65535) {
                __errs.port=true
                __err = 'Port must be in range 1-65535'
            }
        }
        if(required && host.trim().length===0) {
            __errs.host=true
            __err = 'Hostname required'
        }
        
        if(!__err) {
            // Build the URL
            const __urlParts = [
                `${scheme}//`,
                host
            ]
            // Add port if necessary
            if(__port>0) {
                // Don't add it if it's the default port for the HTTP/HTTPS
                if(!(scheme==='http:' && __port===80) && !(scheme==='https:' && __port===443)) {
                    __urlParts.push(`:${__port}`)
                }
            }
            descriptor.path && __urlParts.push(path)
            onChange(__urlParts.join(''))
        }
        setError(__err)
        setErrorFlag(__errs)

    }, [scheme, host, port, path])

    useEffect(() => {
        if(error===null) return
        isValid(descriptor.name, error)
    }, [error])

    return (<Col lg={12} className="form-group">
        <div>{descriptor.required?'(*) ':''}{descriptor.label}</div>
        <Form.Row>
        <Col lg={2}>
            <Form.Control custom 
                    name="uri_scheme"
                    as="select"
                    onChange={e => setScheme(e.target.value)} 
                    value={scheme}>
                {SCHEMES.map(({scheme}) => <option key={`input_${descriptor.name}_scheme_${scheme}`} value={scheme}>{scheme}//</option>)}
            </Form.Control>
        </Col>
        <Col lg={4}>
            <Form.Control
                type="text"
                placeholder="Host/IP Addr"
                onChange={e => setHost(e.target.value)}
                value={host} 
                isInvalid={!!errorFlag.host}/>
        </Col>
        <Col lg={1}>
            <Form.Control
                type="text"
                placeholder={`Port (${portPlaceholder})`}
                onChange={e => setPort(e.target.value)}
                value={port} 
                isInvalid={!!errorFlag.port}/>
        </Col>
        {descriptor.path && <Col lg={5}>
            <Form.Control
                type="text"
                placeholder="Path/query"
                onChange={e => setPath(e.target.value)}
                value={path} 
                isInvalid={!!errorFlag.path}/>
        </Col>}

        </Form.Row>
        <div 
            style={{display: !!error?'block':'none'}}
            className="invalid-feedback">
            {error}
        </div>
    </Col>)
}        