import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, Card, Dropdown } from 'react-bootstrap'
import { EditButton, DeleteButton, ConfirmAlert } from '../UIComponents.jsx'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Request} from "../../../services/HttpService";
import {friendlyTimeFormatter, Currencies} from "../../../utility";
import { Notify } from '../AlertListener.jsx'
import ElectricitySupplier from './electricity-supplier'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")

const { SearchBar } = Search;

export default function BillingPlanList({user=null, root, type=null}) {
	const [canManage, setCanManage]=useState(false)
	if(type===null) {
		throw new Error('"type" required for BillingPlanList')
	}

	const [plans, setPlans] = useState([])
	const [suppliers, setSuppliers] = useState(null)
	const [deleteId, setDeleteId] = useState(null)
	useEffect(() => {
		const __load = async () => {
			try {
				const [__suppliers, __plans] = await Promise.all([
					ElectricitySupplier(),
					request.call(`/api/billable/plan?type=${type}&includePeriods`)])
				setSuppliers(__suppliers)
				setPlans(__plans)
			}
			catch(err) {
				notify.error(err.message)
			}
		}

		if(type!==null) {
			__load()
		}

		return () => {
			setPlans([])
			setSuppliers(null)
		}
	}, [type])

	useEffect(() => {
		// Can currently authenticated user manage this class of billing plan?
		let __canManage=false
		if(user!==null) {
			switch(type) {
				case 'Core':
					__canManage=user.isAdmin ;
					break ;
				case 'Organisation':
					__canManage=user.isOrgAdmin ;
					break ;
				case 'Cluster':
					__canManage=user.isClusterOwner ;
					break ;
			}
		}
		setCanManage(__canManage)

	}, [user])

	const formatter = friendlyTimeFormatter()

	function handleKeyPress(e) {
		if(e.keyCode===27) {
			console.log('handleKeyPress() :: ESC')
			// Esc pressed
			if(deleteId!==null) {
				setDeleteId(null)
			}
		}
	}

	useEffect(() => {
		if(deleteId!==null) {
			document.addEventListener("keydown", handleKeyPress)
			console.log(`useEffect[deleteId] - add handler`)
		}

		return () => {
			document.removeEventListener("keydown", handleKeyPress)
			console.log(`useEffect[deleteId] - remove handler`)
		}

	}, [deleteId])

	async function deletePlan(id, name) {
		setDeleteId(null)
		try {
			await request.call(`/api/billable/plan/${deleteId}`, 'delete')
			notify.info(`Deleted plan "${name}"`) ;
			setDeleteId(null)
			const __plans = await request.call(`/api/billable/plan?type=${type}&includePeriods`)
			setPlans(__plans)
		}
		catch(err) {
			notify.error(err.message)
		}
	}

	const columns = [{
		dataField: '_id',
		text: 'Plan ID',
		hidden: true
	}, {
		dataField: 'name',
		text: 'Name',
		sort: true
	}, 
	{
		dataField: 'is_wholesale',
		text: 'Basis',
		sort: true,
		formatter: (cell, row) => (<span>{cell?'Wholesale':'Tariff'}</span>)
	}, {
		dataField: 'description',
		text: 'Description',
		sort: true
	}, {
		dataField: 'supplier_id',
		text: 'Supplier',
		formatter: id => suppliers.lookup(id),
		sort: true
	}, {
		dataField: 'currency',
		text: 'Currency',
		formatter: data => Currencies.get(data).toString(),
		sort: true
	}, {
		dataField: 'updated_at',
		text: 'Modified',
		sort: true,
		formatter: formatter.format
	},{
		dataField: 'buttons',
		isDummyField: true,
		text: '',
		formatter: (cellcontent, row, rowIndex, __state) => {
			const { canManage, deleteId } = __state
			if(canManage && deleteId!==null) {
				console.log(`[${row.plan_type}::${row.name}] : ID=${row._id}, del=${deleteId}`)
			}
			return canManage?(<React.Fragment>
	      		<Link to={`${root}/${row._id}/edit`}>
	      			<EditButton label={`Edit plan "${row.name}"`} size="sm"/>
	      		</Link>
				  <DeleteButton label={`Delete plan "${row.name}"`} size="sm" onClick={(e) => setDeleteId(row._id)}/>
			</React.Fragment>)
			:null
		},
		formatExtraData: { canManage, deleteId }
	}];

	const expandRow = {
		renderer: row => {
			return (<div className="btn-right"><ConfirmAlert text={`Delete "${row.name}"?`}
				onYes={() => deletePlan(row._id, row.name)}
				onNo={() => setDeleteId(null)}
				className="pull-right"/></div>)
		},
		expandByColumnOnly: true,
		expanded: deleteId!==null?[deleteId]:[]
	}

	return (
		<ToolkitProvider
		  keyField="_id"
		  data={ plans }
		  columns={ columns }
		  search
		>
		  {
		    props => (
		      <Card style={{ marginTop: "1rem" }}>
				<Card.Header className="headerTable">
	  				<h5>{type} Plans</h5>
					{canManage?(<Dropdown as={ButtonGroup} style={{margin: "0 0.5rem 0 0"}}>
						<Link to={`${root}/new?type=${type}`}>
							<Button type="button" size="sm" variant="re-primary" className="btn-re btn-dropdown">
								<i className="fas fa-plus"/>
								<span>New Plan</span>
							</Button>
						</Link>
						<Dropdown.Toggle variant="re-primary" size="sm" split className="btn-re" id="dropdown-split-basic" />
						<Dropdown.Menu style={{margin: 0}}>
							<Dropdown.Item as={Link} to={`${root}/new?type=${type}`}>Create new plan</Dropdown.Item>
							<Dropdown.Item as={Link} to={`${root}/copy/as/${type}`}>Create from existing plan</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>):null}

	  				<div className="btn-right">
        				<SearchBar
          					{ ...props.searchProps }
          					delay={ 1000 }
          					placeholder="Filter"/>
	  				</div>
				</Card.Header>
		        <BootstrapTable
		         	bootstrap4 striped hover condensed 
		         	version="4"
					keyField='id' 
					expandRow={expandRow}
		        	noDataIndication="No plans found"
		        	defaultSorted={[{dataField: 'name', order: 'asc'}]}
		          	{ ...props.baseProps }
		        />

		      </Card>
		    )
		  }
	</ToolkitProvider>)
}