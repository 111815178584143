import React, { Component } from "react";
//import { notify } from "../../utility/ToastContainer";
import SolarServices from "../../services/SolarServices";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import moment from "moment";


const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};



export default class AddPanelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:  "",
      active: true,
      capacity: "",
      losses:"",
      tilt:  "",
      azimuth:  "",
      efficiency:  "",
      array_type:  "",
      module_type: "",
      // panels: [
      //   {
      //     name: this.props.name || "",
      //     active: this.props.active || true,
      //     capacity: this.props.capacity || "",
      //     losses:this.props.losses || "",
      //     tilt: this.props.tilt || "",
      //     azimuth: this.props.azimuth || 0,
      //     efficiency: this.props.efficiency || "",
      //     array_type: this.props.array_type || "",
      //     module_type: this.props.module_type || ""
      //   }
      // ],
      inst_id: "",
      type: "",
      formValid: false,
      errorCount: 0,
      errors: {
        name:  "",
        capacity: "",
        losses: "",
        tilt: "",
        azimuth: "",
        efficiency:"" ,
        array_type:  "",
        module_type:  ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const {
        inst_id,
        type,
        name,
        capacity,
        losses,
        tilt,
        azimuth,
        efficiency ,
        array_type,
        module_type
      } = this.props;
      this.setState({
        inst_id: inst_id,
        type: type,
        name: name,
        capacity: capacity,
        losses: losses,
        tilt: tilt,
        azimuth:azimuth,
        efficiency:efficiency,
        array_type:array_type,
        module_type:module_type,
      });
    }, 1);
  }



  // handleaddPanel = () => {
  //   this.setState({
  //     panels: this.state.panels.concat([
  //       {
  //         name:  "",
  //         active: true,
  //         capacity:  0,
  //         losses:0,
  //         tilt:  0,
  //         azimuth:  0,
  //         efficiency:  0,
  //         array_type:  0,
  //         module_type: 0,
  //       }
  //     ])
  //   });
  // };

  onChangeSelect = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const errors = this.state.errors;
    console.log('value, name', value, name)
    switch (name) {
      case 'name':
       errors.name =
         value.length < 3
           ? 'Name must be 3 characters long!'
           : '';
       break;
        case 'capacity':
         errors.capacity =
           Number(value) > 100000 || Number(value) < 1 || !/^\d+(?:\.\d{1,2})?$/i.test(value)
             ? 'Capacity should be from 1 to 100000, with max 2 decimal places!'
             : '';
         break;
         case 'azimuth':
          errors.azimuth =
          Number(value) > 360 || Number(value) < 0 || !/^\d+(?:\.\d{1,2})?$/i.test(value)
              ? 'Azimuth should be from 0 to 360 with max 2 decimal places!'
              : '';
          break;
          case 'losses':
           errors.losses =
             Number(value) > 100 || Number(value) < 0 || !/^\d+(?:\.\d{1,2})?$/i.test(value)
               ? 'Losses should be from 0 to 100 with max 2 decimal places!'
               : '';
           break;
           case 'tilt':
            errors.tilt =
              Number(value) > 90 || Number(value) < 0 || !/^\d+(?:\.\d{1,2})?$/i.test(value)
                ? 'Tilt should be from 0 to 90 with max 2 decimal places!'
                : '';
            break;
            case 'efficiency':
             errors.efficiency =
               Number(value) > 99.5 || Number(value) < 90 || !/^\d+(?:\.\d{1,2})?$/i.test(value)
                 ? 'Efficiency should be from 90 to 99.5!'
                 : '';
             break;
             case 'array_type':
              errors.array_type =
                Number(value) < 0 || Number(value) > 4 || value === ""
                  ? 'Select array type!'
                  : '';
              break;
              case 'module_type':
               errors.module_type =
                 Number(value) < 0 || Number(value) > 2 || value === ""
                   ? 'Select module type!'
                   : '';
               break;
      default:
        break;
    }

    // const newPanel = this.state.panels.map((panel, sidx) => {
    //   if (idx !== sidx) return panel;
    //   if (name === "name") return { ...panel, [name]: value  };
    //   else if ( name !== "name" && value === 0 ) return { ...panel, [name]:0  };
    //   else return { ...panel, [name]: Number(value) };
    // });
    this.setState({ errors, [name]: value, });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleRemove = idx => () => {
    this.setState({
      panels: this.state.panels.filter((s, sidx) => idx !== sidx)
    });
  };

  addPanel = (event, errors, values) => {
    event.preventDefault();
    const panel = {
      name:  this.state.name,
      active: this.state.active,
      capacity: Number(this.state.capacity),
      losses:Number(this.state.losses),
      tilt:  Number(this.state.tilt),
      azimuth: Number(this.state.azimuth),
      efficiency:  Number(this.state.efficiency),
      array_type: Number (this.state.array_type),
      module_type: Number(this.state.module_type),
    }
  //  console.log('panel', panel)
     this.submitPanel(panel);
  };

  submitPanel = panel => {
    const { type, panel_id, inst_id } = this.props;
    console.log('type', type, inst_id)
    console.log("added panel", panel);
    if (type === "Edit") {
      return (
        SolarServices.editPanelGroup(panel, inst_id, panel_id)
          .then(response => {
            if (response.data) {
              this.props.closeForm("success", "Panel is updated successfully.");
            } else {
              this.props.closeForm("danger", "Panel already exists");
            }
          })
          .catch((err, code, message, statusCode) => {
            this.props.closeForm("danger", err);
            console.log("error", err);
          })
      );
    } else if (type === "Add") {
      return SolarServices.addPanelGroup(panel, inst_id)
        .then(response => {
          if (response.data) {
            //this.closeForm();
            this.props.closeForm("success",  "Panel is created successfully."
            );
            this.setState({ panels: [], type: "add" });
          } else {
            this.props.closeForm("danger", "Panel already exists");
          }
        })
        .catch(err => {
          this.props.closeForm("danger", err);
          console.log("error", err);
        });
    } else {
      this.props.closeForm("danger","error: no form type");
    }
  };

  render() {
    const {
      errors,
      formValid,
      errorCount,
      //panels,
      name,
      capacity,
      losses,
      tilt,
      azimuth,
      efficiency ,
      array_type,
      module_type
    } = this.state;
    const { type } = this.props;
    return (
      <div>
        <div>
          <form onSubmit={this.addPanel}>
            <div>

                <div className="tariffsArray" >
                  <h4 className="headerTable">
                  </h4>
                  <table className="table-market" id="panel-add">
                    <thead>
                      <tr>
                        <th> Name </th>
                        <th> Capacity </th>
                        <th> Losses </th>
                        <th> Tilt </th>
                        <th> Azimuth </th>
                        <th> Efficiency </th>
                        <th> Module type </th>
                        <th> Array type </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            required
                            name="name"
                            id="name"
                            onChange={this.onChangeSelect}
                            placeholder="Enter  Name"
                            value={name || ""}
                            ref={this.innerRef}
                          />
                          {errors.name.length > 0 && (
                            <span className="error">{errors.name}</span>
                          )}
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            name="capacity"
                            id="capacity"
                            onChange={this.onChangeSelect}
                            placeholder="Enter capacity"
                            value={capacity || ""}
                          />
                          {errors.capacity.length > 0 && (
                            <span className="error">{errors.capacity}</span>
                          )}
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            name="losses"
                            id="losses"
                            onChange={this.onChangeSelect}
                            placeholder="Enter losses"
                            value={losses || ""}
                          />
                          {errors.losses.length > 0 && (
                            <span className="error">{errors.losses}</span>
                          )}
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            name="tilt"
                            id="tilt"
                            onChange={this.onChangeSelect}
                            placeholder="Enter tilt"
                            value={tilt || ""}
                          />
                          {errors.tilt.length > 0 && (
                            <span className="error">{errors.tilt}</span>
                          )}
                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            name="azimuth"
                            id="azimuth"
                            onChange={this.onChangeSelect}
                            placeholder="Enter azimuth"
                            value={azimuth || ""}
                          />
                          {errors.azimuth.length > 0 && (
                            <span className="error">{errors.azimuth}</span>
                          )}

                        </td>
                        <td>
                          <input
                            required
                            type="number"
                            name="efficiency"
                            id="efficiency"
                            onChange={this.onChangeSelect}
                            placeholder="Enter efficiency"
                            value={efficiency || ""}
                          />
                          {errors.efficiency.length > 0 && (
                            <span className="error">{errors.efficiency}</span>
                          )}
                        </td>
                        <td>
                          <select
                            name="module_type"
                            id="module_type"
                            value={module_type}
                            onChange={this.onChangeSelect}
                            required
                          ><option value="">Select Module Type</option>
                            <option value="0">Standard</option>
                            <option value="1">Premium</option>
                            <option value="2">Thin Film</option>
                          </select>
                          {errors.module_type.length > 0 && (
                            <span className="error">{errors.module_type}</span>
                          )}
                        </td>
                        <td>
                          <select
                            name="array_type"
                            id="array_type"
                            value={array_type}
                            onChange={this.onChangeSelect}
                            required
                          ><option value="">Select Array Type</option>
                            <option value="0">Fixed-Open Rack</option>
                            <option value="1">Fixed-Roof Mounted</option>
                            <option value="2">1-Axis</option>
                            <option value="3">1-Axis Backtracking</option>
                            <option value="4">2-Axis</option>
                          </select>
                          {errors.array_type.length > 0 && (
                            <span className="error">{errors.array_type}</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>

            <div className="form-group ">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {type === "edit" ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
