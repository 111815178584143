import React, { Component } from "react";
//import { Button } from "react-bootstrap";
import  { notify } from "../../utility/ToastContainer";
import UserServices from "../../services/UserServices";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (count = count+1)
  );
  return count;
}

export default class AddOrganisationForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      nameActive: false, line1Active:false, line2Active:false, cityActive:false,
      countyActive:false, countryActive:false, postcodeActive:false,
      name: '', line1:'', line2:'', city:'', county:'', country:'', postcode:'',
      show:this.props.show,
      active:true,
      formValid: false,
      errorCount: 0,
      errors: {
        name: '', line1:'', line2:'',city:'', county:'', country:'', postcode:'',
      },
    };
  }

  activateField=(e)=> {
    console.log();
    this.setState({
     [`${e.target.name}Active`]: true
    })
  }

  disableField=(e)=> {
    this.setState({
      [`${e.target.name}Active`]: false
   })
 }

  onChangeActive = () => {
    this.setState({
      active: !this.state.active,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  }


 changeHandler = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const show = this.state.show;
    if (e.target.value === "") {
      this.disableField(e);
   } else {
      this.activateField(e);
   }
    //console.log('show', show)
    // const lastName = target.lastName;
    // const email = target.email;
    // const password = target.password;
    let errors = this.state.errors;
    switch (name) {
      case 'name':
        errors.name =
          value.length < 2
            ? 'Name must be 2 characters long!'
            : '';
        break;
      case 'line1':
          errors.line1 =
            value.length < 4 && show === true
              ? 'Line 1 must be 4 characters long!'
              : '';
          break;
      case 'postcode':
              errors.postcode =
              value.length < 4  && show === true
                ? 'Postcode must be 4 characters long!'
                : '';
          break;
      case 'city':
          errors.city =
            value.length < 4
              ? 'City must be 4 characters long!'
              : '';
          break;
      case 'county':
            errors.county =
              value.length < 4 && show === true
                ? 'County must be 4 characters long!'
                : '';
            break;
        case 'country':
                errors.country =
                value.length < 4  && show === true
                  ? 'Country must be 4 characters long!'
                  : '';
            break;
      default:
        break;
  }

    this.setState({
      errors,
      [name]: value,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  addOrganisation = (event, errors, values) => {
    event.preventDefault();
    const id = this.props.orgId;
    var org
    const show = this.state.show;
    const active = JSON.parse(this.state.active)
    //console.log('active', active)
      if (show === true){
         org = {
           name: this.state.name,
           active:active,
           address:{
             line1:this.state.line1,
             line2:this.state.line2,
             city:this.state.city,
             county:this.state.county,
             country:this.state.country,
             postcode:this.state.postcode,
           }
        };

      } else if ( this.props.type === "edit" && show === false) {
         org = {
           name: this.state.name,
           active:active,
           address:null
        };
      } else {
        org = {
          name: this.state.name,
          active:JSON.parse(this.state.active),
       };
      }
    //console.log('organisation', org)
    if (this.props.type === "edit") {
      return UserServices.editOrganisation(id, org)
        .then(response => {
          if (active === true && this.props.active === active){
            this.props.getOrganisationList(this.props.altKey)
          } else if (this.props.active === false) {
            window.location.reload();
          } else  {
            window.location.reload();
          }


          this.props.closeForm("success",  "Organisation is updated successfully.");
        })
        .catch(err => {
            this.props.closeForm("danger", err);
        });
    } else {
      return UserServices.addOrganisation(org)
        .then(response => {
          if (response.data) {
            this.props.closeForm();
          //window.location.reload();
            this.props.closeForm("success", "Organisation is created successfully.");
            this.setState({name: '', line1:'', line2:'', city:'', county:'', country:'', postcode:'',active:true})
          } else {
            // Conflict error found;
            this.props.closeForm("danger", "Name already exists");

          };
        })
        .catch(err => {
            this.props.closeForm("danger", err);
          //this.props.formSubmitted(err, null);
        });
    }

  };

  addAddress=() =>{
    this.setState({show: !this.state.show})
    this.checkAddress()
    }

  checkAddress=() =>{
      const show = this.state.show
        if (show === false){
          this.setState({  line1:'',line2:'', city:'', county:'', country:'',postcode:''});
        }
        this.setState({formValid: validateForm(this.state.errors)});
        this.setState({errorCount: countErrors(this.state.errors)});
      }

  componentDidMount() {

    setTimeout(() => {
      const {orgId, name, type, line1, line2, city, county, country, postcode, show, active} = this.props;
      this.setState({ name:name, type:type, orgId:orgId, line1:line1,
        line2:line2, city:city, county:county, country:country,postcode:postcode, show:show, active:active });
      this.innerRef.current.focus();
    }, 1);
  }

  render() {
    const { errors, formValid, errorCount, show} = this.state;

    var addAddress
    if (show === true){
      addAddress = 'Clear address'
    } else {
      addAddress = 'Add address'
    }

    const type = this.props.type;
    var active
    if (type === 'edit'){
      active = this.props.active;
    } else {
      active = this.state.active;
    }

    var nameActive, line1Active, line2Active, cityActive, countyActive, countryActive, postcodeActive
    if (type === 'edit'){
      nameActive = line1Active = line2Active = cityActive = countyActive = countryActive = postcodeActive = true;
    } else {
      nameActive = this.state.nameActive;
      line1Active= this.state.line1Active;
      line2Active= this.state.line2Active;
      cityActive = this.state.cityActive;
      countyActive  = this.state.countyActive;
      countryActive  = this.state.countryActive;
      postcodeActive = this.state.postcodeActive;
    }

    return (
      <div>

        <div>
          <form onSubmit={this.addOrganisation}>
            <div className="form-group">
            <div className="labelField">
            <label  className={nameActive ? "on" : ""}>
            Name
            </label>
              <input
                className="form-control"
                type="text"
                required
                name="name"
                id="name"
                onChange={this.changeHandler}
                placeholder="Enter Organisation Name"
                value={this.state.name || ""}
                ref={this.innerRef}
              />
              {errors.name.length > 0 &&
                <span className='error'>{errors.name}</span>}
            </div>
              <br/>
              <div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="active"
                id="active"
                onChange={this.onChangeActive}
                onFocus={this.activateField}
                onBlur={this.disableFocus}
                value={active}
                defaultChecked={active}
              /></label>
              </div>
              <div><label htmlFor="addaddress">
              {addAddress} &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="addaddress"
                id="addaddress"
                value={show}
                defaultChecked={show}
                onChange={this.addAddress}
              /></label>
              </div>
              {this.state.show &&
              <div>
                <div className="labelField">
                <label  className={line1Active ? "on" : ""}>
                Line 1
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="line1"
                  id="line1"
                  required
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Line 1"
                  value={this.state.line1|| ""}
                />
                {errors.line1.length > 0 &&
                  <span className='error'>{errors.line1}</span>}
                </div>
                <div className="labelField">
                <label  className={line2Active ? "on" : ""}>
                Line 2
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="line2"
                  id="line2"
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Line 2"
                  value={this.state.line2|| ""}
                />
                </div>
              <div className="labelField">
              <label  className={cityActive ? "on" : ""}>
              City
              </label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  id="city"
                  required
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="City/Town "
                  value={this.state.city|| ""}
                />
                {errors.city.length > 0 &&
                  <span className='error'>{errors.city}</span>}
                </div>
              <div className="labelField">
                <label  className={countyActive ? "on" : ""}>
                County
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="county"
                  id="county"
                  required
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="County"
                  value={this.state.county|| ""}
                />
                {errors.county.length > 0 &&
                  <span className='error'>{errors.county}</span>}
                </div>
              <div className="labelField">
                <label  className={countryActive ? "on" : ""}>
                 Country
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  id="country"
                  required
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Country"
                  value={this.state.country|| ""}
                />
                {errors.country.length > 0 &&
                  <span className='error'>{errors.country}</span>}
                </div>
              <div className="labelField">
                <label  className={postcodeActive ? "on" : ""}>
                Postcode
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="postcode"
                  id="postcode"
                  required
                  onChange={this.changeHandler}
                  onFocus={this.activateField}
                  onBlur={this.disableFocus}
                  placeholder="Postcode"
                  value={this.state.postcode|| ""}
                />
                {errors.postcode.length > 0 &&
                  <span className='error'>{errors.postcode}</span>}
                </div>
              </div>
              }

            </div>
            <br/>
            <div className="form-group textCenter">
              <button
                disabled = {formValid===false || errorCount !== 0 }
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? <p className="form-status">Form is {formValid===false || errorCount !== 0 ? 'invalid ❌' :'valid ✅' }</p> : 'Form not submitted'}
          </form>
        </div>
      </div>
    );
  }
}
