import React, { Component } from "react";
//import MeterRows from "./MeterRow";
import { Modal, Card, Alert } from "react-bootstrap";
import AddMeterForm from "./AddMeter";
//import ToastContainer from "../../utility/ToastContainer";
//import Dropdown from "../../utility/Dropdown";
import AddBillableForm from "./AddBillable";
import MeterServices from "../../services/MeterServices";
import UserServices from "../../services/UserServices";
import TariffTable from "./TariffTable";
import ChargeTable from "./ChargeTable";
import ProfileTable from "./ProfileTable";
import AddChargeForm from "./AddCharge";
import AddTariffForm from "./AddTariff";
import AddProfileForm from "./AddProfile";
import AuthService from "../../services/auth"
//import Dialog from "react-bootstrap-dialog";
//import AddTariffTable from "./AddTariffTable";
//import moment from "moment";

export default class MeterTable extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.child2 = React.createRef();
    this.child3 = React.createRef();
    this.state = {
      noSites: false,
      orgId: "",
      sites: [],
      site_id: "",
      meters: [],
      name: "",
      description: "",
      types: [],
      parent_id: "",
      selectedTypes: null,
      activeMeter: true,
      type: "",
      meter_id: "",
      meter_name: "",
      meter_type: "",
      organisation_id: "",
      hasdata: true,
      billables: [],
      billable_id: "",
      mic: "",
      meter_number: "",
      currency: "",
      timezone: "",
      selectedCurrency: [],
      timezoneSelected: [],
      marketTypeSelected: [],
      index: "",
      market_type: "",
      profiles: [],
      show: false,
      variant: "",
      msg: ""
      //getInactiveSite:'show'
    };
  }

  componentDidMount = () => {
    this.getSiteList();
    //this.props.getSiteList();
  };

  getSiteList = async () => {
    const login_user = sessionStorage.getItem("_user");
    const l_user = JSON.parse(login_user);
    const org_id = l_user.organisation.id;
    //console.log('org_id', org_id)
    this.setState({ org_id: org_id });
    UserServices.getSiteList(org_id)
      .then(response => {
        let sites = response.data;
        var site_id;
        if (sites.length) {
          site_id = sites[0]._id;
          this.getMeterList(site_id);
          //console.log("sites activeMeter", sites);
          this.setState({
            sites: sites,
            site_id: site_id,
            noSites: false,
            getInactiveSite: "Include"
          });
          //this.child.current.getMeterList(site_id);

        } else {
          this.setState({ sites: [], noSites: true });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ clusters: [] });
      });
  };

  onSiteSelected = e => {
    e.preventDefault();
    console.log("onSiteSelected", e.target.value);
    if (e.target.value !== "") {
      const site = this.state.sites[e.target.value];
      const id = site._id;
      this.getMeterList(id);
      this.setState({ site_id: id });
    }
  };

  getInactiveSites = () => {
    const getInactiveSite = this.state.getInactiveSite;
    if (getInactiveSite === "Include") {
    //  console.log('1getInactiveSite',getInactiveSite)
      const login_user = sessionStorage.getItem("_user");
      const l_user = JSON.parse(login_user);
      const org_id = l_user.organisation.id;
      //console.log('org_id', org_id)
      this.setState({ org_id: org_id });
      UserServices.getSitesAll(org_id)
        .then(response => {
          let sites = response.data;
          //console.log('sites all', sites)
          var site_id;
          if (sites.length) {
            site_id = sites[0]._id;
            this.getMeterList(site_id);
          }

          this.setState({
            sites: sites,
            site_id: site_id,
            getInactiveSite: "Hide"
          });
          //this.child.current.getMeterList(site_id);
        })
        .catch(err => {
          console.error(err);
          this.setState({ clusters: [] });
        });
    } else {
      //console.log('2 getInactiveSite',getInactiveSite)
      window.location.reload()
      //this.componentDidMount();
      //this.getSiteList();
    //this.getMeterList(site_id);
    }
  };

  getMeterList = site_id => {
      //console.log('333 site_id', site_id)
      MeterServices.getMeterList()
      .then(response => {
        let m = response.data;
        //console.log("meters ALL", m);
        const found_meters = m.filter(item => item.site_id === site_id);
        //  console.log('found_meters1', found_meters.length)
        //console.log("found_meters", found_meters);
        const length = found_meters.length;
        const index = this.state.index;
        var i;
        if (index !== "" && index < length) {
          i = index;
        } else if (index !== "" && index === length) {
          i = 0;
        } else {
          i = 0;
        }
        //console.log('i', i)
        if (found_meters.length) {
          const meter_id = found_meters[i]._id;
          const meter_name = found_meters[i].name;
          const description = found_meters[i].description;
          const type = found_meters[i].meter_type;
          const activeMeter = found_meters[i].active;
          //console.log('meter id', meter_id);
          //console.log('found_meters', found_meters)
          this.getBillableList(meter_id);
          this.setState({
            meters: found_meters,
            meter_id: meter_id,
            site_id: site_id,
            getInactiveMeters: "Include",
            meter_name: meter_name,
            description: description,
            meter_type: type,
            activeMeter: activeMeter
          });
        } else {
          this.setState({
            meters: found_meters,
            meter_id: "",
            meter_name: "",
            description: "",
            meter_type: "",
            activeMeter: true,
            billables: [],
            meter_number: "",
            mic: "",
            currency: "",
            market_type: "",
            timezone: "",
            getInactiveMeters: "Include"
          });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ meters: [] });
      });
  };

  getMetersIncludeInactive = () => {
    const site_id = this.state.site_id;
    if (this.state.getInactiveMeters === "Include") {
      //  console.log('get inactive')
      MeterServices.getAllMeters()
        .then(response => {
          let meters = response.data;
          if (meters.length) {
            var found_meters = meters.filter(item => item.site_id === site_id);
            if (found_meters.length) {
              const i = 0;
              const meter_id = found_meters[i]._id;
              const meter_name = found_meters[i].name;
              const description = found_meters[i].description;
              const type = found_meters[i].meter_type;
              const activeMeter = found_meters[i].active;
              //console.log('meter_id', meter_id);
              //console.log('found_meters all2', found_meters)
              this.setState({
                meters: found_meters,
                site_id: site_id,
                getInactiveMeters: "Hide",
                meter_id: meter_id,
                meter_name: meter_name,
                description: description,
                meter_type: type,
                activeMeter: activeMeter,
                index: i
              });
            } else {
              this.setState({
                meters: [],
                site_id: "",
                getInactiveMeters: "Hide",
                meter_id: "",
                meter_name: "",
                description: "",
                meter_type: "",
                activeMeter: true,
                index: 0
              });
            }
          }
          //console.log('meters all', meters)
        })
        .catch(err => {
          console.error(err);
          this.setState({ meters: [] });
        });
    } else {
      this.getMeterList(site_id);
    }
  };

  editMeter = () => {
    const types = this.state.meter_type;
    var selectedTypes = [];
    if (types) {
      selectedTypes = types.map(value => ({ value, label: value }));
    }
    this.setState({
      showModalEdit: true,
      meter_id: this.state.meter_id,
      name: this.state.meter_name,
      description: this.state.description,
      types: this.state.types,
      activeMeter: this.state.activeMeter,
      type: "edit",
      selectedTypes: selectedTypes,
      index: this.state.index
    });
  };

  getBillableList = meter_id => {
    //console.log('getBillableList ID=>>>>', meter_id)
    if (meter_id) {
      MeterServices.getBillableList()
        .then(response => {
          let billables = response.data;
          const found_billables = billables.filter(
            item => item.meter_id === meter_id
          );
          //console.log("found_billables....", found_billables);
          if (found_billables.length) {
            const billable_id = found_billables[0]._id;
            const meter_number = found_billables[0].meter_number;
            const market_type = found_billables[0].market_type;
            const currency = found_billables[0].currency;
            const timezone = found_billables[0].timezone;
            this.setState({
              billables: found_billables,
              billable_id: billable_id,
              meter_number: meter_number,
              mic: found_billables[0].MIC,
              currency: currency,
              market_type: market_type,
              timezone: timezone,
              meter_id: meter_id
            });
            if (market_type === "WHOLESALE") {
              this.getProfiles(billable_id);
            } else if (market_type === "TARIFF") {
              this.getTariffsCharges(billable_id);
            }

            //    console.log("xxxxxxxxxxxx....");
          } else {
            this.setState({
              billables: [],
              meter_number: "",
              mic: "",
              currency: "",
              market_type: "",
              timezone: "",
              billable_id: ""
            });
            //this.getTariffsCharges();
            //this.getProfiles();
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ billables: [] });
        });
    } else {
      this.setState({
        billables: [],
        meter_number: "",
        mic: "",
        currency: "",
        market_type: "",
        timezone: ""
      });
    }
  };

  getTariffsCharges = billable_id => {
    //console.log('get tariff charges')
    this.child.current.getTariffList(billable_id);
    this.child2.current.getChargeList(billable_id);
  };

  getProfiles = billable_id => {
    //console.log('get tariff charges')
    this.child3.current.getProfileList(billable_id);
  };

  onMeterSelected = e => {
    e.preventDefault();
    if (e.target.value !== "") {
      const index = e.target.value;
      const meter = this.state.meters[index];
      const id = meter._id;
      this.setState({
        meter_id: id,
        meter_name: meter.name,
        description: meter.description,
        meter_type: meter.meter_type,
        activeMeter: meter.active,
        index: index
      });
      this.getBillableList(id);
    }
  };

  closeEdit = (variant, msg) => {
    this.setState({
      showModalEdit: false,
      index: this.state.index,
      variant: variant,
      msg: msg
    });
    this.getMeterList(this.state.site_id);
    this.onShowAlert();
  };

  add = () => {
    this.setState({ showModaladd: true, type: "new" });
  };

  closeAdd = (variant, msg) => {
    this.setState({
      showModaladd: false,
      index: 0,
      variant: variant,
      msg: msg
    });
    this.getMeterList(this.state.site_id);
    this.onShowAlert();
    //addTariff(this.state.site_id)
  };

  addBillable = () => {
    const meter_id = this.state.meter_id;
    const meter_name = this.state.meter_name;
    const selectedCurrency = [
      {
        value: "EUR",
        label: "Euro €"
      }
    ];
    const timezoneSelected = [
      {
        value: "Europe/Dublin",
        label: "Dublin"
      }
    ];

    //addTariff('meter_id',meter_id)
    //addTariff('open', meter_name)
    this.setState({
      selectedCurrency: selectedCurrency,
      timezoneSelected: timezoneSelected,
      showModalAddBillable: true,
      type: "new",
      meter_id: meter_id,
      meter_name: meter_name,
      index: this.state.index
    });
  };

  editBillable = () => {
    //addTariff('this.state.timezone', this.state.timezone)
    const curr = this.state.currency;
    const timezone = this.state.timezone;
    const marketType = this.state.market_type;
    const currencySelected = [
      {
        value: curr,
        label: curr
      }
    ];
    const timezoneSelected = [
      {
        value: timezone,
        label: timezone
      }
    ];
    const marketTypeSelected = [
      {
        value: marketType,
        label: marketType
      }
    ];
    this.setState({
      showModalEditBillable: true,
      meter_number: this.state.meter_number,
      mic: this.state.mic,
      currency: this.state.currency,
      market_type: this.state.market_type,
      timezone: this.state.timezone,
      billable_id: this.state.billable_id,
      selectedCurrency: currencySelected,
      timezoneSelected: timezoneSelected,
      marketTypeSelected: marketTypeSelected,
      index: this.state.index
    });
  };

  closeAddBillable = (variant, msg) => {
    this.setState({
      showModalAddBillable: false,
      index: this.state.index,
      variant: variant,
      msg: msg
    });
    this.getMeterList(this.state.site_id);
    this.onShowAlert();
  };

  closeEditBillable = (variant, msg) => {
    this.setState({
      showModalEditBillable: false,
      index: this.state.index,
      variant: variant,
      msg: msg
    });
    this.getMeterList(this.state.site_id);
    this.onShowAlert();
  };

  openSelectTariffCharge = () => {
    const meter_id = this.state.meter_id;
    this.setState({ showModalSelect: true, meter_id: meter_id });
  };

  selectTariffCharge = () => {
    const id = this.state.meter_id;
    this.setState({ showModalTariffCharge: true, meter_id: id });
  };

  closeTariffCharge = () => {
    this.setState({ showModalTariffCharge: false });
  };

  onSelected = e => {
    if (e.target.value !== "" && e.target.value === "tariff") {
      this.setState({ showModalTariffCharge: false });
      this.setState({ showModalTariff: true });
    } else if (e.target.value !== "" && e.target.value === "charge") {
      this.setState({ showModalTariffCharge: false });
      this.setState({ showModalCharge: true });
    }
  };

  closeTariff = (variant, msg) => {
    const meter_id = this.state.meter_id;
    this.setState({ showModalTariff: false, variant: variant, msg: msg });
    this.getBillableList(meter_id);
    this.onShowAlert();
  };

  closeTariffEdit = (variant, msg) => {
    this.setState({ showModalTariffEdit: false, variant: variant, msg: msg });
    this.onShowAlert();
  };

  closeCharge = (variant, msg) => {
    const meter_id = this.state.meter_id;
    //console.log('close charge', meter_id)
    this.setState({ showModalCharge: false, variant: variant, msg: msg });
    this.getBillableList(meter_id);
    this.onShowAlert();
  };

  closeChargeEdit = (variant, msg) => {
    this.setState({ showModalChargeEdit: false, variant: variant, msg: msg });
    this.onShowAlert();
  };

  addProfile = () => {
    const meter_id = this.state.meter_id;
    this.setState({ showModalAddProfile: true, meter_id: meter_id });
  };

  closeAddProfile = (variant, msg) => {
    const meter_id = this.state.meter_id;
    //const billable_id = this.state.billable_id;
    this.setState({ showModalAddProfile: false, variant: variant, msg: msg });
    this.getBillableList(meter_id);
    this.onShowAlert();
  };

  openTest = () => {
    this.setState({ showModalTest: true });
  };

  openEdit = () => {
    this.setState({ showModalEditMeter: true });
  };

  onEditSelected = e => {
    const select = e.target.value;
    const billables = this.state.billables;
    //console.log("billables.length", billables.length);
    if (select !== "" && select === "meter") {
      this.editMeter();
    } else if (select !== "" && select === "billable") {
      if (billables.length) {
        this.editBillable();
      } else {
        this.addBillable();
      }
    }
    this.setState({ showModalEditMeter: false });
  };

  closeEditMeter = (variant, msg) => {
    this.setState({ showModalEditMeter: false });
  };

  getData = val => {
    this.setState({ profiles: val });
    //console.log('getting data from child', val);
  };

  setShow = () => {
    this.setState({ show: false });
  };

  onShowAlert = () => {
    //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
    });
  };

  closeModal = () => {
    this.setState({
      showModaladd: false,
      showModalEdit: false,
      showModalEditMeter: false,
      showModalAddBillable: false,
      showModalEditBillable: false,
      showModalChangePassword: false,
      showModalTariff: false,
      showModalTariffEdit: false,
      showModalCharge: false,
      showModalChargeEdit: false,
      showModalAddProfile: false
    });
  };

  render() {
    const {
      orgId,
      sites,
      selectedTypes,
      types,
      parent_id,
      meter_id,
      name,
      meter_name,
      description,
      meter_type,
      meter_number,
      billable_id,
      mic,
      currency,
      market_type,
      timezone,
      activeMeter,
      billables,
      meters,
      site_id,
      selectedCurrency,
      timezoneSelected,
      marketTypeSelected,
      index,
      getInactiveMeters,
      getInactiveSite,
      noSites,
      variant,
      msg,
      show
    } = this.state;
    const { org_id } = this.props;

    //const d =new Date();
    const s_date = new Date();
    // d.setHours(0,0,0,0);
    //console.log('getInactiveSite',getInactiveSite)
    //console.log('getInactiveMeters',getInactiveMeters)
    const tariffs = [
      {
        month_start: "JANUARY",
        month_end: "DECEMBER",
        weekday_start: "MONDAY",
        weekday_end: "SUNDAY",
        time_start: "00:00",
        time_end: "00:00",
        price: 0
      }
    ];
    //console.log(" market_type", market_type);
    //&& !profiles.length

    return (
      <div className="card">
        <div className="settings">
          {show === true ? (
            <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>
          ) : (
            <div></div>
          )}
          {noSites === false ? (
            <div className="col-sm-12">
              <div className="headerTable">
                <button
                  onClick={this.getInactiveSites}
                  className="inactive-sites-meters btn-round"
                >
                  {getInactiveSite}&nbsp; Inactive
                </button>
                <h2>Site</h2>
                <select className="dropdown" onChange={this.onSiteSelected}>
                  {sites.map((option, index) => (
                    <option key={index} value={index}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <button
                  onClick={this.getMetersIncludeInactive}
                  className="inactive-sites-meters btn-round"
                >
                  {getInactiveMeters}&nbsp; Inactive
                </button>
                <h2>Meter</h2>
                <select
                  className="dropdown"
                  onChange={this.onMeterSelected}
                  value={index}
                >
                  {meters.map((option, index) => (
                    <option key={index} value={index}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <button className="add btn-round " onClick={this.add}>
                  Add Meter
                </button>
              </div>
              {meters.length ? (
                <div>
                  <table className="table-market">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Active</th>
                        <th>Number</th>
                        <th>MIC</th>
                        <th>Currency</th>
                        <th>Market Type</th>
                        <th>Timezone</th>
                        <th>Cost</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{meter_name}</td>
                        <td>{description}</td>
                        <td>{meter_type.join(", ")}</td>
                        {activeMeter === true ? <td>Yes</td> : <td>No</td>}
                        <td>{meter_number}</td>
                        <td>{mic}</td>
                        <td>{currency}</td>
                        <td>{market_type}</td>
                        <td>{timezone.split("/")[1]}</td>

                        <td>
                          {!billables.length ? (
                            <div></div>
                          ) : billables.length &&
                            market_type === "WHOLESALE" ? (
                            <button
                              className="editDelete"
                              onClick={() => this.addProfile()}
                            >
                              Profile
                            </button>
                          ) : billables.length && market_type === "TARIFF" ? (
                            <button
                              className="editDelete"
                              onClick={() => this.selectTariffCharge()}
                            >
                              Add
                            </button>
                          ) : (
                            <div></div>
                          )}
                        </td>

                        {!meters.length ? (
                          <td></td>
                        ) : (
                          <td>
                            <button
                              onClick={() => this.openEdit()}
                              className="editDelete"
                            >
                              Edit
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  {market_type === "WHOLESALE" ? (
                    <div>
                      <ProfileTable
                        billable_id={billable_id}
                        currency={currency}
                        ref={this.child3}
                        sendData={this.getData}
                      />
                    </div>
                  ) : market_type === "TARIFF" ? (
                    <div>
                      <TariffTable
                        billable_id={billable_id}
                        billables={billables}
                        ref={this.child}
                      />
                      <ChargeTable
                        billable_id={billable_id}
                        timezone={timezone}
                        currency={currency}
                        billables={billables}
                        ref={this.child2}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div>
              <Card
                style={{
                  width: "18rem"
                }}
              >
                <Card.Body>
                  <Card.Title>
                    <i
                      style={{
                        color: "orange"
                      }}
                      className="fas fa-exclamation-triangle"
                    ></i>
                    Warning
                  </Card.Title>
                  <Card.Text>Add sites to be able to add meters.</Card.Text>
                </Card.Body>
              </Card>
            </div>
          )}
          <div className="col-lg-12"></div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModaladd}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Add Meter</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddMeterForm
                    getMeterList={this.getMeterList}
                    orgId={org_id}
                    site_id={site_id}
                    parent_id={parent_id}
                    edit={false}
                    closeForm={this.closeAdd}
                    selectedTypes={null}
                    show={true}
                    activeMeter={true}
                    type={"add"}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalEdit}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Edit Meter</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddMeterForm
                    getMeterList={this.getMeterList}
                    orgId={orgId}
                    meter_id={meter_id}
                    site_id={site_id}
                    parent_id={parent_id}
                    name={name}
                    description={description}
                    selectedTypes={selectedTypes}
                    types={types}
                    activeMeter={this.state.activeMeter}
                    type={"edit"}
                    edit={true}
                    show={false}
                    closeForm={this.closeEdit}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalAddBillable}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Add Billable</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddBillableForm
                    orgId={org_id}
                    edit={false}
                    closeForm={this.closeAddBillable}
                    type={"add"}
                    meter_id={meter_id}
                    meter_name={meter_name}
                    currencySelected={selectedCurrency}
                    timezoneSelected={timezoneSelected}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalEditBillable}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Edit Billable</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddBillableForm
                    orgId={org_id}
                    edit={false}
                    closeForm={this.closeEditBillable}
                    type={"edit"}
                    meter_id={meter_id}
                    meter_name={meter_name}
                    meter_number={meter_number}
                    mic={mic}
                    currency={currency}
                    currencySelected={selectedCurrency}
                    timezoneSelected={timezoneSelected}
                    marketTypeSelected={marketTypeSelected}
                    billable_id={billable_id}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalTariffCharge}
              onHide={this.closeTariffCharge}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">
                  Add Tariff or Charge
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div className="textCenter">
                  <select id="mySelect" onChange={this.onSelected}>
                    <option key="select" name="select" value="select">
                      Please Select
                    </option>
                    <option key="tariff" name="tariff" value="tariff">
                      Tariff
                    </option>
                    <option key="charge" name="charge" value="charge">
                      Charge
                    </option>
                  </select>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              dialogClassName="custom-dialog-charge"
              backdrop="static"
              keyboard={false}
              show={this.state.showModalCharge}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Add Charge</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div>
                  <AddChargeForm
                    meter_id={meter_id}
                    billable_id={billable_id}
                    org_id={org_id}
                    type={"add"}
                    timezone={timezone}
                    currency={currency}
                    closeForm={this.closeCharge}
                    startDate={s_date}
                    selectedType={"enter"}
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              dialogClassName="custom-dialog"
              keyboard={false}
              show={this.state.showModalTariff}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Add Tariff(s)</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <AddTariffForm
                  billable_id={billable_id}
                  type={"add"}
                  closeForm={this.closeTariff}
                  tariffs={tariffs}
                  startDate={s_date}
                />
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalTariffEdit}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Edit Tariff</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <AddTariffForm />
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalAddProfile}
              onHide={this.closeModal}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Add Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <AddProfileForm
                  billable_id={billable_id}
                  type={"add"}
                  closeForm={this.closeAddProfile}
                  currency={currency}
                  valid_from={s_date}
                />
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <Modal
              backdrop="static"
              keyboard={false}
              show={this.state.showModalEditMeter}
              onHide={this.closeEditMeter}
            >
              <Modal.Header closeButton="closeButton">
                <Modal.Title className="textCenter">Edit Details</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  display: "relative"
                }}
              >
                <div className="textCenter">
                  <select id="mySelect" onChange={this.onEditSelected}>
                    <option key="select" name="select" value="select">
                      Select
                    </option>
                    <option key="meter" name="meter" value="meter">
                      Meter
                    </option>
                    <option key="billable" name="billable" value="billable">
                      Billable
                    </option>
                  </select>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
