import React, { useState, useEffect, useContext, useCallback } from 'react'
import UserContext from "../../../services/user-context"
import {
    Button,
    Container, Row, Col
} from "react-bootstrap"
import { AddButton, LeafletMap} from "../../components/UIComponents"
import { Accordion, Button as IButton, Icon } from "semantic-ui-react"
import {AlertContainer, Notify} from '../../components/AlertListener'
import {Request} from "../../../services/HttpService";
import SessionStorage from "../../../services/SessionStorage";
import SiteMeters from './SiteMeters'

import SiteList from './SiteList'

const EXPANDED = "org.admin.site.active"

const request = new Request({ service: 'Site service'})
const notify=Notify("site.notifications")


export default function SiteDashboard() {
    const [sites, setSites] = useState([])

    const {user}  = useContext(UserContext)

    useEffect(() => {
        if(user===null) {
            setSites([])
            return
        }

        const loadSites = async() => {
			try {
                console.log('Calling ', `/api/organisation/${user.organisation.id}/site`)
                const __sites = await request.call(`/api/organisation/${user.organisation.id}/site?tenancyView`)
                console.log('Sites : ', __sites)
                // Arrange the sites into a tree

                __sites.forEach(s => {
                    const {location=null} = s 
                    if(location!==null) {
                        const [longitude, latitude] = location.coordinates
                        s.location = [latitude, longitude]
                    }
                    else {
                        s.location=null
                    }
                })

                const __ids = __sites.map(({_id}) => _id)
                const __top=__sites.filter(({parent_id}) => typeof(parent_id)==='undefined' || parent_id===null || !__ids.includes(parent_id))

                if(__top.length!==__sites.length) {
                    // Need to sort out child sites

                    // First - exclude anything we have already assigned
                    const __filter = ((exclude, from) => {
                        const ids = exclude.map(({_id}) => _id)
                        return from.filter(({_id}) => !ids.includes(_id))
                    })

                    function addChildren(curr) {
                        for(const s of curr) {
                            const __children = __sites.filter(({parent_id}) => s._id===parent_id)
                            if(__children.length>0) {
                                s.sites = __children
                                addChildren(__children)
                            }
                        }
                    }
                    addChildren(__top)

                }

				setSites(__top)
			}
			catch(err) {
				notify.error(err.message)
			}
        }

        loadSites()
    }, [user])

    return (
        <>
        <AlertContainer tag="site.notifications" page="sites"/>
        <Container fluid>
            <Row>
                <Col lg="12">
                    <h3>Sites</h3>
                </Col>
            </Row>

            {sites.length>0?(<Row>
                <Col>
                    <SiteList sites={sites}/>
                </Col>
            </Row>):(<div>No sites found</div>)}
        </Container>
        </>
    )		
}