import React, { useState, useEffect, useContext } from "react";
import UserContext from '../../services/user-context'
import imgSun from "../../assets/svg/sun.svg";

export default function SolarWidget({solar=null}) {
  const [current, setCurrent] = useState(null)
  const [total, setTotal] = useState(null)
  
  const {Time} = useContext(UserContext)
  useEffect(() => {
    if(!Array.isArray(solar) || solar.length===0) return

    const __start=Time.today(),
      __end=Time.localTime().add(59, 'minutes')
    const __todaysReadings=solar.filter(({timestamp}) => 
      Time.compare(timestamp, __start)>=0 
      && Time.compare(timestamp, __end)<=0
    )

    // Add all to
    const [current, total] = __todaysReadings.reduce(
      ([, total], {reading}) => 
      ([reading, total+reading]), [0,0])

    setCurrent(current.toFixed(2))
    setTotal(total.toFixed(3))

    return () => {
      setCurrent(null)
      setTotal(null)
    }
  }, [solar])

  return (<div>
    <div className="component-card mini-card load">
      <img alt={"solar"} src={imgSun} />
      <h5>Current Solar</h5>
      <div className="component-data">
        <p className="main-data">{current!==null?current:'--'}&nbsp;<span>kWh</span></p>
        <h6> Total today {total!==null?total:'--'} kWh </h6>
      </div>
    </div>
  </div>)
}
