import { filterReport } from './HelperFunctions'

export default class ReportResult {
  constructor(item, label, attr, values, timestart, timeend, precision = 3) {
    this.item = item;
    this.label = label;
    const __precision = n => {
      if (precision === null || typeof n !== "number") return n;
      return parseFloat(n.toFixed(3));
    };
    this.data = [
      filterReport(timestart, timeend, values).map(v => __precision(v[attr])),
      //values.map(v => __precision(v[attr])),

    ];
  }

  get alldata() {
    const { label } = this,
      data = this.data[0];
      //console.log('111111data', data)
    return {
      name: data.length > 0 ? label : "",
      data
    };
  }

}
