import React, { Component } from "react";
import ChargesRow from "./ChargesRow";
//import ToastContainer from "../../utility/ToastContainer";
import MeterServices from "../../services/MeterServices";
import { Modal, Alert } from "react-bootstrap";
import AddChargeForm from "./AddCharge";
//import { notify } from "../../utility/ToastContainer";
import Dialog from "react-bootstrap-dialog";
//import moment from "moment";

export default class ChargeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billable_id: "",
      billables: [],
      charges: [],
      charge_id: "",
      valid_from: "",
      valid_to: "",
      name: "",
      selectedType: {},
      price: 0,
      getInactive: "Show",
      timezone: "",
      currency: "",
      show: false,
      variant: "",
      msg: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const { billable_id, timezone, currency } = this.props;
      this.setState({
        billable_id: billable_id,
        timezone: timezone,
        currency: currency
      });
    }, 1);
  };

  // sendStatusNotification = (type, msg) => {
  //   let className =
  //     type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
  //   let toastMsg = {
  //     msg: msg,
  //     className: className
  //   };
  //   notify(toastMsg);
  // };

  closeCharge = () => {
    this.setState({ showModalChargeEdit: false });
  };

  editCharge = (charge, valid_from, valid_to) => {
    //console.log("WWWWWW", charge, valid_from, valid_to);
    this.setState({ showModalChargeEdit: true });
    this.setState({
      name: charge.name,
      selectedType: charge.charge_type,
      price: charge.price,
      charge_id: charge._id,
      valid_from: valid_from,
      valid_to: valid_to
    });
  };

  getChargeList = billable_id => {
    if (billable_id) {
      MeterServices.getChargeList(billable_id)
        .then(response => {
          const billables = response.data;
          //console.log("Billables charges", billables);
          if (billables.length) {
            this.setState({
              getInactive: "Show",
              billables: billables,
              billable_id: billables[0].billable_id,
              timezone: billables[0].timezone,
              currency: billables[0].currency
            });
          } else {
            this.setState({
              billables: [],
              getInactive: "Show",
              billable_id: "",
              timezone: "",
              currency: ""
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            billables: [],
            getInactive: "Show",
            billable_id: "",
            timezone: "",
            currency: ""
          });
        });
    } else {
      this.setState({
        billables: [],
        getInactive: "Show",
        billable_id: "",
        timezone: "",
        currency: ""
      });
    }
  };

  getAllCharges = () => {
    const billable_id = this.props.billable_id;
    const getInactive = this.state.getInactive;
    if (getInactive === "Show") {
      if (billable_id) {
        MeterServices.getAllCharges(billable_id)
          .then(response => {
            const billables = response.data;
            //console.log("billables charges history", billables);
            if (billables.length) {
              this.setState({
                getInactive: "Hide",
                billables: billables,
                billable_id: billables[0].billable_id,
                timezone: billables[0].timezone,
                currency: billables[0].currency
              });
            } else {
              this.setState({
                getInactive: "Hide",
                billables: [],
                billable_id: "",
                timezone: "",
                currency: ""
              });
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({
              billables: [],
              getInactive: "Hide",
              billable_id: "",
              timezone: "",
              currency: ""
            });
          });
      } else {
        this.setState({
          billables: [],
          getInactive: "Hide",
          billable_id: "",
          timezone: "",
          currency: ""
        });
      }
    } else {
      this.getChargeList(billable_id);
    }
  };

  deleteCharge = charge_id => {
    //console.log("charge-delete", charge_id);
    this.dialog.show({
      body: "Would you like to delete charge. Proceed ?",
      actions: [
        Dialog.CancelAction(),
        Dialog.Action(
          "Proceed",
          () => this.deleteChargeOK(charge_id),
          "btn-warning"
        )
      ],
      bsSize: "medium"
    });
  };

  deleteChargeOK = charge_id => {
    //console.log("DELETE CHARGE: charge_id", charge_id);
    const billable_id = this.props.billable_id;
    //  console.log("DELETE CHARGE: billable_id", billable_id);
    MeterServices.deleteCharge(billable_id, charge_id)
      .then(response => {
        //console.log("response", response);
        this.setState({variant:"success", msg:"Charge is deleted successfully."});
        this.getChargeList(billable_id);
        this.onShowAlert();
      })
      .catch(err => {
        console.error(err);
        this.setState({variant:"danger", msg:err});
        this.onShowAlert();
      });
  };

  setShow=()=>{
    this.setState({ show:false });
  }

  onShowAlert = () => {
  //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
    });
  };

  render() {
    const {
      billables,
      charge_id,
      name,
      selectedType,
      price,
      getInactive,
      valid_to,
      valid_from,
      billable_id,
      currency,
      timezone,
      variant, msg, show
    } = this.state;
    //console.log('billablescharges ', billables)

    var date_from = new Date(valid_from);

    var date_to;
    if (valid_to) {
      date_to = new Date(valid_to);
    } else {
      date_to = "";
    }
    // {billables.map(b => (
    //   <ChargesBillableRow
    //     billable={b}
    //     key={b._id}
    //     valid_from={b.valid_from}
    //     valid_to={b.valid_to}
    //     editCharge={this.editCharge}
    //     deleteCharge={this.deleteCharge}
    //     billable_id={billable_id}
    //     currency={currency}
    //     timezone={timezone}
    //   />
    // ))}

    return (
      <div className="table-padding">
      {show === true ? (
        <Alert variant={variant} onClose={() => this.setShow()} dismissible>
          <Alert.Heading>{msg}</Alert.Heading>
        </Alert>
      ) : (
        <div></div>
      )}
        <Dialog
          ref={el => {
            this.dialog = el;
          }}
        />

        <div className=" headerTable">
          <h4>Charges</h4>
          <button
            className="inactive-tariffs btn-round"
            onClick={this.getAllCharges}
          >
            {getInactive} Inactive
          </button>

          {billables.length
            ?(<div>
            <table className="table-market" id="user-table">
              <thead>
                <tr>
                  <th> Valid Period </th>
                  <th> Name </th>
                  <th> Type </th>
                  <th> Price </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {billables.map(charge => (
                  <ChargesRow
                    charge={charge}
                    key={charge._id}
                    charge_id={charge._id}
                    editCharge={this.editCharge}
                    deleteCharge={this.deleteCharge}
                  />
                ))}
              </tbody>
            </table>
          </div>)
        : <div></div>}
        </div>
        <div>
          <Modal
            dialogClassName="custom-dialog-charge"
            backdrop="static"
            keyboard={false}
            show={this.state.showModalChargeEdit}
            onHide={this.closeCharge}
          >
            <Modal.Header closeButton="closeButton">
              <Modal.Title className="textCenter">Edit Charge</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddChargeForm
                  name={name}
                  selectedType={selectedType}
                  price={price}
                  charge_id={charge_id}
                  startDate={date_from}
                  endDate={date_to}
                  type={"edit"}
                  billable_id={billable_id}
                  currency={currency}
                  timezone={timezone}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
