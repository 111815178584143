import React, {useState, useEffect} from "react"
import { Modal, Button} from "react-bootstrap"

const noop=() => {}

function ConfirmDialog({yes="Yes", onYes=noop, no="No", onNo=noop, text, heading="Confirm"}) {

	return (
		<Modal show={true}
			backdrop="static"
			keyboard={true}
			onEscapeKeyDown={onNo}
			onHide={onNo}
      		aria-labelledby="contained-modal-title-vcenter"
      		centered>
			<Modal.Header closeButton>
				<Modal.Title>{heading}</Modal.Title>
			</Modal.Header>

			{text?(<Modal.Body>{text}</Modal.Body>):null}

			<Modal.Footer>
                <Button onClick={onNo} variant="outline-secondary">{no}</Button>
                <Button onClick={onYes} variant="primary">{yes}</Button>
			</Modal.Footer>
		</Modal>
	)

}

export default ConfirmDialog