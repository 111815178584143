import React from 'react'

import TextControl from './TextControl'
import NumberControl from './NumberControl'
import CheckboxControl from './CheckboxControl'
import DateRangeControl from './DateRangeControl'
import URLControl from './URLControl'


export default function FormControl({descriptor, ...props}) {
    const {type, ...__descriptor} = descriptor

    let FormComponent

    switch(type) {
        case 'text':
            FormComponent = TextControl
            break 
        case 'integer':
            __descriptor.decimalPlaces = 0
            __descriptor.step = 1
            FormComponent = NumberControl
            break
        case 'boolean':
            FormComponent = CheckboxControl
            break
        case 'date-range':
            FormComponent = DateRangeControl
            break 
        case 'url':
            FormComponent = URLControl
            break
        default: 
            throw new TypeError(`Unsupported control type "${type}"`)
    }

    return (<FormComponent descriptor={__descriptor} {...props}/>)
}
