import React, {useState, useEffect} from "react";
import { useHistory, useLocation } from "react-router"
import { Container, Button, Row, Col } from 'react-bootstrap'

import SplitRenewables from './SplitRenewables'

export default function SolarScenarioSettings({settings}) {
    const [prev, setPrev]=useState(null)
    const location=useLocation() 

    useEffect(() => {
        if(location.state && location.state.from) {
            setPrev(location.state.from)
        }
    }, [location])

    const history = useHistory()

    function goBack() {
        if(prev!==null) {
            history.goBack()
        }
        else {
            history.replace('/dashboard/scenario/solar/list')
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col className="headerTable">
                    <h2>Solar Scenario Settings</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="button" onClick={goBack} size="sm" variant="outline-secondary">
                        <i className="fas fa-arrow-left"/>Back
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3></h3>
                    <Container fluid>
                        <SplitRenewables settings={settings}/>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="button" onClick={goBack} size="sm" variant="outline-secondary">
                        <i className="fas fa-arrow-left"/>Back
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}
