import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {friendlyTimeFormatter, Currencies} from "../../../utility";
import {Request} from "../../../services/HttpService";
import { Notify } from '../AlertListener.jsx'
import ElectricitySupplier from './electricity-supplier'

const request = new Request({ service: 'Billable service'})
const notify=Notify("billing.notifications")

const { SearchBar } = Search;

export default function CreatePlanFromExisting({type, user, root}) {
    const [plans, setPlans] = useState([])
    const [suppliers, setSuppliers] = useState(null)
    const [redirect, setRedirect] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState(null)

    useEffect(() => {
		const __load = async () => {
			try {
				const [__suppliers, __plans] = await Promise.all([
					ElectricitySupplier(),
					request.call(`/api/billable/plan?includePeriods`)])
				setSuppliers(__suppliers)
				setPlans(__plans.map(p => {
                    const __searchText = []
                    __searchText.push(p.is_wholesale?'Wholesale':'Tariff')
                    if(p.supplier_id) {
                        __searchText.push(__suppliers.lookup(p.supplier_id))
                    }
                    return {...p, textsearch: __searchText.join(',')}
                }))
			}
			catch(err) {
				notify.error(err.message)
			}
		}

        __load()

		return () => {
			setPlans([])
            setSuppliers(null)
            setSelectedPlan(null)
		}
	}, [])

	const formatter = friendlyTimeFormatter()

    function cancel() {
        setRedirect('')
    }

    function Page1() {
        const [selected, setSelected] = useState(null)
        
        const columns = [{
            dataField: '_id',
            text: 'Plan ID',
            hidden: true
        }, {
            dataField: 'name',
            text: 'Name',
            sort: true
        }, 
        {
            dataField: 'plan_type',
            text: 'Type',
            sort: true
        },
        {
            dataField: 'is_wholesale',
            text: 'Basis',
            sort: true,
            formatter: (cell, row) => (<span>{cell?'Wholesale':'Tariff'}</span>)
        }, {
            dataField: 'description',
            text: 'Description',
            sort: true
        }, {
            dataField: 'supplier_id',
            text: 'Supplier',
            formatter: id => suppliers.lookup(id),
            sort: true
        }, {
            dataField: 'currency',
            text: 'Currency',
            formatter: data => Currencies.get(data).toString(),
            sort: true
        }, {
            dataField: 'updated_at',
            text: 'Modified',
            sort: true,
            formatter: formatter.format
        }, {
            dataField: 'textsearch',
            text: 'textsearch',
            hidden: true
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            // hideSelectColumn: true,
            onSelect: (row, isSelect) => {
                setSelected(row)
                return true
          },
            bgColor: '#28ba9f'
        };

        function next() {
            setSelectedPlan(selected)
        }

        return (
            <div style={{ marginTop: "1rem" }}>
                <Row>
                    <Col>
                        <ToolkitProvider
                                keyField="_id"
                                data={ plans }
                                columns={ columns }
                                search>
                            {props => (<>
                                <div>
                                    <span style={{fontSize: "1.5rem"}}>Select a plan to copy</span>
                                    <div className="btn-right">
                                        <SearchBar
                                                { ...props.searchProps }
                                                delay={ 1000 }
                                                placeholder="Filter"/>
                                        </div>

                                </div>
                          <BootstrapTable
                                bootstrap4 striped hover condensed 
                                version="4"
                                keyField='id' 
                                noDataIndication="No plans found"
                                selectRow={selectRow}
                                defaultSorted={[{dataField: 'name', order: 'asc'}]}
                                { ...props.baseProps }
                            /></>)}
                        </ToolkitProvider>
                    </Col>
                </Row>
                <Row>
  					<Col>
                      <Button 
                            type="button" 
                            variant="outline-secondary" 
                            onClick={cancel}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-times"/>Cancel
                        </Button>
                        <Button 
                            type="button" 
                            variant="re-primary" 
                            disabled={selected===null}
                            onClick={next}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-angle-double-right"/>Next
                        </Button>
  					</Col>
  				</Row>
            </div>
        )
    }

    function Page2() {
        const [periods, setPeriods] = useState([])
        const [canSave, setCanSave] = useState(false)

        const SELECT_CONFIG={
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: handleSelect,
            onSelectAll: handleSelectAll,
            // hideSelectColumn: true,
            bgColor: '#28ba9f'
        }
        const [selected, setSelected] = useState([])
  
        useEffect(() => {
            if(selectedPlan!==null) {
                const {periods=[]} = selectedPlan
                setPeriods(periods)
                // Select all by default
                setSelected(periods.map(p => p._id))
            }
            else {
                setPeriods([])
                setSelected([])
            }

            return () => setPeriods([])
        }, [])

        useEffect(() => {
            setCanSave(selected.length>0)
        }, [selected])

        function handleSelect(row, isSelect) {
            console.log(`handleSelect(${isSelect}) : `, row)
            if(isSelect) {
                if(!selected.includes(row._id)) {
                    selected.push(row._id)
                }
            }
            else {
                const __i = selected.indexOf(row._id)
                if(__i>=0) {
                    selected.splice(__i, 1)
                }
            }
            setSelected([...selected])
        }
    
        function handleSelectAll(isSelect, rows) {
            console.log(`handleSelectAll(${isSelect}) : `, rows)
            const __selected=isSelect?rows.map(r => r._id):[]
            setSelected(__selected)
        }
    
        async function create(edit) {
            try {
                const __new = await request.call(
                    `/api/billable/plan/${selectedPlan._id}/copy`,
                    'put',
                    {
                        params: {
                            type, 
                            periods: selected
                        }
                    })
                notify.info(`Created new "${type}" plan "${__new.name}" `)
                setRedirect(edit?`/${__new._id}/edit`:'')
            }
            catch(err) {
                notify.error(err.message)
            }
            console.log(`Create plan copy (edit=${edit})`)
        }

        function formatDay(ts) {
            return (<span>{ts?moment(ts).format('DD/MM/YYYY'):''}</span>)
        }
    
        const columns = [{
            dataField: '_id',
            text: 'Period ID',
            hidden: true
        }, {
            dataField: 'valid_from',
            text: 'From',
            formatter: formatDay,
            sort: true
        }, {
            dataField: 'valid_to',
            text: 'To',
            formatter: formatDay,
            sort: true
        }, {
            dataField: 'updated_at',
            text: 'Modified',
            sort: true,
            formatter: formatter.format
        }];
      
        return (
            <div style={{ marginTop: "1rem" }}>
                <div style={{fontSize: "1.5rem"}}>Select one or more periods to copy</div>
                <Row>
                    <Col>
                        <BootstrapTable bootstrap4 striped hover condensed 
                            keyField='_id' 
                            className='selectable'
                            data={ periods } 
                            columns={ columns } 
                            selectRow={{...SELECT_CONFIG, selected}}
                            noDataIndication="No periods defined"
                            defaultSorted={[{dataField: 'valid_from', order: 'asc'}]}/>
                    </Col>
                </Row>
                <Row>
  					<Col>
                      <Button 
                            type="button" 
                            variant="outline-secondary" 
                            onClick={cancel}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-times"/>Cancel
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            onClick={() => setSelectedPlan(null)}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-angle-double-left"/>Back
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            disabled={!canSave}
                            onClick={() => create(true)}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-angle-double-right"/>Next
                        </Button>
                        <Button 
                            type="button" 
                            variant="re-primary" 
                            onClick={() => create(false)}
                            disabled={!canSave}
                            style={{marginRight: "1rem"}}>
                            <i className="fas fa-save"/>Save and Finish
                        </Button>
  					</Col>
  				</Row>
            </div>

        )
    }

    if(redirect!==null) {
        return (<Redirect to={`${root}${redirect}`}/>)
    }

    return (
        <Container fluid >
            <Row>
                <Col><h3>Create Billing Plan From Existing</h3></Col>
            </Row>
            {selectedPlan===null?(<Page1/>):(<Page2/>)}
        </Container>
    )
}