import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {Notify} from '../../components/AlertListener'

const notify=Notify("cluster.notifications")

export default function ClusterMemberSettings({cluster=null}) {
    if(cluster===null) return null

    return (
        <Container style={{paddingTop : "1.5rem"}} fluid>
            <Row style={{paddingBottom: "1rem"}}><Col><h4>Cluster Membership</h4></Col></Row>
            <Row><Col>Member of cluster {cluster.name}</Col></Row>
        </Container>
    )
}