import React, { Component } from "react";
import { notify } from "../../utility/ToastContainer";
import HttpServiceSettings from "../../services/HttpServiceSettings";
import Select from "react-select";

const marketTypeOpt = [
  { value: "TARIFF", label: "TARIFF" },
  { value: "WHOLESALE", label: "WHOLESALE" }
];

const timeZoneOpt = [
  { value: "Europe/Dublin", label: "Dublin" },
  { value: "Europe/London", label: "London" },
  { value: "Europe/Berlin", label: "Berlin" },
  { value: "Europe/Madrid", label: "Madrid" },
  { value: "Europe/Paris", label: "Paris" }
];

// const currency = [
//   { value: "EUR", label: "Euro €" },
//   { value: "GBP", label: "Pound £" }
// ];

const currency = [
  { value: "EUR", label: "Euro €" },
];

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export default class AddBillableForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      micActive: false,
      nameActive: false,
      meter_numberActive: false,
      market_typeActive: false,
      timezoneActive: false,
      currencyActive: false,
      timeZoneOptSelected: "",
      marketTypeOptSelected: "",
      billable_id: "",
      meter_name: "",
      MIC: "",
      meter_number: "",
      curency: "",
      mic: "",
      marekt_type: "",
      timezone: "",
      meter_id: "",
      active: true,
      currencySelected: this.props.currencySelected || null,
      timezoneSelected: this.props.timezoneSelected || null,
      marketTypeSelected: this.props.marketTypeSelected || null,
      formValid: false,
      errorCount: 0,
      errors: {
        mic: "",
        meter_number: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const {
        name,
        meter_id,
        meter_name,
        mic,
        meter_number,
        currencySelected,
        timezoneSelected,
        billable_id
      } = this.props;
      //console.log('site_id componentsdid mount', site_id)
      this.setState({
        name: name,
        meter_id: meter_id,
        meter_name: meter_name,
        meter_number: meter_number,
        mic: mic,
        currencySelected: currencySelected,
        timezoneSelected: timezoneSelected,
        billable_id: billable_id
      });
      this.innerRef.current.focus();
    }, 1);
  }

  activateField = e => {
    this.setState({
      [`${e.target.name}Active`]: true
    });
  };

  disableField = e => {
    this.setState({
      [`${e.target.name}Active`]: false
    });
  };

  onChangeActive = () => {
    this.setState({
      active: !this.state.active
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleChangeMarketType = marketTypeSelected => {
    if (marketTypeSelected) {
      this.setState({ market_typeActive: true });
    } else {
      this.setState({ market_typeActive: false });
    }

    let errors = this.state.errors;
    switch (marketTypeSelected) {
      case "select":
        errors.select = !marketTypeSelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, marketTypeSelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleChangeTimeZone = timezoneSelected => {
    if (timezoneSelected) {
      this.setState({ timezoneActive: true });
    } else {
      this.setState({ timezoneActive: false });
    }

    let errors = this.state.errors;
    switch (timezoneSelected) {
      case "select":
        errors.select = !timezoneSelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, timezoneSelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  handleChangeCurency = currencySelected => {
    //console.log('currencySelected', currencySelected)
    if (currencySelected) {
      this.setState({ currencyActive: true });
    } else {
      this.setState({ currencyActive: false });
    }
    let errors = this.state.errors;
    switch (currencySelected) {
      case "select":
        errors.select = !currencySelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, currencySelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  onChangeName = e => {
    //console.log(e.target.value)
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;

    if (e.target.value === "") {
      this.disableField(e);
    } else {
      this.activateField(e);
    }
    //console.log('name', name)
    switch (name) {
      case "mic":
        errors.mic = /\+|-/.test(value) || value < 10 ? "Number is not valid. Must be bigger than 10!" : "";
        break;
      case "meter_number":
        errors.meter_number =
          /\+|-/.test(value) ||   value.length < 3   ? "Number is not valid.Meter Number must be 3 characters long!" : "";
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [name]: value
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
    //this.props.getClusterList();
  };

  addBillable = (event, errors, values) => {
    event.preventDefault();
    //const blb_id = this.state.blb_id;
    const type = this.props.type;
    const meter_id = this.props.meter_id;
    const meter_name = this.props.meter_name;
    const mic = Number(this.state.mic);
    const meter_number = this.state.meter_number;
    const marketType = this.state.marketTypeSelected;
    const market_type = marketType.value;
    const currencyType = this.state.currencySelected;
    const currency = currencyType.value;
    const timezoneType = this.state.timezoneSelected;
    const timezone = timezoneType.value;
    const billable_id = this.props.billable_id;

    //console.log('marketType', marketType)
    //  console.log('market_type', market_type)
    let blb = {
      meter_number: meter_number,
      MIC: mic,
      name: meter_name,
      currency: currency,
      market_type: market_type,
      timezone: timezone,
      meter_id: meter_id
    };
    console.log("blb", blb);
    //console.log('mic', mic)
    if (type === "edit") {
      return HttpServiceSettings.editBillable(billable_id, blb)
        .then(response => {
          this.props.closeForm("success", "Billable is updated successfully.");
        })
        .catch(err => {
          this.props.closeForm("danger", err);
        });
    } else if (type === "add") {
      return HttpServiceSettings.addBillable(blb)
        .then(response => {
          if (response.data) {
            this.props.closeForm("success","Billable is created successfully.");
            this.setState({
              meter_number: "",
              MIC: "",
              name: "",
              currency: "",
              market_type: "",
              timezone: "",
              currencySelected: null,
              timezoneSelected: null,
              marketTypeSelected: null
            });
          } else {
            this.props.closeForm("danger","Billable name already exists");
          }
        })
        .catch(err => {
          this.props.closeForm("danger", "Billable not created");
          console.log("err", err);
        });
    } else {
      console.log("error");
    }
  };

  // closeForm = event => {
  //   if (this.props.closeForm) {
  //     this.props.closeForm();
  //   }
  // };

  render() {
    const {
      errors,
      formValid,
      errorCount,
      mic,
      meter_number,
      marketTypeSelected,
      currencySelected,
      timezoneSelected
    } = this.state;
    //const meter_id = this.props.meter_id;
    //console.log('meter_id', meter_id)
    const { type } = this.props;
    //const currencySelected = marketTypeOpt[0]

    var micActive,
      meter_numberActive,
      market_typeActive,
      timezoneActive,
      currencyActive;
    if (type === "edit") {
      micActive = meter_numberActive = market_typeActive = timezoneActive = currencyActive = true;
    } else {
      micActive = this.state.micActive;
      meter_numberActive = this.state.meter_numberActive;
      market_typeActive = this.state.market_typeActive;
      timezoneActive = true;
      currencyActive = true;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addBillable}>
            <div className="form-group">
              <div>
                <div className="labelField">
                  <label className={meter_numberActive ? "on" : ""}>
                    Number
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    required
                    name="meter_number"
                    id="meter_number"
                    onChange={this.onChangeName}
                    placeholder="Enter Meter Number"
                    value={meter_number || ""}
                    ref={this.innerRef}
                  />
                  {errors.meter_number.length > 0 && (
                    <span className="error">{errors.meter_number}</span>
                  )}
                </div>

                <div className="labelField">
                  <label className={micActive ? "on" : ""}>MIC</label>
                  <input
                    className="form-control"
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    required
                    name="mic"
                    id="mic"
                    onChange={this.onChangeName}
                    placeholder="Enter MIC"
                    value={mic || ""}
                  />
                  {errors.mic.length > 0 && (
                    <span className="error">{errors.mic}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="labelField">
              <label className={market_typeActive ? "on" : ""}>
                Market Type
              </label>
              <Select
                value={marketTypeSelected}
                onChange={this.handleChangeMarketType}
                options={marketTypeOpt}
                placeholder={"Select Market Type"}
                name="market_type"
                inputId="market_type"
              />
            </div>
            <div className="labelField">
              <label className={timezoneActive ? "on" : ""}>Time Zone</label>
              <Select
                value={timezoneSelected}
                onChange={this.handleChangeTimeZone}
                options={timeZoneOpt}
                placeholder={"Select Time Zone"}
                name="timezone"
                inputId="timezone"
              />
            </div>

            <div className="labelField">
              <label className={currencyActive ? "on" : ""}>Currency</label>
              <Select
                value={currencySelected}
                onChange={this.handleChangeCurency}
                options={currency}
                placeholder={"Select Currency"}
                name="currency"
                inputId="currency"
              />
            </div>

            {/*<div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="active"
                id="active"
                onChange={this.onChangeActive}
                placeholder="Enter Dataset Name"
                value={active}
                defaultChecked={active}
              /></label>
              </div>*/}

            <div className="form-group">
              <button
                disabled={
                  formValid === false || errorCount !== 0 || !marketTypeSelected
                }
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false || errorCount !== 0 || !marketTypeSelected
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
