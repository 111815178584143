import React, {useState, useEffect, useCallback, useContext } from 'react'
import UserContext from "../../services/user-context"
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataZoom";
import 'echarts/lib/component/legendScroll';
import { STYLES, BASE_OPTIONS, ENERGY_AXES, ENERGY_AXES_WATTS } from './options'

const DateTimeAxis = function(values) {
    let __prev=null

    return {
        data: values,
        format: (ts, i) => {
            if(i===0) __prev=null // Reset the formatter
            const __ts = values[i]
            const __format = __prev===null || __prev.weekday()!==__ts.weekday()?
                "HH:mm[\n(]MM/DD[)]":
                "HH:mm"
            __prev = __ts
            return __ts.format(__format)
        }
    }
}

export default function EnergyPlot({load=null, supply=null, spill=null,
        predictedLoad=null, predictedSupply=null,
        charge=null, discharge=null,
        startTime=null, endTime=null,
        units='kwh', resolution='hour'
    }) {
	const [chart, setChart] = useState(null)
    const [xAxis, setXAxis] = useState(null)

    const { Time } = useContext(UserContext)

    const [xAxisLeft] = useState(startTime?
        Time.localTime(startTime).startOf('hour'):
        null)
    const [xAxisRight, setXAxisRight] = useState(startTime?
        Time.localTime(startTime).add(1, 'hour').startOf('hour'):
        null)
    const [option, setOption] = useState({
        ...BASE_OPTIONS, 
        yAxis: units==='watts'?ENERGY_AXES_WATTS:ENERGY_AXES})
    const [series, setSeries ] = useState([])


    const updateSeries=useCallback((name, data, attr='reading') => {
        const __style = STYLES.get(name)
        if(!__style) return 

        if(!Array.isArray(data) || data.length===null) {
            setSeries(s => {
                const __updated = {...s}
                delete __updated[name]
                return __updated
            })
            return 
        }

        const __series=[]
        let __max=xAxisRight, __values=0
        for(const {timestamp, ...v} of data) {
            const __value = v[attr]
            if(typeof(__value)!=='undefined' && __value!==null) {
                __values+=1
                if(xAxisLeft) {
                    const __ts=Time.localTime(timestamp)
                    if(endTime===null || __ts.isSameOrBefore(endTime)) {
                        if(__max.isBefore(__ts)) {
                            __max = __ts
                        }
                        __series[__ts.diff(xAxisLeft, 'hour')] = __value
                    }
                }
                else {
                    __series.push(__value)
                }
            }
            else {
                __series.push(undefined)
            }
        }
        if(__max) {
            setXAxisRight(__max)
        }

        series[name] = (__values>0)?__series:[]
        // console.log(`Series[${name}] : `, __series)
        setSeries({...series})
    }, [series, setSeries, xAxisLeft, xAxisRight, Time, endTime])

    useEffect(() => {
        updateSeries('actual_supply', supply)
    }, [supply])

    useEffect(() => {
        updateSeries('predicted_load', predictedLoad, 'prediction')
    }, [predictedLoad])

    useEffect(() => {
        updateSeries('predicted_supply', predictedSupply, 'prediction')
    }, [predictedSupply])

    useEffect(() => {
        updateSeries('actual_load', load)
    }, [load])

    useEffect(() => {
        updateSeries('spill', spill)
    }, [spill])

    useEffect(() => {
        updateSeries('charge', charge)
    }, [charge])

    useEffect(() => {
        updateSeries('discharge', discharge)
    }, [discharge])

    useEffect(() => {
        if(!xAxisLeft || !xAxisRight) return
        // console.log(`[EnergyPlot] - left= ${xAxisLeft}, right=${xAxisRight}`)
        let __t = Time.day(xAxisLeft)
        const __last=Time.localTime(xAxisRight).add({hours: 23, minutes: 59}).startOf('day').add(1, 'minute')
        const labels=[]
        const __tick={
            hour: resolution==='hour'?1:0,
            minute: resolution==='raw'?1:0
        }
        while(__t.isBefore(__last)) {
            labels.push(Time.localTime(__t))
            __t.add(__tick)
        }
        setXAxis(DateTimeAxis(labels))

    }, [xAxisLeft, xAxisRight, Time])

    useEffect(() => {
        if(chart===null) return 

        // console.log(`[EnergyPlot::setOption] - enter `)

		const __instance = chart.getEchartsInstance() ;
        const __opt = __instance.getOption()

        // console.log('[EnergyPlot] series=', series)
        const __extra={}
        if(xAxis) {
            const [__main, ...__rest] = __opt.xAxis,
                { axisLabel = {}} = __main
            __extra.xAxis = [ 
                { 
                    ...__main, 
                    data: xAxis.data,
                    axisLabel: {
                        ...axisLabel,
                        formatter: xAxis.format
                    }
                },
                ...__rest
            ]
            // console.log('[EnergyPlot] : xAxis=', __extra.xAxis)
        }
        setOption({
            ...__opt, 
            ...__extra,
            series: Object.entries(series)
                .map(([k, v]) => {
                    // Get the style
                    if(v.length===0) return null
                    const __style = STYLES.get(k) 
                    if(!__style) return null
                    
                    const s = { ...__style, type: 'line', xAxisIndex: 0, data: v}
                    return s
                })
                .filter(s => s!==null)
        })

    }, [chart, series, xAxis, setOption])

    return (
        <ReactEcharts
            ref={(e) => { setChart(e); }}
            notMerge={true}
            lazyUpdate={true}
            echarts={echarts}
            option={option}
            style={{ height: "350px", width: "100%" }}
        />

    )
}