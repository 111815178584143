import HttpService from "./UserServices";
import {Request} from "./HttpService";

const request = new Request({ service: 'Auth service'})

const empty = /^\s*$/;

export class AuthenticatedUser {
  constructor(_u) {
    this.id = _u.id;
    this.firstname = _u.firstname;
    this.lastname = _u.lastname;
    this.email = _u.email;
    this.organisation = _u.organisation;
    this.organisationId = _u.organisation && _u.organisation.id
    this.clusterId = _u.clusterId
    this.clusterName = _u.clusterName
    this.roles = _u.roles || [];
    this.isUser = this.roles.includes('user')
    this.isAdmin = this.roles.includes('admin')
    this.isOrgAdmin = this.roles.includes('cadmin')
    this.isClusterOwner = this.roles.includes('cluster_owner')
  }

  static create(_u) {
    if (typeof _u !== 'undefined' && _u !== null) {
      try {
        if (typeof _u === "string" && !_u.match(empty)) {
          _u = JSON.parse(_u);
        }
        if (
          typeof _u.id !== "undefined" &&
          typeof _u.email !== "undefined" &&
          typeof _u.lastname !== "undefined" &&
          typeof _u.organisation !== "undefined" &&
          typeof _u.organisation.id !== "undefined"
        ) {
          return new AuthenticatedUser(_u);
        }
      } catch (err) {
        console.error("AuthenticatedUser::create() : ", err);
      }
    }
    return null;
  }
}

class AuthService {
  constructor() {
    this._user = null;
  }

  clearSession() {
    this._user=null ;
    sessionStorage.clear();
    sessionStorage.removeItem("_user");
    //localStorage.clear('token')
    window.location.href = "/login";
    console.log('Logged out')
    return null;
  }

  createSession(_user) {
    if (_user !== null) {
      sessionStorage.setItem("_user", JSON.stringify(_user));
      // console.log(`createSession() : Created session for ${_user.email}`)
      this._user = _user;
      return _user;
    }
    return this.clearSession();
  }

  async isAuthenticated() {
    // try {
    //   const u = request.get('/api/user/me')
    //   const __user = AuthenticatedUser.create(u)
    //   return this.createSession(__user)
    // }
    // catch(err) {
    //   if((typeof(err)==='string' && err.match(/Unauthorized/)!==null ) || err.status === 401) {
    //     return this.clearSession()
    //   }
    //   throw err;
    // }
    if (this._user === null) {
      // Try to create user from session storage
      this._user = AuthenticatedUser.create(sessionStorage.getItem("_user"));
      if (this._user === null) {
        try {
          // See is there a session - call the backend
          const response = await HttpService.checkSession();
          return this.createSession(AuthenticatedUser.create(response.data));
        } catch (err) {
          if((typeof(err)==='string' && err.match(/Unauthorized/)!==null ) || err.status === 401) {
            this._user = null
            return null ;
          }
          throw err;
        }
      }
    }
    return this._user;
  }

  async login(email, password) {
    try {
      const response = await HttpService.login(email, password);
      if (new RegExp('401').test(response)) {
        return {"StatusCode":401,"msg":response}
      } else{
        return this.createSession(AuthenticatedUser.create(response.data));
      }
    } catch (err) {
      console.error("login()", err);
      return null;
    }
  }

  async logout() {
    await HttpService.logout();
    return this.clearSession();
  }
}


export default new AuthService();
