import React, {useState, useEffect, useContext, useCallback} from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import ThreeDayPlot from "../plots/ThreeDayPlot"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import SessionStorage from "../../services/SessionStorage"
import WidgetPanel from "../widgets/WidgetPanel";

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

const SELECTED_SITE_KEY = 'site.dash.site.selected'

const NO_SITES = { value: "", label: "-- No sites --"}

export default function SiteDashboard() {
    const [title, setTitle] = useState(null)
    const [loadData, setLoadData] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedLoadData, setPredictedLoadData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)
    const [tariffData, setTariffData] = useState(null)
    const [batteryChargeData, setBatteryChargeData] = useState(null)
    const [batteryDischargeData, setBatteryDischargeData] = useState(null)
    const [sites, setSites] = useState([NO_SITES])
    const [selectedSite, setSelectedSite] = useState(null)
    const [siteBatteries, setSiteBatteries] = useState([])
    const [batteries, setBatteries] = useState(null)

    const { user, Time } = useContext(UserContext)



    useEffect(() => {
        if(user===null) return ;

        const { organisationId } = user

        const __loadSites = async () => {
            try {
                let __sites = await request.get(`/api/organisation/${organisationId}/site`, {
                    tenancyView: true,
                    full: true
                })
                
                if(__sites.length===0) {
                    setSites([NO_SITES])
                    return ;
                }

                const __tree=[]
                
                // Filter out all sites whose parent ID is not in set
                const __ids = __sites.map(({_id}) => _id)
                const __filtered = __sites.map(({parent_id, ...rest}) => {
                    if(!parent_id || parent_id==='' || !__ids.includes(parent_id)) return rest
                    return {
                        ...rest,
                        parent_id
                    }
                })

                function addNode({_id, name}, depth=0) {
                    __tree.push({ value: _id, label: `${depth>0?'- ':''}${name}`, name, depth})
                    // Need to add child nodes?
                    const children = __filtered.filter(({parent_id}) => _id===parent_id)
                    for(const c of children) {
                        addNode(c, depth+1)
                    }
                }
                const __top=__filtered.filter(({parent_id}) => !parent_id)
                for(const n of __top) {
                    addNode(n)
                }
                setSites(__tree)
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }

        const __loadBatteries = async () => {
            // From/to
            const __from = Time.localToDate(Time.yesterday()), 
                __to = Time.localToDate(Time.tomorrow().add(1, 'day'))

            try {
                let __batteries = await request.get(`/api/battery`, {
                    from: __from,
                    to: __to
                })
                
                setBatteries(__batteries)
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }

        __loadSites()
        __loadBatteries()

    }, [user])

    useEffect(() => {
        if(selectedSite===null || batteries===null) return

        // Is there a battery for the site?
        const siteId = selectedSite.value
        setSiteBatteries(batteries.filter(({site_id}) => site_id===siteId))

        return () => setSiteBatteries([])
    }, [selectedSite, batteries])

    // useEffect(() => {
    //     console.log('Batteries for site : ', siteBatteries)
    // }, [siteBatteries])

    useEffect(() => {
        if(sites===null) return 
        let __selected = SessionStorage.get(SELECTED_SITE_KEY, "")
        const [__site] = sites.filter(({value}) => value===__selected)
        if(__site) {
            setSelectedSite(__site)
        }
        else {
            setSelectedSite(sites[0])
        }
    }, [sites])

    useEffect(() => {
        if(selectedSite===null || sites===null) return

        const __query= {
            from: Time.yesterday().format(),
            // Add day + 1 minute to include midnight at end
            to: Time.tomorrow().add({day: 1, minute: 1}).format(),
            price: 1
        }
        const { value: _id, name }= selectedSite
        if(_id) {
            // Fetch site data
            setTitle(`Site - ${name}`)

            // Actual load
            Fetch(`/api/meter/site/${_id}/readings`, { 
                ...__query,
                meter_type: 'load', 
                summary: true}, setLoadData, ["timestamp", "reading", "price", "charges"])
            // Predicted load
            Fetch(`/api/organisation/${user.organisationId}/site/${_id}/predict/load`, __query, setPredictedLoadData)
            // Fetch(`/api/cluster/${clusterId}/predict/load`, __query, setPredictedLoadData)
            // // Actual supply
            Fetch(`/api/meter/site/${_id}/readings`, 
                    { ...__query, meter_type: 'supply', summary: true}, 
                    setSupplyData,
                    ["timestamp", "reading"],
                    excludeNegativeFilter())
            // Predicted supply
            Fetch(`/api/organisation/${user.organisationId}/site/${_id}/predict/supply`,
                __query,
                setPredictedSupplyData)
            // Tariffs
            Fetch(`/api/billable/organisation/${user.organisationId}/rates`, __query, setTariffData)

            // Check for a battery
            // findBattery()
            // Get any battery readings
            // Fetch(`/api/meter/site/${_id}/readings`, 
            //         { ...__query, meter_type: 'storage', summary: true}, 
            //         (readings) => {
            //             setBatteryChargeData(readings.map(({timestamp, reading}) => ({
            //                 timestamp,
            //                 reading: reading<0?Math.abs(reading):0
            //             })))
            //             setBatteryDischargeData(readings.map(({timestamp, reading}) => ({
            //                 timestamp,
            //                 reading: reading>0?reading:0
            //             })))
            //         },
            //         ["timestamp", "reading"])
            return ;
        }
        setLoadData(null)
        setSupplyData(null)
        setPredictedLoadData(null)
        setPredictedSupplyData(null)
        setTariffData(null)
    }, [selectedSite])

    const onSiteChange = useCallback((site) => {
        setSelectedSite(site)
        SessionStorage.set(SELECTED_SITE_KEY, site.value)
    }, [sites])

    if(user===null) return null

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Form className="dropdown">
                        <Form.Row className="align-items-center">
                            <Col xs="auto"><h2>{title}</h2></Col>
                            <Col style={{textAlign: 'right'}}><h4>Site</h4></Col>
                            <Col xs={3} style={{float: 'right'}}>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={selectedSite}
                                    options={sites}
                                    onChange={onSiteChange}
                                    styles={{
                                        option: (styles, {data={}}) => {
                                            const {depth=0} = data
                                            return {
                                                ...styles,
                                                paddingLeft: `${depth*2}rem`
                                            }
                                        }
                                    }}
                                    />

                            </Col>
                        </Form.Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                    {/* <OrganisationMeters organisation={organisation} redirect notable noheader/> */}
                    <ThreeDayPlot 
                        load={loadData} 
                        supply={supplyData}
                        predictedLoad={predictedLoadData} 
                        predictedSupply={predictedSupplyData} 
                        // batteryCharge={batteryChargeData}
                        // batteryDischarge={batteryDischargeData}
                        tariffs={tariffData}
                        batteries={siteBatteries}
                    />
                </Col>
                <Col lg={2}>
                    <WidgetPanel load={loadData} solar={supplyData}/>
                </Col>
            </Row>
        </Container>
    )
}

