import React, { Component } from "react";
//import { Link } from "react-router-dom";
import NavigationItem from "../../utility/NavigationItem";
import imgWind from "../../assets/svg/wind.svg";
import SessionStorage from '../../services/SessionStorage'
import logo from "./Icon_White.png";

function ExpandButton({collapsed=true, toggle=() => {}}) {

  const handleClick = (e) => {
    e.preventDefault() 
    toggle()
  }

  return (
    <div style={{position: 'relative'}}>
    <div
      style={{
        position: 'absolute',
        right: '-2.2rem',
        top: '0.7rem',
        backgroundColor: 'white',
        color: '#000',
        zIndex: '1000',
        fontSize: '1.75rem',
        overflow: 'visible'
      }}>
        <a
          href="/#"
          style={{ color: "#000"}}
          onClick={handleClick}>
        <i className={collapsed ? "fas fa-bars" : "fa fa-times"} />
      </a>
  </div></div>)
}

const COLLAPSED_KEY = 'main.dashboard.nav.collapsed'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: SessionStorage.get(COLLAPSED_KEY, false)
    }
  }

  toggleCollapsed = () => {
    const collapsed = !this.state.collapsed 
    SessionStorage.set(COLLAPSED_KEY, collapsed)
    this.setState({collapsed})
    this.props.onCollapseChange && this.props.onCollapseChange(collapsed)
  }

  render() {
    const { user, location } = this.props;
    const { collapsed } = this.state

    const isCadmin = user.roles.includes("cadmin"),
      isClusterOwner = user.roles.includes("cluster_owner") ;

    const clsActive = `${collapsed ? "collapsed" : ""}`;
    return (<>
      <nav id="sidebar" 
          className={collapsed ? "sidebar-collapse" : ""} style={{ overflow: 'visible'}}>
        <ExpandButton collapsed={collapsed} toggle={this.toggleCollapsed}/>
        <div className="sidebar-content">
        <div className="sidebar-header">
          <img src={logo} alt="SA logo" className="pisces-logo" />
          <img src={logo} alt="SA logo" className="pisces-logo collapsed" />
          <h5 className="project-name">
            REG<b>ENERGY</b>
          </h5>
        </div>
        <ul className="list-unstyled components">
          <NavigationItem to="/dashboard/home">
            <i className="fas fa-home" />
            <span>Home</span>
          </NavigationItem>
          <NavigationItem to="/dashboard/modulesgraph/market">
            <i className="fa fa-chart-line" aria-hidden="true" />
            <span>Market</span>
          </NavigationItem>
          <NavigationItem to="/dashboard/load">
            <i className="fas fa-bolt" />
            <span>Load</span>
          </NavigationItem>

          <NavigationItem to="/dashboard/solar">
            <i className="fas fa-sun" aria-hidden="true" />
            <span>Solar</span>
          </NavigationItem>

          <NavigationItem to="/dashboard/modulesgraph/wind">
            <img alt={"wind"} className="sidebar-icon" src={imgWind} />
            <span>Wind</span>
          </NavigationItem>

          <NavigationItem to="/dashboard/battery">
            <i className="fas fa-battery-full" aria-hidden="true" />
            <span>Battery</span>
          </NavigationItem>

          <NavigationItem to="/dashboard/flow">
            <img alt={"wind"} className="sidebar-icon" src={imgWind} />
            <span>Flow</span>
          </NavigationItem>
          {
            isClusterOwner?(
              <NavigationItem to="/dashboard/scenario">
                <i className="fas fa-play" aria-hidden="true" />
                <span>Scenarios</span>
              </NavigationItem>
            ) : (
            <div></div>
          )}
          {isCadmin ? (
            <div>
              <NavigationItem to="/dashboard/settings">
                <i className="fas fa-cog" aria-hidden="true" />
                <span>Settings</span>
              </NavigationItem>
            </div>
          ) : (
            <div></div>
          )}
          {isClusterOwner && !isCadmin ? (
            <div>
              <NavigationItem to="/dashboard/cluster-admin">
                <i className="fas fa-cog" aria-hidden="true" />
                <span>Settings</span>
              </NavigationItem>
            </div>
          ) : (
            <div></div>
          )}
        </ul>
        </div>
      </nav></>
    );
  }
}

export default Sidebar;
