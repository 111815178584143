import React from "react";

export default function UserRows({ user, changePassword, editUser }) {
  let _roles = user.roles;
  //const role_id = localStorage.getItem("role_id");
  if (typeof _roles === "undefined" || _roles === null) _roles = [];
  return (
    <tr>
      <td>{user.active ? "Yes" : "No"}</td>
      <td>
        {user.firstname}&nbsp;{user.lastname}
      </td>
      <td>{user.email}</td>
      <td>{_roles.join(", ")}</td>

      <td>
        <button className="editDelete" onClick={() => changePassword(user)}>
          Password
        </button>
        <button className="editDelete" onClick={() => editUser(user)}>
          User
        </button>
      </td>

    {/*  {user.roles.includes("admin") && role_id !== "admin" ? (
        <td></td>
      ) : (
        <td>
          <button className="editDelete" onClick={() => changePassword(user)}>
            Password
          </button>
          <button className="editDelete" onClick={() => editUser(user)}>
            User
          </button>
        </td>
      )}*/}
    </tr>
  );
}
