import React, { Component } from "react";
import ee from "event-emitter";

const emitter = new ee();
export const notify = (toastMsg) => {
  emitter.emit("notification", toastMsg);
};

class ToastContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastMsg: "",
      showing: false,
			timeout_id:'',
			ids:[]
    };
    this.timeout = null;
    emitter.on("notification", toastMsg => {
      this.onShow(toastMsg);
    });
  }

  onShow = (toastMsg) => {
    this.showNotification(toastMsg);
    // if (this.timeout) {
    //   clearTimeout(this.timeout);
    //   this.setState(() => {
    //     this.timeout = setTimeout(() => {
    //       this.showNotification(toastMsg);
    //     }, 5000);
    //   });
    // } else {
    //   this.showNotification(toastMsg);
    // }
  };

  showNotification = (toastMsg) => {

    this.setState(
      {
        toastMsg,
        showing: true
      },() => {
         var ids = setTimeout(() => {
          this.setState({
            showing: false,
						ids:ids
          });
        }, 5000)
      });
			//console.log('id', this.state.ids)

    };



    closeToast=()=>{
      this.setState({showing: false});
			const ids = this.state.ids
		//	console.log('close...', ids);
			for (var i=0; i<ids.length; i++) {
  			clearTimeout(ids[i]);
				}
    }


  render() {

    const { showing, toastMsg } = this.state;

    let className = "lobibox-notify  animated-fast fadeInDown notify-mini rounded " + toastMsg.className;

    const toastHtml = (
      <div className="lobibox-notify-wrapper top center" >
        <div className={className}>
          <div className="lobibox-notify-icon-wrapper">
          <div className="lobibox-notify-icon"><div><div className="icon-el">
          <i className="fa fa-exclamation-circle"></i></div></div></div></div>
          <div className="lobibox-notify-body">
          <div className="lobibox-notify-msg" style={{ fontWeight: "700"}}>{toastMsg.msg}</div>
          </div><span className="lobibox-close" onClick={() => this.closeToast()}>×</span>
        </div>
      </div>
    );


    return (
        showing ? toastHtml: ""
    );
  }
}
export default ToastContainer;
