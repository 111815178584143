import React, { useState, useEffect, useContext, useCallback} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import ClusterSelector from "../cluster/ClusterSelector"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'
import AccuracyTable from './../load/AccuracyTable'

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

export default function ClusterSolar({startDate, endDate}) {
    const [cluster, setCluster] = useState(null)
    const [organisation, setOrganisation] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)
    
    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(user===null || !user.clusterId) return ;

        const { clusterId : id } = user

        async function getCluster() {
            try {
                const c = await request.get(`/api/cluster/${id}`)
                setCluster(c)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        getCluster()

        return () => {
            setCluster(null)
        }
    }, [user])

    const __loadMemberData = useCallback(async () => {
        // console.log(`[ClusterLoad]] Fetch load data for org ${organisation.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        const { _id } = organisation
            // Actual supply
            Fetch(`/api/meter/organisation/${_id}/readings`, 
                    { ...__query, meter_type: 'supply'}, 
                    setSupplyData,
                    ["timestamp", "reading"],
                    excludeNegativeFilter())
            // Predicted supply
            Fetch(`/api/cluster/${cluster._id}/organisation/${_id}/predict/supply`, __query, setPredictedSupplyData)
    }, [startDate, endDate, cluster, organisation])

    const __loadClusterData = useCallback(async () => {
        // console.log(`[ClusterLoad]] Fetch load data for cluster ${cluster.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        Fetch(`/api/meter/cluster/${cluster._id}/readings`, 
            { ...__query, meter_type: 'supply'}, 
            setSupplyData,
            ["timestamp", "reading"])

        // Predicted supply
        Fetch(`/api/cluster/${cluster._id}/predict/supply`, __query, setPredictedSupplyData)
    }, [startDate, endDate, cluster])

    useEffect(() => {
        if(!organisation) return
        if(!organisation._id) {
            __loadClusterData()
        }
        else {
            __loadMemberData()
        }
        return () => {
            setSupplyData(null)
            setPredictedSupplyData(null)
        }
    }, [startDate, endDate, cluster, organisation])

    useEffect(() => {
        return () => {
            setSupplyData(null)
            setPredictedSupplyData(null)
        }
    }, [cluster])

    return cluster && (<Container fluid>
        <ClusterSelector cluster={cluster} onChange={setOrganisation} titlePre="Solar"/>
        <div>
            {(supplyData || predictedSupplyData) && <>
                <EnergyPlot 
                    supply={supplyData} 
                    predictedSupply={predictedSupplyData} 
                    startTime={startDate}
                    endTime={endDate}/>
            <AccuracyTable observed={supplyData} predicted={predictedSupplyData}/></>}
        </div>
    </Container>)
}