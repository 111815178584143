import React, {useState, useEffect, useContext} from 'react'
import UserContext from "../../../services/user-context"
import { Container, Row, Col } from 'react-bootstrap'
import {AlertContainer, Notify} from '../../components/AlertListener'
import OrganisationMeters  from './OrganisationMeters'
import {Request} from "../../../services/HttpService";
import ClusterMemberSettings from './ClusterMemberSettings'
import { isID } from '../../../utility'
const request = new Request({ service: 'Organisation service'})
const notify=Notify("org.notifications")

export default function OrganisationDashboard() {
    const [organisation, setOrganisation] = useState(null)
    const [cluster, setCluster] = useState(null)

    const {user}  = useContext(UserContext)

    useEffect(() => {
        if(user===null) return

        // Load the organisation
        const __getOrg =async () => {
            try {
                const o = await request.get(`/api/organisation/${user.organisationId}`)
                setOrganisation(o)
            }
            catch(err) {
                notify.error(err.message)
            }
        }

        const __getCluster = async () => {
            const _id = user.organisation && user.organisation.clusterId
            if(isID(_id)) {
                try {
                    setCluster(await request.get(`/api/cluster/${_id}`))
                }
                catch(err) {
                    notify.error(err.message)
                }
            }

        }

        // Load stuff here
        __getOrg()
        __getCluster()
        return () => {
            setOrganisation(null)
            setCluster(null)
        }
    }, [user])

    return user?(<>
        <AlertContainer tag="org.notifications" page="organisation"/>
        <Container fluid>
            <Row>
                <Col lg="12">
                    <h3>Organisation Settings</h3>
                </Col>
            </Row>

            {organisation?(<>
            <Row>
                <Col>
                    <OrganisationMeters organisation={organisation}/>
                </Col>
            </Row>
            </>):null}
            <ClusterMemberSettings cluster={cluster}/>
        </Container>
    </>):null
}