export default {
    set : function(name, value) {
        const __type = typeof(value) ;
        if(__type==='undefined') {
            // unset the value
            sessionStorage.removeItem(name)
        }
        else {
            sessionStorage.setItem(name, JSON.stringify({
                t: __type,
                v: value
            }))
        }
    },

    get : function(name, dflt=undefined) {
        const __stored = JSON.parse(sessionStorage.getItem(name)) ;
        if(__stored === null) {
            return dflt ;
        }
        switch(__stored.t) {
            case 'number':
                return Number(__stored.v)
            default: 
                return __stored.v
        }
    }
}