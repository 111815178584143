import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { PlayButton, EditButton, DeleteButton, ConfirmAlert, ConfirmDialog } from '../../components/UIComponents.jsx'
import { Loading } from "../../components/UIComponents.jsx"

import { Container, Row, Col, Dropdown, Button, ButtonGroup, ButtonToolbar } from "react-bootstrap"
import { Request } from "../../../services/HttpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Notify } from '../../components/AlertListener.jsx'
import './load-scenario.css'

const notify = Notify("load.notifications")
const request = new Request({ service: 'Scenario service'})


function LoadScenarioList(){
	const [ loading, setLoading ] = useState(true);
	const [ disabled, setDisabled ] = useState(false);
	const [ scenarios, setScenarios ] = useState([]);
	const [ mode, setMode ] = useState('list');
	const [ selected, setSelected ] = useState([]);
	const [ deleteId, setDeleteId ] = useState(null);
	const [ confirmDeleteMultiple, setConfirmDeleteMultiple] = useState(false);

	const columns = [{
		dataField: '_id',
		text: 'id',
		hidden: true
	}, {
		dataField: 'name',
		text: 'Name',
		sort: true
	}, {
		dataField: 'description',
		text: 'Description',
		sort: true
	}, {
		dataField: 'buttons',
		isDummyField: true,
		text: '',
		formatter: (cellcontent, row, rowIndex, __deleteId) => {
			return __deleteId !== row._id?(
				<React.Fragment>
					<Link to={`/dashboard/scenario/load/${row._id}/run`}>
						<PlayButton label={`Run scenario "${row.name}"`} size="sm"/>
					</Link>
					<Link to={`/dashboard/scenario/load/${row._id}/edit`}>
						<EditButton label={`Edit scenario "${row.name}"`} size="sm"/>
					</Link>
				  <DeleteButton label={`Delete scenario "${row.name}"`} size="sm" onClick={(e) => { e.stopPropagation(); setDeleteId(row._id)}}/>
				</React.Fragment>
			):(
				<ConfirmAlert text={`Delete "${row.name}"?`}
					onYes={() => { deleteScenario(row._id, row.name)}}
					onNo={() => { setDeleteId(null) }}
					className="pull-right"
				/>
			)
		},
		formatExtraData: deleteId
	}];
	
	const defaultSorted = [{
		dataField: 'name',
		order: 'asc'
	}];

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: true,
		selected,
		// hideSelectColumn: true,
		onSelect: (row, isSelect) => {
			const { _id } = row;

			if (disabled) {
				return false
			}

			if (isSelect) {
				if (!selected.includes(_id)) {
					selected.push(_id)
					setSelected([...selected]);
				}
				else {
					console.warn(`Row ${_id} already selected`)
				}
			} else {
				const ndx = selected.indexOf(_id)
				if (ndx >= 0) {
					selected.splice(ndx, 1)
					setSelected([...selected]);
				}
				else {
					console.warn(`Row ${_id} not selected`)
				}
			}
			return true
		},
		onSelectAll: (isSelect, rows) => {
			if (isSelect) {
				setSelected(rows.map(r => r._id));

			}
			else {
				setSelected([]);
			}
		},
		bgColor: '#28ba9f'
	};

	async function loadList(){
		// Call the API
		let scenarioList = [];
		try {
			scenarioList = await request.call('/api/scenario/load')
			// Reconcile selected list with the loaded list
			setSelected(scenarioList.filter(({_id}) => selected.includes(_id)).map(({_id}) => _id))
		}
		catch (err) {
			notify.error(err.message);
		}

		setScenarios(scenarioList)
	}

	async function deleteScenario(id, name) {
		if(deleteId !== null && deleteId === id) {
			try {
				await request.call(`/api/scenario/load/${deleteId}`, 'delete')
			    notify.info(`Deleted scenario "${name}"`);
				setDeleteId(null);
			    loadList();
			}
			catch(err) {
			    notify.error(err.message)
			}
		}
	}

	async function deleteMultiple(e){
		// const { selected } = this.state ;
		if(e === true) {
			// Confirmed delete
			const deleteRequests = selected.map(id => {
				return request.call(`/api/scenario/load/${id}`, 'delete')
				.catch(err => {
					notify.error(err.message)
					return err
				})
			})
			await Promise.all(deleteRequests)
			setConfirmDeleteMultiple(false);
			setSelected([]);
			loadList()

			return;
		}

		if(selected.length>0) {
			setConfirmDeleteMultiple(true);
		}
		
	}

	useEffect(() => {
	
		setLoading(true)
		Promise.allSettled([loadList()])
		.then(() => {
			setLoading(false)
		})
		
	}, [])

	return (
		<React.Fragment>
		{confirmDeleteMultiple?(<ConfirmDialog 
				yes="Delete" onYes={() => deleteMultiple(true)} 
				no="Cancel" onNo={() => setConfirmDeleteMultiple(false)} 
				heading="Confirm Deletion" 
				text={`Delete ${selected.length} scenario${scenarios.length>1?'s':''}?`}/>):null}
			<Container fluid>
				<Row>
					<Col className="headerTable">
						<h2>Scenarios</h2>
					</Col>
				</Row>
				<Row>
					<Col className="button-panel">
						<ButtonToolbar className="justify-content-between" aria-label="Toolbar with Button groups">
							<ButtonGroup className="mr-2">
								<Dropdown as={ButtonGroup} style={{ margin: "0 0.5rem 0 0" }}>
									<Link to="/dashboard/scenario/load/new">
										<Button type="button" variant="re-primary" className="btn-re btn-dropdown">
											<i className="fas fa-plus" />
											<span>New</span>
										</Button>
									</Link>
								</Dropdown>
								<Button variant="outline-secondary" 
										type="button" 
										className="icon-only" 
										onClick={deleteMultiple}
										disabled={selected.length === 0}>
									<i className="fas fa-trash"/>
								</Button>
							</ButtonGroup>
						</ButtonToolbar>
					</Col>
				</Row>
				{loading ? (<Row><Col><Loading active={ loading } /></Col></Row>) :
				(<Row>
					<Col>
						<BootstrapTable bootstrap4 striped hover condensed
							keyField='_id'
							className='selectable'
							data={ scenarios }
							columns={ columns }
							defaultSorted={ defaultSorted }
							selectRow={ selectRow }
							noDataIndication="No scenarios found" />
					</Col>
				</Row>)}
			</Container>
		</React.Fragment>

	)
}

export default LoadScenarioList