import React, { useState, useEffect, useContext } from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import Loading from "../LoadingPage";
import UserContext from "../services/user-context"
import AuthService from "../services/auth";
import { Container } from 'react-bootstrap'
import Titlebar from "./titlebar/Titlebar.jsx";
import Sidebar from "./sidebar/Sidebar.jsx";
import Load from "./load/Load.jsx"
import Flow from "./flow/Flow.jsx"
import Solar from "./solar/Solar.jsx"
import Battery from "./battery/Battery.jsx"
import ErrorListener from "../services/ErrorListener";
import DashboardData from "./DashboardData";
import MainDashboard from "./MainDashboard"
import Profile from "./settings/Profile.jsx";
import CadminSettings from "./settings/OrganisationSettingsDashboard.jsx";
import ScenarioDashboard from "./scenario/Dashboard.jsx";
import SessionStorage from '../services/SessionStorage'

const COLLAPSED_KEY = 'main.dashboard.nav.collapsed'

export default function Dashboard({}) {
    const [isCollapsed, setCollapsed] = useState(SessionStorage.get(COLLAPSED_KEY, false))

    const { user } = useContext(UserContext)
    
    const history = useHistory()

    useEffect(() => {
        if(user===null) return

        const on401Error = err => {
            console.error(`Dashboard error handler `, err);
            AuthService.clearSession();
            history.replace('/login')
          };
        
        ErrorListener.on(401, on401Error);

        return () => {
            ErrorListener.off(401, on401Error);
        }

    }, [history, user])

    if(user===null) return (<Loading/>)

    return (<Container fluid>
        <Sidebar onCollapseChange={(c) => setCollapsed(c)} user={user}/>
        <header className="topbar-nav"><Titlebar user={user}/></header>
        <div className={`viewport ${isCollapsed?'expanded':''}`}>
        <Switch>
          <Route exact path="/olddashboard" render={
            props => <DashboardData user={user} {...props}/>
          }/>
          <Route exact path="/dashboard"
            render={props => ( <Redirect to="/dashboard/home" />)}/>
          <Route path="/dashboard/home" component={MainDashboard} />
          {user.isClusterOwner && <Route
              path="/dashboard/scenario"
              render={props => (
                <ScenarioDashboard {...props} user={user} />
              )}
            />}
          <Route
            path="/dashboard/modulesgraph"
            render={props => {
              return (<DashboardData {...props} user={user} />)
            }}
          />
          <Route path="/dashboard/load" render={() => (<Load/>)}/>
          <Route path="/dashboard/solar" render={() => (<Solar/>)}/>
          <Route path="/dashboard/battery" render={() => (<Battery/>)}/>
          <Route path="/dashboard/flow" render={() => (<Flow/>)}/>
          <Route path="/dashboard/profile" component={Profile} />

          {user.isOrgAdmin && <Route
              path="/dashboard/settings"
              render={props => <CadminSettings {...props} user={user} />} />}

          <Route 
            render={props => (<>
              <Redirect to="/dashboard/home" /></>)
            } />
        </Switch>

        </div>
    </Container>)
}