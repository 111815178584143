import React, {useState, useEffect, useContext, useCallback, useMemo} from 'react'
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, InputGroup, Form } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from "react-router-dom";
import {Notify} from '../../components/AlertListener'
import {ConfirmAlert, EditButton, DeleteButton, noop} from '../../components/UIComponents'
import {Request} from "../../../services/HttpService";
import UserContext from "../../../services/user-context"
import AddBatteryWizard from './AddBatteryWizard'
import SessionStorage from "../../../services/SessionStorage"
import {friendlyTimeFormatter} from "../../../utility";

const request = new Request({ service: 'Battery service'})
const notify=Notify("battery.notifications")

const QUERY_INACTIVE = 'battery.settings.list.includeInactive'
const QUERY_OUT_OF_RANGE = 'battery.settings.list.includeOutOfRange'

const modifedFormatter = friendlyTimeFormatter()

export default function BatteryList({noheader=false}) {
    const [batteries, setBatteries] = useState([])
    const [addBattery, setAddBattery] = useState(false)
    const [expanded, setExpanded] = useState([])
    const [includeInactive, setIncludeInactive] = useState(SessionStorage.get(QUERY_INACTIVE, false))
    const [includeOutOfRange, setIncludeOutOfRange] = useState(SessionStorage.get(QUERY_OUT_OF_RANGE, false))

    const { user } = useContext(UserContext)
    const history = useHistory()

    const __loadBatteries = useCallback(async() => {
        try {
            const query={
                includeInactive
            }
            if(includeOutOfRange) {
                query.from="2000-01-01"
                query.to="9999-01-01"
            }
            const __batteries = await request.get('/api/battery', query)
            setBatteries(__batteries)
        }
        catch(err) {
            notify.error(err.message)
        }
    }, [setBatteries, includeOutOfRange, includeInactive])

    function deleteBattery({ _id, name }) {
        setExpanded([_id])
    }

    useEffect(() => {
        __loadBatteries()
    }, [includeInactive, includeOutOfRange, setBatteries])

    async function __delete({_id, name}) {
        try {
            await request.call(`/api/battery/${_id}`, 'delete')
            notify.info(`Deleted battery "${name}"`)
            __loadBatteries()
        }
        catch(err) {
            console.error(err) 
            notify.error(`Error deleting battery - ${err.message}`)
        }
        setExpanded([])
    }

    const toggleInactive = () => {
        const ii = !includeInactive
        setIncludeInactive(ii)
        SessionStorage.set(QUERY_INACTIVE, ii)
    }

    const toggleOutOfRange = () => {
        const oor = !includeOutOfRange
        setIncludeOutOfRange(oor)
        SessionStorage.set(QUERY_OUT_OF_RANGE, oor)
    }

    const columns=[{
        dataField: '_id',
        hidden: true,
    }, {
        dataField: 'name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'makeName',
        text: "Make",
        sort: true
    }, {
        dataField: 'modelName',
        text: 'Model',
        sort: true
    }, {
        dataField: 'capacity',
        text: 'Capacity',
    }, {
        dataField: 'modified',
        text: "Modified",
        sort: true,
        formatter: (cellcontent, row, rowIndex, __state) => {
            return (<div>
                <div style={{display: 'inline-block'}}>{modifedFormatter.format(cellcontent)}</div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <EditButton outline onClick={() => history.push(`/dashboard/settings/battery/${row._id}/edit`)}/>
                    <DeleteButton style={{marginLeft: "0.5rem"}} outline onClick={() => deleteBattery(row)}/>
                </div>
            </div>)
        }
    }]

    const expandRow = {
        renderer: row => (<div style={{float: "right"}}>
            <ConfirmAlert text={`Delete "${row.name}"?`}
							onYes={() => { __delete(row) }}
							onNo={() => { setExpanded([])}}
							className="pull-right"/>
        </div>),
        expanded: expanded,
        showExpandColumn: false,
        expandByColumnOnly: true
    };

    return user && 
        <Container fluid style={{marginTop: "1rem"}}>
            {!noheader && (
            <Row>
                <Col>
                    <div className="align-items-center">
                        <h4 style={{ display: 'inline-block'}}>Batteries</h4>
                        <Button
                            style={{marginBottom: "0.5rem", marginLeft: "0.7rem"}}
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setAddBattery(true)}>
                                Add Battery
                        </Button>
                    </div>
                </Col>
                <Col>
                    <InputGroup  className="justify-content-end">
                        <Form.Check 
                            inline
                            id="frmBattteriesIncludeInactive"
                            checked={includeInactive}
                            onChange={toggleInactive}
                            type="switch" custom label="Include inactive"/>
                        <Form.Check 
                            inline
                            id="frmBattteriesIncludeOutOfRange"
                            checked={includeOutOfRange}
                            onChange={toggleOutOfRange}
                            type="switch" custom label="Include out of range"/>
                    </InputGroup>
                </Col>
            </Row>
            ) }
           
            {addBattery && <AddBatteryWizard onDone={() => {
                setAddBattery(false) 
                __loadBatteries()
            }}/>}

            <BootstrapTable
                bootstrap4 striped hover condensed 
                version="4"
                keyField='_id' 
                data={batteries}
                columns={columns}
                expandRow={expandRow}
                defaultSorted={[{dataField: 'modified', order: 'desc'}]}
                noDataIndication="No batteries found"/>
        </Container>
    
}