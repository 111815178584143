import React, { Component } from "react";
import HttpService from "../../services/HttpService";
//import Dropdown from "../../utility/Dropdown";
import ClusterRows from "./ClusterRows";
import { Modal, Alert } from "react-bootstrap";
import AddClusterForm from "./AddClusterForm";
//import ToastContainer from "../../utility/ToastContainer";
import UserServices from "../../services/UserServices";
import SetClusterownerForm from "./SetClusterOwnerForm"

export default class ClusterTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clusters: [],
      users:[],
      cluster_id: "",
      name: "",
      active: "",
      type: "",
      selectedStatus: null,
      getInactive: "Include",
      clusterOwners:[],
      show:false,
      variant:'', msg:'',
    };
  }

  componentDidMount() {
    this.getClusterList();
  }

  getClusterList = () => {
    HttpService.getClusterList()
      .then(response => {
        let clusters = response.data;
        console.log('clusters', clusters)
        this.getUserList();
        this.setState({
          clusters: clusters,
          cluster_id: clusters._id,
          showModaladdCluster: false,
          getInactive: "Include"
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ clusters: [] });
      });
  };

  getUserList = id => {
    UserServices.getUserList()
      .then(response => {
        let users = response.data;
        console.log('users', users)
        const found_users = users.filter(item => item.roles.includes("cluster_owner") );
        console.log('found_users', found_users)
        const left_users = users.filter(item => !item.roles.includes("cluster_owner") );
        console.log('left_users', left_users)

        this.setState({
          users: left_users,
          clusterOwners:found_users
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({ users: [] });
      });
  };

  getInactive = () => {
    const getInactive = this.state.getInactive;
    if (getInactive === "Include") {
      HttpService.getAllClusters()
        .then(response => {
          let clusters = response.data;
          //console.log("clusters", clusters);
          this.setState({
            clusters: clusters,
            cluster_id: clusters._id,
            getInactive: "Hide"
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({ clusters: [] });
        });
    } else {
      this.getClusterList();
    }
  };

  addCluster = () => {
    //console.log('cluster add')
    const selectedStatus = { value: "open", label: "open" };
    this.setState({
      showModaladdCluster: true,
      type: "add",
      selectedStatus: selectedStatus
    });
  };

  closeAddCluster = (variant, msg) => {
    this.setState({ showModaladdCluster: false, variant:variant, msg:msg });
    this.onShowAlert();
    this.getClusterList();
  };

  editCluster = cluster => {
    //console.log("editsite", cluster);
    const status = cluster.status;
    //console.log("editcluster status", status);
    const selectedStatus = { value: status, label: status };
    this.setState({
      showModalEdit: true,
      cluster_id: cluster._id,
      name: cluster.name,
      active: cluster.active,
      selectedStatus: selectedStatus,
      type: "edit"
    });
  };

  closeEdit = (variant, msg) => {
    this.setState({ showModalEdit: false, variant:variant, msg:msg });
    this.onShowAlert();
    this.getClusterList();
  };


  setOwner =(cluster)=>{
    console.log('cluster', cluster)
    const cluster_id = cluster._id
    this.setState({
      selectUser: true,
      cluster_id: cluster_id,
    });
  }

  closeSelectUser = (variant, msg) => {
    this.setState({ selectUser: false,variant:variant, msg:msg });
    this.onShowAlert();
    this.getClusterList();
  };

  setShow=()=>{
    this.setState({ show:false });
  }

  onShowAlert = ()=>{
    console.log('onShowAlert')
    this.setState({show:true},()=>{
      window.setTimeout(()=>{
        this.setState({show:false})
      },3000)
    });
  }

  closeModal = () => {
      this.setState({ showModalEdit: false, showModaladdCluster: false,selectUser: false  });
  }



  render() {
    const {
      clusters,
      cluster_id,
      name,
      active,
      type,
      getInactive,
      selectedStatus,
      users,
      clusterOwners, show, msg, variant
    } = this.state;
    console.log('clusters', clusters)

    const user_id = users.map(user => user._id);
    const userId = user_id[0];
    console.log('userid', userId)


    //c
    console.log('clusterOwners', clusterOwners)
    return (
      <div className="top-admin">
        <div>
        { show===true
          ?(  <Alert variant={variant} onClose={() => this.setShow()} dismissible>
              <Alert.Heading>{msg}</Alert.Heading>
            </Alert>)
            :<div></div>
       }
        </div>
        <div className="headerTable">
          <h2>Clusters</h2>
          <div className="col-lg-12 text-right">
            <button className="inactive btn-round" onClick={this.getInactive}>
              {getInactive} Inactive
            </button>
            <button className="add btn-round" onClick={this.addCluster}>
              Add Cluster
            </button>
          </div>
        </div>
        <table className="table-market" id="user-table">
          <thead>
            <tr>
              <th> Name </th>
              <th> Join Status </th>
              <th> Active </th>
              <th> Cluster owner</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {clusters.map(cluster => (
              <ClusterRows
                clusterOwners={clusterOwners}
                key={cluster._id}
                cluster={cluster}
                editCluster={this.editCluster}
                setOwner={this.setOwner}
              />
            ))}
          </tbody>
        </table>

        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showModaladdCluster}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Add Cluster</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddClusterForm
                  getClusterList={this.getClusterList}
                  closeAddCluster={this.closeAddCluster}
                  active={true}
                  selectedStatus={selectedStatus}
                  type={type}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showModalEdit}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Edit Cluster</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
                <AddClusterForm
                  getClusterList={this.getClusterList}
                  closeAddCluster={this.closeEdit}
                  cluster_id={cluster_id}
                  name={name}
                  active={active}
                  type={type}
                  selectedStatus={selectedStatus}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.selectUser}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title className="textCenter">Select User as Cluster Owner</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <div>
              <SetClusterownerForm
                users={users}
                user_id={userId}
                cluster_id={cluster_id}
                closeForm={this.closeSelectUser}/>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
