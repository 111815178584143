import React, { Component } from "react";
//import { Button } from "react-bootstrap";
import { notify } from "../../utility/ToastContainer";
import UserServices from "../../services/UserServices";
import Select from "react-select";

const options = [
  { value: "admin", label: "admin" },
  { value: "cadmin", label: "cadmin" },
  { value: "user", label: "user" }
];

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};

export default class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spreadSheetList: [],
      nameActive: false,
      lastnameActive: false,
      selectActive: false,
      name: "",
      lastname: "",
      email: "",
      password: "",
      active: false,
      type: "add",
      selectedOption: null,
      checked: true,
      formValid: false,
      errorCount: 0,
      errors: {
        name: "",
        lastname: "",
        email: "",
        password: "",
        select: ""
      }
    };
  }

  componentDidMount() {
    const { firstname, lastname, email, selectedRoles, type } = this.props;
    this.setState({
      name: firstname,
      email: email,
      lastname: lastname,
      selectedOption: selectedRoles,
      type: type
    });
  }

  onChangeActive = () => {
    this.setState({
      checked: !this.state.checked
    });
  };

  handleChange = selectedOption => {
    console.log(`Option selected:`, selectedOption);
    let errors = this.state.errors;
    switch (selectedOption) {
      case "select":
        errors.select = !selectedOption ? "Select role!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, selectedOption });
  };

  closeForm = event => {
    this.props.closeForm();
  };

  changeHandler = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name = value.length < 4 ? "Name must be 4 characters long!" : "";
        break;
      case "lastname":
        errors.lastname =
          value.length < 4 ? "Surname must be 4 characters long!" : "";
        break;
      case "email":
        errors.email = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
          ? "Email is not valid!"
          : "";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      default:
        break;
    }

    this.setState({
      errors,
      [name]: value
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
    this.props.getUserList();
  };

  addUser = (event, errors, values) => {
    event.preventDefault();
    const type = this.props.type;
    const orgId = this.props.orgId;
    const roles = this.props.roles;
    const userId = this.props.userId;
    const user = {
      firstname: this.state.name,
      lastname: this.state.lastname,
      email: this.state.email,
      password: this.state.password,
      roles: roles,
      active: JSON.parse(this.state.checked),
      orgId: orgId
    };
    if (type === "edit") {
      //console.log('edit', user)
      return UserServices.editUser(userId, user)
        .then(response => {
          if (response.data) {
            this.props.closeForm("success",
              "User is updated successfully."
            );
            //this.closeForm();
          } else {
            this.props.closeForm("danger", "User email already exists");
          }
        })
        .catch((err, code, message, statusCode) => {
          this.props.closeForm("danger", err);
          console.log("error", err);
        });
    } else {
      alert("error");
    }
  };

  render() {
    const {
      selectedOption,
      errors,
      formValid,
      errorCount,
      name,
      lastname,
      checked
    } = this.state;
    const type = this.props.type;
    const profile = this.props.profile;
    console.log("profile", profile);

    const login_user = sessionStorage.getItem("_user");
    const l_user = JSON.parse(login_user);
    console.log("login_user", l_user);

    var roleAdmin;
    if (l_user.roles.includes("admin")) {
      roleAdmin = true;
    } else {
      roleAdmin = false;
    }

    var nameActive, lastnameActive, selectActive;
    if (type === "edit") {
      nameActive = lastnameActive = selectActive = true;
    } else {
      nameActive = this.state.nameActive;
      lastnameActive = this.state.lastnameActive;
      selectActive = this.state.selectActive;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addUser}>
            <div className="form-group">
              <div className="labelField">
                <label className={nameActive ? "on" : ""}>First Name</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="name"
                  id="firstname"
                  onChange={this.changeHandler}
                  placeholder="Enter User First Name"
                  value={name || ""}
                />
                {errors.name.length > 0 && (
                  <span className="error">{errors.name}</span>
                )}
              </div>
              <div className="labelField">
                <label className={lastnameActive ? "on" : ""}>Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="lastname"
                  id="lastname"
                  onChange={this.changeHandler}
                  placeholder="Enter User Last Name"
                  value={lastname || ""}
                />
                {errors.lastname.length > 0 && (
                  <span className="error">{errors.lastname}</span>
                )}
              </div>
              {roleAdmin && (
                <div>
                  <div className="labelField">
                    <label className={selectActive ? "on" : ""}>
                      Select Role(s)
                    </label>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                      name="select"
                      inputId="select"
                      isMulti
                    />
                    {errors.select.length > 0 && (
                      <span className="error">{errors.select}</span>
                    )}
                  </div>
                  <br />
                  <div>
                    <label htmlFor="active">
                      Active &nbsp;
                      <input
                        className="form-group text-right"
                        type="checkbox"
                        name="active"
                        id="active"
                        onChange={this.onChangeActive}
                        value={checked || false}
                        defaultChecked={this.state.checked}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="form-group ">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false || errorCount !== 0 || !selectedOption
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
