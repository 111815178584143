import React, { useState, useEffect } from 'react'
import {Alert, Container, Row, Col } from 'react-bootstrap'
import {SaveButton, CancelButton, ConfirmAlert } from '../../components/UIComponents.jsx'
import {Request} from "../../../services/HttpService";
import { Notify } from '../../components/AlertListener.jsx'
const notify=Notify("load.notifications")

const BASELOAD_ATTRS=["name", "scaler", "baseLoad_type", "baseLoad_id"];

function validateNumber(n, v, min=NaN, max=NaN) {
	if(v.length===0) {
		throw new Error(`Value required for "${n}"`)
	}
	else {
		// Make sure value is nuneric
		const __v = parseFloat(v)
		if(isNaN(v) || isNaN(__v)) {
			throw new Error(`"${n}" Must be numeric value`)
		}
		if(!(isNaN(min) || isNaN(max))) {
			// Check range
			if(__v<min || __v>max) {
				throw new Error(`"${n}" must be in range ${min}/${max}`)
			}
		}
		else if(!isNaN(min)) {
			// Check min
			if(__v<min) {
				throw new Error(`"${n}" must be >= ${min}`)
			}
		}
		else if(!isNaN(max)) {
			// Check range
			if(__v>max) {
				throw new Error(`"${n}" must be <= ${max}`)
			}
		}
		return __v
	}
}

const noop=() => {}
const DEFAULT_BASELOAD = {name: "", scaler: 100, baseLoad_id: "", baseLoad_type: "ORGANISATION"}
const EMPTY = {name: "", scaler: "", baseLoad_id: ""}

export default function EditBaseLoad({
		baseLoadProfile=null,
		organisations=[],
		onDone=()=>{},
		visible=false,
		scenario_id=null,
		onSave=noop,
		onCancel=noop
	}) {
	const request = new Request({ service: 'Scenario service'})

	const [show, setShow] = useState(visible)
	const [isEdit, setEdit] = useState(false)
	const [confirmRequired, setConfirm] = useState(false)
	const [initial, setInitial] = useState(baseLoadProfile)
	const [__baseLoadProfile, setBaseLoad] = useState(baseLoadProfile)
	const [errors, setErrors] = useState(BASELOAD_ATTRS.reduce((a, c) => { a[c]=false; return a}, {}))

	useEffect(() => {
		// console.log('EditBaseLoad :: mount - baseLoadProfile=', baseLoadProfile)
		if(baseLoadProfile===null) {
			// Add
			setBaseLoad(bl => ({...DEFAULT_BASELOAD}))
			setInitial(i => ({...DEFAULT_BASELOAD}))
			setEdit(e => false)
		} else {
			// Edit
			setBaseLoad(bl => ({...baseLoadProfile}))
			setInitial(i => ({...baseLoadProfile}))
			setEdit(e => true)
		}
		setShow(s => visible)
		return () => {
			console.log('EditBaseLoad :: unmount')
			setBaseLoad(bl => ({...EMPTY}))
			setBaseLoad(bl => ({...EMPTY}))
		}
	}, [baseLoadProfile, visible])

	function hasChanged() {
		if(initial===null) return false
		const tmp =  BASELOAD_ATTRS.reduce((en, k) => en || initial[k]!==__baseLoadProfile[k], false) 
		return tmp
	}

	function isValid(name, value) {
		errors[name] = false ;
		let __v = value
		try {
			switch(name) {
				case 'name':
					if(value.length<2) {
						throw new Error('"Name" must be at least 2 characters')
					}
					break;
				case "scaler":
					__v=validateNumber(name, value, 0, 1000)
					break;
				case "baseLoad_id":
					let __o = organisations.filter(_o => _o._id === value)
					if (__o.length !== 1) {
						throw new Error('Please select a valid Organisation')
					}
					break;
				default:
					break;
			}
		}
		catch(err) {
			errors[name]=err.message ;
		}
		__baseLoadProfile[name]=__v ;
		setBaseLoad({...__baseLoadProfile})
		setErrors({...errors})
		return errors[name] === false ;
	}

	function onChange(e) {
		const { value="", name } = e.target ;
		isValid(name, value)
	}

	async function save() {
		if(BASELOAD_ATTRS.reduce((v, a) => v&=isValid(a, __baseLoadProfile[a]), true)) {
			try {
				let { _id=null, ...__bl }=__baseLoadProfile;
				if(scenario_id !== null) {
					__bl = await request.call(
						`/api/scenario/load/${scenario_id}/baseLoadProfiles${_id!==null?`/${_id}`:''}`, 
						'put', 
						{ data: __bl }
					)
					__bl.org_name = organisations.filter(_o => _o._id === __bl.baseLoad_id)[0].name
				}

				setShow(false)
				setBaseLoad(null)
				onSave(__bl)
			}
			catch(err) {
				notify.error(err.message)
			}
		}
	}

	function cancel(e) {
		if(e===true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			setConfirm(false)
			setShow(false)
			setBaseLoad(null)
			onCancel()
		}
		setConfirm(true)
	}

	function canSave() { return true }

	return __baseLoadProfile!==null?(
		<Alert show={show}>
			<Alert.Heading>{isEdit?'Edit':'New'} Base Load Profile</Alert.Heading>
			<Container className="form-group">
				<Row>
					<Col>
		                <label className="on">Name</label>
		                <input
		                  className="form-control"
		                  type="text"
		                  required
		                  name="name"
		                  id="bl_name"
		                  onChange={onChange}
		                  placeholder="Enter name"
		                  value={__baseLoadProfile.name}
		                />
		                {errors.name && (
		                  <span className="error">{errors.name}</span>
		                )}

					</Col>
				</Row>
				<Row>
					<Col>
		                <label className="on">Organisation</label>
						<select 
							className="form-control"
							type="string"
							required
							name="baseLoad_id"
							id="bl_baseLoad_id"
							onChange={onChange}
							placeholder="Profile"
							value={__baseLoadProfile.baseLoad_id}
						>
							<option key="baseLoad_id_none" value="">None</option>
							{organisations.map(({ _id, name }) => (
								<option key={`baseLoad_id_${_id}`} value={_id}>{name}</option>)
							)}
						</select>
		                {errors.baseLoad_id && (
		                  <span className="error">{errors.baseLoad_id}</span>
		                )}

					</Col>
					<Col>
		                <label className="on">Scaler (%)</label>
		                <input
		                  className="form-control"
		                  type="number"
		                  required
		                  name="scaler"
		                  id="bl_scaler"
		                  onChange={onChange}
		                  placeholder="Scaler"
		                  value={__baseLoadProfile.scaler}
		                />
		                {errors.scaler && (
		                  <span className="error">{errors.scaler}</span>
		                )}

					</Col>
				</Row>
				<Row>
					<Col>
						{confirmRequired?(<ConfirmAlert
							rightJustify
							text="Base Load Profile details have changed, really cancel?"
							onYes={() => cancel(true)}
							onNo={() => setConfirm(false)}
						/>):
						(<div className="form-group btn-right save-cancel">
			            	<CancelButton label="Cancel edit" onClick={cancel}/>
			            	<SaveButton label={`${isEdit?'Update':'Create'} base load profile`} onClick={save} disabled={!canSave()}/>
			            </div>)}
			        </Col>
			    </Row>
			</Container>
		</Alert>
	):null
}

