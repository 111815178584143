import React, {useState, useEffect, useCallback, useContext} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {AlertContainer, Notify} from '../../components/AlertListener'
import UserContext from "../../../services/user-context"
import {Request} from "../../../services/HttpService";
import { isID } from "../../../utility"

import ClusterMemberList from './ClusterMemberList'
import ClusterMeters from './ClusterMeters'

const request = new Request({ service: 'Cluster service'})
const notify=Notify("cluster.notifications")

export default function ClusterDashboardSettings({}) {
    const [managedCluster, setManagedCluster] = useState(null)

    const {user}  = useContext(UserContext)

    useEffect(() => {
        if(user===null) return

        async function __loadCluster(_id) {
            // Load a cluster by ID
            if(isID(_id)) {
                try {
                    setManagedCluster(await request.get(`/api/cluster/${_id}`))
                }
                catch(err) {
                    notify.error(err.message)
                }
    
            }
            return null
        }

        __loadCluster(user.clusterId)

        return () => {
            setManagedCluster(null)
        }
    }, [user])

    return user?(<>
        <AlertContainer tag="cluster.notifications" page="cluster"/>
        <Container fluid>
            <Row>
                <Col lg="12">
                    <h3>Cluster Settings {managedCluster?`"${managedCluster.name}"`:''}</h3>
                </Col>
            </Row>

            <ClusterMemberList cluster={managedCluster}/>
            <ClusterMeters cluster={managedCluster}/>
            {/* {organisation?(<>
            <Row>
                <Col>
                    <OrganisationMeters organisation={organisation}/>
                </Col>
            </Row>
            </>):null} */}
        </Container>
    </>):null

}