import React from "react";

export default function ClusterRows({
  cluster,
  clusterOwners,
  editCluster,
  setOwner
}) {
  const cluster_id = cluster._id;
  //console.log('cluster_id',cluster_id)
  var clusterOwner = [];
  //var firstname=''
  //var lastname=''
  var email = "";
  if (clusterOwners.length) {
    clusterOwner = clusterOwners.filter(item => item.cluster_id === cluster_id);
  }
  //console.log('clusterOwner', clusterOwner)

  if (clusterOwner.length) {
    //firstname = clusterOwner.map(cluster => cluster.firstname).join(', ')
    //lastname = clusterOwner.map(cluster => cluster.lastname).join(', ')
    email = clusterOwner.map(cluster => cluster.email).join(", ");
  }
  return (
    <tr>
      <td>{cluster.name}</td>
      <td>{cluster.status}</td>
      <td>{cluster.active ? "Yes" : "No"}</td>
      <td>{email}</td>
      <td>
        <button className="editDelete" onClick={() => editCluster(cluster)}>
          Edit
        </button>
        <button className="editDelete" onClick={() => setOwner(cluster)}>
          Set
        </button>
      </td>
    </tr>
  );
}
