import React, { useState, useEffect, useRef } from "react";
import ForceGraph2D from "react-force-graph-2d";
import imgMeter from "../../assets/svg/meter_dl.svg";
import imgSite from "../../assets/svg/site.svg";
import imgOrg from "../../assets/svg/org.svg";
import imgMeter1 from "../../assets/svg/meter_dl_Blue.svg";
import imgMeter2 from "../../assets/svg/meter_dl_Teal.svg";
import imgSolar from "../../assets/svg/sun2.svg";
import imgBattery from "../../assets/svg/battery2.svg";


export default function ForceDirectedOrg({orgData}) {
const fgRef = useRef();
const [selectedNode, setSelectedNode] = useState(null);
const [nodePosition, setNodePosition] = useState(null);
const [stopEngine, setStopEngine] = useState(false);
const [graphData, setGraphData] = useState(orgData);
const [zoomEdge, setZoomEdge] = useState(240)
const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

// finding node position
const setPosition = (e) => {
  let position = {};
  position.x = e?.pageX;
  position.y = e?.pageY;
  setNodePosition(position);
};


useEffect(() => {
  setGraphData(orgData);
  fgRef.current.d3Force("charge").distanceMax(240);
  if(vw>vh){
    setZoomEdge(vw*0.08)
  }else{
    setZoomEdge(vh*0.08)
  }
}, [graphData, orgData]);


return (
  <div>
    {selectedNode &&
      (<div 
        className="nodeCard"
        style={{
          position: "absolute",
          margin: "2px 0px 2px 0px",
          left: nodePosition?.x,
          top: nodePosition?.y,
          border:
            selectedNode.group === "organisation"
              ? "2px solid #1fb6c7"
              : selectedNode.group === "site"
              ? "2px solid #50ba47"
              : selectedNode.group === "meter"
              ? "2px solid #ba4a3d"
              : selectedNode.group === "cluster"
              ? "2px solid #40af41"
              : null
              }} 
              >

              <div>
                <div style={{ display: "flex" }}>
                  <span style={{ maxWidth: "200px"}}>
                    <strong>{selectedNode.name.toUpperCase()}</strong>
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => {}}>
                    <i className="fal fa-folder-plus" />
                  </span>
                </div>
              </div>

          <div>
            <div style={{ display: "flex" }}>
              <span style={{ color: "#757575" }}>
                {selectedNode.group.toUpperCase()}
              </span>{" "}
              <span style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => {}}>
                <i className="fal fa-folder-plus" />
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* {selectedNode.group === "organisation" ? <img className="flowIcons" src={imgOrg}/> : null}
              {selectedNode.group === "site" ? <img className="flowIcons" src={imgSite}/> : null}
              {selectedNode.group === "meter" ? <img className="flowIcons" src={imgMeter}/> : null} */}
            </div>
            <div className="hoverItem"
              style={{
                fontWeight: "bold",
                display: "flex",
                cursor: "pointer"
              }}
            >                
              <span className="entityInNode"
                onClick={() => {
                  if (selectedNode) selectedNode.openUserPanel();
                }}
              >
                {selectedNode.id}
              </span>                 
            </div>
          </div>
      </div>

    )}


    <div onMouseMove={setPosition}>
    <ForceGraph2D
      ref={fgRef}
      graphData={graphData}
      width={vw*0.9}
      height={vh*0.9}
      backgroundColor={"#f8f9f3"}
      // height="700"
      // width="1000"
      nodeId="id"
      autoPauseRedraw={false}
      //scale={1}
      // onNodeHover={(node) => {
      //   if (node) {
      //     setSelectedNode(node);
      //   } else {
      //     setSelectedNode(null);
      //   }
      // }}
      

      nodeCanvasObject={(node, ctx) => {
        //console.log(`ctx width ${ctx.canvas.width}`)
        
      const size = 15;
      const img = new Image();
      ctx.beginPath();
      // console.log(`zoomEdge ${zoomEdge}`)
      // if(zoomEdge===240){
      //   if(ctx.canvas.width>ctx.canvas.height){
      //     setZoomEdge(ctx.canvas.width*0.10)
      //   }else{
      //     setZoomEdge(ctx.canvas.height*0.10)
      //   }
      // }
        const label = node.name;
        const textWidth = ctx.measureText(label).width;
        const bckgDimensions = [textWidth, 2].map((n) => n + 10 * 0.2); // for padding
        ctx.fillStyle = "#e3ded3"; //background color for tag
        ctx.fillRect(
          node.x - bckgDimensions[0] / 2,
          node.y + (size - (bckgDimensions[1]*2)),
          ...bckgDimensions
        );

        ctx.font = `3px mukta`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#3d3d3d"; //node.color;
        ctx.fillText(label, node.x + 0.6, node.y + (size-(bckgDimensions[1]+2)));


        if(node.reading){
          const label2 = node.reading
          const textWidth2 = ctx.measureText(label2).width;
          const bckgDimensions2 = [textWidth2, 2].map((n) => n + 10 * 0.2); 
          ctx.fillStyle = "#e3ded3"; //background color for tag
          ctx.fillRect(
            node.x - bckgDimensions2[0] / 2,
            node.y - (size - (bckgDimensions2[1])),
            ...bckgDimensions2
          );

          ctx.font = `3px mukta`;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#3d3d3d"; //node.color;
          ctx.fillText(label2, node.x + 0.6, node.y - (size-(bckgDimensions2[1]+2)));
        }
      
      // for highlighting
      // const outline = new Image();
      // outline.src = frame;
      // ctx.drawImage(
      //   outline,
      //   node.x - size / 2 - 1.9,
      //   node.y - size / 2 - 2.8,
      //   size + 6,
      //   size + 7
      // ); //frame image
      
      img.src =
      node.icon_type === "imgOrg" 
      ? imgOrg 
      : node.icon_type === "imgSite" 
      ? imgSite
      : node.icon_type === "imgMeter1" 
      ? imgMeter1
      : node.icon_type === "imgMeter2" 
      ? imgMeter2
      : node.icon_type === "imgSolar" 
      ? imgSolar
      : node.icon_type === "imgBattery" 
      ? imgBattery
      : null

      node.img = img;
      ctx.drawImage(
        img,
        node.x - size / 2,
        node.y - size / 2,
        //node.y - size / 2,
        size - 1,
        size - 1
      );
      return ctx;
    }}
    // onRenderFramePost={() => {
    //   fgRef.current.zoomToFit(20, 240);
    // }}
    minZoom={0.1}
    maxZoom={90}
    onEngineTick={() => {
      if (!stopEngine) {
        fgRef.current.zoomToFit(20, zoomEdge);
      }
    }}
    onEngineStop={() => {
      if (!stopEngine) {
        fgRef.current.zoomToFit(20, zoomEdge);
        setStopEngine(true);
      }
    }}

    linkDirectionalParticles="value"
    linkDirectionalParticleSpeed={d => d.value * 0.001}
    linkCurvature="curvature"
    enablePointerInteraction={true}
    linkDirectionalParticleWidth={5}
    // onRenderFramePost={() => {
    //   scale(0.9);
    // }}
    />
    </div>



    {/* s */}



  </div>
  
  );
}




