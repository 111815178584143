import React, { Component } from "react";
//import ComparisonTable from "./ComparisonTable";
import { Icon, Popup } from "semantic-ui-react";

export default class TotalComparisontable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const {
      mean_sq_error,
      maseF,
      spearmanRHO,
    } = this.props;


    return (
      <div>

        <div>
          <h5> ACCURACY :
              <Popup
                trigger={<Icon circular className="info" size="small" />}
                content="Mean squared error (MSE) or mean squared deviation (MSD) of an estimator (of a procedure for estimating an unobserved quantity) measures the average of the squares of the errors—that is,the average squared difference between the estimated values and the actual value.  Lower better, best <1."
              />
              Error score(MSE) of <b> {mean_sq_error !== null ? mean_sq_error : "--"} </b>

               &nbsp;&nbsp;
              <Popup
                trigger={<Icon circular className="info" size="small" />}
                content="The mean absolute scaled error (MASE) calculates the mean absolute scaled error (MASE) between the forecast and the eventual outcomes. It is the mean absolute error of the forecast values, divided by the mean absolute error of the in-sample one-step naive forecast. Lower better, best <1."
              />
              MASE of <b> {maseF !== null ? maseF : "--"} </b>

               &nbsp;&nbsp;
              <Popup
                trigger={<Icon circular className="info" size="small" />}
                content="R-squared (R2) is a statistical measure that represents the proportion of the variance for a dependent variable that is explained by an independent variable or variables in a regression model. Best closer to 100% ."
              />
              R2 of <b> {spearmanRHO !== null ? spearmanRHO : "--"}</b>
              </h5>
        </div>
      </div>
    );
  }
}
