import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import SiteSelector from "../site/SiteSelector"
import Fetch from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'

export default function SiteLoad({startDate, endDate}) {
    const [site, setSite] = useState(null)
    const [loadData, setLoadData] = useState(null)

    const { Time } = useContext(UserContext)

    const __loadData = useCallback(async (_id) => {
        // console.log(`[SiteLoad] Fetch load data for ${site.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        Fetch(`/api/meter/site/${_id}/readings`, { 
            ...__query,
            meter_type: 'load', 
            summary: true}, setLoadData, ["timestamp", "reading"])
    }, [startDate, endDate, site, Time])

    useEffect(() => {
        if(!(site && site._id)) return 
        __loadData(site._id)

        return () => {
            setLoadData(null)
        }
    }, [startDate, endDate, site])

    return (<Container fluid>
        <SiteSelector onChange={setSite} titlePre="Load"/>
        <div>
            {loadData && <EnergyPlot load={loadData} startTime={startDate}/>}
        </div>
    </Container>)
}