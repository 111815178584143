import React from "react";
//import moment from "moment";

export default function PanelRow({ panel, editPanel, deletePanel }) {
  return (
    <tr>
      <td>{panel.name} </td>
      <td>{panel.capacity}</td>
      <td>{panel.azimuth}</td>
      <td>{panel.tilt}</td>
      <td>{panel.losses} </td>
      <td>{panel.efficiency}</td>

      {panel.module_type === 0 ? (<td> Standard</td>)
              : panel.module_type === 1 ? (<td>Premium</td>)
              : <td>Thin film</td>
          }



          {panel.array_type === 0 ? (<td> Fixed-open rack</td>)
              : panel.array_type === 1 ? (<td> Fixed-roof mounted</td>)
                  : panel.array_type === 2 ?(<td> 1-axis</td>)
                  : panel.array_type === 3 ?(<td>1-axis backtracking</td>)
                  : <td>2-axis</td>
          }



      <td>
        <button
          className="editDelete"
          onClick={() => editPanel(panel)}
        >
          Edit
        </button>
      </td>
      <td>
        <button
          className="editDelete"
          onClick={() => deletePanel(panel)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
}
