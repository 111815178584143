import React, { Component } from "react";
import SolarServices from "../../services/SolarServices";
//import Select from "react-select";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
const countErrors = (errors) => {
  let count = 0;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (count = count+1)
  );
  return count;
}

// const timeZoneOpt = [
//   { value: "Europe/Dublin", label: "Dublin" },
//   { value: "Europe/London", label: "London" },
//   { value: "Europe/Berlin", label: "Berlin" },
//   { value: "Europe/Madrid", label: "Madrid" },
//   { value: "Europe/Paris", label: "Paris" }
// ];

export default class AddInstallationForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      timezoneSelected: this.props.timezoneSelected || null,
      timezoneActive: false,
      nameActive:false,
      name: this.props.name || '',
      latitude:this.props.latitude || '',
      longitude: this.props.longitude ||'',
      timezone:this.props.timezone || "",
      active:this.props.active || true,
      type:'add',
      inst_id:'',
      formValid: false,
      errorCount: 0,
      errors: {
        name: '',
        latitude:'',
        longitude:''
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
     const { name, installation_id,site_id, org_id, latitude, longitude, active, inst_id} = this.props;
     this.setState({ name:name, site_id:site_id, installation_id:installation_id, org_id:org_id, latitude:latitude,
       longitude:longitude, active:active, inst_id:inst_id});
       this.innerRef.current.focus();
    }, 1);
  }

  handleChangeTimeZone = timezoneSelected => {
    if (timezoneSelected) {
      this.setState({ timezoneActive: true });
    } else {
      this.setState({ timezoneActive: false });
    }

    let errors = this.state.errors;
    switch (timezoneSelected) {
      case "select":
        errors.select = !timezoneSelected ? "Select status!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, timezoneSelected });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };


  activateField=(e)=> {
    console.log();
    this.setState({
     [`${e.target.name}Active`]: true
    })
  }

  disableField=(e)=> {
    this.setState({
      [`${e.target.name}Active`]: false
   })
 }

  onChangeActive = () => {
    this.setState({
      active: !this.state.active,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  }

  changeHandler = e => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;

    if (e.target.value === "") {
      this.disableField(e);
   } else {
      this.activateField(e);
   }
    //console.log('name', name)
    switch (name) {
       case 'name':
        errors.name =
          value.length < 3
            ? 'Name must be 3 characters long!'
            : '';
        break;
        case 'longitude':
          errors.longitude =
            !/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(value)
              ? 'Invalid Latitude'
              : '';
          break;
          case 'latitude':
            errors.latitude =
              !/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(value)
                ? 'Invalid Latitude'
                : '';
            break;
      default:
      break;
    }
    this.setState({
      errors,
      [name]: value,
    });
    this.setState({formValid: validateForm(this.state.errors)});
    this.setState({errorCount: countErrors(this.state.errors)});
  };


  addInstallation = (event, errors, values) => {
        event.preventDefault();
        //const timezoneType = this.state.timezoneSelected;
        const {site_id, type, org_id, inst_id} = this.props;
        const {name, latitude,longitude, active} = this.state;
        const installation = {
          name: name,
    			longitude: Number(longitude),
    			latitude: Number(latitude),
    			site_id: site_id,
    			active: active,
        }
        console.log('installation',inst_id, installation)

        if (type === 'Edit'){

          return SolarServices.editInstallation(installation, inst_id)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                console.log('edit')
                this.setState({ name:'', type:'add', installation_id:'', active:true });
                   this.props.closeAdd("success", "Installation is updated successfully.");
              } else {
                 this.props.closeAdd("danger", "Installation name already exists");
              };
            })
            .catch(err => {
              this.props.closeAdd("danger", err);
            });
        }else if (type==='Add'){
          return SolarServices.addInstallation( installation, org_id)
            .then(response => {
              if (response.data) {
                 this.setState({ name:'', type:'add', installation_id:'', active:true });
                this.props.closeAdd("success", "Installation is created successfully.");
              } else {
                 this.props.closeAdd("danger", "Installation name already exists");
              };
            })
            .catch(err => {
              this.props.closeAdd("danger", err);
            });

        }

    }

  closeForm = event => {
    if (this.props.closeAddinstallation) {
        this.props.closeAddinstallation();
      }
    };


  render() {
    const {errors, formValid, errorCount, name, latitude, longitude} = this.state;
    const type = this.props.type;
    console.log('type', type)

    var nameActive
    var longitudeActive
    var latitudeActive
    if (type === 'Edit'){
      nameActive = true;
      longitudeActive = true;
      latitudeActive = true;
    } else {
      nameActive = this.state.nameActive;
      longitudeActive = this.state.longitudeActive;
      latitudeActive = this.state.latitudeActive;
    }


    if (type === 'Edit'){
      var active = this.props.active
    } else {
          active = this.state.active;
    }

    return (
      <div>
        <div>
          <form onSubmit={this.addInstallation}>
            <div className="form-group">
            <div className="labelField">
              <label className={nameActive ? "on" : ""}>
              Name
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="name"
                id="name"
                onChange={this.changeHandler}
                placeholder="Enter installation name"
                value={name || ""}
                ref={this.innerRef}
              />
              {errors.name.length > 0 &&
                <span className='error'>{errors.name}</span>}
              </div>
              <div className="labelField">
                <label  className={longitudeActive ? "on" : ""}>
                Longitude
                </label>
                <input
                  className="form-control"
                  type="number"
                  required
                  name="longitude"
                  id="longitude"
                  onChange={this.changeHandler}
                  placeholder="Enter longitude "
                  value={longitude || ""}
                />
                {errors.longitude.length > 0 &&
                  <span className='error'>{errors.longitude}</span>}
                </div>
              <div className="labelField">
                <label  className={latitudeActive ? "on" : ""}>
                Latitude
                </label>
                <input
                  className="form-control"
                  type="number"
                  required
                  name="latitude"
                  id="latitude"
                  onChange={this.changeHandler}
                  placeholder="Enter latitude"
                  value={latitude || ""}
                />
                {errors.latitude.length > 0 &&
                  <span className='error'>{errors.latitude}</span>}
                </div>

                  {/*<div className="labelField">
                    <label className={timezoneActive ? "on" : ""}>Time Zone</label>
                    <Select
                      value={timezoneSelected}
                      onChange={this.handleChangeTimeZone}
                      options={timeZoneOpt}
                      placeholder={"Select Time Zone"}
                      name="timezone"
                      inputId="timezone"
                    />
                  </div>*/}
              <br/>
              <div><label htmlFor="active">
              Active  &nbsp;
              <input
                className="form-group text-right"
                type="checkbox"
                name="active"
                id="active"
                onChange={this.onChangeActive}
                placeholder="Enter Dataset Name"
                value={active}
                defaultChecked={active}
              /></label>
              </div>
            </div>
            <div className="form-group">
              <button
                disabled = {formValid===false || errorCount !== 0 }
                type="submit"
                className="submit btn-round"
              >
                {type === "Edit" ? "Update" : "Submit"}
              </button>
            </div>
            {errorCount !== 0
              ? <p className="form-status">Form is {formValid===false || errorCount !== 0 ? 'invalid ❌' :'valid ✅' }</p>
              : 'Form not submitted'}
          </form>
        </div>
      </div>

    );
  }
}
