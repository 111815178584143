import React, { Component } from "react";
// import TariffsRow from "./TariffsRow";
import TariffBillableRow from "./TariffBillableRow";
//import ToastContainer from "../../utility/ToastContainer";
//import { notify } from "../../utility/ToastContainer";
import MeterServices from "../../services/MeterServices";
//import moment from "moment";
import { Modal, Alert } from "react-bootstrap";
import AddTariffForm from "./AddTariff";
import Dialog from "react-bootstrap-dialog";

export default class TariffTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billable_id: "",
      billables: [],
      tariffs: [],
      tariff_id: "",
      month_start: "",
      month_end: "",
      weekday_start: "",
      weekday_end: "",
      time_start: "",
      time_end: "",
      price: 0,
      valid_from: "",
      valid_to: "",
      getInactive: "Show",
      show: false,
      variant: "",
      msg: "",
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const { billable_id } = this.props;
      this.setState({ billable_id: billable_id });
    }, 1);
  };

  // sendStatusNotification = (type, msg) => {
  //   let className =
  //     type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
  //   let toastMsg = {
  //     msg: msg,
  //     className: className
  //   };
  //   notify(toastMsg);
  // };

  getTariffList = billable_id => {
    if (billable_id) {
      MeterServices.getTariffList(billable_id)
        .then(response => {
          const billables = response.data;
          //console.log("billables tariffs", billables);
          if (billables.length) {
            this.setState({ getInactive: "Show", billables: billables });
          } else {
            this.setState({
              billables: [],
              getInactive: "Show"
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ billables: [] });
        });
    } else {
      this.setState({
        billables: [],
        getInactive: "Show"
      });
    }
  };

  getTariffHistory = () => {
    const getInactive = this.state.getInactive;
    const billable_id = this.props.billable_id;
    if (getInactive === "Show") {
      if (billable_id) {
        MeterServices.getAllTariffs(billable_id)
          .then(response => {
            const billables = response.data;
            //console.log("billables tariffs history", billables);
            if (billables.length) {
              this.setState({ getInactive: "Hide", billables: billables });
            } else {
              this.setState({
                billables: [],
                getInactive: "Hide"
              });
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({ billables: [] });
          });
      } else {
        this.setState({
          billables: [],
          getInactive: "Hide"
        });
      }
    } else {
      //console.log('getInactive', getInactive);
      this.getTariffList(billable_id);
    }
  };

  closeTriffEdit = () => {
    this.setState({ showModalEdit: false });
  };

  editTariff = (tariff, valid_from, valid_to) => {
    //console.log("tariff", tariff);
    this.setState({ showModalEdit: true });
    this.setState({
      startDate: valid_from,
      endDate: valid_to,
      month_start: tariff.month_start,
      month_end: tariff.month_end,
      weekday_start: tariff.weekday_start,
      weekday_end: tariff.weekday_end,
      time_start: tariff.time_start,
      time_end: tariff.time_end,
      price: tariff.price
    });
  };

  deleteTariff = tariff_id => {
    //console.log("tariff -delete", tariff_id);
    this.dialog.show({
      body: "Would you like to delete tariff. Proceed ?",
      actions: [
        Dialog.CancelAction(),
        Dialog.Action(
          "Proceed",
          () => this.deleteTariffOK(tariff_id),
          "btn-warning"
        )
      ],
      bsSize: "medium"
    });
  };

  deleteTariffOK = tariff_id => {
  //  console.log("tariff delete", tariff_id);
    const billable_id = this.props.billable_id;
    MeterServices.deleteTariff(billable_id, tariff_id)
      .then(response => {
        this.setState({variant:"success", msg:"Tariff is deleted successfully."});
        this.getTariffList(billable_id);
        this.onShowAlert();
      })
      .catch(err => {
        console.error(err);
        this.setState({variant:"danger", msg:err});
        this.onShowAlert();
      });
  };

  setShow=()=>{
    this.setState({ show:false });
  }

  onShowAlert = () => {
  //  console.log("onShowAlert");
    this.setState({ show: true }, () => {
      window.setTimeout(() => {
        this.setState({ show: false });
      }, 5000);
    });
  };

  render() {
    const {
      billables,
      month_start,
      month_end,
      weekday_start,
      weekday_end,
      time_start,
      time_end,
      price,
      getInactive,
      variant, msg, show
    } = this.state;
//console.log('billables', billables)

    const v_from = new Date();

    return (
      <div className="table-padding">
      {show === true ? (
        <Alert variant={variant} onClose={() => this.setShow()} dismissible>
          <Alert.Heading>{msg}</Alert.Heading>
        </Alert>
      ) : (
        <div></div>
      )}
        <Dialog
          ref={el => {
            this.dialog = el;
          }}
        />
        <div className=" headerTable">
          <h4>Tariffs</h4>
          <button
            className="inactive-tariffs btn-round"
            onClick={this.getTariffHistory}
          >
            {getInactive} Inactive
          </button>

          <div>
            {billables.map(b => (
              <TariffBillableRow
                billable={b}
                key={b._id}
                valid_from={b.valid_from}
                valid_to={b.valid_to}
                editTariff={this.editTariff}
                deleteTariff={this.deleteTariff}
              />
            ))}
          </div>
        </div>

        <div>
          <Modal
            dialogClassName="custom-dialog"
            backdrop="static"
            keyboard={false}
            show={this.state.showModalEdit}
            onHide={this.closeTriffEdit}
          >
            <Modal.Header closeButton="closeButton">
              <Modal.Title className="textCenter">Edit Tariff</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "relative"
              }}
            >
              <AddTariffForm
                month_start={month_start}
                month_end={month_end}
                weekday_start={weekday_start}
                weekday_end={weekday_end}
                time_start={time_start}
                time_end={time_end}
                price={price}
                startDate={v_from}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
