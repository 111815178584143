import React, { useState, useEffect, useContext } from 'react'
import UserContext from "../../services/user-context"
import ReactEcharts from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/dataZoom";
import 'echarts/lib/component/legendScroll';
import { STYLES, createSeries } from '../plots/options'

export default function BatteryPlot({data=[], soc=[], startDate, endDate}) {
    const [option, setOption] = useState({})
    const [minMax, setMinMax] = useState({})
	const [chart, setChart] = useState(null)

    
    const { Time } = useContext(UserContext)
    useEffect(() => {
        if(chart===null || !Array.isArray(data) || data.length===0) return ;

        const __data = data.map(({timestamp, reading, soc}) => ([
            +Time.localTime(timestamp),
            reading<0?Math.abs(reading):0,
            reading>0?reading:0,
            soc
        ]))
        const series=[
            ...[ 'charge', 'discharge'].map(createSeries),
            ...[ 'soc'].map(s => createSeries(s, { yAxisIndex: 1}))        ]

        const yAxis = [
            {
                type: "value",
                position: "left",
                offset: 0,
                nameTextStyle: {
                    fontSize: 16,
                    padding: 8
                },
                axisLabel: {
                    formatter: "{value}",
                    inside: true,
                    margin: -40
                },
                axisLine: {
                    lineStyle: {
                        color: "black"
                    }
                },
                name: "Energy(kWh)",
                nameLocation: "end",
                min: 0,
                max: 3.3
            },
            {
                type: "value",
                scale: true,
                axisTick: {
                    alignWithLabel: true
                },
                position: "right",
                offset: 0,
                nameTextStyle: {
                    fontSize: 16,
                    padding: 8
                },
                axisLabel: {
                    formatter: v => v>=0?v:'',
                    inside: true,
                    margin: -40
                },
                axisLine: {
                    lineStyle: {
                        color: "blue",
                        inside: true
                    }
                },
                name: "SoC (%)",
                min: 0,
                max: 100
            }
            // , {
            //     type: 'value',
            //     name: 'SoC (%)',
            //     min: -100, 
            //     max: 100,
            //     position: "right",
            //     boundaryGap: [0, '100%']
            // }
        ]
            
        setOption({
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                }
            },
            legend: {
                orient: "horizontal",
                itemWidth: 10,
                itemHeight: 8,
                itemGap: 20,
                icon: "roundRect",
                textStyle: {
                    fontSize: 12
                }
            },
            // title: {
            //   left: 'center',
            //   text: 'Large Ara Chart'
            // },
            toolbox: {
                feature: {
                    dataZoom: {
                      title: {
                          zoom: 'Zoom',
                          back: 'Undo last zoom'
                      },
                      yAxisIndex: 'none'
                    },
                    restore: {
                        title: 'Reset zoom'
                    },
                    saveAsImage: {
                        title: 'Download as PNG'
                    }
                }
            },
            dataset: {
                source: __data,
                dimensions: ['timestamp', 'charge', 'discharge', 'soc']
            },
            grid: {
                top: "20%",
                left: "8%",
                right: "10%",
                bottom: "10%",
                containLabel: true
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                ...minMax
            },
            yAxis,
            dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                },
                {
                  start: 0,
                  end: 100
                }
            ],
            series
        })

        return () => {
            setOption(null)
        }
    }, [chart, data, minMax])

    useEffect(() => {
        const __minMax = {}
        if(startDate) {
            __minMax.min=+startDate
        }
        if(endDate) {
            __minMax.max=+endDate
        }
        setMinMax(__minMax)
    }, [startDate, endDate])

    return <ReactEcharts
        ref={(e) => { setChart(e); }}
        notMerge={true}
        lazyUpdate={true}
        echarts={echarts}
        option={option}
        style={{ height: "350px", width: "100%" }}
    />
}