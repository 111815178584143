import React, { Component } from "react";
import { Redirect } from "react-router-dom"
import { notify } from "../utility/ToastContainer";
import ToastContainer from "../utility/ToastContainer";
import AuthService from "../services/auth";
import logo from "../dashboard/sidebar/Icon_White.png";
import avatar from "../dashboard/titlebar/user_green.png";

class TopAdminNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarActive: props.navbarActive,
      redirect: null
    };
  }

  toggleNavbar = event => {
    event.preventDefault();
    const navState = !this.state.navbarActive;
    this.setState({ navbarActive: navState });
    this.props.showNavbar && this.props.showNavbar(navState);
  };
  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  logout = async e => {
    e.preventDefault();
    try {
      await AuthService.logout();
      // this.props.history.replace("/login?status=Logged.Out");
      this.setState({redirect: "/login?status=Logged.Out"});
    } catch (err) {
      console.error("logout() : ", err);
      this.sendStatusNotification("ERROR", err);
    }
  };

  render() {
    const { user } = this.props;
    const { redirect } = this.state
    if(redirect!==null) {
      return (<Redirect to={redirect}/>)
    }
    return (
      <div>
        <div>
          <ToastContainer />
        </div>

        <div style={{height: "5rem"}}>
          <nav className="navbar navbar-expand fixed-top gradient-scooter">
          <h2>Admin Dashboard</h2>
            <ul className="navbar-nav mr-auto align-items-center">
              <li className="nav-item">
                <img src={logo} alt="user icon" className="user-icon" />
              </li>
            </ul>
            <ul className="navbar-nav align-items-center right-nav-link">
              <li className="nav-item">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  data-toggle="dropdown"
                  href="/#"
                  aria-expanded="false"
                >
                  <span className="user-profile">
                    <img src={avatar} alt="user icon" className="user-icon" />
                  </span>
                  <span style={{ color: "#212529", marginLeft: "10px" }}>
                    Welcome {user.firstname} ({user.organisation.name})
                    <i className="fas fa-caret-down" />
                  </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-right">
                  <li className="dropdown-item user-details">
                    <div className="media">
                      <div className="avatar">
                        <img
                          src={avatar}
                          alt="user icon"
                          className="user-icon"
                        />
                      </div>
                      <div className="media-body">
                        <h6 className="mt-2 user-title">
                          {user.organisation.name}
                        </h6>
                        <p className="user-subtitle">{user.email}</p>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown-divider" />
                  <li className="dropdown-item">
                    <a href="/topadmin/profile">
                      {" "}
                      <i className="fa fa-cog" /> Profile
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <a href="/#" onClick={this.logout}>
                      {" "}
                      <i className="fas fa-power-off" /> Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default TopAdminNav;
