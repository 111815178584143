import React, { useState, useEffect } from 'react'
import { Redirect } from "react-router-dom";
import { AddButton, EditButton, DeleteButton, ConfirmAlert, Toggle, LocationChooser } from '../../components/UIComponents.jsx'
import { Form, Card, Container, Row, Col, Button, Accordion, Dropdown, ButtonGroup } from 'react-bootstrap'
import SaveAsDialog from './SaveAsDialog.jsx'
import EditBaseLoadProfile from './EditBaseLoadProfile.jsx'
import { Request } from "../../../services/HttpService";
import { Notify } from '../../components/AlertListener.jsx'
import uuid from '../../../utility/uuid'

const request = new Request({ service: 'Scenario service' })

const ATTRS = ["name", "description", "billing_plan_id"];

const notify = Notify("load.notifications")

const DEFAULT = { name: "", description: "", billing_plan_id: "" }

const IS_EMPTY = /^\s*$/

const FORM_WIDTH = { lg: 6 },
	noop = () => { }

function BaseLoadProfileRow({scenarioId, organisations, baseLoadProfile, onDelete=noop, edit=false, eventKey}) {
	const [isEdit, setEdit] = useState(edit) 
	const [__baseLoadProfile, setBaseLoadProfile] = useState({...baseLoadProfile})
	const [confirmRequired, confirmDelete] = useState(false)

	if(isEdit) {
		return (<EditBaseLoadProfile visible={isEdit}
			baseLoadProfile={__baseLoadProfile}
			organisations={organisations}
			scenario_id={scenarioId}
			onSave={async (blp) => {
				setEdit(false)
				setBaseLoadProfile({...blp})
			}}
			onCancel={() => { setEdit(false)}}/>)
	}

	async function deleteBaseLoadProfile(e) {
		if(e===true) {
			try {
				await request.call(`/api/scenario/load/${scenarioId}/baseLoadProfiles/${__baseLoadProfile._id}`, 'delete')
				return onDelete(__baseLoadProfile._id)
			}
			catch(err) {
				notify.error(err.message)
			}
			return confirmDelete(false)
		}
		confirmDelete(true)
	}

	return (
		<Card>
			<Card.Header>
				<Toggle eventKey={eventKey}/>
				<span>{__baseLoadProfile.name}</span>
				<div className="btn-right">
		      		<EditButton label={`Edit baseLoadProfile "${__baseLoadProfile.name}"`} size="sm" onClick={() => setEdit(true)}/>
		      		<DeleteButton label={`Delete baseLoadProfile "${__baseLoadProfile.name}"`} onClick={() => deleteBaseLoadProfile()} size="sm"/>
		      	</div>
			</Card.Header>
			{confirmRequired?(
				<Card.Body>
					<ConfirmAlert
							rightJustify
							text={`Delete ${__baseLoadProfile.name}?`}
							onYes={() => deleteBaseLoadProfile(true)}
							onNo={() => confirmDelete(false)}
						/>
				</Card.Body>
			):(
				<Accordion.Collapse eventKey={eventKey}>
					<Card.Body>
						<p>Organisation: {`${__baseLoadProfile.org_name}`}</p>
						<p>Scaler: {`${__baseLoadProfile.scaler}%`}</p>
					</Card.Body>
				</Accordion.Collapse>
			)}
		</Card>
	)
}

function EditLoadScenario({ visible = false, mode = 'add', _id = null }) {

	const [fields, setFields] = useState(DEFAULT)
	const [initial, setInitial] = useState(DEFAULT)
	const [loading, setLoading] = useState(false)
	const [canSave, enableSave] = useState(false)
	const [saveAs, setSaveAs] = useState(null)
	const [redirect, setRedirect] = useState(null)
	const [confirmRequired, setConfirm] = useState(false)
	const [id, setId] = useState(_id)
	const [isEdit, setEdit] = useState(false)
	const [billingPlans, setBillingPlans] = useState([])
	const [organisations, setOrgs] = useState([])
	const [baseLoadProfiles, setBaseLoadProfiles] = useState([])
	const [askAddBaseLoadProfiles, addBaseLoadProfilesPrompt] = useState(false)
	const [__addBaseLoadProfile, addBaseLoadProfile] = useState(false)

	useEffect(() => {
		const loadScenario = async () => {
			addBaseLoadProfilesPrompt(false)
			setRedirect(null)
			if (mode === 'add') {
				// Adding
				setEdit(false)
				setFields({ ...DEFAULT })
				setInitial({ ...DEFAULT })
			}
			else {
				// Editing
				// Need to load the record
				setEdit(true)
				try {
					const scenario = await request.call(`/api/scenario/load/${_id}`)
					setId(_id)
					const { name, description = "", billing_plan_id = "", baseLoadProfiles = [] } = scenario;
					const initial = { name, description, billing_plan_id };
					setInitial(initial)
					setFields({ ...initial })
					setBaseLoadProfiles([...baseLoadProfiles])
				}
				catch (err) {
					notify.error(err.message)
				}
			}
		}

		const loadBillingPlans = async () => {
			try {
				const __plans = await request.call(`/api/billable/plan?type=Cluster`)
				setBillingPlans(__plans)
			}
			catch (err) {
				notify.error(err.message)
			}
		}

		setLoading(true)
		Promise.allSettled([loadScenario(), loadBillingPlans(), loadBaseLoadProfiles()])
			.then(() => {
				setLoading(false)
			})

		return () => {
			setId(null)
			setLoading(false)
			setDisabled({ disabled: false })
		}
	}, [mode, _id])

	const [__disabled, setDisabled] = useState({ disabled: false })
	useEffect(() => {
		setDisabled({ disabled: loading })

		return () => {
			setLoading(false)
			setDisabled({ disabled: false })
		}
	}, [loading])

	useEffect(() => {
		return () => {
			setRedirect(null)
		}
	}, [redirect])

	const [errors, setErrors] = useState(ATTRS.reduce((a, c) => { a[c] = false; return a }, {}))

	async function loadBaseLoadProfiles() {
		if(id !== null){
			try {
				
				const __org = await request.call(`/api/organisation`)
				const __orgs = await request.call(`/api/organisation/${__org[0]._id}/site/tenant?id=${__org[0].cluster_id}`)
				const __baseLoadProfiles = await request.call(`/api/scenario/load/${id}/baseLoadProfiles`)
				
				const _bloads = await __baseLoadProfiles.map(_b => {
					let __o = __orgs.filter(_o => _o._id == _b.baseLoad_id)
					_b.org_name = __o[0].name
					return _b
				})

				setOrgs(__orgs)
				setBaseLoadProfiles(_bloads)
			}
			catch(err) {
				notify.error(err.message)
			}
		}
	}

	async function handleSaveAs() {
		const { name } = fields;
		const __valid = isValid('name', name)
		if (__valid) {
			setSaveAs({ ...fields, name: `Copy of ${fields.name}`, baseLoadProfiles: baseLoadProfiles || [] })
		}
	}

	async function save() {
		const { name, description, billing_plan_id = "" } = fields;
		try {
			const __valid = isValid('name', name);
			if (__valid) {
				const data = { name, description }

				if (!IS_EMPTY.test(billing_plan_id)) {
					data.billing_plan_id = billing_plan_id
				}
				if (!isEdit) {
					const __new = await request.call(`/api/scenario/load`, 'put', { data })
					setId(__new._id)
					addBaseLoadProfilesPrompt(true)
					// setRedirect('/list')
				}
				else {
					await request.call(`/api/scenario/load${mode === 'edit' ? `/${_id}` : ''}`, 'put', { data })
					setRedirect('/list')
				}
				notify.info(`${isEdit ? "Updated" : "Created"} load scenario "${name}"`);
			}
		}
		catch (err) {
			notify.error(err.message);
		}
	}

	function hasChanged() {
		if (initial === null) return false
		console.log('hasChanged() : fields  : ', JSON.stringify(fields))
		console.log('hasChanged() : initial : ', JSON.stringify(initial))
		const tmp = ATTRS.reduce((en, k) => en || initial[k] !== fields[k], false)
		return tmp
	}

	function saveButtonEnabled() {
		const __noErrors = ATTRS.reduce((en, k) => en && errors[k] === false, true)
		if (__noErrors) {
			console.log('saveButtonEnabled() : hasChanged = ', hasChanged())
			return hasChanged()
		}
		return false;
	}

	function isValid(name, value) {
		errors[name] = false;

		switch (name) {
			case 'name':
				if (value.length < 2) {
					errors.name = 'Name must be at least 2 characters'
				}
				break;
			default:
				break
		}
		fields[name] = value;
		setFields({ ...fields })
		setErrors({ ...errors })
		enableSave(saveButtonEnabled());
		return errors[name] === false;
	}

	function onChange(e) {
		const { value = "", name } = e.target;
		isValid(name, value)
	}


	function cancel(e) {
		if (e === true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			setRedirect('/list')
		}
		setConfirm(true)
	}

	const ButtonSave = () => (<Button type="button" onClick={save} variant="re-primary" disabled={!canSave}>
		<i className="fas fa-save" />Save
	</Button>)

	return redirect !== null ?
		(<Redirect to={`/dashboard/scenario/load${redirect}`} />) :
		(
			<Card className="settings"><Container className="small-spacing" style={{ marginLeft: "0px", marginRight: "0px" }}>
				<Row>
					<Col className="headerTable">
						<h2>{`${isEdit ? "Edit" : "Add"} Scenario`}</h2>
					</Col>
				</Row>
				{saveAs !== null ? (<SaveAsDialog
					data={saveAs}
					onCancel={() => setSaveAs(null)}
					onSave={(o) => {
						setSaveAs(null)
						notify.info(`Saved copy of "${fields.name}"`)
						setRedirect(`/${o._id}/edit`)
					}} />
				) : null}
				{ askAddBaseLoadProfiles?
					(<Row><Col lg="4"><ConfirmAlert 
						heading="Add base load profile to scenario?"
						variant="light"
						onNo={() => setRedirect('/list')}
						onYes={() => setRedirect(`/${id}/edit`)}
						/></Col></Row>
					):(<form onSubmit={save}>
						<div className="form-group">
							<Form.Row>
								<Col lg="3">
									<label className="on">Name</label>
									<input
										className="form-control"
										type="text"
										required
										{...__disabled}
										name="name"
										id="name"
										onChange={onChange}
										placeholder="Enter name"
										value={fields.name}
									/>
									{errors.name && (
										<span className="error">{errors.name}</span>
									)}
								</Col>
							</Form.Row>
							<Form.Row>
								<Col lg="3">
									<label className="on">Description</label>
									<input
										className="form-control"
										type="text"
										{...__disabled}
										name="description"
										id="description"
										onChange={onChange}
										placeholder="Enter description"
										value={fields.description}
									/>
									{errors.name && (
										<span className="error">{errors.description}</span>
									)}
								</Col>
							</Form.Row>
							<Form.Row>
								<Col lg="3">
									<Form.Group>
										<Form.Label>Billing plan</Form.Label>
										<Form.Control as="select" name="billing_plan_id" value={fields.billing_plan_id} onChange={onChange} custom>
											<option key="billing_plan_none" value="">None</option>
											{billingPlans.map(({ _id, name }) => (
												<option key={`billing_plan_${_id}`} value={_id}>{name}</option>)
											)}
										</Form.Control>
									</Form.Group>
								</Col>
							</Form.Row>
						</div>
						{isEdit?(
							<Row>
								<Col {...FORM_WIDTH}>
									<div className="headerTable">
										<h5>Base Load Profiles</h5>
										<div className="btn-right">
											<AddButton label="Add Base Load" onClick={() => addBaseLoadProfile(true)} size="1x"/>
										</div>
									{__addBaseLoadProfile?(
										<EditBaseLoadProfile visible={__addBaseLoadProfile}
											scenario_id={id}
											organisations={organisations}
											onSave={async (bl) => {
												addBaseLoadProfile(false)
												loadBaseLoadProfiles(id)
											}}
											onCancel={() => {addBaseLoadProfile(false)}}
										/>
									):null}
									</div>
									<hr/>
									{baseLoadProfiles.length>0?
									(<Accordion>
										{baseLoadProfiles.map((blp,i) => (
											<BaseLoadProfileRow 
												scenarioId={id} 
												organisations={organisations}
												key={`blr_${uuid()}`} 
												eventKey={blp._id} 
												baseLoadProfile={blp}
												onDelete={async() => {await loadBaseLoadProfiles()}}
											/>
										))}
									</Accordion>
									):<div>No base load profiles defined</div>}
								</Col>
							</Row>
						):null}
						<div className="form-group save-cancel">
							<Row>
								<Col {...FORM_WIDTH}>
									{confirmRequired ? (<ConfirmAlert
										heading="Details have changed"
										text="Scenario details have been changed, really cancel?"
										buttonsInline={false}
										onNo={() => setConfirm(false)}
										onYes={() => cancel(true)} />
									):(<div className="form-group btn-right">
											<Button
												type="button"
												variant="outline-secondary"
												onClick={cancel}
												style={{ marginRight: "1rem" }}>
												<i className="fas fa-times" />Cancel
											</Button>
											{isEdit ? (<Dropdown as={ButtonGroup} style={{ margin: 0 }}>
												<ButtonSave onClick={save} />
												<Dropdown.Toggle split variant="re-primary" />
												<Dropdown.Menu>
													<Dropdown.Item onSelect={save} disabled={!canSave}>Save</Dropdown.Item>
													<Dropdown.Item onSelect={handleSaveAs}>Save as</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>) : (<ButtonSave onClick={save} />)}
										</div>)}
								</Col>
							</Row>
						</div>
					</form>)}
		</Container>
	</Card>)
}


export default EditLoadScenario