import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Menu, Segment } from "semantic-ui-react";
import {
	// BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'

import SessionStorage from '../../services/SessionStorage'

import UserSettings from "./UserTable";
import SiteSettings from "./SiteTable";
import BillingSettings from "../components/billing/BillingDashboard"
import SiteDashboard from "./site/SiteDashboard"
import OrganisationDashboard from "./organisation/OrganisationDashboard"
import ClusterDashboard from "./cluster/ClusterSettingsDashboard"
import WindSettings from "../wind/WindSettings";
import BatterySettings from "./battery/BatterySettings"
import MeterSettingDashboard from "./meters/MetersDashboard"
import RenewableSettingDashboard from "./renewables/RenewablesDashboard"
import UserContext from "../../services/user-context"
import { isID } from "../../utility"

// qB73&xyUfL52$%!h

const TAB_ACTIVE = 'org.admin.tab.active'

const TABS = [
    { 
        to: "cluster",
        label: 'Cluster',
        auth: ['cluster_owner']
    },
    { 
        to: "organisation",
        label: "Organisation",
        auth: ['cadmin']
    },
    { 
        to: "sites",
        label: "Sites",
        auth: ['cadmin']
    },
    // { 
    //     to: "meters",
    //     label: "Meters",
    //     auth: ['cadmin']
    // },
    // { 
    //     to: "sites-legacy",
    //     label: "Sites (Legacy)",
    //     auth: ['cadmin']
    // },
    { 
        to: "user",
        label: "Users",
        auth: ['cadmin']
    },
    { 
        to: "renewables",
        label: "Solar",
        auth: ['cadmin']
    },
    { 
        to: "wind",
        label: "Wind",
        auth: ['cadmin']
    },
    { 
        to: "battery",
        label: "Battery",
        auth: ['cadmin', 'cluster_owner']
    },
/* { 
    to: "clusterjoin",
    label: "Cluster",
    auth: ['cadmin']
}
,
{isClusterOwner? ({ 
    to: 'cluster"admin",
    label: "Requests",
    auth: ['cadmin']
}
), : null} */
    { 
        to: "billing",
        label: "Billing",
        auth: ['cadmin']
    }
]


export default function OrganisationSettings() {
	const [activeTab, setActiveTab] = useState(SessionStorage.get(TAB_ACTIVE, '/dashboard/settings/sites'))
    const [tabs, setTabs] = useState([])
    const { user } = useContext(UserContext)

    const [isClusterMember, setClusterMember] = useState(user.organisation && isID(user.organisation.clusterId))
    const [isClusterOwner, setClusterOwner] = useState(user.isClusterOwner)
    const [isOrgAdmin, setOrgAdmin] = useState(user.isOrgAdmin)

    useEffect(() => {
        if(user===null) return

        const roles = user.roles || []
        // Filter the tabs by role
        setTabs(TABS.filter(({auth=['cadmin']}) => 
            auth.some(a => roles.includes(a))
        ))

        return () => {
            setTabs([])
        }
    }, [user])

	function handleTabChange(tab) {
        const __activeTab = `/dashboard/settings/${tab}`
		SessionStorage.set(TAB_ACTIVE, __activeTab)
		setActiveTab(__activeTab)
    }

    const location = useLocation(),
        history = useHistory()

    return tabs.length>0 && (
        <Container fluid>
            <Menu  pointing tabular attached="top">
                {tabs.map(({to, label}) =>  {
                    return (<LinkContainer key={`settings_link_${to}`} to={`/dashboard/settings/${to}`}>
                        <Menu.Item name={label} onClick={() => handleTabChange(to)}>
                            {label}
                        </Menu.Item>
                    </LinkContainer>)
                })}
            </Menu>
            <Segment attached="bottom">
                <Container fluid>
                    {user!==null?(<Switch>
                        {isOrgAdmin && <Route key="organisation" path="/dashboard/settings/organisation" component={OrganisationDashboard}/>}
                        {(isClusterOwner) && 
                            <Route key="cluster" path="/dashboard/settings/cluster" component={ClusterDashboard}/>}
                        <Route key="site" path="/dashboard/settings/sites" component={SiteDashboard}/>
                        <Route key="user" path="/dashboard/settings/user" render={(props) => (
                            <UserSettings {...props} 
                                user={user}
                                // org_id={org_id}
                            />) 
                        }/>
                        {/* <Route key="clusterjoin" path="/dashboard/settings/clusterjoin" component={ClusterSettings} /> */}
                        <Route key="renewables" path="/dashboard/settings/renewables" component={RenewableSettingDashboard}/>

                        <Route key="wind" path="/dashboard/settings/wind" component={WindSettings} />
                        <Route key="battery" path="/dashboard/settings/battery"><BatterySettings/></Route>
                        <Route key="billing" path="/dashboard/settings/billing" render={props => <BillingSettings root='/dashboard/settings/billing'/>}/>
                        {/* {isClusterOwner? (
                            <Route key="cluster-admin" path="/dashboard/settings/cluster-admin"  render={(props) => <Requests {...props}
                                user={user}/>} />
                            ) : null
                        } */}
                        <Route
                            render={() => {
                                let __to=activeTab
                                if(__to===location.pathname) {
                                    __to = tabs[0].to
                                    console.log(`Circular redirect - go to "${__to}" instead`)
                                }
                                console.log('Reedirecting to : ', __to)
                                history.replace(__to)
                            }}
                        />

                    </Switch>):null}

                </Container>
            </Segment>
        </Container>
    )

}