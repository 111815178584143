import React, { useState, useEffect, useRef, useMemo } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'

function DraggableMarker({position, text=null, onPositionChange}) {
  const [, setLatlng] = useState(position)
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
        	const {lat, lng} = marker.getLatLng() ;
        	setLatlng({lat, lng})
          	onPositionChange(parseFloat(lat.toFixed(6)), parseFloat(lng.toFixed(6)))
        }
      },
    }),
    [onPositionChange],
  )

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
      {text!==null?
      	(<Tooltip minWidth={90}>
	        <span>{text}</span>
	      </Tooltip>):null}
    </Marker>
  )
}

function LocationChooser({
	latitude=null, longitude=null, 
	onPositionChange=null,
	height="50vh", width="100%",
	visible: isVisible=false,
	closable=false,
	onDismiss=()=>{}
}) {
	if(latitude===null || longitude===null) {
		throw new Error('LocationChooser() : latitude and longitude required')
	}
	const [ position, setPosition ] = useState({lat: latitude, lon: longitude})

	useEffect(() => {
		setPosition({lat: latitude, lon: longitude})
	}, [latitude, longitude])

	const [ visible, setVisible ] = useState(isVisible)

	console.log('LocationChooser() - enter')

	return visible?(
		<div>
			{closable?(<div className="modal-header">
				<div className="textCenter modal-title h5">
					<span>Choose location</span>&nbsp;
				</div>
				<button type="button" className="close" onClick={() => {setVisible(false); onDismiss()}}>
					<span aria-hidden="true">×</span>
					<span className="sr-only">Close</span>
				</button>
			</div>):null}

			<MapContainer style={{ height: height, width: width}} center={position} zoom={13} scrollWheelZoom={true}>
			  <TileLayer
			    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			  />
			  <DraggableMarker position={position} text="Drag to location" onPositionChange={onPositionChange}/>
			</MapContainer>
		</div>
	):null
}

export default LocationChooser ;