import React, { useState } from 'react';
import {
	Switch,
	Route,
	Redirect
} from 'react-router-dom';
import ScenarioList from './LoadScenarioList.jsx'
import EditScenario from './EditLoadScenario.jsx'
import RunScenario from './RunLoadScenario.jsx'
import {AlertContainer, Notify} from '../../components/AlertListener.jsx'
const notify = Notify('load.notifications')

function LoadDashboard() {

	return (
		<React.Fragment>
			<AlertContainer tag='load.notifications' page='load'/>
			<Switch>
				<Route path='/dashboard/scenario/load/list' exact>
					<React.Fragment>
						<ScenarioList/>
					</React.Fragment>
				</Route>
				<Route path='/dashboard/scenario/load/new' exact>
					<React.Fragment>
						<EditScenario/>
					</React.Fragment>
				</Route>
				<Route 
					path="/dashboard/scenario/load/:id([a-fA-F0-9]{24})/edit"
					exact
					render={({match, props, location}) => (
						<EditScenario mode="edit" _id={match.params.id}/>
					)}
				/>
				<Route 
					path="/dashboard/scenario/load/:id([a-fA-F0-9]{24})/run"
					exact
					render={({match, props, location}) => (
						<RunScenario _id={match.params.id}/>
					)}
				/>
				<Route>
					<Redirect to='/dashboard/scenario/load/list'/>
				</Route>
			</Switch>
		</React.Fragment>
	)
}

export default LoadDashboard