import React, {useState, useEffect, useCallback, useRef} from 'react'
import { Link } from 'react-router-dom'
import {Alert, Button, ButtonToolbar, Card, Container, Row, Col, Form, Modal } from 'react-bootstrap'
import useIsMounted from "../../../utility/isMounted"
import uuid from "../../../utility/uuid"
import moment, { monthsShort } from 'moment'
import { Loading, LeafletMap } from "../../components/UIComponents.jsx"
import { Notify } from '../../components/AlertListener.jsx'
import { Request } from "../../../services/HttpService";
import SelectCompare from './SelectLoadCompare.jsx'
import { JAN1, DAYS_IN_MONTH } from './plots/utils'
import MonthPlot from './plots/MonthPlot'
import YearPlot from './plots/YearPlot'
import DayPlot from './plots/DayPlot'

const notify=Notify("load.notifications")
const request = new Request({ service: 'Scenario service'})

const LOADING_NONE=0, 
	LOADING_SCENARIO=1,
	RUNNING_SCENARIO=2 ;

function RunLoadScenario({_id=null, month: __month=null, day: __day=null})  {
    const isMounted = useIsMounted() ;
    const [loading, setLoading] = useState(LOADING_NONE)
    const [error, setError] = useState(null)
    const [scenario, setScenario] = useState(null)
    const [fulldata, setFulldata] = useState(null)
    const [splitData, setSplitData] = useState(null)
    const [selectCompare, setSelectCompare] = useState(false)
    const [compare, setCompare] = useState([]);
    const [canCompare, setCanCompare] = useState(false)
    const [viewDetails, showDetails] = useState(false)
    const [isWarned, setWarned] = useState(false)
    const [isExporting, setExport] = useState(false)
    const [type, setType] = useState('ac')
    const [month, setMonth] = useState(null)
    const [day, setDay] = useState(null)
    const [precision, setPrecision] = useState([1, 2, "kWh"])

	useState(() => {
		if(_id===null || __month===null) return 
		const __mNdx=parseInt(__month)
		if(!isNaN(__mNdx) && __mNdx>=1 && __mNdx<=12 ) {
			const __m = monthsShort()[__mNdx-1]
			setMonth(__m)
			if(__day!==null) {
				const __dNdx = parseInt(__day)
				if(!isNaN(__dNdx) && __dNdx>=1 && __dNdx<=DAYS_IN_MONTH[__mNdx-1]) {
					setDay(moment(JAN1).add({months: __mNdx-1, days: __dNdx-1}))
				}
				else {
					console.error(`${__m} must have day between 01 and ${DAYS_IN_MONTH[__mNdx-1]}`)
				}
			}
		}
		else {
			console.error('Month must be in range 01 - 12')
		}
	}, [_id, __month, __day])

	function reset() {
		setLoading(l => LOADING_NONE)
		setScenario(s => null)
		setFulldata(r => null)
		setSelectCompare(false)
		setCompare(c => [])
	}


	useEffect(() => {
		return reset 
	}, [])

	const [times, setTimes] = useState(1)

	function writeCSV({useUTC=false, output, prices, filename="output.csv"}) {
		const formatTimestamp= ts => useUTC?
			ts.toISOString():
			ts.local().format("YYYY-MM-DD HH:mm:ss")

		const __includePrices = Array.isArray(prices) && prices.length===output.length
		const __content = [ `"Timestamp","Energy (kWh)"${__includePrices?',"Price"':''}\n` ]

		const __ts=moment.utc('2019-01-01T00:00:00Z')
		for(const [i, v] of output.entries()) {
			__content.push(`"${formatTimestamp(__ts)}",${(v).toFixed(3)}${__includePrices?`,${prices[i]}`:''}\n`)
			__ts.add(1, 'hour')
		}

		const a = document.createElement('a');
		const file = new Blob(__content, {type: 'text/csv'});
  
  		a.href= URL.createObjectURL(file);
  		a.download = filename;
  		a.click();

		URL.revokeObjectURL(a.href);
		setTimes(times+1)
	}

	function Exporter({show}) {
		const [visible, setVisible] = useState(false)
		const [output, setOutput] = useState([])
		const [prices, setPrices] = useState(null)
		const [useUTC, setUseUTC] = useState(false)
		const [usePrices, setUsePrices] = useState(false)
		const [scenario, setScenario] = useState(null)

		const refSubmit = useRef(null)

		useEffect(() => {
			if(show) {
				const __showDialog = false
				if(!Array.isArray(fulldata) || fulldata.length<1 ) {
					setError('No data available to export')
					setVisible(false)
				}
				else {
					const [scenario, data]=fulldata
	
					const { ac, prices=null } = data.hourly || {}
					if(!Array.isArray(ac) || ac.length===0) {
						setError('No output data available')
					}
					setOutput(ac)
					const __prices = Array.isArray(prices)&&prices.length===ac.length?prices:null
					setPrices(__prices)
					setUsePrices(__prices!==null)
					setScenario(scenario)
					setVisible(true)
				}
			}
			else {
				setScenario(s => null)
				setPrices(p => null)
				setOutput(o => [])
			}
			return () => {
				setVisible(false)
			}
		}, [show])

		useEffect(() => {
			if(visible && refSubmit.current!==null) {
				refSubmit.current.focus()
			}

		}, [visible])

		function __export() {
			writeCSV({filename: `${scenario.name}.csv`, output, prices: usePrices?prices:null, useUTC})
	
			setExport(false)
		}



		return (
			<Modal show={visible}
				backdrop="static"
				onHide={() => setExport(false)}
				centered>
				<Form onSubmit={() => __export()}>
					<Modal.Header closeButton>
						<Modal.Title>Export scenario "{scenario && scenario.name}" results</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>Export {output.length/24} days data</h5>
						<Row>
							<Col xs={{offset: 1}}>
							<Form.Row>
							<Form.Check custom
								label="Use UTC for timestamps"
								id='chkUseUTC'
								defaultChecked={useUTC}
								onChange={() => setUseUTC(!useUTC)}/>
						</Form.Row>
						<Form.Row>
							<Form.Check custom
								label="Include price data"
								id='chkIncludePricing'
								defaultChecked={usePrices}
								disabled={prices===null}
								onChange={() => setUsePrices(!usePrices)}/>
						</Form.Row>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button type="button" 
							variant="outline-secondary"
							onClick={() => setExport(false)}>
							<i className="fas fa-times"/>Cancel
						</Button>
						<Button type="submit" 
							ref={refSubmit}
							variant="primary">
							<i className="fas fa-save"/>Export
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)
	}

	useEffect(() => {
		async function loadScenario(scenarioId) {
			setLoading(LOADING_SCENARIO)
			try {
				const scenario = await request.call(`/api/scenario/load/${scenarioId}`)
				if(isMounted.current) {
					const { _id, name, description="", cluster_id, billing_plan_id, baseLoadProfiles=[] } = scenario;
					setScenario(s => ({_id, 
						name, 
						description, 
						cluster_id, 
						billing_plan_id,
						baseLoadProfiles: baseLoadProfiles.map(blp => ({...blp, enabled: blp.enabled!==false}))
					}))
				}
			}
			catch(err) {
				notify.error(err.message)
				reset()
			}
		}
		if(_id!==null) {
			loadScenario(_id)
		}
	}, [_id, isMounted])


	async function runScenario(scenarios=[], baseLoadProfiles) {
		let __errs = [], __results=[] ;

		if(scenarios.length===0) return []

		if(__errs.length===0) {
			setLoading(RUNNING_SCENARIO)
			const __requests = scenarios.map(__scenario => 
				{
					// First load the monthly data
					return request.call(`/api/scenario/load/${__scenario._id}/run?granularity=all`, 
						'get',
						{ params: { baseLoadProfiles }})
					.then(data => {
						return [__scenario, data!==""?data:null]
					})
					.catch(err => {
						console.error(err)
						__errs.push(err.message)
						return [__scenario, null]
					})
				})
			__results = await Promise.all(__requests)
			setLoading(l => LOADING_NONE)
		}
		if(__errs.length>0) {
			setError(e => __errs)
		}
		else {
			setError(e => null)
		}
		return __results
	}

	const ErrorsAlert = () => {
		if(error===null) return null
		console.error(error)
		let __content=null
		if(Array.isArray(error)) {
			__content = error.length>1?(<ol>
				{error.map(e => (<li key={uuid()}>{e}</li>))}
			</ol>):
			(<p>{error[0]}</p>)
		}
		else if(typeof(error)==='string') {
			__content = (<p>{error}</p>)
		}
		if(__content===null) return __content ;
		return (
			<Alert variant="danger" dismissible onClose={() => setError(null)}>
				<Alert.Heading>Errors</Alert.Heading>{__content}</Alert>)
	}

	useEffect(() => {
		if(scenario===null) return 
		const { baseLoadProfiles } = scenario
		if(baseLoadProfiles.length===0) {
			setLoading(LOADING_NONE)
			return 
		}

		const __run = async () => {
			const __result=await runScenario(
				[scenario], 
				baseLoadProfiles.filter(blp => blp.enabled).map(blp => blp._id)) ;
			setFulldata(d => __result[0]) ;
			setCanCompare(__result[0][1]!==null)
		}
		__run()

	}, [scenario])

	// useEffect(() => {
	// 	async function __fetchSplitData() {
	// 		try {
	// 			const {_id, baseLoadProfiles } = fulldata[0]
	// 			// First load the monthly data
	// 			const data=await request.call(`/api/scenario/load/${_id}/split`, 
	// 				'get',
	// 				{ params: { baseLoadProfiles : baseLoadProfiles.filter(blp => blp.enabled).map(blp => blp._id) }})
	// 			setSplitData(data)
	// 		}
	// 		catch(err) {
	// 			console.error(err) 
	// 			notify.error(err.message)
	// 		}
	// 	}
	// 	if(!Array.isArray(fulldata) || fulldata.length===0) {
	// 		setSplitData(null)
	// 		return
	// 	}
	// 	__fetchSplitData()
	// }, [fulldata])

	function toggle(blp) {
		// Don't allow all profiles be disabled
		if(blp.enabled) {
			const __active = scenario.baseLoadProfiles.reduce((a, v) => a+(v.enabled?1:0), 0)
			if(__active<2) {
				if(!isWarned) {
					notify.warn('Must have at least 1 active base load profile')
					setWarned(true)
				}
				return ;
			}
		}
		blp.enabled = !blp.enabled ;
		// Rerun the scenario
		setScenario({...scenario, baseLoadProfiles: [...scenario.baseLoadProfiles]}) 
	}

	async function onCompare(scenarios) {
		setSelectCompare(false)
		if(scenarios.length>0) {
			const __data = await runScenario(scenarios)
			setCompare(__data)
		}
		else {
			setCompare([])
		}
	}

	function onUncompare(scenario) {
		const __newCompare = compare.filter(([s]) => s._id!==scenario._id)
		setCompare(__newCompare)
	}

	const Comparing = ({scenario, data=null}) => {
		if(scenario===null) return null
		const backgroundColor=data!==null?'inherit':'red'
		return (<div style={{marginLeft: "1rem", display: "inline-block", border: "1px solid", padding: "0.25rem", backgroundColor}}>
			<span style={{margin: "0.25rem"}}>{scenario.name}</span>
			<button 
				type="button" 
				className="close"
				onClick={() => onUncompare(scenario)}
				disabled={selectCompare!==false}
				style={{borderLeft: "1px solid", paddingLeft: "0.25rem", marginLeft: "0.25rem", backgroundColor}}>
					<span aria-hidden="true">×</span><span className="sr-only">Close alert</span>
			</button>
		</div>)
	}

	const __setDay = useCallback((d=null, m=null) => {
		if(d===null) {
			setDay(null)
			if(m!==null) setMonth(m)
		}
		else {
			setDay(d)
			setMonth(m!==null?m:d.format('MMM'))
		}
	}, [])


    return (
		<Card>
			<Container fluid>
				<Row>
					<Col>
						<h2>Run Scenario</h2>
			      		<ButtonToolbar><Link to={`/dashboard/scenario/load/list`}>
							<Button type="button" size="sm" variant="outline-secondary">
								<i className="fas fa-arrow-left"/>Back
							</Button>
							
						</Link></ButtonToolbar>
					</Col>
				</Row>
				{loading===LOADING_SCENARIO?(
					<Row><Col><Loading active={true}/></Col></Row>
				):scenario!==null?
				Array.isArray(scenario.baseLoadProfiles) && scenario.baseLoadProfiles.length===0?
				(
					<Alert variant="warning">
						Cannot run scenario "{scenario.name}". No base load profiles defined
					</Alert>
				):
				(<React.Fragment>
				<Row>
					<Col>
						<Card.Title>
					        <Button variant="link" type="button"
					        	onClick={() => showDetails(!viewDetails)}
					            className="btn btn-link">
            					<span>
                					<i className={`fas fa-chevron-${viewDetails?"down":"right"}`}></i>
            					</span>
        					</Button>

							<span>{scenario.name}</span>
						</Card.Title>
					</Col>
				</Row>
				{viewDetails?(<Row>
					<Col lg={4}>
						{scenario.description.length>0?(<Card.Subtitle>{scenario.description}</Card.Subtitle>):null}
						<h5>Base Load Profiles</h5>
						<Container fluid>
							<Form>
							{scenario.baseLoadProfiles.map(blp => (
								<Row key={`runpnl_${blp._id}`}>
									<Col>
										<Form.Check 
    										type="switch"
											label={`${blp.name} - ${blp.scaler}%`}
											id={`toggle-profile-${blp._id}`}
											onClick={() => toggle(blp)}
											onChange={() => {}}
											checked={blp.enabled}/>
									</Col>
								</Row>))}
							</Form>
						</Container>
					</Col>
				</Row>):null}
				<Row>
					<Col>
						<h4>Graph</h4>
						<ErrorsAlert/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="re-primary" 
							type="button" 
							onClick={() => setSelectCompare(true)}
							disabled={!canCompare || selectCompare!==false}>
							<i className="fas fa-balance-scale"/>Compare
						</Button>
						{ compare.map(([s, data]) => (<Comparing key={`key_${s._id}`} scenario={s} data={data}/>))}
						{loading===RUNNING_SCENARIO?(<Loading text="Running Load Scenario" active={true}/>):null}
						{selectCompare?(
						<SelectCompare scenario={scenario} 
							max={3}
							comparing={compare}
							onSelect={onCompare} 
							onCancel={() => setSelectCompare(false)}/>
						):null}
					</Col>
					<Col xs="auto">
						<Button size="sm" onClick={() => setExport(true)} variant="outline-secondary">
							<i className="fas fa-download"/>Export
						</Button>
						<Exporter show={isExporting}/>
					</Col>
				</Row>
				<Row style={{marginTop:"1rem"}}>
					<Col>
						{month===null?
							(<YearPlot type={type} data={fulldata} comparing={compare} setMonth={m => setMonth(m)}/>)
							:day===null?
								(
									<MonthPlot type={type} month={month} data={fulldata} comparing={compare} setMonth={setMonth} setDay={__setDay}/>
								):(
									<DayPlot type={type} day={day} data={fulldata} split={splitData} comparing={compare} setDay={__setDay}/>
								)
						}
					</Col>
				</Row>
				</React.Fragment>):null}
			</Container>
		</Card>
	)

}


export default RunLoadScenario ;
