import React, {useState, useContext, useCallback, useEffect, Component} from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Request} from "../../services/HttpService"
import {Notify} from '../components/AlertListener'
import socketIOClient from "socket.io-client";
import ForceGraph2D from 'react-force-graph-2d';
//import SocketContext from "../../services/socket-context";
import io from "socket.io-client";

const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

//const socket = io("/api/meter/ws:10080");
// export const SocketContext = createContext();


export default function  SiteFlow() {

  //const socket = useContext(SocketContext);
  const [organisation, setOrganisation] = useState(null)
  const [flowData, setFlowData] = useState({})
  const { user } = useContext(UserContext)
  const [response, setResponse] = useState("");

  useEffect(() => {
    if(!user) return

    async function __getOrg() {
        try {
            const o = await request.get(`/api/organisation/${user.organisationId}`)
            setOrganisation(o)
        }
        catch(e) {
            console.error(e)  
            notify.error(e.message)
        }
    }

    __getOrg()

    return() => {
        setOrganisation(null)
    }
  }, [user])

  const __loadInitialFlowData = useCallback(async (_id, initialData) => {

    setFlowData(initialData)

  }, []);

  const __updateFlowData = useCallback(async (_id, updateData) => {

    console.log(`FLOWDATA ${JSON.stringify(flowData)}`)

    // let updatedData = await flowData.map(async d => {     
    //   return d.meter_id === updateData.meter_id ? updateData : d
    // })

    // setFlowData(updatedData)

  }, [])


  const __someUIwithData = useCallback( async () => {
    // TODO

  }, [flowData])



  useEffect(() => {
    if(!organisation) return

    console.log(`ORG ${organisation._id}`)

    console.log('Connecting to socket')
    localStorage.debug = '*'
    const socket = socketIOClient("http://10.0.20.2", {
      path: '/api/meter/ws'
    });
    socket.on ("connect", () => {
      console.log("CONNECTED to Socket")
      socket.emit("SubscribeOrg", organisation._id)
    })
    socket.on ("OrgInitial", data => {
      __loadInitialFlowData(organisation._id, data)

    })
    socket.on("DataUpdate", data => {
      console.log(`updated data ${data}`)
      __updateFlowData(organisation._id, data)

    })
    socket.on("FromAPI", data => {
      setResponse(data);
    });

    const cookieVal = JSON.parse(localStorage.getItem('JSESSIONID')) ;
    console.log(`**USER** ${JSON.stringify(user)}`)

    // Run when component dismounts to close the socket
    return () => {
      console.log("Closing socket....")
      socket.disconnect();  
      setFlowData({})  
    }
  }, [organisation]);

 
  return organisation && (
    <Container fluid>
      <h2>Flow - {organisation.name}</h2>
      <div>
        <div>
          <b>Connection status:</b> {response}
        </div>
        
      </div>
      <div>
        <b>Data:</b><pre>{JSON.stringify(flowData, null, 2) }</pre>
      </div>
     </Container>
  )

}
