import React, { Component } from "react";
//import { Button } from "react-bootstrap";
//import { notify } from "../../utility/ToastContainer";
import HttpServiceSettings from "../../services/MeterServices";
//import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

// const options = [
//   { value: "", label: "Select type" },
//   { value: "KWH", label: "KWH" },
//   { value: "ANNUAL", label: "ANNUAL" },
//   { value: "KVA/MIC/ANNUAL", label: "KVA/MIC/ANNUAL" }
// ];
const options = ["Select Type", "KWH", "ANNUAL", "KVA/MIC/ANNUAL"];

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};
var dateNow = new Date();
//const date_from  = dateNow.setUTCHours(0,0,0,0);
//console.log('date_from',date_from)

export default class AddChargeForm extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
    this.state = {
      startDate: dateNow,
      endDate: "",
      today: dateNow,
      billable_id: "",
      name: "",
      charge_type: "",
      price: "",
      selectedType: this.props.selectedType || "",
      type: "",
      formValid: false,
      errorCount: 0,
      errors: {
        name: "",
        price: "",
        charge_type: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const {
        name,
        type,
        price,
        billable_id,
        selectedType,
        startDate,
        endDate,
        currency,
        timezone
      } = this.props;
      //console.log('site_id componentsdid mount', site_id)
      this.setState({
        startDate: startDate,
        endDate: endDate,
        type: type,
        billable_id: billable_id,
        name: name,
        price: price,
        selectedType: selectedType,
        currency: currency,
        timezone: timezone
      });
      this.innerRef.current.focus();
    }, 1);
  }

  // closeForm = event => {
  //   this.props.closeForm();
  // };

  // sendStatusNotification = (type, msg) => {
  //   let className =
  //     type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
  //   let toastMsg = {
  //     msg: msg,
  //     className: className
  //   };
  //   notify(toastMsg);
  // };

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleChange = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    console.log("value", value);
    let errors = this.state.errors;
    switch (name) {
      case "charge_type":
        errors.charge_type = value === "Select Type" ? "Select type!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, selectedType: value });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  changeHandler = e => {
    //console.log('e.target.value', e.target.value)
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case "name":
        errors.name =
          value.length > 20 || value.length < 3
            ? "Name must be between 3-20 characters long!"
            : "";
        break;
      case "price":
        errors.charge_type = value === "Select Type";
        errors.price =
          /\+|-/.test(value)
            ? "Price is not valid"
            : "";
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [name]: value
    });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  addCharge = (event, errors, values) => {
    event.preventDefault();
    const { currency, timezone } = this.props;

    const option = this.state.selectedType;
    console.log("option", option);
    if (option === "enter") {
      let errors = this.state.errors;
      errors.charge_type = "Select type!";
      this.setState({ errors: errors, errorCount: 1 });
    } else {
      const name = this.state.name;
      const price = Number(this.state.price);
      const endDate = this.state.endDate;
      const to_date = moment().toISOString();
      const st_date = this.state.startDate;
      const startDate = moment(st_date)
        .startOf("day")
        .toISOString();
      const dateFrom = startDate;

      var charge;
      if (startDate === null || startDate === "" || startDate === undefined) {
        alert("Please enter valid from date");
      } else if (endDate === null || endDate === "" || endDate === undefined) {
        charge = {
          valid_from: dateFrom,
          currency: currency,
          timezone: timezone,
          name: name,
          price: price,
          charge_type: option
        };
        this.submitCharge(charge);
      } else {
        if (dateFrom > to_date) {
          console.log("dateFrom > to_date", dateFrom > to_date);
          alert("Please check the sequence of dates selected");
        } else {
          charge = {
            valid_from: dateFrom,
            valid_to: endDate,
            currency: currency,
            timezone: timezone,
            name: name,
            price: price,
            charge_type: option
          };
          this.submitCharge(charge);
        }
      }
      console.log("charge", charge);
    }
  };

  submitCharge = charge => {
    const { type, billable_id, charge_id } = this.props;
    if (type === "edit") {
      console.log("edit");
      return HttpServiceSettings.editCharge(billable_id, charge_id, charge)
        .then(response => {
          if (response.data) {
            this.props.closeForm("success", "Charge is updated successfully.");
            //  this.closeForm();
            this.setState({
              name: "",
              price: "",
              selectedType: "",
              type: ""
            });
          } else {
            this.props.closeForm("danger", "Charge already exists");
          }
        })
        .catch((err, code, message, statusCode) => {
          this.props.closeForm("danger", err);
          console.log("error", err);
        });
    } else if (type === "add") {
      return HttpServiceSettings.addCharge(charge, billable_id)
        .then(response => {
          if (response.data) {
            //this.closeForm();
            this.props.closeForm(
              "success",
              "Charge is created successfully."
            );
            this.setState({
              name: "",
              price: "",
              selectedType: "",
              type: ""
            });
          } else {
            // Conflict error found;
            this.props.closeForm("danger", "Charge already exists");
          }
        })
        .catch(err => {
          this.props.closeForm("danger", err);
          console.log("error", err);
        });
    } else {
      alert("error");
    }
  };

  render() {
    const {
      formValid,
      errorCount,
      name,
      errors,
      price,
      endDate,
      startDate,
      selectedType,
      today
    } = this.state;

    return (
      <div>
        <div>
          <form onSubmit={this.addCharge}>
            <div>
              <div className="form-inline">
                <div className="form-group col-xs-8">
                  <div className="labelDate2">
                    <label className="on">Valid from date</label>
                    <DatePicker
                      selected={startDate}
                      onChange={this.handleStartDateChange}
                      locale="en"
                      name="valid_from"
                      dateFormat="dd/MM/yyyy"
                      minDate={today}
                    />
                  </div>
                </div>
                <div className="form-group col-xs-8">
                  <div className="labelDate2">
                    <label className="on">Valid to date (optional)</label>
                    <DatePicker
                      selected={endDate}
                      onChange={this.handleEndDateChange}
                      locale="en"
                      name="valid_to"
                      dateFormat="dd/MM/yyyy"
                      minDate={today}
                    />
                  </div>
                </div>
              </div>

              <table className="table-market" id="charge-add">
                <thead>
                  <tr>
                    <th> Name</th>
                    <th> Type </th>
                    <th> Price </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        required
                        name="name"
                        id="name"
                        onChange={this.changeHandler}
                        placeholder="Enter Charge Name"
                        value={name || ""}
                        ref={this.innerRef}
                      />
                      {errors.name.length > 0 && (
                        <span className="error">{errors.name}</span>
                      )}
                    </td>
                    <td>
                      <select
                        required
                        id="select-type"
                        onChange={this.handleChange}
                        name="charge_type"
                        value={selectedType}
                      >
                        {options.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.charge_type.length > 0 && (
                        <span className="error">{errors.charge_type}</span>
                      )}
                    </td>

                    <td>
                      <input
                        className="form-control"
                        type="number"
                        onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                        required
                        name="price"
                        id="price"
                        onChange={this.changeHandler}
                        placeholder="Enter price"
                        value={price || ""}
                      />
                      {errors.price.length > 0 && (
                        <span className="error">{errors.price}</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-group ">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false || errorCount !== 0
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
