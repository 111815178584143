import moment from 'moment'
import Decimal from 'decimal.js-light'

export const STYLES = new Map([
    ['actual_cost', {
        name: 'Elec. cost ({currency.major}) ',
        itemStyle: {
            color: "#1ecbe1"
        },
        step: "end",
        yAxisIndex: 1 // right axis
    }],
    ['predicted_cost', {
        name: "Pred. Elec. cost ({currency.major}) ",
        itemStyle: {
            color: "#E74C3C"
        },
        step: "end",
        yAxisIndex: 1 // right axis
    }],
    ['wholesale', {
        name: "Wholesale ({currency.minor})",
        itemStyle: {
            color: "#2C3E50 "
        },
        step: "end",
        yAxisIndex: 0 // left axis
    }],
    ['charges', {
        name: "Elec. charges ({currency.minor})",
        itemStyle: {
            color: "#4CAF50 "
        },
        step: "end",
        yAxisIndex: 0 // left axis
    }],
    ['tariffs', {
        name: "Elec. price ({currency.minor}) ",
        itemStyle: {
            color: "#3f51b5"
        },
        step: "end",
        yAxisIndex: 0 // left axis
    }],

    ['actual_load', {
        name: "Actual load (kWh)",
        itemStyle: {
            color: "#8476ff"
        },
        smooth: true,
        yAxisIndex: 0 // left axis
    }],
    ['spill', {
        name: "Spill (kWh)",
        itemStyle: {
            color: "#1ecbe1"
        },
        smooth: true,
        yAxisIndex: 0 // left axis
    }],
    ['actual_supply', {
        name: "Solar (kWh)",
        itemStyle: {
            color: "#ffea00"
        },
        smooth: true,
        yAxisIndex: 0 // left axis
    }],
    ['predicted_supply', {
        name: " Pred. solar (kWh)   ",
        itemStyle: {
            color: "#ff8433"
        },
        smooth: true,
        yAxisIndex: 0 // left axis
    }],
    ['predicted_load', {
        name: " Pred. load (kWh)   ",
        itemStyle: {
            color: "#d32cbf"
        },
        smooth: true,
        yAxisIndex: 0 // left axis
    }],

    ['charge', {
        name: "Charge rate (kW)",
        itemStyle: {
            color: "#2e7d32"
        },
        smooth: false,
        yAxisIndex: 0 // left axis
    }],

    ['discharge', {
        name: "Discharge rate (kW)",
        itemStyle: {
            color: "#ff6f00"
        },
        smooth: false,
        yAxisIndex: 0 // left axis
    }],

    ['soc', {
      name: "State of Charge (%)",
      itemStyle: {
          color: "blue"
      },
      areaStyle: undefined,
      smooth: false,
      yAxisIndex: 0 // left axis
  }],

])

export const TIMELINE_24HR = []

for (let hour = 0, time = moment(0, 0).utc(); hour <= 24; hour++, time.add(1, 'hour')) {
    TIMELINE_24HR.push(time.format("HH:mm"));
}

export const DAY_AXIS={
  "name": "",
  "nameLocation": "left",
  "nameTextStyle": {
      "fontSize": 20,
      "padding": 20
  },
  "type": "category",
  position: "bottom",
  "data": TIMELINE_24HR,
  "axisTick": {
      "alignWithLabel": true
  },
  "boundaryGap": false,
  "splitLine": {
      "show": true
  },
  "offset": 10
}

export const BASE_OPTIONS = {
    "title": {
        "text": "",
        "x": "center"
    },
    "tooltip": {
        "trigger": "axis"
    },
    "legend": {
        "orient": "horizontal",
        "itemWidth": 10,
        "itemHeight": 8,
        "itemGap": 20,
        "icon": "roundRect",
        "textStyle": {
            "fontSize": 12
        }
    },
    "toolbox": {
        "feature": {
            "dataZoom": {
                "title": "zoom",
                "show": true
            },
            "saveAsImage": {
                "title": "download as png"
            }
        }
    },
    "grid": {
        "top": "20%",
        "left": "8%",
        "right": "10%",
        "bottom": "10%",
        "containLabel": true
    },
    "xAxis": [DAY_AXIS],
    "yAxis": [],
    "series": []
}

export const ENERGY_AXES = [{
    "type": "value",
    "position": "left",
    "offset": 0,
    "nameTextStyle": {
        "fontSize": 16,
        "padding": 8
    },
    "axisLabel": {
        "formatter": "{value}",
        "inside": true,
        "margin": -40
    },
    "axisLine": {
        "lineStyle": {
            "color": "black"
        }
    },
    "name": "Energy(kWh)",
    "nameLocation": "end",
    "min" : 0
}]

export const ENERGY_AXES_WATTS = [{
  ...ENERGY_AXES[0],
  "name": "Energy(W)"
}]

export const COST_AXES = [{
    "type": "value",
    "scale": true,
    "axisTick": {
        "alignWithLabel": true
    },
    "position": "left",
    "offset": 0,
    "nameTextStyle": {
        "fontSize": 16,
        "padding": 8
    },
    "axisLabel": {
        "formatter": "{value}",
        "inside": true,
        "margin": -40
    },
    "axisLine": {
        "lineStyle": {
            "color": "black"
        }
    },
    "name": "Price(c)",
    "min" : 0
},
{
    "type": "value",
    "scale": true,
    "axisTick": {
        "alignWithLabel": true
    },
    "position": "right",
    "offset": 0,
    "nameTextStyle": {
        "fontSize": 16,
        "padding": 8
    },
    "axisLabel": {
        "formatter": "{value}",
        "inside": true,
        "margin": -40
    },
    "axisLine": {
        "lineStyle": {
            "color": "blue",
            "inside": true
        }
    },
    "name": "Cost(€)",
    "min" : 0
}]

const energy = {
    "title": {
      "text": "",
      "x": "center"
    },
    "tooltip": {
      "trigger": "axis"
    },
    "legend": {
      "orient": "horizontal",
      "itemWidth": 10,
      "itemHeight": 8,
      "itemGap": 20,
      "icon": "roundRect",
      "textStyle": {
        "fontSize": 12
      }
    },
    "toolbox": {
      "feature": {
        "dataZoom": {
          "title": "zoom",
          "show": true
        },
        "saveAsImage": {
          "title": "download as png"
        }
      }
    },
    "grid": {
      "top": "20%",
      "left": "8%",
      "right": "10%",
      "bottom": "10%",
      "containLabel": true
    },
    "xAxis": [
      {
        "name": "",
        "nameLocation": "middle",
        "nameTextStyle": {
          "fontSize": 20,
          "padding": 20
        },
        "type": "category",
        "data": [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "00:00"
        ],
        "axisTick": {
          "alignWithLabel": true
        },
        "boundaryGap": false,
        "splitLine": {
          "show": true
        },
        "offset": 10
      }
    ],
    "yAxis": [
      {
        "__axis": "LOAD",
        "type": "value",
        "position": "left",
        "offset": 0,
        "nameTextStyle": {
          "fontSize": 16,
          "padding": 8
        },
        "axisLabel": {
          "formatter": "{value}",
          "inside": true,
          "margin": -40
        },
        "axisLine": {
          "lineStyle": {
            "color": "black"
          }
        },
        "name": "Energy(kWh)",
        "nameLocation": "end"
      }
    ],
    "series": [
      {
        "name": "",
        "type": "line",
        "data": []
      },
      {
        "name": " Pred. load (kWh)   ",
        "data": [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "4.760",
          "4.090",
          "4.470",
          "4.840",
          "6.720",
          "7.000",
          "5.270",
          "4.590",
          "3.130",
          "1.990",
          "3.390",
          "2.870",
          "3.020",
          "7.680"
        ],
        "type": "line",
        "itemStyle": {
          "color": "#d32cbf"
        },
        "yAxisIndex": 0,
        "smooth": true
      },
      {
        "name": "Actual load (kWh)",
        "data": [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "3.639",
          "4.423",
          "5.288",
          "5.221",
          "5.997",
          "6.893",
          "7.178",
          "5.282",
          "5.210"
        ],
        "type": "line",
        "itemStyle": {
          "color": "#8476ff"
        },
        "yAxisIndex": 0,
        "smooth": true
      },
      {
        "name": "Solar (kWh)",
        "data": [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "3.182",
          "3.682",
          "5.644",
          "6.138",
          "6.840",
          "8.260",
          "7.880",
          "5.558",
          "5.362"
        ],
        "type": "line",
        "itemStyle": {
          "color": "#ffea00"
        },
        "yAxisIndex": 0,
        "smooth": true
      }
    ]
  }
  
const cost = {
    "title": {
      "text": "",
      "x": "center"
    },
    "tooltip": {
      "trigger": "axis"
    },
    "legend": {
      "orient": "horizontal",
      "itemWidth": 10,
      "itemHeight": 8,
      "itemGap": 20,
      "icon": "roundRect",
      "textStyle": {
        "fontSize": 12
      }
    },
    "toolbox": {
      "feature": {
        "dataZoom": {
          "title": "zoom",
          "show": true
        },
        "saveAsImage": {
          "title": "download as png"
        }
      }
    },
    "grid": {
      "top": "20%",
      "left": "8%",
      "right": "10%",
      "bottom": "10%",
      "containLabel": true
    },
    "xAxis": [
      {
        "interval": 1,
        "name": "",
        "nameLocation": "middle",
        "nameTextStyle": {
          "fontSize": 20,
          "padding": 20
        },
        "type": "category",
        "data": [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
          "00:00"
        ],
        "axisTick": {
          "alignWithLabel": true
        },
        "boundaryGap": false,
        "splitLine": {
          "show": true
        },
        "offset": 10
      }
    ],
    "yAxis": [
      {
        "__axis": "CURRENCY_MINOR",
        "type": "value",
        "scale": true,
        "axisTick": {
          "alignWithLabel": true
        },
        "position": "left",
        "offset": 0,
        "nameTextStyle": {
          "fontSize": 16,
          "padding": 8
        },
        "axisLabel": {
          "formatter": "{value}",
          "inside": true,
          "margin": -40
        },
        "axisLine": {
          "lineStyle": {
            "color": "black"
          }
        },
        "name": "Price(c)"
      },
      {
        "__axis": "CURRENCY_MAJOR",
        "type": "value",
        "scale": true,
        "axisTick": {
          "alignWithLabel": true
        },
        "position": "right",
        "offset": 0,
        "nameTextStyle": {
          "fontSize": 16,
          "padding": 8
        },
        "axisLabel": {
          "formatter": "{value}",
          "inside": true,
          "margin": -40
        },
        "axisLine": {
          "lineStyle": {
            "color": "blue",
            "nside": true
          }
        },
        "name": "Cost(€)"
      }
    ],
    "series": [
      {
        "name": "Pred. Elec. cost (€) ",
        "data": [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000",
          "0.000"
        ],
        "type": "line",
        "yAxisIndex": 1,
        "itemStyle": {
          "color": "#E74C3C"
        },
        "step": "end"
      },
      {
        "name": "Elec. price (c) ",
        "data": [
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "9.018",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "15.354",
          "9.018"
        ],
        "type": "line",
        "yAxisIndex": 0,
        "itemStyle": {
          "color": "#3f51b5"
        },
        "step": "end"
      },
      {
        "name": "Elec. charges (c)",
        "data": [
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969",
          "3.969"
        ],
        "type": "line",
        "yAxisIndex": 0,
        "itemStyle": {
          "color": "#4CAF50 "
        },
        "step": "end"
      },
      {
        "name": "Elec. cost (€) ",
        "data": [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "0.757",
          "0.878",
          "1.010",
          "1.000",
          "1.119",
          "1.257",
          "1.301",
          "1.009",
          "0.998"
        ],
        "type": "line",
        "yAxisIndex": 1,
        "itemStyle": {
          "color": "#1ecbe1"
        },
        "step": "end"
      },
      {
        "name": "",
        "type": "line",
        "data": []
      }
    ]
  }

export const createSeries = (series, opts={}) => {
  //  const { areaStyle={}, ...__opts} = opts
  //  if(areaStyle!==false) __opts.areaStyle=areaStyle

   return {
    type: 'line',
    symbol: 'none',
    areaStyle: {},
    encode: {
      x: 'timestamp',
      y: series
    },
    ...STYLES.get(series),
    ...opts
  }
}

