import React from 'react';
import moment from 'moment';

export default function RateChargesRow({rate_charges, rate_charge}) {
    return (
        <tr>
            <td>{rate_charge.name}</td>
            <td>{moment(rate_charge.valid_from).format("Do MMM  YY")}</td>
            {rate_charge.valid_to
                ? (
                    <td>{moment(rate_charge.valid_to).format("Do MMM  YY")}</td>
                )
                : <td></td>}
            <td>{rate_charge.start}</td>
            <td>{rate_charge.end}</td>
            <td>{rate_charge.band}</td>
            <td>{rate_charge.charge_type}</td>
            <td>{rate_charge.value_type}</td>
            <td>{rate_charge.currency}</td>
            <td>{rate_charge.value}</td>


        </tr>

    );
}
