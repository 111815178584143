import {Request} from "./HttpService";
import { noop } from '../dashboard/components/UIComponents'

const request = new Request({ service: 'Site service'})

export async function loadSitesForOrganisation(organisationId, onLoad=noop) {
    let __sites = await request.get(`/api/organisation/${organisationId}/site`, {
        tenancyView: true,
        full: true
    })
    
    if(__sites.length===0) {
        onLoad([])
        return ;
    }

    const __tree=[]
    
    // Filter out all sites whose parent ID is not in set
    const __ids = __sites.map(({_id}) => _id)
    const __filtered = __sites.map(({parent_id, ...rest}) => {
        if(!parent_id || parent_id==='' || !__ids.includes(parent_id)) return rest
        return {
            ...rest,
            parent_id
        }
    })

    function addNode(site, depth=0) {
        const { _id, name} = site
        __tree.push({ ...site, value: _id, label: `${depth>0?'- ':''}${name}`, depth})
        // Need to add child nodes?
        const children = __filtered.filter(({parent_id}) => _id===parent_id)
        for(const c of children) {
            addNode(c, depth+1)
        }
    }
    const __top=__filtered.filter(({parent_id}) => !parent_id)
    for(const n of __top) {
        addNode(n)
    }
    // console.log('(tree) setSites() ', __tree)
    onLoad(__tree)
}