import React, { Component } from "react";
import ProfileTable from "./ProfileTable";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const login_user = sessionStorage.getItem("_user");
    const l_user = JSON.parse(login_user);
    const id = l_user.organisation.id;

    return (
      <div>
        <div className="top-admin">
          <ProfileTable orgId={id} />
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
