import React, { useState, useEffect, useCallback, useContext} from 'react'
import { Container } from 'react-bootstrap'
import UserContext from "../../services/user-context"
import {Notify} from '../components/AlertListener'
import {Request} from "../../services/HttpService"
import Fetch, { excludeNegativeFilter } from "../helpers/fetch-data"
import EnergyPlot from '../plots/EnergyPlot'
import AccuracyTable from './../load/AccuracyTable'
const request = new Request({ service: 'Dashboard service'})
const notify=Notify("dash.notifications")

export default function OrganisationSolar({startDate, endDate}) {
    const [organisation, setOrganisation] = useState(null)
    const [supplyData, setSupplyData] = useState(null)
    const [predictedSupplyData, setPredictedSupplyData] = useState(null)
    
    const { user, Time } = useContext(UserContext)

    useEffect(() => {
        if(!user) return

        async function __getOrg() {
            try {
                const o = await request.get(`/api/organisation/${user.organisationId}`)
                setOrganisation(o)
            }
            catch(e) {
                console.error(e)  
                notify.error(e.message)
            }
        }

        __getOrg()

        return() => {
            setOrganisation(null)
        }
    }, [user])

    const __supplyData = useCallback(async (_id) => {
        console.log(`[OrgSolar] Fetch load data for ${organisation.name} from ${Time.day(startDate)} to ${Time.day(endDate)}`)
        // Actual load
        const __query= {
            from: Time.day(startDate).format(),
            // Add day + 1 minute to include midnight at end
            to: Time.day(endDate).add(1, 'minute').format(),
            price: 0
        }
        Fetch(`/api/meter/organisation/${user.organisationId}/readings`, 
                { ...__query, meter_type: 'supply'}, 
                setSupplyData,
                ["timestamp", "reading"],
                excludeNegativeFilter())
        // Predicted supply
        Fetch(`/api/organisation/${user.organisationId}/predict/supply`, __query, setPredictedSupplyData)

    }, [startDate, endDate, organisation])

    useEffect(() => {
        if(!organisation) return
        __supplyData(organisation._id)

        return () => {
            setSupplyData(null)
            setPredictedSupplyData(null)
        }
    }, [startDate, endDate, organisation])

    return organisation && (<Container fluid>
        <h2>Solar - {organisation.name}</h2>
        <div>
            {(supplyData || predictedSupplyData) && 
                <EnergyPlot 
                    supply={supplyData} 
                    predictedSupply={predictedSupplyData} 
                    startTime={startDate}
                    endTime={endDate}/>}
            <AccuracyTable observed={supplyData} predicted={predictedSupplyData}/>
        </div>
    </Container>)
}