import React, { useState, useEffect, useContext, useCallback } from 'react'
import SessionStorage from "../../services/SessionStorage"
import UserContext from "../../services/user-context"
import { Menu } from 'semantic-ui-react'
import EnergyPlot from './EnergyPlot'
import CostPlot from './CostPlot'
import BatteryPlot from '../battery/BatteryPlot'
import Fetch from "../helpers/fetch-data"
import { Request } from "../../services/HttpService"

const request = new Request({ service: 'Dashboard service' })

const DAY_SELECTOR = 'dash.day.active'

const MENU_ITEMS = ['yesterday', 'today', 'tomorrow']

const EMPTY = { yesterday: [], today: [], tomorrow: [] }

const avg = a => Array.isArray(a) ?
    a.reduce((t, c) => t + c, 0) / a.length :
    0

const addElement = (e, a, i) => {
    const __l = a[i] || []
    __l.push(e)
    a[i] = __l
}

export default function ThreeDayPlot({ load = null, supply = null,
    predictedLoad = null, predictedSupply = null,
    batteryCharge = null, batteryDischarge = null,
    tariffs = null, wholesale = null,
    batteries = null
}) {
    const [day, setDay] = useState(SessionStorage.get(DAY_SELECTOR, 'today'))
    const [date, setDate] = useState(null)
    const [loadData, setLoadData] = useState(EMPTY)
    const [supplyData, setSupplyData] = useState(EMPTY)
    const [predictedLoadData, setPredictedLoadData] = useState(EMPTY)
    const [predictedSupplyData, setPredictedSupplyData] = useState(EMPTY)
    const [batteryChargeData, setBatteryChargeData] = useState(EMPTY)
    const [batteryDischargeData, setBatteryDischargeData] = useState(EMPTY)
    const [tariffData, setTariffData] = useState(EMPTY)
    const [wholesaleData, setWholeData] = useState(EMPTY)
    const [batteryData, setBatteryData] = useState([])

    const { Time } = useContext(UserContext)
    const [start] = useState(Time.localTime(Time.yesterday()))
    // Add day + 1 minute to include midnight at end
    const [end] = useState(Time.tomorrow().add({ day: 1, minute: 1 }))

    const handleChangeDay = useCallback((e, { name }) => {
        SessionStorage.set(DAY_SELECTOR, name)
        setDay(name)
    }, [setDay])

    useEffect(() => {
        switch (day) {
            case 'yesterday':
                setDate(Time.yesterday())
                break;
            case 'today':
                setDate(Time.today())
                break;
            case 'tomorrow':
                setDate(Time.tomorrow())
                break;
        }

    }, [day])

    const createSeries = useCallback((data) => {
        if (!Array.isArray(data)) return EMPTY
        const __ts = Time.localTime(start),
            __data = []
        let __ndx = 0
        while (Time.compare(__ts, end) < 0) {
            if (__ndx < data.length && Time.compare(__ts, data[__ndx].timestamp) === 0) {
                __data.push(data[__ndx])
                __ndx += 1
            }
            else {
                __data.push({ timestamp: __ts.format() })
            }
            __ts.add(1, 'hour')
        }
        return {
            yesterday: __data.slice(0, 25),
            today: __data.slice(24, 49),
            tomorrow: __data.slice(48)
        }
    }, [Time, start, end])

    useEffect(() => {
        setLoadData(createSeries(load))
    }, [load])

    useEffect(() => {
        setSupplyData(createSeries(supply))
    }, [supply])

    useEffect(() => {
        setPredictedLoadData(createSeries(predictedLoad))
    }, [predictedLoad])

    useEffect(() => {
        setPredictedSupplyData(createSeries(predictedSupply))
    }, [predictedSupply])

    useEffect(() => {
        setBatteryChargeData(createSeries(batteryCharge))
    }, [batteryCharge])

    useEffect(() => {
        setBatteryDischargeData(createSeries(batteryDischarge))
    }, [batteryDischarge])

    useEffect(() => {
        setTariffData(createSeries(tariffs))
    }, [tariffs])

    useEffect(() => {
        setWholeData(createSeries(wholesale))
    }, [wholesale])

    useEffect(() => {
        // From/to
        const __yesterday = Time.yesterday(),
            __iYesterday = +__yesterday,
            __today = Time.today(),
            __iToday = +__today,
            __tomorrow = Time.tomorrow(),
            __iTomorrow = +__tomorrow,
            __iTomorrowEnd = +(Time.tomorrow().add(1, 'day')),
            __query = {
                from: __yesterday.toISOString(),
                to: __tomorrow.add({ day: 1, minute: 1 }).toISOString()
            }

        async function __fetchBatteryData() {
            setBatteryData(await Promise.all(batteries.map(async (battery) => {
                const { name, _id, meter_id=null } = battery
                if(meter_id===null) return null
                const rval = {
                    battery,
                    yesterday: [{timestamp: __iYesterday, reading: 0}],
                    today: [{timestamp: __iToday, reading: 0}],
                    tomorrow: [{timestamp: __iTomorrow, reading: 0}],
                    soc: []
                }

                const __closeSeries = (series, endTS) => {
                    if(Array.isArray(series) && series.length>0) {
                        const __lastTS = series[series.length-1].timestamp
                        if(__lastTS<endTS) {
                            series.push({timestamp: __lastTS+1, reading: 0 })
                        }
                        series.push({timestamp: endTS, reading: 0})
                    }
                }
                try {
                    const __response = await request.get(`/api/battery/${_id}/soc`, __query)
                    // const __response = await request.get(`/api/meter/${meter_id}/readings`,
                    //     { 
                    //         ...__query,
                    //         aggregation: 'raw',
                    //         meter_type: 'storage'
                    //     })
                    for(const {timestamp, soc, charge=0, discharge=0} of __response) {
                        const ts = +(Time.localTime(timestamp)),
                            reading = (charge>0?charge*-1:discharge)/1000 
                        const __v = { timestamp: ts, reading, soc}
                        let __series=null
                        if(ts>=__iYesterday && ts<__iToday) {
                            __series = rval.yesterday
                        }
                        else if(ts>=__iToday && ts<__iTomorrow) {
                            __series = rval.today
                        }
                        else if(ts>=__iTomorrow && ts<__iTomorrowEnd) {
                            __series = rval.tomorrow
                        }
                        if(__series) {
                            if(__series.length===1 ) {
                                const {timestamp: __start} = __series[0]
                                if(__start<ts) {
                                    __series.push({timestamp: ts-1, reading: 0})
                                }
                            }
                            __series.push(__v)
                        }
                        rval.soc.push({timestamp: ts, soc})
                    }
                    __closeSeries(rval.yesterday, __iToday)
                    __closeSeries(rval.today, __iTomorrow)
                    __closeSeries(rval.tomorrow, __iTomorrowEnd)
                    return rval ;
                }
                catch(err) {
                    console.error(`[__fetchBatteryData] - Error fetching data for ${name}`, err)
                    return null
                }
            })))

        }

        async function __loadData() {

            // Fetch the battery data
            const __batteryData = await Promise.all(batteries.map(async (battery) => {
                const { _id } = battery,
                    __data = {
                        battery,
                        yesterday: { range: [__iYesterday, __iToday], soc: [], charge: [], discharge: [] },
                        today: { range: [__iToday, __iTomorrow], soc: [], charge: [], discharge: [] },
                        tomorrow: { range: [__iTomorrow, +Time.tomorrow().add(1, 'day')], soc: [], charge: [], discharge: [] }
                    }
                const [__policy = {}, __soc = {}] = await Promise.allSettled([
                    request.get(`/api/battery/${_id}/policy`, __query),
                    request.get(`/api/battery/${_id}/soc`, __query)])
                if (__soc.status === 'fulfilled') {
                    let i = 0
                    const __hBase = Math.floor(__iYesterday / (1000 * 60 * 60)),
                        __chAccum = [], __dischAccum = [],
                        __chVals = new Array(72),
                        __dischVals = new Array(72)
                    for (const { timestamp, soc, charge = 0, discharge = 0 } of __soc.value) {
                        const __ts = +Time.localTime(timestamp),
                            __th = Math.floor(__ts / (1000 * 60 * 60)) - __hBase,
                            __v = [__ts, soc]
                        addElement(charge, __chVals, __th)
                        addElement(discharge, __dischVals, __th)

                        if (__ts < __iToday) {
                            __data.yesterday.soc.push(__v)
                        }
                        else if (__ts < __iTomorrow) {
                            __data.today.soc.push(__v)
                        }
                        else {
                            __data.tomorrow.soc.push(__v)
                        }
                    }
                    // Convert watts to kW and round to 2 dec  places
                    const __chAvg = __chVals.map(a => Math.round(avg(a) / 10) / 100),
                        __dischAvg = __dischVals.map(a => Math.round(avg(a) / 10) / 100)
                    __data.yesterday.charge = __chAvg.slice(0, 24)
                    __data.today.charge = __chAvg.slice(24, 48)
                    __data.tomorrow.charge = __chAvg.slice(48)
                    __data.yesterday.discharge = __dischAvg.slice(0, 24)
                    __data.today.discharge = __dischAvg.slice(24, 48)
                    __data.tomorrow.discharge = __dischAvg.slice(48)
                }
                if (__policy.status === "fulfilled") {
                    const __pData = new Array(72).fill(0)
                    for (const { timestamp, charge = 0, discharge = 0 } of __policy.value) {
                        const __ts = Time.localTime(timestamp)
                        __pData[__ts.diff(__yesterday, 'hour')] = discharge !== 0 ? discharge * -1 : charge
                    }
                    __data.yesterday.policy = __pData.slice(0, 24)
                    __data.today.policy = __pData.slice(24, 48)
                    __data.tomorrow.policy = __pData.slice(48)
                }
                return __data
            }))
            setBatteryData(__batteryData)
        }

        if (Array.isArray(batteries) && batteries.length > 0) {
            // __loadData()
            __fetchBatteryData()
        }


        return () => {
            if (Array.isArray(batteries) && batteries.length > 0) {
                setBatteryData(batteries.map(b => null))
            }
            else {
                setBatteryData([])
            }
        }
    }, [batteries])


    return (
        <div>
            <Menu pointing secondary>
                {MENU_ITEMS.map(m => (<Menu.Item
                    name={m}
                    key={`dashday_${m}`}
                    active={day === m}
                    onClick={handleChangeDay}
                />))}
            </Menu>

            <div>
                <EnergyPlot
                    load={loadData[day]}
                    supply={supplyData[day]}
                    predictedLoad={predictedLoadData[day]}
                    predictedSupply={predictedSupplyData[day]}
                // charge={batteryChargeData[day]}
                // discharge={batteryDischargeData[day]}
                />
                {
                    batteryData.map(__b => (
                        __b!==null?
                        (<div key={`__battery${__b.battery._id}`}>
                            <h5>Storage - {__b.battery.name}</h5>
                            <BatteryPlot battery={__b.battery} 
                                data={__b[day]} soc={__b.soc} />
                        </div>
                        )
                        : __b
                    ))
                }
                <CostPlot
                    load={loadData[day]}
                    predictedLoad={predictedLoadData[day]}
                    tariffs={tariffData[day]}
                    wholesale={wholesaleData[day]}
                />
                <div>{date && date.format('Do MMM YYYY')}</div>
            </div>
        </div>
    )
}