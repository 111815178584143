import moment, { monthsShort } from 'moment'

export const OPTIONS = {
    title: {
        left: 'center',
        text: ''
    },
    grid: {
        left: '5%',
        top: '10%',
        right: '5%',
        bottom: '20%'
    },
    color: [ "#1ecbe1", "#E74C3C", "#8476ff", "#d32cbf", "#ffea00", "#ff8433", "#3f51b5", "#009C95"],
    toolbox: {
        feature: {
            saveAsImage: {
                show: true,
                title: 'Download as PNG'
            }
        }
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: [{
        type: 'category',
        name: 'Month',
        nameLocation: "middle",
        nameTextStyle: {
            fontSize: 16,
            padding: [10, 0,0,0]
        },
        data: monthsShort()
    }],
    yAxis: [{
        type: 'value',
        name: "Monthly Output (kw)",
        nameRotate: 90,
        nameGap: 45,
        nameLocation: "middle",
        nameTextStyle: {
            fontSize: 16,
            // padding: [10, 0,0,0]
        },
        max: 0, 
        interval: 2500,
        axisLabel: {
            formatter: '{value}',
            rotate: 45
        }
    }],
    series: []
}

// Build series
const AXIS_PARAMS = [
    [10000, 2500, 45],
    [5000, 1000, 40],
    [2500, 500, 40],
    [1000, 250, 40],
    [500, 100, 30],
    [250, 50, 30],
    [100, 25, 40],
    [50, 10, 30],
    [20, 4, 30],
    [10, 2, 30],
    [5, 1, 30],
    [null, 0.5, 30]
]

export function calculateAxisParams(max, curr={max: 0}) {
    if(max<=curr.max) return curr ;
    for(const [__m, interval, nameGap] of AXIS_PARAMS) {
        if(__m===null || max>__m) {
            const __max = Math.ceil(max/interval)*interval
            return { max: __max, interval, nameGap }
        }
    }
}

const LOOKUP=[
    { style: { fontSize: "3rem"}, precision: 2}, // 10
    { style: { fontSize: "3rem"}, precision: 1}, // 100
    { style: { fontSize: "2.5rem"}, precision: 0}, // 1000
    { style: { fontSize: "2.5rem"}, precision: 0}, // 10000
    { style: { fontSize: "2rem"}, precision: 0}, // 100000
    { style: { }, precision: 0}, // 1000000
]

export default function scale(value, units="watts") {
    let __exp = 0, __curr=value/10
    while(__curr>1) {
        __exp+=1
        __curr/=10
    }
    if(__exp>LOOKUP.length) __exp=LOOKUP.length-1
    const __props=LOOKUP[__exp]
    return {
        value: value.toFixed(__props.precision),
        style: __props.style
    }
}

const  KILOWATT_SCALE_PRECISION = [
    [1, 2, 'kWh'], //10 - Kilowatts
    [1, 1, 'kWh'], //100
    [1, 0, 'kWh'], //100
    [1000, 2, 'MWh'], //1000 - MegaWatts
    [1000, 1, 'MWh'], //10000
    [1000, 0, 'kWh'], //100000
    [1000000, 2, 'GWh'], //1000000 - GigaWatts
    [1000000, 1, 'GWh'], //10000000
    [1000000, 0, 'GWh']  //1000000000
]

export const DEFAULT_WATT_HOUR_SCALER = {
    transform: v=>v,
    divisor: 1,
    precision: 0,
    units: 'Wh'
}



export function scaleWattHours(value, precision=null) {
    let __selected = KILOWATT_SCALE_PRECISION[KILOWATT_SCALE_PRECISION.length-1]
    if(precision == null){
        // default to last value
        // loop through KILOWATT_SCALE_PRECISION to find a set that reduces the value below 1000
        for(const __params of KILOWATT_SCALE_PRECISION) {
            if(value/__params[0] < 1000) {
                __selected = __params
                break
            }
        }

    } else {
        __selected = precision
    }
    return {
        // transform : v => v/__selected[0],
        transform : v => parseFloat( (v/__selected[0]).toFixed(__selected[1])),
        divisor : __selected[0],
        precision : __selected[1],
        units: __selected[2]
    }
}

export function textWidth(elem, value, units) {
    const __maxWidth = elem.offsetWidth
    const __div = document.createElement('div')
    elem.appendChild(__div)
    let size=4

    // Main content node
    const __main = elem.firstChild
    __main.style.display = 'inline'
    __div.innerHTML = __main.outerHTML
    // Find the nodes
    const __value = __main.getElementsByClassName('__value').item(0),
        __units = __main.getElementsByClassName('__value__units').item(0)
    if(__value) {
        __value.innerHTML = value
    }
    if(__units) {
        __units.innerHTML=units
    }
    while(true) {
        __main.style.fontSize=`${size}rem`
        // Calculate the width of the children
        const __children = __main.children 
        let __width=0
        for(let i=0; i<__children.length; ++i) {
            __width += __children[i].offsetWidth
        }
        // Don't go below 1rem
        if(__width<__maxWidth || size===1) {
            elem.removeChild(__div)
            return`${size}rem`
        }
        size-=0.5
    }
}


export const JAN1=moment('2019-01-01')

export const DAYS_IN_MONTH = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

