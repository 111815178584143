import React, { Component } from "react";
import Graph, { LOAD } from "../DashboardGraphs.jsx";
import TotalComparisonTable from "../accuracy/TotalComparisonTable.jsx";
import ComparisonTable from "../accuracy/ComparisonTable";
//import SpearmanRHO from "spearman-rho";

// import {
//   getComarisonValues,
//   getDataDifferences,
//   total
// } from "../../utility/HelperFunctions";

export default class LoadGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  render() {
    const {
      predictedLoad,
      actualLoad,
      yName,
      timeline,
      // loadReadingArray,
      // loadPredictionArray,
      // loadParsed,
      // predictedloadParsed,
      differencesLoad,
      r2Load,
      totalDifLoad,
      totalReadingLoad,
      totalPredictionLoad,
      totalPercLoad,
      mean_sq_errorLoad,
      maseFLoad
    } = this.props;

    //console.log('222differencesLoad',differencesLoad)

    // var differences = [];
    // var totalDif
    // var totalReading
    // var totalPrediction
    // var totalPerc
    //
    //
    // if (loadParsed.length && predictedloadParsed.length) {
    //   differences = getDataDifferences(loadParsed, predictedloadParsed, timeline);
    //   const diff = differences.map(a => a.difference)
    //   const arrayDiff = diff.filter(a=> !isNaN(a));
    //   const reading = differences.map(a => a.readingTotal);
    //   var arrayRead = reading.filter(a=> !isNaN(a));
    //   const prediction = differences.map(a => a.predictionTotal);
    //   var arrayPredict = prediction.filter(a=> !isNaN(a));
    //
    //    totalDif = total(arrayDiff).toFixed(2);
    //    totalReading = total(arrayRead).toFixed(2);
    //    totalPrediction = total(arrayPredict).toFixed(2);
    //    totalPerc = ((totalReading - totalPrediction)/totalReading *100).toFixed(3)
    //
    //
    //
    //
    //    var {
    //      mean_sq_error,
    //      maseF,
    //    } = getComarisonValues( arrayRead, arrayPredict);

    //this.getspearmanRHO( arrayRead, arrayPredict);
    //  }

    console.log('33333 loadParsed', actualLoad)

    return (
      <div className="marginPage">
        <div className="row small-gutters ">
          <div className="col-lg-12">
            <Graph
              // price={priceLabel}
              modules={true}
              timeline={timeline}
              yName={yName}
              axes={{ left: [LOAD] }}
              series={[predictedLoad, actualLoad]}
            />
          </div>
          <div>
            <TotalComparisonTable
              mean_sq_error={mean_sq_errorLoad}
              maseF={maseFLoad}
              spearmanRHO={r2Load}
            />
          </div>
        </div>

        {differencesLoad && differencesLoad.length ? (
          <div className="row small-gutters ">
            <div className="col-lg-12">
              <div>
                <ComparisonTable
                  differences={differencesLoad}
                  sumActualReadings={totalReadingLoad}
                  sumPredictions={totalPredictionLoad}
                  diffTotal={totalDifLoad}
                  incr={totalPercLoad}
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
