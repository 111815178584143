import React, { useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import OrgTabView from '../OrgTabView'
import OrganisationFlow from './OrganisationFlow'
import ClusterFlow from './ClusterFlow'
import SiteFlow from './SiteFlow'

export default function Flow() {
    return (<Container fluid>
        
        { <OrgTabView page="flow" 
            ClusterPage={ClusterFlow}
            OrganisationPage={OrganisationFlow}
            SitePage={OrganisationFlow}
            
            /> }
    </Container>)
}