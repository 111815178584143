import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";

import { TimeUtils } from 'time-utils'

import Moment from "moment";
import momentLocalizer from "react-widgets-moment";



import "react-widgets/dist/css/react-widgets.css";
import "./sass/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
 
import debug from "debug"

// import LoadingPage from './LoadingPage.jsx' ;
import Dashboard from "./dashboard/Dashboard.jsx";
import LoginPage from "./login/LoginPage.jsx";
import AdminDashboard from './admin/AdminDashboard.jsx'
import TopAdmin from "./dashboard/settings/TopAdminPage.jsx";
import AuthService, {AuthenticatedUser} from "./services/auth";
import UserContext from "./services/user-context"
import {Request} from "./services/HttpService"
import { Currencies, DEFAULT_CURRENCY } from './utility'

//import request from 'reqwest';
//import Credentials from 'credentials'
//import io from 'socket.io-client';
//import {SocketContext, socket} from "./services/socket-context"

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

process.env.DEBUG="xxx"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const request = new Request({ service: 'App'})

const NotFound = ({ location }) => (
  <div>
    <h2>Not Found</h2>
    <p>{location.pathname} not found</p>
  </div>
);

Moment.locale("en-GB");
momentLocalizer();

export default function App() {
  const [ loading, setLoading ] = useState(true)
  // const [ context, setContext ] = useState(null)
  const [ user, setUser ] = useState(null)
  // const [ timezone, setTimeZone ] = useState('Europe/Dublin')
  // const [ Time, setTime ] = useState(null)
  // const [ Currency, setCurrency] = useState(null)
  ///const [socket, setSocket] = useState(null)
  const [ context, setContext] = useState(null)

  // Disble debug
  localStorage.setItem('debug', '___none')

  useEffect(() => {
    // Are we logged in?
    const checkLoggedIn = async () => {
      try {
        const u = await request.get('/api/user/me')
        const __user = AuthenticatedUser.create(u)
        setUser(__user)
      }
      catch(err) {
        if((typeof(err)==='string' && err.match(/Unauthorized/)!==null ) || err.status === 401) {
          setUser(null)
        }
        else {
          console.error(err)
        }
      }
      setLoading(false)
    }
    checkLoggedIn() 
  }, [])

  useEffect(() => {
    if(!user) return 

    const __tz = user.timezone || 'Europe/Dublin'
    setContext({
      user: user, 
      timezone: __tz,
      Time: new TimeUtils(__tz),
      Currency: Currencies.get(DEFAULT_CURRENCY)
    })

    return () => setContext(null)
  }, [user])

  const history = useHistory()
  const location = useLocation() 

  if(loading) return (<div>Loading app</div>)


  function onLogin(u) {
    setUser(u) ;
    history.replace(u.isAdmin?'/admin':'/dashboard')
  }

  if(user===null) {
    // Need to login
    return (<LoginPage onLogin={onLogin}/>)
  }

  async function logout() {
    if(user===null) return
    try {
      await AuthService.logout();
      console.log(`Logged out ${user.email}`)
      setUser(null)
    } catch (err) {
      console.error("logout() : ", err);
    }
  };

  const MainComponent = ({user}) => {
      // Check where to go
      if(user.isAdmin) {
          return (<Redirect to='/admin'/>)
      }
      else if(user.isUser || user.isOrgAdmin || user.isClusterOwner) {
          return (<Redirect to='/dashboard'/>)
      }
      else {
          // Not logged in
          setUser(null)
          return (<Redirect to='/login'/>)
      }
  }

  // const Socketcontext = () => {
  //   return (
  //     <SocketContext.Provider value={socket}>

  //     </SocketContext.Provider>
  //   );
  // };

  const PublicContent = () => (
    <Switch>
        <Route exact path="/"
          render={() => (<Redirect to={user.isAdmin?'/admin':'/dashboard'}/>)}/>
        <Route path="/login"
          render={() => (<Redirect to={user.isAdmin?'/admin':'/dashboard'}/>)}/>
        <Route path="/logout"
          render={() => {
            logout()
          }}/>
    </Switch>
  )

  const ProtectedContent = ({context=null}) => (
      <UserContext.Provider value={context}>
        {user.isAdmin?(
          <Switch>
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/topadmin" component={TopAdmin} />
            <Route render={() => (<Redirect to="/admin"/>)}/>
          </Switch>
        ):(
          <Switch>
            <Route path="/dashboard" component={Dashboard}/> 
            <Route path="/olddashboard" component={Dashboard}/> 
            <Route render={() => { 
              return (<Redirect to="/dashboard/home"/>)}}/>
          </Switch>
        )}
      </UserContext.Provider>

       
  )

  return (
   
    <>
      <PublicContent/>
      {context!==null && <ProtectedContent context={context}/>}
    </>
  )
}

