import React, { useState, useEffect, useRef, useContext, useCallback} from 'react'
import {Container, Row, Col, Button, Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment'
import { ConfirmAlert, noop } from '../../components/UIComponents'
import MeterServices from '../../../services/MeterServices'
import UserContext from "../../../services/user-context";
import {Request} from "../../../services/HttpService";
import {Notify} from '../../components/AlertListener'
import Moment from 'moment'

const request = new Request({ service: 'Site service'})
const notify=Notify("site.notifications")

const ValidMeterTypes = [ 'incomer', 'load', 'supply', 'virtual', 'spill' ]
const MeterSubTypes = [ 
    ['voltage', 'Volts'],
    ['current', 'Amps'],
    ['watts', 'Watts'],
    ['kilowatts', 'Kilowatts'],
    ['kilowatt-hour', 'Kilowatt Hours']
 ] ;

const ATTRS = { 
	"name": { minLength: 2, required: true },
	"description" : {},
	"meter_type": { validate: (value) => {
        if(!Array.isArray(value) || value.length===0) {
            return "Meter type required"
        }
         
        return undefined
    }}, 
	"meter_subtype": { required: true},
    "date_range": {  
        validate: (value, meter) => {
            const { valid_from, valid_to, openEnded } = meter
            if(!valid_from) return "Valid from required"
            if(!openEnded) {
                if(!valid_to) return "Valid to required"
                const __to = moment(valid_to)
                if(__to.isSameOrBefore(valid_from, 'date')) {
                    return "From must be later than to date"
                }
            }
            return undefined
        }
    },
    "billing_plan_id": {},
    "valid_from": { control: 'date_range' },
    "valid_to": { control: 'date_range' },
    "openEnded": { control: 'date_range' }
}

function getControl(n) {
    if(ATTRS[n] && ATTRS[n].control) {
        return ATTRS[n].control
    }
    else {
        return n
    }
}

const NEW_METER={
    name: "",
    description: "",
    meter_type: [],
    meter_subtype: "kilowatt-hour",
    billing_plan_id: "",
    config: {}, 
    openEnded: true
}


export default function EditMeterForm({meter=null, 
        site=null, 
        organisation=null,
        cluster=null,
        type=null, 
        name=null, 
        virtual=false,
        existing=[], 
        onDone=noop, 
        canAggregate=[]}) {
    const [typeOptions, setTypeOptions] = useState([...ValidMeterTypes])
    const [fixedTypes, setFixedTypes] = useState([])
    const [isVirtual, setVirtual] = useState(false)
    const [fields, setFields] = useState({...NEW_METER})
    const [initial, setInitial] = useState({...NEW_METER})
    const [dirty, setDirty] = useState(false)
	const [errors, setErrors] = useState({})
	const [validated, setValidated] = useState(false);
	const [confirmRequired, setConfirm] = useState(false)
    const [aggregableMeters, setAggregableMeters] = useState(null)
    const [additive, setAdditive] = useState([])
    const [additiveOptions, setAdditiveOptions] = useState([])
    const [subtractive, setSubtractive] = useState([])
    const [subtractiveOptions, setSubtractiveOptions] = useState([])
	const [billingPlans, setBillingPlans] = useState([])

    const cancelRef = useRef()

    const { Time } = useContext(UserContext)

    const keyUpHandler = useCallback(event => {
        if(event.key==='Escape') {
            // Use ESC to click cancel - button ref is cleanest way to ensure 
            // context is in scope
            cancelRef.current.click()
        }
    }, [])
    
    async function loadAggregableMeters() {
        if(aggregableMeters===null) {
            try {
                let __param, __label
                if(site!==null) {
                    // Get meters for a site
                    __param=`siteId=${site._id}`
                    __label = m => m.site_id===site._id?m.name:`${m.site_name || '????'}/${m.name}`
                }
                else if(organisation!==null) {
                    // Get meters for an org
                    __param=`organisationId=${organisation._id}`
                    __label = m => `${m.site_name || '????'}/${m.name}`
                }
                else if(cluster!==null) {
                    // Cluster meters
                    __param = `clusterId=${cluster._id}`
                    __label = m => `${m.org_name || '????'}/${m.name}`
                }
                else {
                    console.error('loadAggregableMeters() site/organisation/cluster not set')
                    return
                }
                const __meters = await request.get(`/api/meter/aggregable?${__param}`)

                const __aggregable=__meters.map(m => ({
                    ...m,
                    value: m._id,
                    label: __label(m)
                }))
                const __a = __aggregable.filter(m => meter===null || meter._id!==m._id)
                setAggregableMeters(__a)
            }
            catch(err) {
                console.error(err)
                notify.error('Error fetching aggregable meters')
                setAggregableMeters([])
            }
        }
    }

    useEffect(() => {
        if(aggregableMeters!==null) {
            setSubtractiveOptions(filterList(aggregableMeters, additive))
            setAdditiveOptions(filterList(aggregableMeters, subtractive))
        }
    }, [aggregableMeters, additive, subtractive])

    const mapOption = useCallback((m) => {
        const {_id } = m
        const [match] = aggregableMeters.filter(m => m._id===_id)
        if(match) {
            let __label
            if(site) {
                __label = match.site_id===site._id?match.name:`${match.site_name || '????'}/${match.name}`
            }
            else if(organisation) {
                __label = `${match.site_name || '????'}/${match.name}`
            }
            else if(cluster) {
                __label = `${match.org_name || '????'}/${match.name}`
            }
            else {
                __label = `????/${m.name}`
            }
            return { _id, name: match.name, value: _id, label: __label}
        }
        return { _id, name: m.name, value: _id, label: m.name}
    }, [aggregableMeters, site, cluster, organisation])
    

    useEffect(() => {
        async function __loadBillingPlans() {
            try {
                const __type = cluster!==null?'Cluster':'Organisation'
                const __plans = await request.get(`/api/billable/plan?type=${__type}`)
                setBillingPlans(__plans)
            }
            catch(err) {
                notify.error(err.message)
            }
        }
        __loadBillingPlans()
    }, [setBillingPlans])

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler)

        if(meter===null) {
            const __initial = {...NEW_METER, valid_from: new Date(), valid_to: null, openEnded: true}
            let __meter_type=[]
            if(type!==null && ValidMeterTypes.includes(type)) {
                __meter_type = [type]
            }
            if(virtual && type!=='virtual') {
                __meter_type.push('virtual')
            }
            let __name=null
            switch(type) {
                case 'load':
                    __name = name || "Load meter"
                    break ;
                case 'incomer':
                    __name = name || "Main incomer"
                    break ;
                case 'supply':
                    __name = name || "Supply meter"
                    break ;
                case 'spill':
                    __name = name || "Spill meter"
                    break ;
                case 'virtual':
                    __name = name || "Virtual meter"
                    break ;
                default:
                    __name=""
            }
            __initial.name = MeterServices.findUniqueName(existing, __name)
            setInitial({...__initial, meter_type: [...__meter_type]})
            setFields({...__initial, meter_type: [...__meter_type]})
            setFixedTypes(__meter_type)
            if(__meter_type.includes('virtual')) {
                loadAggregableMeters()
                setVirtual(true)
            }
            setAdditive([])
            setSubtractive([])
        }
        else {
            const { name, description="", meter_type, meter_subtype, billing_plan_id, config={}, valid_from, valid_to=null } = meter
            const __initial = {
                name, description, 
                meter_type, meter_subtype, config, 
                billing_plan_id,
                valid_from: Time.dateToLocal(valid_from).toDate(), 
                valid_to: valid_to?Time.dateToLocal(valid_to).toDate():null, 
                openEnded: valid_to===null}
            setInitial(__initial)
            setFields({...__initial})

            const isVirtual = meter.meter_type.includes('virtual')
            setVirtual(isVirtual)
            if(isVirtual) {
                loadAggregableMeters()
                const {additive=[], subtractive=[]} = config
                setAdditive(additive)
                setSubtractive(subtractive)
            }
            else {
                setAdditive([])
                setSubtractive([])
            }
        }
        return () => {
            window.removeEventListener('keyup', keyUpHandler)
        }
    }, [meter, site, organisation, cluster])

    useEffect(() => {
        const { meter_type=[] } = fields
        // Restrict the available tags based on existing
        if(meter_type.includes('incomer')) {
            // Incommer can't be virtual
            setTypeOptions([ 'incomer', 'load' ])
        }
        else if(meter_type.includes('load')) {
            setTypeOptions(meter_type.includes('virtual')?
            [ 'load','virtual' ]:
            [ 'incomer', 'load','virtual' ])
        }
        else if(meter_type.includes('supply')) {
            setTypeOptions([ 'supply', 'virtual'])
        }
        else if(meter_type.includes('spill')) {
            setTypeOptions([ 'virtual', 'spill' ])
        }
        else if(meter_type.includes('virtual')) {
            setTypeOptions([ 'load', 'supply', 'virtual', 'spill' ])
        }
        else {
            setTypeOptions(ValidMeterTypes)
        }

        // Is the edit dirty?
		const __d = hasChanged() 
		setDirty(d => __d)
    }, [fields])

    async function onCancel(e) {
		if(e===true || !hasChanged()) {
			// Result of clicking confirm yes or no change
			return onDone()
		}
		setConfirm(true)
    }

    async function onSave(event) {
		if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        const __isValid = validate()
        setValidated(__isValid)
        if(__isValid) {
            // Save the meter
            const {name, description, meter_type, meter_subtype, billing_plan_id, valid_from, valid_to, openEnded} = fields
            let data, __uri
            if(meter===null) {
                // New meter
                data = {
                    name, 
                    description,
                    active: true, 
                    meter_type,
                    meter_subtype,
                    config: {}
                }
                if(site) {
                    data.site_id = site._id
                }
                else if(organisation) {
                    data.organisation_id = organisation._id
                }
                else if(cluster) {
                    data.cluster_id = cluster._id
                }
                else {
                    notify.error('No site, organisation, or cluster')
                    return
                }
                __uri = '/api/meter'
            }
            else {
                // Updating
                const { uri={}, ...orig } = meter
                const { path } = uri
                data = {
                    ...orig,

                    config: {},
                    name, description, meter_type, meter_subtype
                }
                if(path) {
                    // ONLY send back the 'path' - 'base' is immutable
                    data.uri = path
                }
                __uri = `/api/meter/${meter._id}`
            }

            console.log(`billing_plan_id : "${billing_plan_id}"`)
            if(billing_plan_id && billing_plan_id!=="") {
                data.billing_plan_id = billing_plan_id
            }
            else {
                delete data.billing_plan_id
            }

            data.valid_from = Moment.utc(valid_from).toISOString()
            data.valid_to=openEnded?undefined:Moment.utc(valid_to).toISOString()
            if(meter_type.includes('virtual')) {
                data.config.additive = additive.map(m => ({_id: m._id}))
                data.config.subtractive = subtractive.map(m => ({_id: m._id}))
            }

            try {
                const __new = await request.call(__uri, 'put', { data })
                notify.info(`${meter===null?'Created':'Updated'} site meter "${data.name}"`)
                onDone()
            }
            catch(err) {
                console.error(err)
                notify.error(err.message)
            }
        }
        return false 
    }

    function listsDiffer(l1=[], l2=[]) {
        if(l1.length!==l2.length) return true
        const _ids = l1.map(({_id}) => _id)
        for(const {_id} of l2) {
            if(!_ids.includes(_id)) return true
        }
        return false
    }

	function hasChanged() {
		const __changed = Object.keys(ATTRS).reduce((en, k) => {
            if(k!=='meter_type') {
                return en || initial[k]!==fields[k]
            }
            if(fields[k].length!==initial[k].length) {
                return true
            }
            for(const v of fields[k]) {
                if(!initial[k].includes(v)) {
                    return true
                }
            }
            return en
        }, false) 
        if(__changed) return true
        if(isVirtual) {
            // Changes to tags will have been covered above - check lists
            const {additive: origAdditive, subtractive: origSubtractive} = initial.config
            if(listsDiffer(origAdditive, additive)) return true
            if(listsDiffer(origSubtractive, subtractive)) return true
        }
        return false
	}
	function isValid(name, value, fields) {
		let __err=false
        if(ATTRS[name]) {
            const {minLength=0, required=false, validate=null} = ATTRS[name]
            if(required && value.length===0) {
                __err = "Value required"
            }
            if(!__err && minLength>0 && value.length<minLength) {
                __err = `Must be at least ${minLength} characters`
            }

            if(!__err && validate) {
                const __msg = validate(value, fields)
                if(__msg) __err=__msg
            }
        }        
		return  __err
	}

	function validate(__fields=fields) {
		const __validated = Object.entries(ATTRS).reduce((v, [name]) => {
            // Get validation entity
            name = getControl(name)
			const __err = isValid(name, __fields[name], __fields)
			errors[name] = __err
			return v && __err===false
		}, true)
		setErrors({...errors})
		return __validated
	}

	function onChange(e) {
		const {currentTarget: __form} = e 
		const { value="", name, type } = e.target ;
		fields[name] = type!=="checkbox"?value:!fields[name]
		setFields({...fields})
        // Get validation entity
        const eName = getControl(name)
		errors[eName]=isValid(eName, value, fields)
		setErrors({...errors})
		setValidated(Object.values(errors).reduce((v,e) => v && e===false, true))
	}

    function onTypeChange(p1, {action, ...value}={}) {
        let __value=fields.meter_type
        switch(action) {
            case 'select-option': 
                const __selected = value.option.value
                __value = [...fields.meter_type, __selected]
                if(__selected==='virtual') {
                    loadAggregableMeters()
                }
                break
            case 'pop-value':
            case 'remove-value':
                const __removed=value.removedValue.value
                if(!fixedTypes.includes(__removed)) {
                    __value=fields.meter_type.filter(t => t!==__removed)
                }
                break
            case 'clear':
                __value = []
                break
            default:
                console.warn(`[addMeter] : Unhandled select action "${action}"`)
                break
        }
        setVirtual(__value.includes('virtual'))

        onChange({ target: {value: __value, name: 'meter_type'}})
    }

    useEffect(() => {
        setDirty(hasChanged())
		setValidated(Object.values(errors).reduce((v,e) => v && e===false, true))
    }, [additive, subtractive])

	const update = useCallback((whichControl, d) => {
		if(whichControl==="from") {
			setFields({...fields, valid_from: d})
		}
		else {
			setFields({...fields, valid_to: d})
		}
	}, [fields])

    // (`Dirty = ${dirty}, validated=${validated}, virtual=${isVirtual}`)

    function onVirtualConfigChange(list="additive", {action, ...value}={}) {
        // console.log(`onVirtualConfigChange(${list}, ${action}, ${JSON.stringify(value)})`)
        let __list
        if(list==='additive') {
            __list=additive
        }
        else if(list==='subtractive') {
            __list=subtractive
        }
        if(__list===null) return 
        switch(action) {
            case 'select-option': 
                const __value = value.option
                __list = [...__list, __value]
                break
            case 'pop-value':
            case 'remove-value':
                const v=value.removedValue
                __list = __list.filter(m => m._id!==v._id)
                break
            case 'clear':
                __list=[]
                break
            default:
                console.warn(`[addMeter] : Unhandled select action "${action}"`)
                break
        }
        if(list==='additive') {
            setAdditive(__list)
            setSubtractiveOptions(filterList(aggregableMeters, __list))
            }
        else if(list==='subtractive') {
            setSubtractive(__list)
            setAdditiveOptions(filterList(aggregableMeters, __list))
        }
    }

    const props={disabled: confirmRequired}

    // Multi select styles
    const styles = {
        multiValue: (base, state) => state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
        multiValueLabel: (base, state) => state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base,
        multiValueRemove: (base, state) => state.data.isFixed ? { ...base, display: 'none' } : base
      };
      
    function filterList(list, exclude) {
        const __exc = exclude.map(({_id}) => _id)
        return list.filter(m => !__exc.includes(m._id))
    }


    return (
        <Container fluid>
            <Form noValidate onSubmit={onSave}>
                <Row>
                    <Col><h5>{meter===null?"New":"Edit"} {site!==null?'Site':organisation!==null?'Organisation':'Cluster'} Meter</h5></Col>
                </Row>
                <Form.Row>
                    <Form.Group as={Col} lg={6} controlId="meterName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            {...props}
                            minLength="2"
                            value={fields.name} 
                            onChange={onChange}
                            isInvalid={!!errors.name}
                            placeholder="Meter name"/>
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>                
                <Form.Row>
                    <Form.Group as={Col} lg={6} controlId="meterDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            {...props}
                            minLength="2"
                            value={fields.description} 
                            onChange={onChange}
                            placeholder="Description"/>
                    </Form.Group>
                </Form.Row>                
                <Form.Row>
                    <Form.Group as={Col} lg={4} controlId="meterType">
                        <Form.Label>Meter Type</Form.Label>
                        <Select
                            value={fields.meter_type.map(t => ({value: t, label: t, isFixed: fixedTypes.includes(t)}))}
                            onChange={onTypeChange}
                            options={typeOptions.map(t => ({label: t, value: t})) }
                            placeholder={"Meter Types"}
                            isDisabled={props.disabled===true}
                            // isClearable
                            styles={styles}
                            name="meter_type"
                            inputId="meterType"
                            isMulti/>
                        <div style={{display: !!errors.meter_type?'block':'none'}} className="invalid-feedback">{errors.meter_type}</div>
                    </Form.Group>
					<Form.Group as={Col} lg="2" controlId="meterSubtype">
						<Form.Label>Units</Form.Label>
						<Form.Control as='select' 
							name='meter_subtype' 
  							{...props}
							value={fields.meter_subtype} 
							isInvalid={!!errors.meter_subtype}
							onChange={onChange}>
							{MeterSubTypes.map(([v,t]) => (<option key={`__subtype_${v}`} value={v}>{t}</option>))}
						</Form.Control>
						<Form.Control.Feedback type="invalid">{errors.meter_subtype}</Form.Control.Feedback>
					</Form.Group>
                </Form.Row>
                <Form.Row>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Billing plan</Form.Label>
                            <Form.Control as="select" name="billing_plan_id" value={fields.billing_plan_id} onChange={onChange} custom>
                                <option key="billing_plan_none" value="">None</option>
                                {billingPlans.map(({_id, name}) => (
                                    <option key={`billing_plan_${_id}`} value={_id}>{name}</option>)
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                {isVirtual && Array.isArray(aggregableMeters)?(<>
                    <Row><Col><h5>Virtual Meter Configuration</h5></Col></Row>
                    {aggregableMeters.length===0?
                        (<Row><Col>No valid meters to aggregate</Col></Row>):null}
                    <Form.Row>
                    <Form.Group as={Col} lg={3} controlId="meterVirtualAdd">
                            <Form.Label>Add Meters</Form.Label>
                            <Select
                                defaultValue={additive.map(mapOption)}
                                onChange={(p1, p2) => onVirtualConfigChange('additive', {...p2})}
                                options={additiveOptions}
                                placeholder={"Add"}
                                isDisabled={props.disabled===true}
                                // isClearable
                                styles={styles}
                                name="meter_additive"
                                inputId="meterAdditive"
                                isMulti/>
                            <div style={{display: !!errors.meter_type?'block':'none'}} className="invalid-feedback">{errors.meter_type}</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={3} controlId="meterVirtualSubtract">
                            <Form.Label>Subtract Meters</Form.Label>
                            <Select
                                defaultValue={subtractive.map(mapOption)}
                                onChange={(p1, p2) => onVirtualConfigChange('subtractive', {...p2})}
                                options={subtractiveOptions}
                                placeholder={"Subtract"}
                                isDisabled={props.disabled===true}
                                // isClearable
                                styles={styles}
                                name="meter_subtractive"
                                inputId="meterSubtractive"
                                isMulti/>
                            <div style={{display: !!errors.meter_type?'block':'none'}} className="invalid-feedback">{errors.meter_type}</div>
                        </Form.Group>
                    </Form.Row>       
                </>):null}
                <Form.Row>
                    <Col lg="3">
                        <div>Valid From</div>
                        <DatePicker
                            style={{width: "100%"}}
                            selected={fields.valid_from}
                            {...props}
                            onChange={d => onChange({target: {name: 'valid_from', value: d}})}
                            // onChange={d => update('from', d)}
                            locale="en"
                            name="valid_from"
                            dateFormat="dd/MM/yyyy"/>
                        <div 
                            style={{display: !!errors.date_range?'block':'none'}}
                            className="invalid-feedback">
                            {errors.date_range}
                        </div>
                    </Col>
                    <Col lg="3">
                        <div>Valid To</div>
                        <DatePicker
                            selected={fields.valid_to}
                            disabled={props.disabled || fields.openEnded}
                            onChange={d => onChange({target: {name: 'valid_to', value: d}})}
                            isDisabled={props.disabled===true}
                            locale="en"
                            name="valid_to"
                            dateFormat="dd/MM/yyyy"/>
                        <div>
                            <Form.Check
                                type="checkbox"
                                name="openEnded"
                                label="Open ended period"
                                {...props}
                                onChange={onChange}
                                checked={fields.openEnded}/>
                        </div>
                    </Col>
                </Form.Row>
                
                <Form.Row>
  					<Col lg="6">
	            		{confirmRequired?(<ConfirmAlert
          					heading="Details have changed"
          					text="Meter details have changed, really cancel?"
          					buttonsInline={false}
          					onNo={() => setConfirm(false)}
          					onYes={() => onCancel(true)}/>
		            	):
			            (<div className="btn-right">
			            	<Button 
		            			type="button" 
                                ref={cancelRef}
		            			variant="outline-secondary" 
		            			disabled={props.disabled===true}
		            			onClick={onCancel}
		            			style={{marginRight: "1rem"}}>
		            			<i className="fas fa-times"/>Cancel
		            		</Button>
		            		
		            		<Button 
                                id="btnSave" 
                                disabled={!dirty || !validated || props.disabled} 
                                type="button" 
                                onClick={onSave} 
                                variant="re-primary">
								<i className="fas fa-save"/>Save
							</Button>
						</div>)}
  					</Col>
  				</Form.Row>
            </Form>
        </Container>
    )
}