import React from "react";

export default function SiteRows({ site, editSite }) {
  return (
    <tr>
      <td>{site.name}</td>
      <td>{site.active ? "Yes" : "No"}</td>
      <td>
        <button className="editDelete" onClick={() => editSite(site)}>
          Edit
        </button>
      </td>
    </tr>
  );
}
