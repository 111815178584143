import React, { Component } from "react";
//import { Button } from "react-bootstrap";
import { notify } from "../../utility/ToastContainer";
import ClusetServices from "../../services/ClusterServices";

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
const countErrors = errors => {
  let count = 0;
  Object.values(errors).forEach(val => val.length > 0 && (count = count + 1));
  return count;
};
//const date_from  = dateNow.setUTCHours(0,0,0,0);
//console.log('date_from',date_from)

export default class SetClusterOwnerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      cluster_id: "",
      user_id:this.props.user_id || '',
      formValid: false,
      errorCount: 0,
      errors: {
        name: "",
        price: "",
        charge_type: ""
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { users, cluster_id,user_id } = this.props;
      //console.log('site_id componentsdid mount', site_id)
      this.setState({
        users: users,
        clusters: cluster_id,
        user_id:user_id
      });
    }, 1);
  }

  closeForm = event => {
    this.props.closeForm();
  };

  sendStatusNotification = (type, msg) => {
    let className =
      type === "ERROR" ? "lobibox-notify-error" : "lobibox-notify-success";
    let toastMsg = {
      msg: msg,
      className: className
    };
    notify(toastMsg);
  };

  handleChange = e => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    console.log("value", value);
    let errors = this.state.errors;
    switch (name) {
      case "select":
        errors.select = !name ? "Select user!" : "";
        break;
      default:
        break;
    }
    this.setState({ errors, user_id: value });
    this.setState({ formValid: validateForm(this.state.errors) });
    this.setState({ errorCount: countErrors(this.state.errors) });
  };

  selectOwner = (event, errors, values) => {
    event.preventDefault();
    const userId = this.state.user_id;
    const cluster_id = this.props.cluster_id;
    const user = { id: userId };
    console.log("CLUSTER OWNER", userId, user);
    return ClusetServices.setOwner(cluster_id, user)
      .then(response => {
        console.log("response", response);
        this.props.closeForm("success","Cluster owner set successfully.");
      })
      .catch(err => {
        this.props.closeForm("danger", err);
      });
  };

  render() {
    const { users, formValid, errorCount } = this.state;

    return (
      <div>
        <div>
          <form onSubmit={this.selectOwner}>
            <div>
              <select
                required
                name="name"
                id="name"
                onChange={this.handleChange}
                placeholder="Select User"
              ><option value=''>Select User</option>
                {users.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.firstname} {option.lastname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group ">
              <button
                disabled={formValid === false || errorCount !== 0}
                type="submit"
                className="submit btn-round"
              >
                {this.props.type === "edit" ? "Update" : "Create"}
              </button>
            </div>
            {errorCount !== 0 ? (
              <p className="form-status">
                Form is{" "}
                {formValid === false || errorCount !== 0
                  ? "invalid ❌"
                  : "valid ✅"}
              </p>
            ) : (
              "Form not submitted"
            )}
          </form>
        </div>
      </div>
    );
  }
}
