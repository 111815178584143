import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { Menu, Segment } from "semantic-ui-react";
import {  LinkContainer } from 'react-router-bootstrap'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import UserContext from "../services/user-context"
import SessionStorage from "../services/SessionStorage"
import {AlertContainer} from './components/AlertListener'
import DateRangePicker from './components/DateRangePicker.jsx'

const START_DATE = 'dashboard.datepicker.start',
	END_DATE = 'dashboard.datepicker.end'


export default function OrgTabView({page, ClusterPage, OrganisationPage, SitePage, daterange=false}) {
    const DASH_SELECTED_TAB = `main.${page}.tab.active`,
        DEFAULT_TAB = `/dashboard/${page}/organisation`

	const [activeTab, setActiveTab] = useState(SessionStorage.get(DASH_SELECTED_TAB, DEFAULT_TAB))

    console.log(`SELECTED : "${DASH_SELECTED_TAB}", TAB="${DEFAULT_TAB}"`)

    const { user, Time } = useContext(UserContext)

	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

    const [maxDate] = useState(Time.localToDate(Time.tomorrow()))

    const location = useLocation()
    const history  = useHistory()
    console.log('Location : ', location)

    function handleTabChange(tab) {
        const __activeTab = `/dashboard/${page}/${tab}`
		SessionStorage.set(DASH_SELECTED_TAB, __activeTab)
		setActiveTab(__activeTab)
    }

    const handleUpdateDateRange = useCallback((__start, __end) => {
        // console.log(`[handleUpdateDateRange] - start = ${__start}, end=${__end})`)
        setStartDate(__start)
        setEndDate(__end)
    }, [startDate, setStartDate, endDate, setEndDate])

    if(!user) return null

    return (<>
        { daterange && <DateRangePicker 
                maxDate={maxDate} maxRange="P1M"
                onChange={handleUpdateDateRange}
                nobutton/> }
        <Menu  pointing tabular attached="top">
            {user.isClusterOwner && <LinkContainer to={`/dashboard/${page}/cluster`}>
                <Menu.Item name='Cluster' onClick={() => handleTabChange('cluster')}>
                    Cluster
                </Menu.Item>
            </LinkContainer>}
            <LinkContainer to={`/dashboard/${page}/organisation`}>
                <Menu.Item name='Organisation' onClick={() => handleTabChange('organisation')}>
                    Organisation
                </Menu.Item>
            </LinkContainer>
            {user.isOrgAdmin && <LinkContainer to={`/dashboard/${page}/site`}>
                <Menu.Item name='Site' onClick={() => handleTabChange('site')}>
                    Site
                </Menu.Item>
            </LinkContainer>}

        </Menu>
        <Segment attached="bottom">
            <AlertContainer tag="dash.notifications" page="dashboard"/>
            <Switch>
                {user.isClusterOwner && <Route key="cluster" path={`/dashboard/${page}/cluster`} 
                    render={() => (<ClusterPage startDate={startDate} endDate={endDate}/>)} />}
                <Route key="organisation" path={`/dashboard/${page}/organisation`} 
                    render={() => (<OrganisationPage startDate={startDate} endDate={endDate}/>)} />
                {user.isOrgAdmin && <Route key="site" path={`/dashboard/${page}/site`} 
                    render={() => (<SitePage startDate={startDate} endDate={endDate}/>)} />}
                <Route
                    render={() => <Redirect to={location.pathname.startsWith(activeTab)?DEFAULT_TAB:activeTab}/>}
                />
            </Switch>
        </Segment>    
    </>)
}