import React, { Component } from "react";
import { Menu, Segment } from "semantic-ui-react";
import {
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import Solar from './solar/SolarDashboard.jsx'
import Load from './load/LoadDashboard.jsx'
import Battery from './battery/BatteryDashboard.jsx'
import Wholesale from './wholesale/WholesaleDashboard.jsx'
import SessionStorage from '../../services/SessionStorage'

const TAB_ACTIVE = 'scenario.tab.active'

class Dashboard extends Component {
	constructor(props) {
		super(props) ;
		this.state = {
			activeTab: SessionStorage.get(TAB_ACTIVE, '/dashboard/scenario/solar')
		}
	}

	handleTabChange = (tab) => {
		const activeTab = `/dashboard/scenario/${tab}`
		SessionStorage.set(TAB_ACTIVE, activeTab)
		this.setState({activeTab})
	}

		
	render() {
		const { activeTab } = this.state ;

		return (
			<React.Fragment>
		    	<Menu  pointing tabular attached="top">
					<LinkContainer to="/dashboard/scenario/solar">
						<Menu.Item name="Market" onClick={() => this.handleTabChange('solar')}>
							Solar
						</Menu.Item>
					</LinkContainer>
					<LinkContainer to="/dashboard/scenario/load">
						<Menu.Item name='Load' onClick={() => this.handleTabChange('load')}>
							Load
						</Menu.Item>
					</LinkContainer>
					<LinkContainer to="/dashboard/scenario/Wholesale">
						<Menu.Item name="Wholesale" onClick={() => this.handleTabChange('wholesale')}>
							Wholesale
						</Menu.Item>
					</LinkContainer>
					<LinkContainer to="/dashboard/scenario/battery">
						<Menu.Item name="Battery" onClick={() => this.handleTabChange('battery')}>
							Battery
						</Menu.Item>
					</LinkContainer>
              	</Menu>
				<Segment attached="bottom">
					<Switch>
						<Route
							path={`/dashboard/scenario/solar`}
							key={`solar`}
							render={() => (
								<Solar location={this.props.location}/>
							)}
						/>
						<Route
							path="/dashboard/scenario/load"
							key='load'
							render={() => (
								<Load location={this.props.location}/>
							)}
						/>
						<Route
							key='wholesale'
							path="/dashboard/scenario/wholesale"
							render={() => (
								<Wholesale location={this.props.location}/>
							)}
						/>
						<Route
							key="battery"
							path="/dashboard/scenario/battery"
							render={() => (
								<Battery/>
							)}
						/>
						<Route
							render={() => (
								<Redirect to={activeTab} />
							)}
						/>
					</Switch>
				</Segment>			
			</React.Fragment>
		);
	}

}

export default Dashboard ;